var disposed = false
function injectStyle (context) {
  if (disposed) return
  require("!!vue-style-loader!../../../../../../node_modules/mini-css-extract-plugin/dist/loader.js!css-loader?{\"sourceMap\":true}!../../../../../../node_modules/vue-loader/lib/style-compiler/index?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-877a2c8c\",\"scoped\":true,\"sourceMap\":true}!less-loader?{\"sourceMap\":true}!./vx-general-inquiry.less")
}
/* script */
export * from "!!babel-loader!./vx-general-inquiry.js"
import __vue_script__ from "!!babel-loader!./vx-general-inquiry.js"/* template */
import {render as __vue_render__, staticRenderFns as __vue_static_render_fns__} from "!!../../../../../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-877a2c8c\",\"hasScoped\":true,\"optionsId\":\"0\",\"buble\":{\"transforms\":{}}}!./vx-general-inquiry.html"
/* template functional */
var __vue_template_functional__ = false
/* styles */
var __vue_styles__ = injectStyle
/* scopeId */
var __vue_scopeId__ = "data-v-877a2c8c"
/* moduleIdentifier (server only) */
var __vue_module_identifier__ = null
import normalizeComponent from "!../../../../../../node_modules/vue-loader/lib/runtime/component-normalizer"
var Component = normalizeComponent(
  __vue_script__,
  __vue_render__,
  __vue_static_render_fns__,
  __vue_template_functional__,
  __vue_styles__,
  __vue_scopeId__,
  __vue_module_identifier__
)
Component.options.__file = "src/gppro/components/access-customer-service/vx-service-ticket/vx-general-inquiry/vx-general-inquiry.vue"

/* hot reload */
if (module.hot) {(function () {
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), false)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-877a2c8c", Component.options)
  } else {
    hotAPI.reload("data-v-877a2c8c", Component.options)
  }
  module.hot.dispose(function (data) {
    disposed = true
  })
})()}

export default Component.exports
