var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-cancel-order row mx-xs-0" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("p", { staticClass: "text" }, [
        _vm._v("\n        " + _vm._s(_vm.i18n.cancelOrderText) + "\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "cancel-order mt-xs-5 col-xs-12 px-xs-3 px-sm-0 mb-xs-3"
        },
        [
          _c(
            "button",
            {
              staticClass:
                "mr-sm-5 col-xs-12 col-sm-6 form-blue-button btn btn-blue-reverse",
              on: {
                click: function($event) {
                  _vm.keepOrder($event)
                }
              }
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.i18n.keepOrder) + "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "col-xs-12 col-sm-6 mt-xs-3 mt-sm-0 form-blue-button",
              on: {
                click: function($event) {
                  _vm.cancelOrder($event)
                }
              }
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.i18n.cancelOrder) + "\n        "
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a4a131e0", { render: render, staticRenderFns: staticRenderFns })
  }
}