var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-product-support",
      class: _vm.componentTheme,
      attrs: { id: _vm.componentId }
    },
    [
      _c("h3", {
        staticClass: "ml-md-3 support-title",
        domProps: { innerHTML: _vm._s(_vm.componentTitle) }
      }),
      _vm._v(" "),
      _vm.componentTitle
        ? _c("div", { staticClass: "title-underline mt-xs-2" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "nav nav-tabs mb-xs-2", attrs: { role: "tablist" } },
        _vm._l(_vm.tabsData, function(item, index) {
          return _c(
            "li",
            {
              staticClass: "px-xs-3",
              class: index === _vm.activeTab ? "active" : ""
            },
            [
              _c("a", {
                staticClass: "px-xs-0 py-xs-3",
                attrs: {
                  href: "#tab" + index,
                  "data-toggle": "tab",
                  role: "tab",
                  "aria-controls": "tab" + index,
                  "aria-selected": index === 0 ? "true" : "false",
                  tabindex: index === 0 ? "0" : "-1"
                },
                domProps: {
                  innerHTML: _vm._s(
                    item.gpMarketingCategoryData.marketingCategoryHeader
                  )
                }
              })
            ]
          )
        })
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab-content" },
        _vm._l(_vm.tabsData, function(item, ind) {
          return _c(
            "div",
            {
              staticClass: "tab-pane",
              class: ind === _vm.activeTab ? "active" : "",
              attrs: {
                "aria-labelledby": "tab" + ind,
                "aria-hidden": _vm.index === 0 ? "false" : "true",
                id: "tab" + ind,
                role: "tabpanel"
              }
            },
            [
              _c("div", { staticClass: "panel panel-default" }, [
                _c("div", { staticClass: "panel-heading p-xs-0" }, [
                  _c("h4", { staticClass: "panel-title" }, [
                    _c(
                      "a",
                      {
                        staticClass: "p-xs-3",
                        class: ind === 0 ? "" : "collapsed",
                        attrs: {
                          "data-toggle": "collapse",
                          "data-parent": ".tab-pane",
                          href: "#collapse_ps" + ind
                        }
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              item.gpMarketingCategoryData
                                .marketingCategoryHeader
                            )
                          }
                        })
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "panel-collapse collapse",
                    class: ind === 0 ? "in" : "",
                    attrs: { id: "collapse_ps" + ind }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "panel-body" },
                      [
                        _c("vx-product-category", {
                          attrs: { tabBody: item.gpTipsAndKnowledgeData }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ]
          )
        })
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-afb0374a", { render: render, staticRenderFns: staticRenderFns })
  }
}