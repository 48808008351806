import find from 'lodash.find';
import globals from '../../../../components/common/globals';
import cookiesMixin from '../../../../components/common/mixins/cookies-mixin';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import messages from '../../../../locale/gp-pro/messages';
import vxEmptyCart from '../../../../components/manage-shopping-cart/vx-empty-cart/vx-empty-cart.vue';
import vxCartProductTile from '../vx-cart-product-tile/vx-cart-product-tile.vue';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxSaveCart from '../vx-save-cart/vx-save-cart.vue';
import ManageShoppingCartService from '../../../../components/common/services/manage-shopping-cart-service';
import {
  cookies,
  flyoutStatus,
} from '../../../../components/common/mixins/vx-enums';
import {
  cartEventBus,
  eventBus,
  globalEventBus,
} from '../../../../modules/event-bus';

export default {
  name: 'vx-mini-cart',
  components: {
    vxCartProductTile,
    vxEmptyCart,
    vxSpinner,
    vxModal,
    vxSaveCart,
  },
  mixins: [cookiesMixin, mobileMixin, flyoutBannerMixin],
  props: {
    // Text coming from property file
    i18n: Object,
    // Indicates if cart is Sample (gpxpress)
    isSampleCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shoppingCartData: {},
      globals,
      cookies,
      messages,
      displayCount: 3,
      manageShoppingCartService: new ManageShoppingCartService(),
      flyoutStatus,
      isBulkEnabled: true,
      shoppingCartProducts: [],
      guestListName: '',
      dataLoaded: false,
    };
  },
  created() {
    // listening to emits of delete and edit item from cart-product tile component
    cartEventBus.$on('cart-update', () => {
      this.callShoppingCart();
    });
    cartEventBus.$on('cart-update-error', () => {
      // this.callShoppingCart('FULL');
    });
  },
  computed: {},
  mounted() {
    this.callMiniCart();
  },
  methods: {
    /**
         * This function gets mini cart details
         */
    callMiniCart() {
      this.dataLoaded = false;
      this.manageShoppingCartService.getDefaultCart({}, this.handleMiniCartResponse, this.handleMiniCartError);
      this.$refs.spinner.showSpinner();
    },
    /**
         * This function handles the response of mini cart details call
         */
    handleMiniCartResponse(response) {
      if (this.$refs.spinner) {
        this.$refs.spinner.hideSpinner();
      }
      if (response && response.data) {
        this.shoppingCartData = response.data;
        for (let i = 0; i < this.shoppingCartData.entries.length; i += 1) {
          this.shoppingCartProducts.push(this.shoppingCartData.entries[i].product);
        }
        this.dataLoaded = true;
      }
    },
    /**
         * This function gets cart details
         */
    callShoppingCart() {
      const requestConfig = {};
      this.manageShoppingCartService.getFullCartService(requestConfig, this.handleShoppingCartResponse, this.handleShoppingCartError);
      // this.spinnerComponent.showSpinner();
    },
    /**
         * This function gets price of installation products
         */
    getInstallationPrice() {
      let invisibleEntries = [];
      const installationPrice = {};
      invisibleEntries = this.shoppingCartData.entries.filter((item) => !!item.visible === false);
      for (let i = 0; i < invisibleEntries.length; i += 1) {
        const installableProduct = find(invisibleEntries[i].additionalAttributes.entry, {
          key: 'installedEntry',
        });
        if (installableProduct) {
          installationPrice[installableProduct.value] = invisibleEntries[i].totalPrice.formattedValue;
        }
      }
      return installationPrice;
    },
    /**
         * This function gets product promotions details
         */
    getProductPromotions() {
      const promotionProductData = {};
      for (let i = 0; i < this.shoppingCartData.appliedProductPromotions.length; i += 1) {
        for (let j = 0; j < this.shoppingCartData.appliedProductPromotions[i].consumedEntries.length; j += 1) {
          const { orderEntryNumber } = this.shoppingCartData
            .appliedProductPromotions[i].consumedEntries[j];
          const { description } = this.shoppingCartData.appliedProductPromotions[i];
          if (!this.shoppingCartData.entries[orderEntryNumber].promotionsRevoked) {
            promotionProductData[orderEntryNumber] = description;
          }
        }
      }
      return promotionProductData;
    },
    /**
         * This function handles the response of cart details
         */
    handleShoppingCartResponse(response) {
      // this.spinnerComponent.hideSpinner();
      if (response && response.data) {
        this.shoppingCartData = response.data;
        this.shoppingCartProducts = [];
        for (let i = 0; i < this.shoppingCartData.entries.length; i += 1) {
          this.shoppingCartProducts.push(this.shoppingCartData.entries[i].product);
        }
        this.individualProducts = this.shoppingCartData.entries;
        if (this.shoppingCartData.appliedOrderPromotions.length) {
          this.shoppingCartData.appliedOrderPromotions.forEach((promotion) => {
            if (promotion.giveAwayCouponCodes.length && promotion.description) {
              this.giveAwayCouponDescription = promotion.description;
            } else {
              this.giveAwayCouponDescription = '';
            }
          });
        } else {
          this.giveAwayCouponDescription = '';
        }
        eventBus.$emit('cart-details', this.shoppingCartData.entries);
        cartEventBus.$emit('total-items-updated', response.data.totalItems, response.data.code);
        if (this.globals.isB2B()) {
          this.installationPrice = this.getInstallationPrice();
        }
        this.promotionData = this.getProductPromotions();
        if (this.shoppingCartData.entries.length !== 0) {
          this.cartHasVisibleItems = true;
        } else {
          this.cartHasVisibleItems = false;
        }
        this.$root.$emit('cartHasVisibleItems', this.cartHasVisibleItems);
        this.$root.$emit('full-cart-called', this.shoppingCartData);
        this.$root.$emit('cart-reloaded', true);
      }
    },
    /**
         * This function handles the error of cart details
         */
    handleShoppingCartError() {
      this.spinnerComponent.hideSpinner();
      this.cartHasVisibleItems = false;
      this.$root.$emit('cartHasVisibleItems', this.cartHasVisibleItems);
    },
    /**
         * This function handles the error of mini cart details call
         */
    handleMiniCartError() {
      if (this.$refs.spinner) {
        this.$refs.spinner.hideSpinner();
        this.dataLoaded = true;
      }
    },
    /**
         * This function shows last n elements from mini cart
         * @param  {Array} array products from mini cart response
         */
    lastNelements(array) {
      const filteredArray = array.filter((item) => item.visible);
      return filteredArray.slice(Math.max(filteredArray.length - this.displayCount, 0));
    },
    handleBulkAddToList(event) {
      if (this.globals.loggedIn) {
        this.$refs.selectListModal.open(event);
      } else {
        // Storing the bulk selected items in the cookie
        const selectedBulkProductsCode = [];
        this.shoppingCartProducts.forEach((item) => {
          selectedBulkProductsCode.push(item.code);
        });
        const selectedBulkProductsCookie = {
          selectedBulkProducts: selectedBulkProductsCode,
          lazyLoad: false,
        };
        this.createCookie(this.cookies.selectedBulkProducts, JSON.stringify(selectedBulkProductsCookie));
        if (this.globals.siteConfig.isGuestList) {
          setTimeout(() => {
            this.guestListName = this.messages['en-US'].searchBrowse.searchResult.guestList;
            this.showFlyout(this.flyoutStatus.success, `${this.shoppingCartProducts.length} ${this.messages['en-US'].searchBrowse.searchResult.addToListSuccess} ${this.messages['en-US'].searchBrowse.searchResult.guestList}`, true);
          }, 300);
        } else {
          this.globals.navigateToUrl('login');
          globalEventBus.$emit('announce', 'For adding to list, you need to login');
          setTimeout(() => {
            this.globals.navigateToUrl('login');
          }, 300);
        }
      }
    },
    onSelectListSuccess(selectedListName) {
      this.$refs.selectListModal.close();
      if (this.globals.loggedIn) {
        this.showFlyout(this.flyoutStatus.success, `${this.shoppingCartProducts.length} ${this.messages['en-US'].searchBrowse.searchResult.addToListSuccess} ${selectedListName}`, true);
      }
    },
  },
};
