/**
 * Nested tiles component
 */
import vxUiuxGpImageText from '../vx-uiux-gp-image-text/vx-uiux-gp-image-text.vue';
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-uiux-nested-tiles',
  components: {
    vxUiuxGpImageText,
  },
  props: {
    imgTileData: {
      type: Object,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
    };
  },
  computed: {},
  mounted() {
    if (this.imgTileData.componentHeaderColor) {
      this.$refs.header.style.color = this.imgTileData.componentHeaderColor;
      if (this.$refs.header.childNodes[0] && this.$refs.header.childNodes[0].style) {
        this.$refs.header.childNodes[0].style.color = this.imgTileData.componentHeaderColor;
      }
    }
  },
  methods: {},
};
