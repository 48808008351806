import axios from 'axios';
import globals from '../globals';
import AnalyticsService from '../services/analytics-service';

const optInMixin = {
  data() {
    return {
      globals,
      queryString: {},
      analyticsService: new AnalyticsService(),
      intervalID: {},
      config: {
        access_token: '',
        authUrl: '/authorizationserver/oauth/token?client_id=mobile_android&client_secret=secret&grant_type=client_credentials',
        urlPrefix: '',
        submitUrl: '',
        estoresPardotURL: 'https://go.pardot.com/l/851283/2020-04-29/26lfb',
        gpproPardotURL: 'https://go.pardot.com/l/851283/2020-04-20/q76l',
        getoptinstatusUrl: '',
      },
      formID: '',
      checkBoxClicked: false,
      existingOpt: false,
      firstFormElementChild: {},
    };
  },
  computed: {

  },
  mounted() {
    const siteName = this.globals.siteId === 'vanityfair' ? 'vanityfairnapkins' : this.globals.siteId;
    this.config.getoptinstatusUrl = `/gpcommercewebservices/v2/${siteName}/utilityforms/getoptstatus`;
  },
  methods: {
    /**
     * submitForm is called on cicking submit button of the create account form.
     * @param {Object} e contains the event of the form.
     * Validates the data entered on successfull validation user a post call is made to registerUser.
     * On failure focus is set back to first field in the form.
     */

    optinAnalyticsHandler(context, isOptin, userEmail, callback) {
      if (context !== 'checkbox' || (context === 'checkbox' && !this.checkBoxClicked)) {
        let gaLabel = '';
        const self = this;
        const dataLayerPush = function() {
          let thisEvent = '';
          let thisAction = '';
          let thisLabel = '';
          const url = window.location;
          const page = url.pathname.split('/').pop();
          const thisIntSource = self.globals.getintsource(page);
          switch (context) {
            case 'checkbox':
              thisEvent = 'promotionClick';
              thisAction = 'engagement';
              thisLabel = 'optin checkbox';
              self.checkBoxClicked = true;
              break;
            case 'submit':
              thisEvent = 'promotionClick';
              thisAction = 'submit';
              thisLabel = gaLabel;

              break;
            case 'impression':
              thisEvent = 'promotionView';
              thisAction = 'view';
              thisLabel = 'impression';
              break;
            default:
              break;
          }
          if (context === 'impression') {
            self.analyticsService.trackMarketingFormPost({
              event: thisEvent,
              action: thisAction,
              label: thisLabel,
              ecommerce: {
                promoView: {
                  promotions: [{
                    creative: 'optionalopt',
                    name: `form|${page}`,
                    position: page,
                    id: thisIntSource,
                  }],
                },
              },
            });
          } else {
            self.analyticsService.trackMarketingFormPost({
              event: thisEvent,
              action: thisAction,
              label: thisLabel,
              ecommerce: {
                promoClick: {
                  promotions: [{
                    creative: 'optionalopt',
                    name: `form|${page}`,
                    position: page,
                    id: thisIntSource,
                  }],
                },
              },
            });
          }

          if (callback) {
            callback.call();
          }
        };
        if (context === 'submit' && isOptin && !this.globals.getIsLoggedIn()) {
          this.GetUserOptInStatus(userEmail, (data) => {
            const brands = [this.globals.siteId];
            this.existingOpt = false;
            if (data.results.success) {
              if (data.results.opts.length > 0) {
                for (let i = 0; i < brands.length; i += 1) {
                  const brand = brands[i];
                  if (!this.existingOpt) {
                    for (let j = 0; j < data.results.opts.length; j += 1) {
                      this.existingOpt = brand.toLowerCase() === data.results.opts[j].brand.toLowerCase();
                    }
                  }
                }
              }
            }
            gaLabel = this.existingOpt ? 'existingopt' : 'newopt';

            dataLayerPush();
          });
        } else if (isOptin && this.globals.getIsLoggedIn()) {
          gaLabel = 'newopt';
          dataLayerPush();
        } else {
          gaLabel = 'no_opt_selected';
          dataLayerPush();
        }
      }
    },

    SendOpt(request, callback) {
      this.email = request.email;
      this.GetAuthToken((success) => {
        if (success) {
          // eslint-disable-next-line prefer-template
          if (this.globals.isB2C()) {
            const utmRequest = request;

            const utmParamKeys = Object.keys(this.queryString);
            const utmParamValues = Object.values(this.queryString);

            for (let i = 0, n = utmParamKeys.length; i < n; i += 1) {
              utmRequest[utmParamKeys[i]] = utmParamValues[i];
            }
            const siteName = this.globals.siteId === 'vanityfair' ? 'vanityfairnapkins' : this.globals.siteId;
            this.config.submitUrl = `/gpcommercewebservices/v2/${siteName}/utilityforms/explicitoptin`;
            axios.post(this.config.urlPrefix + this.config.submitUrl, this.ObjectToQueryString(utmRequest), { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `bearer ${this.config.access_token}` } })
              .then(this.handleSendFormDataResponse)
              .then(callback)
              .catch(callback);
          } else if (this.globals.isB2B()) {
            let url = '';
            if (this.globals.isGppro()) {
              url = this.config.gpproPardotURL;
            } else if (this.globals.isEStores()) {
              url = this.config.estoresPardotURL;
            }

            const pardotBody = {
              email: request.uid,
              'First Name': request.firstName,
              'Last Name': request.lastName,
            };

            axios.post(url, this.ObjectToQueryString(pardotBody))
              .then(callback)
              .catch(callback);
          }
        }
      });
    },

    GetAuthToken(callback) {
      const self = this;
      if (this.config.access_token) {
        if (callback) {
          callback.call(self, true);
        }
      } else {
        // eslint-disable-next-line prefer-template
        axios.post(this.config.urlPrefix + this.config.authUrl)
          .then((response) => {
            if (response && response.data && response.data.access_token) {
              this.config.access_token = response.data.access_token;
              if (callback) {
                callback.call(self, true);
              }
            } else {
              this.handleSendFormDataError.call('The server is unable to accept this form post at this time.');
            }
          })
          .catch(callback);
      }
    },
    ObjectToQueryString(object) {
      let qs = '';
      if (object) {
        qs = Object.keys(object).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`).join('&');
      }
      return qs;
    },
    GetUserOptInStatus(userEmail, callback) {
      this.GetAuthToken((success) => {
        if (success) {
          axios.post(this.config.urlPrefix + this.config.getoptinstatusUrl, this.ObjectToQueryString({
            email: userEmail,
            brands: this.globals.siteId,
          }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `bearer ${this.config.access_token}` } })
            .then((response) => {
              if (callback) {
                const resp = JSON.parse(response.data);
                // eslint-disable-next-line eqeqeq
                if (resp.results && resp.results.success) { resp.results.success = (resp.results.success === 'true'); }
                callback.call(this, resp);
              }
            })
            .catch(() => {
              if (callback) {
                callback.call(this, { results: { success: false } });
              }
            });
        }
      });
    },

    isCheckBoxOnPage() {
      if (this.$el.querySelectorAll('.market-communication.form-group')[0]) {
        this.firstFormElementChild = this.$el.querySelectorAll('.market-communication.form-group')[0].getBoundingClientRect();
        this.intervalID = setInterval(this.checkFormPos, 5000);
      }
    },

    checkFormPos() {
      let inview = '';
      const elementTopY = this.firstFormElementChild.top;
      const elementBottomY = this.firstFormElementChild.top + this.firstFormElementChild.height;
      inview = elementTopY >= 0 && elementBottomY <= document.documentElement.clientHeight + document.documentElement.scrollTop;

      if (inview) {
        this.optinAnalyticsHandler('impression');
        clearInterval(this.intervalID);
      }
    },

    handleSendFormDataResponse(response) {
      const data = JSON.parse(response.data);
      if (!data.results.success) {
        if (data.results.error.length > 0) {
          this.handleSendFormDataError(data.results.error); // .join('</br>')
        } else {
          this.handleSendFormDataError();
        }
        return;
      }

      let cpUserId = '';
      if (data.results.cpuserid) {
        cpUserId = data.results.cpuserid;
        // set cookie to expire in 10 years (HL)
        const CookieDate = new Date();
        CookieDate.setFullYear(CookieDate.getFullYear() + 10);
        document.cookie = `cpUserId=${cpUserId};expires=${CookieDate.toUTCString()}; path=/`;
      }
    },
  },

  created() {
    const util = {
      parseQueryString: () => {
        if (window.location.search.length > 0) {
          const q = window.location.search.substring(1).split('&');
          if (q) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < q.length; i += 1) {
              // eslint-disable-next-line one-var
              const P = q[i].split('='),
                key = decodeURIComponent(P[0]),
                value = decodeURIComponent(P[1]);
              this.queryString[key.toLowerCase()] = value;
            }
          }
        }
      },
    };
    util.parseQueryString();
  },
};

export default optInMixin;
