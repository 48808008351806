import globals from '../globals';

const pardotMixin = {
  data() {
    return {
      globals,
    };
  },
  mounted() {
    if (this.globals.siteConfig.pardotPiAId) {
      let otTimer = {};
      const self = this;
      const otChecker = function() {
        if (!(window.OnetrustActiveGroups == null)) {
          clearInterval(otTimer);
          const isCookiesBlocked = !globals.checkIsOpted();
          const pdScript = document.createElement('script');
          if (isCookiesBlocked) {
            pdScript.setAttribute('type', 'text/plain');
          } else {
            pdScript.setAttribute('type', 'text/javascript');
          }
          pdScript.setAttribute('class', 'optanon-category-4');
          // eslint-disable-next-line prefer-template
          const pdInnerText = document.createTextNode("piAId = '" + self.globals.siteConfig.pardotPiAId + "';\npiCId = '';\npiHostname = '" + self.globals.siteConfig.pardotPiHostname + "';\n\n(function() {\n    function async_load() {\n        var s = document.createElement('script');\n       s.type = 'text/javascript';\n         s.src = ('https:'===document.location.protocol ? 'https://' : 'http://') + '" + self.globals.siteConfig.pardotPiHostname + "' + '/pd.js';\n        var c = document.getElementsByTagName('script')[0];\n        c.parentNode.insertBefore(s, c);\n    }\n    if (window.attachEvent) {\n        window.attachEvent('onload', async_load);\n    } else {\n        window.addEventListener('load', async_load, false);\n    }\n})();");
          pdScript.appendChild(pdInnerText);
          const targetScriptTag = document.getElementsByTagName('BODY');
          if (targetScriptTag && targetScriptTag.length > 0) { targetScriptTag[0].appendChild(pdScript); }
        }
      };
      otTimer = setInterval(otChecker, 500);
      // Set pardot form height dynamically based on the content
      window.addEventListener('message', (event) => {
        const iframeContent = document.querySelector('iframe[src*="pardot"]');
        if (typeof event.data === 'object' && event.data.type === 'iframeHeight') {
          const height = event.data.formHeight;
          if (height >= 500) {
            iframeContent.style.height = `${height + 55}px`;
          }
        }
      });
    }
  },
};

export default pardotMixin;
