var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lazy-component", [
    _c(
      "div",
      {
        staticClass: "vx-uiux-performance-text d-flex flex-wrap",
        class: [
          {
            "flex-sm-row-reverse":
              _vm.uiuxImageTileData.imageAlignment === "right",
            "no-image":
              !_vm.uiuxImageTileData.imageSrcD ||
              _vm.uiuxImageTileData.imageWidth == 0
          },
          _vm.componentTheme
        ],
        attrs: { id: _vm.uiuxImageTileData.componentId }
      },
      [
        _vm.uiuxImageTileData.imageSrcD && _vm.uiuxImageTileData.imageWidth > 0
          ? _c("vx-image-tile", {
              staticClass: "col-xs-12",
              attrs: {
                "text-tile-data": _vm.uiuxTextTileData,
                "image-tile-data": _vm.uiuxImageTileData
              }
            })
          : _vm._e(),
        _vm._v(" "),
        (_vm.uiuxTextTileData.text ||
          _vm.uiuxTextTileData.ctaText ||
          _vm.uiuxTextTileData.text) &&
        100 - _vm.uiuxImageTileData.imageWidth > 0
          ? _c("vx-text-tile", {
              attrs: { "text-tile-data": _vm.uiuxTextTileData }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f5b081e0", { render: render, staticRenderFns: staticRenderFns })
  }
}