var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row cart-page", staticStyle: { margin: "40px" } },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-md-8 " },
        [
          _c("vx-shopping-cart", {
            attrs: { i18n: _vm.messages.manageShoppingCart.shoppingCart }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-xs-12 col-md-4",
          staticStyle: { "margin-top": "71px" }
        },
        [
          _c("vx-order-summary", {
            attrs: { i18n: _vm.messages.manageShoppingCart.orderSummary }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xs-12" },
        [
          _c("vx-empty-cart", {
            attrs: { i18n: _vm.messages.manageShoppingCart.emptyShoppingCart }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-741505a4", { render: render, staticRenderFns: staticRenderFns })
  }
}