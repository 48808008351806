/**
 * Blue universal button
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-cta-button',
  components: {},
  props: {
    ctaButtonData: {
      type: Object,
    },
    componentTheme: {
      type: String,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
    };
  },
  computed: {},
  mounted() {
    if (this.ctaButtonData.isExternalLink === true) {
      this.$el.target = '_blank';
    }
  },
  methods: {},
};
