var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-upsell-carousel" }, [
    _vm.upsellProducts.length > 0
      ? _c(
          "div",
          [
            _c("vx-slider", {
              attrs: {
                "product-data": _vm.upsellProducts,
                title: _vm.messages.pdpTabContainer.upsellCarouselHeading,
                "is-favorites": _vm.isFavorites,
                "slider-slides": _vm.sliderSlides,
                "is-bazaar-voice": _vm.isBazaarVoice,
                "search-browse-i18n": _vm.i18n
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6d683532", { render: render, staticRenderFns: staticRenderFns })
  }
}