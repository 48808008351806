import globals from '../../../../components/common/globals';
import CommonService from '../../../../components/common/services/common-service';
import AnalyticsService from '../../../../components/common/services/analytics-service';
import vxAutoSuggest from '../../search-browse/vx-auto-suggest/vx-auto-suggest.vue';
import vxDropdownPrimary from '../../../../components/common/vx-dropdown-primary/vx-dropdown-primary.vue';
import headerMixin from '../../../../components/common/mixins/header-mixin';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import vxMiniCart from '../../manage-shopping-cart/vx-mini-cart/vx-mini-cart.vue';
import vxLeftNav from '../../manage-profile-shopping-lists/vx-left-nav/vx-left-nav.vue';
import ManageProfileShoppingListService from '../../../../components/common/services/manage-profile-shopping-lists-service';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import cookiesMixin from '../../../../components/common/mixins/cookies-mixin';
import { cookies } from '../../../../components/common/mixins/vx-enums';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxForgotPassword from '../../registration-login/vx-forgot-password/vx-forgot-password.vue';
import registrationLogin from '../../../../locale/gp-pro/registration-login-i18n';
import { globalEventBus, cartEventBus } from '../../../../modules/event-bus';

/**
 * Header section
 */
export default {
  name: 'vx-header-section',
  mixins: [headerMixin, mobileMixin, cookiesMixin, flyoutBannerMixin],
  props: {
    // Header data including categories
    headerData: {
      type: Object,
      required: true,
    },
    // Text and label from properties file
    i18n: {
      type: Object,
      required: true,
    },
    // Flag to enable or disable the favourites option
    isFavoritesEnabled: {
      type: Boolean,
      default: false,
    },
    // Flag to know if the page is home page
    isHomePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      globals,
      showMobileNav: false,
      selectedPrimary: '',
      cartItems: 0,
      cartId: '',
      screenWidth: window.screenwidth,
      desktopWidthMin: 1200,
      userName: '',
      showProfile: false,
      isProfileHovered: false,
      profileTimeout: 250,
      profileTimer: {},
      showMiniCart: false,
      isMiniCartHovered: false,
      miniCartTimeout: 250,
      miniCartTimer: {},
      tabletView: false,
      toggleSearchIcon: false,
      cookies,
      windowWidth: window.innerWidth,
      manageProfileShoppingListService: new ManageProfileShoppingListService(),
      commonService: new CommonService(),
      analyticsService: new AnalyticsService(),
      isPromoEnabled: false,
      b2bUnitProfilesList: [],
      b2bUnitSelected: {},
      prevSelectedB2bUnit: {},
      presentSelectedB2bUnit: {},
      unitHeaderLogo: '',
      showSearch: true,
      showLoginModal: false,
      shownLoginForm: 'gppro',
      showGpProPassword: false,
      showGpExpressPassword: false,
      token: '',
      registrationLogin,
      gpProLoginUsername: null,
      gpProLoginRememberMe: false,
      gpExpressLoginUsername: null,
      gpExpressLoginRememberMe: false,
      loginMenuItems: [],
    };
  },
  computed: {
    // Configurations for header (includes flags)
    headerOptions() {
      return this.headerData.headerOptions;
    },
    // Login menu data in header data
    loginMenu() {
      /*
        if user is logged in and in mobile, we need to display menu options such as profile & preferences, address, payment methods etc
      */
      if (this.showMobileNav && this.globals.getIsLoggedIn()) {
        if (this.loginMenuItems.length === 0) {
          const initialData = [...this.headerData.loginMenu.menuOptions];
          //  the data available is not in the expected format, so we change it here
          const constructedData = this.headerData.accountMenu[0].links.map((item) => ({
            option: item.linkText,
            optionLink: item.linkTo,
            external: false,
            markAsViewAll: false,
            socialLink: false,
          }));
          // the name at 0th index should be first
          const resultantData = [initialData[0], ...constructedData];
          initialData.shift();
          // followed by menu options and the remaining of the initialData array
          this.loginMenuItems = [...resultantData, ...initialData];
        }
      } else {
        this.loginMenuItems = [...this.headerData.loginMenu.menuOptions];
      }
      return this.loginMenuItems;
    },
    // Categories data in header data
    navMenu() {
      return this.headerData.navMenu;
    },
    // Find store/distributor menu data
    findMenu() {
      return this.headerData.findMenu;
    },
    // Checkout header data
    checkoutMenu() {
      return this.headerData.headerOptions.checkoutMenu;
    },
  },
  components: {
    vxAutoSuggest,
    vxMiniCart,
    vxLeftNav,
    vxSpinner,
    vxModal,
    vxDropdownPrimary,
    vxForgotPassword,
  },
  methods: {
    /* eslint no-undef: "error" */

    /**
         * Generate dropdown list for profiles
         */
    b2bUnitsDropDownValues() {
      if (this.headerData.headerOptions.userProfiles) {
        this.headerData.headerOptions.userProfiles.forEach((item, index) => {
          this.$set(this.b2bUnitProfilesList, index, {
            label: item.name,
            value: item.id,
          });
          if (this.globals.userInfo.unit === item.id) {
            this.b2bUnitSelected = {
              label: item.name,
              value: item.id,
            };
            if (this.globals.isEStores && this.$refs && this.$refs.b2bUnitsDropdown) {
              this.$refs.b2bUnitsDropdown.setDropdownLabel(item.name);
              this.unitHeaderLogo = item.distributorImage;
            }
          }
        });
      }
      return this.b2bUnitProfilesList;
    },
    /**
         * Hanldes dropdown selection and set value on scope, make API call to switch profiles
         * @param {Object} event - selecgted profile object
         */
    handleSelectedUnit(event) {
      if (event.value === this.b2bUnitSelected.value) {
        return;
      }
      this.prevSelectedB2bUnit = { ...this.b2bUnitSelected };
      this.b2bUnitSelected = event;
      this.$refs.b2bUnitsDropdown.setDropdownLabel(this.b2bUnitSelected.label);
      this.switchB2bUnitProfiles(event.value);
    },
    /**
         * Set default dropdow value
         */
    handleDefaultUnit() {
      this.$refs.b2bUnitsDropdown.setDropdownLabel(this.b2bUnitSelected.label);
    },
    /**
         * Make call to Switch profile rest service
         * @param {String} selectedUnit
         * @param {Boolean} clearCart
         */
    switchB2bUnitProfiles(selectedUnit, clearCart) {
      const requestConfig = {};
      const requestParams = {
        cartId: this.cartId,
        clearCart,
        unitUid: selectedUnit,
      };
      this.commonService.switchB2bUnitProfiles(
        requestConfig,
        this.handleSwitchProfilesResponse,
        this.handleSwitchProfilesError,
        requestParams,
      );
    },
    /**
         * Handles profile switching response
         * @param {Object} response - Success response
         */
    handleSwitchProfilesResponse() {
      globals.navigateToUrl('home');
    },
    /**
         * Handles profile switching error reponse
         * @param {Object} error
         */
    handleSwitchProfilesError(error) {
      this.presentSelectedB2bUnit = { ...this.b2bUnitSelected };
      this.b2bUnitSelected = this.prevSelectedB2bUnit;
      this.$refs.b2bUnitsDropdown.setDropdownLabel(
        this.prevSelectedB2bUnit.label,
      );
      if (
        error.response.data.errors
                && error.response.data.errors[0].code === '9110'
      ) {
        this.$refs.profileSwitchModal.open();
      }
    },
    /**
         * Hanldes clearing cart of current profile
         */
    clearCart() {
      this.switchB2bUnitProfiles(this.presentSelectedB2bUnit.value, true);
    },
    /**
         * Dynamically sets the height for hamburger menu
         */
    setHeaderHeight() {
      if (this.showMobileNav) {
        setTimeout(() => {
          this.$el.querySelector(
            '.header-left-section .mobile-view',
          ).style.height = `${window.innerHeight - 52}px`;
        }, 400);
      }
    },
    /**
         * Closes the hamburger menu
         */
    dismissMobileMenu() {
      if (this.screenWidth <= this.desktopWidthMin) {
        this.showMobileNav = !this.showMobileNav;
      }
    },
    /**
         * Updates the accessibility related attributes
         * @param {boolean} isExpand Indicates if the hamburger is displayed or hidden
         */
    updateAria(isExpand) {
      if (isExpand) {
        /* if(document.querySelector('.navbar-bottom-right')){
                    document
                        .querySelector('.navbar-bottom-right')
                        .setAttribute('aria-hidden', true);
                } */
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', true);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', true);
        }
      } else {
        /* if(document.querySelector('.navbar-bottom-right')){
                    document
                        .querySelector('.navbar-bottom-right')
                        .setAttribute('aria-hidden', false);
                } */
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', false);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', false);
        }
      }
    },
    /**
         * Displays the secondary category menu and updates the accessibility related attributes for hamburger menu
         * @param {string} selectedItem Primary category item selected by the user
         */
    selectPrimary(selectedItem) {
      this.selectedPrimary = selectedItem;
      const self = this;
      if (this.screenWidth <= this.desktopWidthMin) {
        setTimeout(() => {
          self.$refs.navigationMenu.scrollTop = 0;
          document.querySelector('.js-secondary-action-el')
            .focus();
        }, 150);
      }
      if (this.selectPrimary !== '') {
        if (document.querySelector('.navbar-bottom-right')) {
          document
            .querySelector('.navbar-bottom-right')
            .setAttribute('aria-hidden', true);
        }
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', true);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', true);
        }
      } else {
        if (document.querySelector('.navbar-bottom-right')) {
          document
            .querySelector('.navbar-bottom-right')
            .setAttribute('aria-hidden', false);
        }
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', false);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', false);
        }
      }
    },
    /**
         * Checks if secondary categories are available for a primary category and returns boolean
         * @param {string} primaryItem Primary category item selected by the user
         */
    isSecondaryAvailable(primaryItem) {
      return primaryItem.secondaryList && primaryItem.secondaryList.length > 0;
    },
    /**
         * Sets focus to primary category item in hamburger when returning from secondary menu
         * @param {string} el Primary category item
         * @param {object} menu Categories menu
         */
    focusParent(el, menu) {
      this.selectedPrimary = '';
      for (const i in menu) {
        if (menu[i].primary === el) {
          setTimeout(() => {
            document
              .querySelectorAll('.primary-menu-item')[i].childNodes[0].focus();
          }, 100);
        }
      }
    },
    /**
         * The below method is used to get the parent
         * element with specific class
         * @param {*the current element} el
         * @param {*the target class } cls
         */
    findAncestor(el, cls) {
      while ((el = el.parentElement) && !el.classList.contains(cls));
      return el;
    },
    returnItemsImages(items) {
      const images = [];
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].imageData) {
          const { imageData } = items[i];
          imageData.link = items[i].ctaLink;
          imageData.isExternalLink = items[i].external;
          images.push(imageData);
        }
      }
      return images;
    },
    /**
         * Method to display user profile on hovering on user icon in header
         */
    displayProfile() {
      clearTimeout(this.profileTimer);
      this.showProfile = true;
    },
    /**
         * Method to hide user profile on hovering out of the user profile icon
         */
    hideProfile() {
      this.profileTimer = window.setTimeout(() => {
        if (!this.isProfileHovered) {
          this.showProfile = false;
        }
      }, this.profileTimeout);
    },
    /**
         * Handles the window resize
         */
    handleResize() {
      if (window.innerWidth !== this.windowWidth) {
        this.tabletView = this.isTablet();
        this.windowWidth = window.innerWidth;
        if (this.windowWidth < 1200) {
          this.showSearch = true;
        }
      }
    },
    /**
         * Handles the navigation to lists landing page
         */
    navigateTomyLists() {
      if (this.globals.loggedIn || !this.globals.siteConfig.isGuestList) {
        this.globals.navigateToUrl('myList');
      } else {
        const wuid = this.readCookie(cookies.guestListUid) ? this.readCookie(cookies.guestListUid) : '';
        window.location = `${this.globals.getNavigationUrl('listDetails')}/?listName=${wuid}`;
      }
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch && this.windowWidth >= 1200) {
        setTimeout(() => {
          jQuery('.autosuggest-input')[0].focus();
        }, 100);
      }
    },
    toggleLoginModal() {
      this.showLoginModal = !this.showLoginModal;
    },
    openGpProLoginModal() {
      if (this.showLoginModal === true && this.shownLoginForm === 'gppro') {
        this.showLoginModal = false;
      } else if (this.showLoginModal === true && this.shownLoginForm === 'gpexpress') {
        this.shownLoginForm = 'gppro';
      } else {
        this.showLoginModal = true;
        this.shownLoginForm = 'gppro';
      }
    },
    openGpExpressLoginModal() {
      if (this.showLoginModal === true && this.shownLoginForm === 'gpexpress') {
        this.showLoginModal = false;
      } else if (this.showLoginModal === true && this.shownLoginForm === 'gppro') {
        this.shownLoginForm = 'gpexpress';
      } else {
        this.showLoginModal = true;
        this.shownLoginForm = 'gpexpress';
      }
    },
    showLoginForm(form) {
      this.shownLoginForm = form;
    },
    toggleGpProPassword() {
      this.showGpProPassword = !this.showGpProPassword;
      if (this.showGpProPassword) {
        this.$refs.gpproPassword.type = 'text';
      } else {
        this.$refs.gpproPassword.type = 'password';
      }
    },
    toggleGpExpressPassword() {
      this.showGpExpressPassword = !this.showGpExpressPassword;
      if (this.showGpExpressPassword) {
        this.$refs.gpexpressPassword.type = 'text';
      } else {
        this.$refs.gpexpressPassword.type = 'password';
      }
    },
    handleForgotPassword(callingParent) {
      this.$refs.forgotPasswordModal.open(callingParent);
    },
    setLoginUsernames() {
      if (localStorage) {
        this.gpProLoginUsername = window.localStorage.getItem('gpProLoginUsername');
        if (this.gpProLoginUsername) {
          this.gpProLoginRememberMe = true;
        }
        this.gpExpressLoginUsername = window.localStorage.getItem('gpExpressLoginUsername');
        if (this.gpExpressLoginUsername) {
          this.gpExpressLoginRememberMe = true;
        }
      }
    },
    getLoginContainerVisibility() {
      const asmElement = document.querySelector('[id=_asm]');
      return !this.globals.getIsLoggedIn() && (asmElement === null);
    },
    /* Method to hide dropdown menu if user hits escape key */
    hideDropDownMenu() {
      const secondaryMenu = this.$el.querySelectorAll('.secondary-menu');
      for (const element of secondaryMenu) {
        element.style.visibility = 'hidden';
      }
    },
    /* Method to show dropdown menu when user hovers or tabs over primary navigation links */
    displayDropDownMenu() {
      const secondaryMenu = this.$el.querySelectorAll('.secondary-menu');
      for (const element of secondaryMenu) {
        element.style.visibility = 'visible';
      }
    },
  },
  mounted() {
    this.clearKauthData();
    this.tabletView = this.isTablet();
    this.b2bUnitProfilesList = this.b2bUnitsDropDownValues();
    this.isPromoEnabled = !(this.globals.siteConfig.isFindAStoreEnabled || this.globals.siteConfig.isFindADistributorEnabled || this.globals.siteConfig.isReferAFriend);
    this.loginAreaLabel = globals.getIsLoggedIn()
      ? this.i18n.loginSectionTitle
      : this.i18n.loginHeading;
    this.userName = this.headerData.loginMenu.menuOptions.length
      ? this.headerData.loginMenu.menuOptions[0].option
      : '';
    cartEventBus.$on('total-items-updated', (items, cartId) => {
      this.cartItems = items;
      this.cartId = cartId;
    });
    globalEventBus.$emit('is-checkout', this.headerData.headerOptions.isCheckout);
    globalEventBus.$on('updated-name', (val) => {
      this.userName = val;
    });
    if (this.windowWidth < 1200) {
      this.showSearch = true;
    }
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.setLoginUsernames();
    // Save our login form usernames on login submissions
    const self = this;
    if (localStorage) {
      const gpProLoginForm = document.querySelector('.gp-pro-login');
      const gpExpressLoginForm = document.querySelector('.gp-express-login');
      if (gpProLoginForm !== null) {
        gpProLoginForm.addEventListener('submit', () => {
          const usernameToSave = self.gpProLoginUsername && self.gpProLoginRememberMe ? self.gpProLoginUsername : '';
          window.localStorage.setItem('gpProLoginUsername', usernameToSave);
        });
      }
      if (gpExpressLoginForm !== null) {
        gpExpressLoginForm.addEventListener('submit', () => {
          const usernameToSave = self.gpExpressLoginUsername && self.gpExpressLoginRememberMe ? self.gpExpressLoginUsername : '';
          window.localStorage.setItem('gpExpressLoginUsername', usernameToSave);
        });
      }
    }
    // Display success message if the user logins successfully
    setTimeout(() => {
      if (self.readCookie('guid_user') === 'current') {
        if (!self.readCookie('login_success')) {
          const loginMethod = 'GPPRO';
          this.showFlyout('success', this.i18n.loginSuccessMessage, true);
          self.createCookie('login_success', 'Yes');
          this.analyticsService.trackLoginGA4(loginMethod);
          // Send a new 'action:login' to reCAPTCHA
          if (self.globals.siteConfig.isReCaptchaEnabled && window.grecaptcha) {
            window.grecaptcha.ready(() => {
              window.grecaptcha.execute(self.globals.reCaptchaSiteKey, { action: 'login' });
            });
          }
        }
      } else if (self.readCookie('guid_user') === 'anonymous') {
        self.eraseCookie('login_success');
      }
    }, 1500);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  filters: {
    maxCharacters(value) {
      const charLimit = 120;
      return value.substring(0, charLimit);
    },
    lowercase(value) {
      return value.replace(/\W+/g, '-').toLowerCase();
    },
  },
};
