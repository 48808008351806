var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "purchasing-options-tab",
      attrs: { id: "purchasing-options-tab" }
    },
    [
      _c(
        "div",
        { staticClass: "purchasing-options-tab__container tab-content" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "purchase-section" }, [
            _c("h3", { staticClass: "sub-heading blue-text" }, [
              _vm._v("FIND A DISTRIBUTOR")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                If you are interested in purchasing GP PRO products through a distributor but do not currently partner\n                with one, we can help find a distributor that best fits your needs. Please click below to answer a few\n                requisite questions.\n            "
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "margin-50 add-to-cart-btn form-blue-button",
                  attrs: { "aria-label": "FIND A DISTRIBUTOR" },
                  on: { click: _vm.handleFindDistributor }
                },
                [
                  _vm._v(
                    "\n                    FIND A DISTRIBUTOR\n                "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "sub-heading gray-text" }, [
      _vm._v("NO MATTER HOW YOU BUY, "),
      _c(
        "a",
        {
          staticClass: "heading-anchor",
          attrs: { href: "/gp/contact-us", target: "_blank" }
        },
        [_vm._v("WE ARE HERE TO HELP")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "purchase-section" }, [
      _c("h3", { staticClass: "sub-heading blue-text" }, [
        _vm._v("BUY ONLINE")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "If you would like to purchase directly from GPPRO.com, click Add to Cart \n                and\n                proceed to checkout. GP PRO direct customers can create an account to view and purchase our latest\n                products, access past orders, create product lists, and more."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "purchase-section" }, [
      _c("h3", { staticClass: "sub-heading blue-text" }, [
        _vm._v("WARRANTY AND REPLACEMENTS")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          " You do not need to purchase dispenser replacement on your own if your product is under warranty. The\n                GP\n                PRO dispenser warranties apply to defects in workmanship and materials under normal use with conforming\n                Georgia-Pacific products."
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                The GPXpress® Support Team is your full-service partner throught the lifetime of your product, whether\n                you purchase direct or through a distributor. Contact us to assist with replacements at 1-866-435-5647\n                or "
        ),
        _c(
          "a",
          {
            staticClass: "large-anchor",
            attrs: { href: "mailto:gpxpress@gapac.com" }
          },
          [_vm._v(" gpxpress@gapac.com ")]
        ),
        _vm._v(".\n            ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "last-section" }, [
      _c("p", [
        _vm._v("\n                Have questions? "),
        _c(
          "a",
          {
            staticClass: "large-anchor",
            attrs: { href: "/gp/contact-us", target: "_blank" }
          },
          [_vm._v("\n                    Contact Us!\n                ")]
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-23dbbd4d", { render: render, staticRenderFns: staticRenderFns })
  }
}