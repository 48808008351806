var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-related-products-tab" }, [
    _c(
      "div",
      { staticClass: "row related-products-tab-row" },
      _vm._l(_vm.relatedProductsOrderedData, function(item) {
        return item.referenceType !== "FREQUENTLYBOUGHTTOGETHER" &&
          item.referenceType !== "COMPARE_PRODUCTS"
          ? _c(
              "div",
              { staticClass: "tile-group d-flex flex-column col-sm-12" },
              [
                _vm.isRelatedEnabled
                  ? _c(
                      "h3",
                      {
                        staticClass:
                          "vx-related-products-tab__topleftheading subheadings",
                        attrs: { "aria-hidden": !_vm.isRelatedEnabled }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.referenceType) +
                            "\n            "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap", attrs: { role: "list" } },
                  [
                    _vm.isRelatedEnabled
                      ? _vm._l(item.references, function(product, index) {
                          return _c("vx-product-tile-vertical", {
                            key: product.target.code + index,
                            attrs: {
                              product: product.target,
                              uncheckProduct: _vm.uncheckProduct,
                              showRemoveOption: _vm.showRemoveOption,
                              showCompareOption: _vm.showCompareOption,
                              "is-favorite-visible": _vm.isFavorites,
                              "is-bazaar-voice": _vm.isBazaarVoice,
                              i18n: _vm.i18n,
                              showCertifications: true
                            }
                          })
                        })
                      : _vm._l(_vm.relatedProducts, function(product, index) {
                          return _c("vx-product-tile-vertical", {
                            key: product.code + index,
                            attrs: {
                              product: product,
                              uncheckProduct: _vm.uncheckProduct,
                              showRemoveOption: _vm.showRemoveOption,
                              showCompareOption: _vm.showCompareOption,
                              "is-favorite-visible": _vm.isFavorites,
                              "is-bazaar-voice": _vm.isBazaarVoice,
                              i18n: _vm.i18n,
                              showCertifications: true
                            }
                          })
                        })
                  ],
                  2
                )
              ]
            )
          : _vm._e()
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-25e7777b", { render: render, staticRenderFns: staticRenderFns })
  }
}