/* Handles leasable section on checkout page */
import filter from 'lodash.filter';
import sumBy from 'lodash.sumby';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import vxReviewLeaseAgreement from '../vx-review-lease-agreement/vx-review-lease-agreement.vue';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import globals from '../../common/globals';
import { checkoutEventBus } from '../../../modules/event-bus';
import CheckOutService from '../../common/services/checkout-service';
import checkoutTrackingMixin from '../../common/mixins/checkout-tracking-mixin';

export default {
  name: 'vx-lease-agreement',
  components: {
    vxModal,
    vxSpinner,
    vxReviewLeaseAgreement,
  },
  mixins: [checkoutTrackingMixin],

  props: {
    /**
         * Labels, button and caption texts
         */
    i18n: {
      type: Object,
    },
    /**
         * Contains leasable products from checkout
         */
    checkoutData: Object,
    country: Array,
    sectionIndex: Number,
    countryQuantity: Array,
  },
  data() {
    return {
      leasableItems: [],
      globals,
      checkOutService: new CheckOutService(),
      isSaved: false,
      isEditable: true,
      quantity: 0,
      currentDate: '',
      leasableProductRequestData: [],
      leaseAccepted: true,
      currentCountry: Object,
    };
  },
  computed: {},
  mounted() {
    checkoutEventBus.$on('update-lease-products', (checkoutData) => {
      this.updateData(checkoutData);
    });
    this.leasableItems = filter(this.checkoutData.entries, {
      leasable: true,
    });
    this.quantity = sumBy(this.leasableItems, 'quantity');
    const todayDate = new Date();
    this.currentDate = todayDate.toISOString()
      .substring(0, 10);
    if (globals.isB2B()) {
      this.sendEventToDataLayer('checkout', 3, 'Leasing Agreement');
    }
  },
  methods: {
    updateData(checkoutData) {
      this.leasableItems = filter(checkoutData.entries, {
        leasable: true,
      });
    },
    /**
         * Onclicking of review button openes the terms and conditions modal
         * @param {Object} event of the button
         * @param {Object} countryVal contains the selecte country details
         * @param {Integer} currentIndex containts the current country Index
         */
    reviewAgreement(event, countryVal, currentIndex) {
      this.currentIndex = currentIndex;
      this.currentCountry = countryVal;
      this.$refs.viewTermsModal.open(event);
      if (globals.isB2B()) { this.sendEventToDataLayer('checkout_option', 3, 'Review lease agreement'); }
    },
    // editMethod(event) {
    //   this.isSaved = !this.isSaved;
    //   this.isEditable = !this.isEditable;
    //   checkoutEventBus.$emit('section-edit', 'leaseAgreement');
    //   this.showSaveButton = false;
    // },
    /**
         * Toggles the text from french to English
         * @param {Array} leaseInfo contains the lease informatiion
         */
    toggleViewMode(leaseInfo) {
      // this.$refs.viewTermsModal.close();
      if (leaseInfo.agreementDecision) {
        this.$refs.spinner.showSpinner();
        for (let i = 0; i < this.leasableItems.length; i += 1) {
          this.leasableProductRequestData.push({
            materialNumber: this.leasableItems[i].product.code,
            materialDescription: this.leasableItems[i].product.name,
            quantity: this.leasableItems[i].quantity,
            unit: this.leasableItems[i].product.unit,
          });
        }
        const data = {
          orderDate: this.currentDate,
          leaseTermsID: leaseInfo.id,
          agreementID: `${this.currentCountry.isocode}_${
            this.checkoutData.code
          }`,
          material: this.leasableProductRequestData,
        };
        // Makes a call to save the lease Agreement
        this.checkOutService.saveLeaseAgrement(
          {},
          this.handleSaveLeaseAgrementResponse,
          this.handleSaveLeaseAgrementError,
          data,
        );
        this.$refs.spinner.showSpinner();
      } else {
        this.$set(this.country, this.currentIndex, {
          ...this.country[this.currentIndex],
          leaseAccepted: false,
        });
        this.isReviewAccepted();
        this.$refs.viewTermsModal.close();
      }
    },
    /**
         * Handles the error of saveLeaseAgrement
         * @param {Object} error
         */
    handleSaveLeaseAgrementError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * Handles the response of saveLeaseAgrement
         * @param {Object} response contains the success response of saveLeaseAgrement
         */
    handleSaveLeaseAgrementResponse(response) {
      this.$refs.spinner.hideSpinner();
      this.leaseAgrementData = response.data;
      this.$set(this.country, this.currentIndex, {
        ...this.country[this.currentIndex],
        leaseAccepted: true,
      });
      this.isReviewAccepted();
      this.$refs.viewTermsModal.close();
    },
    saveSection() {
      const self = this;
      this.isSaved = true;
      this.isEditable = false;
      checkoutEventBus.$emit('section-complete', 'leaseAgreement');
      // Send a new 'action:lease' to reCAPTCHA
      if (self.globals.siteConfig.isReCaptchaEnabled && window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(self.globals.reCaptchaSiteKey, { action: 'lease' });
        });
      }
      if (globals.isB2B()) {
        this.sendEventToDataLayer('checkout_option', 3, 'Save and Continue');
      }
    },
    // On accepting terms and conditions closes the modal
    acceptedTerms() {
      this.$refs.viewTermsModal.close();
    },
    // Return the sum of country by its quantity
    getQuantity(country) {
      return sumBy(this.countryQuantity, country);
    },
    /**
         * Used for checking if review is accpeted or not
         */
    isReviewAccepted() {
      const filteredData = this.country.filter((item) => !item.leaseAccepted);
      this.leaseAccepted = filteredData.length > 0;
    },
  },
};
