var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-multi-shipping-address" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addNewAddressModal",
          attrs: {
            size: "large",
            heading: _vm.i18n.shippingAddress.addAddress,
            "is-modal-scrollable": _vm.modalScrollable
          }
        },
        [
          _c(
            "vx-add-edit-address",
            {
              ref: "addressForm",
              attrs: {
                slot: "component",
                submitButtonText: _vm.i18n.shippingAddress.saveAddress,
                i18n: _vm.i18n.address,
                "pallet-shipment": _vm.palletShipment,
                editAddress: {},
                "is-bussiness-unit": _vm.globals.isB2B()
              },
              on: { onAddressSaved: _vm.saveThisAddress },
              slot: "component"
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("div", { staticClass: "section-header mb-xs-3" }, [
                  _vm._v(_vm._s(_vm.i18n.address.shippingAddressModal))
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSaved,
              expression: "!isSaved"
            }
          ],
          staticClass: "mt-xs-5"
        },
        [
          _vm._l(_vm.visibleEntries, function(productEntry) {
            return _c(
              "vx-product-tile",
              {
                key: productEntry.product.name,
                attrs: { slot: "component" },
                slot: "component"
              },
              [
                productEntry.product &&
                productEntry.product.images &&
                productEntry.product.images.length !== 0 &&
                productEntry.product.images[2] &&
                productEntry.product.images[2].url
                  ? _c("template", { slot: "image-slot" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.globals.getNavigationUrl("empty") +
                              productEntry.product.url
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.globals.getThumbnailImageUrl(
                                productEntry.product.images
                              ),
                              alt: productEntry.product.images[2].altText
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !productEntry.product.images
                  ? _c("template", { slot: "image-slot" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.globals.getNavigationUrl("empty") +
                              productEntry.product.url
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.globals.assetsPath + "images/no_image.svg",
                              alt: "No Image"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "details" },
                  [
                    productEntry.product.name
                      ? _c("p", { staticClass: "product-title mb-xs-2" }, [
                          _c("a", {
                            attrs: {
                              href:
                                _vm.globals.getNavigationUrl("empty") +
                                productEntry.product.url
                            },
                            domProps: {
                              innerHTML: _vm._s(productEntry.product.name)
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(
                        _vm._s(_vm.i18n.shippingAddress.itemID) +
                          " : " +
                          _vm._s(productEntry.product.code)
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "product-code mb-xs-2 label-text",
                        attrs: { "aria-hidden": "true" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.i18n.shippingAddress.itemID) +
                            "\n                    " +
                            _vm._s(productEntry.product.code) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    productEntry &&
                    productEntry.product &&
                    productEntry.product.materialStatus ===
                      _vm.ProductAvailability.OBSOLETE &&
                    productEntry.product.replacementProductCode &&
                    productEntry.product.replacementProductCode !== ""
                      ? _c("p", { staticClass: "error-text" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.i18n.shippingAddress.discontinued) +
                              " " +
                              _vm._s(_vm.i18n.shippingAddress.replacedBy) +
                              "\n                    " +
                              _vm._s(
                                productEntry.product.replacementProductCode
                              ) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    productEntry &&
                    productEntry.product &&
                    productEntry.product.materialStatus ===
                      _vm.ProductAvailability.OBSOLETE &&
                    !productEntry.product.replacementProductCode
                      ? _c("p", { staticClass: "error-text" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.i18n.shippingAddress.discontinued) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    productEntry &&
                    productEntry.product &&
                    productEntry.product.materialStatus !==
                      _vm.ProductAvailability.OBSOLETE
                      ? [
                          productEntry.product.stock.stockLevelStatus !=
                            _vm.ProductAvailability.IN_STOCK &&
                          productEntry.product.stock.stockLevelStatus !=
                            _vm.ProductAvailability.LOW_STOCK
                            ? _c(
                                "p",
                                { staticClass: "product-stock mb-xs-3" },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        productEntry.product.stock
                                          .stockLevelStatus
                                      )
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", { staticClass: "product-price mb-xs-3" }, [
                      _c("span", { staticClass: "product-current-price" }, [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Current price")
                        ]),
                        _vm._v(_vm._s(productEntry.basePrice.formattedValue))
                      ]),
                      _vm._v(" "),
                      productEntry.product.weblistPrice &&
                      _vm.globals.siteConfig.isSlashedPriceEnabled
                        ? _c(
                            "span",
                            { staticClass: "product-old-price" },
                            [
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Slashed price")
                              ]),
                              _vm._v(" "),
                              _c("strike", [
                                _vm._v(
                                  _vm._s(
                                    productEntry.product.weblistPrice
                                      .formattedValue
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "product-quantity mb-xs-2 mb-sm-3" },
                      [
                        _vm._v(
                          _vm._s(_vm.i18n.shippingAddress.quantity) +
                            ":\n                    " +
                            _vm._s(productEntry.quantity) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.restrictionError
                      ? [
                          _vm.checkRestricted(productEntry.product.code)
                            ? _c("div", { staticClass: "mt-xs-5" }, [
                                _c(
                                  "span",
                                  { staticClass: "multi-restriction-heading" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.i18n.shippingAddress
                                          .multiRestrictionHeading
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("p", { staticClass: "restriction-error" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.i18n.shippingAddress
                                          .restrictionErrorPart1
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("span"),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.i18n.shippingAddress
                                          .restrictionErrorPart3
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "customer-service" },
                                    [
                                      _vm.globals.isB2B()
                                        ? [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "https://gpxpr.es/",
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.i18n.shippingAddress
                                                        .restrictionErrorPart4
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        : [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: _vm.contactUsUrl,
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.i18n.shippingAddress
                                                        .restrictionErrorPart4
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.i18n.shippingAddress
                                          .restrictionErrorPart5
                                      )
                                    )
                                  ])
                                ])
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.checkRestricted(productEntry.product.code)
                  ? _c("template", { slot: "top-right" }, [
                      _c("span", { staticClass: "col-xs-2 px-xs-0" }, [
                        _c("span", {
                          staticClass: "cart-icons icon-trash",
                          attrs: {
                            title: _vm.i18n.shippingAddress.iconTrashTitle,
                            tabindex: "0",
                            role: "button",
                            "aria-label":
                              _vm.i18n.shippingAddress.iconTrashTitle
                          },
                          on: {
                            click: function($event) {
                              _vm.deleteCartItem(
                                productEntry.entryNumber,
                                productEntry.product.code,
                                $event
                              )
                            }
                          }
                        })
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("template", { slot: "right-section-slot" }, [
                  productEntry &&
                  productEntry.productPromotion &&
                  productEntry.productPromotion.formattedValue &&
                  productEntry.productPromotion.value !=
                    _vm.discount.discountZero
                    ? _c(
                        "p",
                        {
                          staticClass: "product-discount",
                          attrs: { "aria-label": "discount" }
                        },
                        [
                          _vm._v(
                            "-" +
                              _vm._s(
                                productEntry.productPromotion.formattedValue
                              )
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "product-total-price",
                      attrs: {
                        role: "text",
                        "aria-label":
                          "total price" + productEntry.totalPrice.formattedValue
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(productEntry.totalPrice.formattedValue)
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.parentMounted
                  ? _c("template", { slot: "bottom-full-slot" }, [
                      _c(
                        "div",
                        { staticClass: "col-xs-12 px-xs-0" },
                        [
                          _c("vx-split-address", {
                            ref: "splitQtyAddress",
                            refInFor: true,
                            attrs: {
                              i18n: _vm.i18n,
                              splitApplicable: _vm.isSplitApplicable,
                              productDetails: productEntry,
                              entryNumber: productEntry.entryNumber,
                              defaultAddress: _vm.defaultAddress,
                              addresses: _vm.allUserSavedAddresses,
                              isShippingMultiple: _vm.isShippingMultiple
                            },
                            on: {
                              selectfirstAddress: _vm.setAddressForAllEntries,
                              splitError: _vm.showSplitError
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 mt-xs-5 px-xs-0" }, [
            _c("div", { staticClass: "sub-heading mb-xs-2 px-xs-0" }, [
              _vm._v(
                _vm._s(_vm.i18n.shippingAddress.multipleShippingAddressSubText)
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary modal-btn-primary-medium",
                on: {
                  click: function($event) {
                    _vm.toggleViewMode($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.i18n.shippingAddress.useMultipleShippingAddress
                    ) +
                    "\n            "
                )
              ]
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSaved,
              expression: "isSaved"
            }
          ],
          staticClass: "col-xs-12 px-xs-0 d-flex flex-row flex-wrap"
        },
        _vm._l(_vm.uniqueSavedAddresses, function(data, index) {
          return _c(
            "div",
            { staticClass: "mt-xs-4 col-sm-6 col-xs-12 px-xs-0" },
            [
              _c("div", { staticClass: "product-address-heading" }, [
                _c("span", [_vm._v(_vm._s(index + 1) + ".")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.i18n.shippingAddress.shippingAddressHeading)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pt-xs-2 address-container" }, [
                _c("p", { staticClass: "address-view mb-xs-2" }, [
                  _vm._v(
                    _vm._s(data.value.firstName) +
                      " " +
                      _vm._s(data.value.lastName)
                  )
                ]),
                _vm._v(" "),
                data.value.companyName
                  ? _c("p", { staticClass: "address-view mb-xs-2" }, [
                      _vm._v(_vm._s(data.value.companyName))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", { staticClass: "address-view mb-xs-2" }, [
                  _vm._v(_vm._s(data.value.line1))
                ]),
                _vm._v(" "),
                data.value.line2
                  ? _c("p", { staticClass: "address-view mb-xs-2" }, [
                      _vm._v(_vm._s(data.value.line2))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", { staticClass: "address-view mb-xs-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(data.value.town) +
                      " " +
                      _vm._s(data.value.region.isocodeShort) +
                      "\n                    " +
                      _vm._s(data.value.postalCode)
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "address-view mb-xs-2" }, [
                  _vm._v(_vm._s(data.value.country.isocode))
                ]),
                _vm._v(" "),
                data.value.userId && _vm.globals.getIsLoggedIn()
                  ? _c("p", { staticClass: "address-view mb-xs-2" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(data.value.userId.split("|")[0])
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                data.value.userId && !_vm.globals.getIsLoggedIn()
                  ? _c("p", { staticClass: "address-view mb-xs-2" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(data.value.userId.split("|")[1])
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", { staticClass: "address-view mb-xs-2" }, [
                  _vm._v(_vm._s(data.value.phone))
                ])
              ])
            ]
          )
        })
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7e6de475", { render: render, staticRenderFns: staticRenderFns })
  }
}