var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "vx-tab-container row d-flex",
      attrs: { id: "vx-tab-container" }
    },
    [
      _c("div", { staticClass: "px-xs-0 col-xs-12" }, [
        _c(
          "ul",
          {
            staticClass: "nav nav-tabs px-sm-3 px-md-4",
            attrs: {
              id: "tabs",
              role: "tablist",
              "aria-orientation": "horizontal"
            }
          },
          [
            _c(
              "li",
              { staticClass: "tab-item active", attrs: { role: "tab" } },
              [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      id: "tab-details",
                      name: _vm.i18n.tabDetails,
                      "aria-controls": "pane-details",
                      href: "#pane-details",
                      "data-toggle": "tab",
                      role: "tab",
                      tabindex: "0"
                    },
                    on: {
                      keyup: [
                        function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k($event.keyCode, "left", 37, $event.key, [
                              "Left",
                              "ArrowLeft"
                            ])
                          ) {
                            return null
                          }
                          if ("button" in $event && $event.button !== 0) {
                            return null
                          }
                          _vm.leftArrow($event)
                        },
                        function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k($event.keyCode, "right", 39, $event.key, [
                              "Right",
                              "ArrowRight"
                            ])
                          ) {
                            return null
                          }
                          if ("button" in $event && $event.button !== 2) {
                            return null
                          }
                          _vm.rightArrow($event)
                        }
                      ],
                      keydown: _vm.focusedElement
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.i18n.tabDetails) +
                        "\n                "
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.isRelatedProducts && _vm.isRelatedEnabled
              ? _c("li", { staticClass: "tab-item", attrs: { role: "tab" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        id: "tab-related-products",
                        name: _vm.i18n.tabRelatedProducts,
                        "aria-controls": "pane-related-products",
                        href: "#pane-related-products",
                        "data-toggle": "tab",
                        role: "tab",
                        tabindex: "0"
                      },
                      on: {
                        click: function($event) {
                          _vm.initiateReszieEvent()
                        },
                        keyup: [
                          function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k($event.keyCode, "left", 37, $event.key, [
                                "Left",
                                "ArrowLeft"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 0) {
                              return null
                            }
                            _vm.leftArrow($event)
                          },
                          function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k($event.keyCode, "right", 39, $event.key, [
                                "Right",
                                "ArrowRight"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 2) {
                              return null
                            }
                            _vm.rightArrow($event)
                          }
                        ],
                        keydown: _vm.focusedElement
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.i18n.tabRelatedProducts) +
                          "\n                "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.pdpProductsData &&
            _vm.pdpProductsData.hasOwnProperty("specifications") &&
            _vm.pdpProductsData.specifications.length > 0 &&
            _vm.globals.siteConfig.showProductSpecs
              ? _c("li", { staticClass: "tab-item", attrs: { role: "tab" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        id: "tab-specifications",
                        name: _vm.i18n.tabSpecifications,
                        "aria-controls": "pane-specifications",
                        href: "#pane-specifications",
                        "data-toggle": "tab",
                        role: "tab",
                        tabindex: "0"
                      },
                      on: {
                        keyup: [
                          function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k($event.keyCode, "left", 37, $event.key, [
                                "Left",
                                "ArrowLeft"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 0) {
                              return null
                            }
                            _vm.leftArrow($event)
                          },
                          function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k($event.keyCode, "right", 39, $event.key, [
                                "Right",
                                "ArrowRight"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 2) {
                              return null
                            }
                            _vm.rightArrow($event)
                          }
                        ],
                        keydown: _vm.focusedElement
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.i18n.tabSpecifications) +
                          "\n                "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.pdpProductsData && _vm.isBazaarVoice === "true"
              ? _c("li", { staticClass: "tab-item", attrs: { role: "tab" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link reviews-tab",
                      attrs: {
                        id: "tab-reviews",
                        name: _vm.i18n.tabReviews,
                        "aria-controls": "pane-reviews",
                        href: "#pane-reviews",
                        "data-toggle": "tab",
                        role: "tab",
                        tabindex: "0"
                      },
                      on: {
                        keyup: [
                          function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k($event.keyCode, "left", 37, $event.key, [
                                "Left",
                                "ArrowLeft"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 0) {
                              return null
                            }
                            _vm.leftArrow($event)
                          },
                          function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k($event.keyCode, "right", 39, $event.key, [
                                "Right",
                                "ArrowRight"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 2) {
                              return null
                            }
                            _vm.rightArrow($event)
                          }
                        ],
                        keydown: _vm.focusedElement
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.i18n.tabReviews) +
                          "\n                    "
                      ),
                      _vm.pdpProductsData.bvNumberOfReviews
                        ? _c("div", { staticClass: "reviews-value" }, [
                            _vm._v(
                              "\n                        (" +
                                _vm._s(_vm.pdpProductsData.bvNumberOfReviews) +
                                ")\n                    "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("li", { staticClass: "tab-item", attrs: { role: "tab" } }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: {
                    id: "pdp-purchasing-option",
                    name: _vm.i18n.purchasingOptions,
                    "aria-controls": "purchasing-options",
                    href: "#purchasing-options",
                    "data-toggle": "tab",
                    role: "tab",
                    tabindex: "0"
                  },
                  on: {
                    keyup: [
                      function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k($event.keyCode, "left", 37, $event.key, [
                            "Left",
                            "ArrowLeft"
                          ])
                        ) {
                          return null
                        }
                        if ("button" in $event && $event.button !== 0) {
                          return null
                        }
                        _vm.leftArrow($event)
                      },
                      function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k($event.keyCode, "right", 39, $event.key, [
                            "Right",
                            "ArrowRight"
                          ])
                        ) {
                          return null
                        }
                        if ("button" in $event && $event.button !== 2) {
                          return null
                        }
                        _vm.rightArrow($event)
                      }
                    ],
                    keydown: _vm.focusedElement
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.i18n.purchasingOptions) +
                      "\n                "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _vm.pdpProductsData &&
            ((_vm.pdpProductsData.dataSheets &&
              _vm.pdpProductsData.dataSheets.dataSheet &&
                _vm.pdpProductsData.dataSheets.dataSheet.length > 0) ||
              (_vm.pdpProductsData.productResourceVideos &&
                _vm.pdpProductsData.productResourceVideos.video &&
                  _vm.pdpProductsData.productResourceVideos.video.length >
                    0)) &&
            _vm.globals.siteConfig.showProductResources
              ? _c("li", { staticClass: "tab-item", attrs: { role: "tab" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        id: "tab-resources",
                        name: _vm.i18n.tabProductResources,
                        "aria-controls": "pane-resources",
                        href: "#pane-resources",
                        "data-toggle": "tab",
                        role: "tab",
                        tabindex: "0"
                      },
                      on: {
                        keyup: [
                          function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k($event.keyCode, "left", 37, $event.key, [
                                "Left",
                                "ArrowLeft"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 0) {
                              return null
                            }
                            _vm.leftArrow($event)
                          },
                          function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k($event.keyCode, "right", 39, $event.key, [
                                "Right",
                                "ArrowRight"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 2) {
                              return null
                            }
                            _vm.rightArrow($event)
                          }
                        ],
                        keydown: _vm.focusedElement
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.i18n.tabProductResources) +
                          "\n                "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.globals.isVanityfair() && _vm.compareProductIds.length > 1
              ? _c("li", { staticClass: "tab-item", attrs: { role: "tab" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        id: "tab-compare-products",
                        name: _vm.i18n.tabCompareProducts,
                        "aria-controls": "pane-compare-products",
                        href: "#pane-compare-products",
                        "data-toggle": "tab",
                        role: "tab",
                        tabindex: "0"
                      },
                      on: {
                        keyup: [
                          function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k($event.keyCode, "left", 37, $event.key, [
                                "Left",
                                "ArrowLeft"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 0) {
                              return null
                            }
                            _vm.leftArrow($event)
                          },
                          function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k($event.keyCode, "right", 39, $event.key, [
                                "Right",
                                "ArrowRight"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 2) {
                              return null
                            }
                            _vm.rightArrow($event)
                          }
                        ],
                        keydown: _vm.focusedElement
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.i18n.tabCompareProducts) +
                          "\n                "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.pdpProductsData &&
            _vm.pdpProductsData.tabInfo &&
            JSON.parse(_vm.pdpProductsData.showCustomTabs)
              ? _vm._l(_vm.pdpProductsData.tabInfo, function(tabs, index) {
                  return _c(
                    "li",
                    { staticClass: "tab-item", attrs: { role: "tab" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            id: "tab-custom-" + (index + 1),
                            name: tabs.name,
                            "aria-controls": "pane-custom-" + (index + 1),
                            href: "#pane-custom-" + (index + 1),
                            "data-toggle": "tab",
                            role: "tab",
                            tabindex: "0"
                          },
                          on: {
                            keyup: [
                              function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "left",
                                    37,
                                    $event.key,
                                    ["Left", "ArrowLeft"]
                                  )
                                ) {
                                  return null
                                }
                                if ("button" in $event && $event.button !== 0) {
                                  return null
                                }
                                _vm.leftArrow($event)
                              },
                              function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "right",
                                    39,
                                    $event.key,
                                    ["Right", "ArrowRight"]
                                  )
                                ) {
                                  return null
                                }
                                if ("button" in $event && $event.button !== 2) {
                                  return null
                                }
                                _vm.rightArrow($event)
                              }
                            ],
                            keydown: _vm.focusedElement
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                tabs.name !== "Product Resources"
                                  ? tabs.name
                                  : "Resources"
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ]
                  )
                })
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "tab-content",
            attrs: { id: "content", role: "tablist" }
          },
          [
            _c(
              "div",
              {
                staticClass: "px-sm-3 px-md-3 card tab-pane fade in active",
                attrs: {
                  id: "pane-details",
                  role: "tabpanel",
                  "aria-labelledby": "tab-details"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "px-xs-3 card-header",
                    attrs: { role: "tab", id: "heading-details" }
                  },
                  [
                    _c("h5", { staticClass: "mb-0" }, [
                      _c(
                        "a",
                        {
                          staticClass: "collapsed",
                          attrs: {
                            "data-toggle": "collapse",
                            href: "#collapse-details",
                            "data-parent": "#content",
                            "aria-expanded": "false",
                            "aria-controls": "collapse-details"
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.i18n.tabDetails) +
                              "\n                            "
                          ),
                          _c("span", { staticClass: "icon expanded" })
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "px-sm-3 px-md-0 collapse",
                    attrs: {
                      id: "collapse-details",
                      role: "tabpanel",
                      "aria-labelledby": "heading-details"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _vm.pdpProductsData
                          ? _c("vx-product-details-tab", {
                              attrs: {
                                "product-details-tab-data": _vm.pdpProductsData,
                                i18n: _vm.messages.pdp.productDetails
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            (_vm.isRelatedProducts && _vm.isRelatedEnabled) ||
            (_vm.pdpProductsData &&
              _vm.pdpProductsData.code &&
              _vm.pdpProductsData.code.length > 0)
              ? _c(
                  "div",
                  {
                    staticClass: "px-sm-3 px-md-3 card tab-pane fade",
                    attrs: {
                      id: "pane-related-products",
                      role: "tabpanel",
                      "aria-labelledby": "tab-related-products"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "px-xs-3 card-header",
                        attrs: { role: "tab", id: "heading-related-products" }
                      },
                      [
                        _c("h5", { staticClass: "mb-0" }, [
                          _c(
                            "a",
                            {
                              staticClass: "collapsed",
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#collapse-related-products",
                                "data-parent": "#content",
                                "aria-expanded": "false",
                                "aria-controls": "collapse-related-products"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.i18n.tabRelatedProducts) +
                                  "\n                            "
                              ),
                              _c("span", { staticClass: "icon expanded" })
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.pdpProductsData
                      ? _c(
                          "div",
                          {
                            staticClass: "px-xs-3 collapse",
                            attrs: {
                              id: "collapse-related-products",
                              role: "tabpanel",
                              "aria-labelledby": "heading-related-products"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-body" },
                              [
                                _vm.isRelatedEnabled
                                  ? _c("vx-related-products-tab", {
                                      attrs: {
                                        "parent-product":
                                          _vm.pdpProductsData.code,
                                        "is-favorites": _vm.isFavorites,
                                        "is-bazaar-voice": _vm.isBazaarVoice,
                                        "is-related-enabled":
                                          _vm.isRelatedEnabled,
                                        i18n: _vm.searchBrowseI18n
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("vx-upsell-carousel", {
                                  attrs: {
                                    "passed-in-upsell-product-ids":
                                      _vm.pdpProductsData.code,
                                    "is-bazaar-voice": _vm.isBazaarVoice,
                                    i18n: _vm.searchBrowseI18n
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "px-sm-3 px-md-3 card tab-pane fade in",
                attrs: {
                  id: "pane-specifications",
                  role: "tabpanel",
                  "aria-labelledby": "tab-specifications"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "px-xs-3 card-header",
                    attrs: { role: "tab", id: "heading-specifications" }
                  },
                  [
                    _c("h5", { staticClass: "mb-0" }, [
                      _c(
                        "a",
                        {
                          staticClass: "collapsed",
                          attrs: {
                            "data-toggle": "collapse",
                            href: "#collapse-specifications",
                            "data-parent": "#content",
                            "aria-expanded": "false",
                            "aria-controls": "collapse-specifications"
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.i18n.tabSpecifications) +
                              "\n                            "
                          ),
                          _c("span", { staticClass: "icon expanded" })
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "px-sm-3 px-md-0 collapse",
                    attrs: {
                      id: "collapse-specifications",
                      role: "tabpanel",
                      "aria-labelledby": "heading-specifications"
                    }
                  },
                  [
                    _vm.pdpProductsData
                      ? _c(
                          "div",
                          { staticClass: "card-body" },
                          _vm._l(_vm.pdpProductsData.specifications, function(
                            productSpecData,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                staticClass: "col-md-6 col-sm-6 col-xs-12 col",
                                class: { onlyleftborder: index % 2 === 1 }
                              },
                              [
                                _c("vx-product-details-tab-specs", {
                                  attrs: {
                                    productDetailsSpecificationData: productSpecData,
                                    indexNo: index
                                  }
                                })
                              ],
                              1
                            )
                          })
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.pdpProductsData && _vm.isBazaarVoice === "true"
              ? _c(
                  "div",
                  {
                    staticClass: "card tab-pane fade",
                    attrs: {
                      id: "pane-reviews",
                      role: "tabpanel",
                      "aria-labelledby": "tab-reviews"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "px-xs-3 card-header",
                        attrs: { role: "tab", id: "heading-reviews" }
                      },
                      [
                        _c("h5", { staticClass: "mb-0" }, [
                          _c(
                            "a",
                            {
                              staticClass: "collapsed",
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#collapse-reviews",
                                "data-parent": "#content",
                                "aria-expanded": "false",
                                "aria-controls": "collapse-reviews"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.i18n.tabReviews) +
                                  "\n                            "
                              ),
                              _c("span", { staticClass: "icon expanded" })
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(0)
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "px-sm-4 px-md-2 card tab-pane fade",
                attrs: {
                  id: "purchasing-options",
                  role: "tabpanel",
                  "aria-labelledby": "tab-purchasing-options"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "px-xs-3 card-header",
                    attrs: { role: "tab", id: "heading-resources" }
                  },
                  [
                    _c("h5", { staticClass: "mb-0" }, [
                      _c(
                        "a",
                        {
                          staticClass: "collapsed",
                          attrs: {
                            "data-toggle": "collapse",
                            href: "#collapse-purchasing-options",
                            "data-parent": "#content",
                            "aria-expanded": "false",
                            "aria-controls": "collapse-purchasing-options"
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.i18n.purchasingOptions) +
                              "\n                            "
                          ),
                          _c("span", { staticClass: "icon expanded" })
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "px-xs-2 collapse",
                    attrs: {
                      id: "collapse-purchasing-options",
                      role: "tabpanel",
                      "aria-labelledby": "heading-resources"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _vm.pdpProductsData
                          ? _c("vx-purchasing-options-tab", {
                              attrs: {
                                "product-resources-tab-data":
                                  _vm.pdpProductsData,
                                i18n: _vm.messages.pdp.productResourcesTab
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.pdpProductsData &&
            ((_vm.pdpProductsData.dataSheets &&
              _vm.pdpProductsData.dataSheets.dataSheet &&
                _vm.pdpProductsData.dataSheets.dataSheet.length > 0) ||
              (_vm.pdpProductsData.productResourceVideos &&
                _vm.pdpProductsData.productResourceVideos.video &&
                  _vm.pdpProductsData.productResourceVideos.video.length >
                    0)) &&
            _vm.globals.siteConfig.showProductResources
              ? _c(
                  "div",
                  {
                    staticClass: "px-sm-4 px-md-2 card tab-pane fade",
                    attrs: {
                      id: "pane-resources",
                      role: "tabpanel",
                      "aria-labelledby": "tab-resources"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "px-xs-3 card-header",
                        attrs: { role: "tab", id: "heading-resources" }
                      },
                      [
                        _c("h5", { staticClass: "mb-0" }, [
                          _c(
                            "a",
                            {
                              staticClass: "collapsed",
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#collapse-resources",
                                "data-parent": "#content",
                                "aria-expanded": "false",
                                "aria-controls": "collapse-resources"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.i18n.tabProductResources) +
                                  "\n                            "
                              ),
                              _c("span", { staticClass: "icon expanded" })
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "px-xs-2 collapse",
                        attrs: {
                          id: "collapse-resources",
                          role: "tabpanel",
                          "aria-labelledby": "heading-resources"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _vm.pdpProductsData
                              ? _c("vx-product-resources-tab", {
                                  attrs: {
                                    "product-resources-tab-data":
                                      _vm.pdpProductsData,
                                    i18n: _vm.messages.pdp.productResourcesTab
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.compareProductIds.length > 1
              ? _c(
                  "div",
                  {
                    staticClass: "card tab-pane fade",
                    attrs: {
                      id: "pane-compare-products",
                      role: "tabpanel",
                      "aria-labelledby": "tab-compare-products"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "px-xs-3 card-header",
                        attrs: { role: "tab", id: "heading-compare-products" }
                      },
                      [
                        _c("h5", { staticClass: "mb-0" }, [
                          _c(
                            "a",
                            {
                              staticClass: "collapsed",
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#collapse-compare-products",
                                "data-parent": "#content",
                                "aria-expanded": "false",
                                "aria-controls": "collapse-compare-products"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.i18n.tabCompareProducts) +
                                  "\n                            "
                              ),
                              _c("span", { staticClass: "icon expanded" })
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "px-xs-0 px-md-3 collapse",
                        attrs: {
                          id: "collapse-compare-products",
                          role: "tabpanel",
                          "aria-labelledby": "heading-compare-products"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-body mx-md-4 my-md-4 mt-sm-4 mb-sm-4 mt-xs-4 mb-xs-4"
                          },
                          [
                            _c("vx-compare-result-page", {
                              attrs: {
                                "show-title": false,
                                "show-remove-option": false,
                                "is-favorites": _vm.isFavorites,
                                "page-type": "PDP_COMPARE",
                                "pdp-compare-ids": _vm.compareProductIds,
                                "is-bazaar-voice": _vm.isBazaarVoice,
                                i18n: _vm.searchBrowseI18n
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pdpProductsData &&
            _vm.pdpProductsData.tabInfo &&
            JSON.parse(_vm.pdpProductsData.showCustomTabs)
              ? _vm._l(_vm.pdpProductsData.tabInfo, function(tabs, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "custom-card card tab-pane fade",
                      attrs: {
                        id: "pane-custom-" + (index + 1),
                        role: "tabpanel",
                        "aria-labelledby": "tab-custom-" + (index + 1)
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "px-xs-3 card-header",
                          attrs: {
                            role: "tab",
                            id: "heading-custom-tabs-" + (index + 1)
                          }
                        },
                        [
                          _c("h5", { staticClass: "mb-0" }, [
                            _c(
                              "a",
                              {
                                staticClass: "collapsed",
                                attrs: {
                                  "data-toggle": "collapse",
                                  href: "#collapse-custom-tabs-" + (index + 1),
                                  "data-parent": "#content",
                                  "aria-expanded": "false",
                                  "aria-controls":
                                    "#collapse-custom-tabs-" + (index + 1)
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(tabs.name) +
                                    "\n                                "
                                ),
                                _c("span", { staticClass: "icon expanded" })
                              ]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "px-xs-2 collapse",
                          attrs: {
                            id: "collapse-custom-tabs-" + (index + 1),
                            role: "tabpanel",
                            "aria-labelledby":
                              "heading-custom-tabs-" + (index + 1)
                          }
                        },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(tabs.content) }
                            })
                          ])
                        ]
                      )
                    ]
                  )
                })
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "px-xs-3 collapse",
        attrs: {
          id: "collapse-reviews",
          role: "tabpanel",
          "aria-labelledby": "heading-reviews"
        }
      },
      [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xs-12" }, [
              _c("div", { staticClass: "summary-container" }, [
                _c("div", { attrs: { id: "BVRRSummaryContainer" } })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c("div", { staticClass: "ratings-reviews-container" }, [
                _c("div", { attrs: { id: "BVRRContainer" } })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c("div", { staticClass: "qa-container" }, [
                _c("div", { attrs: { id: "BVQAContainer" } })
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3c0281f2", { render: render, staticRenderFns: staticRenderFns })
  }
}