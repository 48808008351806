var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-address-verification" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.hideVerifiedAddress
        ? _c("div", { staticClass: "pb-xs-3 px-xs-3 hide-verified" }, [
            _vm._v(" " + _vm._s(_vm.status.message) + " ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.addressVerificationSuccess
        ? _c(
            "div",
            {
              staticClass: "d-flex flex-xs-column flex-sm-row",
              attrs: { "data-test-top": _vm.isMobile() }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "col-sm-6 col-xs-12 d-flex current-address-container"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "current-address-heading",
                      attrs: { role: "heading" }
                    },
                    [_vm._v(_vm._s(_vm.i18n.currentAddressHeading))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pt-xs-3 current-address-body" },
                    _vm._l(
                      _vm.formAddressValues(_vm.unverifiedAddress),
                      function(address) {
                        return _c("span", {
                          staticClass: "address-body",
                          domProps: { innerHTML: _vm._s(address) }
                        })
                      }
                    )
                  ),
                  _vm._v(" "),
                  _vm.isMobile()
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "mt-xs-4 submit btn btn-block btn-default modal-btn-IEspacing",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.selectedAddress(_vm.current, $event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.useCurrentAddress))]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              !_vm.hideVerifiedAddress
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-sm-6 col-xs-12 d-flex verified-address-container"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "verified-address-heading",
                          attrs: { role: "heading" }
                        },
                        [_vm._v(_vm._s(_vm.i18n.verifiedAddressHeading))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "pt-xs-3 verified-address-body" },
                        _vm._l(
                          _vm.formAddressValues(_vm.verifiedAddress),
                          function(address) {
                            return _c("span", {
                              staticClass: "address-body",
                              domProps: { innerHTML: _vm._s(address) }
                            })
                          }
                        )
                      ),
                      _vm._v(" "),
                      _vm.isMobile()
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "mt-xs-4 submit btn btn-block button-color text-trans modal-btn-IEspacing",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  _vm.selectedAddress(_vm.verified, $event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.i18n.useVerifiedAddress))]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMobile()
        ? _c(
            "div",
            {
              staticClass: "d-flex flex-xs-column flex-sm-row",
              attrs: { "data-test-bottom": _vm.isMobile() }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "col-sm-6 col-xs-12 d-flex current-address-container"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "mt-xs-4 submit btn btn-block text-trans btn-default modal-btn-IEspacing",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.selectedAddress(_vm.current, $event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.useCurrentAddress))]
                  )
                ]
              ),
              _vm._v(" "),
              !_vm.hideVerifiedAddress
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-sm-6 col-xs-12 d-flex verified-address-container"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "mt-xs-4 submit btn btn-block button-color text-trans modal-btn-IEspacing",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.selectedAddress(_vm.verified, $event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.useVerifiedAddress))]
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5c34ad5b", { render: render, staticRenderFns: staticRenderFns })
  }
}