/**
 * Component for displaying the selected product tiles and details in tabular form for comparison
 */

import vxProductTileVertical from '../vx-product-tile-vertical/vx-product-tile-vertical.vue';
import vxCompareTable from '../vx-compare-table/vx-compare-table.vue';
import globals from '../../common/globals';
import cookiesMixin from '../../common/mixins/cookies-mixin';
import SearchBrowseService from '../../common/services/search-browse-service';
import mobileMixin from '../../common/mixins/mobile-mixin';

export default {
  name: 'vx-compare-result-page',
  components: {
    vxProductTileVertical,
    vxCompareTable,
  },
  props: {
    /**
     * Copy text coming from properties files
     */
    i18n: {
      type: Object,
    },
    /**
     * indicates whether to show remove option on the product tile
     */
    showRemoveOption: {
      type: Boolean,
      default: false,
    },
    /**
     * indicates whether the site is configured for favorites
     */
    isFavorites: {
      type: Boolean,
      default: false,
    },
    /**
     * indicates whether the site is configured for Bazaar Voice
     */
    isBazaarVoice: {
      type: String,
      default: '',
    },
    /**
     * page type is the type of the page passed as a parameter for get compare results service call
     */
    pageType: {
      type: String,
      default: '',
    },
    /**
     * indicates whether to show title
     */
    showTitle: {
      type: Boolean,
      default: true,
    },
    /**
     * array of product ids selected for comparison
     */
    pdpCompareIds: {
      type: Array,
    },
  },
  mixins: [cookiesMixin, mobileMixin],
  data() {
    return {
      globals,
      productsCompareData: {},
      showRemoveButton: true,
      comparedProducts: null,
      searchBrowseService: new SearchBrowseService(),
    };
  },
  async mounted() {
    this.onInitCheck();
  },
  methods: {
    /**
     * This function is called on mounted which will read product codes from cookie and call generate a query
     */
    onInitCheck() {
      const self = this;
      let queryString = '';
      // condition to check if component is called from PDP page or search result page.
      if (this.pdpCompareIds && this.pdpCompareIds.length > 0) {
        queryString = self.generateQuery(this.pdpCompareIds);
      } else {
        const comparedProductsCookie = this.readCookie('CompareCookie');
        if (
          comparedProductsCookie
          && comparedProductsCookie !== undefined
          && comparedProductsCookie !== null
        ) {
          if (this.isJsonString(comparedProductsCookie)) {
            self.comparedProducts = JSON.parse(comparedProductsCookie).map((item) => item.code);
          } else {
            self.comparedProducts = this.$route.query.productCodes.split(':');
            const compareProductValueJson = [];
            self.comparedProducts.forEach((element) => {
              compareProductValueJson.push({ code: element });
            });
            this.globals.setCookie('CompareCookie', JSON.stringify(compareProductValueJson));
          }
          queryString = self.generateQuery(self.comparedProducts);
        } else {
          queryString = window.location.search;
        }
      }
      self.generateUrlRequest(queryString);
    },
    /**
     * This function handles response of get compare results service call
     */
    handleCompareResponse(response) {
      if (this.isMobile()) {
        this.productsCompareData = response.data;
        this.productsCompareData.products = this.productsCompareData.products.slice(0, 2);
      } else {
        this.productsCompareData = response.data;
      }
    },
    /**
     * This function handles error of get compare results service call
     */
    handleCompareError() {},
    /**
     * This function generates compare result url
     * @param  {String} query string of product codes selected for comparison
     */
    generateUrlRequest(query) {
      const requestConfig = {};
      const self = this;
      self.searchBrowseService.getCompareResults(
        requestConfig,
        self.handleCompareResponse,
        self.handleCompareError,
        query,
        self.pageType,
      );
    },
    /**
     * This function generates compare result url
     * @param  {String} productCode string of product code to generate the query
     */
    generateQuery(productCode) {
      const initialText = '?productCodes=';
      const prodList = initialText + productCode.join(':');
      return prodList;
    },
    /**
      * This function verifies whether json is valid or not
      * @param  {String} str json String
      */
    isJsonString(str) {
      try {
        const json = JSON.parse(str);
        return (typeof json === 'object');
      } catch (e) {
        return false;
      }
    },
  },
};
