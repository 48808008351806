var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-review-lease mx-xs-3 mx-sm-5" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.leaseAgrementData && _vm.leaseAgrementData.legalTermName
        ? _c("h3", {
            staticClass: "lease-heading",
            domProps: { innerHTML: _vm._s(_vm.leaseAgrementData.legalTermName) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.leaseAgrementData && _vm.leaseAgrementData.legalTermsText
        ? _c("p", {
            staticClass: "lease-body mt-xs-4 mt-md-5",
            domProps: {
              innerHTML: _vm._s(_vm.leaseAgrementData.legalTermsText)
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2ebd9e06", { render: render, staticRenderFns: staticRenderFns })
  }
}