/**
 * Handles the shipping address functionality
 */
import uniqBy from 'lodash.uniqby';
import vxMultiShippingAddress
  from '../vx-multi-shipping-address/vx-multi-shipping-address.vue';
import vxSingleShippingAddress
  from '../../../../components/checkout/vx-single-shipping-address/vx-single-shipping-address.vue';
import globals from '../../../../components/common/globals';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import CheckoutService from '../../../../components/common/services/checkout-service';
// import savedAddresses from './vx-shipping-address-mock';
// import { cartEventBus } from '../../../modules/event-bus';
import {
  checkoutEventBus, globalEventBus,
} from '../../../../modules/event-bus';

export default {
  name: 'vx-shipping-address',
  components: {
    vxMultiShippingAddress,
    vxSingleShippingAddress,
    vxSpinner,
  },
  props: {
    // Text coming from property file
    i18n: Object,
    // Products in cart
    visibleEntries: {
      type: Array,
    },
    // Indicates is user is B2B L1 user/anonymous/B2C
    userLevel: Boolean,
    // Index of Shipping Address Section
    sectionIndex: Number,
    // All the cart entries
    allEntries: {
      type: Array,
    },
  },
  data() {
    return {
      globals,
      checkoutService: new CheckoutService(),
      isSingle: true,
      isMultiple: false,
      isSaved: false,
      isEditable: true,
      savedAddresses: [],
      existingSavedAddresses: [],
      dataLoaded: false,
      loadedflag: false,
      isGuest: false,
      isPalletShipment: false,
      isNewProductAdded: false,
      isShippingMultiple: true,
      disableShipToMultiple: false,
    };
  },
  // need to remove mock
  mounted() {
    this.loadedflag = true;
    if (this.globals.getIsLoggedIn()) {
      this.getAddressesRequest();
    } else {
      this.isGuest = true;
      this.getGuestAddressesRequest();
    }
    this.palletShipment();
    globalEventBus.$on('enable-shipping-address', (data) => {
      if (data) {
        this.editMethod();
      }
    });
  },
  updated() {
    if (this.loadedflag) {
      this.isAddressExists();
    }
  },
  methods: {
    /**
         * This function shows saved addresses on page refresh
         */
    isAddressExists() {
      const splitEntries = [];
      this.existingSavedAddresses = [];
      this.allEntries.forEach((entry) => {
        if (entry.splitEntries && entry.splitEntries.length === 0) {
          this.isNewProductAdded = true;
          this.isEditable = true;
        }
      });
      for (let i = 0; i < this.visibleEntries.length; i += 1) {
        if (
          this.isNewProductAdded
                    && this.visibleEntries[0]
                    && this.visibleEntries[0].splitEntries
                    && this.visibleEntries[0].splitEntries.length !== 0
                    && this.visibleEntries[0].splitEntries[0]
                    && this.visibleEntries[0].splitEntries[0].deliveryAddress
        ) {
          const addressToBePopulated = {
            label: this.$refs.singleShipping.formAddressString(
              this.visibleEntries[0].splitEntries[0].deliveryAddress,
            ),
            value: this.visibleEntries[0].splitEntries[0].deliveryAddress,
          };
          this.$refs.singleShipping.selectedAddress(addressToBePopulated);
          this.$refs.singleShipping.populateStoredAddress();
          break;
        }
        for (
          let k = 0;
          k < this.visibleEntries[i].splitEntries.length;
          k += 1
        ) {
          splitEntries.push(this.visibleEntries[i].splitEntries[k]);
        }
      }
      if (splitEntries.length === 0) {
        this.isEditable = true;
      } else if (!this.isNewProductAdded) {
        for (let j = 0; j < splitEntries.length; j += 1) {
          this.existingSavedAddresses.push(splitEntries[j].deliveryAddress);
        }
        this.existingSavedAddresses = uniqBy(
          this.existingSavedAddresses,
          'id',
        );
        this.$refs.singleShipping.showExistingAddresses(
          this.existingSavedAddresses,
        );
      }
      this.loadedflag = false;
    },
    /**
         * This function checks if product can be delivered in pallet
         */
    palletShipment() {
      for (let i = 0; i < this.visibleEntries.length; i += 1) {
        if (
          this.visibleEntries[i].product
                    && this.visibleEntries[i].product.isPallet
        ) {
          this.isPalletShipment = true;
          break;
        } else {
          this.isPalletShipment = false;
        }
      }
    },
    /**
         * This function gets all saved addresses of the logged in  user
         */
    getAddressesRequest() {
      this.checkoutService.getCheckoutAddress(
        {},
        this.handleGetAddressResponse,
        this.handleGetAddressError,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
         * This function gets all saved addresses of the guest user
         */
    getGuestAddressesRequest() {
      this.checkoutService.getGuestAddress(
        {},
        this.handleGetAddressResponse,
        this.handleGetAddressError,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
         * This function handles response of all get addresses call
         */
    handleGetAddressResponse(response) {
      this.$refs.spinner.hideSpinner();
      this.savedAddresses = [];
      if (this.globals.isEStores()) {
        response.data.addresses.forEach((address) => {
          if (address.unit && address.unit.uid === this.globals.userInfo.unit) {
            this.savedAddresses.push(address);
          }
        });
      } else {
        this.savedAddresses = response.data.addresses;
      }
      // Disable ship to multiple link if the user is L2/L3 and doesnt have any saved address
      if (!this.userLevel && this.savedAddresses.length === 0) {
        this.disableShipToMultiple = true;
      }
      this.dataLoaded = true;
      if (this.dataLoaded) {
        if (this.isMultiple) {
          this.$nextTick(() => {
            this.$refs.multiShipping.getAddressValues(this.savedAddresses);
          });
          checkoutEventBus.$emit('shipping-multiple');
        } else {
          this.$nextTick(() => {
            this.$refs.singleShipping.getAddressValues(this.savedAddresses);
          });
          checkoutEventBus.$emit('shipping-single');
        }
      }
    },
    /**
         * This function handles error of all get addresses call
         */
    handleGetAddressError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function is triggered from vx-single-shipping-address & vx-multi-shipping-address section when user saves address
         * @param  {Object} data save data flags object
         */
    onAddressSaved(data) {
      this.saveMethod(data);
    },
    /**
         * This function toggles between Single and Multiple Shipping
         */
    toggleMethodType() {
      this.isSingle = !this.isSingle;
      this.isMultiple = !this.isMultiple;
      if (this.isMultiple) {
        checkoutEventBus.$emit('shipping-multiple');
      }
      if (this.isSingle) {
        checkoutEventBus.$emit('shipping-single');
      }
    },
    /**
         * This function is triggered when user tries to change address
         */
    editMethod() {
      if (this.globals.getIsLoggedIn()) {
        this.getAddressesRequest();
      } else {
        this.isGuest = true;
        this.getGuestAddressesRequest();
      }
      this.isSaved = !this.isSaved;
      this.isEditable = !this.isEditable;
      this.existingAddresses = [];
      checkoutEventBus.$emit('section-edit', 'shippingAddress');
      checkoutEventBus.$emit('shipping-address-edit');
      if (this.isSingle) {
        this.$refs.singleShipping.toggleEditMode();
        checkoutEventBus.$emit('shipping-single');
      } else if (this.isMultiple) {
        this.$refs.multiShipping.toggleEditMode();
        checkoutEventBus.$emit('shipping-multiple');
      }
    },
    /**
         * This function is triggered when user saves Shipping address section
         * @param  {Object} data save data flags object
         */
    saveMethod(data) {
      this.isSaved = data.isSaved;
      this.isEditable = data.isEditable;
      checkoutEventBus.$emit('section-complete', 'shippingAddress');
      checkoutEventBus.$emit('shipping-address-added');
    },
    /**
         * This function is triggered when user tries to add new address in vx-single-shipping-address
         */
    addNewAddressSingle(event) {
      this.$refs.singleShipping.addNewAddress(event);
    },
    /**
         * This function is triggered when user tries to add new address in vx-multi-shipping-address
         */
    addNewAddressMultiple() {
      this.$refs.multiShipping.addNewAddress();
    },
    /**
         * This function checks if multiple shipping is opted
         * @param  {Boolean} val flag for multiple shipping
         */
    findShippingType(val) {
      this.isShippingMultiple = val;
    },
  },
};
