var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-bundles" }, [
    _c("h1", { staticClass: "mb-xs-4" }, [
      _vm._v(_vm._s(_vm.bundlesData.title))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row bundles-wrapper" },
      _vm._l(_vm.bundlesData.ImageList, function(bundle, index) {
        return _c(
          "div",
          {
            staticClass:
              "bundles-image-wrapper mb-sm-4 mb-xs-3 col-xs-12 col-sm-6 col-md-4"
          },
          [_c("vx-image-tile", { attrs: { imageTileData: bundle } })],
          1
        )
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-10c8db1b", { render: render, staticRenderFns: staticRenderFns })
  }
}