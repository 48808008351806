var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-order-details" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "saveListModal",
          attrs: {
            size: "extra-small",
            heading: _vm.i18n.orderDetails.selectListLabel
          }
        },
        [
          _c("vx-save-cart", {
            attrs: {
              slot: "component",
              wishlistEntries: _vm.productList,
              i18n: _vm.i18n.saveList
            },
            on: { "list-saved": _vm.handleListSaved },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "viewTermsModal",
          attrs: { size: "large", heading: _vm.leaseAgrementData.legalTermName }
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("p", {
              staticClass: "terms-content-intro",
              domProps: {
                innerHTML: _vm._s(_vm.leaseAgrementData.legalTermsText)
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _vm.dataLoaded
        ? [
            _c(
              "vx-modal",
              {
                ref: "cancelOrder",
                attrs: {
                  size: "medium",
                  heading: _vm.i18n.orderDetails.cancelOrder
                }
              },
              [
                _c("vx-cancel-order", {
                  attrs: {
                    slot: "component",
                    type: "modal",
                    i18n: _vm.i18n.orderDetails,
                    "order-id": _vm.orderDetails.code
                  },
                  on: {
                    "cancel-order-success": _vm.handleCancelOrderSuccess,
                    "cancel-order-error": _vm.handleCancelOrderError,
                    "keep-order": _vm.handleKeepOrder
                  },
                  slot: "component"
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-md-8 col-sm-12 col-xs-12 pr-xs-0 pl-xs-0 d-flex flex-row flex-wrap"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column confirmation-left-section"
                  },
                  [
                    _c("h1", { staticClass: "my-xs-4 details-page-title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderDetails.orderDetailsHeading) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.giveAwayCouponCode || _vm.giveAwayCouponName
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "give-away-coupon py-xs-3 pl-xs-3 mt-md-2 mb-xs-3"
                          },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.i18n.orderDetails.giveAwayCoupon)
                              )
                            ]),
                            _vm._v(" "),
                            _vm.giveAwayCouponName
                              ? _c("h5", [
                                  _vm._v(_vm._s(_vm.giveAwayCouponName))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.giveAwayCouponCode
                              ? _c("h5", [
                                  _vm._v(_vm._s(_vm.giveAwayCouponCode))
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm.orderDetails.isSubscription
                  ? _c(
                      "p",
                      { staticClass: "col-xs-12 px-xs-0 mb-xs-0 order-number" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.i18n.orderDetails.subscrOrder) +
                            " #" +
                            _vm._s(_vm.orderDetails.code) +
                            "\n      "
                        )
                      ]
                    )
                  : _c(
                      "p",
                      { staticClass: "col-xs-12 px-xs-0 mb-xs-0 order-number" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.i18n.orderDetails.order) +
                            " #" +
                            _vm._s(_vm.orderDetails.code) +
                            "\n      "
                        )
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                  },
                  [
                    _c("div", { staticClass: "order-info-heading pb-xs-2" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderDetails.orderPlaced) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "order-info" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.getDateByTimeZone(_vm.orderDetails.created)
                          ) +
                          "\n          " +
                          _vm._s(_vm.i18n.orderDetails.estName) +
                          "\n        "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                  },
                  [
                    _c("div", { staticClass: "order-info-heading pb-xs-2" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderDetails.orderStatus) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "order-info order-status" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderDetails.statusDisplay) +
                          "\n        "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm.globals.isB2B() && _vm.isAdmin
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "order-info-heading pb-xs-2" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.orderDetails.orderPlacedBy) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "order-info" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.orderDetails.user.name) +
                              "\n        "
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isMultiple
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "order-info-heading pb-xs-2" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.orderDetails.shippedTo) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _c("span", { staticClass: "user-name" }, [
                              _c("span", { staticClass: "delivery-address" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.firstName
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "delivery-address" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.lastName
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress &&
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress.companyName
                              ? _c(
                                  "span",
                                  { staticClass: "delivery-address" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetails.deliveryGroup[0].value
                                          .splitEntries[0].deliveryAddress
                                          .companyName
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "delivery-address" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.deliveryGroup[0].value
                                    .splitEntries[0].deliveryAddress.line1
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress.line2
                              ? _c(
                                  "span",
                                  { staticClass: "delivery-address" },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.orderDetails.deliveryGroup[0]
                                            .value.splitEntries[0]
                                            .deliveryAddress.line2
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", [
                              _c("span", { staticClass: "delivery-address" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.town
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "delivery-address" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.region
                                      .isocodeShort
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "delivery-address" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress
                                      .postalCode
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress &&
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress.country
                              ? _c(
                                  "span",
                                  { staticClass: "delivery-address" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetails.deliveryGroup[0].value
                                          .splitEntries[0].deliveryAddress
                                          .country.isocode
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                  },
                  [
                    _c("div", { staticClass: "order-info-heading pb-xs-2" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderDetails.billedTo) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.orderDetails.paymentInfo &&
                    _vm.orderDetails.paymentInfo.cardType &&
                    _vm.orderDetails.paymentInfo.cardType.name &&
                    (_vm.orderDetails.paymentInfo.cardType.name.toLowerCase() ===
                      _vm.paymentTypes.googlePay.toLowerCase() ||
                      _vm.orderDetails.paymentInfo.cardType.name.toLowerCase() ===
                        _vm.paymentTypes.paypal.toLowerCase())
                      ? _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.accountHolderName
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.orderDetails.paymentInfo.cardNumber)
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.cardType.name
                                )
                              )
                            ])
                          ]
                        )
                      : _vm.orderDetails.paymentInfo &&
                        _vm.orderDetails.paymentInfo.cardType
                      ? _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.accountHolderName
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.cardType.name
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.orderDetails.paymentInfo.cardNumber)
                              )
                            ]),
                            _vm._v(" "),
                            _vm.orderDetails.paymentInfo.expiryMonth
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderDetails.paymentInfo.expiryMonth
                                    ) +
                                      "/" +
                                      _vm._s(
                                        _vm.orderDetails.paymentInfo.expiryYear
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderDetails.paymentInfo.billingAddress
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderDetails.paymentInfo
                                        .billingAddress.postalCode
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.orderDetails.notAvailable) +
                                "\n        "
                            )
                          ]
                        )
                  ]
                ),
                _vm._v(" "),
                _vm.globals.isB2B() && !_vm.isMultiple
                  ? [
                      _vm._l(
                        _vm.orderDetails.deliveryGroup[0].value.splitEntries,
                        function(entry) {
                          return [
                            _vm.checkInstallable(
                              _vm.formattedEntries[entry.entryNumber]
                            )
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "order-info-heading pb-xs-2"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.i18n.orderDetails.installation
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "order-info" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.getInstallDate(
                                              _vm.orderDetails
                                                .scheduleInstallation
                                                .preferredDate
                                            )
                                          ) +
                                          "\n              |\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "install-time" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.orderDetails
                                                  .scheduleInstallation
                                                  .preferredTime
                                              )
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderDetails.isSubscription
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "order-info-heading pb-xs-2" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.i18n.orderDetails.subscriptionHeading
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "order-info shipping-method" },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.globals.navigateToUrl(
                                        "subscriptionLanding"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.i18n.orderDetails.manageSubscription
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                !_vm.orderDetails.isSubscription
                  ? [
                      !_vm.isMultiple
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "order-info-heading pb-xs-2" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.i18n.orderDetails.deliveryMethod
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.orderDetails.deliveryMode &&
                              Object.keys(_vm.orderDetails).length != 0
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "order-info shipping-method"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.orderDetails.deliveryMode.name
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                : _vm.orderDetails.deliveryGroup[0].value
                                    .deliveryMode
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "order-info shipping-method"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.orderDetails.deliveryGroup[0]
                                                .value.deliveryMode.name
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isMultiple && !_vm.orderDetails.isSubscription
                        ? [
                            _vm.orderDetails.deliveryMode &&
                            Object.keys(_vm.orderDetails).length != 0
                              ? [
                                  _vm.orderDetails.deliveryMode.description
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-info-heading pb-xs-2"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.i18n.orderDetails
                                                      .deliveryInstructions
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "order-info pr-sm-3"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.orderDetails
                                                      .deliveryMode.description
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              : [
                                  _vm.orderDetails.deliveryGroup[0].value
                                    .deliveryInstruction
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-info-heading pb-xs-2"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.i18n.orderDetails
                                                      .deliveryInstructions
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "order-info pr-sm-3"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.orderDetails
                                                      .deliveryGroup[0].value
                                                      .deliveryInstruction
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                          ]
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.globals.isB2B()
                  ? [
                      _vm.checkLeasable(_vm.orderDetails.deliveryGroup)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "order-info-heading pb-xs-2" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.i18n.orderDetails.leaseAgreement
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "order-info" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.orderDetails.leaseName) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "order-info" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        _vm.globals.getNavBaseUrl() +
                                        _vm.globals.navigations.leasePage +
                                        "/?" +
                                        _vm.order.orderId +
                                        "=" +
                                        _vm.orderCode,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.i18n.orderDetails.download)
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderDetails.refundAmt
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-3 col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "order-info-heading pb-xs-2" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.i18n.orderDetails.refund) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "order-info-heading pb-xs-2" },
                            [
                              _vm._v(
                                "\n            $" +
                                  _vm._s(_vm.orderDetails.refundAmt) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "col-xs-12 px-xs-0 mt-xs-4 mb-xs-0 contact-us-section pb-xs-4"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderDetails.contactUsHeading) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      ref: "emailUsBtn",
                      staticClass: "btn btn-tertiary mt-xs-4 customer-service",
                      attrs: { id: "emailUsBtn" },
                      on: {
                        click: function($event) {
                          _vm.emailCustomerSupport()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderDetails.sendEmailButton) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-tertiary mt-sm-4 ml-sm-4 mr-sm-4 customer-service",
                      attrs: { href: "tel:+1-866-435-5647" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderDetails.callUsButton) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-tertiary mt-sm-4 customer-service",
                      on: {
                        click: function($event) {
                          _vm.chatCustomerSupport()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderDetails.chatUsButton) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-12 px-xs-0 pt-xs-5 pb-xs-3" },
                  [
                    _c(
                      "span",
                      { staticClass: "col-xs-11 px-xs-0 sub-title" },
                      [
                        _vm.orderDetails.isSubscription
                          ? [
                              _c(
                                "p",
                                { staticClass: "col-xs-12 px-xs-0 sub-title" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.i18n.orderDetails.itemOrdered
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          : [
                              _c(
                                "p",
                                { staticClass: "col-xs-12 px-xs-0 sub-title" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.i18n.orderDetails.itemsOrdered
                                      ) +
                                      "\n              (" +
                                      _vm._s(_vm.orderDetails.totalProductQty) +
                                      ")\n            "
                                  )
                                ]
                              )
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    !_vm.globals.isB2C()
                      ? _c("span", {
                          staticClass:
                            "icon-add-to-list col-xs-1 px-xs-0 d-flex flex-row-reverse",
                          attrs: {
                            title: _vm.i18n.orderDetails.iconAddToListTitle,
                            "aria-label":
                              _vm.i18n.orderDetails.iconAddToListTitle,
                            tabindex: "0",
                            role: "button"
                          },
                          on: {
                            click: function($event) {
                              _vm.handleSaveList($event)
                            },
                            keyup: function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter-space",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              _vm.handleSaveList($event)
                            }
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.renderData, function(consignment, index) {
                  return _c(
                    "div",
                    { staticClass: "px-xs-0 mb-xs-5 col-xs-12" },
                    [
                      _vm.isMultiple
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 px-sm-4 py-sm-4  px-xs-3 py-xs-3 multi-shipping-details d-flex flex-row flex-wrap"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-xs-12 col-sm-6 px-xs-0" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "order-info-heading pb-xs-2"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.i18n.orderDetails.shippedTo
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "order-info d-flex flex-column pr-xs-3"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "user-name mb-xs-0" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "delivery-address" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  consignment.shippingAddress
                                                    .firstName
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "delivery-address" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  consignment.shippingAddress
                                                    .lastName
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      consignment.shippingAddress &&
                                      consignment.shippingAddress.companyName
                                        ? _c(
                                            "span",
                                            { staticClass: "delivery-address" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  consignment.shippingAddress
                                                    .companyName
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "delivery-address" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              consignment.shippingAddress.line1
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      consignment.shippingAddress.line2
                                        ? _c(
                                            "span",
                                            { staticClass: "delivery-address" },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    consignment.shippingAddress
                                                      .line2
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("span", [
                                        _c(
                                          "span",
                                          { staticClass: "delivery-address" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                consignment.shippingAddress.town
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "delivery-address" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  consignment.shippingAddress
                                                    .region.isocodeShort
                                                )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "delivery-address" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                consignment.shippingAddress
                                                  .postalCode
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      consignment.shippingAddress &&
                                      consignment.shippingAddress.country
                                        ? _c(
                                            "span",
                                            { staticClass: "delivery-address" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  consignment.shippingAddress
                                                    .country.isocode
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              consignment.statusList[0].renderObj
                                .unShippedProducts.length
                                ? [
                                    consignment.deliveryMode.value
                                      .deliveryInstruction &&
                                    !_vm.orderDetails.isSubscription
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-xs-12 col-sm-6 pt-xs-4 px-xs-0 pt-sm-0"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "order-info-heading pb-xs-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.i18n.orderDetails
                                                          .deliveryInstructions
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "order-info" },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        consignment.deliveryMode
                                                          .value
                                                          .deliveryInstruction
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xs-12 col-sm-6 px-xs-0",
                                        class: consignment.deliveryMode.value
                                          .deliveryInstruction
                                          ? "pt-xs-4"
                                          : ""
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "order-info-heading pb-xs-2"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.i18n.orderDetails
                                                    .deliveryMethod
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "order-info shipping-method"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  consignment.deliveryMode.value
                                                    .splitEntries[0]
                                                    .deliveryMode.name
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                : [
                                    consignment.deliveryMode.value
                                      .deliveryInstruction &&
                                    !_vm.orderDetails.isSubscription
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-xs-12 col-sm-6 pt-xs-4 px-xs-0 pt-sm-0"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "order-info-heading pb-xs-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.i18n.orderDetails
                                                          .deliveryInstructions
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "order-info" },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        consignment.deliveryMode
                                                          .value
                                                          .deliveryInstruction
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xs-12 col-sm-6 px-xs-0 pt-xs-4"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "order-info-heading pb-xs-2"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.i18n.orderDetails
                                                    .deliveryMethod
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "order-info shipping-method"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getShippingMethod(
                                                    consignment.deliveryMode
                                                      .value.deliveryMode.name
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                              _vm._v(" "),
                              _vm.globals.isB2B()
                                ? [
                                    _vm._l(
                                      _vm.orderDetails.deliveryGroup[index]
                                        .value.splitEntries,
                                      function(entry) {
                                        return [
                                          _vm.checkInstallable(
                                            _vm.formattedEntries[
                                              entry.entryNumber
                                            ]
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-xs-12 col-sm-6 pt-xs-4 px-xs-0"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order-info-heading pb-xs-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.i18n
                                                              .orderDetails
                                                              .installation
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "order-info"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.getInstallDate(
                                                              _vm.orderDetails
                                                                .scheduleInstallation
                                                                .preferredDate
                                                            )
                                                          ) +
                                                          "\n                  |\n                  "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "install-time"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.orderDetails
                                                                  .scheduleInstallation
                                                                  .preferredTime
                                                              )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(consignment.statusList, function(
                        statusData,
                        index
                      ) {
                        return [
                          statusData.renderObj.unShippedProducts.length
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 px-sm-4 py-sm-3 py-xs-2 py-xs-2 tracking-details d-flex flex-row flex-wrap",
                                    class: {
                                      "single-tracking": !_vm.isMultiple
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-xs-6 px-xs-0" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "order-info-heading pb-xs-2"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.i18n.orderDetails
                                                    .trackingStatus
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "order-info" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  statusData.consignmentEntryStatus
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  statusData.renderObj.unShippedProducts,
                                  function(productEntry) {
                                    return productEntry.orderEntry.visible
                                      ? _c(
                                          "vx-product-tile",
                                          {
                                            staticClass:
                                              "px-xs-0 col-xs-12 unshipped-products"
                                          },
                                          [
                                            productEntry &&
                                            productEntry.orderEntry &&
                                            productEntry.orderEntry.product &&
                                            productEntry.orderEntry.product
                                              .images &&
                                            productEntry.orderEntry.product
                                              .images.length !== 0 &&
                                            productEntry.orderEntry.product
                                              .images[2] &&
                                            productEntry.orderEntry.product
                                              .images[2].url
                                              ? _c(
                                                  "template",
                                                  { slot: "image-slot" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            _vm.globals.getNavigationUrl(
                                                              "empty"
                                                            ) +
                                                            productEntry
                                                              .orderEntry
                                                              .product.url
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            alt:
                                                              productEntry
                                                                .orderEntry
                                                                .product.name,
                                                            src: _vm.globals.getThumbnailImageUrl(
                                                              productEntry
                                                                .orderEntry
                                                                .product.images
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !productEntry.orderEntry.product
                                              .images
                                              ? _c(
                                                  "template",
                                                  { slot: "image-slot" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            _vm.globals.getNavigationUrl(
                                                              "empty"
                                                            ) +
                                                            productEntry
                                                              .orderEntry
                                                              .product.url
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            alt: "no image",
                                                            src:
                                                              _vm.globals
                                                                .assetsPath +
                                                              "images/no_image.svg"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "template",
                                              { slot: "details" },
                                              [
                                                productEntry &&
                                                productEntry.orderEntry &&
                                                productEntry.orderEntry
                                                  .product &&
                                                productEntry.orderEntry.product
                                                  .name
                                                  ? [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "product-title mb-xs-2"
                                                        },
                                                        [
                                                          _c("a", {
                                                            attrs: {
                                                              href:
                                                                _vm.globals.getNavigationUrl(
                                                                  "empty"
                                                                ) +
                                                                productEntry
                                                                  .orderEntry
                                                                  .product.url
                                                            },
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                productEntry
                                                                  .orderEntry
                                                                  .product.name
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.i18n.orderDetails
                                                          .itemId
                                                      ) +
                                                        " :\n                  " +
                                                        _vm._s(
                                                          productEntry
                                                            .orderEntry.product
                                                            .code
                                                        )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "product-code mb-xs-2",
                                                    attrs: {
                                                      "aria-hidden": "true"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.i18n.orderDetails
                                                            .itemId
                                                        ) +
                                                        "\n                  " +
                                                        _vm._s(
                                                          productEntry
                                                            .orderEntry.product
                                                            .code
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                productEntry.orderEntry
                                                  .product &&
                                                productEntry.orderEntry.product
                                                  .materialStatus ===
                                                  _vm.ProductAvailability
                                                    .OBSOLETE &&
                                                productEntry.orderEntry.product
                                                  .replacementProductCode &&
                                                productEntry.orderEntry.product
                                                  .replacementProductCode !== ""
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "product-stock mb-xs-3"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .orderDetails
                                                                .discontinued
                                                            ) +
                                                            "\n                  " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .orderDetails
                                                                .replacedBy
                                                            ) +
                                                            "\n                  " +
                                                            _vm._s(
                                                              productEntry
                                                                .orderEntry
                                                                .product
                                                                .replacementProductCode
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                productEntry.orderEntry
                                                  .product &&
                                                productEntry.orderEntry.product
                                                  .materialStatus ===
                                                  _vm.ProductAvailability
                                                    .OBSOLETE &&
                                                !productEntry.orderEntry.product
                                                  .replacementProductCode
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "product-stock mb-xs-3"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .orderDetails
                                                                .discontinued
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                productEntry.orderEntry
                                                  .product &&
                                                productEntry.orderEntry.product
                                                  .materialStatus !==
                                                  _vm.ProductAvailability
                                                    .OBSOLETE
                                                  ? [
                                                      productEntry.orderEntry
                                                        .product.stock
                                                        .stockLevelStatus !==
                                                        _vm.ProductAvailability
                                                          .IN_STOCK &&
                                                      productEntry.orderEntry
                                                        .product.stock
                                                        .stockLevelStatus !==
                                                        _vm.ProductAvailability
                                                          .LOW_STOCK &&
                                                      !productEntry.orderEntry
                                                        .product.stock
                                                        .isProductUnavailable
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "product-stock mb-xs-3"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    productEntry
                                                                      .orderEntry
                                                                      .product
                                                                      .stock
                                                                      .stockLevelStatus
                                                                  ) +
                                                                  "\n                  "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "product-quantity mb-xs-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.i18n.orderDetails
                                                            .quantity
                                                        ) +
                                                        " : " +
                                                        _vm._s(
                                                          productEntry.quantity
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                productEntry.orderEntry
                                                  .product &&
                                                productEntry.orderEntry.product
                                                  .notPurchasable
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "error-text mb-xs-3"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .orderDetails
                                                                .notPurchasable
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.checkForAppliedVouchers()
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "product-promo mb-xs-3 mr-sm-3"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .orderDetails
                                                                .promo
                                                            ) +
                                                            ":\n                  " +
                                                            _vm._s(
                                                              _vm.orderDetails
                                                                .appliedVouchers[0]
                                                                .code
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.orderDetails
                                                  .isSubscription &&
                                                _vm.formattedEntries[
                                                  productEntry.orderEntry
                                                    .entryNumber
                                                ].subscriptionFrequency &&
                                                _vm.formattedEntries[
                                                  productEntry.orderEntry
                                                    .entryNumber
                                                ].subscriptionFrequency.entry
                                                  ? _c(
                                                      "p",
                                                      {
                                                        ref: "subscribeInfo",
                                                        refInFor: true,
                                                        staticClass:
                                                          "subscription"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .orderDetails
                                                                .subscribeFrequencyHeading
                                                            ) +
                                                            _vm._s(
                                                              _vm
                                                                .formattedEntries[
                                                                productEntry
                                                                  .orderEntry
                                                                  .entryNumber
                                                              ]
                                                                .subscriptionFrequency
                                                                .entry[0].key
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                        _c("span", {
                                                          staticClass:
                                                            "icon-alert-circle popover-subscribe",
                                                          attrs: {
                                                            role: "button",
                                                            tabindex: "0",
                                                            "aria-label":
                                                              _vm.i18n
                                                                .orderDetails
                                                                .subscribeIconTitle,
                                                            "data-toggle":
                                                              "popover"
                                                          },
                                                          on: {
                                                            mouseover: function(
                                                              $event
                                                            ) {
                                                              _vm.openTooltip()
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.globals.isB2C() &&
                                                _vm.globals.siteConfig
                                                  .isGiftingEnabled
                                                  ? [
                                                      _vm.checkGiftable(
                                                        productEntry.orderEntry
                                                      )
                                                        ? [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "helper-text mb-xs-3"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.i18n
                                                                        .orderDetails
                                                                        .giftable
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            productEntry
                                                              .orderEntry
                                                              .giftMessage
                                                              ? [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "gift-message-heading mb-xs-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .i18n
                                                                              .orderDetails
                                                                              .giftMessageHeading
                                                                          ) +
                                                                          "\n                      "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "gift-message"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            productEntry
                                                                              .orderEntry
                                                                              .giftMessage
                                                                          ) +
                                                                          "\n                      "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              : _vm._e()
                                                          ]
                                                        : _vm._e()
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.globals.isB2B()
                                                  ? [
                                                      _vm.installableProducts[
                                                        productEntry.orderEntry
                                                          .entryNumber
                                                      ]
                                                        ? [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "helper-text mb-xs-3"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.i18n
                                                                        .orderDetails
                                                                        .installable
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "template",
                                              { slot: "right-section-slot" },
                                              [
                                                _vm.orderDetails.isSubscription
                                                  ? [
                                                      _vm.hasSubscriptionPriceFormattedVal()
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "product-total-price",
                                                              attrs: {
                                                                role: "text",
                                                                "aria-label":
                                                                  "total Price " +
                                                                  _vm
                                                                    .orderDetails
                                                                    .totalPriceWithTax
                                                                    .formattedValue
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    $" +
                                                                  _vm._s(
                                                                    (
                                                                      _vm
                                                                        .orderDetails
                                                                        .totalSubscriptionPrice
                                                                        .value -
                                                                      _vm
                                                                        .orderDetails
                                                                        .promotionalDiscounts
                                                                        .value
                                                                    ).toFixed(2)
                                                                  ) +
                                                                  "\n                  "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  : [
                                                      productEntry &&
                                                      productEntry.consignmentEntryPrice &&
                                                      productEntry
                                                        .consignmentEntryPrice
                                                        .formattedValue
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "product-total-price mb-xs-3"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    $" +
                                                                  _vm._s(
                                                                    (
                                                                      productEntry
                                                                        .consignmentEntryPrice
                                                                        .value -
                                                                      productEntry
                                                                        .orderEntry
                                                                        .productPromotion
                                                                        .value
                                                                    ).toFixed(2)
                                                                  ) +
                                                                  "\n                  "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                _vm._v(" "),
                                                _vm.globals.siteConfig
                                                  .reorderEnabled &&
                                                !_vm.orderDetails
                                                  .isSubscription &&
                                                !productEntry.orderEntry.product
                                                  .stock.isProductUnavailable
                                                  ? [
                                                      _c("vx-reorder-product", {
                                                        attrs: {
                                                          "single-product-data": {
                                                            product:
                                                              productEntry
                                                                .orderEntry
                                                                .product,
                                                            quantity:
                                                              productEntry.quantity
                                                          },
                                                          i18n:
                                                            _vm.i18n
                                                              .reorderButton
                                                        }
                                                      })
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  }
                                )
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(statusData.renderObj.trackingList, function(
                            trackingInfo
                          ) {
                            return statusData.renderObj.trackingList.length
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 px-sm-4 py-sm-3 py-xs-2 py-xs-2 tracking-details d-flex flex-row flex-wrap",
                                      class: {
                                        "single-tracking": !_vm.isMultiple
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-xs-6 px-xs-0" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-info-heading pb-xs-2"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.i18n.orderDetails
                                                      .trackingStatus
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "order-info" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    trackingInfo.products[0]
                                                      .consignmentEntryStatus
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-xs-6 px-xs-0" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-info-heading pb-xs-2"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.i18n.orderDetails
                                                      .trackingNumber
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "order-info" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    target: "_blank",
                                                    href:
                                                      trackingInfo.trackingURL
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      trackingInfo.trackingID
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(trackingInfo.products, function(
                                    productEntry
                                  ) {
                                    return productEntry.orderEntry.visible
                                      ? _c(
                                          "vx-product-tile",
                                          { staticClass: "px-xs-0 col-xs-12" },
                                          [
                                            productEntry &&
                                            productEntry.orderEntry &&
                                            productEntry.orderEntry.product &&
                                            productEntry.orderEntry.product
                                              .images &&
                                            productEntry.orderEntry.product
                                              .images.length !== 0 &&
                                            productEntry.orderEntry.product
                                              .images[2] &&
                                            productEntry.orderEntry.product
                                              .images[2].url
                                              ? _c(
                                                  "template",
                                                  { slot: "image-slot" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            _vm.globals.getNavigationUrl(
                                                              "empty"
                                                            ) +
                                                            productEntry
                                                              .orderEntry
                                                              .product.url
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            alt:
                                                              productEntry
                                                                .orderEntry
                                                                .product.name,
                                                            src: _vm.globals.getThumbnailImageUrl(
                                                              productEntry
                                                                .orderEntry
                                                                .product.images
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !productEntry.orderEntry.product
                                              .images
                                              ? _c(
                                                  "template",
                                                  { slot: "image-slot" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            _vm.globals.getNavigationUrl(
                                                              "empty"
                                                            ) +
                                                            productEntry
                                                              .orderEntry
                                                              .product.url
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            alt: "no image",
                                                            src:
                                                              _vm.globals
                                                                .assetsPath +
                                                              "images/no_image.svg"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "template",
                                              { slot: "details" },
                                              [
                                                productEntry &&
                                                productEntry.orderEntry &&
                                                productEntry.orderEntry
                                                  .product &&
                                                productEntry.orderEntry.product
                                                  .name
                                                  ? [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "product-title mb-xs-2"
                                                        },
                                                        [
                                                          _c("a", {
                                                            attrs: {
                                                              href:
                                                                _vm.globals.getNavigationUrl(
                                                                  "empty"
                                                                ) +
                                                                productEntry
                                                                  .orderEntry
                                                                  .product.url
                                                            },
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                productEntry
                                                                  .orderEntry
                                                                  .product.name
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.i18n.orderDetails
                                                            .itemId
                                                        ) +
                                                        " :\n                  " +
                                                        _vm._s(
                                                          productEntry
                                                            .orderEntry.product
                                                            .code
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "product-code mb-xs-2",
                                                    attrs: {
                                                      "aria-hidden": "true"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.i18n.orderDetails
                                                            .itemId
                                                        ) +
                                                        "\n                  " +
                                                        _vm._s(
                                                          productEntry
                                                            .orderEntry.product
                                                            .code
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                productEntry.orderEntry
                                                  .product &&
                                                productEntry.orderEntry.product
                                                  .materialStatus ===
                                                  _vm.ProductAvailability
                                                    .OBSOLETE &&
                                                productEntry.orderEntry.product
                                                  .replacementProductCode &&
                                                productEntry.orderEntry.product
                                                  .replacementProductCode !== ""
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "product-stock mb-xs-3"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .orderDetails
                                                                .discontinued
                                                            ) +
                                                            "\n                  " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .orderDetails
                                                                .replacedBy
                                                            ) +
                                                            "\n                  " +
                                                            _vm._s(
                                                              productEntry
                                                                .orderEntry
                                                                .product
                                                                .replacementProductCode
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                productEntry.orderEntry
                                                  .product &&
                                                productEntry.orderEntry.product
                                                  .materialStatus ===
                                                  _vm.ProductAvailability
                                                    .OBSOLETE &&
                                                !productEntry.orderEntry.product
                                                  .replacementProductCode
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "product-stock mb-xs-3"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .orderDetails
                                                                .discontinued
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                productEntry.orderEntry
                                                  .product &&
                                                productEntry.orderEntry.product
                                                  .materialStatus !==
                                                  _vm.ProductAvailability
                                                    .OBSOLETE
                                                  ? [
                                                      productEntry.orderEntry
                                                        .product.stock
                                                        .stockLevelStatus !==
                                                        _vm.ProductAvailability
                                                          .IN_STOCK &&
                                                      productEntry.orderEntry
                                                        .product.stock
                                                        .stockLevelStatus !==
                                                        _vm.ProductAvailability
                                                          .LOW_STOCK &&
                                                      !productEntry.orderEntry
                                                        .product.stock
                                                        .isProductUnavailable
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "product-stock mb-xs-3"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    productEntry
                                                                      .orderEntry
                                                                      .product
                                                                      .stock
                                                                      .stockLevelStatus
                                                                  ) +
                                                                  "\n                  "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "product-quantity mb-xs-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.i18n.orderDetails
                                                            .quantity
                                                        ) +
                                                        " : " +
                                                        _vm._s(
                                                          productEntry.quantityShip
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.checkForAppliedVouchers()
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "product-promo mb-xs-3 mr-sm-3"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .orderDetails
                                                                .promo
                                                            ) +
                                                            ":\n                  " +
                                                            _vm._s(
                                                              _vm.orderDetails
                                                                .appliedVouchers[0]
                                                                .code
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.globals.isB2C() &&
                                                _vm.globals.siteConfig
                                                  .isGiftingEnabled
                                                  ? [
                                                      _vm.checkGiftable(
                                                        productEntry.orderEntry
                                                      )
                                                        ? [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "helper-text mb-xs-3"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.i18n
                                                                        .orderDetails
                                                                        .giftable
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            productEntry
                                                              .orderEntry
                                                              .giftMessage
                                                              ? [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "gift-message-heading mb-xs-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .i18n
                                                                              .orderDetails
                                                                              .giftMessageHeading
                                                                          ) +
                                                                          "\n                      "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "gift-message"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            productEntry
                                                                              .orderEntry
                                                                              .giftMessage
                                                                          ) +
                                                                          "\n                      "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              : _vm._e()
                                                          ]
                                                        : _vm._e()
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.globals.isB2B()
                                                  ? [
                                                      _vm.istallableProducts[
                                                        productEntry.orderEntry
                                                          .entryNumber
                                                      ]
                                                        ? [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "helper-text mb-xs-3"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.i18n
                                                                        .orderDetails
                                                                        .installable
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "template",
                                              { slot: "right-section-slot" },
                                              [
                                                _vm.orderDetails &&
                                                _vm.orderDetails
                                                  .totalSubscriptionPrice
                                                  ? [
                                                      _vm.hasSubscriptionPriceFormattedVal()
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "product-total-price",
                                                              attrs: {
                                                                role: "text",
                                                                "aria-label":
                                                                  "total Price " +
                                                                  _vm
                                                                    .orderDetails
                                                                    .totalPriceWithTax
                                                                    .formattedValue
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    $" +
                                                                  _vm._s(
                                                                    (
                                                                      _vm
                                                                        .orderDetails
                                                                        .totalSubscriptionPrice
                                                                        .value -
                                                                      _vm
                                                                        .orderDetails
                                                                        .promotionalDiscounts
                                                                        .value
                                                                    ).toFixed(2)
                                                                  ) +
                                                                  "\n                  "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  : [
                                                      productEntry &&
                                                      productEntry.price &&
                                                      !productEntry.orderEntry
                                                        .product.notPurchasable
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "product-total-price mb-xs-3"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    $" +
                                                                  _vm._s(
                                                                    productEntry.orderEntry.totalPrice.value.toFixed(
                                                                      2
                                                                    )
                                                                  ) +
                                                                  "\n                  "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                _vm._v(" "),
                                                _vm.globals.siteConfig
                                                  .reorderEnabled &&
                                                !_vm.orderDetails
                                                  .isSubscription &&
                                                !productEntry.orderEntry.product
                                                  .stock.isProductUnavailable
                                                  ? [
                                                      _c("vx-reorder-product", {
                                                        attrs: {
                                                          "single-product-data": {
                                                            product:
                                                              productEntry
                                                                .orderEntry
                                                                .product,
                                                            quantity:
                                                              productEntry.quantity
                                                          },
                                                          i18n:
                                                            _vm.i18n
                                                              .reorderButton
                                                        }
                                                      })
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  })
                                ]
                              : _vm._e()
                          })
                        ]
                      })
                    ],
                    2
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-md-4 col-sm-6 col-xs-12 px-xs-0 order-summary-container"
              },
              [
                _c(
                  "vx-order-summary",
                  {
                    staticClass: "ml-sm-5 ml-xs-0",
                    attrs: {
                      i18n: _vm.i18n.orderSummary,
                      "order-details": _vm.orderDetails,
                      page: "orderDetails",
                      "is-shipping-multiple": _vm.isMultiple,
                      "is-giftable": _vm.isGiftable,
                      "is-installable": _vm.isInstallable,
                      "contact-number": _vm.contactNumber
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "order-summary-buttons-container px-sm-4 px-xs-3",
                        attrs: { slot: "bottomButton" },
                        slot: "bottomButton"
                      },
                      [
                        _vm.showCancelOrderButton
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-block btn-default mb-xs-5 form-blue-button",
                                on: {
                                  click: function($event) {
                                    _vm.handleCancelOrder($event)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.i18n.orderDetails.cancelOrder) +
                                    "\n          "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.globals.siteConfig.reorderEnabled &&
                        !_vm.orderDetails.isSubscription &&
                        !(
                          _vm.orderDetails.entries.length === 1 &&
                          _vm.orderDetails.entries[0].product.stock
                            .isProductUnavailable
                        )
                          ? [
                              _c("vx-reorder-product", {
                                attrs: {
                                  "multiple-products-data":
                                    _vm.formattedEntries,
                                  i18n: _vm.i18n.reorderButton
                                }
                              })
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d5bd647c", { render: render, staticRenderFns: staticRenderFns })
  }
}