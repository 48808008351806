var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-user-groups-landing px-xs-0 pt-xs-4 pt-sm-0 mt-sm-5" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "createUserGroupModal",
          attrs: { size: "large", heading: _vm.i18n.addUserGroup },
          on: { close: _vm.resetValues }
        },
        [
          _c(
            "div",
            {
              staticClass: "row",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c(
                "form",
                {
                  staticClass: "col-xs-12",
                  attrs: { novalidate: "true" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.addUserGroup($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" },
                      [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "id",
                              "aria-hidden": _vm.isDeviceAndroid()
                            }
                          },
                          [_vm._v(_vm._s(_vm.i18n.id))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userGroupDetails.id,
                              expression: "userGroupDetails.id"
                            }
                          ],
                          staticClass: "form-control",
                          class: { "input-error": _vm.errors.has("id") },
                          attrs: {
                            type: "text",
                            maxlength: "35",
                            name: "id",
                            "aria-label": _vm.i18n.id
                          },
                          domProps: { value: _vm.userGroupDetails.id },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.userGroupDetails,
                                "id",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.has("id")
                          ? _c("div", { staticClass: "mt-xs-2" }, [
                              _c("span", { staticClass: "error-msg" }, [
                                _vm._v(_vm._s(_vm.errors.first("id")))
                              ])
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" },
                      [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "userGroupName",
                              "aria-hidden": _vm.isDeviceAndroid()
                            }
                          },
                          [_vm._v(_vm._s(_vm.i18n.userGroupName))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userGroupDetails.userGroupName,
                              expression: "userGroupDetails.userGroupName"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "input-error": _vm.errors.has("userGroupName")
                          },
                          attrs: {
                            type: "text",
                            maxlength: "35",
                            name: "userGroupName",
                            "aria-label": "i18n.userGroupName"
                          },
                          domProps: {
                            value: _vm.userGroupDetails.userGroupName
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.userGroupDetails,
                                "userGroupName",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.has("userGroupName")
                          ? _c("div", { staticClass: "mt-xs-2" }, [
                              _c("span", { staticClass: "error-msg" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first("userGroupName"))
                                )
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "mb-xs-3 mb-sm-5 form-group col-xs-12 col-md-6"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "dropdown-label",
                            attrs: {
                              for: "businessUnitDropdown",
                              "aria-hidden": !_vm.isTablet()
                            }
                          },
                          [_vm._v(_vm._s(_vm.i18n.businessUnit))]
                        ),
                        _vm._v(" "),
                        _c("vx-dropdown-primary", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          ref: "businessUnitDropdown",
                          attrs: {
                            "describedby-aria": _vm.errors.has("businessUnit")
                              ? "business_unit_error_msg"
                              : null,
                            "required-aria": "true",
                            dropdownValues: _vm.businessUnitsList,
                            "aria-label": _vm.i18n.businessUnit,
                            name: "businessUnit",
                            dropdownError: _vm.errors.has("businessUnit")
                          },
                          on: {
                            "selected-option": function($event) {
                              _vm.userGroupDetails.businessUnit = $event
                            }
                          },
                          model: {
                            value: _vm.userGroupDetails.businessUnit.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.userGroupDetails.businessUnit,
                                "value",
                                $$v
                              )
                            },
                            expression: "userGroupDetails.businessUnit.value"
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.has("businessUnit")
                          ? _c("div", { staticClass: "mt-xs-2" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "error-msg",
                                  attrs: {
                                    "aria-live": "assertive",
                                    id: "business_unit_error_msg"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("businessUnit"))
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.isUserGroupExists
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 alert-msg p-xs-3 mb-xs-5 mt-xs-3"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.isUserGroupExistsError) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xs-12 text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary save-btn modal-btn-primary-medium modal-btn-xs-sticky",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.i18n.save))]
                    )
                  ])
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-between " }, [
        _c("h3", { staticClass: "mt-xs-0" }, [
          _vm._v(_vm._s(_vm.i18n.userGroups))
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn-link add-create-btn",
            attrs: {
              href: "javascript:void(0)",
              role: "button",
              "aria-label": _vm.i18n.createUserGroup
            },
            on: {
              click: function($event) {
                _vm.openAddUserGroupModal($event)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.i18n.createUserGroup) + "\n    ")]
        )
      ]),
      _vm._v(" "),
      _vm.isMobile()
        ? _c(
            "div",
            { staticClass: "mb-xs-3" },
            [
              _c("vx-dropdown-primary", {
                ref: "sortDropdown",
                attrs: { dropdownValues: _vm.sortOptions },
                on: {
                  "selected-option": function($event) {
                    _vm.triggerSort($event, "#usergroups-data-table")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("vx-table", {
        attrs: { tableConfig: _vm.tableConfig, tableData: _vm.users }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-58de8dd1", { render: render, staticRenderFns: staticRenderFns })
  }
}