var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.globals.siteConfig.showProductIcons &&
    _vm.productIconsData.length &&
    !(
      _vm.productIconsData.length === 1 &&
      _vm.productIconsData[0].title === "Certifications Available"
    )
    ? _c("div", { staticClass: "vx-product-icons" }, [
        _c(
          "ul",
          _vm._l(_vm.productIconsData, function(productIcon) {
            return _c("li", [
              productIcon.title !== "Certifications Available"
                ? _c("span", [
                    _c("span", {
                      class: productIcon.className,
                      attrs: {
                        "aria-hidden": "true",
                        title: productIcon.title,
                        "aria-label": productIcon.title
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(productIcon.title) +
                          "\n                "
                      )
                    ])
                  ])
                : _vm._e()
            ])
          })
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3b3a6a90", { render: render, staticRenderFns: staticRenderFns })
  }
}