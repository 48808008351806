export default {
  topHeading: 'All major credit and debit cards accepted.',
  sideHeading: 'Payment Method',
  cardNumber: 'Card Number',
  cardHolderName: 'Name on Card',
  cardExpiration: 'Expiration',
  cardCvv: 'CVV',
  month: 'Month',
  year: 'Year',
  cardCvvQuery: 'What is this?',
  selectAddress: 'Select Address',
  selectAnAddress: 'Select an Address',
  billingAddress: 'Billing Address',
  billingAddress1: 'Billing Address 1',
  billingAddress2: 'Billing Address 2 (optional)',
  city: 'City',
  state: 'State',
  country: 'Country',
  zipcode: 'Zip/Postal Code',
  contactInfo: 'Contact Info',
  email: 'Email Address',
  phone: 'Phone Number',
  saveBtn: 'SAVE',
  saveAndContinueBtn: 'SAVE & CONTINUE',
  lblAddress: 'New Address',
  lblAddressValue: 'NEW',
  userTitle: 'mr',
  cardTypeMaster: 'master card',
  cardTypeVisa: 'visa card',
  cardTypeDiscover: 'discover card',
  cardTypeAmex: 'amex card',
  cardTypeDiners: 'diners card',
  updatePaymentSuccessMsg: 'Payment details updated successfully',
  handleSignatureErrorMessage: 'We are unable to process your payment at this time. Please verify that your payment details are correct and try again. If the problem persists, contact us for further assistance.',
  changeAddress: 'Change Address',
  savedAddresses: 'Saved Addresses',
  addOrEditAddress: 'To add or edit an address, please go to your profile',
  invalidFieldExpiration: 'Field expiration is not valid!',
  defaultPaymentMethod: 'Make Default Payment Method',
  cardnumberRequiredError: 'Please enter in a card number.',
  cardnumberValidError: 'The card number is invalid, please try again.',
  cardnumberMaxError: 'The card number is invalid, please try again.',
  cardholdernameRequiredError: 'Please enter a valid Name on Card',
  cardholdernameRegexError: 'Please enter a valid Name on Card',
  cardholdernameMaxError: 'Please enter a valid Name on Card',
  cardholderInvalidNameError: 'Please enter a valid Name on Card',
  cardcvnRequiredError: 'Please enter in a CVV number.',
  cardcvnRegexError: 'The CVV number entered is invalid, please try again.',
  addressline1RequiredError: 'Please enter in a valid Billing Address 1',
  addressline1MaxError: 'Maximum 60 characters are allowed',
  addressline2RequiredError: 'Please enter in a valid Billing Address 2',
  addressline2MaxError: 'Maximum 60 characters are allowed',
  cityRequiredError: 'Please enter in a valid City.',
  cityMaxError: 'Maximum 40 characters are allowed',
  postalcodeRequiredError: 'Please enter in a valid Zip/Postal Code',
  postalcodeMaxError: 'Maximum 30 characters are allowed',
  postalcodeRegexError: 'Please enter in a valid Zip/Postal Code',
  emailRequiredError: 'Please enter in an Email Address',
  emailInvalidError: 'The Email Address is not valid, please try again.',
  emailMaxError: 'Maximum 80 characters are allowed',
  phoneRequiredError: 'Please enter in a Phone Number',
  phoneMinError: 'The Phone Number entered is not valid, please try again.',
  phoneMaxError: 'Maximum 30 characters are allowed',
  monthRequiredError: 'Please select a Month',
  monthValidError: 'Please select correct expiration Month',
  yearRequiredError: 'Please select a Year',
  yearValidError: 'Please select correct expiration Year',
  addressRequiredError: 'Please select an Address',
  countryRequiredError: 'Please select a Country',
  stateRequiredError: 'Please select a State',
  dropdownLabel: 'Select option',
  saveForLater: 'Save card for later use',
  addPaymentHeading: 'Add Payment Method',
  useShippingMethod: 'Use Shipping Address',
};
