const manageB2BOrg = {
    businessUnit: {
        unitDetailheading: 'Business Units',
        companyLabel: 'Unit Name',
        addressLabel: 'Address',
        infoLabel: 'Other Info',
        editText: 'Edit',
        disableUnitText: 'Disable Unit',
        enableUnitText: 'Enable Unit',
        childInfogroupLabel: 'Child Units',
        addText: 'Add',
        newText: 'New',
        existingText: 'Existing',
        disabledText: 'Disabled',
        adminInfogroupLabel: 'Administrators',
        buyerInfogroupLabel: 'Buyers',
        detailsText: 'Details',
        disableText: 'Disable',
        disableUnitheading: 'Disable Unit',
        enableText: 'Enable',
        enableUnitheading: 'Enable Unit',
        enableUnitMessage: 'Warning! Enabling this unit will also enable its child units, Permissions and Addresses.',
        removeModalHeading: 'Remove from Parent Unit',
        removeModalMessage: 'Are you sure you want to remove this user from this b2b unit?',
        addExistingheading: 'Add Existing',
        removeText: 'Remove',
        saveText: 'Save',
        submitText: 'Submit',
        addUser: 'Add User',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email Address',
        parentUnit: 'Parent Unit',
        selectRole: 'Select Role',
        permissions: 'Permissions',
        leaseSigner: 'Lease Signer',
        admin: 'Admin',
        buyer: 'Buyer',
        user: 'User',
        firstNameRequiredError: 'Please provide your First Name',
        lastNameRequiredError: 'Please provide your Last Name',
        emailRequiredError: 'Please enter the email address',
        emailInvalidError: 'Please enter a valid email address',
        parentUnitDropdownError: 'Please enter a valid parent unit',
        emailExists: 'An account already exists for this email address. Please use a new or different email address.',
        addChildUnitheading: 'Add Units',
        companyName: 'Company Name',
        editChildheading: 'Edit',
        administrator: 'Administrator',
        companyModalHeading: 'Edit Company Details',
        companyDetailsDataModalHeading: 'Enter company details',
        phoneMinError: 'Please enter valid phone number',
        companyDetailsDecription: 'Company Details',
        addressPart1: 'Address Line 1',
        addressPart2: 'Address Line 2',
        city: 'City',
        zipcode: 'Zipcode',
        cmpPhnNo: 'Company Phone Number',
        countryLabel: 'Country',
        other: 'Other',
        skip: 'Skip',
        save: 'Save',
        submit: 'Submit',
        dropdownInput: 'Select Option',
        disableUnitMessage: 'Warning! Disabling this unit will also disable its child units, Permissions and Addresses.',
        invite: 'Invite ',
        currentAddressHeading: 'Current Address',
        verifiedAddressHeading: 'Verified Address',
        useCurrentAddress: 'USE THIS ADDRESS',
        useVerifiedAddress: 'USE THIS ADDRESS',
        firstNameRegexError: 'Please enter in a valid First Name',
        firstNameMaxError: 'First Name should not be more than 35 characters',
        lastNameRegexError: 'Please enter in a valid Last Name',
        lastNameMaxError: 'Last Name should not be more than 35 characters',
        companyNameError: 'Please enter a valid Business Unit name',
        businessUnitRequiredError: 'Please enter a valid Business Unit name',
        inviteUser: 'Email successfully sent to the user',
        iconSuccessTitle: 'Success',
        iconCloseTitle: 'Close',
        iconAlertTitle: 'Important Notice',
        iconDeleteTitle: 'Delete',
        noPermission: 'You do not have permission to view this content.'
    },
    addUserModal: {
        addUser: 'ADD USER',
        firstName: 'FIRST NAME',
        lastName: 'LAST NAME',
        email: 'EMAIL ADDRESS',
        parentUnit: 'Parent Unit',
        selectRole: 'SELECT ROLE',
        permissions: 'PERMISSIONS',
        save: 'SAVE',
        leaseSigner: 'LEASE SIGNER',
        admin: 'ADMIN',
        buyer: 'BUYER',
    },
    usersLanding: {
        users: 'USERS',
        description: 'This is some sample description - test',
        addUsers: 'ADD USERS',
        tableHeaders: 'First Name, Last Name, Role, Parent Unit, Status',
        addUser: 'ADD USER',
        firstName: 'FIRST NAME',
        lastName: 'LAST NAME',
        email: 'EMAIL ADDRESS',
        parentUnit: 'Parent Unit',
        selectRole: 'SELECT ROLE',
        permissions: 'PERMISSIONS',
        save: 'SAVE',
        leaseSigner: 'LEASE SIGNER',
        admin: 'ADMIN',
        buyer: 'BUYER',
        active: 'Active',
        disabled: 'Disabled',
        noDataAvailable: 'No Data Available',
        firstNameRequiredError: 'Please provide your First name',
        lastNameRequiredError: 'Please provide your Last name',
        emailRequiredError: 'Please enter the Email Address',
        emailInvalidError: 'Please enter a valid Email Address',
        parentUnitDropdownError: 'Please enter a valid Parent Unit',
        emailExists:
            'An account already exists for this Email Address. Please login or use a new Email Address.',
        enabled: 'Enabled',
        pending: 'Pending Approval',
        invite: 'INVITE',
        inviteUser: 'Email successfully sent to the user',
        businessUnitRequiredError: 'Please enter a valid Business Unit name',
        b2badmingroup: 'b2badmingroup',
        b2bcustomergroup: 'b2bcustomergroup',
        adminWithLeaseSigner: 'Admin with lease signer',
        buyerWithLeaseSigner: 'Buyer with lease signer',
        submit: 'SUBMIT',
        select: 'Select',
        iconSuccessTitle: 'Success',
        iconCloseTitle: 'Close',
        mobileSortOptions: [
            {
                label: 'First Name A to Z',
                value: '0-asc',
            },
            {
                label: 'First Name Z to A',
                value: '0-dsc',
            },
            {
                label: 'Last Name A to Z',
                value: '1-asc',
            },
            {
                label: 'Last Name Z to A',
                value: '1-dsc',
            },
            {
                label: 'Role Name A to Z',
                value: '2-asc',
            },
            {
                label: 'Role Name Z to A',
                value: '2-dsc',
            },
            {
                label: 'Status A to Z',
                value: '4-asc',
            },
            {
                label: 'Status Z to A',
                value: '4-dsc',
            },
        ],
    },
    userGroupLanding: {
        addUserGroup: 'ADD USER GROUP',
        createUserGroup: 'CREATE USER GROUP',
        id: 'ID',
        userGroupName: 'USER GROUP NAME',
        userGroupDetails: 'USER GROUP DETAILS',
        tableHeaders: 'ID, User Group Name, Business Unit, Status',
        noDataAvailable: '"No user group found"',
        active: 'Active',
        disabled: 'Disabled',
        save: 'SAVE',
        userGroups: 'USER GROUPS',
        description: 'Some sample description - test',
        businessUnit: 'Business Unit',
        idRequiredError: 'Please enter a valid User Group ID.',
        userGroupNameRequiredError: 'Please enter a valid User Group Name.',
        businessUnitError: 'Please select a valid unit',
        mobileSortOptions: [
            {
                label: 'User Group ID A to Z',
                value: '0-asc',
            },
            {
                label: 'User Group ID Z to A',
                value: '0-dsc',
            },
            {
                label: 'User Group Name A to Z',
                value: '1-asc',
            },
            {
                label: 'User Group Name Z to A',
                value: '1-dsc',
            },
            {
                label: 'Business Unit A to Z',
                value: '2-asc',
            },
            {
                label: 'Business Unit Z to A',
                value: '2-dsc',
            },
            {
                label: 'Status A to Z',
                value: '3-asc',
            },
            {
                label: 'Status Z to A',
                value: '3-dsc',
            },
        ],
    },
    userDetails: {
        name: 'NAME',
        email: 'EMAIL',
        contact: 'CONTACT NUMBER',
        status: 'STATUS',
        role: 'ROLE/PERMISSIONS',
        enabled: 'Enabled',
        disabled: 'Disabled',
        resetPassword: 'A link to reset password has been submitted.',
        deleteDefaultUnitError: 'At least one unit should be selected.',
        inviteUser: 'Email successfully sent to the user',
        permissionRemoveMessage: 'Are you sure you want to remove this user from this permission?',
        parentUnitRemoveMessage: 'Are you sure you want to remove this user from this b2b unit?',
        businessUnitRequiredError: 'Please enter a valid Business Unit name',
        b2badmingroup: 'b2badmingroup',
        b2bcustomergroup: 'b2bcustomergroup',
        admin: 'Admin',
        buyer: 'Buyer',
        adminWithLeaseSigner: 'Admin with lease signer',
        buyerWithLeaseSigner: 'Buyer with lease signer',
        phoneMessage: 'User needs to update under profile and preferences',
        emailRequiredError: 'Please enter your Email Address',
        emailInvalidError: 'Please enter a valid Email Address',
        firstNameRequiredError: 'Please provide your First Name',
        firstNameRegexError: 'Please enter in a valid First Name',
        firstNameMaxError: 'First Name should not be more than 35 characters',
        lastNameRequiredError: 'Please provide your Last Name',
        lastNameRegexError: 'Please enter in a valid Last Name',
        lastNameMaxError: 'Last name should not be more than 35 characters',
        noFormChangeMsg: 'No changes has been made to the user details',
        iconSuccessTitle: 'Success',
        iconCloseTitle: 'Close',
        iconAlertTitle: 'Important Notice',
        userStatus: {
            enabled: 'Enabled',
            disabled: 'Disabled',
            pending: 'Pending Approval',
        },
        unitDetail: {
            heading: 'User Details',
            editText: 'Edit',
            disableText: 'Disable User',
            enableText: 'Enable User',
            hyperlinkText: 'Reset Password',
        },
        parentUnits: {
            groupLabel: 'Parent Units',
            addText: 'Add',
            existingText: 'Existing',
            disabledText: 'Disabled',
            iconDeleteTitle: 'Delete',
        },
        permissions: {
            groupLabel: 'Permissions',
            addText: 'Add',
            existingText: 'Existing',
            disabledText: 'Disabled',
            iconDeleteTitle: 'Delete',
        },
        userGroups: {
            groupLabel: 'User Groups',
            addText: 'Add',
            existingText: 'Existing',
            disabledText: 'Disabled',
            iconDeleteTitle: 'Delete',
        },
        removeModal: {
            removeText: 'Remove',
            heading: 'Remove',
            headingText: 'Remove from',
            message: 'Are you sure you want to remove ',
        },
        addExistingModal: {
            heading: 'Add Existing',
            saveText: 'Save',
        },
        disableUserModal: {
            disableText: 'Disable',
            heading: 'Disable User',
            message1: 'Disabling this user will prevent the user from logging in',
            message2: 'will prevent this user from logging in to the storefront and/or placing an order.',
        },
        enableUnitModal: {
            enableText: 'Enable',
            heading: 'Enable User',
            message: 'Are you sure you want to enable the user ',
        },
        editUserModal: {
            editUser: 'Edit User',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email Address',
            parentUnit: 'Parent Unit',
            selectRole: 'Select Role',
            admin: 'Admin',
            buyer: 'Buyer',
            permissions: 'Permissions',
            leaseSigner: 'Lease Signer',
            emailExists:
                'An account already exists for this email address. Please login or use a new email address.',
            save: 'SAVE',
            submit: 'SUBMIT',
            invite: 'INVITE',
            108: 'An account already exists for this email address. Please login or use a new email address.',
        },
    },
    businessUnitLanding: {
        heading: 'BUSINESS UNITS',
        parentSectionHeading: 'Parent Business Unit',
        childSectionHeading: 'Child Business Units',
        adminsText: 'Admins',
        buyersText: 'Buyers',
        administrators: 'Administrators',
        unitName: 'UNIT NAME',
        unitNameRequiredError: 'Please enter a valid business unit name.',
        parentUnitRequiredError: 'Please enter a valid parent unit.',
        unitNameMaxError: 'Unit Name should not be more than 35 characters.',
        addBusinessUnit: 'ADD BUSINESS UNITS',
        viewDetails: 'View Details',
        noDataAvailable: 'No child units found',
        addUnitModalHeading: 'ADD UNIT',
        companyName: 'Company Name',
        parentUnit: 'Parent Unit',
        save: 'Save',
        subHeading: 'A sample sub heading for the business units landing page - test.',
        iconAlertTitle: 'Important Notice',
    },
    userGroupDetails: {
        id: 'ID',
        userGroupName: 'USER GROUP NAME',
        businessUnit: 'Business Unit',
        status: 'STATUS',
        statusActive: 'Enabled',
        statusDisable: 'Disabled',
        userStatus: {
            enabled: 'Enabled',
            disabled: 'Disabled',
            pending: 'Pending Approval',
        },
        unitDetail: {
            heading: 'User Group Details',
            editText: 'Edit',
            disableText: 'Disable User Group',
            enableText: 'Enable User Group',
            hyperlinkText: 'Delete User Group',
        },
        permissions: {
            groupLabel: 'Permissions',
            addText: 'Add',
            existingText: 'Existing',
            disabledText: 'Disabled',
            iconDeleteTitle: 'Delete',
        },
        userGroups: {
            groupLabel: 'Users',
            addText: 'Add',
            existingText: 'Existing',
            disabledText: 'Disabled',
            iconDeleteTitle: 'Delete',
        },
        disableUserGroupModal: {
            disableText: 'Disable',
            heading: 'Disable User Group',
            message1: 'Warning! Disabling ',
            message2: 'will remove all users.',
        },
        enableUnitModal: {
            enableText: 'Enable',
            heading: 'Enable User Group',
            message: 'Are you sure you want to enable the User Group ',
        },
        removeModal: {
            removeText: 'Remove',
            heading: 'Remove',
            message: 'Are you sure you want to remove ',
        },
        deleteModal: {
            deleteWithOutUsers: 'Delete',
            deleteWithUsers: 'Close',
            heading: 'Delete User Group',
            messageWithOutUsers: 'Warning! Are you sure you would like to delete this user group',
            messageWithUsers:
                'Warning! Users must first be removed from this user group before it can be deleted.',
        },
        addExistingModal: {
            heading: 'Add Existing',
            saveText: 'Save',
        },
        editUserGroupModal: {
            heading: 'Edit User Group',
            id: 'ID',
            userGroupName: 'User Group Name',
            businessUnit: 'Business Unit',
            save: 'Save',
            idRequiredError: 'Please provide the user group ID.',
            userGroupNameRequiredError: 'Please provide the user group name.',
        },
    },
    permissionsLanding: {
        landingHeader: 'ID,Type,Business Unit,Time Period,Amount,Status',
        permissionsHeading: 'PERMISSIONS',
        permissionDetails: 'Permission Details',
        edit: 'Edit',
        disablePermission: 'Disable Permission',
        detailsHeader: 'TYPE,PERMISSION ID,BUSINESS UNIT,ESTIMATED AMOUNT,TIME PERIOD,STATUS',
        createPermissionLink: 'CREATE PERMISSION',
        permissionType: 'Type',
        PermissionID: 'Permission ID',
        timePeriod: 'Time Period',
        businessUnit: 'Business Unit',
        timeperiod: 'Time Period',
        thresholdAmount: 'Threshold Amount',
        currencyType: 'Currency Type',
        cancel: 'Cancel',
        save: 'Save',
        disable: 'Disable',
        permissionsSubheading: 'Manage Permissions Information Text',
        noPermissions: 'No permissions found',
        createHeading: 'CREATE PERMISSION',
        permissionTypeError: 'Please select the permission type',
        permissionIdError: 'Please enter in a valid Permission ID',
        timePeriodError: 'Please select a timespan',
        thresholdAmountError: 'Please enter in a valid Threshold Amount',
        currencyTypeError: 'Please select the permission currency',
        businessUnitError: 'Please select business unit',
        permissionTypeLabel: 'Select a type',
        businessUnitLabel: 'Select a business unit',
        timePeriodDropdownLabel: 'Select a time period',
        defaultCurrency: 'USD',
        permissionColumns: 'ID,Type,Business Unit,Time Period,Amount,Status',
        enabled: 'Enabled',
        disabled: 'Disabled',
        thresholdLabel: 'Order per threshold',
        timespanLabel: 'Order per timespan',
        thresholdValue: 'B2BOrderThresholdPermission',
        timeSpanPermissionValue: 'B2BOrderThresholdTimespanPermission',
        timeSpanLabels: 'DAY,WEEK,MONTH,QUARTER,YEAR',
        iconAlertTitle: 'Important Notice',
        mobileSortOptions: [
            {
                label: 'Permission ID A to Z',
                value: '0-asc',
            },
            {
                label: 'Permission ID Z to A',
                value: '0-dsc',
            },
            {
                label: 'Permission Type A to Z',
                value: '1-asc',
            },
            {
                label: 'Permission Type Z to A',
                value: '1-dsc',
            },
            {
                label: 'Business Unit A to Z',
                value: '2-asc',
            },
            {
                label: 'Business Unit Z to A',
                value: '2-dsc',
            },
            {
                label: 'Time Period A to Z',
                value: '3-asc',
            },
            {
                label: 'Time Period Z to A',
                value: '3-dsc',
            },
            {
                label: 'Estimated Spend High to low',
                value: '4-dsc',
            },
            {
                label: 'Estimated Spend Low to High',
                value: '4-asc',
            },
            {
                label: 'Status A to Z',
                value: '5-asc',
            },
            {
                label: 'Status Z to A',
                value: '5-dsc',
            },
        ],
    },
    permissionsDetails: {
        permissionsHeading: 'Permissions',
        permissionsDetailsHeading: 'Permission Details',
        createPermissionLink: 'Create Permission',
        permissionsSubheading: 'Manage Permissions Information Text',
        noPermissions: 'No permissions found',
        enabled: 'Enabled',
        disabled: 'Disabled',
        editHeading: 'Edit Permission',
        typeLabel: 'PERMISSION TYPE',
        permissionIdLabel: 'PERMISSION ID',
        timeSpanPermission: 'Allowed Order Threshold (per timespan)',
        businessUnitLabel: 'Business Unit',
        estimatedAmountLabel: 'ESTIMATED AMOUNT',
        timePeriodLabel: 'TIME PERIOD',
        statusLabel: 'STATUS',
        editText: 'Edit',
        disableText: 'Disable Permission',
        enableText: 'Enable Permission',
        timeSpanLabels: 'DAY,WEEK,MONTH,QUARTER,YEAR',
        permissionTypeError: 'Please select the permission type',
        permissionIdError: 'Please enter in a valid Permission ID',
        timePeriodError: 'Please select a timespan',
        thresholdAmountError: 'Please enter in a valid Threshold Amount',
        currencyTypeError: 'Please select the permission currency',
        businessUnitError: 'Please select business unit',
        permissionTypeLabel: 'Select a type',
        timePeriodDropdownLabel: 'Select a time period',
        defaultCurrency: 'USD',
        thresholdLabel: 'Order per threshold',
        timespanLabel: 'Order per timespan',
        disableUnitModalHeading: 'Disable Permission',
        disableUnitModalMessage: 'Warning!  Disabling this permission will not allow it to be used.',
        disableUnitModalDisableText: 'Disable',
        createHeading: 'Create Permission',
        permissionType: 'Permission Type',
        PermissionID: 'Permission ID',
        businessUnit: 'Business Unit',
        timePeriod: 'Time Period',
        timeSpanPermissionValue: 'B2BOrderThresholdTimespanPermission',
        thresholdValue: 'B2BOrderThresholdPermission',
        thresholdAmount: 'Estimated Amount',
        currencyType: 'Currency Type',
        save: 'Save',
        iconAlertTitle: 'Important Notice',
        userStatus: {
            enabled: 'Enabled',
            disabled: 'Disabled',
            pending: 'Pending Approval',
        },
    },
};
export default manageB2BOrg;
