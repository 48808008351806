var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-delete-list-modal" }, [
    _c("form", { ref: "deleteListModal" }, [
      _c(
        "div",
        { staticClass: "mb-md-5 mb-xs-4 form-group" },
        [
          !_vm.multipleLists
            ? _c("p", { staticClass: "list-label", attrs: { role: "text" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.i18n.deleteList.content) +
                    "\n            "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.multipleLists
            ? _c("p", { staticClass: "list-label", attrs: { role: "text" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.i18n.deleteList.multipleListContent) +
                    "\n            "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.selectedListsName, function(list) {
            return _c("div", { staticClass: "list", attrs: { role: "text" } }, [
              _vm._v("\n                " + _vm._s(list) + "\n            ")
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "button",
          {
            staticClass:
              "modal-btn-primary-small modal-btn-xs-sticky form-blue-button",
            attrs: { type: "button" },
            on: { click: _vm.deleteList }
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.i18n.deleteList.delete) +
                "\n            "
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-34d760a7", { render: render, staticRenderFns: staticRenderFns })
  }
}