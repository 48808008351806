import passwordChecklistMessage from '../common/password-checkList-message-i18n';

const registrationLogin = {
  createAccount: {
    heading: 'CREATE ACCOUNT',
    subHeading: 'Your Information',
    addToMarketComm:
      'I agree to receive occasional news promotions, product information and other communications from Georgia-Pacific Consumer Products',
    companyModalHeading: 'Give Us More Info',
    termasConditionHeading: 'Terms and Condition',
    firstName: 'FIRST NAME',
    lastName: 'LAST NAME',
    email: 'EMAIL',
    password: 'PASSWORD',
    confirmPassword: 'CONFIRM PASSWORD',
    confirmEmail: 'CONFIRM EMAIL',
    passwordInstruction:
      'Password must contain upper/lower case letters, numbers, special characters(! @ # $ % ^ * ( ) _ + { } ; : . , -) and a minimum of 6 characters..',
    mailerText:
      'I agree to receive occasional news promotions, product information and other communications from Georgia-Pacific Consumer Products.',
    termsPrivacy: 'By creating an account you agree to Georgia-Pacific\'s ',
    termsPrivacyB2C: 'By creating an account you agree to our ',
    termsPrivacyLink: 'Terms and Conditions',
    privacyPolicy: ' & Privacy Policy',
    submit: 'Create Account',
    haveAccount: 'Already have an account?',
    gpExpressLinkText: 'Please use below link for',
    gpExpressLink: 'GP Express login',
    login: 'Login Here',
    companyDetailsDataModalHeading: 'Enter Company Details',
    // Internal company details module
    companyName: 'Company Name',
    companyDetailsDecription: 'Company Details',
    addressPart1: 'Address 1',
    addressPart2: 'Address 2 (optional)',
    city: 'City',
    zipcode: 'Zip Code',
    cmpPhnNo: 'Company Phone Number',
    countryLabel: 'Country',
    mobileNo: 'Mobile no',
    other: 'Other',
    skip: 'Skip',
    save: 'Save',
    dropdownInput: 'Select Option',
    emptyStateError: 'Please select a State',
    addressVerificationHeading: 'Address Verification',
    currentAddressHeading: 'Current Address',
    verifiedAddressHeading: 'Verified Address',
    useCurrentAddress: 'Use Current Address',
    useVerifiedAddress: 'Use Verified Address',
    companyNameError: 'Please enter Company Name',
    companyNameMaxError: 'Company Name cannot be more than 40 characters',
    companyPhnNoError: 'Please enter the Company Phone Number',
    phoneMinError: 'Please enter valid Phone Number',
    addressLine1Error: 'Please enter Address line 1',
    addressLineRegexError:
    'At this time, orders cannot be shipped to PO Boxes',
    cityError: 'Please enter City',
    stateError: 'Please select a State',
    zipCodeError: 'Please enter in a valid Zip/Postal Code',
    zipCodeFormatError: 'Please enter in a valid Zip/Postal Code',
    //
    businessSubHeading: 'Your Information',
    // ErrorCodes
    195: 'An account already exists for that Email Address',
    210: 'You cannot use your GP network account to register as a customer. Please use a different email account or create a ticket for further assistance.',
    // Flyout success msg
    registerSuccessMsg: 'You have registered successfully.',
    // Validation messages
    emailRequiredError: 'Please enter in a valid Email Address',
    emailInvalidError: 'Please enter in a valid Email Address',
    passwordRequiredError: 'Please enter in a valid Password',
    passwordInvalidError: 'Please enter in a valid Password',
    passwordMinError: 'Your Password must be atleast 8 characters long',
    firstNameRequiredError: 'Please enter in a valid First Name',
    firstNameRegexError: 'Please enter in a valid First Name',
    firstNameMaxError: 'First Name should not be more than 35 characters',
    lastNameRequiredError: 'Please enter in a valid Last Name',
    lastNameRegexError: 'Please enter in a valid Last Name',
    lastNameMaxError: 'Last Name should not be more than 35 characters',
    country: 'COUNTRY',
    gender: 'Gender (Optional)',
    birthDay: 'Birthday (Optional)',
    monthRequiredError: 'Please select the Month',
    dateRequiredError: 'Please select the Date',
    iconEyeShowTitle: 'Show Password',
    iconEyeHideTitle: 'Hide Password',
    iconAlertTriangleTitle: 'Important Notice',
    passwordCheck: passwordChecklistMessage,
    confirmPasswordRequiredError: 'Confirm password is required',
    confirmPasswordMismatchError: 'Confirm password does not match the password',
    confirmEmailMismatchError: 'Confirm email does not match the email',
    confirmEmailRequiredError: 'Confirm email is required',
  },
  login: {
    headingforB2c: 'Log in',
    headingForReturningCustomer: 'Returning Customer',
    headingforB2bPart1: 'LOGIN WITH YOUR GEORGIA-PACIFIC ACCOUNT',
    email: 'EMAIL ADDRESS',
    password: 'PASSWORD',
    submit: 'LOGIN',
    forgotPassword: 'Forgot Password?',
    noAccount: 'Don\'t have a GP account?',
    noGPAccount: 'Don\'t have a GP account?',
    createAccount: 'Create one now',
    gpExpressLinkText: 'Please use below link for',
    gpExpressLink: 'GPXpress® Login',
    // Validation messages
    emailRequiredError: 'Please enter in a valid Email Address',
    emailInvalidError: 'Please enter in a valid Email Address',
    passwordRequiredError: 'Please enter in a valid Password',
    passwordInvalidError: 'Please enter in a valid Password',
    invalidAttemptsError:
      'Your account is locked, please use Forgot Your Password link to reset your password',
    // Internal forgot password module
    passwordModalHeading: 'RESET PASSWORD',
    passwordDescription1: 'Please enter your account email address.',
    passwordDescription2:
      'Instructions on how to reset your password will be sent to this address.',
    passwordSocialDescription:
      'If you signed up using a third party service such as Facebook or Google, please login with that instead.',
    passwordButtonValue: 'SUBMIT',
    emailLabel: 'EMAIL',
    passwordResetPostLabel: 'Password Reset Instructions Sent',
    passwordResetPostDesc:
      'Password reset instructions have been sent to your e-mail address. Please contact GPXpress@gapac.com or 866.HelloGP (435.5647) if you require additional assistance.',
    passwordResetPhoneNum: '+1-866-435-5647',
    passwordResetPostDescLine2: ' or email at ',
    passwordResetEmail: 'gpxpress@gapac.com',
    dot: '.',
    // ErrorCodes
    189: 'Contact customer service',
    190: 'Please try again, or login with Facebook or Google',
    b2berror190:
      'Email ID and password doesn\'t match.',
    191: 'Your account is locked, please use Forgot Your Password link to reset your password.',
    192: 'Something went wrong. Please try again', // siteId error
    193: 'Please try again, or login with Facebook or Google',
    194: 'Token Expired', // 30 days Koch Auth
    // ErrorCode for forget password
    196: 'Cannot reset password for social account',
    198: 'Sorry, we couldn\'t find this email address in our system. \r\nPlease try a different email.',
    199: 'Email id used for registration and authentication are not same',
    206: 'Your account has been disabled, please contact the Admin of your Organization',
    207: 'You have successfully registered. To activate your account, please click on the link in your email.',
    210: 'You cannot use your GP network account to register as a customer. Please use a different email account or create a ticket for further assistance.',
    support: 'Call our support specialists if you have any questions or concerns.',
    supportNumber: '1-866-435-5647',
    checkoutSubmit: 'LOG IN AND CHECKOUT',
    userUpdateSuccess: 'Your email ID is successfully updated. Please login with new email ID',
    iconEyeShowTitle: 'Show Password',
    iconEyeHideTitle: 'Hide Password',
    activeLoginSession: 'There is already an active login session. Please close the browser.',
    subscribeText: 'Items with a subscription require you to log in or sign up to continue checking out.'
  },
  passwordExpiry: {
    passwordHeading: 'Password Reset Link',
    passwordDescription1: 'Sorry, but that password reset link is incorrect no longer valid. If you still need to reset your password, please enter your email address below to request a new link.',
    passwordButtonValue: 'Request New Link',
    emailLabel: 'Email Address',
    // Validation messages
    emailRequiredError: 'Please enter your Email Address',
    emailInvalidError: 'Please enter in a valid Email Address',
    passwordRequiredError: 'Please enter your Password',
    passwordInvalidError: 'Please enter in a valid Password',
    passwordResetPostLabel: 'Password Reset Instructions Sent',
    passwordResetPostDesc: 'Password reset instructions have been sent to your e-mail address. Please contact GPXpress@gapac.com or 866.HelloGP (435.5647) if you require additional assistance.',
    passwordResetPhoneNum: '+1 866-435-5647',
    passwordResetPostDescLine2: ' or email at ',
    passwordResetEmail: 'gpxpress@gapac.com',
    dot: '.',
    // ErrorCodes
    196: 'Cannot reset password for social account',
    198: 'Sorry we could not find this email address in our system, Please try different email'
  },
  updatePassword: {
    currentPasswordLabel: 'Enter Current Password',
    updatePasswordTitle: 'RESET PASSWORD',
    newPasswordLabel: 'NEW PASSWORD',
    newPasswordConfirmLabel: 'CONFIRM NEW PASSWORD',
    passwordRequiredError: 'Please enter in a New Password',
    passwordInvalidError:
      'The password selected does not meet the password guidelines, please try again.',
    passwordInstruction:
      'Password must contain the following:At least 6 characters with no space,At least one upper and lower case character,At least one number,And at least one of the following special characters ! @ # $ % ^ * ( ) _ + { } ; : . , -',
    newPasswordResetError:
      'The password you have entered matches your previous password. Please enter a different password.',
    showIconLabel: 'Show',
    resetPasswordButton: 'SUBMIT',
    passwordSuccessMsg:
      'Success! Your Password has been reset,and we\'ve logged you into your account.',
    submit: 'Submit',
    // ErrorCodes
    197: 'Link expired',
    iconEyeShowTitle: 'Show Password',
    iconEyeHideTitle: 'Hide Password',
    passwordCheck: passwordChecklistMessage,
  },
};
export default registrationLogin;
