import globals from '../../common/globals';
import { cartEventBus } from '../../../modules/event-bus';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import AnalyticsService from '../../common/services/analytics-service';
import ManageShoppingCartService from '../../common/services/manage-shopping-cart-service';

export default {
  name: 'vx-add-installation',
  components: {
    vxSpinner,
  },
  mixins: [],
  props: {
    // Details of the installation product and installable product
    installDetails: Object,
    // Text coming from property file
    i18n: Object,
  },
  data() {
    return {
      globals,
      installationDetails: Object,
      analyticsService: new AnalyticsService(),
      manageShoppingCartService: new ManageShoppingCartService(),
    };
  },
  computed: {},
  mounted() {
    this.callInstallDetails();
  },
  methods: {
    /**
     * This function adds the installation product to cart
     */
    addToCart() {
      const requestConfig = {};
      requestConfig.data = {
        quantity: this.installDetails.parentProductQuantity,
        product: {
          code: this.installDetails.code,
        },
        additionalAttributes: {
          entry: [
            {
              key: 'installedEntry',
              value: this.installDetails.parentProductEntry,
            },
          ],
        },
      };
      this.manageShoppingCartService.addInstallation(
        requestConfig,
        this.handleAddInstallationResponse,
        this.handleAddInstallationError,
      );
      this.$refs.spinner.showSpinner();
      // sending the data to Google Analytics on Add to Cart Button click
      const analyticsObject = {
        code: this.installDetails.parentProductCode,
        name: this.installDetails.parentProductName,
        quantity: this.installDetails.parentProductQuantity,
      };
      this.analyticsService.trackAddToCart(analyticsObject);
      if (this.globals.siteId === 'gppro') {
        this.analyticsService.trackAddToCartGA4(analyticsObject);
      }
      //  this.trackAddToCart(analyticsObject);
    },
    /**
     * This function handles the response of adding the installation product to cart
     */
    handleAddInstallationResponse() {
      this.$refs.spinner.hideSpinner();
      this.callInstallUpdate();
    },
    /**
     * This function handles the error of adding the installation product to cart
     */
    handleAddInstallationError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * This function gets the details for the add installation modal
     */
    callInstallDetails() {
      const requestConfig = {};
      this.manageShoppingCartService.installationDetails(
        requestConfig,
        this.handleInstallationDetailsResponse,
        this.handleInstallationDetailsError,
        this.installDetails.code,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
     * This function handles the response for the call to get details for the add installation modal
     */
    handleInstallationDetailsResponse(response) {
      this.installationDetails = response.data;
      this.$refs.spinner.hideSpinner();
    },
    /**
     * This function handles the error for the call to get details for the add installation modal
     */
    handleInstallationDetailsError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * This function informs that installation has been selected for the installable product
     */
    callInstallUpdate() {
      const requestConfig = {};
      requestConfig.data = {
        quantity: this.installDetails.parentProductQuantity,
        additionalAttributes: {
          entry: [
            {
              key: 'installed',
              value: 'true',
            },
          ],
        },
      };
      this.manageShoppingCartService.updateInstallation(
        requestConfig,
        this.handleUpdateInstallationResponse,
        this.handleUpdateInstallationError,
        this.installDetails.parentProductEntry,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
     * This function handles the response of the call that informs installation has been selected for the installable product
     */
    handleUpdateInstallationResponse() {
      cartEventBus.$emit('cart-update');
      this.$refs.spinner.hideSpinner();
      this.$emit('add-installation-success');
    },
    /**
     * This function handles the error of the call that informs installation has been selected for the installable product
     */
    handleUpdateInstallationError() {
      this.$refs.spinner.hideSpinner();
      this.$emit('add-installation-error');
    },
  },
};
