/* Handles prices on order summary section on cart, checkout and orderConfrimation */
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import globals from '../../../../components/common/globals';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import { pageType } from '../../../../components/common/mixins/vx-enums';

export default {
  name: 'vx-order-summary-details',
  components: {
    vxModal,
  },
  props: {
    // Text coming from property file
    i18n: Object,
    // Indicates if page is cart/checkout/orderConfirmation/orderApprovalDetails/orderDetails
    page: String,
    // Order summary details
    orderSummaryData: Object,
    // Indicates if there are any gift products in cart
    giftable: Boolean,
    // Indicates if there are any installation products in cart
    installable: Boolean,
    // Indicates if Multiple/Single Shipping is opted
    isShippingMultiple: Boolean,
  },
  data() {
    return {
      globals,
      orderSummaryClicked: false,
      isGiftable: false,
      isInstallable: false,
      pageType,
    };
  },
  mixins: [mobileMixin],
  computed: {},
  mounted() {
    this.isGiftable = this.giftable;
    this.isInstallable = this.installable;
  },
  methods: {
    /**
         * This function opens the entire order summary section in mobile view on cart page
         */
    openOrderSummary() {
      this.orderSummaryClicked = !this.orderSummaryClicked;
      this.$root.$emit('orderSummaryScrollUp', this.orderSummaryClicked);
    },
    /**
         * This function opens a madal that shows details about installation service
         */
    openInstallationModal() {
      this.$refs.installableMoreInfo.open();
    },
    /**
         * This function shows progress bar based on the free shipping promotions on cart page
         * @param  {Object} orderSummaryData orderSummary Data
         */
    getProgressbarStyle(orderSummaryData) {
      const freeShippingLimitBalance = parseInt(orderSummaryData.freeShippingLimit, 10) || 0;
      const subtotal = parseInt(orderSummaryData.subTotal.value, 10);
      const progressPercentage = (subtotal * 100) / (subtotal + freeShippingLimitBalance);
      // const bgColor = (progressPercentage === 100) ? '#81bc02' : '#00bdf0';
      const bgColor = '#00bdf0';
      const obj = {
        width: `${progressPercentage}%`,
        backgroundColor: `${bgColor}`,
      };
      return obj;
    },
  },
};
