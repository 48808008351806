var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-404-page" }, [
    _c("div", { staticClass: "error-container" }, [
      _c("div", { staticClass: "error-heading" }, [
        _vm._v(_vm._s(_vm.i18n.heading))
      ]),
      _vm._v(" "),
      _vm.globals.isVanityfair() || _vm.globals.isEStores()
        ? _c("div", { staticClass: "error-msg-container" }, [
            _c("span", { staticClass: "error-msg-code" }, [
              _vm._v(_vm._s(_vm.i18n.errorMsgCode))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "error-msg-text" }, [
              _vm._v(_vm._s(_vm.i18n.errorMsgText))
            ])
          ])
        : _c("div", { staticClass: "error-msg-container" }, [
            _vm._v(_vm._s(_vm.i18n.errorMsg))
          ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "immediate-assistance" },
        [
          _c("span", [_vm._v(_vm._s(_vm.i18n.immediateAssitance))]),
          _vm._v(" "),
          [
            _c("a", [
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      _vm.globals.navigateToUrl("contactUs")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.contactUs))]
              )
            ])
          ],
          _vm._v(_vm._s(_vm.i18n.dot) + "\n    ")
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "return-home" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-tertiary btn-small-text",
            on: { click: _vm.goToHomePage }
          },
          [_vm._v(_vm._s(_vm.i18n.buttonText))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3517b825", { render: render, staticRenderFns: staticRenderFns })
  }
}