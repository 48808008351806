import vxAddpaymentForm from '../vx-add-payment-form/vx-add-payment-form.vue';
import CheckoutService from '../services/checkout-service';
import vxProfileCard from '../vx-profile-card/vx-profile-card.vue';
import vxModal from '../vx-modal/vx-modal.vue';
import { eventBus } from '../../../modules/event-bus';
import CyberSourceIntegrationService from '../services/cybersource-integration-service';
import flyoutBannerMixin from '../vx-flyout-banner/vx-flyout-banner-mixin';
import vxSpinner from '../vx-spinner/vx-spinner.vue';
import globals from '../globals';
import { paymentTypes } from '../mixins/vx-enums';

export default {
  name: 'vx-payment-methods',
  components: {
    'vx-add-payment-form': vxAddpaymentForm,
    vxProfileCard,
    vxModal,
    vxSpinner,
  },
  mixins: [flyoutBannerMixin],
  props: ['i18n'],
  data() {
    return {
      paymentMethodsData: null,
      displayTopHeadings: true,
      reloadingOnSuccess: true,
      deletePaymentObj: {},
      preferenceCardClasses: 'mb-xs-3 px-xs-3 py-xs-3 pl-sm-4 pr-sm-3',
      populatePaymentFormData: false,
      paymentFormData: {},
      cardDetails: [],
      deletePaymentId: '',
      targetEvent: '',
      csService: new CyberSourceIntegrationService(),
      globals,
      checkoutService: new CheckoutService(),
    };
  },
  computed: {},
  mounted() {
    const self = this;
    self.fetchSavedCards();
    eventBus.$on('paymentMethodsData', (data) => {
      self.paymentsData = data;
      self.onResponseData();
    });
    const eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    eventer(messageEvent, (e) => {
      if (e.data) {
        if (e.data.paymentMethod === paymentTypes.creditCard) {
          this.$refs.paymentMethodsSpinner.showSpinner();
          this.$refs.paymentForm.iframeResponse(e.data);
        }
      }
    });
  },
  methods: {
    newCardAdded() {
      this.cardDetails = [];
      this.fetchSavedCards();
      this.$refs.paymentMethodsSpinner.hideSpinner();
      this.$refs.paymentFormModal.close();
    },
    cancelDisable() {
      this.$refs.paymentDeleteModal.close();
    },
    openAddPaymentForm() {
      const isEstores = globals.isEStores();
      if (isEstores) {
        this.populatePaymentFormData = false;
        this.paymentFormData = {};
        this.$refs.paymentFormModal.open();
      } else {
        this.checkRecaptchaScore();
        this.$refs.paymentMethodsSpinner.showSpinner();
      }
    },
    /*
    Checking recaptcha score
    */
    checkRecaptchaScore() {
      const self = this;
      const requestConfig = {};
      if (self.globals.siteConfig.isReCaptchaEnabled && window.grecaptcha) {
        const checkoutService = new CheckoutService();
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(self.globals.reCaptchaSiteKey, {
            action: 'profileaddpayment',
          }).then((token) => {
            checkoutService.proceedService(requestConfig, self.handleProceedMethodResponse, self.handleProceedMethodError, token);
          });
        });
      }
    },
    /**
         * This function handles response of checkRecaptchaScore method call
         */
    handleProceedMethodResponse(response) {
      const status = response.data.ReCaptchaStatus;
      if (status === 'true') {
        this.populatePaymentFormData = false;
        this.paymentFormData = {};
        this.$refs.paymentFormModal.open();
      } else {
        this.$refs.paymentIssueModal.open();
      }
      this.$refs.paymentMethodsSpinner.hideSpinner();
    },
    /**
         * This function handles error of checkRecaptchaScore method call
         */
    handleProceedMethodError() {
      this.$refs.paymentMethodsSpinner.hideSpinner();
    },
    fetchSavedCards() {
      this.csService.fetchSavedCardsService(
        {},
        this.handleAddressData,
        this.handleAddressDataError,
      );
    },
    handleAddressData(response) {
      const { status, data } = response;
      const self = this;
      if (status) {
        if (data && data.payments) {
          self.cardDetails = [];
          const fetchedDetails = data.payments;
          for (let roll = 0; roll < fetchedDetails.length; roll += 1) {
            const tempObj = {};
            tempObj.cardHolderName = fetchedDetails[roll].accountHolderName;
            tempObj.cardType = fetchedDetails[roll].cardType.name;
            tempObj.cardNumber = fetchedDetails[roll].cardNumber;
            tempObj.cardExpiry = `${fetchedDetails[roll].expiryMonth}/${
              fetchedDetails[roll].expiryYear
            }`;
            tempObj.zipCode = fetchedDetails[roll].billingAddress
              ? fetchedDetails[roll].billingAddress.postalCode
              : '';
            tempObj.isDefaultPaymentOpt = fetchedDetails[roll].defaultPayment;
            tempObj.subscriptionId = fetchedDetails[roll].subscriptionId;
            tempObj.id = fetchedDetails[roll].id;
            self.cardDetails.push(tempObj);
          }
        } else {
          self.cardDetails = [];
        }
      }
    },
    handleAddressDataError() {},
    openEditPaymentModal(paymentId, event) {
      this.targetEvent = event;
      this.csService.fetchSavedCardDetails(
        {},
        this.handleSavedCardData,
        this.handleSavedCardDataError,
        paymentId,
      );
    },
    openDeletePaymentModal(paymentObj, event) {
      this.deletePaymentObj = paymentObj;
      this.deletePaymentId = paymentObj.id;
      this.$refs.paymentDeleteModal.open(event);
    },
    sendPaymentDeleteRequest() {
      this.$refs.paymentMethodsSpinner.showSpinner();
      this.csService.deleteSavedCardDetails(
        {},
        this.handleDeleteSavedCardResponse,
        this.handleDeleteSavedCardError,
        this.deletePaymentId,
      );
    },
    handleDeleteSavedCardResponse(response) {
      const { status } = response;
      if (status) {
        this.showFlyout(
          'success',
          this.i18n.paymentMethods.deletePaymentSuccessMessage,
          true,
        );
        this.fetchSavedCards();
        this.$refs.paymentDeleteModal.close();
        this.$refs.paymentMethodsSpinner.hideSpinner();
      }
    },
    handleDeleteSavedCardError() {},
    handleSavedCardData(response) {
      const { status, data } = response;
      if (status) {
        if (data) {
          this.populatePaymentFormData = true;
          this.paymentFormData = data;
          this.$refs.paymentFormModal.open(this.targetEvent);
        }
      }
    },
    handleSavedCardDataError() {},
    onResponseData() {
      // eventBus.$emit('paymentSuccess', self.cardDetails);
    },
    dismissAddPaymentModal() {
      /* Commenting as per ALM-28818
      this.$refs.paymentFormModal.close(); */
      this.showFlyout(
        'error',
        this.i18n.paymentMethods.creditCardAddressError,
        false,
      );
      this.$refs.paymentMethodsSpinner.hideSpinner();
    },
  },
};
