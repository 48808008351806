import {
  globalEventBus,
} from '../../../modules/event-bus';

export default {
  name: 'vx-assistive-text',
  components: {},
  props: {
    value: String,
    ariaLive: {
      type: String,
      default: 'assertive',
      validator: (value) => ['assertive', 'polite', 'off'].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      textToRead: [],
    };
  },
  methods: {
    /**
     * say is triggered once the event 'announce' is handled.
     * @param {string} text
     */
    say(text) {
      if (text) {
        this.textToRead = [];
        this.textToRead.push(text);
      }
    },
  },
  async mounted() {
    globalEventBus.$on('announce', (value) => {
      this.say(value);
    });
    // this.say(this.value);
  },
  watch: {
    value(val) {
      this.say(val);
    },
  },
};
