var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-uiux-tiles-carousel", class: _vm.componentTheme },
    [
      _vm.carouselData.title
        ? _c("div", {
            staticClass: "header",
            domProps: { innerHTML: _vm._s(_vm.carouselData.title) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.carouselData.gpRotatingData &&
      _vm.carouselData.gpRotatingData.length > 0
        ? _c(
            "div",
            { staticClass: "tiles-carousel" },
            [
              _vm.showNavigationArrows
                ? _c("button", {
                    staticClass: " swiper-button-prev chevron-left-image",
                    class: { "swiper-button-disabled": _vm.firstSlide },
                    attrs: {
                      tabindex: "0",
                      "aria-label": _vm.i18n.iconChevronLeftTitle,
                      disabled: _vm.firstSlide ? true : false
                    },
                    on: {
                      click: function($event) {
                        _vm.slidePrev()
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "swiper",
                { attrs: { options: _vm.swiperOptions } },
                _vm._l(_vm.carouselData.gpRotatingData, function(
                  product,
                  index
                ) {
                  return _c("swiper-slide", { key: index }, [
                    !product.imageData.isVideo
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: product.ctaLink,
                              target: product.isExternalLink
                                ? "_blank"
                                : "_self"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "tile-image-container" },
                              [
                                _c("lazy-component", [
                                  _c("img", {
                                    staticClass: "tile-image",
                                    attrs: {
                                      src: product.imageData.imageSrcD,
                                      alt: product.imageData.imageSrcAltTextD
                                    }
                                  })
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "bottom",
                                style: {
                                  backgroundColor: product.backgroundColor
                                    ? product.backgroundColor
                                    : "transparent"
                                }
                              },
                              [
                                _c("lazy-component", [
                                  _c("img", {
                                    staticClass: "tile-icon",
                                    attrs: {
                                      src: product.imageData.iconLogo,
                                      alt: ""
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "tile-header",
                                  domProps: {
                                    innerHTML: _vm._s(product.header)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "tile-description",
                                  domProps: { innerHTML: _vm._s(product.text) }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    product.imageData.isVideo
                      ? _c(
                          "div",
                          {
                            staticClass: "video-tile",
                            on: {
                              click: function($event) {
                                _vm.openModal(product.imageData.videoSrc)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "tile-image-container" },
                              [
                                _c("lazy-component", [
                                  _c("img", {
                                    staticClass: "tile-image",
                                    attrs: {
                                      src: product.imageData.imageSrcD,
                                      alt: product.imageData.imageSrcAltTextD
                                    }
                                  })
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "bottom",
                                style: {
                                  backgroundColor: product.backgroundColor
                                    ? product.backgroundColor
                                    : "transparent"
                                }
                              },
                              [
                                _c("lazy-component", [
                                  _c("img", {
                                    staticClass: "tile-icon",
                                    attrs: {
                                      src: product.imageData.iconLogo,
                                      alt: ""
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "tile-header",
                                  domProps: {
                                    innerHTML: _vm._s(product.header)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "tile-description",
                                  domProps: { innerHTML: _vm._s(product.text) }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                })
              ),
              _vm._v(" "),
              _vm.showNavigationArrows
                ? _c("button", {
                    staticClass: "swiper-button-next chevron-right-image",
                    class: { "swiper-button-disabled": _vm.lastSlide },
                    attrs: {
                      tabindex: "0",
                      "aria-label": _vm.i18n.iconChevronRightTitle,
                      disabled: _vm.lastSlide ? true : false
                    },
                    on: {
                      click: function($event) {
                        _vm.slideNext()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2da22450", { render: render, staticRenderFns: staticRenderFns })
  }
}