var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-cancel-subscription row mx-xs-0" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("p", { staticClass: "mb-xs-0" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.i18n.cancelSubsText) +
            _vm._s(_vm.subscriptionId.entries[0].product.name) +
            _vm._s(_vm.i18n.cancelSubsquestion) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.i18n.cancelInfoText))]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "cancel-subscription mt-xs-4 col-xs-12 px-xs-3 px-sm-0 mb-xs-3"
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-default mr-sm-5 col-xs-12 col-sm-6",
              on: {
                click: function($event) {
                  _vm.keepSubscription($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.i18n.keepSubscription))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary col-xs-12 col-sm-6 mt-xs-3 mt-sm-0",
              on: {
                click: function($event) {
                  _vm.cancelSubscription($event)
                },
                keyup: function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k(
                      $event.keyCode,
                      "enter-space",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  _vm.cancelSubscription($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.i18n.cancelSubscriptionButton))]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0103fee5", { render: render, staticRenderFns: staticRenderFns })
  }
}