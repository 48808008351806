import globals from '../globals';
import CommonService from '../services/common-service';
import vxSpinner from '../vx-spinner/vx-spinner.vue';

export default {
  name: 'vx-delete-address',
  components: {
    vxSpinner,
  },
  props: {
    // Address
    address: Object,
    // Text coming from property file
    i18n: Object,
  },
  data() {
    return {
      globals,
      commonService: new CommonService(),
    };
  },
  computed: {},
  mounted() {},
  methods: {
    /**
     * This function triggers delete address call
     */
    deleteAddress() {
      this.commonService.deleteAddress({}, this.handleDeleteAddressResponse, this.handleDeleteAddressError, this.address.id);
      this.$refs.deleteSpinner.showSpinner();
    },
    /**
     * This function handles the response of delete address call
     */
    handleDeleteAddressResponse(response) {
      this.$refs.deleteSpinner.hideSpinner();
      this.$emit('onAddressDeleted', response);
    },
    /**
     * This function handles the error of delete address call
     */
    handleDeleteAddressError(error) {
      if (error) {
        this.$refs.spinner.hideSpinner();
      }
    },
  },
};
