var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-product-category-solution",
      class: _vm.componentTheme,
      attrs: { id: _vm.componentId }
    },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.tabsData.componentHeader
        ? _c("h3", {
            staticClass: "category-title ml-sm-3 mb-sm-5 mx-xs-3 mx-sm-5",
            style: { color: _vm.tabsData.componentHeaderColor },
            domProps: { innerHTML: _vm._s(_vm.tabsData.componentHeader) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tabsData.componentHeader
        ? _c("div", {
            staticClass: "title-underline mt-xs-0 mb-xs-3",
            style: _vm.tabsData.componentHeaderColor
              ? { "border-bottom-color": _vm.tabsData.componentHeaderColor }
              : ""
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "nav nav-tabs mb-xs-5 mb-md-2 px-sm-3 px-md-4" },
        _vm._l(_vm.tabsData.tabs, function(item, index) {
          return _c(
            "li",
            { staticClass: "px-xs-3", class: index === 0 ? "active" : "" },
            [
              _c("a", {
                staticClass: "px-xs-0 py-xs-2",
                style: { color: item.componentHeaderColor },
                attrs: { href: "#tab" + index, "data-toggle": "tab" },
                domProps: { innerHTML: _vm._s(item.componentHeader) },
                on: {
                  click: function($event) {
                    _vm.loadProducts(item, index)
                  }
                }
              })
            ]
          )
        })
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab-content mx-sm-3" },
        [
          _vm.tabBody.length > 0
            ? _vm._l(_vm.tabsData.tabs, function(item, ind) {
                return _c(
                  "div",
                  {
                    staticClass: "tab-pane",
                    class: ind === 0 ? "active" : "",
                    attrs: { id: "tab" + ind }
                  },
                  [
                    _c("div", { staticClass: "panel panel-default" }, [
                      _c("div", { staticClass: "panel-heading p-xs-0" }, [
                        _c("h4", { staticClass: "panel-title" }, [
                          _c(
                            "a",
                            {
                              staticClass: "px-xs-0 py-xs-3 mx-xs-3 mx-sm-5",
                              class: ind === 0 ? "" : "collapsed",
                              attrs: {
                                "data-toggle": "collapse",
                                "data-parent": ".tab-pane",
                                href: "#collapse" + ind
                              },
                              on: {
                                click: function($event) {
                                  _vm.loadProducts(item, ind)
                                }
                              }
                            },
                            [
                              _c("span", {
                                style: { color: _vm.tabsData.headerColor },
                                domProps: {
                                  innerHTML: _vm._s(item.componentHeader)
                                }
                              })
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "panel-collapse collapse",
                          class: ind === 0 ? "in" : "",
                          attrs: { id: "collapse" + ind }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "panel-body p-xs-0" },
                            [
                              ind === _vm.activeTab
                                ? [
                                    _c("vx-marketing-product", {
                                      attrs: {
                                        "tab-body": _vm.tabBody,
                                        "tab-body-title": _vm.tabBodyTitle,
                                        "broucher-link": _vm.tabsData.ctaLink,
                                        "brochure-text": _vm.tabsData.ctaText,
                                        "is-brochure-link-external":
                                          _vm.isBrochureLinkExternal,
                                        "is-request-trial-link-external":
                                          _vm.isRequestTrialLinkExternal,
                                        "request-trial-text":
                                          _vm.tabsData.tabs[ind].ctaText,
                                        "request-trial-link":
                                          _vm.tabsData.tabs[ind].ctaLink
                                      }
                                    })
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      )
                    ])
                  ]
                )
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1850f5bb", { render: render, staticRenderFns: staticRenderFns })
  }
}