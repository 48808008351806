var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-left-nav" }, [
    _c("main", { staticClass: "cd-main-content" }, [
      _c(
        "nav",
        { staticClass: "cd-side-nav" },
        _vm._l(_vm.leftNavData, function(navData) {
          return _c(
            "div",
            {
              staticClass: "left-nav-sub-container",
              class: {
                hidden:
                  !(navData.title && navData.title !== "") ||
                  (navData.title === _vm.leftNavigation.yourCompany &&
                    !_vm.showCompanySection)
              }
            },
            [
              _vm.globals.isB2B()
                ? [
                    navData.title &&
                    navData.title.toUpperCase() ===
                      _vm.leftNavigation.yourCompany.toUpperCase() &&
                    _vm.showCompanySection
                      ? [
                          _c("h3", { staticClass: "side-heading h3" }, [
                            _vm._v(_vm._s(navData.title))
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(navData.links, function(listData) {
                              return _c(
                                "li",
                                {
                                  staticClass: "cd-label",
                                  class: [
                                    {
                                      selected:
                                        listData.linkTo === _vm.currentUrlPath
                                    }
                                  ]
                                },
                                [
                                  listData.linkText
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.baseURL + listData.linkTo
                                            }
                                          },
                                          [_vm._v(_vm._s(listData.linkText))]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            })
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    navData.title &&
                    navData.title.toUpperCase() ===
                      _vm.leftNavigation.yourAccount.toUpperCase()
                      ? [
                          _c("h3", { staticClass: "side-heading h3" }, [
                            _vm._v(_vm._s(navData.title))
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(navData.links, function(listData) {
                              return _c(
                                "li",
                                {
                                  staticClass: "cd-label",
                                  class: [
                                    {
                                      selected:
                                        listData.linkTo === _vm.currentUrlPath
                                    }
                                  ]
                                },
                                [
                                  listData.linkText
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.baseURL + listData.linkTo
                                            }
                                          },
                                          [_vm._v(_vm._s(listData.linkText))]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            })
                          )
                        ]
                      : _vm._e()
                  ]
                : [
                    navData.title
                      ? _c("h3", { staticClass: "side-heading" }, [
                          _vm._v(_vm._s(navData.title))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(navData.links, function(listData) {
                        return _c(
                          "li",
                          {
                            staticClass: "cd-label",
                            class: [
                              {
                                selected:
                                  ~listData.linkTo.indexOf(
                                    _vm.currentUrlPath
                                  ) && ~_vm.currentUrlPath.search(/\/[a-z]/)
                              }
                            ]
                          },
                          [
                            listData.linkText
                              ? [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.baseURL + listData.linkTo
                                      }
                                    },
                                    [_vm._v(_vm._s(listData.linkText))]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      })
                    )
                  ]
            ],
            2
          )
        })
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-777cb78e", { render: render, staticRenderFns: staticRenderFns })
  }
}