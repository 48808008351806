var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-checkbox-group" },
    _vm._l(_vm.checkBoxValues, function(item) {
      return _c("div", [
        _c("label", { staticClass: "checkbox-container" }, [
          _vm._v(_vm._s(item.label) + "\n      "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checkedBox,
                expression: "checkedBox"
              }
            ],
            staticClass: "form-checkbox-input",
            attrs: { tabindex: "0", type: "checkbox", name: item.name },
            domProps: {
              value: item.value,
              checked: Array.isArray(_vm.checkedBox)
                ? _vm._i(_vm.checkedBox, item.value) > -1
                : _vm.checkedBox
            },
            on: {
              click: function($event) {
                _vm.checkTicked($event, item)
              },
              change: function($event) {
                var $$a = _vm.checkedBox,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = item.value,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkedBox = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.checkedBox = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.checkedBox = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" })
        ])
      ])
    })
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a9f32c46", { render: render, staticRenderFns: staticRenderFns })
  }
}