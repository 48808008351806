/**
 * Bundles component will be used to display bundles on the pdp page.
 */
import vxImageTile from '../../view-site-content/vx-image-tile/vx-image-tile.vue';

export default {
  name: 'vx-bundles-render',
  components: {
    vxImageTile,
  },
  props: {
    // bundles data coming from parent component
    bundlesData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
