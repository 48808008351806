var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-csv-upload" },
    [
      _c("div", { staticClass: "upload-info" }, [
        _vm._v(_vm._s(_vm.i18n.csvUpload.uploadInfo))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "my-xs-3 download-link" }, [
        _c(
          "a",
          {
            staticClass: "download-link",
            attrs: { tabindex: "0" },
            on: {
              click: function($event) {
                _vm.fileDownload()
              }
            }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.i18n.csvUpload.downloadCaption) + "\n    "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("vx-file-upload", {
        attrs: {
          i18n: _vm.i18n.fileUpload,
          disableAttach: _vm.disableAttach,
          fileType: _vm.fileType,
          templateError: _vm.templateFormatError
        },
        on: {
          "file-upload": function($event) {
            _vm.fileUpload($event)
          },
          "attachments-updated": _vm.attachmentListUpdated
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1c61dbd4", { render: render, staticRenderFns: staticRenderFns })
  }
}