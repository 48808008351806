var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.imageTileData.isVideo && _vm.imageTileData.imageLink
    ? _c("section", {
        staticClass: "view-site-content-vx-image-tile px-xs-0",
        class: { "is-link": _vm.imageTileData.imageLink },
        style: [
          {
            width: _vm.imageTileData.imageWidth
              ? _vm.imageTileData.imageWidth + "%"
              : ""
          },
          _vm.backgroundImage
        ],
        attrs: {
          "aria-label": _vm.textTileData.header,
          tabindex: "0",
          role: "link"
        },
        on: {
          click: function($event) {
            _vm.goToUrl(_vm.imageTileData.imageLink)
          }
        }
      })
    : !_vm.imageTileData.isVideo && !_vm.imageTileData.imageLink
    ? _c("section", {
        staticClass: "view-site-content-vx-image-tile px-xs-0",
        class: { "is-link": _vm.imageTileData.imageLink },
        style: [
          {
            width: _vm.imageTileData.imageWidth
              ? _vm.imageTileData.imageWidth + "%"
              : ""
          },
          _vm.backgroundImage
        ],
        attrs: { "aria-label": _vm.textTileData.header }
      })
    : _c(
        "section",
        {
          staticClass: "view-site-content-vx-image-tile px-xs-0",
          style: [
            {
              width: _vm.imageTileData.imageWidth
                ? _vm.imageTileData.imageWidth + "%"
                : ""
            },
            _vm.backgroundImage
          ],
          attrs: { "aria-label": _vm.textTileData.header }
        },
        [
          _c("img", {
            staticClass: "play-video-icon",
            attrs: { src: _vm.playIconSrc, alt: "Play Video" },
            on: {
              click: function($event) {
                _vm.openVideo(_vm.imageTileData.videoSrc)
              },
              mouseover: _vm.onMouseOver,
              mouseleave: _vm.onMouseLeave
            }
          })
        ]
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-82501504", { render: render, staticRenderFns: staticRenderFns })
  }
}