var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-hero-banner-responsive" },
    [
      _vm.heroBannerData.componetType === "imageWrapper"
        ? _vm._t("default", [
            _vm.isPriceSpider
              ? _c(
                  "div",
                  { staticStyle: { position: "absolute" } },
                  [
                    _c("vx-price-spider", {
                      attrs: {
                        config: {
                          pskey: _vm.globals.getPriceSpiderID(),
                          pscountry: "US",
                          pslanguage: "en",
                          pssku: _vm.psSKU,
                          src: "//cdn.pricespider.com/1/lib/ps-widget.js",
                          pslabel: _vm.heroBannerData.ctaText
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.heroBannerData.imageLink
              ? _c(
                  "a",
                  _vm._g(
                    {
                      attrs: {
                        href:
                          _vm.heroBannerData.imageLink && !_vm.isPriceSpider
                            ? _vm.heroBannerData.isExternalImage
                              ? _vm.heroBannerData.imageLink
                              : _vm.globals.getUrlWithContextPath(
                                  _vm.heroBannerData.imageLink
                                )
                            : "javascript:void(0)",
                        "aria-label": [_vm.backgroundAlt],
                        target: _vm.heroBannerData.isExternalImage
                          ? "_blank"
                          : ""
                      }
                    },
                    _vm.isPriceSpider ? { click: _vm.clickPriceSpiderBtn } : {}
                  ),
                  [
                    _c("lazy-component", [
                      _c(
                        "picture",
                        {
                          staticClass: "banner",
                          class: _vm.heroBannerData.componentTheme
                        },
                        [
                          _c("source", {
                            attrs: {
                              media: "(min-width: 1200px)",
                              srcset: _vm.heroBannerData.backgroundImageD
                            }
                          }),
                          _vm._v(" "),
                          _c("source", {
                            attrs: {
                              media: "(min-width: 768px)",
                              srcset: _vm.heroBannerData.backgroundImageT
                            }
                          }),
                          _vm._v(" "),
                          _c("source", {
                            attrs: {
                              media: "(min-width: 360px)",
                              srcset: _vm.heroBannerData.backgroundImageM
                            }
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "banner__image",
                            attrs: {
                              src: _vm.heroBannerData.backgroundImageD,
                              alt: _vm.backgroundAlt
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "banner__promotion",
                              class: _vm.heroBannerData.textPosition
                            },
                            [
                              _vm.heroBannerData.headerLogo
                                ? _c("div", { staticClass: "headerLogo" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.heroBannerData.headerLogo,
                                        alt:
                                          _vm.heroBannerData
                                            .headerLogoImageAltText
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.heroBannerData.headingText
                                ? _c("div", {
                                    staticClass:
                                      "banner__heading my-xs-2 my-sm-4",
                                    style: {
                                      color: _vm.heroBannerData.headingColor
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.heroBannerData.headingText
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.heroBannerData.subHeadingText
                                ? _c("h3", {
                                    staticClass:
                                      "banner__subheading my-xs-2 my-sm-4",
                                    style: {
                                      color: _vm.heroBannerData.subHeadingColor
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.heroBannerData.subHeadingText
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.heroBannerData.ctaText &&
                              _vm.heroBannerData.linkTo &&
                              !_vm.heroBannerData.jumpToHtml
                                ? _c("a", {
                                    staticClass: "btn",
                                    class: _vm.heroBannerData.ctaStyle,
                                    style: {
                                      color: _vm.heroBannerData.ctaColor,
                                      backgroundColor:
                                        _vm.heroBannerData.ctaBgColor
                                    },
                                    attrs: {
                                      href: _vm.heroBannerData.isExternalLink
                                        ? _vm.heroBannerData.linkTo
                                        : _vm.globals.getUrlWithContextPath(
                                            _vm.heroBannerData.linkTo
                                          ),
                                      target: _vm.heroBannerData.isExternalLink
                                        ? "_blank"
                                        : "",
                                      "aria-label": _vm.stripHtmlTags(
                                        _vm.heroBannerData.headingText
                                      )
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.heroBannerData.ctaText
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.heroBannerData.ctaText &&
                              _vm.heroBannerData.linkTo &&
                              _vm.heroBannerData.jumpToHtml
                                ? _c("a", {
                                    staticClass: "btn",
                                    class: _vm.heroBannerData.ctaStyle,
                                    style: {
                                      color: _vm.heroBannerData.ctaColor,
                                      backgroundColor:
                                        _vm.heroBannerData.ctaBgColor
                                    },
                                    attrs: {
                                      href: _vm.heroBannerData.isExternalLink
                                        ? _vm.heroBannerData.linkTo
                                        : _vm.globals.getUrlWithContextPath(
                                            _vm.heroBannerData.linkTo
                                          ),
                                      target: _vm.heroBannerData.isExternalLink
                                        ? "_blank"
                                        : "",
                                      "aria-label": _vm.stripHtmlTags(
                                        _vm.heroBannerData.headingText
                                      )
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.heroBannerData.ctaText
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.emitScrollSignal(
                                          _vm.heroBannerData.linkTo
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { attrs: { "aria-label": [_vm.backgroundAlt] } },
                  [
                    _vm.hasRaysImage()
                      ? [
                          _c("lazy-component", [
                            _c(
                              "picture",
                              {
                                staticClass: "banner-responsive raysimage",
                                class: _vm.heroBannerData.componentTheme
                              },
                              [
                                _c("source", {
                                  attrs: {
                                    media: "(min-width: 1200px)",
                                    srcset: _vm.raysImages.desktop
                                  }
                                }),
                                _vm._v(" "),
                                _c("source", {
                                  attrs: {
                                    media: "(min-width: 768px)",
                                    srcset: _vm.raysImages.tablet
                                  }
                                }),
                                _vm._v(" "),
                                _c("source", {
                                  attrs: {
                                    media: "(min-width: 360px)",
                                    srcset: _vm.raysImages.mobile
                                  }
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "banner__image",
                                  attrs: {
                                    src: _vm.raysImages.desktop,
                                    alt: _vm.backgroundAlt
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("lazy-component", [
                      _c(
                        "picture",
                        {
                          staticClass: "banner",
                          class: _vm.heroBannerData.componentTheme
                        },
                        [
                          _c("source", {
                            attrs: {
                              media: "(min-width: 1200px)",
                              srcset: _vm.heroBannerData.backgroundImageD
                            }
                          }),
                          _vm._v(" "),
                          _c("source", {
                            attrs: {
                              media: "(min-width: 768px)",
                              srcset: _vm.heroBannerData.backgroundImageT
                            }
                          }),
                          _vm._v(" "),
                          _c("source", {
                            attrs: {
                              media: "(min-width: 360px)",
                              srcset: _vm.heroBannerData.backgroundImageM
                            }
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "banner__image",
                            attrs: {
                              src: _vm.heroBannerData.backgroundImageD,
                              alt: _vm.backgroundAlt
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "banner__promotion",
                              class: [
                                _vm.heroBannerData.textPosition,
                                "hero-banner-text-content"
                              ],
                              style: [_vm.backgroundStyle]
                            },
                            [
                              _vm.heroBannerData.headerLogo
                                ? _c("div", { staticClass: "headerLogo" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.heroBannerData.headerLogo,
                                        alt:
                                          _vm.heroBannerData
                                            .headerLogoImageAltText
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.heroBannerData.headingText
                                ? _c("div", {
                                    staticClass:
                                      "banner__heading my-xs-2 my-sm-4 ",
                                    style: {
                                      color: _vm.heroBannerData.headingColor
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.heroBannerData.headingText
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.heroBannerData.subHeadingText
                                ? _c("h2", {
                                    staticClass:
                                      "banner__subheading my-xs-2 my-sm-4",
                                    style: {
                                      color: _vm.heroBannerData.subHeadingColor
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.heroBannerData.subHeadingText
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.heroBannerData.ctaText &&
                              _vm.heroBannerData.linkTo &&
                              !_vm.heroBannerData.jumpToHtml
                                ? _c("a", {
                                    staticClass: "btn",
                                    class: _vm.heroBannerData.ctaStyle,
                                    style: {
                                      color: _vm.heroBannerData.ctaColor,
                                      backgroundColor:
                                        _vm.heroBannerData.ctaBgColor
                                    },
                                    attrs: {
                                      href: _vm.heroBannerData.isExternalLink
                                        ? _vm.heroBannerData.linkTo
                                        : _vm.globals.getUrlWithContextPath(
                                            _vm.heroBannerData.linkTo
                                          ),
                                      target: _vm.heroBannerData.isExternalLink
                                        ? "_blank"
                                        : "",
                                      "aria-label": _vm.stripHtmlTags(
                                        _vm.heroBannerData.headingText
                                      )
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.heroBannerData.ctaText
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.heroBannerData.ctaText &&
                              _vm.heroBannerData.linkTo &&
                              _vm.heroBannerData.jumpToHtml
                                ? _c("a", {
                                    staticClass: "btn",
                                    class: _vm.heroBannerData.ctaStyle,
                                    style: {
                                      color: _vm.heroBannerData.ctaColor,
                                      backgroundColor:
                                        _vm.heroBannerData.ctaBgColor
                                    },
                                    attrs: {
                                      href: _vm.heroBannerData.isExternalLink
                                        ? _vm.heroBannerData.linkTo
                                        : _vm.globals.getUrlWithContextPath(
                                            _vm.heroBannerData.linkTo
                                          ),
                                      target: _vm.heroBannerData.isExternalLink
                                        ? "_blank"
                                        : "",
                                      "aria-label": _vm.stripHtmlTags(
                                        _vm.heroBannerData.headingText
                                      )
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.heroBannerData.ctaText
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.emitScrollSignal(
                                          _vm.heroBannerData.linkTo
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ])
                  ],
                  2
                )
          ])
        : _vm.heroBannerData.componetType === "divWrapper"
        ? _vm._t("default", [
            _vm.heroBannerData.showTextFullWidth
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "banner banner__text-full-width",
                      style: { backgroundColor: _vm.heroBannerData.wrapperBg }
                    },
                    [
                      _c("div", { staticClass: "banner__promotion p-xs-4" }, [
                        _vm.heroBannerData.headerLogo
                          ? _c("div", { staticClass: "headerLogo" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.heroBannerData.headerLogo,
                                  alt: _vm.heroBannerData.headerLogoImageAltText
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerData.headingText
                          ? _c("div", {
                              staticClass:
                                "banner__heading mt-xs-0 my-xs-2 my-sm-4 ",
                              style: { color: _vm.heroBannerData.headingColor },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerData.headingText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerData.subHeadingText
                          ? _c("h3", {
                              staticClass: "banner__subheading",
                              style: {
                                color: _vm.heroBannerData.subHeadingColor
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerData.subHeadingText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerData.ctaText &&
                        _vm.heroBannerData.linkTo &&
                        !_vm.heroBannerData.jumpToHtml
                          ? _c("a", {
                              staticClass: "btn",
                              class: _vm.heroBannerData.ctaStyle,
                              style: {
                                color: _vm.heroBannerData.ctaColor,
                                backgroundColor: _vm.heroBannerData.ctaBgColor
                              },
                              attrs: {
                                href: _vm.heroBannerData.isExternalLink
                                  ? _vm.heroBannerData.linkTo
                                  : _vm.globals.getUrlWithContextPath(
                                      _vm.heroBannerData.linkTo
                                    ),
                                target: _vm.heroBannerData.isExternalLink
                                  ? "_blank"
                                  : ""
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.heroBannerData.ctaText)
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerData.ctaText &&
                        _vm.heroBannerData.linkTo &&
                        _vm.heroBannerData.jumpToHtml
                          ? _c("a", {
                              staticClass: "btn",
                              class: _vm.heroBannerData.ctaStyle,
                              style: {
                                color: _vm.heroBannerData.ctaColor,
                                backgroundColor: _vm.heroBannerData.ctaBgColor
                              },
                              attrs: {
                                href: _vm.heroBannerData.isExternalLink
                                  ? _vm.heroBannerData.linkTo
                                  : _vm.globals.getUrlWithContextPath(
                                      _vm.heroBannerData.linkTo
                                    ),
                                target: _vm.heroBannerData.isExternalLink
                                  ? "_blank"
                                  : ""
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.heroBannerData.ctaText)
                              },
                              on: {
                                click: function($event) {
                                  _vm.emitScrollSignal(
                                    _vm.heroBannerData.linkTo
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass: "banner banner__div",
                      class: _vm.heroBannerData.componentTheme,
                      style: { backgroundColor: _vm.heroBannerData.wrapperBg }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "banner__promotion",
                          class: _vm.heroBannerData.textPosition
                        },
                        [
                          _vm.heroBannerData.headerLogo
                            ? _c(
                                "div",
                                { staticClass: "headerLogo" },
                                [
                                  _c("lazy-component", [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.heroBannerData.headerLogo,
                                        alt:
                                          _vm.heroBannerData
                                            .headerLogoImageAltText
                                      }
                                    })
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.heroBannerData.headingText
                            ? _c("div", {
                                staticClass: "banner__heading",
                                style: {
                                  color: _vm.heroBannerData.headingColor
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.heroBannerData.headingText
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.heroBannerData.subHeadingText
                            ? _c("h3", {
                                staticClass: "banner__subheading",
                                style: {
                                  color: _vm.heroBannerData.subHeadingColor
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.heroBannerData.subHeadingText
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.heroBannerData.ctaText &&
                          _vm.heroBannerData.linkTo &&
                          !_vm.heroBannerData.jumpToHtml
                            ? _c("a", {
                                staticClass: "btn",
                                class: _vm.heroBannerData.ctaStyle,
                                style: {
                                  color: _vm.heroBannerData.ctaColor,
                                  backgroundColor: _vm.heroBannerData.ctaBgColor
                                },
                                attrs: {
                                  href: _vm.heroBannerData.isExternalLink
                                    ? _vm.heroBannerData.linkTo
                                    : _vm.globals.getUrlWithContextPath(
                                        _vm.heroBannerData.linkTo
                                      ),
                                  target: _vm.heroBannerData.isExternalLink
                                    ? "_blank"
                                    : ""
                                },
                                domProps: {
                                  innerHTML: _vm._s(_vm.heroBannerData.ctaText)
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.heroBannerData.ctaText &&
                          _vm.heroBannerData.linkTo &&
                          _vm.heroBannerData.jumpToHtml
                            ? _c("a", {
                                staticClass: "btn",
                                class: _vm.heroBannerData.ctaStyle,
                                style: {
                                  color: _vm.heroBannerData.ctaColor,
                                  backgroundColor: _vm.heroBannerData.ctaBgColor
                                },
                                attrs: {
                                  href: _vm.heroBannerData.isExternalLink
                                    ? _vm.heroBannerData.linkTo
                                    : _vm.globals.getUrlWithContextPath(
                                        _vm.heroBannerData.linkTo
                                      ),
                                  target: _vm.heroBannerData.isExternalLink
                                    ? "_blank"
                                    : ""
                                },
                                domProps: {
                                  innerHTML: _vm._s(_vm.heroBannerData.ctaText)
                                },
                                on: {
                                  click: function($event) {
                                    _vm.emitScrollSignal(
                                      _vm.heroBannerData.linkTo
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
          ])
        : _vm.heroBannerData.componetType === "videoWrapper"
        ? _vm._t("default", [
            _c("vx-video-player", { ref: "videoPlayer" }),
            _vm._v(" "),
            _c("lazy-component", [
              _c(
                "picture",
                {
                  staticClass: "banner banner__video-layer",
                  class: _vm.heroBannerData.componentTheme
                },
                [
                  _c("source", {
                    attrs: {
                      media: "(min-width: 1200px)",
                      srcset: _vm.heroBannerData.backgroundImageD
                    }
                  }),
                  _vm._v(" "),
                  _c("source", {
                    attrs: {
                      media: "(min-width: 768px)",
                      srcset: _vm.heroBannerData.backgroundImageT
                    }
                  }),
                  _vm._v(" "),
                  _c("source", {
                    attrs: {
                      media: "(min-width: 360px)",
                      srcset: _vm.heroBannerData.backgroundImageM
                    }
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "banner__image",
                    attrs: {
                      src: _vm.heroBannerData.backgroundImageD,
                      alt: _vm.backgroundAlt
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "banner__promotion",
                      class: _vm.heroBannerData.textPosition
                    },
                    [
                      _vm.heroBannerData.headingText
                        ? _c("div", {
                            staticClass: "banner__heading",
                            style: { color: _vm.heroBannerData.headingColor },
                            domProps: {
                              innerHTML: _vm._s(_vm.heroBannerData.headingText)
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.heroBannerData.subHeadingText
                        ? _c("h3", {
                            staticClass: "banner__subheading",
                            style: {
                              color: _vm.heroBannerData.subHeadingColor
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.heroBannerData.subHeadingText
                              )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.heroBannerData.ctaText
                        ? _c("a", {
                            staticClass: "btn",
                            class: _vm.heroBannerData.ctaStyle,
                            style: {
                              color: _vm.heroBannerData.ctaColor,
                              backgroundColor: _vm.heroBannerData.ctaBgColor
                            },
                            attrs: {
                              href: "javascript:void(0)",
                              target: _vm.heroBannerData.isExternalLink
                                ? "_blank"
                                : ""
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.heroBannerData.ctaText)
                            },
                            on: { click: _vm.openModal }
                          })
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "banner__video-play-icon banner__position__center icon-play",
                      style: { color: _vm.heroBannerData.playIconColor },
                      attrs: {
                        "aria-label": _vm.i18n.iconPlayTitle,
                        href: "javascript:void(0)",
                        title: _vm.i18n.iconPlayTitle
                      },
                      on: { click: _vm.openModal }
                    },
                    [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(_vm._s(_vm.i18n.iconPlayTitle))
                      ])
                    ]
                  )
                ]
              )
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-36ad39b7", { render: render, staticRenderFns: staticRenderFns })
  }
}