import globals from '../../../../components/common/globals';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import ManageProfileShoppingListService
  from '../../../../components/common/services/manage-profile-shopping-lists-service';
import vxAddEditAddress from '../../../../components/common/vx-add-edit-address/vx-add-edit-address.vue';
import vxDeleteAddress from '../../../../components/common/vx-delete-address/vx-delete-address.vue';
import vxProfileCard from '../../common/vx-profile-card/vx-profile-card.vue';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import {
  myAccountAddressStatus,
} from '../../../../components/common/mixins/vx-enums';
import {
  eventBus,
} from '../../../../modules/event-bus';

export default {
  name: 'vx-my-account-address',
  mixins: [flyoutBannerMixin],
  components: {
    vxModal,
    vxSpinner,
    vxAddEditAddress,
    vxProfileCard,
    vxDeleteAddress,
  },
  props: {
    /**
        * Labels, button and caption texts
        */
    i18n: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      globals,
      hideUnderReviewSection: true,
      addresses: [],
      isAdmin: false,
      selectedAddress: {},
      editAddressUrl: '',
      addressModalHeading: '',
      manageProfileShoppingListService: new ManageProfileShoppingListService(),
      isActive: false,
      isDisabled: false,
      modalScrollable: true,
    };
  },
  computed: {},
  mounted() {
    this.getAddresses();
    // TODO Update Service instead of Ajax Calls
    // Service Changes
    // const manageProfileShoppingListService = new ManageProfileShoppingListService(this.globals.getHeaders());
    // manageProfileShoppingListService.getAddresses(this.handleGetAddressResponse);
    this.$refs.spinner.showSpinner();
    eventBus.$on('removeScrollbar', (isRemoveScrollbar) => {
      this.modalScrollable = !isRemoveScrollbar;
    });
  },
  methods: {
    /**
         * Fetch the address of user
         * @returns null
         */
    getAddresses() {
      this.manageProfileShoppingListService.getAddresses({}, this.handleGetAddressResponse, this.handleGetAddressError);
    },
    /**
         * Delete the address of user and close the delete modal.
         * @returns null
         */
    deletedAddress() {
      this.$refs.deleteAddressModal.close();
      this.getAddresses();
    },
    /**
         * Handles savig of an address from given inputs
         * @param {Object} address - address details object
         * @returns null
         */
    saveAddress(address) {
      const addressObj = {
        ...address.address.value,
        fromPage: 'my-account',
      };
      const requestConfig = {};
      requestConfig.data = addressObj;
      if (address.address.value.id) {
        // Edit Address
        this.manageProfileShoppingListService.updateAddress(requestConfig, this.handleCreateAddressResponse(this.i18n.addAddress.updateAddressDetails), this.handleCreateAddressError, address.address.value.id);
      } else {
        // Add Address
        this.manageProfileShoppingListService.createShippingAddress(requestConfig, this.handleCreateAddressResponse(this.i18n.addAddress.addAddressDetails), this.handleCreateAddressError);
      }
      this.$refs.addNewAddressModal.close();
      this.$refs.spinner.showSpinner();
    },
    /**
         * Handles the response of edit address service, and make the true/false of
         * active, disable, review status on admin/system status
         * @param {Object} response - success response object
         */
    handleEditAddressResponse() {
      this.$refs.spinner.hideSpinner();
      this.getAddresses();
    },
    /**
         * Handles the error response of edit address service
         * @param {Object} error - error response object
         * @returns null
         */
    handleEditAddressError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * Handles address modal
         * @param {Object} updatedAddress
         */
    handleAddressModal() {},
    /**
         * Open address modal
         * @param {Boolean} isNew - true/false
         * @param {Object} address - address object
         * @param {Object} event - event object
         * @returns null
         */
    openAddressModal(isNew, address, event) {
      if (isNew) {
        this.selectedAddress = {};
        this.addressModalHeading = this.i18n.addAddress.addAddressHeading;
      } else {
        this.selectedAddress = address;
        this.addressModalHeading = this.i18n.addAddress.editAddressHeading;
      }
      this.$refs.addNewAddressModal.open(event);
    },
    /**
         * Handles open delete modal
         * @param {Object} address - address details object
         * @param {Object} event - event object
         * @returns null
         */
    openDeleteModal(address, event) {
      this.selectedAddress = address;
      this.$refs.deleteAddressModal.open(event);
    },
    /**
         * Handles the response of address service, and make the true/false of
         * active, disable, review status on admin/system status
         * @param {Object} response
         */
    handleGetAddressResponse(response) {
      this.addresses = response.data.addresses;
      this.isActive = false;
      this.isDisabled = false;
      this.addresses.forEach((address) => {
        if (address.approvalStatus === myAccountAddressStatus.active) {
          this.isActive = true;
        }
        if (address.approvalStatus === myAccountAddressStatus.disabled) {
          this.isDisabled = true;
        }
      });
      this.isAdmin = response.data.isAdmin;
      for (const i in this.addresses) {
        if (
          this.addresses[i].approvalStatus === myAccountAddressStatus.pending
                    || this.addresses[i].approvalStatus === myAccountAddressStatus.pendingbyadmin
                    || this.addresses[i].approvalStatus === myAccountAddressStatus.pendingbygp
        ) {
          this.hideUnderReviewSection = false;
          break;
        }
      }
      this.$refs.spinner.hideSpinner();
    },
    /**
         * Handles the error response of address service, and hide loader on failure.
         * @param {Object} error
         * @returns null
         */
    handleGetAddressError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * Hanldes fething address and display
         * @param {Object} - success response of saving address
         * @returns null
         */
    handleCreateAddressResponse(response) {
      this.$refs.spinner.hideSpinner();
      this.getAddresses();
      this.showFlyout('success', response, true, 10000);
    },
    /**
         * Handles error response of saving address
         * @param {*} error
         */
    handleCreateAddressError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * Handles enable address on making service call
         * @param {String} addId
         * @param {String} uid
         * @param {String} userId
         * @returns null
         */
    enableUserAddress(addId, uid, userId) {
      this.$refs.spinner.showSpinner();
      const addConfig = {
        addId,
        payload: {
          approvalStatus: myAccountAddressStatus.active,
          unit: {
            uid,
          },
          userId,
        },
      };
      const manageProfileShoppingListService = new ManageProfileShoppingListService(this.globals.getHeaders());
      manageProfileShoppingListService.toggleUserAddress({}, this.handleAddressUpdateResponse, this.handleAddressUpdateError, addConfig);
    },
    /**
         * Handles disabling address on making service call
         * @param {String} addId
         * @param {String} uid
         * @param {String} userId
         * @returns null
         */
    disableUserAddress(addId, uid, userId) {
      this.$refs.spinner.showSpinner();
      const addConfig = {
        addId,
        payload: {
          approvalStatus: myAccountAddressStatus.disabled,
          unit: {
            uid,
          },
          userId,
        },
      };
      const manageProfileShoppingListService = new ManageProfileShoppingListService(this.globals.getHeaders());
      manageProfileShoppingListService.toggleUserAddress({}, this.handleAddressUpdateResponse, this.handleAddressUpdateError, addConfig);
    },
    /**
         * Handle address enable/disable success response
         * @param {Object} response
         * @returns null
         */
    handleAddressUpdateResponse(response) {
      const { status } = response;
      if (status) {
        this.getAddresses();
      }
    },
    /**
         * Handles error/failure response of enable/disable of address
         * @param {Object} error - error response
         * @returns null
         */
    handleAddressUpdateError() {},
    /**
         * Handles user address rejetion
         * @param {String} addId
         * @param {String} uid
         * @param {String} userId
         * @returns null
         */
    rejectUserAddress(addId, uid, userId) {
      this.$refs.spinner.showSpinner();
      const addConfig = {
        addId,
        payload: {
          approvalStatus: 'REJECTED',
          unit: {
            uid,
          },
          userId,
          fromPage: 'my-account',
        },
      };
      const manageProfileShoppingListService = new ManageProfileShoppingListService(this.globals.getHeaders());
      manageProfileShoppingListService.toggleUserAddress({}, this.handleAddressUpdateResponse, this.handleAddressUpdateError, addConfig);
    },
    /**
         * Handles address approval
         * @param {String} addId
         * @param {String} uid
         * @param {String} userId
         * @returns null
         */
    approveUserAddress(addId, uid, userId) {
      this.$refs.spinner.showSpinner();
      const addConfig = {
        addId,
        payload: {
          approvalStatus: 'PENDINGBYGP',
          unit: {
            uid,
          },
          userId,
          fromPage: 'my-account',
        },
      };
      const manageProfileShoppingListService = new ManageProfileShoppingListService(this.globals.getHeaders());
      manageProfileShoppingListService.toggleUserAddress({}, this.handleAddressUpdateResponse, this.handleAddressUpdateError, addConfig);
    },
  },
};
