var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pdp-page" }, [
    _c("h1", [_vm._v(_vm._s(_vm.msg))]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-6" },
          [
            _c("vx-thumbnail-viewer", {
              attrs: {
                "total-carousel-data": _vm.messages.thumbnailViewer.galleryData
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-6" },
          [
            _c("vx-pdp-product-info", {
              attrs: {
                i18n: _vm.messages.pdpProductInfo,
                "pdp-product-info-data":
                  _vm.messages.pdpProductSection.productInfo
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12" },
          [
            _c("vx-tab-container", {
              attrs: {
                i18n: _vm.messages.pdpTabContainer,
                "pdp-product-info-data":
                  _vm.messages.pdpProductSection.productInfo
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2594f544", { render: render, staticRenderFns: staticRenderFns })
  }
}