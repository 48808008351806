var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "vx-empty-cart" }, [
    !_vm.isMiniCart
      ? _c("div", { staticClass: "empty-shopping-cart py-xs-4" }, [
          _c("p", { staticClass: "empty-shopping-cart-heading pb-xs-3" }, [
            _vm._v(_vm._s(_vm.i18n.emptyShoppingCartHeading))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "empty-shopping-cart-msg pb-xs-4" }, [
            _vm._v(_vm._s(_vm.i18n.emptyShoppingCartMsg))
          ]),
          _vm._v(" "),
          _c("button", { staticClass: "btn btn-tertiary btn-small-text" }, [
            _vm._v(_vm._s(_vm.i18n.continueShoppingButton))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isMiniCart
      ? _c("p", { staticClass: "empty-mini-cart-msg" }, [
          _vm.msgVisible
            ? _c("span", [_vm._v(_vm._s(_vm.i18n.emptyMiniCartMsg))])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ffa28ef6", { render: render, staticRenderFns: staticRenderFns })
  }
}