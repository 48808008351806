var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nfr-page" }, [
    _c("h1", [_vm._v(_vm._s(_vm.msg))]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12" },
          [_c("vx-404-page", { attrs: { i18n: _vm.messages.nfr.i18n404 } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12" },
          [_c("vx-500-page", { attrs: { i18n: _vm.messages.nfr.i18n500 } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("br")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-288df246", { render: render, staticRenderFns: staticRenderFns })
  }
}