var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.globals.siteConfig.showProductIcons && _vm.productIconsData.length
    ? _c("div", { staticClass: "vx-product-icons" }, [
        _c(
          "ul",
          _vm._l(_vm.productIconsData, function(productIcon) {
            return _c("li", [
              _c("span", {
                class: productIcon.className,
                attrs: {
                  "aria-hidden": "true",
                  title: productIcon.title,
                  "aria-label": productIcon.title
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "sr-only" }, [
                _vm._v(_vm._s(productIcon.title))
              ])
            ])
          })
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7d841c8a", { render: render, staticRenderFns: staticRenderFns })
  }
}