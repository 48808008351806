/* eslint-disable no-new */
/* eslint-disable no-undef */
export default {
  name: 'vx-youtube-player',
  props: {
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    src: {
      type: String,
    },
  },
  computed: {},
  async mounted() {
    const self = this;
    // get any previously added script tag for youtube iframe API

    // method to play the video programatically once the player has been loaded
    window.onPlayerReady = function(event) {
      event.target.playVideo();
    };

    if (!window.YT) {
      // create a script tag for using the Youtube iframe API
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      tag.id = 'youtube-script';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = function() {
        self.initializeYoutubePlayer();
      };
    } else {
      self.initializeYoutubePlayer();
    }
  },
  methods: {
    initializeYoutubePlayer() {
      // extract the youtube video ID if complete url was provided
      const videoCode = this.src.split('embed/')[1].split('?')[0];
      new YT.Player('vx-youtube-player', {
        height: this.height,
        width: this.width,
        videoId: videoCode,
        events: {
          onReady: window.onPlayerReady,
        },
      });
    },
  },
};
