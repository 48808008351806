var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-details-tab" }, [
    _c("div", { staticClass: "product-details-tab__container" }, [
      _c("div", { staticClass: "section row" }, [
        _vm.globals.siteConfig.showProductFeatures
          ? _c("div", { staticClass: "top-right-section" }, [
              _c("div", [
                _c(
                  "ul",
                  {
                    staticClass:
                      "product-details-tab__toprightcontent px-xs-3 py-xs-1"
                  },
                  _vm._l(_vm.productDetailsTabData.featureList, function(
                    productFeature,
                    index
                  ) {
                    return _c("li", [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(productFeature) }
                      })
                    ])
                  })
                )
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d525e232", { render: render, staticRenderFns: staticRenderFns })
  }
}