var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row vx-minimized-header" }, [
    _c("div", [
      _vm.imgUrl
        ? _c("img", {
            attrs: {
              src: _vm.globals.assetsPath + "images/" + _vm.imgUrl,
              alt: _vm.imgAltText,
              "aria-hidden": "true"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "sr-only" }, [_vm._v("Employee Store")])
    ]),
    _vm._v(" "),
    _vm.i18n.placeholderTxt
      ? _c("h4", [_vm._v(_vm._s(_vm.i18n.placeholderTxt))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-aea931ca", { render: render, staticRenderFns: staticRenderFns })
  }
}