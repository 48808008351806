var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-container" }, [
    _c("h1", [_vm._v(_vm._s(_vm.msg))]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row", staticStyle: { padding: "20px" } },
      [
        _c("h6", [_vm._v("Form Elements")]),
        _vm._v(" "),
        _c("h7", [_vm._v("Input field")]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("h7", [_vm._v("Input field without label")]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("h7", [_vm._v("Input field with errors")]),
        _vm._v(" "),
        _c("section", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "sample2" } }, [_vm._v("Label")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|alpha",
                expression: "'required|alpha'"
              }
            ],
            staticClass: "form-control input-error",
            attrs: {
              type: "text",
              id: "sample2",
              placeholder: "Sample2",
              name: "sample2"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "error-style" }, [
            _vm._v(_vm._s(_vm.errors.first("sample2")))
          ])
        ]),
        _vm._v(" "),
        _c("h6", [_vm._v("Stepper Control")]),
        _vm._v(" "),
        _c("span", [_vm._v("Stepper control one way binding")]),
        _vm._v(" "),
        _c("vx-stepper-control", { attrs: { value: _vm.stepperVal } }),
        _vm._v(" "),
        _c("span", [_vm._v("Stepper control two way binding")]),
        _vm._v(" "),
        _c("vx-stepper-control", {
          model: {
            value: _vm.stepperVal,
            callback: function($$v) {
              _vm.stepperVal = $$v
            },
            expression: "stepperVal"
          }
        }),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.stepperVal))]),
        _vm._v(" "),
        _c("h7", [_vm._v("Badge")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "badge" }, [_vm._v("13")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("h6", [_vm._v("Radio Button")]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c("h6", [_vm._v("Radio Button Group")]),
        _vm._v(" "),
        _c("vx-radio-button-group", {
          attrs: { "radiobutton-values": _vm.radioOptions, name: "myoptions" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "20px" } }, [
      _c("hr"),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "fonts-section", staticStyle: { "font-size": "30px" } },
        [
          _c("h2", [_vm._v("Font Icons")]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("span", { staticClass: "icon-facebook" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-google" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-chevron-right" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-chevron-left" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-chevron-down" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-chevron-up" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-check" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-bookmark" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-attachment" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-alert-triangle" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-alert-circle" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-circle" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-disc" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-download" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-edit" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-eye" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-file" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-filter" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-heart" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-info" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-loader" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-mail" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-maximize" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-menu" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-message-square" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-minimize" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-minus" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-more-horizontal" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-more-vertical" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-phone" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-play" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-plus" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-printer" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-save" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-search" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-settings" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-share" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-square" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-star" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-trash" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-user" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-x-circle" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-x" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-zoom-in" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-zoom-out" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-add-to-list" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-certification" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-online" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-save-cart" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-seasonal" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-shipping" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-subscription" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-cart" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-quick-order" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-star-half-filled" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-star-filled" })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "row", staticStyle: { padding: "20px" } }, [
        _c("button", { staticClass: "btn btn-default" }, [_vm._v("Default")]),
        _vm._v(" "),
        _c("button", { staticClass: "btn btn-primary" }, [_vm._v("Primary")]),
        _vm._v(" "),
        _c("button", { staticClass: "btn btn-tertiary" }, [_vm._v("Tertiary")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row", staticStyle: { padding: "20px" } }, [
        _c("button", { staticClass: "btn btn-default btn-small-text" }, [
          _vm._v("Default Small Text")
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "btn btn-primary btn-small-text" }, [
          _vm._v("Primary Small Text")
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "btn btn-tertiary btn-small-text" }, [
          _vm._v("Tertiary Small Text")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row", staticStyle: { padding: "20px" } }, [
        _c("h1", [
          _vm._v("Custom Checkboxes (No id binding Required - Accessible)")
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "checkbox-container" }, [
          _vm._v("One\n          "),
          _c("input", { attrs: { type: "checkbox", checked: "" } }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" })
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "checkbox-container" }, [
          _vm._v("Two\n          "),
          _c("input", { attrs: { type: "checkbox" } }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" })
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "checkbox-container" }, [
          _vm._v("Three\n          "),
          _c("input", { attrs: { type: "checkbox" } }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" })
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "checkbox-container" }, [
          _vm._v("Four\n          "),
          _c("input", { attrs: { type: "checkbox" } }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" })
        ]),
        _vm._v(" "),
        _c("h1", [_vm._v("Custom Checkboxes ID based - Acccessible")]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", { attrs: { id: "checkbox", type: "checkbox" } }),
          _vm._v(" "),
          _c("label", { attrs: { for: "checkbox" } }, [_vm._v("Checkbox")])
        ]),
        _vm._v(" "),
        _c("h1", [_vm._v("Noraml Checkboxes - Acccessible")]),
        _vm._v(" "),
        _c("input", { attrs: { id: "checkbox", type: "checkbox" } }),
        _vm._v("Normal Checkbox\n        "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "tag" }, [
          _c("span", { staticClass: "tag-content" }, [_vm._v("Filter tag")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "sample" } }, [_vm._v("Label")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "text", id: "sample", placeholder: "Sample" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "form-group" }, [
      _c("input", {
        staticClass: "form-control no-title",
        attrs: { type: "text", id: "sample1", placeholder: "Sample1" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "form-group" }, [
      _c("input", { attrs: { type: "radio", id: "radioButton" } }),
      _vm._v(" "),
      _c("label", { attrs: { for: "radioButton" } }, [_vm._v("Radio")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ca60cc46", { render: render, staticRenderFns: staticRenderFns })
  }
}