/* eslint-disable no-param-reassign */
/**
 * PDP Service provides services for Product Detail Page Module.
 */

import RootService from './root-service';
import globals from '../globals';

class PdpService extends RootService {
  /**
   * Get Product Data service call which gets product data on load of PDP
   * @param  {String}   productId string of product id
   * @param  {String}   userId string of user id
   */
  getProductData(requestConfig, successCallback, errorCallback, productId, userId) {
    const config = requestConfig;
    config.headers = {
      pragma: 'no-cache',
    };
    config.url = `${globals.getRestUrl('pdpProductsUrl')}/${productId}`;
    if (userId) {
      config.params = {
        fields: 'FULL',
        userId,
        nocache: new Date().getTime(),
      };
    } else {
      config.params = {
        fields: 'FULL',
        nocache: new Date().getTime(),
      };
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Add Product to Cart service call which adds that product to cart
   */
  addProductToCart(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('addProductToCart', 'cart');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Add Product to wishlist service call which adds that product to wishlist
   */
  addProductToList(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('addProductToList', 'cart');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Subscribe Product to Cart service call
   */
  subscribeProductToCart(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('subscribeProductToCart', 'cart');
    this.post(config, successCallback, errorCallback);
  }

  /**
   *  Get Subscription Frequency service call to get all the subscription frequency available
   */
  getSubscriptionFrequency(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('getSubscriptionFrequency', 'cart');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Add Refill Products service call
   */
  addRefillsProducts(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('refillsUrl', 'cart');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Share Product service call which shares that product
   */
  shareItem(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('shareProduct');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Common service call for upsell, crossell and related products
   * @param  {String}   productId string of product id
   */
  getProductsData(requestConfig, successCallback, errorCallback, productId) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('getProductsUrl')}/${productId}/references`;
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Service for upsell, crossell and similar products in Related Products Tab in sections
   * @param  {String}   productId string of product id
   */
  getRelatedProductsData(requestConfig, successCallback, errorCallback, productId) {
    const config = requestConfig;
    if (globals.siteConfig.isRelatedEnabled) {
      config.url = `${globals.getRestUrl('getProductsUrl')}/${productId}/relatedProducts`;
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Save to List service call adds the product to list
   */
  saveAList(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('createList', 'user');
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Shopping List get service call which gives all the shopping list names of that user
   */
  getShoppingLists(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('getAllWishLists', 'user');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Delete Product from List service call which deletes product from the list
   */
  deleteCartItem(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('deleteListEntry', 'user');
    this.delete(config, successCallback, errorCallback);
  }

  /**
   * Share Product Resource service call which shares that product Resource
   */
  shareResource(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('shareResource');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Download Images service call which downloads Images in zip for products
   * @param  {String}   productId string of product id
   */
  getImagesInZipFormat(requestConfig, successCallback, errorCallback, productId) {
    const config = requestConfig;
    config.responseType = 'arraybuffer';
    config.url = `${globals.getRestUrl('pdpProductsUrl')}/${productId}/exportimage`;
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Save PDF Details service call which saves the details entered by the user in the PDF modal
   */
  savePdfDetails(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('savePdfDetails', 'user')}`;
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Get Saved PDF Details service call which gets the saved details entered by the user in the PDF modal
   * @param  {String}   certificationsName string of names of certifications
   * @param  {String}   productCode value for the productCode
   * @param  {Boolean}  onlyEncodedImage Bool value for onlyEncodedImage

   */
  getSavedPdfDetails(requestConfig, successCallback, errorCallback, certificationsName, productCode, onlyEncodedImage) {
    const config = requestConfig;
    config.headers = {
      pragma: 'no-cache',
    };
    productCode = productCode || '';
    certificationsName = 'ELEMENTS,EPA,CUSTOMIZABLE,ECC,CMA,DFE,GREEN_SEAL,BPI,FSC,HOW_2_RECYCLE,SFI,USDA,USGBC_LEED,ECO_LOGO,WASTE_REDUCING,SAFER_CHOICE,RPA,KOLO,TUV_HOME_COMPOST,NITRILE_COMPATIBLE,HACCP';

    config.url = `${globals.getRestUrl('savePdfDetails', 'user')}?certificationCodes=${certificationsName}&productCode=${productCode}&onlyEncodedImage=${onlyEncodedImage}`;
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Get Subscription Cart Details service call which gets the subscribed cart details as per subscription id
   * @param  {String}   subscriptionCartId string of subscribed cart id
   */
  getSubscriptionCart(requestConfig, successCallback, errorCallback, subscriptionCartId) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('subscriptionCart', 'user')}/${subscriptionCartId}`;
    config.params = {
      fields: 'FULL',
      calculationType: 'cart',
    };
    this.get(config, successCallback, errorCallback);
  }
}
export {
  PdpService as
  default,
};
