var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-download-product-info" },
    [
      _c(
        "vx-spinner",
        {
          ref: "spinner",
          attrs: {
            image: _vm.globals.assetsPath + "images/spinner.gif",
            "full-screen": ""
          }
        },
        [_vm._v(">")]
      ),
      _vm._v(" "),
      _c("h4", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.i18n.downloadProductInfo.downloadProductInfoDescription
            ) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("label", [
        _vm._v(
          "\n        " +
            _vm._s(_vm.i18n.downloadProductInfo.downloadProductInfoContent) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-xs-4 mb-xs-5 form-group" }, [
        _c("h4", [
          _vm._v(
            "\n            " +
              _vm._s(_vm.i18n.downloadProductInfo.downloadProductInfoFormat) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("vx-radio-button-group", {
              ref: "downloadOptions",
              attrs: {
                "radiobutton-values": _vm.downloadOptions,
                name: _vm.downloadOptions.name
              },
              on: {
                input: function($event) {
                  _vm.selectedDownloadOption($event)
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "button",
          {
            staticClass:
              "submit modal-btn-primary-small modal-btn-xs-sticky form-blue-button",
            attrs: { type: "submit", disabled: _vm.errors.any() },
            on: { click: _vm.downloadExcel }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.i18n.downloadProductInfo.downloadProductInfoButton) +
                "\n        "
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-68f87147", { render: render, staticRenderFns: staticRenderFns })
  }
}