var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-share-resource-modal" },
    [
      _c("vx-spinner", {
        ref: "shareResourceSpinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "resource-name mb-xs-2" }, [
        _vm._v(_vm._s(_vm.resource.altText))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "resource-description" }, [
        _vm._v(_vm._s(_vm.resource.description))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-center mx-xs-3 my-xs-4" },
        [
          _vm.isYoutubeVideo(_vm.resource)
            ? _c("div", { staticClass: "video-player" }, [
                _c("iframe", {
                  attrs: {
                    width: "100%",
                    src: _vm.embedURL,
                    name: "video-player",
                    title: _vm.resource.altText,
                    allowfullscreen: ""
                  }
                })
              ])
            : _c(
                "a",
                {
                  staticClass: "view-btn",
                  attrs: { href: _vm.resource.resourceURL, target: "_blank" }
                },
                [
                  _c(
                    "button",
                    { staticClass: "btn btn-default modal-btn-primary-small" },
                    [_vm._v(_vm._s(_vm.i18n.viewButton))]
                  )
                ]
              )
        ]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("div", { staticClass: "pt-xs-4 form-group" }, [
            _c("label", { attrs: { for: "link" } }, [
              _vm._v(_vm._s(_vm.i18n.linkLabel))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.constructedShareUrl,
                  expression: "constructedShareUrl"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                name: "link",
                "aria-label": _vm.i18n.linkLabel,
                disabled: ""
              },
              domProps: { value: _vm.constructedShareUrl },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.constructedShareUrl = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          !_vm.globals.getIsLoggedIn()
            ? _c("div", { staticClass: "pt-xs-3 form-group" }, [
                _c("label", { attrs: { for: "link" } }, [
                  _vm._v(_vm._s(_vm.i18n.senderNameLabel))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.senderName,
                      expression: "senderName"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { required: true, min: 2, max: 120 },
                      expression: "{required: true, min: 2, max: 120}"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("senderName") },
                  attrs: {
                    type: "text",
                    name: "senderName",
                    minlength: "2",
                    maxlength: "120",
                    "aria-label": _vm.i18n.senderNameLabel
                  },
                  domProps: { value: _vm.senderName },
                  on: {
                    focus: _vm.inputFocusIn,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.senderName = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("senderName"),
                        expression: "errors.has('senderName')"
                      }
                    ],
                    staticClass: "mt-xs-2",
                    attrs: { "aria-hidden": !_vm.errors.has("senderName") }
                  },
                  [
                    _c("span", { staticClass: "error-msg" }, [
                      _vm._v(_vm._s(_vm.errors.first("senderName")))
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.globals.getIsLoggedIn()
            ? _c("div", { staticClass: "pt-xs-3 form-group" }, [
                _c("label", { attrs: { for: "senderEmail" } }, [
                  _vm._v(_vm._s(_vm.i18n.senderEmailLabel))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.senderEmail,
                      expression: "senderEmail"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|email",
                      expression: "'required|email'"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("senderEmail") },
                  attrs: {
                    type: "email",
                    name: "senderEmail",
                    "aria-label": _vm.i18n.senderEmailLabel
                  },
                  domProps: { value: _vm.senderEmail },
                  on: {
                    focus: _vm.inputFocusIn,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.senderEmail = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("senderEmail")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(_vm._s(_vm.errors.first("senderEmail")))
                      ])
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "pt-xs-3 pb-xs-2 form-group" }, [
            _c("label", { attrs: { for: "recipients" } }, [
              _vm._v(_vm._s(_vm.i18n.emailLabel))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.recipientValue,
                  expression: "recipientValue"
                },
                {
                  name: "validate",
                  rawName: "v-validate:recipients",
                  value: "required|email",
                  expression: "'required|email'",
                  arg: "recipients"
                }
              ],
              staticClass: "form-control",
              class: { "input-error": _vm.errors.has("recipients") },
              attrs: {
                type: "text",
                name: "recipients",
                "aria-label": _vm.i18n.emailLabel
              },
              domProps: { value: _vm.recipientValue },
              on: {
                focus: _vm.inputFocusIn,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.recipientValue = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("recipients")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", { staticClass: "error-msg" }, [
                    _vm._v(_vm._s(_vm.errors.first("recipients")))
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "copy-me form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.copyMe,
                  expression: "copyMe"
                }
              ],
              attrs: {
                id: "copy-me",
                type: "checkbox",
                "aria-label": _vm.i18n.copyLabel
              },
              domProps: {
                checked: Array.isArray(_vm.copyMe)
                  ? _vm._i(_vm.copyMe, null) > -1
                  : _vm.copyMe
              },
              on: {
                change: function($event) {
                  var $$a = _vm.copyMe,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.copyMe = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.copyMe = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.copyMe = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "copy-me" } }, [
              _vm._v(_vm._s(_vm.i18n.copyLabel))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pt-xs-3 mb-xs-5 form-group" }, [
            _c("label", { attrs: { for: "comments" } }, [
              _vm._v(_vm._s(_vm.i18n.commentLabel))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userComments,
                  expression: "userComments"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                maxlength: "1024",
                name: "link",
                "aria-label": _vm.i18n.commentLabel
              },
              domProps: { value: _vm.userComments },
              on: {
                focus: _vm.inputFocusIn,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.userComments = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-xs-2 d-flex justify-content-center" }, [
            _c(
              "button",
              {
                staticClass:
                  "submit btn btn-primary modal-btn-primary-small modal-btn-IEspacing modal-btn-EDGEspacing",
                class: { "modal-btn-xs-sticky": _vm.isBtnSticky },
                attrs: { type: "submit", disabled: _vm.errors.any() }
              },
              [_vm._v(_vm._s(_vm.i18n.submitLabel))]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b5a2cb52", { render: render, staticRenderFns: staticRenderFns })
  }
}