/**
 * 1/3 CTA
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-uiux-gp-image-text',
  components: {},
  props: {
    uiuxImageTileData: {
      type: Object,
    },
    uiuxTextTileData: {
      type: Object,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
    };
  },
  computed: {},
  mounted() {
    if (this.uiuxTextTileData.textAlignment) {
      this.$el.style.textAlign = this.uiuxTextTileData.textAlignment;
    }
    if (this.uiuxTextTileData.headerColor) {
      this.$refs.category.style.color = this.uiuxTextTileData.headerColor;
    }
    if (this.uiuxTextTileData.superHeaderColor) {
      this.$refs.header.style.color = this.uiuxTextTileData.superHeaderColor;
      if (this.$refs.header.childNodes[0] && this.$refs.header.childNodes[0].style) {
        this.$refs.header.childNodes[0].style.color = this.uiuxTextTileData.superHeaderColor;
      }
    }
    if (this.uiuxTextTileData.textColor) {
      this.$refs.description.style.color = this.uiuxTextTileData.textColor;
    }
    if (this.uiuxTextTileData.ctaLink && this.uiuxTextTileData.ctaText && this.uiuxTextTileData.isExternalLink === true) {
      this.$refs.link.target = '_blank';
    }
  },
  methods: {
    goToLink(link) {
      if (link) {
        window.location.href = link;
      }
    },
  },
};
