var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "search-browse-compare-table mt-md-5" }, [
    _c(
      "div",
      { staticClass: "compare-table-data d-flex flex-column" },
      _vm._l(_vm.productData.specifications, function(spec) {
        return _c(
          "div",
          { staticClass: "category-block d-flex flex-column" },
          [
            _c(
              "div",
              { staticClass: "category-name d-flex align-items-center" },
              [
                _c("h3", { staticClass: "pl-md-3 pl-xs-3 pl-sm-4" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(spec.categoryLabel) +
                      "\n                "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _vm._l(spec.subCategories, function(sub) {
              return _c(
                "div",
                {
                  staticClass:
                    "category-data d-flex flex-column px-md-5 pb-xs-2 pt-md-2"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "attribute-name mt-md-4 mb-md-3 px-sm-4 px-xs-4 px-md-0"
                    },
                    [
                      _c("h4", { staticClass: "my-md-0" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(sub.attributeLabel) +
                            "\n                    "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "attribute-data d-flex mb-md-4 px-sm-4 px-xs-4 px-md-0 mt-md-4"
                    },
                    [
                      _c("h4", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(sub.attributeLabel) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.specCols(sub.columnValues), function(column) {
                        return _c("div", { staticClass: "attribute-value" }, [
                          _c("span", {
                            staticClass:
                              "word-wrap-all pl-md-0 pl-sm-0 pl-xs-0",
                            domProps: {
                              innerHTML: _vm._s(column == "NULL" ? "-" : column)
                            }
                          })
                        ])
                      })
                    ],
                    2
                  )
                ]
              )
            })
          ],
          2
        )
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e726b00c", { render: render, staticRenderFns: staticRenderFns })
  }
}