var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "create-account" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "companyDetailsModal",
          attrs: { size: "large", heading: _vm.i18n.companyModalHeading },
          on: { close: _vm.loginRequest }
        },
        [
          _c("vx-company-details", {
            attrs: { slot: "component", userData: _vm.user, i18n: _vm.i18n },
            on: {
              companyDetailsubmitted: _vm.loginRequest,
              skip: _vm.loginRequest
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "termsConditionModal",
          attrs: { size: "medium", heading: _vm.i18n.termasConditionHeading }
        },
        [
          _c("vx-terms-condition", {
            attrs: { slot: "component" },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "h1",
        { staticClass: "mb-sm-4 mb-md-4 mt-xs-0 mb-xs-3 register-heading" },
        [_vm._v(_vm._s(_vm.i18n.heading))]
      ),
      _vm._v(" "),
      _vm.globals.isB2B()
        ? _c(
            "div",
            { staticClass: "mt-sm-3 mb-sm-4 mt-xs-4 mb-xs-3 b2b-sub-heading" },
            [_vm._v(_vm._s(_vm.i18n.businessSubHeading))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("form", [
        _c("div", { staticClass: "mb-xs-4 form-group" }, [
          _c(
            "label",
            {
              attrs: { for: "firstName", "aria-hidden": _vm.isDeviceAndroid() }
            },
            [_vm._v(_vm._s(_vm.i18n.firstName))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true, regex: /^[A-Za-z\s\.'-]+$/, max: 35 },
                expression:
                  "{ required: true, regex: /^[A-Za-z\\s\\.'-]+$/ ,max: 35 }"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.firstName,
                expression: "user.firstName"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("firstName") },
            attrs: {
              type: "text",
              maxlength: "35",
              name: "firstName",
              "aria-label": _vm.i18n.firstName,
              "aria-describedby": "register_first_error"
            },
            domProps: { value: _vm.user.firstName },
            on: {
              focus: function($event) {
                _vm.createAccountServerError = ""
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "firstName", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("firstName"),
                  expression: "errors.has('firstName')"
                }
              ],
              staticClass: "mt-xs-2",
              attrs: { "aria-hidden": !_vm.errors.has("firstName") }
            },
            [
              _c(
                "span",
                {
                  staticClass: "error-msg",
                  attrs: { id: "register_first_error" }
                },
                [_vm._v(_vm._s(_vm.errors.first("firstName")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-4 form-group" }, [
          _c(
            "label",
            {
              attrs: { for: "lastName", "aria-hidden": _vm.isDeviceAndroid() }
            },
            [_vm._v(_vm._s(_vm.i18n.lastName))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true, regex: /^[A-Za-z\s\.'-]+$/, max: 35 },
                expression:
                  "{ required: true, regex: /^[A-Za-z\\s\\.'-]+$/ ,max: 35 }"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.lastName,
                expression: "user.lastName"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("lastName") },
            attrs: {
              type: "text",
              maxlength: "35",
              name: "lastName",
              "aria-label": _vm.i18n.lastName,
              "aria-describedby": "register_last_error"
            },
            domProps: { value: _vm.user.lastName },
            on: {
              focus: function($event) {
                _vm.createAccountServerError = ""
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "lastName", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("lastName"),
                  expression: "errors.has('lastName')"
                }
              ],
              staticClass: "mt-xs-2",
              attrs: { "aria-hidden": !_vm.errors.has("lastName") }
            },
            [
              _c(
                "span",
                {
                  staticClass: "error-msg",
                  attrs: { id: "register_last_error" }
                },
                [_vm._v(_vm._s(_vm.errors.first("lastName")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-4 form-group" }, [
          _c(
            "label",
            { attrs: { for: "email", "aria-hidden": _vm.isDeviceAndroid() } },
            [_vm._v(_vm._s(_vm.i18n.email))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|email|max:80",
                expression: "'required|email|max:80'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.uid,
                expression: "user.uid"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("email") },
            attrs: {
              type: "text",
              name: "email",
              maxlength: "80",
              "aria-label": _vm.i18n.email,
              "aria-describedby": _vm.errors.has("email")
                ? "register_email_error"
                : false
            },
            domProps: { value: _vm.user.uid },
            on: {
              focus: function($event) {
                _vm.createAccountServerError = ""
              },
              keyup: function($event) {
                _vm.user.uid = _vm.user.uid.toLowerCase()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "uid", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("email"),
                  expression: "errors.has('email')"
                }
              ],
              staticClass: "mt-xs-2",
              attrs: { "aria-hidden": !_vm.errors.has("email") }
            },
            [
              _c(
                "span",
                {
                  staticClass: "error-msg",
                  attrs: { id: "register_email_error" }
                },
                [_vm._v(_vm._s(_vm.errors.first("email")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-4 form-group" }, [
          _c(
            "label",
            {
              attrs: {
                for: "confirmEmail",
                "aria-hidden": _vm.isDeviceAndroid()
              }
            },
            [_vm._v(_vm._s(_vm.i18n.confirmEmail))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|max:80",
                expression: "'required|max:80'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.confirmEmail,
                expression: "user.confirmEmail"
              }
            ],
            staticClass: "form-control",
            class: {
              "input-error":
                _vm.errors.has("confirmEmail") ||
                (_vm.isEmailMismatch && _vm.user.confirmEmail.length)
            },
            attrs: {
              type: "text",
              name: "confirmEmail",
              maxlength: "80",
              "aria-label": _vm.i18n.confirmEmail,
              "aria-describedby": _vm.errors.has("confirmEmail")
                ? "confirm_email_error"
                : false
            },
            domProps: { value: _vm.user.confirmEmail },
            on: {
              focus: function($event) {
                _vm.createAccountServerError = ""
              },
              keyup: function($event) {
                _vm.user.uid = _vm.user.uid.toLowerCase()
              },
              paste: _vm.handlePasteAction,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "confirmEmail", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("confirmEmail")
            ? _c(
                "div",
                {
                  staticClass: "mt-xs-2",
                  attrs: { "aria-hidden": !_vm.errors.has("confirmEmail") }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: { id: "confirm_email_error" }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("confirmEmail")))]
                  )
                ]
              )
            : _c("div", { staticClass: "mt-xs-2" }, [
                _vm.isEmailMismatch && _vm.user.confirmEmail.length
                  ? _c("span", { staticClass: "error-msg" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.confirmEmailMismatchError) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-xs-3 mb-sm-5 form-group has-feedback" },
          [
            _c(
              "label",
              {
                attrs: { for: "password", "aria-hidden": _vm.isDeviceAndroid() }
              },
              [_vm._v(_vm._s(_vm.i18n.password))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.password,
                  expression: "user.password"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: { required: true, regex: _vm.globals.PASSWORD_REGEX },
                  expression:
                    "{ required: true, regex: globals.PASSWORD_REGEX }"
                }
              ],
              ref: "password",
              staticClass: "password form-control",
              class: { "input-error": _vm.errors.has("password") },
              attrs: {
                maxlength: "255",
                type: "password",
                name: "password",
                "aria-label": _vm.i18n.password,
                "aria-describedby": _vm.errors.has("password")
                  ? "password_error"
                  : false
              },
              domProps: { value: _vm.user.password },
              on: {
                focus: function($event) {
                  _vm.createAccountServerError = ""
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "password", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: " icon-eye toggle-password",
              class: { "icn-eye-collapsed": _vm.showPassword },
              attrs: {
                tabindex: "0",
                role: "button",
                "aria-label": _vm.showPassword
                  ? _vm.i18n.iconEyeHideTitle
                  : _vm.i18n.iconEyeShowTitle,
                title: _vm.showPassword
                  ? _vm.i18n.iconEyeHideTitle
                  : _vm.i18n.iconEyeShowTitle
              },
              on: {
                click: _vm.togglePassword,
                keyup: function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k(
                      $event.keyCode,
                      "enter-space",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  return _vm.togglePassword($event)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("password"),
                    expression: "errors.has('password')"
                  }
                ],
                staticClass: "mt-xs-2",
                attrs: { "aria-hidden": !_vm.errors.has("password") }
              },
              [
                _c(
                  "span",
                  { staticClass: "error-msg", attrs: { id: "password_error" } },
                  [_vm._v(_vm._s(_vm.errors.first("password")))]
                )
              ]
            ),
            _vm._v(" "),
            _c("vx-password-check-list", {
              attrs: {
                password: _vm.user.password,
                i18n: _vm.i18n.passwordCheck
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-xs-4 form-group dropdown" },
          [
            _c("label", [_vm._v(_vm._s(_vm.i18n.country))]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("vx-dropdown-primary", {
              ref: "countryDropdown",
              attrs: { dropdownValues: _vm.countryList },
              on: {
                "selected-option": function($event) {
                  _vm.user.country = $event
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.isGenderEnabled
          ? _c(
              "div",
              { staticClass: "mb-xs-4 form-group dropdown" },
              [
                _c("label", [_vm._v(_vm._s(_vm.i18n.gender))]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("vx-dropdown-primary", {
                  ref: "genderDropdown",
                  attrs: { dropdownValues: _vm.genderList },
                  on: {
                    "selected-option": function($event) {
                      _vm.user.gender = $event
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isDobEnabled
          ? _c("div", { staticClass: "mb-xs-4 form-group dropdown" }, [
              _c("label", [_vm._v(_vm._s(_vm.i18n.birthDay))]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "month-checkbox" },
                [
                  _c("vx-dropdown-primary", {
                    ref: "monthDropdown",
                    attrs: {
                      dropdownError: _vm.showMonthError,
                      dropdownValues: _vm.populateMonths,
                      name: "createAccountMonth",
                      "describedby-aria": _vm.showMonthError
                        ? "month_error_msg"
                        : null
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.handleChangeMonth($event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.showMonthError
                    ? _c("div", { staticClass: "mt-xs-2" }, [
                        _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            attrs: {
                              id: "month_error_msg",
                              "aria-live": "assertive"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.i18n.monthRequiredError) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "date-checkbox" },
                [
                  _c("vx-dropdown-primary", {
                    ref: "dateDropdown",
                    attrs: {
                      dropdownError: _vm.showDateError,
                      dropdownValues: _vm.date,
                      "describedby-aria": _vm.showDateError
                        ? "date_error_msg"
                        : null
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.handleChangeDate($event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.showDateError
                    ? _c("div", { staticClass: "mt-xs-2" }, [
                        _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            attrs: {
                              id: "date_error_msg",
                              "aria-live": "assertive"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.i18n.dateRequiredError) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.globals.siteConfig.editCommPrefEnabled
          ? _c("div", { staticClass: "my-xs-0 checkbox" }, [
              _vm.globals.isB2B()
                ? _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.b2bMailerCheck,
                          expression: "user.b2bMailerCheck"
                        }
                      ],
                      attrs: { id: "mailerText", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.user.b2bMailerCheck)
                          ? _vm._i(_vm.user.b2bMailerCheck, null) > -1
                          : _vm.user.b2bMailerCheck
                      },
                      on: {
                        focus: function($event) {
                          _vm.createAccountServerError = ""
                        },
                        change: function($event) {
                          var $$a = _vm.user.b2bMailerCheck,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.user,
                                  "b2bMailerCheck",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.user,
                                  "b2bMailerCheck",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.user, "b2bMailerCheck", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "mailerText" } }, [
                      _vm._v(_vm._s(_vm.i18n.mailerText))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.globals.isB2C()
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "market-communication mb-xs-2 form-group"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.addToMarketComm,
                              expression: "user.addToMarketComm"
                            }
                          ],
                          attrs: { id: "addToMarketComm", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.user.addToMarketComm)
                              ? _vm._i(_vm.user.addToMarketComm, null) > -1
                              : _vm.user.addToMarketComm
                          },
                          on: {
                            click: function($event) {
                              _vm.optinAnalyticsHandler("checkbox")
                            },
                            focus: function($event) {
                              _vm.createAccountServerError = ""
                            },
                            change: function($event) {
                              var $$a = _vm.user.addToMarketComm,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.user,
                                      "addToMarketComm",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.user,
                                      "addToMarketComm",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.user, "addToMarketComm", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "addToMarketComm" } }, [
                          _vm._v(_vm._s(_vm.i18n.addToMarketComm))
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.globals.isB2B()
                ? _c("p", { staticClass: "my-xs-0 terms-and-conditions" }, [
                    _vm._v(_vm._s(_vm.i18n.termsPrivacy) + "\n        "),
                    _c(
                      "a",
                      {
                        attrs: { tabindex: "0" },
                        on: {
                          click: function($event) {
                            _vm.openTermsModal($event)
                          },
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter-space",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            _vm.openTermsModal($event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.termsPrivacyLink))]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.globals.isB2C()
                ? _c("p", { staticClass: "my-xs-0 terms-and-conditions" }, [
                    _vm._v(_vm._s(_vm.i18n.termsPrivacyB2C) + "\n        "),
                    _c("a", [
                      _c(
                        "span",
                        {
                          attrs: { tabindex: "0", role: "link" },
                          on: {
                            click: function($event) {
                              _vm.openTermsModal($event)
                            },
                            keyup: function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter-space",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              _vm.openTermsModal($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.i18n.termsPrivacyLink) +
                              "\n          "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("a", [
                      _c(
                        "span",
                        {
                          attrs: { tabindex: "0", role: "link" },
                          on: {
                            click: function($event) {
                              _vm.openTermsModal($event)
                            },
                            keyup: function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter-space",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              _vm.openTermsModal($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.i18n.privacyPolicy) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.createAccountServerError
          ? _c("div", { staticClass: "mt-xs-4 mb-xs-2" }, [
              _c("span", {
                staticClass: "error-icon icon-alert-triangle",
                attrs: { title: _vm.i18n.iconAlertTriangleTitle }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-msg" }, [
                _vm._v(_vm._s(_vm.createAccountServerError))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "mt-md-3 mt-sm-3 mt-xs-4 submit btn btn-block btn-primary d-flex flex-column justify-content-center align-items-center",
            attrs: {
              type: "submit",
              disabled:
                _vm.errors.any() ||
                _vm.showDateError ||
                _vm.showMonthError ||
                _vm.isEmailMismatch
            },
            on: { click: _vm.submitForm }
          },
          [_vm._v("\n      " + _vm._s(_vm.i18n.submit) + "\n    ")]
        ),
        _vm._v(" "),
        _vm.globals.isB2C()
          ? _c("p", { staticClass: "login-exists mt-xs-2 mb-sm-0 mb-xs-5" }, [
              _vm._v("\n      " + _vm._s(_vm.i18n.haveAccount) + "\n      "),
              _c("a", [
                _c(
                  "span",
                  {
                    attrs: { tabindex: "0", role: "link" },
                    on: {
                      click: _vm.login,
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        return _vm.login($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.i18n.login) + "\n        "
                    )
                  ]
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          ref: "loginForm",
          attrs: { action: _vm.actionUrl, method: "post", hidden: "" }
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "j_username" },
            domProps: { value: _vm.user.uid }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "j_password" },
            domProps: { value: _vm.user.password }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "baseSiteId" },
            domProps: { value: _vm.globals.siteId }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "CSRFToken" },
            domProps: { value: _vm.globals.csrfToken }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2ebf8955", { render: render, staticRenderFns: staticRenderFns })
  }
}