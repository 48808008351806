import AccordionComp from '../../common/vx-accordion/vx-accordion.vue';

export default {
  name: 'vx-faq',
  components: {
    'accordion-comp': AccordionComp,
  },
  props: {
    accordionGroupData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      manipulatedArray: [],
    };
  },
  computed: {},
  async mounted() {
    this.manipulatedArray = this.manipulateData(this.accordionGroupData);
  },
  methods: {
    /**
     * converts the string in to an object by checking with the match method
     * @param {string} data
     * @return {object} object with faq data
     */
    manipulateData(data) {
      const out = data.match(/<que>(.*?)<\/ans>/g).map((faq, index) => ({
        accordionTitle: faq.match(/<que>(.*?)<\/que>/g)[0].replace(/<\/?que>/g, ''),
        accordionId: `${faq.match(/<que>(.*?)<\/que>/g)[0].replace(/<\/?que>/g, '')}i${index}`,
        accordionBody: faq.match(/<ans>(.*?)<\/ans>/g)[0].replace(/<\/?ans>/g, ''),
      }));

      let schemaData = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [],
      };

      // Is there already a tag for FAQPage?
      let faqScript = document.querySelector('[id=FAQPage]');
      const titles = []; // This keep track of the titles in the list so we don't duplicate any
      if (faqScript) {
        schemaData = JSON.parse(faqScript.innerHTML);
        schemaData.mainEntity.forEach((entity) => {
          if (!titles.includes(entity.name)) { titles.push(entity.name); }
        });
      } else {
        faqScript = document.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.setAttribute('id', 'FAQPage');
        document.getElementsByTagName('head')[0].appendChild(faqScript);
      }

      out.forEach((faqItem) => {
        if (faqItem.accordionTitle && !titles.includes(faqItem.accordionTitle)) {
          titles.push(faqItem.accordionTitle);
          schemaData.mainEntity.push({
            '@type': 'Question',
            name: faqItem.accordionTitle,
            acceptedAnswer: {
              '@type': 'Answer',
              text: faqItem.accordionBody,
            },
          });
        }
      });

      faqScript.innerHTML = JSON.stringify(schemaData);

      return out;
    },
  },
};
