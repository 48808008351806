var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-add-product-to-list common" }, [
    _c(
      "div",
      {
        staticClass: "modal-container",
        on: {
          click: function($event) {
            $event.stopPropagation()
          }
        }
      },
      [
        _c("div", { staticClass: "input-text" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.i18n.addProductsToListDescription) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-container form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.productNames,
                expression: "productNames"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text", "aria-label": _vm.i18n.firstName },
            domProps: { value: _vm.productNames },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.productNames = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-tertiary d-flex align-items-center",
              attrs: {
                type: "button",
                "aria-label": _vm.i18n.applyProductCodes
              },
              on: { click: _vm.handleAddProductsToList }
            },
            [_c("span", { staticClass: "icon-plus" })]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-example" }, [
          _vm._v("\n      " + _vm._s(_vm.i18n.productListExample) + "\n    ")
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "input-text" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.i18n.searchProductsForListDescription) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("vx-auto-suggest-to-list", {
          on: { addSelectedProducts: _vm.handleFoundProductsToList }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "icon-cross mt-xs-2 mr-xs-1",
          on: {
            click: function($event) {
              _vm.closeAddProductToList()
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2e1b8cdc", { render: render, staticRenderFns: staticRenderFns })
  }
}