var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-hero-banner", { attrs: { heroBannerData: _vm.heroBannerData } }),
      _vm._v(" "),
      _c("banner-carousel", { attrs: { carouselData: _vm.carouselData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ffa080e6", { render: render, staticRenderFns: staticRenderFns })
  }
}