/**
 * Breadcrumb component will be used to display breadcrumbs on the page
 */
import globals from '../../../../components/common/globals';

export default {
  name: 'vx-breadcrumb',
  components: {},
  props: {
    // breadcrumb details coming from parent component
    breadcrumbs: {
      type: Array,
    },
  },
  data() {
    return {
      globals,
    };
  },
  computed: {},
  mounted() {
    document.querySelector('.breadcrumb li:last-child').setAttribute('aria-current', 'page');
  },
  methods: {},
};
