var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-payment-billing row" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addCardModal",
          attrs: {
            size: "large",
            heading: _vm.i18n.addPaymentForm.addPaymentHeading,
            "is-modal-scrollable": true
          }
        },
        [
          _c("vx-add-payment-form", {
            ref: "paymentForm",
            attrs: {
              slot: "component",
              i18n: _vm.i18n.addPaymentForm,
              "shipping-address": _vm.shippingAddress,
              shoppingCartDetails: _vm.cartDetails,
              "is-checkout": "",
              showTopHeadings: true,
              "guest-email": _vm.guestEmail
            },
            on: {
              "card-added": function($event) {
                _vm.setNewCard($event)
              },
              "paypal-status": function($event) {
                _vm.getPaypalStatus($event)
              },
              addPaymentFailed: function($event) {
                _vm.dismissAddPaymentModal($event, true)
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.dataLoaded
        ? [
            _c(
              "div",
              { staticClass: "row heading-section-wrapper" },
              [
                _c(
                  "div",
                  {
                    class: {
                      "col-xs-12": _vm.addNewCard || _vm.isEditable,
                      "col-xs-8": !_vm.addNewCard && !_vm.isEditable,
                      "new-card": _vm.addNewCard
                    }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "section-header mb-xs-0 pb-xs-4",
                        attrs: { "aria-level": "2", role: "heading" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.sectionIndex) +
                            ". " +
                            _vm._s(_vm.i18n.paymentBilling.heading) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                !_vm.addNewCard
                  ? [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isEditable,
                              expression: "!isEditable"
                            }
                          ],
                          staticClass: "d-flex justify-content-end col-xs-4"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "change",
                              attrs: {
                                tabindex: "0",
                                disabled: _vm.disableButton,
                                role: _vm.button
                              },
                              on: {
                                click: function($event) {
                                  _vm.editPayment($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.i18n.paymentBilling.changeButton)
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "col-xs-12 section-sub-heading mb-xs-4",
                class: { "editable-sub-heading": _vm.isEditable }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.i18n.paymentBilling.subHeading) +
                    "\n    "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "payment-options col-xs-12 d-flex mb-xs-3 align-items-center"
              },
              [
                _c("vx-radio-button-group", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEditable,
                      expression: "isEditable"
                    }
                  ],
                  ref: "cardRadioButton",
                  attrs: {
                    radiobuttonValues: _vm.cardRadioButton,
                    "arrange-horizontal": _vm.arrangeHorizontal
                  },
                  on: { "selected-option": _vm.paymentOption },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.value === _vm.paymentType &&
                          item.value === _vm.allPaymentTypes.credit
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "form-elements visible-xs pt-xs-2 pt-md-3"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-icon-holder",
                                      class: {
                                        "card-icon-holder-margin": !_vm.reloadOnSuccess
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "card-icon",
                                        attrs: {
                                          src:
                                            _vm.globals.assetsPath +
                                            "images/creditcardicons/MasterCard.svg",
                                          alt:
                                            _vm.i18n.paymentBilling
                                              .cardTypeMaster,
                                          "aria-hidden": "true"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.i18n.paymentBilling
                                              .cardTypeMaster
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "card-icon",
                                        attrs: {
                                          src:
                                            _vm.globals.assetsPath +
                                            "images/creditcardicons/Visa.svg",
                                          alt:
                                            _vm.i18n.paymentBilling
                                              .cardTypeVisa,
                                          "aria-hidden": "true"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.i18n.paymentBilling.cardTypeVisa
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "card-icon",
                                        attrs: {
                                          src:
                                            _vm.globals.assetsPath +
                                            "images/creditcardicons/Discover.svg",
                                          alt:
                                            _vm.i18n.paymentBilling
                                              .cardTypeDiscover,
                                          "aria-hidden": "true"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.i18n.paymentBilling
                                              .cardTypeDiscover
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "card-icon",
                                        attrs: {
                                          src:
                                            _vm.globals.assetsPath +
                                            "images/creditcardicons/AmericanExpress.svg",
                                          alt:
                                            _vm.i18n.paymentBilling
                                              .cardTypeAmex,
                                          "aria-hidden": "true"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.i18n.paymentBilling.cardTypeAmex
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _vm.globals.siteConfig
                                        .isDinersCardAvailable
                                        ? _c("img", {
                                            staticClass: "card-icon",
                                            attrs: {
                                              src:
                                                _vm.globals.assetsPath +
                                                "images/creditcardicons/DinersClub.svg",
                                              alt:
                                                _vm.i18n.paymentBilling
                                                  .cardTypeDiners,
                                              "aria-hidden": "true"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.globals.siteConfig
                                        .isDinersCardAvailable
                                        ? _c(
                                            "span",
                                            { staticClass: "sr-only" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.i18n.cardTypeDiners)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                !_vm.checkoutData.isSubscription
                  ? [
                      _vm.isMobile()
                        ? [
                            _c("div", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.paymentType ===
                                    _vm.allPaymentTypes.paypal,
                                  expression:
                                    "paymentType === allPaymentTypes.paypal"
                                }
                              ],
                              staticClass: "mb-xs-4 mb-sm-0",
                              attrs: { id: "paypal-button-container" }
                            })
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.globals.siteConfig.isGooglePayEnabled &&
                      _vm.isGooglePayAvailable &&
                      _vm.isEditable
                        ? _c("div", { attrs: { id: "google-pay-container" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.globals.siteConfig.isApplePayEnabled &&
                      _vm.isApplePayAvailable &&
                      _vm.isEditable
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ml-sm-5 mt-sm-0 mt-xs-5 apple-pay-container"
                            },
                            [
                              _c("button", {
                                staticClass:
                                  "apple-pay-button apple-pay-button-white-with-line",
                                on: { click: _vm.initiateApplePay }
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.addNewCard && _vm.paymentType === _vm.allPaymentTypes.credit
              ? [
                  _c("vx-add-payment-form", {
                    ref: "paymentForm",
                    staticClass: "px-xs-3",
                    attrs: {
                      slot: "component",
                      showTopHeadings: false,
                      i18n: _vm.i18n.addPaymentForm,
                      "shipping-address": _vm.shippingAddress,
                      "is-checkout": "",
                      "is-first-card-images": _vm.isMobileFisrtCard,
                      "is-first-card": "",
                      "guest-email": _vm.guestEmail
                    },
                    on: {
                      "card-added": function($event) {
                        _vm.setNewCard($event)
                      },
                      "paypal-status": function($event) {
                        _vm.getPaypalStatus($event)
                      },
                      addPaymentFailed: function($event) {
                        _vm.dismissAddPaymentModal($event, false)
                      }
                    },
                    slot: "component"
                  })
                ]
              : _vm._e(),
            _vm._v(" "),
            !_vm.isMobile()
              ? [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.paymentType === _vm.allPaymentTypes.paypal,
                        expression: "paymentType === allPaymentTypes.paypal"
                      }
                    ],
                    staticClass: "ml-xs-3",
                    attrs: { id: "paypal-button-container" }
                  })
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.addNewCard &&
                      _vm.paymentType === _vm.allPaymentTypes.credit,
                    expression:
                      "!addNewCard && paymentType === allPaymentTypes.credit"
                  }
                ]
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isEditable,
                        expression: "isEditable"
                      }
                    ],
                    staticClass: "col-xs-12"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex mt-xs-4 mt-sm-5" },
                      [
                        _c("vx-dropdown-primary", {
                          ref: "cardDropdown",
                          staticClass: "col-xs-10 col-sm-8 px-xs-0",
                          attrs: { dropdownValues: _vm.savedCards },
                          on: {
                            "selected-item": function($event) {
                              _vm.handleSelected($event)
                            },
                            primaryDropdownMounted: function($event) {
                              _vm.handleDefault()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "pl-sm-3 add-new-payment col-xs-2 col-sm-4 pr-xs-0",
                            attrs: {
                              tabindex: "0",
                              role: "button",
                              "aria-label": "add payment"
                            },
                            on: {
                              click: function($event) {
                                _vm.addNewPayment($event)
                              },
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter-space",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                _vm.addNewPayment($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.i18n.paymentBilling.addCardButton)
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-xs-5" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "save-payment btn button-color text-trans",
                          attrs: { disabled: _vm.isButtonDisabled },
                          on: {
                            click: function($event) {
                              _vm.savePayment($event)
                            },
                            keyup: function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter-space",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              _vm.savePayment($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.i18n.paymentBilling.savePaymentButton
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isEditable,
                        expression: "!isEditable"
                      }
                    ],
                    staticClass: "col-xs-12 card-details"
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c("span", { staticClass: "card-img" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.cardImage,
                            alt: _vm.cardSelected.cardType
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "selected-card pl-xs-2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cardSelected.cardType) +
                            " " +
                            _vm._s(_vm.i18n.paymentBilling.savedCardDetails) +
                            " " +
                            _vm._s(_vm.cardSelected.cardNumber) +
                            "\n          "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "section-sub-heading mt-xs-3 mb-sm-2 mb-xs-3"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.i18n.paymentBilling.billingAddress) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "address-text mb-xs-2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cardSelected.accountHolderName) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "address-text",
                          attrs: { tabindex: "0", role: "text" }
                        },
                        [
                          _vm.userAddress && _vm.userAddress.companyName
                            ? _c("p", { staticClass: "address-text mb-xs-2" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.userAddress.companyName) +
                                    "\n            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", { staticClass: "address-text mb-xs-2" }, [
                            _vm._v(_vm._s(_vm.userAddress.line1))
                          ]),
                          _vm._v(" "),
                          _vm.userAddress && _vm.userAddress.line2
                            ? _c("p", { staticClass: "address-text mb-xs-2" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.userAddress.line2) +
                                    "\n            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.userAddress &&
                          _vm.userAddress.region &&
                          _vm.userAddress.region.isocodeShort
                            ? _c("p", { staticClass: "address-text mb-xs-2" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.userAddress.town) +
                                    " " +
                                    _vm._s(
                                      _vm.userAddress.region.isocodeShort
                                    ) +
                                    " " +
                                    _vm._s(_vm.userAddress.postalCode) +
                                    "\n            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.userAddress &&
                          _vm.userAddress.country &&
                          _vm.userAddress.country.isocode
                            ? _c("p", { staticClass: "address-text mb-xs-2" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.userAddress.country.isocode) +
                                    "\n            "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ])
                  ]
                )
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b0e6fe54", { render: render, staticRenderFns: staticRenderFns })
  }
}