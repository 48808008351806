var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12" },
      [
        _c("vx-order-details", {
          attrs: { i18n: _vm.messages.manageTransactionHistory }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-20e3896f", { render: render, staticRenderFns: staticRenderFns })
  }
}