var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-sample-cart-checkout" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "col-xs-12 checkout-button btn btn-primary checkout-btn",
          on: { click: _vm.checkoutButtonClicked }
        },
        [_vm._v(_vm._s(_vm.i18n.btnSampleCheckout))]
      ),
      _vm._v(" "),
      _vm.userReview
        ? _c("div", { staticClass: "error-msg mt-xs-2 col-xs-12 pl-xs-0" }, [
            _vm._v(_vm._s(_vm.i18n.underReview))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2b5c8393", { render: render, staticRenderFns: staticRenderFns })
  }
}