var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-tax-exemption" },
    [
      _c("p", { staticClass: "mb-xs-4" }, [_vm._v(_vm._s(_vm.i18n.helpText))]),
      _vm._v(" "),
      _c("h4", [_vm._v(_vm._s(_vm.i18n.title))]),
      _vm._v(" "),
      _c("vx-file-upload", {
        attrs: {
          isError: _vm.isFileSizeExceeded,
          i18n: _vm.i18n,
          disableAttach: _vm.disableAttach,
          fileType: _vm.fileType,
          "multiple-file-upload": true
        },
        on: {
          "file-upload": function($event) {
            _vm.fileUpload($event)
          },
          "attachments-updated": _vm.attachmentListUpdated
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-493d5508", { render: render, staticRenderFns: staticRenderFns })
  }
}