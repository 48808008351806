import {
  Validator,
} from 'vee-validate';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import globals from '../../../../components/common/globals';
import vxDropdownPrimary from '../../../../components/common/vx-dropdown-primary/vx-dropdown-primary.vue';
import ManageB2bOrgService from '../../../../components/common/services/manage-b2b-org-service';
import detectDeviceMixin from '../../../../components/common/mixins/detect-device-mixin';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import vxTable from '../../../../components/common/vx-table/vx-table.vue';
import vxTableUtilsMixin from '../../../../components/common/mixins/vxTable-mixin';

export default {
  name: 'vx-user-groups-landing',
  components: {
    vxDropdownPrimary,
    vxModal,
    ManageB2bOrgService,
    vxSpinner,
    vxTable,
  },
  props: {
    i18n: {
      type: Object,
      required: true,
    },
  },
  mixins: [mobileMixin, detectDeviceMixin, vxTableUtilsMixin],
  data() {
    return {
      globals,
      manageB2bOrgService: '',
      tableTitles: [],
      sortOptions: this.i18n.mobileSortOptions,
      userGroupDetails: {
        id: '',
        userGroupName: '',
        businessUnit: {
          label: '',
          value: '',
        },
        status: '',
      },
      businessUnitsList: [],
      userGroups: { userGroups: [] },
      userGroupDetailsUrl: '',
      isUserGroupExists: false,
      isUserGroupExistsError: '',
      tableConfig: {},
    };
  },
  computed: {},
  mounted() {
    this.$refs.spinner.showSpinner();
    this.manageB2bOrgService = new ManageB2bOrgService();
    this.userGroupDetailsUrl = `${this.globals.getB2BBaseURL()}${
      this.globals.navigations.userGroupLanding
    }?ugid=`;
    /**
     * Getting JSON for user groups data.
     */
    this.manageB2bOrgService.getUserGroups({}, this.handleUserGroupsData, this.handleUserGroupsError);
    this.manageB2bOrgService.getBusinessUnits({}, this.handleBusinessUnitsData, this.handleBusinessUnitsError, '');

    const veeCustomErrorMessage = {
      en: {
        custom: {
          id: {
            required: this.i18n.idRequiredError,
          },
          userGroupName: {
            required: this.i18n.userGroupNameRequiredError,
          },
          businessUnit: {
            required: this.i18n.businessUnitError,
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
    this.tableTitles = this.i18n.tableHeaders.split(',').map((title) => title.trim());
    this.initializeTableConfig();
  },

  methods: {
    /**
     * Handles the user groups data
     * @param {Object} response contains user groups
     */
    handleUserGroupsData(response) {
      this.$refs.spinner.hideSpinner();
      const { status, data } = response;

      if (status) {
        this.userGroups = data;
      }
    },
    handleUserGroupsError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * Handles the handleBusinessUnitsData
     * @param {Object} response  contains business units of user group
     */
    handleBusinessUnitsData(response) {
      const { status, data } = response;

      if (status) {
        this.businessUnitsList = data.units.map(this.createBusinessUnitsList);
      }
    },
    handleBusinessUnitsError() {

    },
    createBusinessUnitsList(item) {
      return {
        label: item.name,
        value: item.id,
      };
    },
    /**
     * Handles the handleAddUserGroup response
     * @param {Object} response contains the user groups
     */
    handleAddUserGroup(response) {
      const { status } = response;
      if (status) {
        window.location.href = `${this.userGroupDetailsUrl}${this.userGroupDetails.id}`;
      }
    },
    handleAddUserGroupError() {

    },
    /**
     * To open the Create User Group Modal.
     */
    openAddUserGroupModal(event) {
      this.$refs.createUserGroupModal.open(event);
    },
    /**
     * Validated the form data
     * Makes a request for creating user group
     */
    addUserGroup() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            unitUid: this.userGroupDetails.businessUnit.value,
            userGroupName: this.userGroupDetails.userGroupName,
            userGroupUid: this.userGroupDetails.id,
          };
          const requestConfig = {};
          requestConfig.data = payload;
          this.manageB2bOrgService.createUserGroup(
            requestConfig,
            this.handleAddUserGroup,
            this.handleUserGroupExists,
          );
        } else {
          this.globals.setFocusByName(this.$el, this.globals.getElementName(this.errors));
        }
      });
    },
    /**
     * Hnadles in user group already exists
     * @param {Object} error contains error message
     */
    handleUserGroupExists(error) {
      const { data } = error.response;
      if (data.errors[0].message) {
        this.isUserGroupExists = true;
        this.isUserGroupExistsError = data.errors[0].message;
      }
    },
    /**
     * Resets the userGroupDetails
     */
    resetValues() {
      this.userGroupDetails.businessUnit = {};
      this.userGroupDetails.userGroupName = '';
      this.userGroupDetails.id = '';
      this.isUserGroupExists = false;
    },
    initializeTableConfig() {
      this.tableConfig = {
        rowCount: 5,
        emptyTable: this.i18n.noDataAvailable,
        columnConfiguration: [
          {
            customHeaderName: this.tableTitles[0],
            customTemplate(data) {
              return `<a href="${globals.getB2BBaseURL()}${
                globals.navigations.userGroupLanding
              }?ugid=${data.uid}">${data.uid}</a>`;
            },
          },
          {
            customHeaderName: this.tableTitles[1],
            field: 'name',
          },
          {
            customHeaderName: this.tableTitles[2],
            field: 'unit.name',
          },
          {
            customHeaderName: this.tableTitles[3],
            customTemplate: (data) => (data.members ? this.i18n.active : this.i18n.disabled),
          },
        ],
      };
    },
  },
};
