var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-remove-installation" }, [
    _c("div", { staticClass: "remove-installation-heading" }, [
      _vm._v(_vm._s(_vm.i18n.removeInstallationMsg))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-xs-12 pt-xs-5 d-flex justify-content-center" },
      [
        _c(
          "button",
          {
            staticClass:
              "remove-btn btn btn-primary modal-btn-primary-small modal-btn-xs-sticky",
            attrs: { type: "button" },
            on: { click: _vm.removeInstallation }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.i18n.removeInstallationButton) + "\n    "
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-00957a26", { render: render, staticRenderFns: staticRenderFns })
  }
}