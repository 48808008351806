import RootService from './root-service';
import globals from '../globals';

class AcsService extends RootService {
  /**
   * createServiceTicket is used to make an API call to create a SR ticket.
   * @param {object} requestConfig
   * @param {Function} successCallback
   * @param {Function} errorCallback
   */
  createServiceTicket(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('createContactUsTicket');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * this method is triggered from contact us page form.
   * @param {object} requestConfig
   * @param {Function} successCallback
   * @param {Function} errorCallback
   * @param {String} ticketNumber
   */
  submitAttachments(requestConfig, successCallback, errorCallback, ticketNumber) {
    const config = requestConfig;
    config.headers = {
      contentType: 'multipart/form-data',
    };
    config.url = globals.getRestUrl('submitContactUsAttachments');
    config.params = {
      ticketNumber,
    };
    this.post(config, successCallback, errorCallback);
  }

  /**
   * this method is called in contact us page to get the topics.
   * @param {Object} requestConfig
   * @param {Function} successCallback
   * @param {Function} errorCallback
   */
  getTopicOfInquiry(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('getTopicOfInquiry');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * getDispenserTicketParams is triggered in dispenserTicket call.
   * @param {Object} requestConfig
   * @param {Function} successCallback
   * @param {Function} errorCallback
   */
  getDispenserTicketParams(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('getDispenserTicketParams');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * submitDispenserTicket is triggered while submitting the dispenser ticket.
   * @param {Object} requestConfig
   * @param {Function} successCallback
   * @param {Function} errorCallback
   */
  submitDispenserTicket(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('submitDispenserTicket');
    this.post(config, successCallback, errorCallback);
  }
}

// export default AcsService;
export {
  AcsService as
  default,
};
