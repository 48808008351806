var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "update-password" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.headingData
        ? _c("h3", { staticClass: "mb-xs-4 mt-xs-0 update-password-title" }, [
            _vm._v(_vm._s(_vm.headingData))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "update-password-form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.newPassword($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "mb-md-5 mb-sm-5 mb-xs-4 form-group has-feedback" },
            [
              _c(
                "label",
                {
                  attrs: {
                    for: "password",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.newPasswordLabel))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: true,
                      regex: _vm.globals.PASSWORD_REGEX
                    },
                    expression:
                      "{ required: true, regex: globals.PASSWORD_REGEX }"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.passwordUpdateText,
                    expression: "passwordUpdateText"
                  }
                ],
                ref: "password",
                staticClass: "mb-xs-2 form-control password",
                class: { "input-error": _vm.errors.has("password") },
                attrs: {
                  type: "password",
                  name: "Password",
                  "aria-label": _vm.i18n.newPasswordLabel
                },
                domProps: { value: _vm.passwordUpdateText },
                on: {
                  focus: function($event) {
                    _vm.updatePasswordServerError = ""
                  },
                  keydown: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.newPasswordOnKeyPress($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.passwordUpdateText = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "icon-eye toggle-password",
                attrs: {
                  tabindex: "0",
                  role: "button",
                  "aria-label": _vm.showPassword
                    ? _vm.i18n.iconEyeHideTitle
                    : _vm.i18n.iconEyeShowTitle
                },
                on: {
                  click: _vm.togglePassword,
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k(
                        $event.keyCode,
                        "enter-space",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    return _vm.togglePassword($event)
                  }
                }
              }),
              _vm._v(" "),
              _vm.isNewPassword
                ? _c("div", { staticClass: "mt-xs-2" }, [
                    _vm.isNewPassword
                      ? _c("span", { staticClass: "error-msg" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.i18n.newPasswordResetError) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("Password"),
                      expression: "errors.has('Password')"
                    }
                  ],
                  staticClass: "mt-xs-2",
                  attrs: { "aria-hidden": !_vm.errors.has("Password") }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: { id: "register_email_error" }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("Password")))]
                  )
                ]
              ),
              _vm._v(" "),
              _c("vx-password-check-list", {
                attrs: {
                  password: _vm.passwordUpdateText,
                  i18n: _vm.i18n.passwordCheck
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.updatePasswordServerError
            ? _c("div", { staticClass: "mt-xs-2" }, [
                _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.updatePasswordServerError))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "center-block btn button-color text-trans modal-btn-primary-medium",
              attrs: { type: "button", disabled: _vm.errors.any() },
              on: { click: _vm.newPassword }
            },
            [_vm._v(_vm._s(_vm.i18n.resetPasswordButton))]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          ref: "loginForm",
          attrs: { action: _vm.actionUrl, method: "post", hidden: "" }
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "j_username" },
            domProps: { value: _vm.userEmailId }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "j_password" },
            domProps: { value: _vm.passwordUpdateText }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "baseSiteId" },
            domProps: { value: _vm.globals.siteId }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "CSRFToken" },
            domProps: { value: _vm.globals.csrfToken }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-537ce245", { render: render, staticRenderFns: staticRenderFns })
  }
}