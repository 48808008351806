var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "password-checker", attrs: { tabindex: "0" } },
    [
      _c("ul", { staticClass: "password-instruction-list" }, [
        _c("li", { class: _vm.characterCheckClass }, [
          _vm._v(_vm._s(_vm.i18n.characterCountValidationMessage))
        ]),
        _vm._v(" "),
        _c("li", { class: _vm.caseCheckClass }, [
          _vm._v(_vm._s(_vm.i18n.upperCaseValidationMessage))
        ]),
        _vm._v(" "),
        _c("li", { class: _vm.numberCheckClass }, [
          _vm._v(_vm._s(_vm.i18n.numberValidationMessage))
        ]),
        _vm._v(" "),
        _c("li", { class: _vm.specialCharacterChaeckClass }, [
          _vm._v(
            _vm._s(_vm.i18n.specialCharacterValidationMessage + ": ") +
              "\n      "
          ),
          _c("span", [_vm._v(_vm._s(_vm.i18n.specialCharacterList))])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c769d076", { render: render, staticRenderFns: staticRenderFns })
  }
}