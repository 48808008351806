import { swiper, swiperSlide } from 'vue-awesome-swiper';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import globals from '../../../../components/common/globals';
import vxVideoPlayer from '../../../../components/common/vx-video-player/vx-video-player.vue';

export default {
  name: 'vx-video-library',
  components: {
    swiper,
    swiperSlide,
    vxVideoPlayer,
  },
  props: {
    videoLibraryData: {
      type: Object,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      swiperOptionTop: {
        loop: true,
        loopedSlides: this.videoLibraryData.videoComponents.length, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: this.videoLibraryData.videoComponents.length, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      swiper: '',
      firstSlide: false,
      lastSlide: false,
      swiperWrapperClass: '.thumb-container .swiper-container',
      globals,
      headerTextColor: '#005293',
      bodyTextColor: '#6f7173',
      textAlignment: 'justify',
      textBackgroundColor: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
    const self = this;
    self.swiper = document.querySelector(this.swiperWrapperClass).swiper;
    self.swiper.on('reachEnd', () => {
      self.firstSlide = false;
      self.lastSlide = true;
    });
    self.swiper.on('reachBeginning', () => {
      self.firstSlide = true;
      self.lastSlide = false;
    });

    if (this.videoLibraryData.videoComponents.length > 1) {
      self.firstSlide = true;
    }
  },
  methods: {
    onLazyLoad() {
      this.videoLibraryData.videoComponents.forEach((slideData, i) => {
        const imageSrc = this.getResponsiveImage(
          slideData.imageData.imageSrcD,
          slideData.imageData.imageSrcD,
          slideData.imageData.imageSrcM,
        );
        document.querySelectorAll(`.slide${i}`).forEach((el) => {
          const element = el;
          element.style.background = `url("${imageSrc}") no-repeat center`;
          element.style.backgroundSize = 'cover';
        });

        document.querySelectorAll(`.thumbslide${i}`).forEach((el) => {
          const element = el;
          element.style.background = `url("${imageSrc}") no-repeat center`;
          element.style.backgroundSize = 'cover';
        });
        if (Object.prototype.hasOwnProperty.call(slideData, 'headerTextColor') && slideData.headerTextColor.length) {
          this.$refs[`headerText${i}`][0].style.color = slideData.headerTextColor;
          this.$refs[`thumbText${i}`][0].style.color = slideData.headerTextColor;
        } else {
          this.$refs[`headerText${i}`][0].style.color = '';
          this.$refs[`thumbText${i}`][0].style.color = '';
        }
      });

      if (Object.prototype.hasOwnProperty.call(this.videoLibraryData, 'headerTextColor') && this.videoLibraryData.headerTextColor.length) {
        this.headerTextColor = this.videoLibraryData.headerTextColor;
      }

      if (Object.prototype.hasOwnProperty.call(this.videoLibraryData, 'bodyTextColor') && this.videoLibraryData.bodyTextColor.length) {
        this.bodyTextColor = this.videoLibraryData.bodyTextColor;
      }
      if (Object.prototype.hasOwnProperty.call(this.videoLibraryData, 'textAlignment') && this.videoLibraryData.textAlignment.length) {
        this.textAlignment = this.videoLibraryData.textAlignment;
      }

      if (Object.prototype.hasOwnProperty.call(this.videoLibraryData, 'textBackgroundColor') && this.videoLibraryData.textBackgroundColor.length) {
        this.textBackgroundColor = this.videoLibraryData.textBackgroundColor;
      }
    },
    slidePrev() {
      this.swiper.slidePrev();
      this.lastSlide = false;
    },
    /**
         * Function sets the slides to next product
         */
    slideNext() {
      this.swiper.slideNext();
      this.firstSlide = false;
    },
    playVideo(videoUrl) {
      this.$refs.videoPlayer.openPlayer(videoUrl);
    },
    generateIconUrl(direction) {
      if (this.videoLibraryData.videoComponents.length <= 1 || (direction === 'prev' && this.firstSlide) || (direction === 'next' && this.lastSlide)) {
        return `${this.globals.assetsPath}images/gp-pro/grey_arrow.svg`;
      }
      return `${this.globals.assetsPath}images/gp-pro/blue_arrow.svg`;
    },
  },
};
