var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-schedule-installation px-xs-3 row" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "section-header col-xs-12 px-xs-0" }, [
        _c(
          "div",
          { staticClass: "heading d-flex justify-content-between mt-xs-0" },
          [
            _c(
              "div",
              {
                staticClass: "section-header",
                attrs: { tabindex: "0", role: "heading" }
              },
              [
                _vm._v(
                  _vm._s(_vm.sectionIndex) +
                    ". " +
                    _vm._s(_vm.i18n.scheduleInstallation.heading)
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isSaved,
                    expression: "isSaved"
                  }
                ],
                staticClass: " d-flex justify-content-end"
              },
              [
                _c("a", [
                  _c(
                    "span",
                    {
                      staticClass: "lease-change",
                      attrs: {
                        tabindex: "0",
                        role: "button",
                        "aria-label": _vm.i18n.scheduleInstallation.change
                      },
                      on: {
                        click: function($event) {
                          _vm.editMethod($event)
                        },
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter-space",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          _vm.editMethod($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.scheduleInstallation.change))]
                  )
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "sub-heading mt-xs-3" }, [
          _vm._v(_vm._s(_vm.i18n.scheduleInstallation.subHeading))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "section-address mt-xs-4 col-xs-12 px-xs-0" }, [
        _c("div", { staticClass: "row" }, [
          !_vm.isMultipleAddress
            ? _c("div", { staticClass: "col-xs-6" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.i18n.scheduleInstallation.addressHeading))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.formattedAddress.firstname) +
                      " " +
                      _vm._s(_vm.formattedAddress.lastname)
                  )
                ]),
                _vm._v(" "),
                _vm.formattedAddress && _vm.formattedAddress.companyName
                  ? _c("p", [_vm._v(_vm._s(_vm.formattedAddress.companyName))])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.formattedAddress.line1))]),
                _vm._v(" "),
                _vm.formattedAddress.line2
                  ? _c("p", [_vm._v(_vm._s(_vm.formattedAddress.line2))])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", [
                  _vm.formattedAddress && _vm.formattedAddress.town
                    ? _c("span", [_vm._v(_vm._s(_vm.formattedAddress.town))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formattedAddress &&
                  _vm.formattedAddress.region &&
                  _vm.formattedAddress.region.isocodeShort
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.formattedAddress.region.isocodeShort))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formattedAddress && _vm.formattedAddress.postalCode
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.formattedAddress.postalCode))
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.formattedAddress &&
                _vm.formattedAddress.country &&
                _vm.formattedAddress.country.isocode
                  ? _c("p", [
                      _vm._v(_vm._s(_vm.formattedAddress.country.isocode))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.formattedAddress.isPallet
                  ? _c("p", { staticClass: "pallet-text" }, [
                      _vm._v(_vm._s(_vm.i18n.scheduleInstallation.pallet))
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isMultipleAddress
            ? _c("div", { staticClass: "col-xs-6" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.i18n.scheduleInstallation.addressHeading))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.i18n.scheduleInstallation.multipleAddresses)
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-6" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xs-12" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.i18n.scheduleInstallation.items))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-xs-0" }, [
                  _vm._v(_vm._s(_vm.scheduledDetails.item))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-xs-12 mt-xs-4" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(_vm.i18n.scheduleInstallation.installationDetails)
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm.scheduledTime
                    ? _c("span", [
                        _c("span", { staticClass: "preferred-time" }, [
                          _vm._v(_vm._s(_vm.scheduledTime))
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.i18n.scheduleInstallation.of))
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.selectedInstallDate) +
                      "\n            "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isEditable && _vm.showInstallationSection,
              expression: "isEditable && showInstallationSection"
            }
          ],
          staticClass: "section-installation mt-xs-4 col-xs-12 px-xs-0"
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-5 col-xs-12" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 pb-xs-5" },
                  [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.i18n.scheduleInstallation.installDetails)
                      )
                    ]),
                    _vm._v(" "),
                    _c("date-picker", {
                      staticClass: "input-calendar",
                      attrs: {
                        name: "installationDate",
                        lang: "en",
                        format: "dddd MMMM Do, YYYY",
                        clearable: false,
                        "not-before": _vm.disabledDates.to,
                        "not-after": _vm.disabledDates.from
                      },
                      model: {
                        value: _vm.selectedDate,
                        callback: function($$v) {
                          _vm.selectedDate = $$v
                        },
                        expression: "selectedDate"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-12 col-sm-11" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.i18n.scheduleInstallation.extraInstallDetails)
                    )
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.scheduledDetails.extraInfo,
                        expression: "scheduledDetails.extraInfo"
                      }
                    ],
                    staticClass: "input-instructions",
                    attrs: { rows: "8" },
                    domProps: { value: _vm.scheduledDetails.extraInfo },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.scheduledDetails,
                          "extraInfo",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6 col-sm-offset-1 col-xs-12" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12" },
                  [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.i18n.scheduleInstallation.preferredTime)
                      )
                    ]),
                    _vm._v(" "),
                    _c("vx-radio-button-group", {
                      ref: "scheduleTime",
                      attrs: {
                        name: "installTime",
                        radiobuttonValues: _vm.radioOptions,
                        "arrange-horizontal": ""
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-12" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.i18n.scheduleInstallation.contactPerson))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "my-xs-4 form-group" }, [
                    _c(
                      "label",
                      {
                        attrs: {
                          for: "us",
                          "aria-hidden": _vm.isDeviceAndroid()
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.scheduleInstallation.nameLabel))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.scheduledDetails.name,
                          expression: "scheduledDetails.name"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|alpha_spaces|max:80",
                          expression: "'required|alpha_spaces|max:80'"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        maxlength: "80",
                        name: "customerName",
                        "aria-label": _vm.i18n.scheduleInstallation.nameLabel,
                        "aria-describedby": "custName_err_msg"
                      },
                      domProps: { value: _vm.scheduledDetails.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.scheduledDetails,
                            "name",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("customerName")
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: { id: "custName_err_msg" }
                            },
                            [_vm._v(_vm._s(_vm.errors.first("customerName")))]
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-xs-4 mb-xs-0 form-group" },
                    [
                      _c("label", { attrs: { for: "us" } }, [
                        _vm._v(_vm._s(_vm.i18n.scheduleInstallation.phoneLabel))
                      ]),
                      _vm._v(" "),
                      _c("the-mask", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required: true|min:15",
                            expression: "'required: true|min:15'"
                          }
                        ],
                        staticClass: "phone-number form-control",
                        attrs: {
                          masked: _vm.masked,
                          mask: "+1 ###-###-####",
                          type: "tel",
                          name: "phoneNumber",
                          "aria-label": _vm.i18n.mobileNo,
                          "aria-describedby": "phoneNumber_err_msg"
                        },
                        model: {
                          value: _vm.scheduledDetails.phoneNo,
                          callback: function($$v) {
                            _vm.$set(_vm.scheduledDetails, "phoneNo", $$v)
                          },
                          expression: "scheduledDetails.phoneNo"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("phoneNumber")
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c(
                              "span",
                              {
                                staticClass: "error-msg",
                                attrs: { id: "phoneNumber_err_msg" }
                              },
                              [_vm._v(_vm._s(_vm.errors.first("phoneNumber")))]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-5 col-xs-12 mt-xs-4 px-xs-0" }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isEditable && _vm.showScheduleButton,
                expression: "isEditable && showScheduleButton"
              }
            ],
            staticClass: "btn-block btn btn-primary",
            on: {
              click: function($event) {
                _vm.showInstallSection($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.installBtnLabel))]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6e689956", { render: render, staticRenderFns: staticRenderFns })
  }
}