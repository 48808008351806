import { eventBus } from '../../../../modules/event-bus';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import viewSiteContent from '../../../../locale/view-site-content-i18n';
import globals from '../../../../components/common/globals';

export default {
  name: 'vx-image-tile',
  components: {},
  props: {
    imageTileData: {
      type: Object,
      required: true,
    },
    textTileData: {
      type: Object,
      required: true,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      i18n: viewSiteContent.imageTile,
      backgroundImg: '',
      playIconSrc: `${globals.assetsPath}images/gp-pro/play-video-icon.png`,
    };
  },
  computed: {
    backgroundImage() {
      return { 'background-image': `url(${this.backgroundImg})` };
    },
    backgroundAlt() {
      return this.getResponsiveAlt(this.imageTileData.imageSrcAltTextD, this.imageTileData.imageSrcAltTextT, this.imageTileData.imageSrcAltTextM);
    },
  },
  mounted() {
    this.backgroundImg = this.getResponsiveImage(this.imageTileData.imageSrcD, this.imageTileData.imageSrcT, this.imageTileData.imageSrcM);
    document.addEventListener('DOMContentLoaded', () => {
      const playButton = document.querySelector('.play-video-icon');
      playButton.addEventListener('mouseenter', () => {
        playButton.setAttribute('src', `${globals.assetsPath}images/gp-pro/play-video-icon-dark.png`);
      });
      playButton.addEventListener('mouseleave', () => {
        playButton.setAttribute('src', `${globals.assetsPath}images/gp-pro/play-video-icon.png`);
      });
    });
  },
  methods: {
    /**
         * Emitting event to open the video player by sending the source url.
         * @param {String} src
         */
    openVideo(src) {
      eventBus.$emit('open-player', src);
    },
    goToUrl(url) {
      if (url) window.location.href = url;
    },
    onMouseOver() {
      this.playIconSrc = `${globals.assetsPath}images/gp-pro/play-video-icon-dark.png`;
    },
    onMouseLeave() {
      this.playIconSrc = `${globals.assetsPath}images/gp-pro/play-video-icon.png`;
    },
  },
};
