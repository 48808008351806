var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-gift-options row" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.dataLoaded
        ? [
            _c("div", { staticClass: "col-xs-8" }, [
              _c(
                "p",
                {
                  staticClass: "section-header mb-xs-0 pb-xs-4",
                  attrs: { role: "heading" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.sectionIndex) + ". " + _vm._s(_vm.i18n.heading)
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isEditable,
                    expression: "!isEditable"
                  }
                ],
                staticClass: "d-flex justify-content-end col-xs-4",
                attrs: { "aria-hidden": _vm.isEditable }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "gifting-change",
                    attrs: {
                      tabindex: "0",
                      role: "button",
                      "aria-label": _vm.i18n.changeButton
                    },
                    on: {
                      click: function($event) {
                        _vm.editMethod($event)
                      },
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.editMethod($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.changeButton))]
                )
              ]
            ),
            _vm._v(" "),
            _vm.isEditable
              ? _c(
                  "div",
                  { staticClass: "col-xs-12" },
                  [
                    _vm._l(_vm.giftableProducts, function(productEntry, index) {
                      return _c(
                        "vx-product-tile",
                        { attrs: { slot: "component" }, slot: "component" },
                        [
                          productEntry.product &&
                          productEntry.product.images &&
                          productEntry.product.images.length !== 0 &&
                          productEntry.product.images[2] &&
                          productEntry.product.images[2].url
                            ? _c("template", { slot: "image-slot" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        _vm.globals.getNavigationUrl("empty") +
                                        productEntry.product.url
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.globals.getThumbnailImageUrl(
                                          productEntry.product.images
                                        ),
                                        alt:
                                          productEntry.product.images[2].altText
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("template", { slot: "details" }, [
                            _c("p", { staticClass: "product-title mb-xs-3" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.globals.getNavigationUrl("empty") +
                                      productEntry.product.url
                                  }
                                },
                                [_vm._v(_vm._s(productEntry.product.name))]
                              )
                            ]),
                            _vm._v(" "),
                            productEntry.splitEntries[0]
                              ? _c(
                                  "p",
                                  { staticClass: "shipping-heading mb-xs-2" },
                                  [_vm._v(_vm._s(_vm.i18n.shipping))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            productEntry.splitEntries[0]
                              ? _c(
                                  "p",
                                  { staticClass: "shipping-address mb-xs-3" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getAddress(
                                          productEntry.splitEntries[0]
                                            .deliveryAddress
                                        )
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "gift" }, [
                              _c("span", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.giftWrapDetails[index]
                                          .giftWrapSelected,
                                      expression:
                                        "giftWrapDetails[index].giftWrapSelected"
                                    }
                                  ],
                                  attrs: {
                                    id: productEntry.product.code,
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.giftWrapDetails[index]
                                        .giftWrapSelected
                                    )
                                      ? _vm._i(
                                          _vm.giftWrapDetails[index]
                                            .giftWrapSelected,
                                          null
                                        ) > -1
                                      : _vm.giftWrapDetails[index]
                                          .giftWrapSelected
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a =
                                          _vm.giftWrapDetails[index]
                                            .giftWrapSelected,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.giftWrapDetails[index],
                                              "giftWrapSelected",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.giftWrapDetails[index],
                                              "giftWrapSelected",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.giftWrapDetails[index],
                                          "giftWrapSelected",
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { attrs: { for: productEntry.product.code } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.i18n.giftWrapCheckbox) +
                                        " -\n                " +
                                        _vm._s(
                                          _vm.giftWrapDetails[index]
                                            .giftWrapCost
                                        )
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "gifting-message mt-xs-3" },
                                [_vm._v(_vm._s(_vm.i18n.giftMessage))]
                              ),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.giftWrapDetails[index]
                                        .giftWrapMessage,
                                    expression:
                                      "giftWrapDetails[index].giftWrapMessage"
                                  }
                                ],
                                attrs: { maxlength: "1024" },
                                domProps: {
                                  value:
                                    _vm.giftWrapDetails[index].giftWrapMessage
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.giftWrapDetails[index],
                                      "giftWrapMessage",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "info-text mb-xs-0 mt-xs-2" },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        1024 -
                                          _vm.giftWrapDetails[index]
                                            .giftWrapMessage.length
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.i18n.giftMessageCharacterCount)
                                    )
                                  ])
                                ]
                              )
                            ])
                          ])
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-xs-4 pt-sm-5 mt-sm-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "save-gift btn btn-primary",
                          on: {
                            click: function($event) {
                              _vm.saveGiftWrap($event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.saveButton))]
                      )
                    ])
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isEditable
              ? _c(
                  "div",
                  { staticClass: "col-xs-12" },
                  _vm._l(_vm.giftableProducts, function(productEntry, index) {
                    return _c(
                      "vx-product-tile",
                      { attrs: { slot: "component" }, slot: "component" },
                      [
                        productEntry.product &&
                        productEntry.product.images &&
                        productEntry.product.images.length !== 0 &&
                        productEntry.product.images[2] &&
                        productEntry.product.images[2].url
                          ? _c("template", { slot: "image-slot" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.globals.getNavigationUrl("empty") +
                                      productEntry.product.url
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.globals.getThumbnailImageUrl(
                                        productEntry.product.images
                                      ),
                                      alt:
                                        productEntry.product.images[2].altText
                                    }
                                  })
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("template", { slot: "details" }, [
                          _c("p", { staticClass: "product-title mb-xs-3" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.globals.getNavigationUrl("empty") +
                                    productEntry.product.url
                                }
                              },
                              [_vm._v(_vm._s(productEntry.product.name))]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm.giftWrapDetails[index].giftWrapMessage
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "gift-message-heading mb-xs-2"
                                  },
                                  [_vm._v(_vm._s(_vm.i18n.giftingMessage))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.giftWrapDetails[index].giftWrapMessage
                              ? _c(
                                  "p",
                                  { staticClass: "gift-message mb-xs-3" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.giftWrapDetails[index]
                                          .giftWrapMessage
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.giftWrapDetails[index].giftWrapSelected
                              ? _c("p", { staticClass: "gift-message" }, [
                                  _vm._v(
                                    _vm._s(_vm.i18n.giftWrapAdded) +
                                      " -\n              "
                                  ),
                                  _c("span", { staticClass: "gift-price" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.giftWrapDetails[index].giftWrapCost
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ])
                        ])
                      ],
                      2
                    )
                  })
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-76540790", { render: render, staticRenderFns: staticRenderFns })
  }
}