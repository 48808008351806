var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-uiux-half-banner",
      class: _vm.imgTitleData.componentTheme
    },
    [
      _vm.imgTitleData.tiles[0]
        ? _c(
            "div",
            {
              staticClass: "tile",
              class: { "has-link": _vm.imgTitleData.tiles[0].imageLink },
              attrs: { role: "presentation" },
              on: {
                click: function($event) {
                  _vm.goToLink(_vm.imgTitleData.tiles[0].imageLink)
                }
              }
            },
            [
              _c("img", { attrs: { src: _vm.imgTitleData.tiles[0].imgSrcD } }),
              _vm._v(" "),
              _c("div", { ref: "tileOneImage", staticClass: "image" }),
              _vm._v(" "),
              _c("div", { staticClass: "bottom" }, [
                _vm.imgTitleData.tiles[0].header
                  ? _c("div", {
                      ref: "tileOneHeader",
                      staticClass: "header",
                      domProps: {
                        innerHTML: _vm._s(_vm.imgTitleData.tiles[0].header)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.imgTitleData.tiles[0].tileText
                  ? _c("div", {
                      ref: "tileOneDescription",
                      staticClass: "description",
                      domProps: {
                        innerHTML: _vm._s(_vm.imgTitleData.tiles[0].tileText)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.imgTitleData.tiles[0].ctaLink &&
                _vm.imgTitleData.tiles[0].ctaText
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.imgTitleData.tiles[0].ctaLink,
                          "aria-label":
                            _vm.imgTitleData.tiles[0].header +
                            " " +
                            _vm.imgTitleData.tiles[0].ctaText
                        }
                      },
                      [
                        _c(
                          "button",
                          {
                            ref: "tileOneButton",
                            staticClass: "form-blue-button",
                            class: _vm.imgTitleData.tiles[0].ctaStyle
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.imgTitleData.tiles[0].ctaText) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.imgTitleData.tiles[0].ctaText) +
                              "\n                "
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.imgTitleData.tiles[1]
        ? _c(
            "div",
            {
              staticClass: "tile",
              class: { "has-link": _vm.imgTitleData.tiles[1].imageLink },
              attrs: { role: "presentation" },
              on: {
                click: function($event) {
                  _vm.goToLink(_vm.imgTitleData.tiles[1].imageLink)
                }
              }
            },
            [
              _c("img", { attrs: { src: _vm.imgTitleData.tiles[1].imgSrcD } }),
              _vm._v(" "),
              _c("div", { ref: "tileTwoImage", staticClass: "image" }),
              _vm._v(" "),
              _c("div", { staticClass: "bottom" }, [
                _vm.imgTitleData.tiles[1].header
                  ? _c("div", {
                      ref: "tileTwoHeader",
                      staticClass: "header",
                      domProps: {
                        innerHTML: _vm._s(_vm.imgTitleData.tiles[1].header)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.imgTitleData.tiles[1].tileText
                  ? _c("div", {
                      ref: "tileTwoDescription",
                      staticClass: "description",
                      domProps: {
                        innerHTML: _vm._s(_vm.imgTitleData.tiles[1].tileText)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.imgTitleData.tiles[1].ctaLink &&
                _vm.imgTitleData.tiles[1].ctaText
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.imgTitleData.tiles[1].ctaLink,
                          "aria-label":
                            _vm.imgTitleData.tiles[1].header +
                            " " +
                            _vm.imgTitleData.tiles[1].ctaText
                        }
                      },
                      [
                        _c(
                          "button",
                          {
                            ref: "tileTwoButton",
                            staticClass: "form-blue-button",
                            class: _vm.imgTitleData.tiles[1].ctaStyle
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.imgTitleData.tiles[1].ctaText) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.imgTitleData.tiles[1].ctaText) +
                              "\n                "
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7aa673a8", { render: render, staticRenderFns: staticRenderFns })
  }
}