/**
 * vx-banner-carousel is the container for hero banner.
 *
 */
import vxHeroBanner from '../vx-hero-banner/vx-hero-banner.vue';
import vxHeroBannerResponsive from '../vx-hero-banner-responsive/vx-hero-banner-responsive.vue';
import { eventBus } from '../../../modules/event-bus';
import viewSiteContent from '../../../locale/view-site-content-i18n';

export default {
  name: 'vx-banner-carousel',
  components: {
    vxHeroBanner,
    vxHeroBannerResponsive,
  },
  props: {
    carouselData: Object,
  },
  data() {
    return {
      carouselId: this.carouselData.componentId, // to generate the random string which serves as unique id
      i18n: viewSiteContent.bannerCarousel,
    };
  },
  computed: {},
  async mounted() {
    if (this.carouselData.gpRotatingData.length > 1) {
      jQuery(`#${this.carouselId}`).children('.left').hide(); // hiding initially the left indicator
      jQuery(`#${this.carouselId}`).on('slid.bs.carousel', () => this.checkitem()); // on sliding setting triggering the event to hide the first indicator at first active slide and last indicator at last active slide
      jQuery(`#${this.carouselId}`).carousel({
        interval: this.carouselData.autoStart ? this.carouselData.autoStart : false,
      });
      eventBus.$on('carouselBinded', () => {
        setTimeout(() => {
          jQuery(`#${this.carouselId}`).children('.left').hide();
        }, 100);
      });
    }
  },
  methods: {
    /**
     * used to check if the slide is first or last,
     *  based on which we will disable the navigation arrows of left and right
     */
    checkitem() {
      if (this.carouselData.gpRotatingData.length > 1) {
        this.$emit('slide-changed', {});
        const carouselEL = jQuery(`#${this.carouselId}`);
        if (
          jQuery(`#${this.carouselId} .carousel-inner .item:first`).hasClass('active')
        ) {
          carouselEL.children('.left').hide();
          carouselEL.children('.right').show();
          if (this.$el.querySelector('.item.active .selected-product-img')) {
            this.$el.querySelector('.item.active .selected-product-img').focus();
          }
        } else if (
          jQuery(`#${this.carouselId} .carousel-inner .item:last`).hasClass('active')
        ) {
          carouselEL.children('.right').hide();
          carouselEL.children('.left').show();
          if (this.$el.querySelector('.item.active .selected-product-img')) {
            this.$el.querySelector('.item.active .selected-product-img').focus();
          }
        } else {
          carouselEL.children('.carousel-control').show();
        }
      }
    },
  },
};
