var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-banner-carousel row" }, [
    _c(
      "div",
      {
        staticClass: "carousel slide",
        attrs: {
          id: _vm.carouselId,
          "data-wrap": _vm.carouselData.loopCarousel,
          "data-ride": "carousel"
        }
      },
      [
        _c(
          "div",
          { staticClass: "carousel-inner", attrs: { role: "listbox" } },
          _vm._l(_vm.carouselData.gpRotatingData, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "item",
                class: { active: index === 0 }
              },
              [
                typeof _vm.$parent.isMobile === "function" &&
                _vm.$parent.isMobile()
                  ? _vm._t(
                      "default",
                      [
                        !_vm.carouselData.isResponsiveImage
                          ? _c("vx-hero-banner", {
                              attrs: { "hero-banner-data": item }
                            })
                          : _c("vx-hero-banner-responsive", {
                              attrs: { "hero-banner-data": item }
                            })
                      ],
                      { row: item }
                    )
                  : _c(
                      "span",
                      [
                        !_vm.carouselData.isResponsiveImage
                          ? _c("vx-hero-banner", {
                              attrs: { "hero-banner-data": item }
                            })
                          : _c("vx-hero-banner-responsive", {
                              attrs: { "hero-banner-data": item }
                            })
                      ],
                      1
                    )
              ],
              2
            )
          })
        ),
        _vm._v(" "),
        _c(
          "ol",
          { staticClass: "carousel-indicators" },
          [
            _vm.carouselData.gpRotatingData &&
            _vm.carouselData.gpRotatingData.length > 1
              ? _vm._l(_vm.carouselData.gpRotatingData, function(item, index) {
                  return _c("li", {
                    key: index,
                    class: { active: index === 0 },
                    attrs: {
                      "data-target": "#" + _vm.carouselId,
                      "data-slide-to": index
                    }
                  })
                })
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _vm.carouselData.gpRotatingData &&
        _vm.carouselData.gpRotatingData.length > 1
          ? _c(
              "a",
              {
                staticClass: "left carousel-control",
                attrs: {
                  href: "#" + _vm.carouselId,
                  role: "button",
                  "data-slide": "prev",
                  "aria-label": _vm.i18n.iconChevronLeftTitle
                }
              },
              [
                _c("span", {
                  staticClass:
                    "glyphicon glyphicon-chevron-left icon-chevron-left",
                  attrs: { "aria-hidden": "true" }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "sr-only glyphicon-text-color" }, [
                  _vm._v(_vm._s(_vm.i18n.carouselControlPrevious))
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.carouselData.gpRotatingData &&
        _vm.carouselData.gpRotatingData.length > 1
          ? _c(
              "a",
              {
                staticClass: "right carousel-control",
                attrs: {
                  href: "#" + _vm.carouselId,
                  role: "button",
                  "data-slide": "next",
                  "aria-label": _vm.i18n.iconChevronRightTitle
                }
              },
              [
                _c("span", {
                  staticClass:
                    "glyphicon glyphicon-chevron-right icon-chevron-right",
                  attrs: { "aria-hidden": "true" }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "sr-only xxglyphicon-text-color" }, [
                  _vm._v(_vm._s(_vm.i18n.carouselControlNext))
                ])
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0447be5b", { render: render, staticRenderFns: staticRenderFns })
  }
}