var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-edit-personal-details" },
    [
      _c("vx-spinner", {
        ref: "personalDetailsSpinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.callConfirmPasswordModal($event)
            }
          }
        },
        [
          _c("div", { staticClass: "mb-xs-4 form-group" }, [
            _c(
              "label",
              {
                staticClass: "attribute-name",
                attrs: {
                  for: "firstName",
                  "aria-hidden": _vm.isDeviceAndroid()
                }
              },
              [_vm._v(_vm._s(_vm.i18n.firstName))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    required: true,
                    regex: /^[A-Za-z\s\.'-]+$/,
                    max: 35
                  },
                  expression:
                    "{ required: true,\n          regex: /^[A-Za-z\\s\\.'-]+$/ ,max: 35 }"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.firstName,
                  expression: "user.firstName"
                }
              ],
              ref: "firstName",
              staticClass: "form-control attribute-name",
              class: { "input-error": _vm.errors.has("firstName") },
              attrs: {
                "aria-label": _vm.i18n.firstName,
                type: "text",
                maxlength: "35",
                name: "firstName"
              },
              domProps: { value: _vm.user.firstName },
              on: {
                focus: function($event) {
                  _vm.createAccountServerError = ""
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "firstName", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("firstName")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", { staticClass: "error-msg" }, [
                    _vm._v(_vm._s(_vm.i18n.firstNameInvalidError))
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-xs-4 form-group" }, [
            _c(
              "label",
              {
                staticClass: "attribute-name",
                attrs: { for: "lastName", "aria-hidden": _vm.isDeviceAndroid() }
              },
              [_vm._v(_vm._s(_vm.i18n.lastName))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    required: true,
                    regex: /^[A-Za-z\s\.'-]+$/,
                    max: 35
                  },
                  expression:
                    "{ required: true,\n          regex: /^[A-Za-z\\s\\.'-]+$/ ,max: 35 }"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.lastName,
                  expression: "user.lastName"
                }
              ],
              ref: "lastName",
              staticClass: "form-control attribute-name",
              class: { "input-error": _vm.errors.has("lastName") },
              attrs: {
                type: "text",
                "aria-label": _vm.i18n.lastName,
                maxlength: "35",
                name: "lastName"
              },
              domProps: { value: _vm.user.lastName },
              on: {
                focus: function($event) {
                  _vm.createAccountServerError = ""
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "lastName", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("lastName")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", { staticClass: "error-msg" }, [
                    _vm._v(_vm._s(_vm.i18n.lastNameInvalidError))
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-xs-4 form-group" }, [
            _c(
              "label",
              {
                staticClass: "attribute-name",
                attrs: { for: "email", "aria-hidden": _vm.isDeviceAndroid() }
              },
              [_vm._v(_vm._s(_vm.i18n.email))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email|max:80",
                  expression: "'required|email|max:80'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.uid,
                  expression: "user.uid"
                }
              ],
              ref: "email",
              staticClass: "form-control attribute-name",
              class: { "input-error": _vm.errors.has("email") },
              attrs: {
                type: "text",
                "aria-label": _vm.i18n.email,
                name: "email",
                maxlength: "80",
                disabled: _vm.isB2bUnit
              },
              domProps: { value: _vm.user.uid },
              on: {
                focus: _vm.displayEmailInfo,
                keyup: _vm.hideEmailErrors,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "uid", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.showEmailInfo
              ? _c("div", { staticClass: "mt-xs-2 email-info-msg" }, [
                  _vm._v("\n        " + _vm._s(_vm.i18n.emailInfo) + "\n      ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.errors.has("email")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", { staticClass: "error-msg" }, [
                    _vm._v(_vm._s(_vm.i18n.emailInvalidError))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isEmailDuplicate
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", { staticClass: "error-msg" }, [
                    _vm._v(_vm._s(_vm.i18n.emailDuplicateError))
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-xs-4  form-group" },
            [
              _c(
                "label",
                {
                  staticClass: "attribute-name attribute-name",
                  attrs: {
                    for: "phoneNumber",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.mobileNo))]
              ),
              _vm._v(" "),
              _c("the-mask", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "min:15",
                    expression: "'min:15'"
                  }
                ],
                staticClass: "phone-number form-control attribute-name",
                attrs: {
                  masked: _vm.masked,
                  mask: "+1 ###-###-####",
                  type: "tel",
                  name: "phoneNumber",
                  "aria-label": _vm.i18n.mobileNo
                },
                model: {
                  value: _vm.user.cellPhone,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "cellPhone", $$v)
                  },
                  expression: "user.cellPhone"
                }
              }),
              _vm._v(" "),
              _vm.errors.has("phoneNumber")
                ? _c("div", { staticClass: "mt-xs-2" }, [
                    _c("span", { staticClass: "error-msg" }, [
                      _vm._v(_vm._s(_vm.i18n.phoneNumberInvalidError))
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.isGenderEnabled
            ? _c(
                "div",
                { staticClass: "mb-xs-4 form-group dropdown" },
                [
                  _c("label", { staticClass: "attribute-name" }, [
                    _vm._v(_vm._s(_vm.i18n.gender))
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    ref: "genderDropdown",
                    attrs: { dropdownValues: _vm.genderList },
                    on: {
                      "selected-option": function($event) {
                        _vm.user.gender = $event
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isDobEnabled
            ? _c("div", { staticClass: "mb-xs-4 form-group dropdown" }, [
                _c("label", { staticClass: "attribute-name" }, [
                  _vm._v(_vm._s(_vm.i18n.birthDay))
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "month-checkbox" },
                  [
                    _c("vx-dropdown-primary", {
                      ref: "monthDropdown",
                      attrs: {
                        "describedby-aria": _vm.showMonthError
                          ? "month_error_msg"
                          : null,
                        dropdownError: _vm.showMonthError,
                        dropdownValues: _vm.populateMonths,
                        name: "createAccountMonth"
                      },
                      on: {
                        "selected-option": function($event) {
                          _vm.handleChangeMonth($event)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.showMonthError
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                id: "month_error_msg",
                                "aria-live": "assertive"
                              }
                            },
                            [_vm._v(_vm._s(_vm.i18n.monthRequiredError))]
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "date-checkbox" },
                  [
                    _c("vx-dropdown-primary", {
                      ref: "dayDropdown",
                      attrs: {
                        "describedby-aria": _vm.showDateError
                          ? "date_error_msg"
                          : null,
                        dropdownError: _vm.showDateError,
                        dropdownValues: _vm.date
                      },
                      on: {
                        "selected-option": function($event) {
                          _vm.handleChangeDate($event)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.showDateError
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                id: "date_error_msg",
                                "aria-live": "assertive"
                              }
                            },
                            [_vm._v(_vm._s(_vm.i18n.dateRequiredError))]
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "clearDiv" }, [
            _c(
              "button",
              {
                staticClass:
                  "mt-md-3 mt-sm-3 mt-xs-4 submit btn center-block button-color text-trans modal-btn-primary-small modal-bottom-spacing",
                attrs: {
                  type: "submit",
                  disabled:
                    _vm.errors.any() || _vm.showDateError || _vm.showMonthError,
                  "aria-label": _vm.i18n.save
                }
              },
              [_vm._v(_vm._s(_vm.i18n.save))]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "confirmPasswordModal",
          attrs: { size: "extra-small", heading: "Confirm Password" }
        },
        [
          _c("vx-confirm-password", {
            attrs: { slot: "component", userDetails: _vm.user, i18n: _vm.i18n },
            on: {
              errorExists: _vm.checkError,
              verifiedPassword: _vm.updatedPassword
            },
            slot: "component"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5eff4cc8", { render: render, staticRenderFns: staticRenderFns })
  }
}