var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-guest-marketing-communications px-xs-3 py-xs-4 p-sm-5" },
    [
      _c("h4", { staticClass: "communication-heading mt-xs-0 mb-xs-4" }, [
        _vm._v(_vm._s(_vm.i18n.header))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "communication-message mt-xs-0 mb-xs-5" }, [
        _vm._v(
          _vm._s(_vm.i18n.description1) +
            " " +
            _vm._s(_vm.globals.getSiteId()) +
            ". " +
            _vm._s(_vm.i18n.description2)
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "communication-email" },
        [_vm._t("market-communication-email")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-493a7f65", { render: render, staticRenderFns: staticRenderFns })
  }
}