/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
/* eslint-disable radix */
import globals from '../../common/globals';
import { eventBus, globalEventBus } from '../../../modules/event-bus';
import mobileMixin from '../../common/mixins/mobile-mixin';
import vxPriceSpider from '../vx-price-spider/vx-price-spider-shell';

export default {
  name: 'vx-text-tile',
  components: { vxPriceSpider },
  mixins: [mobileMixin],
  props: {
    textTileData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      globals,
      fontStyleHeader: {
        color: this.textTileData.headerColor,
      },
      fontStyleText: {
        color: this.textTileData.textColor,
      },
      fontStyleCta: {
        color: this.textTileData.ctaColor,
      },
      alignmentClass: {
        'align-left': this.textTileData.textAlignment === 'left',
        'align-center': this.textTileData.textAlignment === 'center',
      },
      backgroundImg: '',
      isPriceSpider: false,
      psSKU: '',
    };
  },
  computed: {
    backgroundStyle() {
      return !this.backgroundImg
        ? {
          'background-color': this.textTileData.backgroundColor,
        }
        : {
          'background-image': `url(${this.backgroundImg})`,
        };
    },
    textWidth() {
      return this.textTileData.imageWidth
        ? {
          width: `${100 - parseInt(this.textTileData.imageWidth)}%`,
        }
        : {
          width: '',
        };
    },

    backgroundAlt() {
      return this.getResponsiveAlt(
        this.textTileData.backgroundImageAltTextD,
        this.textTileData.backgroundImageAltTextT,
        this.textTileData.backgroundImageAltTextM,
      );
    },
  },
  async mounted() {
    this.backgroundImg = this.getResponsiveImage(
      this.textTileData.backgroundImageD,
      this.textTileData.backgroundImageT,
      this.textTileData.backgroundImageM,
    );

    if (this.textTileData.ctaLink && this.textTileData.ctaLink.indexOf('price-spider') > -1) {
      this.isPriceSpider = true;
      this.textTileData.ctaLink.split(' ').forEach((element) => { if (element.indexOf('price-spider') > -1) { this.psSKU = element.split('-')[2]; } });
    }
  },
  methods: {
    /**
     * Function to open video in the modal by sending the video source.
     * @param {String} src
     */
    openVideo(src) {
      eventBus.$emit('open-player', src);
    },
    /**
     * Function to emit scroll signal to scroll the page to particular position by sending the hash id.
     * @param {String} link
     */
    emitScrollSignal(link) {
      globalEventBus.$emit('scroll-to', link);
    },
    // Method to strip HTML tags while dynamically setting the aria-label on links
    stripHtmlTags(ariaLabel) {
      if (ariaLabel) {
        return ariaLabel.replace(/(<([^>]+)>)/gi, '');
      }
    },
  },
};
