var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-profile-preferences", {
        attrs: {
          i18n: _vm.messages.manageProfileShoppingLists.profilePreferences
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-10e9b937", { render: render, staticRenderFns: staticRenderFns })
  }
}