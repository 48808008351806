var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-delete-address" },
    [
      _c("vx-spinner", {
        ref: "deleteSpinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("form", { ref: "deleteAddressModal" }, [
        _c("div", { staticClass: "mb-md-5 mb-xs-4 form-group" }, [
          _c(
            "p",
            { staticClass: "address-heading mt-sm-2", attrs: { role: "text" } },
            [_vm._v(_vm._s(_vm.i18n.txtDelete))]
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "px-xs-0" }, [
            _c("li", { staticClass: "card-item", attrs: { role: "text" } }, [
              _vm._v(
                _vm._s(_vm.address.firstName) +
                  " " +
                  _vm._s(_vm.address.lastName)
              )
            ]),
            _vm._v(" "),
            _vm.address && _vm.address.companyName
              ? _c(
                  "li",
                  { staticClass: "card-item", attrs: { role: "text" } },
                  [_vm._v(_vm._s(_vm.address.companyName))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("li", { staticClass: "card-item", attrs: { role: "text" } }, [
              _vm._v(_vm._s(_vm.address.line1))
            ]),
            _vm._v(" "),
            _vm.address && _vm.address.line2
              ? _c(
                  "li",
                  { staticClass: "card-item", attrs: { role: "text" } },
                  [_vm._v(_vm._s(_vm.address.line2))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("li", { staticClass: "card-item", attrs: { role: "text" } }, [
              _vm._v(
                _vm._s(_vm.address.town) +
                  " " +
                  _vm._s(_vm.address.region.isocodeShort) +
                  " " +
                  _vm._s(_vm.address.postalCode)
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "card-item", attrs: { role: "text" } }, [
              _vm._v(_vm._s(_vm.address.country.isocode))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c(
            "button",
            {
              staticClass:
                "btn btn-primary modal-btn-primary-xl modal-btn-xs-sticky",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  _vm.deleteAddress($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.i18n.btnDelete))]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-41a90357", { render: render, staticRenderFns: staticRenderFns })
  }
}