/**
 * Product categories carousel
 */
import {
  swiper,
  swiperSlide,
} from 'vue-awesome-swiper';
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-uiux-product-categories-carousel',
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    componentTitle: String,
    tabsData: Array,
    swiperModifierClass: {
      type: String,
      default: '',
    },
    enableSimulateTouch: {
      type: Boolean,
      default: true,
    },
    componentTheme: String,
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      selectedCategory: null,
      nextButton: '',
      prevButton: '',
      firstItem: '',
      lastItem: '',
      allTabs: '',
      selectedTab: '',
      tabParent: '',
      selectedTabPos: '',
      swiper: '',
      showNavigationArrows: false,
      firstSlide: true,
      lastSlide: false,
      tabs: '',
      target: '',
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 20,
        freeMode: false,
        watchOverflow: true,
        loop: false,
        breakpoints: {
          767: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          1199: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
        simulateTouch: this.enableSimulateTouch,
      },
      viewPort: {
        mobile: 767,
        desktop: 1440,
        tablet: 1199,
      },
      i18n: this.$root.messages.common.slider,
      swiperWrapperClass: '.swiper-container',
    };
  },
  computed: {},
  created() {
    if (this.swiperModifierClass) {
      this.swiperOptions.containerModifierClass = this.swiperModifierClass;
    }
  },
  mounted() {
    this.selectCategory(this.tabsData[0].uiuxCategory.categoryHeader);
    if (this.swiperModifierClass) {
      if (document.querySelector(`.${this.swiperModifierClass.concat('initialized')}`)) {
        this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'initialized');
      } else if (document.querySelector(`.${this.swiperModifierClass.concat('horizontal')}`)) {
        this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'horizontal');
      } else if (document.querySelector(`.${this.swiperModifierClass.concat('android')}`)) {
        this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'android');
      }
    }
    const self = this;
    setTimeout(() => {
      self.onInit();
      self.swiper = document.querySelector('.vx-uiux-product-categories-carousel .swiper-container').swiper;
      self.swiper.on('reachEnd', () => {
        self.firstSlide = false;
        self.lastSlide = true;
      });
      self.swiper.on('reachBeginning', () => {
        self.firstSlide = true;
        self.lastSlide = false;
      });
    }, 100);
    window.addEventListener('resize', () => {
      self.onInit();
    });
    this.tabs = document.querySelectorAll('[role="tab"]');
  },
  watch: {
    tabsData() {
      this.onInit();
    },
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category;
      this.onInit();
    },
    /**
         * Function sets the slides as per Viewport
         */
    onInit() {
      const self = this;
      let selectedCategoryIndex;
      for (let i = 0; i < self.tabsData.length; i += 1) {
        if (self.tabsData[i].uiuxCategory.categoryHeader === this.selectedCategory) {
          selectedCategoryIndex = i;
          break;
        }
      }
      if (self.isMobile()) {
        self.showNavigationArrows = self.tabsData[selectedCategoryIndex].productList.length > 1;
      } else if (self.isTablet()) {
        self.showNavigationArrows = self.tabsData[selectedCategoryIndex].productList.length > 2;
      } else {
        self.showNavigationArrows = self.tabsData[selectedCategoryIndex].productList.length > 4;
      }
    },
    /*
         * Function sets the slides to previous product
         */
    slidePrev() {
      this.swiper.slidePrev();
      this.lastSlide = false;
    },
    /**
         * Function sets the slides to next product
         */
    slideNext() {
      this.swiper.slideNext();
      this.firstSlide = false;
    },
    /**
         * Function to enable arrow navigation
         */
    arrowNavigation(event) {
      this.target = event.target;
      const keyCode = event.keyCode || event.which;
      for (const [key, value] of Object.entries(this.tabs)) {
        if (this.target.innerText === `${value.innerText}`) {
          // Left Arrow
          if (key === 0 && keyCode === 37) {
            this.tabs[this.tabs.length - 1].focus();
          } else if (keyCode === 37) {
            this.tabs[parseInt(key, 10) - 1].focus();
          }
          // Right Arrow
          if (key === this.tabs.length - 1 && keyCode === 39) {
            this.tabs[0].focus();
          } else if (keyCode === 39) {
            this.tabs[parseInt(key, 10) + 1].focus();
          }
        }
      }
    },
    // Method to determine the last and first element in the tabpanel
    focusedElement(e) {
      this.allTabs = this.$el.querySelectorAll('.category');
      this.selectedTab = this.$el.querySelector('.category.selected');
      this.tabParent = this.selectedTab.parentNode;
      this.selectedTabPos = Array.prototype.indexOf.call(this.tabParent.children, this.selectedTab);
      this.prevButton = this.$el.querySelector('.swiper-button-prev');
      this.nextButton = this.$el.querySelector('.swiper-button-next');
      if (this.prevButton) {
        if (this.prevButton.classList.contains('swiper-button-disabled')) {
          this.firstItem = this.$el.querySelector('.swiper-wrapper>.swiper-slide:first-child>a');
        } else {
          this.firstItem = this.prevButton;
        }
      } else {
        this.firstItem = this.$el.querySelector('.swiper-wrapper>.swiper-slide:first-child>a');
      }

      if (this.nextButton) {
        if (this.nextButton.classList.contains('swiper-button-disabled')) {
          this.lastItem = this.$el.querySelector('.swiper-wrapper>.swiper-slide:last-child>a');
        } else {
          this.lastItem = this.nextButton;
        }
      } else {
        this.lastItem = this.$el.querySelector('.swiper-wrapper>.swiper-slide:last-child>a');
      }
      this.focusTrap(e);
    },
    // Method to trap focus within tabpanel
    focusTrap(e) {
      const keyCode = e.keyCode || e.which;
      if (keyCode === 9 && document.activeElement === this.lastItem && !e.shiftKey) {
        if (this.selectedTabPos === this.allTabs.length - 1) {
          this.selectCategory(this.tabsData[0].uiuxCategory.categoryHeader);
        } else {
          this.selectCategory(this.tabsData[this.selectedTabPos + 1].uiuxCategory.categoryHeader);
          this.selectedTab.focus();
        }
      }
    },
  },
};
