/**
 * Banner text component
 */
import globals from '../../../../components/common/globals';
import cookiesMixin from '../../../../components/common/mixins/cookies-mixin';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import messages from '../../../../locale/gp-pro/messages';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxSaveCart from '../../manage-shopping-cart/vx-save-cart/vx-save-cart.vue';
import SearchBrowseService from '../../../../components/common/services/search-browse-service';
import {
  ProductAvailability,
  cookies,
  addToCartStatus,
  flyoutStatus,
} from '../../../../components/common/mixins/vx-enums';
import {
  eventBus,
  cartEventBus,
  globalEventBus,
} from '../../../../modules/event-bus';

export default {
  name: 'vx-frequently-bought-together',
  components: {
    vxSpinner,
    vxModal,
    vxSaveCart,
  },
  mixins: [cookiesMixin, mobileMixin, flyoutBannerMixin],
  data() {
    return {
      ProductAvailability,
      globals,
      cookies,
      messages,
      searchBrowseService: new SearchBrowseService(),
      addToCartStatus,
      flyoutStatus,
      isBulkEnabled: true,
      frequentlyBoughtTogether: [],
      frequentlyBoughtTogetherParsedArray: [],
      guestListName: '',
    };
  },
  computed: {},
  mounted() {
    const self = this;
    eventBus.$on('frequentlyBoughtTogether', (data) => {
      self.frequentlyBoughtTogether = data.references;
      for (let i = 0; i < self.frequentlyBoughtTogether.length; i += 1) {
        const notPurchasable = self.frequentlyBoughtTogether[i].target.notPurchasable === true;
        const isLowStock = self.frequentlyBoughtTogether[i].target.minOrderQuantity
                   && self.frequentlyBoughtTogether[i].target.stock.stockLevel < self.frequentlyBoughtTogether[i].target.minOrderQuantity;
        const isComingSoon = self.frequentlyBoughtTogether[i].target.materialStatus === self.ProductAvailability.COMING_SOON;
        const notAvailable = !self.frequentlyBoughtTogether[i].target.stock.hasOwnProperty('nextAvailableDate');

        if (!notPurchasable && !isLowStock && !isComingSoon && !notAvailable) {
          self.frequentlyBoughtTogetherParsedArray.push(self.frequentlyBoughtTogether[i].target);
        }
      }
    });
  },
  methods: {
    handleBulkAddToCart() {
      this.$refs.spinner.showSpinner();
      const bulkProducts = [];
      this.frequentlyBoughtTogetherParsedArray.forEach((item) => {
        bulkProducts.push({
          code: item.code,
          minOrderQuantity: item.minOrderQuantity || 1,
        });
      });
      const requestBody = {
        products: bulkProducts,
      };
      const requestConfig = {};
      requestConfig.data = requestBody;
      this.searchBrowseService.addBulkToCart(requestConfig, this.handleAddBulkToCartResponse, this.handleAddBulkToCartError);
    },
    handleAddBulkToCartResponse(response) {
      if (response.data) {
        cartEventBus.$emit('call-basic-cart');
        this.$refs.spinner.hideSpinner();
        const errorProducts = [];
        this.frequentlyBoughtTogetherParsedArray.forEach((item) => {
          response.data.forEach((product) => {
            if (product.statusCode === this.addToCartStatus.failure) {
              if (product.errorProductCode === item.code) {
                errorProducts.push(item.code);
              }
              if (errorProducts.length === this.frequentlyBoughtTogetherParsedArray.length) {
                this.showFlyout(this.flyoutStatus.error, this.messages['en-US'].searchBrowse.searchResult.bulkAddToCartError, true);
              } else {
                this.showFlyout(this.flyoutStatus.error, this.messages['en-US'].searchBrowse.searchResult.addToCartError, true);
              }
            }
            if (errorProducts.length === 0) {
              this.showFlyout(this.flyoutStatus.success, this.messages['en-US'].searchBrowse.searchResult.addToCartSuccess, true);
            }
          });
        });
      }
    },
    handleAddBulkToCartError() {
      this.$refs.spinner.hideSpinner();
    },
    handleBulkAddToList(event) {
      if (this.globals.loggedIn) {
        this.$refs.selectListModal.open(event);
      } else {
        // Storing the bulk selected items in the cookie
        const selectedBulkProductsCode = [];
        this.frequentlyBoughtTogetherParsedArray.forEach((item) => {
          selectedBulkProductsCode.push(item.code);
        });
        const selectedBulkProductsCookie = {
          selectedBulkProducts: selectedBulkProductsCode,
          lazyLoad: false,
        };
        this.createCookie(this.cookies.selectedBulkProducts, JSON.stringify(selectedBulkProductsCookie));
        if (this.globals.siteConfig.isGuestList) {
          setTimeout(() => {
            this.guestListName = this.messages['en-US'].searchBrowse.searchResult.guestList;
            this.showFlyout(this.flyoutStatus.success, `${this.frequentlyBoughtTogetherParsedArray.length} ${this.messages['en-US'].searchBrowse.searchResult.addToListSuccess} ${this.messages['en-US'].searchBrowse.searchResult.guestList}`, true);
          }, 300);
        } else {
          this.globals.navigateToUrl('login');
          globalEventBus.$emit('announce', 'For adding to list, you need to login');
          setTimeout(() => {
            this.globals.navigateToUrl('login');
          }, 300);
        }
      }
    },
    onSelectListSuccess(selectedListName) {
      this.$refs.selectListModal.close();
      if (this.globals.loggedIn) {
        this.showFlyout(this.flyoutStatus.success, `${this.frequentlyBoughtTogetherParsedArray.length} ${this.messages['en-US'].searchBrowse.searchResult.addToListSuccess} ${selectedListName}`, true);
      }
    },
  },
};
