import axios from 'axios';
import { Validator } from 'vee-validate';
import filter from 'lodash.filter';
import includes from 'lodash.includes';
// import createAccount from './create-account-i18n';
import vxDropdownPrimary from '../../../../components/common/vx-dropdown-primary/vx-dropdown-primary.vue';
import globals from '../../../../components/common/globals';
import vxCompanyDetails from '../vx-company-details/vx-company-details.vue';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import loginMixin from '../../../../components/common/mixins/login-mixin';
import kauthMixin from '../../../../components/common/mixins/kauth-mixin';
import detectDeviceMixin from '../../../../components/common/mixins/detect-device-mixin';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import vxTermsCondition from '../../../../components/common/vx-terms-condition/vx-terms-condition.vue';
import RegistrationLoginService from '../../../../components/common/services/registration-login-service';
import AnalyticsService from '../../../../components/common/services/analytics-service';
import { globalEventBus } from '../../../../modules/event-bus';
import PardotService from '../../../../components/common/services/pardot-service';
import {
  monthLiterals,
  monthCollection1,
  monthCollection2,
  genderList,
} from '../../../../components/common/mixins/vx-enums';
import vxPasswordCheckList from '../../../../components/registration-login/vx-password-checkList/vx-password-checkList.vue';

/**
 * Register component
 */

export default {
  name: 'vx-create-account',
  mixins: [loginMixin, kauthMixin, detectDeviceMixin],
  props: {
    actionUrl: String,
    i18n: Object,
    countryMarketingValues: Object,
    isGenderEnabled: {
      type: Boolean,
      default: false,
    },
    isDobEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      globals,
      registrationLoginService: new RegistrationLoginService(),
      PardotService: new PardotService(),
      analyticsService: new AnalyticsService(),
      kochTokens: {},
      // i18n: createAccount,
      createAccountServerError: '',
      marketingCheckbox: {},
      monthLiterals,
      date: [],
      dateLimit: 31,
      populateMonths: [],
      dateOfBirth: '',
      countryList: [
        {
          label: 'United States of America',
          value: 'US',
        },
        {
          label: 'Canada',
          value: 'CA',
        },
      ],
      genderList,
      user: {
        country: {
          label: 'United States of America',
          value: 'US',
        },
        gender: {
          label: 'Select Gender',
          value: '',
        },
        month: {
          label: 'Month',
          value: '',
        },
        date: {
          label: 'Date',
          value: '',
        },
        firstName: '',
        lastName: '',
        uid: '',
        password: '',
        confirmPassword: '',
        confirmEmail: '',
        b2bMailerCheck: '',
        addToMarketComm: '',
      },
      showPassword: false,
      showConfirmPassword: false,
      showDateError: false,
      showMonthError: false,
      isPasswordMismatch: true,
      isEmailMismatch: true,
      API_URL: '/gpcommercewebservices/v2/gppro/apiproxy',
      config: {
        authUrl: '/authorizationserver/oauth/token?client_id=mobile_android&client_secret=secret&grant_type=client_credentials',
        urlPrefix: '',
        auth_token: '',
      },
    };
  },
  components: {
    vxDropdownPrimary,
    vxCompanyDetails,
    vxTermsCondition,
    vxModal,
    vxSpinner,
    vxPasswordCheckList,
  },
  methods: {
    /**
     * openTermsModal is called on clicking of terms and conditions, which opens the termsConditionModal.
     * @param {Object} event contains the event of the particular element.
     */
    openTermsModal(event) {
      this.$refs.termsConditionModal.open(event);
    },
    /**
     * This function toggles the password on hiding and showing the text.
     */
    togglePassword() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.$refs.password.type = 'text';
      } else {
        this.$refs.password.type = 'password';
      }
    },
    toggleConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
      if (this.showConfirmPassword) {
        this.$refs.confirmPassword.type = 'text';
      } else {
        this.$refs.confirmPassword.type = 'password';
      }
    },
    /**
     * submitForm is called on cicking submit button of the create account form.
     * @param {Object} e contains the event of the form.
     * Validates the data entered on successfull validation user a post call is made to registerUser.
     * On failure focus is set back to first field in the form.
     */
    submitForm(e) {
      const self = this;
      e.preventDefault();
      if (this.user.month.value === '' && this.user.date.value !== '') {
        this.showMonthError = true;
      } else if (this.user.month.value !== '' && this.user.date.value === '') {
        this.showDateError = true;
      } else if (
        (this.user.month.value === '' && this.user.date.value === '')
        || (this.user.month.value !== '' && this.user.date.value !== '')
      ) {
        this.showMonthError = false;
        this.showDateError = false;
      }
      this.dismissFlyout();
      this.$validator.validateAll()
        .then((result) => {
          self.DisableSubmit();
          self.ValidateBriteVerify((success) => {
            if (success) {
              if (result && !this.showMonthError && !this.showDateError) {
                this.setDob();
                if (self.globals.isB2C()) {
                  self.user.b2bMailerCheck = false;
                } else {
                  self.user.addToMarketComm = false;
                }
                const requestConfig = {};
                requestConfig.data = self.createRequestbody();
                self.registrationLoginService.registerUser(
                  requestConfig,
                  this.handleRegisterResponse,
                  this.handleRegisterError,
                );
                self.$refs.spinner.showSpinner(this.globals.pardotFailureTimeout, self.handleCompanyModal);
              } else {
                this.globals.setFocusByName(
                  this.$el,
                  this.globals.getElementName(this.errors),
                );
              }
            } else {
              self.showFlyout(
                'error',
                'Error! The value of the email field is not valid',
                false,
              );
              self.EnableSubmit();
            }
          });
        });
    },
    /**
     * Creates a request based on the data entered by the user.
     */
    createRequestbody() {
      let marketingCommunications = false;
      if (this.globals.isB2B()) {
        marketingCommunications = this.user.b2bMailerCheck;
      } else {
        marketingCommunications = this.user.addToMarketComm;
      }
      const reqbody = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        password: this.user.password,
        country: this.user.country.value,
        gender: this.user.gender.value,
        dateOfBirth: this.dateOfBirth,
        uid: this.user.uid,
        addToMarketComm: marketingCommunications,
        titleCode: 'mr',
      };
      return reqbody;
    },
    /**
     * handleRegisterResponse is called on successfull posting of the user.
     * A success flyout messae is displayed.
     * If it is B2C and Employee store the kauthData is delete.
     * If it is a B2B Site company details modal is opened.
     */
    handleRegisterResponse(data) {
      const signupMethod = 'GPPRO';
      this.analyticsService.trackSignupGA4(signupMethod);
      const self = this;
      if (this.globals.isB2C()) {
        this.submitLogin();
      } else if (this.globals.isB2B()) {
        // check if user has checked the checkbox
        if (this.user.b2bMailerCheck) {
          // check if a pardot prospect already exists
          const { uid } = data.data;
          this.PardotService.readProspectWithEmail(uid, this.handleCommunicationPreferences, this.handleCommunicationPreferencesError);
        } else {
          // user has not prefered communication emails
          this.handleCompanyModal();
        }
        // Send a new 'action:register' to reCAPTCHA
        if (self.globals.siteConfig.isReCaptchaEnabled && window.grecaptcha) {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(self.globals.reCaptchaSiteKey, { action: 'register' });
          });
        }
      }
    },
    handleCompanyModal() {
      this.$refs.spinner.hideSpinner();
      this.showFlyoutNextPage('success', this.i18n.registerSuccessMsg, false);
      // If it is B2B then survey details call we will generate
      // if it is success company details modal will be opened
      if (this.globals.siteConfig.createAcountMoreDetailsEnabled) {
        this.$refs.companyDetailsModal.open();
      } else {
        this.submitLogin();
      }
    },
    /**
     * On submit or skip or close  we will close the modal we will send
     * data of company details / survey
     * details to company details ajax request on its response we will call login
     * company details success response we will allow person to login.
     */
    loginRequest() {
      this.$refs.companyDetailsModal.close();
      this.submitLogin();
    },
    /**
     * handleRegisterError is called on failure of  posting  the registerUser to server.
     * @param {Object} error is the data recieved from server on failure which contains error code, status.
     */
    handleRegisterError(error) {
      this.$refs.spinner.hideSpinner();
      if (error) {
        this.createAccountServerError = this.i18n[
          error.response.data.errors[0].message
        ];
        globalEventBus.$emit('announce', this.createAccountServerError);
      }
    },
    /**
     * This function navigated the user to login page.
     */
    login() {
      this.globals.navigateToUrl('login');
    },
    /**
     * Based on change in month the date dropdown is set with the respective dates.
     */
    setDateDropDown() {
      this.date = [];
      for (let i = 1; i <= this.dateLimit; i += 1) {
        const tempObj = {};
        if (i < 10) {
          tempObj.label = `0${i}`;
          tempObj.value = `0${i}`;
        } else {
          tempObj.label = i.toString();
          tempObj.value = i.toString();
        }
        this.date.push(tempObj);
      }
    },
    /**
     * When ever there is change in Month handleChangeMonth is called.
     * @param {Object} evt contains the event of the particular field.
     */
    handleChangeMonth(evt) {
      switch (evt.value) {
        case '02':
          this.dateLimit = 29;
          break;
        case '04':
        case '06':
        case '09':
        case '11':
          this.dateLimit = 30;
          break;
        default:
          this.dateLimit = 31;
      }
      this.user.month.value = evt.value;
      if (this.showMonthError) {
        this.showMonthError = false;
      }
      this.setDateDropDown();
    },
    /**
     * Changing the Months in month drop down when ever user changes the date.
     * handleChangeDate is called on chaining the date.
     * @param {Object} evt contains the event of the particular dropdown.
     */
    handleChangeDate(evt) {
      let temp = {};
      const valueLiteral = 'value';
      switch (evt.value) {
        case '31':
          temp = this.filterMonths(
            this.monthLiterals.options,
            valueLiteral,
            monthCollection1,
          );
          break;
        case '30':
          temp = this.filterMonths(
            this.monthLiterals.options,
            valueLiteral,
            monthCollection2,
          );
          break;
        default:
          temp = this.monthLiterals.options;
          break;
      }
      if (this.showDateError) {
        this.showDateError = false;
      }
      this.user.date.value = evt.value;
      this.populateMonths = temp;
    },
    filterMonths(collection, property, values) {
      return filter(collection, (item) => includes(values, item[property]));
    },
    /**
     * Sets tht DOB i.e date of the birth.
     */
    setDob() {
      if (
        this.$refs.dateDropdown
        && this.$refs.monthDropdown
        && this.user.date.value !== ''
        && this.user.month.value !== ''
      ) {
        this.dateOfBirth = `${this.user.date.value}-${this.user.month.value}`;
      }
    },
    handleLoginCallback() {
      this.showFlyoutNextPage('success', 'User logged in', true);
    },
    /**
     * Marketing country check box changes/
     * @param {Object} country contians the current country chose.
     */
    handleCountryForMarketing(country) {
      this.marketingCheckbox = this.countryMarketingValues[country];
    },
    /**
     * handleKochTokenResponse is called on successfull response from getKochAuthToken.
     * @param {Object} response contains the kochTokens.
     */
    handleKochTokenResponse(response) {
      this.kochTokens = response.data;
    },
    handleKochTokenError() {
    },
    handleCommunicationPreferencesError(response) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response, 'text/xml');
      const status = xmlDoc.getElementsByTagName('rsp')[0].getAttribute('stat');
      if (status === 'fail') {
        // user profile does not exist
        const code = xmlDoc.getElementsByTagName('err')[0].getAttribute('code');
        if (code === '4') {
          const {
            uid, country: { value }, firstName, lastName,
          } = this.user;
          const params = `${uid}?country=${value}&first_name=${firstName}&last_name=${lastName}&source=gppro.com-registration&campaign_id=52556`.replace(/ /g, '%20');
          this.PardotService.createProspect(params, () => {
            this.handleCompanyModal();
          }, () => {
            // error creating prospect
            this.handleCompanyModal();
          });
        } else {
          this.handleCompanyModal();
        }
      }
    },
    handleCommunicationPreferences(response) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response, 'text/xml');
      const optedOut = xmlDoc.getElementsByTagName('opted_out')[0].innerHTML === '1';
      const prospectID = xmlDoc.getElementsByTagName('id')[0].innerHTML;
      // check if user has already been opted-out
      if (optedOut && prospectID !== null) {
        // opt-in the user since user checked the checkbox
        this.PardotService.updateProspect(`${prospectID}?opted_out=0`, this.handleUpdatePreferences, this.handleUpdatePreferencesError);
      }
      this.handleCompanyModal();
    },
    updatePasswordMismatch() {
      if (this.user.confirmPassword === this.user.password) {
        this.isPasswordMismatch = false;
      } else {
        this.isPasswordMismatch = true;
      }
    },
    updateEmailMismatch() {
      if (this.user.uid === this.user.confirmEmail) {
        this.isEmailMismatch = false;
      } else {
        this.isEmailMismatch = true;
      }
    },
    handlePasteAction(event) {
      event.preventDefault();
    },
    ValidateBriteVerify(callback) {
      const request = {
        remoteName: 'GPPRO-BV',
        remoteUrl: '/',
        remoteHeaders: ['Content-Type:application/json', 'Authorization:ApiKey: {$remote_access_token}'],
        postData: `{"email": "${this.user.confirmEmail}"}`,
      };
      this.GetAuthToken((success) => {
        if (success) {
          const options = {
            headers: {
              Authorization: `bearer ${this.config.access_token}`,
              'Content-Type': 'application/json',
            },
          };
          axios.post(
            this.API_URL,
            request,
            options,
          ).then(({ data: response }) => {
            if (
              response
              && response.email.status !== 'invalid'
              && response.email.disposable !== true
              && response.email.disposable !== 'true') {
              callback.call(this, true);
            } else {
              callback.call(this, false);
            }
          }).catch(() => {
            callback.call(this, true);
          });
        }
      });
    },

    GetAuthToken(callback) {
      const self = this;
      if (this.config.access_token) {
        if (callback) {
          callback.call(self, true);
        }
      } else {
        // eslint-disable-next-line prefer-template
        axios.post(this.config.urlPrefix + this.config.authUrl)
          .then((response) => {
            if (response && response.data && response.data.access_token) {
              this.config.access_token = response.data.access_token;
              if (callback) {
                callback.call(self, true);
              }
            } else {
              this.handleSendFormDataError.call('The server is unable to accept this form post at this time.');
            }
          })
          .catch(this.handleSendFormDataError);

        // https://localhost:9002/authorizationserver/oauth/token?client_id=mobile_android&client_secret=secret&grant_type=client_credentials
        /*
          {
              "access_token": "e13b0ab7-b93a-4fa0-b38d-b1c05e2b04aa",
              "token_type": "bearer",
              "expires_in": 42074,
              "scope": "basic openid"
          }
        */
      }
    },

    DisableSubmit() {
      if (this.submitButton) {
        this.submitButton.setAttribute('disabled', true);
        this.submitButton.setAttribute('data-enabled-value', this.submitButton.getAttribute('value'));
        this.submitButton.setAttribute('value', 'Validating Email...');
        if (this.$refs.spinner) {
          this.$refs.spinner.showSpinner();
        }
      }
    },

    EnableSubmit() {
      if (this.submitButton) {
        this.submitButton.removeAttribute('disabled');
        this.submitButton.setAttribute('value', this.submitButton.getAttribute('data-enabled-value'));
        this.submitButton.removeAttribute('data-enabled-value');
        if (this.$refs.spinner) {
          this.$refs.spinner.hideSpinner();
        }
      }
    },

  },
  mounted() {
    globalEventBus.$emit('announce', 'Create Account Page');
    const veeCustomErrorMessage = {
      en: {
        custom: {
          firstName: {
            required: this.i18n.firstNameRequiredError,
            regex: this.i18n.firstNameRegexError,
            max: this.i18n.firstNameMaxError,
          },
          lastName: {
            required: this.i18n.lastNameRequiredError,
            regex: this.i18n.lastNameRegexError,
            max: this.i18n.lastNameMaxError,
          },
          email: {
            required: this.i18n.emailRequiredError,
            email: this.i18n.emailInvalidError,
          },
          confirmEmail: {
            required: this.i18n.confirmEmailRequiredError,
          },
          password: {
            required: this.i18n.passwordRequiredError,
            regex: this.i18n.passwordInvalidError,
            min: this.i18n.passwordMinError,
          },
          confirmPassword: {
            required: this.i18n.confirmPasswordRequiredError,
          },
          createAccountMonth: {
            required: this.i18n.monthRequiredError,
          },
          createAccountDay: {
            required: this.i18n.DayRequiredError,
          },
        },
      },
    };
    this.populateMonths = this.monthLiterals.options;
    this.setDateDropDown();
    Validator.localize(veeCustomErrorMessage);
    this.handleCountryForMarketing(this.user.country.value.toLowerCase());
    this.$refs.countryDropdown.setDropdownLabel(this.user.country.label);
    if (this.$refs.genderDropdown) {
      this.$refs.genderDropdown.setDropdownLabel(this.user.gender.label);
    }
    if (this.$refs.monthDropdown) {
      this.$refs.monthDropdown.setDropdownLabel(this.user.month.label);
    }
    if (this.$refs.dateDropdown) {
      this.$refs.dateDropdown.setDropdownLabel(this.user.date.label);
    }
    if (this.globals.isB2B()) {
      this.user.b2bMailerCheck = this.marketingCheckbox.B2BMailerCheck;
    } else {
      this.user.addToMarketComm = this.marketingCheckbox.B2CAddToMarket;
    }
    this.submitButton = this.$el.querySelector('.submit');
  },
  watch: {
    'user.country': function() {
      this.handleCountryForMarketing(this.user.country.value.toLowerCase());
      if (this.globals.isB2B()) {
        this.user.b2bMailerCheck = this.marketingCheckbox.B2BMailerCheck;
      } else {
        this.user.addToMarketComm = this.marketingCheckbox.B2CAddToMarket;
      }
    },
    'user.confirmPassword': function() {
      this.updatePasswordMismatch();
    },
    'user.password': function() {
      if (this.user.confirmPassword.length) {
        this.updatePasswordMismatch();
      }
    },
    'user.uid': function() {
      this.updateEmailMismatch();
    },
    'user.confirmEmail': function() {
      if (this.user.confirmEmail.length) {
        this.updateEmailMismatch();
      }
    },
  },
};
