export default {
  b2b: {
    stg: {
      initParam1: 'https://gppro--gpproqa2.sandbox.my.salesforce.com',
      initParam2: 'https://gppro--gpproqa2.sandbox.my.salesforce-sites.com/LiveAgent',
      initParam4: '00D3I00000017TE',
      initParam5: 'GPPRO',
      initParam6: {
        baseLiveAgentContentURL: 'https://c.la1-c1cs-ia5.salesforceliveagent.com/content',
        deploymentId: '5722A000000TOlq',
        buttonId: '5733q000000RsD1',
        baseLiveAgentURL: 'https://d.la1-c1cs-ia5.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I3q000000CaRcEAK_17a3a7f4481',
        isOfflineSupportEnabled: false,
      },
      salesForceScript: 'https://gppro--gpproqa2.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    },
    prd: {
      initParam1: 'https://gppro.my.salesforce.com',
      initParam2: 'https://gppro.my.salesforce-sites.com/LiveAgent',
      initParam4: '00DF00000004zvO',
      initParam5: 'GPPRO',
      initParam6: {
        baseLiveAgentContentURL: 'https://c.la1-c1-ia5.salesforceliveagent.com/content',
        deploymentId: '5722A000000TOlq',
        buttonId: '5733q000000RsD1',
        baseLiveAgentURL: 'https://d.la1-c1-ia5.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I3q000000CaRcEAK_17a3a7f4481',
        isOfflineSupportEnabled: false,
      },
      salesForceScript: 'https://gppro.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    },
  },
  b2c: {
    stg: {
      initParam1: 'https://gppro--gpproqa2.sandbox.my.salesforce.com',
      initParam2: 'https://gppro--gpproqa2.sandbox.my.salesforce-sites.com/LiveAgent',
      initParam4: '00D3I00000017TE',
      initParam5: 'B2C',
      initParam6: {
        baseLiveAgentContentURL: 'https://c.la1-c1cs-ia5.salesforceliveagent.com/content',
        deploymentId: '5723q000000JPvB',
        buttonId: '5733q000000RsD6',
        baseLiveAgentURL: 'https://d.la1-c1cs-ia5.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I3q000000CaRbEAK_17a3a7580b9',
        isOfflineSupportEnabled: false,
      },
      salesForceScript: 'https://gppro--gpproqa2.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    },
    prd: {
      initParam1: 'https://gppro.my.salesforce.com',
      initParam2: 'https://gppro.my.salesforce-sites.com/LiveAgent',
      initParam4: '00DF00000004zvO',
      initParam5: 'B2C',
      initParam6: {
        baseLiveAgentContentURL: 'https://c.la1-c1-ia5.salesforceliveagent.com/content',
        deploymentId: '5723q000000JPvB',
        buttonId: '5733q000000RsD6',
        baseLiveAgentURL: 'https://d.la1-c1-ia5.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I3q000000CaRbEAK_17a3a7580b9',
        isOfflineSupportEnabled: false,
      },
      salesForceScript: 'https://gppro.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    },
  },
};
