var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-review-lease-agreement" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row modal-btn-IEspacing modal-btn-EDGEspacing" },
        [
          _vm.country.name.toUpperCase() === _vm.countryEnum.options[1].label
            ? _c("div", { staticClass: "terms-language" }, [
                _c("div", { staticClass: "btn-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary py-xs-0 lease-language",
                      class: [_vm.isActive ? "active-class" : "disabled-class"],
                      on: {
                        click: function($event) {
                          _vm.toggleLanguage($event)
                        }
                      }
                    },
                    [_vm._v("EN")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary py-xs-0  lease-language",
                      class: [_vm.isActive ? "disabled-class" : "active-class"],
                      on: {
                        click: function($event) {
                          _vm.toggleLanguage($event)
                        }
                      }
                    },
                    [_vm._v("FR")]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "terms-content col-xs-12" }, [
            _c("div", { staticClass: "row" }, [
              !_vm.orderDetails
                ? _c(
                    "div",
                    {
                      staticClass: "mb-xs-4 col-xs-12 terms-content-description"
                    },
                    [_vm._v(_vm._s(_vm.legalAgreementName))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.legalAgreementContent
                ? _c("div", {
                    staticClass: "col-xs-12 terms-content-intro",
                    domProps: { innerHTML: _vm._s(_vm.legalAgreementContent) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-xs-12 terms-content-points mt-xs-4" },
                [
                  _vm.i18n.termsConditions
                    ? _vm._l(_vm.i18n.termsConditions, function(term, index) {
                        return _c("p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(index + 1) +
                              ". " +
                              _vm._s(term.terms) +
                              "\n            "
                          )
                        ])
                      })
                    : _vm._e()
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-xs-12 terms-decision mt-xs-4 d-flex justify-content-center"
            },
            [
              !_vm.orderDetails
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-default text-trans modal-dual-btn mr-xs-5",
                      on: {
                        click: function($event) {
                          _vm.agreementDecision(false, $event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.declineBtn))]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.orderDetails && !_vm.country.leaseAccepted
                ? _c(
                    "button",
                    {
                      staticClass: "btn button-color text-trans modal-dual-btn",
                      on: {
                        click: function($event) {
                          _vm.agreementDecision(true, $event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.acceptBtn))]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.orderDetails && _vm.country.leaseAccepted
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn button-color text-trans accepted-btn modal-dual-btn",
                      on: {
                        click: function($event) {
                          _vm.agreementAccepted($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.acceptedBtn))]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-089d1bd2", { render: render, staticRenderFns: staticRenderFns })
  }
}