import globals from '../../../../components/common/globals';

export default {
  name: 'vx-live-chat',
  props: {
    // Text and label from properties file
    i18n: {
      type: Object,
      required: true,
    },
    // Dynamic css class for live chat
    liveChatTheme: {
      type: String,
      required: false,
    },
    // Shows call us section
    showContactUs: {
      type: Boolean,
      required: true,
      default: true,
    },
    // Data about chat section
    imageTextData: {
      type: Object,
      required: false,
    },
    // Contact email to be passed from jsp
    contactUsEmail: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      globals,
      splitParam: new RegExp(':\\s*'),
    };
  },
  computed: {
    callNumber() {
      return this.i18n.callNumber.split(this.splitParam)[1];
    },
    textNumber() {
      return this.i18n.textNumber.split(this.splitParam)[1];
    },
  },
};
