/**
 * vx-product-support is a CMS component with
 *  tab  structure where the product can be selected
 *  from the grid and its info is displayed.
 */
import vxProductCategory from '../vx-product-category/vx-product-category.vue';

export default {
  name: 'vx-product-support-render',
  components: {
    'vx-product-category': vxProductCategory,
  },
  props: {
    tabsData: {
      type: Array,
      required: true,
    },
    componentTitle: {
      type: String,
      required: true,
    },
    componentId: {
      type: String,
    },
    componentTheme: {
      type: String,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabBody: [],
    };
  },
  computed: {

  },
  mounted() {},
  methods: {},
};
