var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-split-address" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addNewAddressModal",
          attrs: {
            size: "large",
            heading: _vm.addressModalHeading,
            "is-modal-scrollable": _vm.modalScrollable
          }
        },
        [
          _c(
            "vx-add-edit-address",
            {
              ref: "addressForm",
              attrs: {
                slot: "component",
                buttonText: _vm.i18n.useShippingAddress,
                submitButtonText: _vm.i18n.useShippingAddress,
                "pallet-shipment": _vm.palletShipment,
                i18n: _vm.i18n.address,
                editAddress: _vm.addressToEdit,
                "is-bussiness-unit": _vm.globals.isB2B()
              },
              on: { onAddressSaved: _vm.updatedAddr },
              slot: "component"
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("div", { staticClass: "section-header mb-xs-3" }, [
                  _vm._v(_vm._s(_vm.i18n.shippingAddressModal))
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.splitApplicable
        ? _c("div", [
            !_vm.isSplit
              ? _c(
                  "div",
                  _vm._l(_vm.splitEntry, function(item, index) {
                    return _c("div", { key: item }, [
                      _c(
                        "div",
                        { staticClass: "section-header pt-xs-1 pb-xs-1" },
                        [
                          _c(
                            "span",
                            {
                              class: _vm.isMobile()
                                ? "hidden-xs"
                                : "visibility-hidden"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.i18n.splitAddress.quantity) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "address-no-split",
                              attrs: { tabindex: "0", role: "text" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.i18n.splitAddress.address) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("vx-stepper-control", {
                            ref: "stepperController",
                            refInFor: true,
                            class: _vm.isMobile()
                              ? "hidden-xs"
                              : "visibility-hidden",
                            attrs: { "min-value": 0, value: item.qty },
                            on: {
                              currentCount: function($event) {
                                _vm.handleQuantity(item, index, $event)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("vx-dropdown-primary", {
                            ref: "shippingAddressDropdown",
                            refInFor: true,
                            staticClass:
                              "pl-sm-4 address-dropdown pl-sm-4 pb-sm-0 pt-sm-0 pt-xs-2 pb-xs-2",
                            attrs: {
                              dropdownValues: _vm.addresses,
                              "aria-label": _vm.i18n.splitAddress.address
                            },
                            on: {
                              "selected-item": function($event) {
                                _vm.selectedAddress = $event
                              },
                              "edit-selected-address": function($event) {
                                _vm.editAddress($event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      item.qty > 1
                        ? _c(
                            "div",
                            { staticClass: "px-xs-4 split-addresses" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "split-quantity",
                                  attrs: { role: "button", tabindex: "0" },
                                  on: {
                                    keyup: function($event) {
                                      if (
                                        !("button" in $event) &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter-space",
                                          undefined,
                                          $event.key,
                                          undefined
                                        )
                                      ) {
                                        return null
                                      }
                                      _vm.splitAddresses($event)
                                    },
                                    click: function($event) {
                                      _vm.splitAddresses($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.i18n.splitAddress.splitQuantity
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.totalAddressError
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c("span", { staticClass: "error-msg" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.i18n.splitAddress.dropdownError) +
                                  "\n          "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.totalAddressError && _vm.noPhoneNumber
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c("span", { staticClass: "error-msg" }, [
                              _vm._v(_vm._s(_vm.i18n.splitAddress.checkPhone))
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.totalAddressError && _vm.noCompanyName
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c("span", { staticClass: "error-msg" }, [
                              _vm._v(
                                _vm._s(_vm.i18n.splitAddress.checkCompanyName)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.duplicateAddressError
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c("span", { staticClass: "error-msg" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.i18n.splitAddress.duplicateAddressError
                                  ) +
                                  "\n          "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.quantityError
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c("span", { staticClass: "error-msg" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.i18n.splitAddress.quantityError) +
                                  "\n          "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.fullySplitError
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c("span", { staticClass: "error-msg" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.i18n.splitAddress.fullySplitError
                                  ) +
                                  "\n          "
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  })
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isSplit
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.splitEntry, function(item, index) {
                      return _c("div", { key: item }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "hidden-xs section-header pt-xs-4 pb-xs-1"
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.i18n.splitAddress.quantity) +
                                  "\n          "
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "address" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.i18n.splitAddress.address) +
                                  "\n          "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: _vm.isMobile()
                              ? "address-input-container"
                              : "d-flex"
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isMobile(),
                                        expression: "isMobile()"
                                      }
                                    ],
                                    staticClass: "quantity pt-xs-4 pb-xs-1"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.i18n.splitAddress.quantity) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("vx-stepper-control", {
                                  ref: "stepperController",
                                  refInFor: true,
                                  staticClass: "stepper-control",
                                  attrs: { "min-value": 0, value: item.qty },
                                  on: {
                                    currentCount: function($event) {
                                      _vm.handleQuantity(item, index, $event)
                                    },
                                    spinnerShow: _vm.showSpinnerOnUpdate,
                                    spinnerHide: _vm.hideSpinnerOnUpdate
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "address-dropdown-container mb-xs-4"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isMobile(),
                                        expression: "isMobile()"
                                      }
                                    ],
                                    staticClass: "address pt-xs-4 pb-xs-1"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.i18n.splitAddress.address)
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("vx-dropdown-primary", {
                                      ref: "shippingAddressDropdown",
                                      refInFor: true,
                                      staticClass: "pl-sm-4 address-dropdown",
                                      attrs: {
                                        index: index,
                                        dropdownValues: _vm.addresses,
                                        "aria-label":
                                          _vm.i18n.splitAddress.address
                                      },
                                      on: {
                                        "selected-item": function($event) {
                                          _vm.handleAddressChange($event, index)
                                        },
                                        "selected-index": function($event) {
                                          _vm.indexSelected = $event
                                        },
                                        "edit-selected-address": function(
                                          $event
                                        ) {
                                          _vm.editAddress($event, index)
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "pl-xs-4 icon-trash",
                                      attrs: {
                                        role: "button",
                                        tabindex: "0",
                                        "aria-label":
                                          _vm.i18n.splitAddress.iconTrashTitle,
                                        title:
                                          _vm.i18n.splitAddress.iconTrashTitle
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.deleteSplitRow(index, $event)
                                        },
                                        keyup: function($event) {
                                          if (
                                            !("button" in $event) &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter-space",
                                              undefined,
                                              $event.key,
                                              undefined
                                            )
                                          ) {
                                            return null
                                          }
                                          _vm.deleteSplitRow(index, $event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    }),
                    _vm._v(" "),
                    !_vm.totalQuantityError
                      ? _c("div", { staticClass: "ship-to-another-address" }, [
                          _c(
                            "span",
                            {
                              attrs: { role: "button", tabindex: "0" },
                              on: {
                                click: function($event) {
                                  _vm.splitTheQuantity($event)
                                },
                                keyup: function($event) {
                                  if (
                                    !("button" in $event) &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter-space",
                                      undefined,
                                      $event.key,
                                      undefined
                                    )
                                  ) {
                                    return null
                                  }
                                  _vm.splitTheQuantity($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.i18n.splitAddress.shipToAnotherAddress
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.totalAddressError
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.splitAddress.dropdownError) +
                                "\n        "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.totalAddressError && _vm.noPhoneNumber
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(_vm._s(_vm.i18n.splitAddress.checkPhone))
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.totalAddressError && _vm.noCompanyName
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(
                              _vm._s(_vm.i18n.splitAddress.checkCompanyName)
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.duplicateAddressError
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.i18n.splitAddress.duplicateAddressError
                                ) +
                                "\n        "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.quantityError
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.splitAddress.quantityError) +
                                "\n        "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.fullySplitError
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.splitAddress.fullySplitError) +
                                "\n        "
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.splitApplicable
              ? _c(
                  "div",
                  { staticClass: "align-dropdown" },
                  [
                    _c("div", { staticClass: "mb-xs-2" }, [
                      _c("span", { staticClass: "label-text" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.i18n.splitAddress.address) +
                            "\n        "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("vx-dropdown-primary", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      ref: "shippingAddressDropdown",
                      attrs: {
                        "required-aria": "true",
                        dropdownValues: _vm.addresses,
                        "aria-label": _vm.i18n.splitAddress.address
                      },
                      on: {
                        "selected-item": function($event) {
                          _vm.selectedAddress = $event
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.totalAddressError
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.splitAddress.dropdownError) +
                                "\n        "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.totalAddressError && _vm.noPhoneNumber
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(_vm._s(_vm.i18n.splitAddress.checkPhone))
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.totalAddressError && _vm.noCompanyName
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(
                              _vm._s(_vm.i18n.splitAddress.checkCompanyName)
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.duplicateAddressError
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.i18n.splitAddress.duplicateAddressError
                                ) +
                                "\n        "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.quantityError
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.splitAddress.quantityError) +
                                "\n        "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.fullySplitError
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c("span", { staticClass: "error-msg" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.splitAddress.fullySplitError) +
                                "\n        "
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-18365d0b", { render: render, staticRenderFns: staticRenderFns })
  }
}