var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "view-site-content-vx-marketing-promo d-flex justify-content-center py-xs-2 px-xs-3",
      style: [_vm.backgroundStyle],
      attrs: {
        id: _vm.promotionData.componentId,
        "aria-label": [_vm.backgroundAlt]
      }
    },
    [
      !_vm.promotionData.url
        ? _c("h5", {
            staticClass: "promo-header",
            style: [_vm.fontStyle],
            domProps: { innerHTML: _vm._s(_vm.promotionData.header) }
          })
        : _c(
            "a",
            {
              attrs: {
                href: _vm.promotionData.isExternalLink
                  ? _vm.promotionData.url
                  : _vm.globals.getUrlWithContextPath(_vm.promotionData.url),
                target: _vm.promotionData.isExternalLink ? "_blank" : ""
              }
            },
            [
              _c("h5", {
                staticClass: "promo-header",
                style: [_vm.fontStyle],
                domProps: { innerHTML: _vm._s(_vm.promotionData.header) }
              })
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-feeb9a0a", { render: render, staticRenderFns: staticRenderFns })
  }
}