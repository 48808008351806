/* eslint-disable no-underscore-dangle */
/* global liveagent */

/**
 * live-chat-mixin initializes the live agent and provides the method for triggering the chat on click of button.
 */

const liveChatMixin = {
  created() {
    // Initializing live chat
    liveagent.init(
      this.liveAgentConfig.endPoint,
      this.liveAgentConfig.orgId,
      this.liveAgentConfig.deploymentId,
    );
  },
  mounted() {
    const self = this;
    if (!window._laq) {
      window._laq = [];
    }
    window._laq.push(() => {
      liveagent.showWhenOnline(
        self.liveAgentConfig.buttonId,
        self.$refs.chatOnline,
      );
      liveagent.showWhenOffline(
        self.liveAgentConfig.buttonId,
        self.$refs.chatOffline,
      );
    });
    // Pre populating the fields in the chat window for logged in user
    if (this.globals.getIsLoggedIn()) {
      liveagent.addCustomDetail('UserFirstName', this.globals.userInfo.firstName);
      liveagent.addCustomDetail('UserLastName', this.globals.userInfo.lastName);
      liveagent.addCustomDetail('UserEmail', this.globals.userInfo.email);
      liveagent.addCustomDetail('UserCompany', this.globals.userInfo.unit);
      liveagent.addCustomDetail('UserPhone', this.globals.userInfo.contactNumber);
    }
  },
  methods: {
    /**
     * Triggers the salesforce chat window
     * @param {*} event
     */
    triggerChat() {
      liveagent.startChat(this.liveAgentConfig.buttonId);
    },
  },
};

export default liveChatMixin;
