export default {
  name: 'vx-password-check-list',
  props: {
    password: String,
    i18n: {
      type: Object,
      default: {
        characterCountValidationMessage: '',
        upperCaseValidationMessage: '',
        numberValidationMessage: '',
        specialCharacterValidationMessage: '',
        specialCharacterList: '',
      },
    },
  },
  watch: {
    password() {
      this.characterCheckClass = this.checkPasswordCharacters() ? 'success-msg' : 'bullet checklist-error-msg';
      this.caseCheckClass = this.checkPasswordCase() ? 'success-msg' : 'bullet checklist-error-msg';
      this.numberCheckClass = this.checkPasswordNumbers() ? 'success-msg' : 'bullet checklist-error-msg';
      this.specialCharacterChaeckClass = this.checkPasswordSpecialChars() ? 'success-msg' : 'bullet checklist-error-msg';
    },
  },
  data() {
    return ({
      passwordLengthRegex: /^\S{6,}$/,
      passwordAlphabetRegex: /[A-Z]+/,
      passwordNumberRegex: /\d+/,
      passwordSpecialCharacterRegex: /[!@#$%^*()_\-+{};:.,]+/,
      isFieldDirty: false,
      characterCheckClass: 'bullet plain-msg',
      caseCheckClass: 'bullet plain-msg',
      numberCheckClass: 'bullet plain-msg',
      specialCharacterChaeckClass: 'bullet plain-msg',
    });
  },
  methods: {
    checkPasswordCharacters() {
      return this.passwordLengthRegex.test(this.password);
    },
    checkPasswordCase() {
      return this.passwordAlphabetRegex.test(this.password);
    },
    checkPasswordNumbers() {
      return this.passwordNumberRegex.test(this.password);
    },
    checkPasswordSpecialChars() {
      return this.passwordSpecialCharacterRegex.test(this.password);
    },
  },
};
