import CommonService from '../services/common-service';
import vxSpinner from '../vx-spinner/vx-spinner.vue';
import globals from '../globals';

export default {
  name: 'vx-terms-condition',
  components: {
    vxSpinner,
  },
  props: [],
  data() {
    return {
      commonService: new CommonService(),
      termsConditionData: '',
      globals,
    };
  },
  computed: {

  },
  mounted() {
    this.$refs.spinner.showSpinner();
    this.commonService.getTermsAndCondition({}, this.handleTermsConditionResponse, this.handleTermsConditionError);
  },
  methods: {
    /**
     * Terms and Consition data is taken from response and shown in the view
     * @param {object} response
     */
    handleTermsConditionResponse(response) {
      this.$refs.spinner.hideSpinner();
      const { status, data } = response;
      if (status) {
        this.termsConditionData = data;
      }
    },
    /**
     * On Error of terms and condition call, Hide the spinner
     * @param {object} error
     */
    handleTermsConditionError() {
      this.$refs.spinner.hideSpinner();
    },
  },
};
