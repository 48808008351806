/**
 * Related Products Tab is one of the tabs on the Product Detail Page
 * Consists of methods required to format the product response
 */
import vxProductTileVertical from '../../search-browse/vx-product-tile-vertical/vx-product-tile-vertical.vue';
import globals from '../../../../components/common/globals';
import PdpService from '../../../../components/common/services/pdp-service';
import {
  referenceTypes,
  relatedCategories,
} from '../../../../components/common/mixins/vx-enums';
import {
  eventBus,
} from '../../../../modules/event-bus';

export default {
  name: 'vx-related-products-tab',
  components: {
    vxProductTileVertical,
  },
  props: {
    // parent product code
    parentProduct: {
      type: String,
      default: '',
    },
    // Copy text coming from properties files
    i18n: {
      type: Object,
    },
    // indicates whether the site is configured for favorites
    isFavorites: {
      type: Boolean,
      default: false,
    },
    // indicates whether the site is configured for Bazaar Voice
    isBazaarVoice: {
      type: String,
      default: '',
    },
    // indicates whether the related products are enabled for the product
    isRelatedEnabled: {
      type: Boolean,
      default: false,
    },
    // Inherit variables to pass to child elements
    showCompareOption: {
      type: Boolean,
    },
    showRemoveOption: {
      type: Boolean,
    },
    uncheckProduct: {
      type: Boolean,
    },
  },
  data() {
    return {
      globals,
      relatedProductsData: {},
      pdpService: new PdpService(),
      relatedProducts: [],
      referenceTypes,
      relatedCategories,
    };
  },
  computed: {
    relatedProductsOrderedData() {
      let filteredRelatedProductsData = this.relatedProductsData;
      if (filteredRelatedProductsData.length > 0) {
        filteredRelatedProductsData = this.relatedProductsData.filter(
          (product) => relatedCategories.hasOwnProperty(product.references[0].referenceType),
        );
        if (!filteredRelatedProductsData.length) {
          eventBus.$emit('noRelatedProducts', true);
        }
      }

      if (filteredRelatedProductsData.length > 1) {
        filteredRelatedProductsData.sort((a) => {
          if (a.referenceType.toUpperCase() === 'REFILLS') {
            return -1;
          }

          return 1;
        });
        return filteredRelatedProductsData;
      }

      return filteredRelatedProductsData;
    },
  },
  mounted() {
    this.onInitCheck();
  },
  updated() {},
  methods: {
    /**
         * Function is called on mounted which passes parent product code
         */
    onInitCheck() {
      const self = this;
      self.generateUrlRequest(self.parentProduct);
    },
    /**
         * Function handles the response of related products service
         */
    handleRelatedProductsResponse(response) {
      if (response && response.data) {
        this.relatedProductsData = response.data;
        if (this.relatedProductsData.length) {
          if (!this.isRelatedEnabled) {
            this.relatedProducts = this.relatedProductsData.references.map((item) => item.target);
          } else {
            let showRelatedProductsTab = false;
            this.relatedProductsData.forEach((subcategory) => {
              if (relatedCategories.hasOwnProperty(subcategory.referenceType)) {
                const relatedCategory = relatedCategories[subcategory.referenceType];
                subcategory.referenceType = relatedCategory;
              }
              if (subcategory.referenceType === 'FREQUENTLYBOUGHTTOGETHER') {
                eventBus.$emit('frequentlyBoughtTogether', subcategory);
              }
              if (subcategory.referenceType === 'COMPARE_PRODUCTS') {
                eventBus.$emit('compareProducts', subcategory);
              }
              if (subcategory.referenceType !== '"FREQUENTLYBOUGHTTOGETHER"' && subcategory.referenceType !== 'COMPARE_PRODUCTS') {
                showRelatedProductsTab = true;
              }
            });
            if (showRelatedProductsTab === false) {
              eventBus.$emit('noRelatedProducts', true);
            }
          }
        } else {
          eventBus.$emit('noRelatedProducts', true);
        }
      }
    },
    /**
         * Function handles the error of related products service
         */
    handleRelatedProductsError() {},
    /**
         * Function function to generate compare result url
         */
    generateUrlRequest(productId) {
      const self = this;
      const requestConfig = {};
      if (!this.isRelatedEnabled) {
        if (this.globals.loggedIn) {
          const userId = this.globals.uid;
          requestConfig.params = {
            referenceType: this.referenceTypes.similar,
            userId,
          };
        } else {
          requestConfig.params = {
            referenceType: this.referenceTypes.similar,
          };
        }
        self.pdpService.getProductsData(requestConfig, this.handleRelatedProductsResponse, this.handleRelatedProductsError, productId);
      } else {
        self.pdpService.getRelatedProductsData(requestConfig, this.handleRelatedProductsResponse, this.handleRelatedProductsError, productId);
      }
    },
  },
};
