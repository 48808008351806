var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-marketing-product" }, [
    _vm.tabBodyTitle
      ? _c("h3", {
          staticClass: "col-md-offset-4 hidden-xs hidden-sm pl-xs-3",
          domProps: { innerHTML: _vm._s(_vm.tabBodyTitle) }
        })
      : _vm._e(),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "col-md-4 hidden-xs hidden-sm px-md-4" }, [
        _c(
          "div",
          {
            staticClass:
              "vx-marketing-product__product-info-image p-xs-3 d-flex align-items-center justify-content-center"
          },
          [
            _c("img", {
              attrs: {
                src: _vm.descriptiveData.descriptiveSrc,
                alt: _vm.descriptiveData.descriptiveText
              }
            })
          ]
        ),
        _vm._v(" "),
        _vm.tileText
          ? _c("p", {
              staticClass: "mt-xs-3 mb-xs-0 promotional-text",
              domProps: { innerHTML: _vm._s(_vm.tileText) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.descriptiveData.header
          ? _c("p", {
              staticClass: "my-xs-3",
              domProps: { innerHTML: _vm._s(_vm.descriptiveData.header) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-wrap justify-content-center" }, [
          _vm.tileCtaData.ctaText && !_vm.tileCtaData.jumpToHtml
            ? _c("a", {
                staticClass: "btn btn-tertiary mb-sm-3 product-cta",
                attrs: {
                  href: _vm.tileCtaData.isExternalLink
                    ? _vm.tileCtaData.ctaLink
                    : _vm.globals.getUrlWithContextPath(
                        _vm.tileCtaData.ctaLink
                      ),
                  target: _vm.tileCtaData.isExternalLink ? "_blank" : ""
                },
                domProps: { innerHTML: _vm._s(_vm.tileCtaData.ctaText) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.tileCtaData.ctaText && _vm.tileCtaData.jumpToHtml
            ? _c("a", {
                staticClass: "btn btn-tertiary mb-sm-3 product-cta",
                attrs: {
                  href: _vm.tileCtaData.isExternalLink
                    ? _vm.tileCtaData.ctaLink
                    : _vm.globals.getUrlWithContextPath(
                        _vm.tileCtaData.ctaLink
                      ),
                  target: _vm.tileCtaData.isExternalLink ? "_blank" : ""
                },
                domProps: { innerHTML: _vm._s(_vm.tileCtaData.ctaText) },
                on: {
                  click: function($event) {
                    _vm.emitScrollSignal(_vm.tileCtaData.ctaLink)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.requestTrialText
            ? _c("a", {
                staticClass: "btn btn-tertiary product-cta mb-sm-3",
                attrs: {
                  href: _vm.isRequestTrialLinkExternal
                    ? _vm.requestTrialLink
                    : _vm.globals.getUrlWithContextPath(_vm.requestTrialLink),
                  target: _vm.isRequestTrialLinkExternal ? "_blank" : ""
                },
                domProps: { innerHTML: _vm._s(_vm.requestTrialText) }
              })
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-md-8 col-xs-12 px-xs-0 pt-md-0 position-xs-relative vx-marketing-product__product-wrapper"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-xs-column flex-sm-row flex-wrap w-sm-100"
            },
            _vm._l(_vm.tabData, function(prd) {
              return _c(
                "div",
                { staticClass: "col-xs-12 col-sm-4 px-xs-0 px-sm-3 mb-xs-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-marketing-product__product d-flex flex-column mx-xs-3 mx-sm-0",
                      on: {
                        click: function($event) {
                          _vm.loadDescriptiveImage(prd)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-marketing-product__image-container p-xs-2 d-flex justify-content-center",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: prd.secondaryImgD
                                ? prd.secondaryImgD
                                : _vm.globals.assetsPath +
                                  "images/no_image.svg",
                              alt: prd.secondaryimgAltTextD
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      prd.tileText
                        ? _c("p", {
                            staticClass: "mt-xs-3 mb-xs-0 promotional-text",
                            domProps: { innerHTML: _vm._s(prd.tileText) }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      prd.header
                        ? _c("a", {
                            staticClass: "my-xs-3",
                            attrs: { href: "javascript:void(0)" },
                            domProps: { innerHTML: _vm._s(prd.header) },
                            on: {
                              click: function($event) {
                                _vm.loadDescriptiveImage(prd)
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              )
            })
          ),
          _vm._v(" "),
          _vm.brochureText
            ? _c("a", {
                staticClass:
                  "btn-tertiary btn position-xs-absolute broucher-btn",
                attrs: {
                  href: _vm.isBrochureLinkExternal
                    ? _vm.broucherLink
                    : _vm.globals.getUrlWithContextPath(_vm.broucherLink),
                  target: _vm.isBrochureLinkExternal ? "_blank" : ""
                },
                domProps: { innerHTML: _vm._s(_vm.brochureText) }
              })
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bfd33f0a", { render: render, staticRenderFns: staticRenderFns })
  }
}