var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-store-location" }, [
    _vm.selectedStoreAvailable
      ? _c(
          "div",
          { staticClass: "col-xs-12 col-sm-6 hidden-xs" },
          [
            _c("div", { staticClass: "d-flex selected-store" }, [
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c("img", {
                  attrs: {
                    src: _vm.selectedStore.img
                      ? _vm.selectedStore.img
                      : _vm.globals.assetsPath + "images/no_image.svg",
                    alt: _vm.selectedStore.NAME
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pl-sm-4" },
                [
                  _vm.selectedStore.URL != ""
                    ? [
                        _c(
                          "a",
                          {
                            staticClass: "store-name",
                            attrs: {
                              href: _vm.selectedStore.URL,
                              target: "_blank",
                              "aria-label":
                                "Distributor " + _vm.selectedStore.NAME
                            }
                          },
                          [_vm._v(_vm._s(_vm.selectedStore.NAME))]
                        )
                      ]
                    : [
                        _c(
                          "span",
                          {
                            staticClass: "store-name",
                            attrs: {
                              "aria-label":
                                "Distributor " + _vm.selectedStore.NAME
                            }
                          },
                          [_vm._v(_vm._s(_vm.selectedStore.NAME))]
                        )
                      ],
                  _vm._v(" "),
                  _c("div", { staticClass: "store-info mt-sm-3 mb-sm-2" }, [
                    _vm._v(_vm._s(_vm.selectedStore.ADDRESS))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "store-info mb-sm-2" },
                    [
                      _vm._v(_vm._s(_vm.selectedStore.CITY) + ","),
                      _vm.selectedStore.STATE
                        ? [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.selectedStore.STATE) +
                                ","
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.selectedStore.ZIP))
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.selectedStore.PHONE != undefined
                    ? _c(
                        "a",
                        {
                          staticClass: "store-info",
                          attrs: {
                            "aria-label":
                              "Contact number " + _vm.selectedStore.PHONE,
                            href: "tel:" + _vm.selectedStore.PHONE
                          }
                        },
                        [_vm._v(_vm._s(_vm.selectedStore.PHONE))]
                      )
                    : _vm._e()
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("vx-map-locator", {
              ref: "storemap",
              on: {
                mapLoaded: function($event) {
                  _vm.mapLoaded()
                },
                markerClick: function($event) {
                  _vm.desktopMapNavigation()
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-14945e91", { render: render, staticRenderFns: staticRenderFns })
  }
}