var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "company-details" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addressVerificationModal",
          attrs: {
            size: "medium",
            heading: _vm.i18n.addressVerificationHeading
          }
        },
        [
          _c("vx-address-verification", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n,
              unverifiedAddressProp: _vm.unverifiedAddress
            },
            on: {
              selectedAddress: _vm.handleSelectedAddressResponse,
              error: _vm.handleSelectedAddressError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("form", { staticClass: "companyDetailsForm" }, [
        _c(
          "h3",
          { staticClass: "company-details-heading mt-xs-0 pb-sm-5 mb-xs-0" },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.i18n.companyDetailsDataModalHeading) +
                "\n        "
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "default-company-details" }, [
          _c("div", { staticClass: "section row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-6 col-sm-6 col-xs-12 " },
              [
                _c("label", { staticClass: "pt-sm-0 pt-xs-4" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.i18n.companyName) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.companyDetails.companyName,
                      expression: "companyDetails.companyName"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:40",
                      expression: "'required|max:40'"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("companyName") },
                  attrs: {
                    name: "companyName",
                    type: "text",
                    maxlength: "40",
                    "aria-label": _vm.i18n.companyName
                  },
                  domProps: { value: _vm.companyDetails.companyName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.companyDetails,
                        "companyName",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("companyName")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.errors.first("companyName")) +
                            "\n                        "
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group col-md-6 col-sm-6 col-xs-12 country" },
              [
                _c("label", { staticClass: "pt-sm-0 pt-xs-4" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.i18n.countryLabel) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("vx-dropdown-primary", {
                  ref: "countryDropdown",
                  attrs: {
                    "aria-label": _vm.i18n.countryLabel,
                    dropdownValues: _vm.companyDetails.country.options
                  },
                  on: {
                    "selected-option": function($event) {
                      _vm.setDropdownOption($event)
                    }
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-6 col-sm-6 col-xs-12" },
              [
                _c(
                  "label",
                  {
                    staticClass: "pt-sm-0 pt-xs-4",
                    attrs: { "aria-hidden": _vm.isDeviceAndroid() }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.i18n.addressPart1) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.companyDetails.Address1,
                      expression: "companyDetails.Address1"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:60",
                      expression: "'required|max:60'"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("addressLine1") },
                  attrs: {
                    type: "text",
                    maxlength: "60",
                    name: "addressLine1",
                    "aria-label": _vm.i18n.addressPart1
                  },
                  domProps: { value: _vm.companyDetails.Address1 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.companyDetails,
                        "Address1",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("addressLine1")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.errors.first("addressLine1")) +
                            "\n                        "
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group col-md-6 col-sm-6 col-xs-12 " },
              [
                _c(
                  "label",
                  {
                    staticClass: "pt-sm-0 pt-xs-4",
                    attrs: { "aria-hidden": _vm.isDeviceAndroid() }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.i18n.addressPart2) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.companyDetails.Address2,
                      expression: "companyDetails.Address2"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:40",
                      expression: "'max:40'"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    maxlength: "40",
                    name: "addressLine2",
                    "aria-label": _vm.i18n.addressPart2
                  },
                  domProps: { value: _vm.companyDetails.Address2 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.companyDetails,
                        "Address2",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-6 col-sm-6 col-xs-12 " },
              [
                _c(
                  "label",
                  {
                    staticClass: "pt-sm-0 pt-xs-4",
                    attrs: { "aria-hidden": !_vm.isDeviceAndroid() }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.i18n.city) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.companyDetails.City,
                      expression: "companyDetails.City"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:40",
                      expression: "'required|max:40'"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("city") },
                  attrs: {
                    name: "city",
                    type: "text",
                    maxlength: "40",
                    "aria-label": _vm.i18n.city
                  },
                  domProps: { value: _vm.companyDetails.City },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.companyDetails, "City", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("city")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.errors.first("city")) +
                            "\n                        "
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group col-md-6 col-sm-6 col-xs-12" },
              [
                _c("label", { staticClass: "pt-sm-0 pt-xs-4" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.companyDetails.region.label) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("vx-dropdown-primary", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  ref: "stateDropdown",
                  attrs: {
                    "describedby-aria": _vm.errors.has("state")
                      ? "company_state_error_msg"
                      : null,
                    "required-aria": "true",
                    dropdownValues: _vm.companyDetails.region.options,
                    name: "state",
                    "aria-label": _vm.companyDetails.region.label,
                    dropdownError: _vm.errors.has("state")
                  },
                  on: {
                    "selected-option": function($event) {
                      _vm.companyDetails.stateDropdownOption = $event
                    }
                  },
                  model: {
                    value: _vm.companyDetails.stateDropdownOption.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.companyDetails.stateDropdownOption,
                        "value",
                        $$v
                      )
                    },
                    expression: "companyDetails.stateDropdownOption.value"
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("state")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "assertive",
                            id: "company_state_error_msg"
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.errors.first("state")) +
                              "\n                        "
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-6 col-sm-6 col-xs-12" },
              [
                _c(
                  "label",
                  {
                    staticClass: "pt-sm-0 pt-xs-4",
                    attrs: { "aria-hidden": !_vm.isDeviceAndroid() }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.i18n.zipcode) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.companyDetails.Zipcode,
                      expression: "companyDetails.Zipcode"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        regex: /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/
                      },
                      expression:
                        "{ required: true, regex: /^((\\d{5}-\\d{4})|(\\d{5})|([A-Z]\\d[A-Z]\\s\\d[A-Z]\\d))$/ }"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("zipcode") },
                  attrs: {
                    name: "zipcode",
                    type: "text",
                    "aria-label": _vm.i18n.zipcode
                  },
                  domProps: { value: _vm.companyDetails.Zipcode },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.companyDetails,
                        "Zipcode",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("zipcode")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(_vm._s(_vm.errors.first("zipcode")))
                      ])
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group col-md-6 col-sm-6 col-xs-12" },
              [
                _c(
                  "label",
                  {
                    staticClass: "pt-sm-0 pt-xs-4",
                    attrs: { "aria-hidden": _vm.isDeviceAndroid() }
                  },
                  [_vm._v(_vm._s(_vm.i18n.cmpPhnNo))]
                ),
                _vm._v(" "),
                _c("the-mask", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required: true|min:15",
                      expression: "'required: true|min:15'"
                    }
                  ],
                  staticClass: "phone-number form-control",
                  attrs: {
                    masked: _vm.masked,
                    mask: "+1 ###-###-####",
                    type: "tel",
                    name: "companyPhnNo",
                    "aria-label": _vm.i18n.mobileNo
                  },
                  model: {
                    value: _vm.companyDetails.CompanyPhnNo,
                    callback: function($$v) {
                      _vm.$set(_vm.companyDetails, "CompanyPhnNo", $$v)
                    },
                    expression: "companyDetails.CompanyPhnNo"
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("companyPhnNo")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(_vm._s(_vm.errors.first("companyPhnNo")))
                      ])
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "business-description" }, [
          _c(
            "div",
            { staticClass: "section pt-sm-5 pt-xs-4 row" },
            _vm._l(_vm.surveyQuestions, function(item, index) {
              return _c(
                "div",
                {
                  staticClass:
                    "col-sm-12 col-md-12 col-xs-12 mb-xs-0 form-group buisiness-group-sub"
                },
                [
                  item.questionType === _vm.surveyQuestionType.select
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-sm-5 col-xs-12 px-xs-0 pt-sm-5 pt-xs-4 "
                        },
                        [
                          _c("label", [_vm._v(_vm._s(item.label))]),
                          _vm._v(" "),
                          _c("vx-dropdown-primary", {
                            ref: "surveySelectQuestion",
                            refInFor: true,
                            attrs: { dropdownValues: item.options },
                            on: {
                              "selected-item": function($event) {
                                _vm.onSelectedBusinessGroup($event, item, index)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.questionType === _vm.surveyQuestionType.radio
                    ? _c(
                        "div",
                        { staticClass: "col-sm-5 pt-sm-5 pt-xs-4" },
                        [
                          _c("label", [_vm._v(_vm._s(item.label))]),
                          _vm._v(" "),
                          _c("vx-radio-button-group", {
                            ref: "surveyRadioQuestion",
                            refInFor: true,
                            attrs: {
                              name: index,
                              radiobuttonValues: item.options
                            },
                            on: {
                              "selected-option": function($event) {
                                _vm.onSelectedBusinessGroup($event, item, index)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.questionType === _vm.surveyQuestionType.textField
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "form-group col-sm-5 pt-sm-5 pt-xs-4 px-xs-0"
                        },
                        [
                          _c("label", [_vm._v(_vm._s(item.label))]),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.textField[item.code] },
                            on: {
                              change: function($event) {
                                _vm.onSelectedBusinessGroup($event, item, index)
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ]
              )
            })
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "company-details-button pt-xs-5 py-sm-5 col-xs-12 px-xs-0"
          },
          [
            _c(
              "button",
              {
                staticClass: "save-button form-blue-button btn-md mb-xs-0",
                attrs: { type: "button" },
                on: { click: _vm.callAddressVerification }
              },
              [_vm._v(_vm._s(_vm.i18n.save) + "\n            ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "skip-button form-blue-button btn-md mb-xs-0",
                attrs: { type: "button" },
                on: { click: _vm.skipCompanyDetails }
              },
              [_vm._v(_vm._s(_vm.i18n.skip) + "\n            ")]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-26fc123a", { render: render, staticRenderFns: staticRenderFns })
  }
}