var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "vx-thumbnail-viewer row d-flex" }, [
    _c(
      "div",
      { staticClass: "col-sm-12 pr-sm-0 d-flex flex-column image-width" },
      [
        _c(
          "vx-modal",
          {
            ref: "pdpImageModal",
            attrs: { size: "large", heading: _vm.thumbnailObj.name }
          },
          [
            _c(
              "div",
              {
                staticClass: "text-center",
                attrs: { slot: "component" },
                slot: "component"
              },
              [
                _c("img", {
                  staticClass:
                    "px-sm-2 py-sm-3 px-md-5 py-md-6 selected-product-img",
                  attrs: {
                    alt: _vm.selectedItem.altText,
                    src:
                      _vm.selectedItem.zoomUrl ||
                      _vm.globals.assetsPath + "images/no_image.svg"
                  }
                }),
                _vm._v(" "),
                _vm.selectedItem.mimeType &&
                _vm.selectedItem.mimeType.indexOf("video") >= 0
                  ? _c("video", {
                      attrs: {
                        controls: "",
                        src: _vm.selectedItem.thumbnailUrl
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "thumbnail-container-modal" }, [
                  _c(
                    "div",
                    {
                      class:
                        "slider" +
                        (_vm.isSlidingToPrevious ? " sliding-to-previous" : "")
                    },
                    [
                      _c(
                        "transition-group",
                        { attrs: { name: "list", tag: "ul" } },
                        [
                          _c("button", {
                            key: "left",
                            staticClass: "btn-top icon-chevron-left list-item",
                            attrs: {
                              disabled: _vm.currentTopIndex === 0,
                              "aria-label": _vm.i18n.moreImages
                            },
                            on: { click: _vm.moveTop }
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.currentCarouselData, function(
                            item,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticClass: "list-item p-xs-1 mx-xs-1",
                                class: {
                                  noKaonUrl:
                                    item.kaonUrl &&
                                    !_vm.globals.siteConfig.is3DImageEnabled
                                },
                                attrs: { tabindex: "0" },
                                on: {
                                  click: function($event) {
                                    _vm.updatePicture(item)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.updatePicture(item)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: item.thumbnailUrl,
                                    alt: item.altText
                                  }
                                }),
                                _vm._v(" "),
                                item.externalMediaUrl
                                  ? _c("span", { staticClass: "icon-play" })
                                  : _vm._e()
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("button", {
                            key: "right",
                            staticClass:
                              "btn-right icon-chevron-right list-item",
                            attrs: {
                              disabled:
                                _vm.currentBottomIndex === _vm.totalCount ||
                                _vm.totalCount < _vm.itemsToDisplay,
                              "aria-label": _vm.i18n.moreImages
                            },
                            on: { click: _vm.moveBottom }
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row hidden-xs d-flex thumbnail-wrapper" }, [
          _c("div", { staticClass: "col-sm-3 col-md-2 thumbnail-container" }, [
            _c("button", {
              staticClass: "btn-top icon-chevron-up",
              attrs: {
                disabled: _vm.currentTopIndex === 0,
                "aria-label": _vm.i18n.moreImages
              },
              on: { click: _vm.moveTop }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                class:
                  "slider" +
                  (_vm.isSlidingToPrevious ? " sliding-to-previous" : "")
              },
              [
                _c(
                  "transition-group",
                  { attrs: { name: "list", tag: "ul" } },
                  _vm._l(_vm.currentCarouselData, function(item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "list-item px-sm-2",
                        class: {
                          noKaonUrl:
                            item.kaonUrl &&
                            !_vm.globals.siteConfig.is3DImageEnabled
                        },
                        attrs: { tabindex: "0" },
                        on: {
                          click: function($event) {
                            _vm.updatePicture(item)
                          },
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter-space",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            _vm.updatePicture(item)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: item.thumbnailUrl, alt: item.altText }
                        }),
                        _vm._v(" "),
                        item.externalMediaUrl
                          ? _c("span", { staticClass: "icon-play" })
                          : _vm._e()
                      ]
                    )
                  })
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn-down icon-chevron-down",
              attrs: {
                disabled:
                  _vm.currentBottomIndex === _vm.totalCount ||
                  _vm.totalCount < _vm.itemsToDisplay,
                "aria-label": _vm.i18n.moreImages
              },
              on: { click: _vm.moveBottom }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-sm-9 col-md-10 px-xs-0 product-image-container"
            },
            [
              _vm.thumbnailObj && _vm.thumbnailObj.hasOwnProperty("promoText")
                ? _c(
                    "p",
                    {
                      staticClass:
                        "info-text promo-text px-xs-1 py-md-2 py-xs-1 px-md-2"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.thumbnailObj.promoText) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.thumbnailObj.materialStatus === "COMING_SOON"
                ? _c(
                    "p",
                    {
                      staticClass:
                        "info-text coming-soon px-xs-1 py-md-2 py-xs-1 px-md-2"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.comingSoon) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isResponseHandled
                ? [
                    !_vm.selectedItem
                      ? _c("img", {
                          staticClass: "p-sm-5 p-md-8 selected-product-img",
                          attrs: {
                            alt: "No Image",
                            src: _vm.globals.assetsPath + "images/no_image.svg"
                          }
                        })
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedItem.kaonUrl &&
              _vm.globals.siteConfig.is3DImageEnabled
                ? _c("iframe", {
                    staticClass: "selected-product-iframe",
                    attrs: {
                      src: _vm.selectedItem.kaonUrl,
                      width: "100%",
                      height: _vm.iFrameHeight,
                      frameborder: "0",
                      allowfullscreen: ""
                    }
                  })
                : [
                    !_vm.selectedItem.kaonUrl &&
                    _vm.selectedItem.mimeType &&
                    _vm.selectedItem.mimeType.indexOf("image") >= 0 &&
                    !_vm.playButtonClicked
                      ? _c("img", {
                          staticClass: "p-sm-5 p-md-8 selected-product-img",
                          attrs: {
                            alt: _vm.selectedItem.altText,
                            src:
                              _vm.selectedItem.zoomUrl ||
                              _vm.globals.assetsPath + "images/no_image.svg",
                            "data-zoom":
                              _vm.selectedItem.largeImage ||
                              _vm.globals.assetsPath + "images/no_image.svg"
                          },
                          on: {
                            load: function($event) {
                              if (!_vm.driftEnabled) {
                                _vm.enableDriftZoom("desktop")
                              }
                            }
                          }
                        })
                      : _vm._e()
                  ],
              _vm._v(" "),
              _vm.selectedItem.mimeType &&
              _vm.selectedItem.mimeType.indexOf("video") >= 0
                ? _c("video", {
                    attrs: { controls: "", src: _vm.selectedItem.thumbnailUrl }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.playButtonClicked
                ? _c("div", { staticClass: "video-player" }, [
                    _c("iframe", {
                      attrs: {
                        width: "100%",
                        height: "100%",
                        src: _vm.selectedItem.externalMediaUrl,
                        name: "video-player",
                        title: _vm.selectedItem.altText,
                        allowfullscreen: ""
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.selectedItem.kaonUrl && !_vm.playButtonClicked
                ? _c("span", {
                    staticClass: "icon-maximize",
                    attrs: {
                      "aria-label": _vm.i18n.iconMaximizeTitle,
                      role: "button",
                      tabindex: "0"
                    },
                    on: {
                      click: function($event) {
                        _vm.expandPDPImage($event)
                      },
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.expandPDPImage($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedItem.externalMediaUrl && !_vm.playButtonClicked
                ? _c("span", {
                    staticClass: "icon-play center-align",
                    attrs: {
                      role: "button",
                      tabindex: "0",
                      "aria-label": "play-button"
                    },
                    on: {
                      click: function($event) {
                        _vm.playButtonClicked = true
                      }
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row visible-xs-block center-block" }, [
          _c(
            "div",
            { staticClass: "col-xs-12 visible-xs-block carousel-container" },
            [
              _c("vx-banner-carousel", {
                attrs: { "carousel-data": _vm.mobileCarouselObj },
                on: {
                  "slide-changed": function($event) {
                    _vm.playButtonClicked = false
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm.thumbnailObj &&
                        _vm.thumbnailObj.hasOwnProperty("promoText")
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "info-text promo-text py-xs-1 px-xs-2"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.thumbnailObj.promoText) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.thumbnailObj &&
                        _vm.thumbnailObj.materialStatus === "COMING_SOON"
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "info-text coming-soon py-xs-1 px-xs-2"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.i18n.comingSoon) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.kaonUrl
                          ? [
                              _c("iframe", {
                                staticClass: "selected-product-iframe ml-xs-5",
                                attrs: {
                                  src:
                                    row.kaonUrl &&
                                    _vm.globals.siteConfig.is3DImageEnabled,
                                  width: "90%",
                                  height: _vm.iFrameHeight,
                                  frameborder: "0",
                                  allowfullscreen: ""
                                }
                              })
                            ]
                          : [
                              row &&
                              row.mimeType &&
                              row.mimeType.indexOf("image") >= 0 &&
                              !_vm.playButtonClicked
                                ? _c("img", {
                                    staticClass: "selected-product-img",
                                    attrs: {
                                      alt: row.altText,
                                      src:
                                        row.zoomUrl ||
                                        _vm.globals.assetsPath +
                                          "images/no_image.svg",
                                      tabindex: "-1",
                                      "data-zoom":
                                        row.largeImage ||
                                        _vm.globals.assetsPath +
                                          "images/no_image.svg"
                                    },
                                    on: {
                                      load: function($event) {
                                        _vm.enableDriftZoom("mobile")
                                      },
                                      click: function($event) {
                                        _vm.updatePicture(row),
                                          _vm.expandPDPImage($event)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                        _vm._v(" "),
                        row &&
                        row.mimeType &&
                        row.mimeType.indexOf("video") >= 0
                          ? _c("video", {
                              attrs: { controls: "", src: row.thumbnailUrl }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.playButtonClicked
                          ? _c("div", { staticClass: "video-player" }, [
                              _c("iframe", {
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  src: row.externalMediaUrl,
                                  name: "video-player",
                                  title: row.altText,
                                  allowfullscreen: ""
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.externalMediaUrl && !_vm.playButtonClicked
                          ? _c("span", {
                              staticClass: "icon-play center-align",
                              attrs: {
                                role: "button",
                                tabindex: "0",
                                "aria-label": "play-button"
                              },
                              on: {
                                click: function($event) {
                                  _vm.playButtonClicked = true
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-md-10 col-md-offset-2 col-sm-9 col-sm-offset-3 pl-md-5 pl-sm-0 pl-xs-3 py-xs-3 py-sm-0"
            },
            [
              _vm.productIconsData
                ? _c("vx-product-icons", {
                    attrs: {
                      "product-icons": _vm.productIconsData,
                      i18n: _vm.i18n.productIconsTitle
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-76629a0c", { render: render, staticRenderFns: staticRenderFns })
  }
}