var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-multi-icon desktop-wrapper mobile-box-border" },
    [
      _c("div", { staticClass: "row large-device" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6 col-sm-6" },
          [
            _c("div", { staticClass: "heading-container" }, [
              _c(
                "h1",
                {
                  staticClass: "large-heading",
                  style: {
                    "background-color": _vm.multiIconData.textBackgroundColor,
                    color: _vm.multiIconData.headerTextColor
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.multiIconData.headerText) + "\n    "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "sub-heading",
                style: { color: _vm.selectedData.subHeaderTextColor }
              },
              [_vm._v(_vm._s(_vm.selectedData.subHeaderText))]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "content",
                style: { color: _vm.selectedData.bodyTextColor }
              },
              [_vm._v(_vm._s(_vm.selectedData.bodyText))]
            ),
            _vm._v(" "),
            _vm._l(_vm.desktopIconRowData, function(iconRow, rowIndex) {
              return _c(
                "div",
                { staticClass: "button-container row pt-2" },
                _vm._l(iconRow, function(iconData, columnIndex) {
                  return _c("div", { staticClass: "col-md-4 col-sm-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "circle-icon-container",
                        class:
                          _vm.selectedIndex ===
                          _vm.computeIndex(rowIndex, columnIndex)
                            ? "active-icon"
                            : "",
                        on: {
                          click: function($event) {
                            _vm.fetchSigleData(
                              _vm.computeIndex(rowIndex, columnIndex)
                            )
                          }
                        }
                      },
                      [
                        iconData.iconImageData &&
                        iconData.iconImageData.imageSrcD
                          ? _c("img", {
                              attrs: {
                                src: iconData.iconImageData.imageSrcD,
                                title: iconData.ctaText,
                                alt: _vm.multiIconData.headerText + " icon"
                              }
                            })
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "caption-text",
                        class:
                          _vm.selectedIndex ===
                          _vm.computeIndex(rowIndex, columnIndex)
                            ? "active-text"
                            : ""
                      },
                      [_vm._v(_vm._s(iconData.ctaText))]
                    )
                  ])
                })
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mobile-device" },
        [
          _c("div", { staticClass: "heading-container" }, [
            _c(
              "h1",
              {
                staticClass: "large-heading",
                style: {
                  "background-color": _vm.multiIconData.textBackgroundColor,
                  color: _vm.multiIconData.headerTextColor
                }
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.multiIconData.headerText) + "\n    "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "swiper",
            { staticClass: "swiper", attrs: { options: _vm.swiperOption } },
            [
              _vm._l(_vm.multiIconData.iconComponents, function(
                iconData,
                index
              ) {
                return _c("swiper-slide", [
                  _c("div", {
                    ref: "slide" + index,
                    refInFor: true,
                    staticClass: "image-container"
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mobile-content-container" }, [
                    _c(
                      "h2",
                      {
                        staticClass: "sub-heading",
                        style: { color: iconData.subHeaderTextColor }
                      },
                      [_vm._v(_vm._s(iconData.subHeaderText))]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "content",
                        style: { color: iconData.bodyTextColor }
                      },
                      [_vm._v(_vm._s(iconData.bodyText))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "button-container pt-2" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "circle-icon-container mobile-icon-container"
                          },
                          [
                            iconData.iconImageData &&
                            iconData.iconImageData.imageSrcD
                              ? _c("img", {
                                  attrs: {
                                    src: iconData.iconImageData.imageSrcD,
                                    title: iconData.ctaText,
                                    alt: _vm.multiIconData.headerText + " icon"
                                  }
                                })
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "caption-text active-text" }, [
                          _vm._v(_vm._s(iconData.ctaText))
                        ])
                      ])
                    ])
                  ])
                ])
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-pagination",
                attrs: { slot: "pagination" },
                slot: "pagination"
              })
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-6 col-sm-6 d-flex justify-content-center" },
      [_c("div", { staticClass: "image-container" })]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1c6a45bc", { render: render, staticRenderFns: staticRenderFns })
  }
}