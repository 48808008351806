/**
 * manage-shopping-cart-service provides multiple methods for shopping cart modules.
 */

import RootService from './root-service';
import globals from '../globals';

class ManageShoppingCartService extends RootService {
  /**
   * Gets Basic cart details
   */
  getBasicCartService(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('empty', 'cart');
    config.params = {
      fields: 'BASIC',
      calculationType: 'cart',
    };
    if (config.params) {
      config.params.refreshCart = 'false';
    } else {
      config.params = {
        refreshCart: 'false',
      };
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Gets Full cart details
   */
  getFullCartService(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('empty', 'cart');
    config.params = {
      fields: 'FULL',
      calculationType: 'cart',
    };
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Gets Order summary details for cart page
   */
  getSummaryCartService(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('empty', 'cart');
    config.params = {
      fields: 'SUMMARY',
      calculationType: 'cart',
    };
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Gets Default cart details
   */
  getDefaultCart(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('empty', 'cart');
    config.params = {
      fields: 'DEFAULT',
    };
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Creates cart for user
   */
  createCartService(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('createCart', 'user');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Deletes cart for user
   */
  deleteCartService(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('empty', 'cart');
    this.delete(config, successCallback, errorCallback);
  }

  /**
   * Share Cart Service
   */
  shareCart(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('shareCart', 'cart');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Share wishlist Service
   */
  shareWishlist(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('shareQuickList');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Gets all wishlist of the user
   */
  getAllWishLists(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('getAllWishLists', 'user');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Creates wishlist
   */
  createList(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('createList', 'user');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Saves List
   */
  saveAList(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('createList', 'user');
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Saves cart entries while adding cart to list
   */
  saveCartEntries(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('saveListQuickOrder', 'user');
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Gets cart suggestion products
   */
  getCartSuggestions(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('suggestions', 'cart');
    if (config.params) {
      config.params.refreshCart = 'false';
    } else {
      config.params = {
        refreshCart: 'false',
      };
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Gets Quick order suggestion products
   */
  quickOrderSuggestions(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('quickOrderSuggestions');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Gets gift details
   * @param  {Number}  giftDetailsCode gift product code
   */
  giftDetails(requestConfig, successCallback, errorCallback, giftDetailsCode) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('pdpProductsUrl')}/${giftDetailsCode}`;
    config.params = {
      fields: 'FULL',
    };
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Applies voucher
   */
  applyVoucher(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('vouchers', 'cart');
    } else {
      config.url = globals.getRestUrl('vouchers', 'subscribe');
    }
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Deletes applied voucher
   * @param  {Number}  code voucher code
   */
  deleteVoucher(requestConfig, successCallback, errorCallback, code) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = `${globals.getRestUrl('vouchers', 'cart')}/${code}`;
    } else {
      config.url = `${globals.getRestUrl('vouchers', 'subscribe')}/${code}`;
    }
    this.delete(config, successCallback, errorCallback);
  }

  /**
   * Adds Installation service for installable product
   */
  addInstallation(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('addProductToCart', 'cart');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Informs that installation has been selected for the installable product
   * @param  {Number}  parentProductEntry parent installable product entryNumber
   */
  updateInstallation(requestConfig, successCallback, errorCallback, parentProductEntry) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('editCartEntry', 'cart')}/${parentProductEntry}`;
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Gets Installation details
   * @param  {Number}  installationCode Installation product Code
   */
  installationDetails(requestConfig, successCallback, errorCallback, installationCode) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('pdpProductsUrl')}/${installationCode}`;
    config.params = {
      fields: 'FULL',
    };
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Deletes a product from cart
   * @param  {Number}  itemNumber Product entryNumber
   */
  deleteCartItem(requestConfig, successCallback, errorCallback, itemNumber) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('deleteCartEntry', 'cart')}/${itemNumber}`;
    this.delete(config, successCallback, errorCallback);
  }

  /**
   * Handles when user manually edits the quantity of a product
   * @param  {Number}  itemNumber Product entryNumber
   */
  editCartItem(requestConfig, successCallback, errorCallback, itemNumber) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('editCartEntry', 'cart')}/${itemNumber}`;
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Removes Installation service
   * @param  {Number}  entryNumber Product entryNumber
   */
  removeInstallation(requestConfig, successCallback, errorCallback, entryNumber) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('editCartEntry', 'cart')}/${entryNumber}`;
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Handles addition and removal of gifting option
   * @param  {Number}  entryNumber Product entryNumber
   */
  editGift(requestConfig, successCallback, errorCallback, entryNumber) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('editCartEntry', 'cart')}/${entryNumber}`;
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Validates cart when user clicks on Checkout button on cart page
   */
  validateCart(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (config.params) {
      config.params.refreshCart = 'false';
    } else {
      config.params = {
        refreshCart: 'false',
      };
    }
    config.url = globals.getRestUrl('validateCart', 'cart');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Validates cart when user clicks order sample button on gpxpress cart page
   */
  validateGPXpressCart(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('gpxpressCheckout', 'cart');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Checks incompatible products when cart validation is done
   */
  validateIncompatibleProducts(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (config.params) {
      config.params.refreshCart = 'false';
    } else {
      config.params = {
        refreshCart: 'false',
      };
    }
    config.url = globals.getRestUrl('checkIncompatibleProducts', 'cart');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Saves multiple entries to List
   */
  saveProductsToList(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('saveMultipleLists', 'user');
    this.put(config, successCallback, errorCallback);
  }
}
export {
  ManageShoppingCartService as
  default,
};
