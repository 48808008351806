/**
 * This Component is used to display order-history page.
 * */
import mockOrderHistory from './mockOrderHistory';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import globals from '../../../../components/common/globals';
import vxDropdownPrimary from '../../../../components/common/vx-dropdown-primary/vx-dropdown-primary.vue';
import ManageTransactionService from '../../../../components/common/services/manage-transaction-service';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import vxOrderHistoryFilter from '../vx-order-history-filter/vx-order-history-filter.vue';
import vxTable from '../../../../components/common/vx-table/vx-table.vue';

export default {
  name: 'vx-order-history',
  components: {
    vxDropdownPrimary,
    vxModal,
    vxOrderHistoryFilter,
    vxSpinner,
    vxTable,
  },
  mixins: [mobileMixin],
  props: {
    /**
     * Labels, button and caption texts
     */
    i18n: {
      type: Object,
    },
  },
  data() {
    return {
      mockOrderHistory,
      globals,
      manageTransactionService: new ManageTransactionService(),
      orderHistoryData: [],
      dateRange: 6,
      isAdmin: false,
      totalOrders: '',
      sortOptions: [],
      selectedSort: '',
      // Order History transaction date dropdown data
      orderHistoryDropdownData: {
        options: [
          {
            label: this.i18n.date30DaysFilter,
            value: 30,
          },
          {
            label: this.i18n.date6MonthFilter,
            value: 6,
          },
        ],
      },
      currentYear: '',
      tableConfig: {},
    };
  },
  computed: {},
  mounted() {
    this.$refs.spinner.showSpinner();
    if (!this.isMobile()) {
      this.$refs.orderHistoryData.setDropdownValue(this.dateRange);
    }
    if (this.globals.isB2B()) {
      this.getOrderHistoryB2B();
    } else {
      this.getOrderHistoryB2C();
    }
    // To get current date and to push in transaction date dropdown
    const newDate = new Date();
    this.currentYear = newDate.getFullYear();
    this.orderHistoryDropdownData.options.push({
      label: this.currentYear,
      value: parseInt(this.currentYear, 10),
    });
    this.orderHistoryTimeData(this.currentYear);
    this.initializeTableConfig();
  },
  methods: {
    /**
     * Function to generate sort options.
     */
    generateSortOptions() {
      if (this.globals.isB2C()) {
        this.sortOptions = this.i18n.b2cSortOptions.options;
      } else if (this.globals.isB2B()) {
        this.sortOptions = this.isAdmin
          ? this.i18n.b2bAdminSortOptions.options
          : this.i18n.b2bBuyerSortOptions.options;
      }
    },
    /**
     * Function to get order history for B2C sites.
     */
    getOrderHistoryB2C() {
      this.manageTransactionService.getOrderHistoryB2C(
        {},
        this.handleOrderHistorySuccess,
        this.handleOrderHistoryError,
        this.dateRange,
      );
    },
    /**
     * Function to get order history for B2B sites.
     */
    getOrderHistoryB2B() {
      this.manageTransactionService.getOrderHistoryB2B(
        {},
        this.handleOrderHistorySuccess,
        this.handleOrderHistoryError,
        this.dateRange,
        this.selectedSort,
      );
    },
    /**
     * Function to handle success response for order history service call.
     */
    handleOrderHistorySuccess(response) {
      this.$refs.spinner.hideSpinner();
      const { data } = response;
      if (data && data.orders) {
        this.totalOrders = `(${data.pagination.totalResults})`;
        this.orderHistoryData = data.orders;
        if (this.orderHistoryData.length) {
          this.orderHistoryData.forEach((order) => {
            if (order.shippingAddress.isAdmin) {
              this.isAdmin = order.shippingAddress.isAdmin;
            }
          });
        }
        this.generateSortOptions();
        if (this.isMobile() && this.selectedSort) {
          this.triggerSort();
        }
      }
    },
    /**
     * Function to handle error response for order history service call.
     */
    handleOrderHistoryError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * Function to create address string.
     */
    formAddressString(address) {
      let userAddress = '';
      let companyName = '';
      let line2 = '';
      const name = `${address.firstName} ${address.lastName}`;
      if (address && address.companyName) {
        companyName = address.companyName;
      }
      if (address && address.line2) {
        line2 = address.line2;
      }
      if (address) {
        userAddress = {
          name,
          companyName,
          line1: address.line1,
          line2,
          town: address.town,
          region: address.region.isocodeShort,
          postalCode: address.postalCode,
          country: address.country.isocode,
        };
      }
      return userAddress;
    },
    /**
     * Function to display date range.
     */
    dateFilterSelected(dateRange) {
      this.dateRange = dateRange.value;
      if (!this.isMobile()) {
        if (this.globals.isB2B()) {
          this.getOrderHistoryB2B();
        } else {
          this.getOrderHistoryB2C();
        }
      }
    },
    /**
     * Function to populate order history years data.
     */
    orderHistoryTimeData(year) {
      let priorYear = parseInt(year, 10);
      const orderCreationYear = this.mockOrderHistory.orders[0].creationTime.split(
        '-',
      )[0];
      const totalPriorYears = year - parseInt(orderCreationYear, 10);
      for (let i = 0; i < totalPriorYears; i += 1) {
        priorYear -= 1;
        this.orderHistoryDropdownData.options.push({
          label: priorYear,
          value: priorYear,
        });
      }
    },
    /**
     * Function to open filter modal.
     */
    openFilterModel(event) {
      this.$refs.sortFilterModel.open(event);
    },
    /**
     * Function to populate default date and sortby options.
     */
    populateDefaultDate() {
      this.$refs.orderHistoryData.setDropdownValue(this.dateRange);
      if (!this.selectedSort) {
        this.$refs.sortDropdown.setDropdownLabel('Sort By');
      } else {
        this.$refs.sortDropdown.setDropdownValue(this.selectedSort);
      }
    },
    /**
     * Function to handle click of buttons on filter modal.
     */
    filterApplicable(data) {
      if (data === 'clear') {
        this.dateRange = 6;
        this.selectedSort = '';
        this.$refs.orderHistoryData.setDropdownValue(this.dateRange);
        this.$refs.sortDropdown.setDropdownLabel(this.i18n.defaultSort);
      } else if (data === 'apply') {
        this.applyFilter();
      }
    },
    /**
     * Function to apply filter value.
     */
    applyFilter() {
      if (this.globals.isB2B()) {
        this.getOrderHistoryB2B();
      } else {
        this.getOrderHistoryB2C();
      }
      this.$refs.sortFilterModel.close();
    },
    /**
     * Function to display sort values in mobile view.
     */
    storeSort(e) {
      this.selectedSort = e.value;
    },
    initializeTableConfig() {
      this.tableConfig = {
        rowCount: 5,
        emptyTable: this.i18n.emptyTableMessage,
        columnConfiguration: [
          {
            customHeaderName: this.i18n.orderNumber,
            customTemplate(data) {
              let orderNumberField = '';
              if (data.sourceNetSuite) {
                orderNumberField = `<p>${data.code}</p>`;
              } else if (globals.isB2B() && !this.isAdmin) {
                orderNumberField = `<a href="${globals.getB2BBaseURL()}${
                  globals.navigations.orderDetails
                }${data.code}">${data.code}</a>`;
              } else if (globals.isB2B() && this.isAdmin) {
                orderNumberField = `<a href="${globals.getB2BBaseURL()}${
                  globals.navigations.orderDetails
                }${data.code}?admin=${this.isAdmin}">${data.code}</a>`;
              } else if (globals.isB2C()) {
                orderNumberField = `<a href="${globals.getB2CBaseURL()}${
                  globals.navigations.orderDetails
                }${data.code}">${data.code}</a>`;
              }
              return orderNumberField;
            },
          },
          {
            customHeaderName: this.i18n.orderPlaced,
            customTemplate(data) {
              if (data.placed) {
                const modifiedDateValue = data.placed.split('-');
                const modifiedDate = modifiedDateValue[2].split('T');
                const dateValue = `${modifiedDateValue[1]}/${modifiedDate[0]}/${modifiedDateValue[0]}`;
                return dateValue;
              }
              return '';
            },
          },
          {
            field: 'statusDisplay',
            customHeaderName: this.i18n.status,
          },
          {
            field: 'user.name',
            customHeaderName: this.i18n.orderPlacedBy,
            visibilityCondition: () => this.globals.isB2B() || this.isAdmin,
          },
          {
            customHeaderName: this.i18n.shippedTo,
            customTemplate: (data) => {
              const self = this;
              const addressList = data.shippingAddress.addresses.map((address) => {
                const addressFormatted = self.formAddressString(address);
                const addressFull = `<p>${addressFormatted.name}</p><p>${addressFormatted.companyName}</p><p>${addressFormatted.line1}</p><p>${addressFormatted.line2}</p><p><span class="address-format">${addressFormatted.town}</span> <span class="address-format">${addressFormatted.region}</span> <span class="address-format">${addressFormatted.postalCode}</span></p><p>${addressFormatted.country}</p>`;
                return addressFull;
              });
              return addressList.join('');
            },
            visibilityCondition: () => this.globals.isB2C(),
          },
          {
            field: 'totalNumberOfProducts',
            customHeaderName: this.i18n.productNumber,
          },
          {
            field: 'total.formattedValue',
            customHeaderName: this.i18n.total,
          },
        ],
      };
    },
  },
};
