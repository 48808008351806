import assignIn from 'lodash.assignin';
import get from 'lodash.get';
import flyoutBannerMixin from '../../common/vx-flyout-banner/vx-flyout-banner-mixin';
import globals from '../../common/globals';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import vxDropdownPrimary from '../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import ManageProfileShoppingListService from '../../common/services/manage-profile-shopping-lists-service';
import vxRadioButtonGroup from '../../common/vx-radio-button-group/vx-radio-button-group.vue';
import PardotService from '../../common/services/pardot-service';

export default {
  name: 'vx-communication-preferences',
  components: {
    flyoutBannerMixin,
    vxSpinner,
    vxDropdownPrimary,
    vxRadioButtonGroup,
  },
  /**
  * All copy texts coming from properties files
  */
  props: {
    i18n: {
      type: Object,
      required: true,
    },
    userDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      manageProfileShoppingListService: new ManageProfileShoppingListService(),
      schema: [],
      frequency: {},
      frequencyDropdownValues: [],
      selectedFrequency: '',
      globals,
      questionAnswers: {},
      PardotService: new PardotService(),
      prospectID: null,
      preferencesData: {
        marketingPrefQuestionData: [{
          marketingPreferencesAnsList: [],
          question: 'Opt-In for Global Marketing Communications?',
          selectionType: 'RADIO',
        }],
      },
      saveDisabled: true,
    };
  },
  computed: {

  },
  mounted() {
    // TODO: See if business would like to display a message here instead of only hiding spinner
    const self = this;
    this.$refs.communicationPreferencesSpinner.showSpinner(self.globals.pardotFailureTimeout);
    if (this.globals.isB2B()) {
      const { email } = this.userDetails;
      this.PardotService.readProspectWithEmail(email, this.handleCommunicationPreferences, this.handleCommunicationPreferencesError);
    } else {
      this.manageProfileShoppingListService.getCommunicationPreferences({}, this.handleCommunicationPreferences, this.handleCommunicationPreferencesError);
    }
  },
  methods: {
    /**
  * Apply Selected Communication Preferences by calling service and updating values.
  */
    applyPreferences() {
      const requestObj = {};
      requestObj.marketingPreferences = [];
      this.schema.forEach((item) => {
        item.marketingPreferencesAnsList.forEach((answer) => {
          if (item.selectionType === 'RADIO') {
            requestObj.marketingPreferences.push({
              preferenceTypeId: answer.preferenceTypeId,
              selected: this.questionAnswers[item.question] === answer.preferenceTypeId,
            });
          } else {
            requestObj.marketingPreferences.push({
              preferenceTypeId: answer.preferenceTypeId,
              selected: answer.selected,
            });
          }
        });
      });

      if (this.globals.isB2B()) {
        const { preferenceTypeId, selected } = requestObj.marketingPreferences[0];
        const {
          email, country = null, firstName = null, lastName = null,
        } = this.userDetails;
        if (preferenceTypeId === 'optIn' && selected === true) {
          // check if this user has a prospect already
          this.PardotService.readProspectWithEmail(email, () => {
            // since the prospect already exists, so opt in the user
            this.updateProspect(this.prospectID, 0);
          }, () => {
            // since the prospect is not available, so create one
            const params = `${email}?country=${country}&first_name=${firstName}&last_name=${lastName}&source=gppro.com-profile&campaign_id=58469`;
            this.PardotService.createProspect(params, this.handleUpdatePreferences, this.handleUpdatePreferencesError);
          });
        } else if (preferenceTypeId === 'optOut' && selected === true && this.prospectID !== null) {
          this.updateProspect(this.prospectID, 1);
        }
      } else {
        requestObj.frequency = this.selectedFrequency;
        const requestConfig = {};
        requestConfig.data = requestObj;
        this.manageProfileShoppingListService.updatePreferences(requestConfig, this.handleUpdatePreferences, this.handleUpdatePreferencesError);
      }
      this.$refs.communicationPreferencesSpinner.showSpinner();
    },

    updateProspect(prospectID, optoutFlag) {
      this.PardotService.updateProspect(`${prospectID}?opted_out=${optoutFlag}`, this.handleUpdatePreferences, this.handleUpdatePreferencesError);
    },
    /**
     * Get Communication Preferences Values from Service
     */
    handleCommunicationPreferences(response) {
      this.$refs.communicationPreferencesSpinner.hideSpinner();
      if (response) {
        if (this.globals.isB2B()) {
          delete this.preferencesData.marketingPrefQuestionData[0].selectionType;
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(response, 'text/xml');
          const status = xmlDoc.getElementsByTagName('rsp')[0].getAttribute('stat');
          const optedOut = xmlDoc.getElementsByTagName('opted_out')[0].innerHTML === '1';
          this.prospectID = xmlDoc.getElementsByTagName('id')[0].innerHTML;
          if (status === 'ok') {
            let optionsData = null;
            if (optedOut) {
              // user has already opted-out
              optionsData = {
                description: 'opt in',
                name: 'optIn',
                preferenceTypeId: 'optIn',
                selected: false,
              };
            } else {
              // user has opted-in
              optionsData = {
                description: 'Opt out',
                name: 'optOut',
                preferenceTypeId: 'optOut',
                selected: false,
              };
            }
            this.preferencesData.marketingPrefQuestionData[0].marketingPreferencesAnsList.push(optionsData);
            this.schema = this.preferencesData.marketingPrefQuestionData;
          }
        } else {
          this.schema = response.data.marketingPrefQuestionData;
        }

        this.schema.forEach((item) => {
          item.marketingPreferencesAnsList.forEach((component, index) => {
            item.marketingPreferencesAnsList[index] = this.renameObjectKeys(component, 'description', 'preferenceTypeId');
            if (item.selectionType === 'RADIO' && component.selected) {
              this.questionAnswers[item.question] = component.preferenceTypeId;
            }
            return true;
          });
          return true;
        });
        if (response.data.frequency) {
          this.frequency = response.data.frequency;
        }
        this.generateFrequencyDropdownValues();
      }
    },
    /**
  * Handle error of get communication preferences call
  */
    handleCommunicationPreferencesError(response) {
      this.$refs.communicationPreferencesSpinner.hideSpinner();
      delete this.preferencesData.marketingPrefQuestionData[0].selectionType;
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response, 'text/xml');
      const status = xmlDoc.getElementsByTagName('rsp')[0].getAttribute('stat');
      if (status === 'fail') {
        // user profile does not exist
        const code = xmlDoc.getElementsByTagName('err')[0].getAttribute('code');
        if (code === '4') {
          this.preferencesData.marketingPrefQuestionData[0].marketingPreferencesAnsList.push({
            description: 'Opt in',
            name: 'optIn',
            preferenceTypeId: 'optIn',
            selected: false,
          });
        }
        this.schema = this.preferencesData.marketingPrefQuestionData;
      }
    },
    /**
    * Handle response of update communication preferences call
    */
    handleUpdatePreferences() {
      this.$emit('update-preferences-success', this.i18n.successMarketingPreferences);
      this.$refs.communicationPreferencesSpinner.hideSpinner();
    },
    /**
    * Handle error of update communication preferences call
    */
    handleUpdatePreferencesError() {
      this.$refs.communicationPreferencesSpinner.hideSpinner();
    },
    /**
    * generate dropdown values from communication preferences values
    */
    generateFrequencyDropdownValues() {
      if (this.frequency && this.frequency.freqEnumValues) {
        this.frequency.freqEnumValues.map((child, index) => this.$set(this.frequencyDropdownValues, index, { label: child.split('-')[0], value: child.split('-')[0] }));
        this.setSelectedFrequency();
      }
    },
    /**
    * Set Selected frequency value
    */
    setSelectedFrequency() {
      if (this.frequency && this.frequency.freqEnumValues) {
        const selectedValues = this.frequency.freqEnumValues.filter((child) => child.split('-')[1] === 'true')[0];
        if (selectedValues) {
          this.selectedFrequency = selectedValues.split('-')[0];
          this.$refs.frequencyDropdown.setDropdownValue(this.selectedFrequency);
        }
      }
    },
    /**
    * Renaming Object keys
    */
    renameObjectKeys(object, label, value) {
      const remapedObject = {
        label: object[label],
        value: object[value],
      };
      return assignIn({}, remapedObject, object);
    },
    handleButtonDisable() {
      this.saveDisabled = get(this.preferencesData, ['marketingPrefQuestionData', 0, 'marketingPreferencesAnsList', 0, 'selected']);
    },
  },
};
