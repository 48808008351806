/**
 * vx-video player loads video with
 * external youtube URL as SRC
 */
import { eventBus } from '../../../modules/event-bus';
import vxModal from '../vx-modal/vx-modal.vue';
import vxYoutubePlayer from '../vx-youtube-player/vx-youtube-player.vue';

export default {
  name: 'vx-video-player',
  props: {
    videoOptions: {
      type: String,
      // mocking the cntrols, branding,autoplay,info attributes
      default:
        '?modestbranding=1&rel=0&controls=1&showinfo=0&html5=1',
    },
  },
  components: {
    vxModal,
    vxYoutubePlayer,
  },
  data() {
    return {
      videoSRC: '',
      videoSRCWithOptions: '',
    };
  },
  computed: {},
  methods: {
    openPlayer(videoSRC) {
      this.videoSRC = videoSRC;
      this.videoSRCWithOptions = this.videoSRC + this.videoOptions;
      // Note: AutoPlay doesn't work in loclahost
      this.$refs.banner__video.open();
    },
  },
  /**
   * setting all the video instance options that
   *  are passed from the event that is emitted
   * from the component in which it is embedded
   */
  created() {
    const videoPlayerInstance = this;
    eventBus.$on('open-player', (videoSRC) => {
      videoPlayerInstance.videoSRC = videoSRC;
      videoPlayerInstance.videoSRCWithOptions = videoPlayerInstance.videoSRC + videoPlayerInstance.videoOptions;
      // Note: AutoPlay doesn't work in loclahost
      videoPlayerInstance.$refs.banner__video.open();
    });
  },
};
