var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "vx-order-summary" },
    [
      _c(
        "vx-modal",
        {
          ref: "termsConditionModal",
          attrs: { size: "medium", heading: _vm.i18n.termsConditionHeading },
          on: {
            close: function($event) {
              _vm.closetermsConditionModal()
            }
          }
        },
        [
          _c("vx-terms-condition", {
            attrs: { slot: "component" },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.dataLoaded
        ? [
            _c("div", { staticClass: "order-summary" }, [
              _c(
                "div",
                {
                  staticClass:
                    "pt-xs-4 px-sm-4 px-xs-3 order-summary-details-container"
                },
                [
                  _c("vx-order-summary-details", {
                    attrs: {
                      i18n: _vm.i18n,
                      page: _vm.page,
                      "order-summary-data": _vm.orderSummaryData,
                      giftable: _vm.isGiftable,
                      installable: _vm.isInstallable,
                      "is-shipping-multiple": _vm.isShippingMultiple
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              (_vm.page === _vm.pageType.cart
              ? (_vm.orderSummaryClicked && _vm.isTablet()) || !_vm.isTablet()
              : true)
                ? _c("div", { staticClass: "section-divider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pt-xs-4 px-sm-4 px-xs-3" },
                [
                  (_vm.orderSummaryClicked && _vm.isTablet()) || !_vm.isTablet()
                    ? _c("div", [
                        _vm.page !== _vm.pageType.orderConfirmation &&
                        _vm.page !== _vm.pageType.orderDetails &&
                        _vm.page !== _vm.pageType.orderApprovalDetails
                          ? _c(
                              "div",
                              { staticClass: "promotion-section" },
                              [
                                _c("vx-promotion", {
                                  attrs: {
                                    i18n: _vm.i18n,
                                    "promotion-data":
                                      _vm.orderSummaryData
                                        .appliedOrderPromotions,
                                    "product-promotion":
                                      _vm.orderSummaryData
                                        .appliedProductPromotions,
                                    "voucher-data":
                                      _vm.orderSummaryData.appliedVouchers
                                  }
                                }),
                                _vm._v(" "),
                                _vm.page === _vm.pageType.checkout
                                  ? [
                                      _c(
                                        "p",
                                        { staticClass: "checkout-terms-cond" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { "aria-hidden": "true" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.termsConditionText)
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "sr-only" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.termsConditionText) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.i18n.termsConditionLink
                                                  )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.openTermsModal($event)
                                                },
                                                keyup: function($event) {
                                                  if (
                                                    !("button" in $event) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter-space",
                                                      undefined,
                                                      $event.key,
                                                      undefined
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  _vm.openTermsModal($event)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.termsConditionLink
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.page !== _vm.pageType.checkout
                          ? _c("div", {
                              staticClass:
                                "section-divider visible-xs visible-sm"
                            })
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.page === _vm.pageType.orderConfirmation ||
                  _vm.page === _vm.pageType.orderDetails
                    ? [
                        _vm.isInstallable
                          ? _c(
                              "div",
                              {
                                staticClass: "order-helper-text pt-xs-2 pt-sm-3"
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.i18n.installHelpContactText1) +
                                      " "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "tel:" +
                                          _vm.i18n.installHelpContactNumber
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.i18n.installHelpContactNumber
                                        )
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.i18n.installHelpContactText2)
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "sub-total-helper-text pb-xs-4 non-printable-section"
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.i18n.orderHelpContactText) +
                                "\n            "
                            ),
                            _vm.contactNumber
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "tel:" + _vm.contactNumber }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.contactNumber))]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ]),
            _vm._v(" "),
            _vm._t("bottomButton")
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6cc871ad", { render: render, staticRenderFns: staticRenderFns })
  }
}