import VueBarcode from 'vue-barcode';
import globals from '../../../../components/common/globals';
import vxProductTile from '../../common/vx-product-tile/vx-product-tile.vue';
import vxCreateList from '../vx-create-list/vx-create-list.vue';
import vxDeleteList from '../vx-delete-list/vx-delete-list.vue';
import vxDownloadInfo from '../../vx-download-info/vx-download-info.vue';
import vxEditListName from '../vx-edit-list-name/vx-edit-list-name.vue';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxStepperControl from '../../common/vx-stepper-control/vx-stepper-control.vue';
import shareList from '../vx-share-list/vx-share-list.vue';
import downloadProductInfo from '../vx-download-product-info/vx-download-product-info.vue';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import flyoutBannerSecondaryMixin
  from '../../../../components/common/vx-flyout-banner-secondary/vx-flyout-banner-secondary-mixin';
import {
  ProductAvailability,
  addToCartStatus,
  flyoutStatus,
  generatePdf,
  cookies,
  pages,
} from '../../../../components/common/mixins/vx-enums';
import AnalyticsService from '../../../../components/common/services/analytics-service';
import { cartEventBus } from '../../../../modules/event-bus';
import saveCart from '../../../../components/manage-shopping-cart/vx-save-cart/vx-save-cart.vue';
import vxNotifyMe from '../../../../components/search-browse/vx-notify-me/vx-notify-me.vue';
import ManageProfileShoppingListService
  from '../../../../components/common/services/manage-profile-shopping-lists-service';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import vxAddProductToList from '../vx-add-product-to-list/vx-add-product-to-list.vue';
import vxPdfGenerator from '../../../../components/common/vx-pdf-generator/vx-pdf-generator.vue';
import vxAddCustomAttribute from '../vx-add-custom-attribute/vx-add-custom-attribute.vue';
import vxAccordion from '../../../../components/common/vx-accordion/vx-accordion.vue';
import cookiesMixin from '../../../../components/common/mixins/cookies-mixin';
import vxShareItem from '../../pdp/vx-share-item/vx-share-item.vue';
import vxProductIcons from '../../common/vx-product-icons/vx-product-icons.vue';
import ProductMixin from '../../../../components/common/mixins/product-mixin';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import ctaLogicMixin from '../../../../components/common/mixins/cta-logic-mixin';

export default {
  name: 'vx-list-details',
  mixins: [
    flyoutBannerMixin,
    flyoutBannerSecondaryMixin,
    cookiesMixin,
    ProductMixin,
    mobileMixin,
    ctaLogicMixin,
  ],
  components: {
    'vx-product-tile': vxProductTile,
    vxModal,
    vxStepperControl,
    vxCreateList,
    vxDeleteList,
    vxDownloadInfo,
    vxNotifyMe,
    'vx-edit-list-name': vxEditListName,
    'vx-share-list': shareList,
    'vx-save-cart': saveCart,
    'vx-download-product-info': downloadProductInfo,
    vxSpinner,
    vxAddProductToList,
    vxPdfGenerator,
    vxAddCustomAttribute,
    vxAccordion,
    barcode: VueBarcode,
    vxShareItem,
    vxProductIcons,
  },
  props: {
    /**
         * Labels, button and caption texts
         */
    i18n: {
      type: Object,
    },
    /**
         * check for is favorites
         */
    isFavorites: {
      type: Boolean,
    },
    /**
         * check whether list page is loading from an email link after being shared on list page
         */
    isSharedList: {
      type: Boolean,
    },
    /**
         * check whether list page is loading from an email link after being shared on cart page
         */
    isSharedCart: {
      type: Boolean,
    },
    /**
         * check for user being able to share list
         */
    isSharable: {
      type: Boolean,
      default: false,
    },
    /**
         * check for user being able to download excel
         */
    isExcelDownloadable: {
      type: Boolean,
      default: false,
    },
    /**
         * check for user being able to download pdf
         */
    isDownloadable: {
      type: Boolean,
      default: false,
    },
    /**
         * color codes data from jsp for pdf download modal
         */
    colorCodes: {
      type: Object,
      default: {},
    },
    isFilterRequired: {
      type: Boolean,
      default: false,
    },
    /**
         * check for user being able to add products to list using a string of Ids or SKUs
         */
    isAddProduct: {
      type: Boolean,
      default: false,
    },
    quantityValue: {
      type: Number,
    },
    prodId: {
      type: Number,
    },
  },
  data() {
    return {
      ProductAvailability,
      flyoutStatus,
      addToCartStatus,
      globals,
      productEntries: [],
      listName: '',
      listId: '',
      quantityUpdate: {},
      wishlistUid: '',
      subtotal: 0,
      updatedQuantity: 0,
      prodPrice: 0,
      noProducts: false,
      message: '',
      selectedLists: [],
      selectedListNames: [],
      analyticsService: new AnalyticsService(),
      manageProfileShoppingListService: new ManageProfileShoppingListService(),
      notifyCode: '',
      searchQuery: '',
      currentProduct: {},
      canAddProductsToList: false,
      isAddProductsToList: true,
      customAttributes: [],
      attributeList: [],
      isAccordionOpen: false,
      pdfInfo: {},
      pdf: {},
      pdpListdata: [],
      loadPdfGenerator: false,
      fromPdfModal: false,
      featureProducts: [],
      activeContainerIndex: '',
      activeInputIndex: 0,
      accordionData: {
        hideAccordion: this.i18n.listDetails.hideAttribute,
        viewAccordion: this.i18n.listDetails.viewAttribute,
      },
      generatePdf,
      cookies,
      categoryGroups: {},
      addToCartDisabled: false,
      certificationsName: '',
      hasSustainabilityPage: true,
      isShareList: false,
      isCartShareList: false,
      showGuestUserAddListMessage: false,
      isAddtoSampleEnabled: true,
      pages,
    };
  },
  computed: {
    /**
         * This chunk is to filter through list page names
         */
    filteredProductEntries() {
      const search = this.searchQuery.toLowerCase()
        .trim();
      if (!search) return this.productEntries;
      return this.productEntries.filter(
        (item) => item.product.name.toLowerCase()
          .indexOf(search) > -1,
      );
    },
  },
  created() {
    this.listName = this.globals.getUrlParam('listName');
    // isShareList param is present when user navigates to list page via share list pdf email
    this.isShareList = this.globals.getUrlParam('isShareList');
    this.isCartShareList = this.globals.getUrlParam('isCartShareList');
    this.selectedLists.push(this.listName);
    if (this.globals.getCookie(cookies.guestListUid) && !this.listName) {
      if (!this.globals.loggedIn && this.globals.isB2B()) {
        this.setListPageUrl();
      }
    }
  },
  mounted() {
    this.onInit();
  },
  methods: {
    /**
         * This function handles the response of add product to cart service
         */
    handleAddProductResponse(response) {
      if (response && response.data) {
        cartEventBus.$emit('call-basic-cart');
      }
      if (response.data.statusCode) {
        if (response.data.statusCode === this.i18n.listDetails.maxPurchaseableQuantityErrorCode) {
          if (response.data.statusCode === this.i18n.listDetails.maxPurchaseableQuantityErrorCode) {
            if (this.productEntry && this.productEntry.product.maxOrderQuantity && response.data.quantityAdded === this.productEntry.product.maxOrderQuantity) {
              this.showFlyout('error', this.i18n.listDetails.maxPurchaseableQuantityErrorMessage, true);
            } else if (this.productEntry && this.productEntry.product.maxOrderQuantity && response.data.quantityAdded < this.productEntry.product.maxOrderQuantity) {
              this.showFlyout('error', `${this.i18n.listDetails.maxPurchaseableQuantityUpdateMessage1
                            + this.productEntries.filter(
                              (obj) => obj.product.code === this.currentProduct.productCode,
                            )[0].product.maxOrderQuantity}, ${this.i18n.listDetails.maxPurchaseableQuantityUpdateMessage2}`, true);
            }
          }
        } else if (response.data.statusCode === this.i18n.listDetails.lowStockErrorCode) {
          this.showFlyout('error', this.i18n.listDetails.lowStockErrorMessage, true);
        } else if (response.data.statusCode === this.ProductAvailability.OFFLINE) {
          this.showFlyout(
            'error',
            this.i18n.listDetails.addListToCartError,
            true,
          );
          this.onInit();
        } else {
          this.showFlyout(this.flyoutStatus.success, `${response.data.quantityAdded} ${this.i18n.listDetails.addToCartSuccessMessage}`, true, 3000);
        }
      }
    },
    /**
         * This function handles the error of add product to cart service
         */
    handleAddProductError(error) {
      if (error && error.response && error.response.data && error.response.data.errors.length !== 0 && error.response.data.errors[0] && error.response.data.errors[0].code) {
        if (error.response.data.errors[0].code === this.i18n.listDetails.maxPurchaseableQuantityErrorCode) {
          this.showFlyout('error', `${this.i18n.listDetails.maxPurchaseableQuantityUpdateMessage1} ${this.productEntries.filter(
            (obj) => obj.product.code === this.currentProduct.productCode,
          )[0].product.maxOrderQuantity}, ${this.i18n.listDetails.maxPurchaseableQuantityUpdateMessage2}`, true);
        } else if (error.response.data.errors[0].code === this.i18n.listDetails.lowStockErrorCode) {
          this.showFlyout('error', this.i18n.listDetails.lowStockErrorMessage, true);
        }
      }
    },
    /**
         * This function is called when component is mounted.
         *  It contains the service calls for different configurations to load list details data.
         * @param  {Boolean} fromPdf - Boolean to initialise list data if it's coming from pdf
         */
    onInit(fromPdf) {
      this.$refs.spinner.showSpinner();
      if (this.isFavorites) {
        const requestConfig = {};
        if (this.isSharedList || this.isSharedCart) {
          requestConfig.data = {
            listName: this.listName,
          };
          this.manageProfileShoppingListService.getSharedListDetails(requestConfig, this.handleShoppingListResponse, this.handleShoppingListError, fromPdf);
        } else {
          this.manageProfileShoppingListService.getFavoritesListDetails(requestConfig, this.handleShoppingListResponse, this.handleShoppingListError, fromPdf);
        }
      } else if (this.isSharedList || this.isSharedCart) {
        const requestConfig = {};
        requestConfig.data = {
          listName: this.listName,
        };
        this.manageProfileShoppingListService.getSharedListDetails(requestConfig, this.handleShoppingListResponse, this.handleShoppingListError, fromPdf);
      } else if (this.isShareList || this.isCartShareList) {
        this.manageProfileShoppingListService.createSharedList({}, this.handleShoppingListResponse, this.handleShoppingListError, this.listName);
      } else {
        const requestConfig = {};
        this.manageProfileShoppingListService.getListDetails(
          requestConfig,
          this.handleShoppingListResponse,
          this.handleShoppingListError,
          this.listName,
          fromPdf,
          this.isShareList,
          this.isCartShareList,
        );
      }
    },
    /**
         * This function handles the response of add product to cart service
         */
    handleShoppingListResponse(response, isUpdateQuantity) {
      this.$refs.spinner.hideSpinner();
      if (!response.data.wishlistEntries || response.data.wishlistEntries.length === 0) {
        this.noListMessage = this.i18n.listDetails.noProductMessage;
        this.noProducts = true;
        this.productEntries = response.data.wishlistEntries;
      } else {
        this.productEntries = response.data.wishlistEntries;
      }
      this.categoryGroups = response.data.wishlistEntriesGroup;
      if (response.data.customAttr1 || response.data.customAttr2 || response.data.customAttr3) {
        this.categoryGroups.forEach((category) => {
          category.value.wishlistEntryGroup.forEach((entry) => {
            entry.product.customAttr1Value = !entry.customAttr1Value ? '' : entry.customAttr1Value;
            entry.product.customAttr2Value = !entry.customAttr2Value ? '' : entry.customAttr2Value;
            entry.product.customAttr3Value = !entry.customAttr3Value ? '' : entry.customAttr3Value;
          });
        });
      }
      if (!this.fromPdfModal) {
        const certificationsList = [];
        this.productEntries.forEach((product) => {
          if (product.product && product.product.gpCertifications) {
            product.product.gpCertifications.forEach((certification) => {
              certificationsList.push(certification.id);
            });
          }
        });
        this.certificationsName = certificationsList.join();
      }
      if (!isUpdateQuantity) {
        this.handleCustomAttributesResponse(response);
      }
      this.wishlistUid = response.data.wishlistUid;
      this.subtotal = response.data.subtotal;
      this.listId = response.data.name;
      const existingListName = this.selectedListNames.indexOf(this.listId);
      if (existingListName === -1) {
        this.$set(this.selectedListNames, 0, this.listId);
      }
      if (this.fromPdfModal) {
        this.pdpListdata = response.data;
        this.hasSustainabilityPage = response.data.soldToId !== generatePdf.soldTo555555;
        this.loadPdfGenerator = true;
        this.fromPdfModal = false;
        this.$nextTick(() => {
          this.identifyPdfFormat();
        });
      }
      // create guest wishlist cookie if guest user navigates to list page via share list pdf email
      if ((this.isShareList || this.isCartShareList) && !this.globals.loggedIn) {
        if (this.globals.siteConfig.isShareWishlistEnabled && !this.globals.getCookie(cookies.guestListUid)) {
          this.createCookie(cookies.guestListUid, response.data.wishlistUid, 3000);
        }
      }
      // update url with wishlistUid once user navigates to list page via share list pdf email
      if (this.isShareList || this.isCartShareList) {
        this.selectedLists = [];
        this.setListPageUrl();
      }
      this.isShareList = false;
      this.isCartShareList = false;
      if (response.data.productErrorMessage) {
        this.showFlyout(flyoutStatus.error, response.data.productErrorMessage, true);
      }
    },
    /**
         * This function handles the check to enable sample order
         * @param  {Object} data - sample order data
         */
    isSampleOrderEnable(data) {
      if (data) {
        const isSampleTrueProducts = [];
        const isApprovedSampleTrueProducts = [];
        data.forEach((item) => {
          if (item.product.isSample) {
            isSampleTrueProducts.push(item.product.code);
          }
          if (item.product.approvedSampleStatus) {
            isApprovedSampleTrueProducts.push(item.product.code);
          }
        });
        if (data.length && !isApprovedSampleTrueProducts.length) {
          this.isAddtoSampleEnabled = false;
        } else {
          this.isAddtoSampleEnabled = true;
          if (
            data.length
                        && isSampleTrueProducts.length
                        && isApprovedSampleTrueProducts.length === data.length
          ) {
            this.isAddtoSampleEnabled = true;
          } else {
            this.isAddtoSampleEnabled = false;
          }
        }
      }
    },
    /**
         * This function handles the error of add product to cart service
         */
    handleShoppingListError(error) {
      this.$refs.spinner.hideSpinner();
      if (!this.globals.loggedIn && !this.readCookie(cookies.guestListUid)) {
        this.showGuestUserAddListMessage = true;
      } else {
        this.showGuestUserAddListMessage = false;
      }
      if (!this.listName && error.response.data.errors[0].code === '109') {
        this.noProducts = true;
      }
    },
    /**
         * This function handles the data of custom attribute from response
         */
    handleCustomAttributesResponse(response) {
      this.customAttributes = [];
      this.attributeList = [];
      this.isAccordionOpen = false;
      const attributes = [
        {
          label: 'customAttr1',
          value: 'customAttr1Value',
        },
        {
          label: 'customAttr2',
          value: 'customAttr2Value',
        },
        {
          label: 'customAttr3',
          value: 'customAttr3Value',
        },
      ];
      attributes.forEach((attr) => {
        if (response.data[attr.label]) {
          this.attributeList.push({
            label: attr.value,
            value: response.data[attr.label],
          });
        } else {
          this.attributeList.push({
            label: attr.value,
            value: '',
          });
        }
      });
      this.customAttributes = this.attributeList.filter(
        (attribute) => attribute.value !== '',
      );
    },
    /**
         * This function is called when add list to cart button
         */
    addListToCart() {
      const self = this;
      const requestConfig = {};
      self.manageProfileShoppingListService.addListToCart(requestConfig, self.handleAddListResponse, self.handleAddistError, self.listName);
      this.$refs.spinner.showSpinner();
    },
    /**
         * This function handles the response of add list to cart service
         */
    handleAddListResponse(response) {
      this.$refs.spinner.hideSpinner();
      if (response && response.data) {
        let isOffline = false;
        let offlineMsg = '';
        let productsAddedToCart = 0;
        cartEventBus.$emit('call-basic-cart');
        const nonPurchasable = [];
        response.data.forEach((product) => {
          if (product.statusCode === addToCartStatus.failure || product.statusCode === this.i18n.listDetails.maxPurchaseableQuantityErrorCode
                       || product.statusCode === this.i18n.listDetails.lowStockErrorCode) {
            nonPurchasable.push(product.errorProductName);
          }
          if (product.statusCode === this.ProductAvailability.OFFLINE) {
            isOffline = true;
            offlineMsg = product.statusMessage;
          }
          productsAddedToCart += product.quantityAdded;
        });
        if (nonPurchasable.length > 0 && isOffline) {
          this.onInit();
          this.showFlyout('error', this.i18n.listDetails.addListToCartError, false);
          this.showFlyoutSecondary('error', offlineMsg, false);
        } else if (nonPurchasable.length > 0) {
          this.showFlyout('error', this.i18n.listDetails.addListToCartError, false);
        } else if (isOffline) {
          this.onInit();
          this.showFlyout('error', offlineMsg, true);
        } else {
          this.showFlyout(this.flyoutStatus.success, `${productsAddedToCart} ${this.i18n.listDetails.addToCartSuccessMessage}`, true, Number(this.globals.siteConfig.bannerTimer));
        }
      }
    },
    /**
         * This function handles the error of add list to cart service
         */
    handleAddistError() {
      this.$refs.spinner.hideSpinner();
    },
    handleSaveList() {
      if (this.globals.loggedIn) {
        this.$refs.selectListModal.open();
      } else {
        this.globals.navigateToUrl('login');
      }
    },
    onSelectListSuccess() {
      this.$refs.selectListModal.close();
      this.showFlyout('success', this.i18n.listDetails.selectListResponse, true);
    },
    /**
         * This function is called when add list to trash icon in the product tile
         * @param  {Number} itemNumber - product item number
         */
    deleteCartItem(itemNumber) {
      this.$refs.spinner.showSpinner();
      const requestBody = {
        wishlistUid: this.wishlistUid,
        product: {
          code: itemNumber,
        },
      };
      const requestConfig = {};
      requestConfig.data = requestBody;
      this.manageProfileShoppingListService.deleteCartItem(requestConfig, this.handleDeleteCartItemResponse, this.handleDeleteCartItemError);
    },
    /**
         * This function handles the response of delete cart item service
         */
    handleDeleteCartItemResponse() {
      this.onInit();
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function handles the error of delete cart item service
         */
    handleDeleteCartItemError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function handles the success emitted from the delete list modal component
         */
    deleteModalSuccess(success) {
      this.onInit();
      this.$refs.deleteListModal.close();
      if (this.readCookie(cookies.guestListUid)) {
        this.eraseCookie(cookies.guestListUid);
      }
      this.showFlyout('success', success, true);
      const listViewPage = this.globals.getNavBaseUrl() + this.globals.serviceUrls.listLandingView;
      window.location.href = listViewPage;
    },
    /**
         * This function handles the error emitted from the delete list modal component
         */
    deleteModalError(error) {
      this.onInit();
      this.$refs.deleteListModal.close();
      this.showFlyout('error', error, true);
    },
    /**
         * This function handles the error emitted from the share list modal component
         */
    onShareListError(error) {
      this.$refs.shareItemModal.close();
      this.showFlyout('error', error, true);
    },
    /**
         * This function handles the sucess emitted from the share list modal component
         */
    onShareListSuccess(success) {
      this.$refs.shareItemModal.close();
      if (this.productEntries.length === 1) {
        this.showFlyout(flyoutStatus.success, success, true);
      } else if (this.productEntries.length > 1) {
        this.showFlyout(flyoutStatus.success, this.i18n.listDetails.shareMultipleItemResponse, true);
      }
    },
    /**
         * This function handles the error emitted from the share list pdf modal component
         */
    onShareListPdfError(error) {
      this.$refs.shareListPdfModal.close();
      this.showFlyout('error', error, true);
    },
    /**
         * This function handles the success emitted from the share list modal pdf component
         */
    onShareListPdfSuccess(success) {
      this.$refs.shareListPdfModal.close();
      if (this.productEntries.length === 1) {
        this.showFlyout(flyoutStatus.success, success, true);
      } else if (this.productEntries.length > 1) {
        this.showFlyout(flyoutStatus.success, this.i18n.listDetails.shareMultipleItemResponse, true);
      }
    },
    /**
         * This function handles the sucess emitted from the edit list name modal component
         */
    editListNameSuccess(success) {
      this.$refs.editListModal.close();
      this.$refs.spinner.showSpinner();
      this.showFlyout('success', success, true);
      this.onInit();
    },
    /**
         * This function handles the error emitted from the edit list name modal component
         */
    editListNameError(error) {
      this.$refs.editListModal.close();
      this.showFlyout('error', error, true);
    },
    /**
         * This function is called when the edit icon is clicked
         */
    handleEditList(event) {
      this.$refs.editListModal.open(event);
    },
    /**
         * This function is called when the share icon is clicked
         */
    handleShareList(event, type) {
      if (this.globals.siteConfig.isShareWishlistEnabled && type === 'list') {
        // share list as pdf
        this.$refs.shareListPdfModal.open(event);
      } else {
        // normal list share
        this.$refs.shareItemModal.open(event);
      }
    },
    /**
         * This function is called when the Download icon is clicked
         */
    handleDownloadProductInfo(event) {
      this.$refs.downloadProductExcelModal.open(event);
    },
    /**
         * This function is called when the delete icon is clicked
         */
    handleDeleteList(event) {
      this.$refs.deleteListModal.open(event);
    },
    /**
         * This function is called when the download icon is clicked
         */
    handleDownloadList(event) {
      // this.$refs.pdfModule.renderImage('img#itf1');
      this.$refs.downloadImagePdfModal.open(event);
    },
    /**
         * This function is called when stepper control is clicked
         * @param  {Number} prodPrice - current product price
         * @param  {String} prodCode - current prod code
         * @param  {Number} updatedQuantity - updated Quantity of product
         */
    getQuantity(prodPrice, prodCode, updatedQuantity) {
      this.$refs.spinner.showSpinner();
      this.updatedQuantity = updatedQuantity;
      if (this.updatedQuantity === 0) {
        this.deleteCartItem(prodCode);
      } else {
        this.prodPrice = Number(prodPrice.replace(',', '').substr(1));
        this.quantityUpdate = {
          wishlistUid: this.wishlistUid,
          entryType: 'PRODUCT',
          quantity: updatedQuantity,
          product: {
            code: prodCode,
          },
        };
        const requestConfig = {};
        requestConfig.data = this.quantityUpdate;
        this.manageProfileShoppingListService.updateQuantity(requestConfig, this.handleUpdateQuantityResponse, this.handleUpdateQuantityError);
      }
    },
    /**
         * This function handles the error of update quantity service
         */
    handleUpdateQuantityError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function handles the response of update quantity service
         */
    handleUpdateQuantityResponse(response) {
      this.$refs.spinner.hideSpinner();
      if (response) {
        this.handleShoppingListResponse(response, true);
      }
    },
    /**
         * This function opens gifting details modal
         * @param  {String} message - gift modal message
         */
    openGiftModal(message) {
      this.message = message;
      this.$refs.addGiftModal.open();
    },
    /**
         * This function opens installation details modal
         * @param  {String} message - installation modal message
         */
    openInstallationDetailsModal(message) {
      this.message = message;
      this.$refs.installationDetailsModal.open();
    },
    /**
         * This function allows the user to add product from list page to cart page
         * @param  {Number} prodQuantity - product quantity
         * @param  {Number} prodCode - product code
         * @param  {Number} prodPrice - product price
         */
    addToCart(prodQuantity, prodCode) {
      this.currentProduct.productCode = prodCode;
      const requestObjParams = {
        quantity: prodQuantity,
        product: {
          code: prodCode,
        },
      };
      // sending the data to Google Analytics on Add to Cart button click
      const analyticsObject = {
        code: prodCode,
        name: this.listId,
        quantity: prodQuantity,
      };
      this.analyticsService.trackAddToCart(analyticsObject);
      if (this.globals.siteId === 'gppro') {
        this.analyticsService.trackAddToCartGA4(analyticsObject);
      }
      // this.trackAddToCart(analyticsObject);
      const requestConfig = {};
      requestConfig.data = requestObjParams;
      this.manageProfileShoppingListService.addProductToCart(
        requestConfig,
        this.handleAddProductResponse,
        this.handleAddProductError,
      );
    },
    /**
         * This function opens the notify me modal
         * @param  {Object} product - product info
         */
    notifyMe(product) {
      this.notifyCode = product.code;
      this.$refs.notifyMeModal.open();
    },
    /**
         * This function shows flyout for error
         */
    onNotifyMeError(error) {
      this.$refs.notifyMeModal.close();
      this.showFlyout('error', error, true);
    },
    /**
         * This function shows flyout for success
         */
    onNotifyMeSuccess(success) {
      this.$refs.notifyMeModal.close();
      this.showFlyout('success', success, true);
    },
    /**
         * This function shows flyout for error when user inputs value higher than the available max quantity in stepper
         */
    maxValueUpdated() {
      this.showFlyout('error', this.i18n.listDetails.maxValueUpdatedStatus, true);
    },
    /**
         * This function handles the max quantity from product data coming in service
         * @param  {Number} maxValue - max value of product
         */
    maxOrderQuantity(maxValue) {
      return maxValue;
    },
    /**
         * This function shows flyout for error when user inputs value lower than the available min quantity in stepper
         */
    minValueUpdated() {
      this.showFlyout(this.flyoutStatus.error, this.i18n.listDetails.minValueUpdatedStatus, true);
    },
    /**
         * This function handles the min quantity from product data coming in service
         * @param  {Number} minValue - min value of product
         */
    minOrderQuantity(minValue) {
      return minValue;
    },
    /**
         * This function sets stock level of the product
         * @param  {Number} stockLevel - stock level of product
         */
    setStockLevel(stockLevel) {
      return stockLevel;
    },
    /**
         * This function throws error flyout for stock level of product
         */
    stockLevelUpdated() {
      this.showFlyout(this.flyoutStatus.error, this.i18n.listDetails.stockLevelUpdatedStatus, true);
    },
    /**
         * This function handles logic to open or close the accordion
         */
    accordionStatus(event, index) {
      this.isAccordionOpen = !this.isAccordionOpen;
      const accordion = document.querySelector(`.accordion-${index}`);
      if (this.isAccordionOpen) {
        accordion.style.display = 'block';
      } else {
        accordion.style.display = 'none';
      }
    },
    /**
         * This function saves custom attribute for each product in the product tile
         * @param  {Object} product - product details
         * @param  {String} attribute - attribute value
         * @param  {Number} index - Index of that product in template
         */
    saveCustomAttributeValue(evt, product, attribute, index, inputIndex) {
      if (evt.target) {
        const custAttribute = evt.target.value;
        this.activeContainerIndex = index;
        if (inputIndex < 2) {
          this.activeInputIndex = inputIndex + 1;
        } else {
          this.activeInputIndex = inputIndex;
        }
        const requestConfig = {};
        const requestBody = {
          wishlistUid: this.wishlistUid,
          product: {
            code: product.code,
          },
          customAttr1Value: product.customAttr1Value,
          customAttr2Value: product.customAttr2Value,
          customAttr3Value: product.customAttr3Value,
        };
        requestBody[attribute.label] = custAttribute;
        requestConfig.data = requestBody;
        this.manageProfileShoppingListService.saveCustomAttributes(requestConfig, this.handleSaveCustomAttributeResponse, this.handleSaveCustomAttributeError);
      }
    },
    /**
         * This function handles the response for saving custom attribute for each product in the product tile
         */
    handleSaveCustomAttributeResponse() {
      this.onInit();
    },
    /**
         * This function handles the error for saving custom attribute for each product in the product tile
         */
    handleSaveCustomAttributeError() {},
    /**
         * This function opens the modal for entering custom attribute labels
         */
    handleAddCustomAttribute() {
      this.$refs.addCustomAttributeModal.open();
    },
    /**
         * This function allows user to add products to list by entering a string consisting of comma separated Ids or SKUs
         * @param  {String} productsArray - string of products to add to list
         */
    handleAddProductsToList(productsArray) {
      const productsString = productsArray.replace(/\s/g, '');
      const requestConfig = {};
      requestConfig.params = {
        productcodes: productsString,
      };
      this.manageProfileShoppingListService.addProductToList(requestConfig, this.handleAddProductToListResponse, this.handleAddProductToListError, this.wishlistUid);
    },
    /**
         * This function handles response for add products to list
         */
    handleAddProductToListResponse(response) {
      this.showFlyout(flyoutStatus.success, response.data.description, true);
      this.noProducts = false;
      if (response.data.errorMessage) {
        this.showFlyoutSecondary(flyoutStatus.error, response.data.errorMessage, true);
      }
      this.onInit();
    },
    /**
         * This function handles error for add products to list
         */
    handleAddProductToListError() {
      this.showFlyout(flyoutStatus.error, this.i18n.listDetails.addProductToListError, true);
    },
    /**
         * This function handles the emit for adding custom attribute labels from the modal
         * @param  {Object} attributeInfo - custom attribute information entered by user
         */
    onCustomAttributeInit(attributeInfo) {
      const requestConfig = {};
      requestConfig.data = {
        wishlistUid: this.wishlistUid,
        customAttr1: attributeInfo.attributeOne,
        customAttr2: attributeInfo.attributeTwo,
        customAttr3: attributeInfo.attributeThree,
      };
      this.manageProfileShoppingListService.addCustomAttributes(requestConfig, this.handleAddCustomAttributeSuccess, this.handleAddCustomAttributeError);
    },
    /**
         * This function handles the response for adding custom attribute labels from the modal
         */
    handleAddCustomAttributeSuccess() {
      this.$refs.addCustomAttributeModal.close();
      this.onInit();
    },
    /**
         * This function handles the error for adding custom attribute labels from the modal
         */
    handleAddCustomAttributeError() {},
    /**
         * This function handles the emit for downloading product images in zip format
         * @param  {Object} imageInfo - Image information of the products
         */
    onImageDownloadInit(imageInfo) {
      const requestConfig = {};
      requestConfig.params = {
        wishlistid: this.wishlistUid,
        imageformat: imageInfo.format,
        resolution: imageInfo.size,
        allimages: imageInfo.allImages,
      };
      this.manageProfileShoppingListService.getImagesInZipFormat(requestConfig, this.handleimageDownloadResponse, this.handleImageDownloadError, this.wishlistUid);
    },
    /**
         * This function handles the response for downloading product images in zip format
         */
    handleimageDownloadResponse(response) {
      const filename = this.i18n.listDetails.defaultDownloadedFilename;
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, this.i18n.listDetails.defaultDownloadedFilename);
        this.$refs.downloadImagePdfModal.close();
      }
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$refs.downloadImagePdfModal.close();
    },
    /**
         * This function handles the error for downloading product images in zip format
         */
    handleImageDownloadError() {
      this.showFlyout(flyoutStatus.error, this.i18n.listDetails.imageDownloadError, true);
    },
    /**
         * This function handles the emit for downloading pdf
         * @param  {Object} pdfInfo - pdf information of the products
         */
    onPdfDownloadInit(pdfInfo) {
      this.pdfInfo = pdfInfo;
      this.fromPdfModal = true;
      this.featureProducts = pdfInfo.featureProducts;
      this.onInit(true);
    },
    /**
         * This function identifies the format for downloading pdf
         */
    identifyPdfFormat() {
      if (this.pdfInfo.featureCheckedItems === generatePdf.fullDetails && this.featureProducts.length) {
        this.createCustomStylePDF();
      } else {
        switch (this.pdfInfo.formatColumns) {
          case 'Display in one column':
            this.createOneColumnFormat();
            break;
          case 'Display in two columns':
            this.createTwoColumnFormat();
            break;
          case 'Display in three columns':
            this.createThreeColumnFormat();
            break;
          case 'Display as full detail':
            this.createPdpFormat();
            break;
          default:
            this.createOneColumnFormat();
        }
      }
    },
    /**
         * This function handles formatting for multi select layout
         */
    createCustomStylePDF() {
      this.$refs.pdfModule.createCustomStylePDF(this.pdfInfo, this.featureProducts);
      this.$refs.downloadImagePdfModal.close();
    },
    /**
         * This function handles formatting for one column layout
         */
    createOneColumnFormat() {
      this.$refs.pdfModule.createOneColumnFormat(this.pdfInfo);
      this.loadPdfGenerator = false;
      this.$refs.downloadImagePdfModal.close();
    },
    /**
         * This function handles formatting for two column layout
         */
    createTwoColumnFormat() {
      this.$refs.pdfModule.createTwoColumnFormat(this.pdfInfo);
      this.loadPdfGenerator = false;
      this.$refs.downloadImagePdfModal.close();
    },
    /**
         * This function handles formatting for three column layout
         */
    createThreeColumnFormat() {
      this.$refs.pdfModule.createThreeColumnFormat(this.pdfInfo);
      this.loadPdfGenerator = false;
      this.$refs.downloadImagePdfModal.close();
    },
    /**
         * This function creates pdp format
         */
    createPdpFormat() {
      this.$refs.pdfModule.createListPdfPDPFormat(this.pdfInfo);
      this.loadPdfGenerator = false;
      this.$refs.downloadImagePdfModal.close();
    },
    /**
         * This function handles errors for pdp format
         */
    handleListPdpFormatError() {
      // this.$refs.spinner.hideSpinner();
    },
    /**
         * This function handles response for pdp format
         */
    handleListPdpFormatResponse(response) {
      // this.$refs.spinner.hideSpinner();
      if (response) {
        this.pdpListdata = response.data.products;
      }
    },
    /**
         * This function limits product description to 500 characters in pdf
         */
    limitCharacters(data) {
      let newData = data.length > 500 ? `${data.substring(0, 500)}...` : data;
      newData = this.replaceText(newData);
      return newData;
    },
    /**
         * This function replaces special non ASCII characters in pdf
         */
    replaceText(text) {
      const mapObj = {
        '™': '(TM)',
        '’': '\'',
        '–': '-',
        '<br>': '',
        '&bdquo;': '',
        '&euro;': 'E',
        '&ldquo;': '"',
        '”': '"',
        '„': ',',
        '€': 'E',
        '“': '"',
      };
      const newText = text.replace(/™|’|–|<br>|&bdquo;|&euro;|&ldquo;|”|„|€|“/gi, (matched) => mapObj[matched]);
      return newText;
    },
    /**
         * This function downloads excel with product info
         */
    downloadExcel(excelDownloadOption) {
      this.$refs.spinner.showSpinner();
      const requestConfig = {};
      requestConfig.params = {
        listkey: excelDownloadOption,
      };
      this.manageProfileShoppingListService.getExcelFile(requestConfig, this.handleExcelDownloadResponse, this.handleExcelDownloadError, this.wishlistUid);
    },
    /**
         * This function handles response for download excel
         */
    handleExcelDownloadResponse(response) {
      this.$refs.spinner.hideSpinner();
      const disposition = response.headers['content-disposition'];
      const reg = /filename[^;=\n]*=(UTF-8(['"]*))?(.*)/;
      const matches = reg.exec(disposition);
      const filename = matches != null && matches[3] ? matches[3] : this.i18n.listDetails.defaultExcelFilename;
      // const filename = 'AllProducts.zip';
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, this.i18n.listDetails.defaultExcelFilename);
        this.$refs.downloadProductExcelModal.close();
      }
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$refs.downloadProductExcelModal.close();
    },
    /**
         * This function handles error for download excel
         */
    handleExcelDownloadError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function removes a category and all its products from the list page
         */
    handleRemoveCategoryClick(evt, categoryGroup) {
      const productArray = categoryGroup.value.wishlistEntryGroup;
      const productString = productArray.map((entry) => entry.product.code).join(',');
      const requestConfig = {
        data: {
          wishlistUid: this.wishlistUid,
          entryType: 'PRODUCT',
          category: categoryGroup.key,
          product: {
            code: productString,
          },
        },
      };

      this.manageProfileShoppingListService.removeCategory(requestConfig, this.handleRemoveCategoryResponse, this.handleRemoveCategoryError);
    },
    /**
         * This function handles response for remove a category
         */
    handleRemoveCategoryResponse() {
      this.onInit();
    },
    /**
         * This function handles error for remove a category
         */
    handleRemoveCategoryError() {
    },
    /**
         * This function disables add to cart button
         */
    disableAddToCart() {
      this.addToCartDisabled = true;
    },
    /**
         * This function sets the page url and adds params based on how it was navigated to
         */
    setListPageUrl() {
      let wuid = '';
      if (!this.globals.loggedIn) {
        wuid = this.readCookie(cookies.guestListUid) ? this.readCookie(cookies.guestListUid) : '';
      } else {
        wuid = this.wishlistUid;
      }
      const url = `${this.globals.getNavigationUrl('listDetails')}/?listName=${wuid}`;
      window.history.replaceState({}, 'List Detail', url);
      this.listName = this.globals.getUrlParam('listName');
      this.selectedLists.push(this.listName);
    },
    /**
         * This function closes add product popover
         */
    closeAddProductPopover() {
      this.canAddProductsToList = false;
    },
    verifyIfNotifyMeDispaly(productEntry) {
      return this.shouldNotifymeDisplayedForProduct(productEntry.product, this.pages.wishList);
    },
    shouldAddTocartbeDisabled(productEntry) {
      return this.shouldAddTocartDisabledForProduct(productEntry.product, this.pages.wishList);
    },
  },
  updated() {
    if (document.body.scrollHeight > window.innerHeight && document.getElementsByClassName('add-to-cart-container')[0] && document.getElementsByClassName('footer-section')[0]) {
      document.getElementsByClassName('footer-section')[0].style.paddingBottom = '70px';
    }
  },
};
