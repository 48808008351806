var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-permisions-landing-page pt-xs-4 pt-sm-5" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-between " }, [
        _c("h3", { staticClass: "header mt-xs-0" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.i18n.permissionsHeading) +
              "\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row d-flex px-xs-3" }, [
        _c("div", { staticClass: "col-sm-6 col-xs-12 py-xs-2 py-sm-3" }, [
          _c("div", { staticClass: "row vx-profile-card d-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 add-card card-default-height mb-xs-3 px-xs-3 py-xs-3 pl-sm-4 pr-sm-3 add-new-permission"
              },
              [
                _c("div", { staticClass: "add-permission-wrapper" }, [
                  _c("span", {
                    staticClass: "icon-big icon-plus",
                    attrs: { title: "Add", "aria-hidden": "true" }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "btn-link add-create-btn",
                        attrs: {
                          href: "javascript:void(0)",
                          role: "button",
                          "aria-label": _vm.i18n.createPermissionLink
                        },
                        on: {
                          click: function($event) {
                            _vm.openPermissionsModal($event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.createPermissionLink) + " ")]
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.isMobile()
        ? _c(
            "div",
            { staticClass: "mb-xs-3" },
            [
              _c("vx-dropdown-primary", {
                ref: "sortDropdown",
                attrs: { dropdownValues: _vm.MobileSortOptions },
                on: {
                  "selected-option": function($event) {
                    _vm.triggerSort($event, "#permissions-data-table")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("vx-table", {
        attrs: {
          tableConfig: _vm.tableConfig,
          tableData: _vm.permissions.permissions
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "permissionModal",
          attrs: { size: "large", heading: _vm.i18n.createHeading }
        },
        [
          _c("vx-permissions-form", {
            attrs: {
              slot: "component",
              type: "modal",
              isEdit: false,
              "time-period-values": _vm.i18n.timeSpanLabels,
              i18n: _vm.i18n
            },
            on: {
              showSpinner: _vm.showLoadingSpinner,
              hideSpinner: _vm.hideLoadingSpinner,
              fetchAllPermission: _vm.fetchPermissinons,
              closePermissionsForm: _vm.closePermissionsModal
            },
            slot: "component"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2da09bf4", { render: render, staticRenderFns: staticRenderFns })
  }
}