// import login from './login-i18n';
import { Validator } from 'vee-validate';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxForgotPassword from '../../registration-login/vx-forgot-password/vx-forgot-password.vue';
import globals from '../../../../components/common/globals';
import loginMixin from '../../../../components/common/mixins/login-mixin';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import headerMixin from '../../../../components/common/mixins/header-mixin';
import detectDeviceMixin from '../../../../components/common/mixins/detect-device-mixin';
import { globalEventBus } from '../../../../modules/event-bus';
import cookiesMixin from '../../../../components/common/mixins/cookies-mixin';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';

export default {
  name: 'vx-gpxpress-login',
  mixins: [
    loginMixin,
    flyoutBannerMixin,
    headerMixin,
    detectDeviceMixin,
    cookiesMixin,
  ],
  props: {
    actionUrl: String,
    i18n: Object,
  },
  components: {
    vxModal,
    vxForgotPassword,
    vxSpinner,
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
        profile: '',
      },
      // i18n: login,
      globals,
      showModal: true,
      disableField: false,
      showPassword: false,
      serverError: '',
      token: '',
      isCheckout: false,
      gpProLoginRememberMe: false,
    };
  },
  methods: {
    /**
     * This function is called on click of submit button.
     * @param {Object} e contains the event of the element.
     * On successfull validation submitlogin is called else focus is set back to first field in the form.
     */
    submitClicked(e) {
      const self = this;
      e.preventDefault();
      this.$refs.spinner.showSpinner();
      this.$validator.validateAll().then((result) => {
        if (localStorage) {
          const usernameToSave = self.user.email && self.gpProLoginRememberMe ? self.user.email : '';
          window.localStorage.setItem('gpProLoginUsername', usernameToSave);
        }
        if (result) {
          if (self.readCookie('guid_user') === 'current') {
            self.showFlyout('error', this.i18n.activeLoginSession, true);
            this.$refs.spinner.hideSpinner();
          } else {
            this.createCookie('estores_profile', this.user.profile);
            self.submitLogin();
          }
        } else {
          self.globals.setFocusByName(self.$el, self.globals.getElementName(self.errors));
          this.$refs.spinner.hideSpinner();
        }
      });
    },
    /**
     * This function toggles the password on hiding and showing the text.
     */
    togglePassword() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.$refs.password.type = 'text';
      } else {
        this.$refs.password.type = 'password';
      }
    },
    /**
     * This function is called on clicking forgot password link
     * @param {Object} callingParent contains the event of the link
     * Opens forgotPasswordModal by passing the event as a param.
     */
    handleForgotPassword(callingParent) {
      this.$refs.forgotPasswordModal.open(callingParent);
    },
    createAccount() {
      this.createAccountButHandler(this.user.profile);
    },
    handleErrorCallback(errorData) {
      if (errorData.response.data.error_description === 'Reset credentials') {
        this.disableField = true;
        this.showFlyout('error', this.i18n.invalidAttemptsError, true);
      } else {
        this.serverError = errorData.response.data.error_description;
      }
    },
    hideServerError() {
      this.serverError = '';
    },
    setLoginUsernames() {
      if (localStorage) {
        this.user.email = window.localStorage.getItem('gpProLoginUsername');
        if (this.user.email) {
          this.gpProLoginRememberMe = true;
        }
      }
    },
    isASM() {
      return document.getElementById('_asm');
    },
  },
  mounted() {
    this.handleUserUpdateSuccesMsg();
    globalEventBus.$emit('announce', 'Login Page');
    this.handleLoginErrorCode();
    const veeCustomErrorMessage = {
      en: {
        custom: {
          j_username: {
            required: this.i18n.emailRequiredError,
            email: this.i18n.emailInvalidError,
          },
          j_password: {
            required: this.i18n.passwordRequiredError,
            regex: this.i18n.passwordInvalidError,
          },
          profileName: {
            required: this.i18n.profileNameRequiredError,
            regex: this.i18n.profileNameInvalidError,
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
    if (this.globals.getCookie('flow') === 'checkout' || this.globals.getIsSubscription()) {
      this.isCheckout = true;
    }
    this.user.profile = this.globals.getUrlParam('profileName') || this.globals.getCookie('estores_profile') || '';
    this.setLoginUsernames();
  },
};
