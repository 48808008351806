var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "vx-cart-product-tile" },
    [
      _c(
        "vx-modal",
        {
          ref: "addInstallationModal",
          attrs: { size: "small", heading: _vm.i18n.addInstallationHeading }
        },
        [
          _c("vx-add-installation", {
            attrs: {
              slot: "component",
              type: "modal",
              "install-details": _vm.installable,
              i18n: _vm.i18n
            },
            on: {
              "add-installation-success": _vm.handleAddInstallatioSuccess,
              "add-installation-error": _vm.handleAddInstallatioError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "removeInstallationModal",
          attrs: { size: "small", heading: _vm.i18n.removeInstallationHeading }
        },
        [
          _c("vx-remove-installation", {
            attrs: { slot: "component", type: "modal", i18n: _vm.i18n },
            on: { "remove-installation-success": _vm.removeInstallation },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "installationDetailsModal",
          attrs: { size: "small", heading: _vm.i18n.installationDetailsHeading }
        },
        [
          _c("vx-installation-details", {
            attrs: {
              slot: "component",
              type: "modal",
              "install-details": _vm.installable
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addGiftModal",
          attrs: { size: "large", heading: _vm.i18n.giftHeading }
        },
        [
          _c("vx-gifting-details", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n,
              "giftable-details": _vm.giftable
            },
            on: {
              giftDetailsClose: function($event) {
                _vm.$refs.addGiftModal.close()
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.isMiniCart
        ? _c(
            "div",
            {
              staticClass: "row mini-cart-product-tile d-flex py-xs-3 mx-xs-0"
            },
            [
              _c("div", { staticClass: "col-xs-3 px-xs-0" }, [
                _vm.productData &&
                _vm.productData.product &&
                _vm.productData.product.images &&
                _vm.productData.product.images.length !== 0 &&
                _vm.productData.product.images[2] &&
                _vm.productData.product.images[2].url
                  ? _c("div", { staticClass: "product-img px-xs-1 py-xs-2" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.globals.getNavigationUrl("empty") +
                              _vm.productData.product.url
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.globals.getThumbnailImageUrl(
                                _vm.productData.product.images
                              ),
                              alt: _vm.productData.product.images[2].altText
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.productData.product.images
                  ? _c("div", { staticClass: "product-img px-xs-1 py-xs-2" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.globals.getNavigationUrl("empty") +
                              _vm.productData.product.url
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.globals.assetsPath + "images/no_image.svg",
                              alt: "No Image"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-xs-9 px-xs-0 d-flex flex-column justify-content-between"
                },
                [
                  _c(
                    "div",
                    { staticClass: "price-align" },
                    [
                      _vm.productData.product.name
                        ? [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "product-title mb-xs-0 col-xs-12 px-xs-0"
                              },
                              [
                                _c("a", {
                                  attrs: {
                                    href:
                                      _vm.globals.getNavigationUrl("empty") +
                                      _vm.productData.product.url
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.productData.product.name
                                    )
                                  }
                                })
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.isSampleCart
                    ? _c("div", { staticClass: "col-xs-12 px-xs-0 pt-xs-2" }, [
                        _vm.productData.product.code &&
                        _vm.globals.siteConfig.isProductIdEnabled
                          ? _c("span", { staticClass: "product-codes" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.i18n.manufactureID) +
                                  "  " +
                                  _vm._s(_vm.productData.product.code) +
                                  "\n        "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.productData.product.cmirCode &&
                        _vm.globals.siteConfig.isProductIdEnabled
                          ? _c("span", { staticClass: "product-codes" }, [
                              _vm._v(
                                " |\n          " +
                                  _vm._s(_vm.i18n.customerID) +
                                  "  " +
                                  _vm._s(_vm.productData.product.cmirCode) +
                                  "\n        "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _vm.productData.product.notPurchasable
                      ? _c(
                          "p",
                          { staticClass: "product-stock mb-xs-3 error-text" },
                          [_vm._v(_vm._s(_vm.i18n.notPurchasable))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.productData.product.notPurchasable
                      ? _c("p", { staticClass: "product-price mb-xs-3" }, [
                          _c("span", { staticClass: "product-current-price" }, [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Current price")
                            ]),
                            _vm._v(
                              _vm._s(_vm.productData.basePrice.formattedValue)
                            )
                          ]),
                          _vm._v(" "),
                          _vm.productData.product.weblistPrice &&
                          _vm.globals.siteConfig.isSlashedPriceEnabled
                            ? _c(
                                "span",
                                { staticClass: "product-old-price" },
                                [
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Slashed price")
                                  ]),
                                  _vm._v(" "),
                                  _c("strike", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.productData.product.weblistPrice
                                          .formattedValue
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.globals.isB2B()
                      ? _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                          _vm.productData &&
                          _vm.productData.productPromotion &&
                          _vm.productData.productPromotion.value
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "col-xs-8 product-promo mb-xs-3 pl-xs-0"
                                },
                                [
                                  _vm._v(
                                    "Promo: Coupon\n            Applied!\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.productData &&
                          _vm.productData.productPromotion &&
                          _vm.productData.productPromotion.value &&
                          _vm.productData.productPromotion.value !=
                            _vm.discount.discountZero
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "col-xs-4 product-discount text-right"
                                },
                                [
                                  _vm._v(
                                    "\n            -" +
                                      _vm._s(
                                        _vm.productData.productPromotion
                                          .formattedValue
                                      )
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", { staticClass: "product-quantity" }, [
                      _vm._v(_vm._s(_vm.i18n.quantity))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "cart-quantity-align" },
                      [
                        _c("vx-stepper-control", {
                          attrs: {
                            "max-value": _vm.maxOrderQuantity(
                              _vm.productData.product.maxOrderQuantity,
                              _vm.productData.quantity,
                              _vm.productData.giveAway
                            ),
                            value: _vm.productData.quantity,
                            "min-value": _vm.minOrderQuantity(
                              _vm.productData.product.minOrderQuantity,
                              _vm.productData.quantity,
                              _vm.productData.giveAway
                            ),
                            "stock-level": _vm.setStockLevel(
                              _vm.productData.product.stock.stockLevel
                            )
                          },
                          on: {
                            currentCount: function($event) {
                              _vm.handleQuantity(
                                _vm.productData.entryNumber,
                                $event
                              )
                            },
                            maxValueUpdated: _vm.maxValueUpdated,
                            minValueUpdated: _vm.minValueUpdated,
                            stockLevelUpdated: _vm.stockLevelUpdated
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.isSampleCart && !_vm.productData.product.notPurchasable
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "col-xs-6 product-total-price pt-xs-2 d-flex justify-content-end px-xs-0"
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.productData.totalPrice.formattedValue
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMiniCart
        ? _c(
            "div",
            {
              staticClass:
                "row shopping-cart-product-tile mt-sm-5 mb-sm-0 mb-xs-3",
              class: { "error-border": _vm.leaseableError }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "col-xs-12 px-sm-4 pt-sm-4 pb-sm-2 px-xs-3 py-xs-3 d-flex"
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-xs-3 col-sm-4 col-md-3 px-xs-0" },
                    [
                      _vm.productData &&
                      _vm.productData.product &&
                      _vm.productData.product.images &&
                      _vm.productData.product.images.length !== 0 &&
                      _vm.productData.product.images[2] &&
                      _vm.productData.product.images[2].url
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "product-img px-md-2 py-md-4 px-sm-1 py-sm-3 px-xs-1 py-xs-2"
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.globals.getNavigationUrl("empty") +
                                      _vm.productData.product.url
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.globals.getThumbnailImageUrl(
                                        _vm.productData.product.images
                                      ),
                                      alt:
                                        _vm.productData.product.images[2]
                                          .altText
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.productData.product.images
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "product-img px-md-2 py-md-4 px-sm-1 py-sm-3 px-xs-1 py-xs-2"
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.globals.getNavigationUrl("empty") +
                                      _vm.productData.product.url
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.globals.assetsPath +
                                        "images/no_image.svg",
                                      alt: "No Image"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.productIconsData
                        ? _c("vx-product-icons", {
                            attrs: {
                              "product-icons": _vm.productIconsData,
                              i18n: _vm.i18n.productIconsTitle
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.installable.isInstallable &&
                      _vm.globals.siteConfig.installationSchedulingEnabled
                        ? _c(
                            "div",
                            { staticClass: "installation" },
                            [
                              !_vm.installable.isInstalled
                                ? [
                                    _c("span", {
                                      staticClass: "icon-alert-circle",
                                      attrs: {
                                        title: _vm.i18n.iconAlertTitle,
                                        "aria-label": _vm.i18n.iconAlertTitle,
                                        tabindex: "0",
                                        role: "button"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.openInstallationDetailsModal(
                                            $event
                                          )
                                        },
                                        keyup: function($event) {
                                          if (
                                            !("button" in $event) &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter-space",
                                              undefined,
                                              $event.key,
                                              undefined
                                            )
                                          ) {
                                            return null
                                          }
                                          _vm.openInstallationDetailsModal(
                                            $event
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          tabindex: "0",
                                          "aria-label":
                                            _vm.i18n.addInstallationHeading
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.openAddInstallationModal($event)
                                          },
                                          keyup: function($event) {
                                            if (
                                              !("button" in $event) &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter-space",
                                                undefined,
                                                $event.key,
                                                undefined
                                              )
                                            ) {
                                              return null
                                            }
                                            _vm.openAddInstallationModal($event)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.i18n.addInstallationHeading
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.giftable.isGiftable &&
                      _vm.globals.siteConfig.isGiftingEnabled
                        ? _c("div", { staticClass: "gift" }, [
                            _c("span", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.giftable.isGiftOpted,
                                    expression: "giftable.isGiftOpted"
                                  }
                                ],
                                attrs: {
                                  id: _vm.productData.product.code,
                                  type: "checkbox"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.giftable.isGiftOpted
                                  )
                                    ? _vm._i(_vm.giftable.isGiftOpted, null) >
                                      -1
                                    : _vm.giftable.isGiftOpted
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.giftable.isGiftOpted,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.giftable,
                                              "isGiftOpted",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.giftable,
                                              "isGiftOpted",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.giftable,
                                          "isGiftOpted",
                                          $$c
                                        )
                                      }
                                    },
                                    _vm.editGift
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: { for: _vm.productData.product.code }
                                },
                                [_vm._v(_vm._s(_vm.i18n.giftCheckbox))]
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "icon-alert-circle",
                              attrs: {
                                tabindex: "0",
                                title: _vm.i18n.iconAlertTitle,
                                "aria-label": _vm.i18n.iconAlertTitle,
                                role: "button"
                              },
                              on: {
                                click: function($event) {
                                  _vm.openGiftModal($event)
                                },
                                keyup: function($event) {
                                  if (
                                    !("button" in $event) &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter-space",
                                      undefined,
                                      $event.key,
                                      undefined
                                    )
                                  ) {
                                    return null
                                  }
                                  _vm.openGiftModal($event)
                                }
                              }
                            })
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-9 col-sm-8 col-md-9 pr-xs-0 pl-xs-3 pr-sm-0 pl-sm-4 pl-md-4"
                    },
                    [
                      _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                        _c(
                          "div",
                          { staticClass: "col-xs-10 px-xs-0" },
                          [
                            _vm.leaseableError
                              ? _c(
                                  "p",
                                  { staticClass: "lease-product-error" },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "error-icon icon-alert-triangle",
                                      attrs: { title: _vm.i18n.iconAlertTitle }
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "error-msg" }, [
                                      _vm._v(_vm._s(_vm.i18n.leaseProductError))
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.productData.product.name
                              ? [
                                  _c(
                                    "p",
                                    { staticClass: "product-title mb-xs-2" },
                                    [
                                      _c("a", {
                                        attrs: {
                                          href:
                                            _vm.globals.getNavigationUrl(
                                              "empty"
                                            ) + _vm.productData.product.url
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.productData.product.name
                                          )
                                        }
                                      })
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.isProductRemovable
                          ? _c("span", { staticClass: "col-xs-2 px-xs-0" }, [
                              !_vm.productData.giveAway
                                ? _c("span", {
                                    staticClass:
                                      "cart-icons icon-trash shift-right",
                                    attrs: {
                                      tabindex: "0",
                                      role: "button",
                                      "aria-label": _vm.i18n.iconDeleteTitle
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.deleteCartItem(
                                          _vm.productData.entryNumber,
                                          $event
                                        )
                                      },
                                      keyup: function($event) {
                                        if (
                                          !("button" in $event) &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter-space",
                                            undefined,
                                            $event.key,
                                            undefined
                                          )
                                        ) {
                                          return null
                                        }
                                        _vm.deleteCartItem(
                                          _vm.productData.entryNumber,
                                          $event
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(
                          _vm._s(_vm.i18n.itemId) +
                            " : " +
                            _vm._s(_vm.productData.product.code)
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "product-code mb-xs-2",
                          attrs: { "aria-hidden": "true" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.i18n.itemId) +
                              " " +
                              _vm._s(_vm.productData.product.code)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.globals.siteConfig.enableDisplayAttributes &&
                      _vm.productData.product.displayAttributes
                        ? _c("div", {
                            staticClass: "display-attributes",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.displayAttributes(
                                  _vm.productData.product.displayAttributes
                                )
                              )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.globals.isB2B()
                        ? _c("div", [
                            _vm.productData &&
                            _vm.productData.product &&
                            (_vm.productData.product.materialStatus ===
                              "OBSOLETE" ||
                              _vm.productData.product.materialStatus ===
                                "PREDISCONTINUED") &&
                            _vm.productData.product.replacementProductCode &&
                            _vm.productData.product.replacementProductCode !==
                              ""
                              ? _c("p", { staticClass: "error-text" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.i18n.discontinued) +
                                      " " +
                                      _vm._s(_vm.i18n.replacedBy) +
                                      " " +
                                      _vm._s(
                                        _vm.productData.product
                                          .replacementProductCode
                                      ) +
                                      "\n        "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.productData &&
                            _vm.productData.product &&
                            (_vm.productData.product.materialStatus ===
                              "OBSOLETE" ||
                              _vm.productData.product.materialStatus ===
                                "PREDISCONTINUED") &&
                            !_vm.productData.product.replacementProductCode
                              ? _c("p", { staticClass: "error-text" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.i18n.discontinued) +
                                      "\n        "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.productData &&
                      _vm.productData.product &&
                      _vm.productData.product.materialStatus !== "OBSOLETE" &&
                      _vm.productData.product.materialStatus !==
                        "PREDISCONTINUED"
                        ? [
                            _vm.productData.product &&
                            _vm.productData.product.stock &&
                            _vm.productData.product.stock.stockLevelStatus ===
                              _vm.ProductAvailability.LOW_STOCK &&
                            _vm.globals.siteConfig.showLowInventoryMessage
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "product-stock mb-xs-3 text-danger",
                                    attrs: { role: "status", tabindex: "0" }
                                  },
                                  [_vm._v(_vm._s(_vm.i18n.lowInventoryMessage))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.productData.product &&
                            _vm.productData.product.stock &&
                            (_vm.productData.product.stock.stockLevelStatus !==
                              _vm.ProductAvailability.LOW_STOCK ||
                              _vm.productData.product.stock.stockLevelStatus !==
                                _vm.ProductAvailability.IN_STOCK)
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "product-stock mb-xs-3",
                                    attrs: { role: "status", tabindex: "0" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.productData.product.stock
                                          .lowStockMessage
                                      )
                                    )
                                  ]
                                )
                              : _vm.productData.product.stock
                                  .stockLevelStatus ===
                                  _vm.ProductAvailability.OUT_OF_STOCK &&
                                !_vm.productData.product.stock.hasOwnProperty(
                                  "nextAvailableDate"
                                )
                              ? _c(
                                  "p",
                                  { staticClass: "product-stock mb-xs-3" },
                                  [_vm._v(_vm._s(_vm.i18n.outOfStock))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.productData.product.stock.nextAvailableDate
                              ? _c(
                                  "p",
                                  { staticClass: "product-stock pb-md-1" },
                                  [
                                    _vm._v(
                                      "(" +
                                        _vm._s(_vm.i18n.backOderabledate) +
                                        "\n            " +
                                        _vm._s(
                                          _vm.productData.product.stock
                                            .nextAvailableDate
                                        ) +
                                        ")\n          "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.productData.product.notPurchasable
                        ? _c(
                            "p",
                            { staticClass: "product-stock mb-xs-3 error-text" },
                            [_vm._v(_vm._s(_vm.i18n.notPurchasable))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.productData.product.notPurchasable
                        ? _c("p", { staticClass: "product-price mb-xs-3" }, [
                            _c(
                              "span",
                              { staticClass: "product-current-price" },
                              [
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Current price")
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.productData.basePrice.formattedValue
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.productData.product.weblistPrice &&
                            _vm.globals.siteConfig.isSlashedPriceEnabled
                              ? _c(
                                  "span",
                                  { staticClass: "product-old-price" },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Slashed price")
                                    ]),
                                    _vm._v(" "),
                                    _c("strike", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.productData.product.weblistPrice
                                            .formattedValue
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-8 px-xs-0" },
                          [
                            _vm.promotionData[_vm.productData.entryNumber]
                              ? _c(
                                  "p",
                                  { staticClass: "product-promo mb-xs-3" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.i18n.promo) +
                                        ":\n              " +
                                        _vm._s(
                                          _vm.promotionData[
                                            _vm.productData.entryNumber
                                          ]
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", { staticClass: "product-quantity" }, [
                              _vm._v(_vm._s(_vm.i18n.quantity))
                            ]),
                            _vm._v(" "),
                            !_vm.isBundleProduct
                              ? _c("vx-stepper-control", {
                                  attrs: {
                                    "max-value": _vm.maxOrderQuantity(
                                      _vm.productData.product.maxOrderQuantity,
                                      _vm.productData.quantity,
                                      _vm.productData.giveAway
                                    ),
                                    value: _vm.productData.quantity,
                                    "min-value": _vm.minOrderQuantity(
                                      _vm.productData.product.minOrderQuantity,
                                      _vm.productData.quantity,
                                      _vm.productData.giveAway
                                    ),
                                    "stock-level": _vm.setStockLevel(
                                      _vm.productData.product.stock.stockLevel
                                    )
                                  },
                                  on: {
                                    currentCount: function($event) {
                                      _vm.handleQuantity(
                                        _vm.productData.entryNumber,
                                        $event
                                      )
                                    },
                                    maxValueUpdated: _vm.maxValueUpdated,
                                    minValueUpdated: _vm.minValueUpdated,
                                    stockLevelUpdated: _vm.stockLevelUpdated
                                  }
                                })
                              : _c("vx-stepper-control", {
                                  attrs: {
                                    "is-disabled": "true",
                                    "max-value": _vm.maxOrderQuantity(
                                      _vm.productData.product.maxOrderQuantity,
                                      _vm.productData.quantity,
                                      _vm.productData.giveAway
                                    ),
                                    value: _vm.productData.quantity,
                                    "min-value": _vm.minOrderQuantity(
                                      _vm.productData.product.minOrderQuantity,
                                      _vm.productData.quantity,
                                      _vm.productData.giveAway
                                    ),
                                    "stock-level": _vm.setStockLevel(
                                      _vm.productData.product.stock.stockLevel
                                    )
                                  },
                                  on: {
                                    currentCount: function($event) {
                                      _vm.handleQuantity(
                                        _vm.productData.entryNumber,
                                        $event
                                      )
                                    },
                                    maxValueUpdated: _vm.maxValueUpdated,
                                    minValueUpdated: _vm.minValueUpdated,
                                    stockLevelUpdated: _vm.stockLevelUpdated
                                  }
                                })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-4 px-xs-0" },
                          [
                            _vm.productData &&
                            _vm.productData.productPromotion &&
                            _vm.productData.productPromotion.value &&
                            _vm.productData.productPromotion.value !=
                              _vm.discount.discountZero
                              ? _c("p", { staticClass: "product-discount" }, [
                                  _vm._v(
                                    "-" +
                                      _vm._s(
                                        _vm.productData.productPromotion
                                          .formattedValue
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "product-total-price",
                                attrs: {
                                  role: "text",
                                  "aria-label":
                                    "Price" +
                                    _vm.productData.totalPrice.formattedValue
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.productData.totalPrice.formattedValue
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ],
                    2
                  )
                ]
              ),
              _vm._v(" "),
              _vm.installable.isInstalled
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 installation-added py-sm-3 px-sm-4 py-xs-3 px-xs-3"
                    },
                    [
                      _c("div", { staticClass: "col-xs-8 col-sm-10 px-xs-0" }, [
                        _c("p", { staticClass: "installation-text" }, [
                          _vm._v(_vm._s(_vm.i18n.installationMsg))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-4 col-sm-2 px-xs-0 d-flex justify-content-end"
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "installation-price d-flex flex-row"
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.installationPrice[
                                        _vm.productData.entryNumber
                                      ]
                                    )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "icon-x pl-sm-4 pl-xs-2",
                                attrs: {
                                  role: "button",
                                  tabindex: "0",
                                  title: _vm.i18n.iconCloseTitle,
                                  "aria-label": _vm.i18n.iconCloseTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.openRemoveInstallationModal($event)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.openRemoveInstallationModal($event)
                                  }
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-530b0d6d", { render: render, staticRenderFns: staticRenderFns })
  }
}