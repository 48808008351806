import Drift from 'drift-zoom';
import {
  eventBus,
  globalEventBus,
} from '../../../../modules/event-bus';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import vxShareItem from '../vx-share-item/vx-share-item.vue';
import vxBannerCarousel from '../../view-site-content/vx-banner-carousel/vx-banner-carousel.vue';
import vxProductIcons from '../../common/vx-product-icons/vx-product-icons.vue';
import {
  PdpCarouselConfig,
  product3DHeightBreakPoints,
  flyoutStatus,
} from '../../../../components/common/mixins/vx-enums';
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import vxPdfGenerator from '../../common/vx-pdf-generator/vx-pdf-generator.vue';
import vxDownloadInfo from '../../vx-download-info/vx-download-info.vue';
import PdpService from '../../../../components/common/services/pdp-service';
import AnalyticsService from '../../../../components/common/services/analytics-service';

export default {
  name: 'vx-thumbnail-viewer',
  components: {
    vxModal,
    vxShareItem,
    vxBannerCarousel,
    vxProductIcons,
    vxPdfGenerator,
    vxDownloadInfo,
  },
  props: {
    // Copy text coming from properties files
    i18n: {
      type: Object,
    },
    // indicates whether the site is configured for pdf download
    showDownload: {
      type: String,
      default: '',
    },
    // indicates whether the site is configured for favorites
    isFavorites: {
      type: Boolean,
      default: false,
    },
    // Variable to pass to child elements
    guestListName: {
      type: String,
    },
    // Details of the products
    pdpProductInfoData: {
      type: Object,
    },
    // Color codes for PDP download
    colorCodes: {
      type: Object,
      default: {},
    },
    // Variables to be passed into child elements
    pdpPdfDownloaded: {
      type: Boolean,
    },
    // ID of product
    productId: {
      type: String,
      default: '',
    },
    productCode: {
      type: String,
      default: '',
    },
    onlyEncodedImage: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [mobileMixin, flyoutBannerMixin],
  data() {
    return {
      globals,
      flyoutStatus,
      currentCarouselData: [],
      totalCarouselData: [],
      thumbnailObj: {},
      isSlidingToPrevious: false,
      totalCount: 0,
      currentTopIndex: 0,
      currentBottomIndex: 0,
      itemsToDisplay: 5,
      totalImagesCount: 99,
      totalVideoCount: 99,
      selectedItem: '',
      modalHeading: 'PDP Image',
      mobileCarouselObj: {},
      PdpCarouselConfig,
      isResponseHandled: false,
      iFrameHeight: Number,
      productIconsData: [],
      playButtonClicked: false,
      prodUrl: '',
      prodId: this.productId,
      pdpProductInfo: {
        name: '',
        stock: '',
        price: '',
        baseOptions: '',
        featureList: '',
      },
      certificationsName: '',
      loadPdfGenerator: false,
      pdpService: new PdpService(),
      analyticsService: new AnalyticsService(),
      driftEnabled: false,
      drift: {},
      driftMobileCount: 0,
    };
  },
  created() {
    this.setCarouselName();
  },
  mounted() {
    // At first show only 5 items
    const self = this;
    if (this.isMobile()) {
      this.iFrameHeight = product3DHeightBreakPoints.mobile;
    } else if (this.isTablet()) {
      this.iFrameHeight = product3DHeightBreakPoints.tablet;
    } else {
      this.iFrameHeight = product3DHeightBreakPoints.desktop;
    }
    this.loadPdfGenerator = true;
    eventBus.$on('totalCarouselData', (data) => {
      self.thumbnailObj = data;
      self.isResponseHandled = true;
      if (data && data.images && data.images.length > 0) {
        const totalCarouselData = data.images.filter((item) => item.mimeType);
        if (totalCarouselData.length > 0) {
          for (let i = 0; i < totalCarouselData.length; i += 1) {
            const lgImgURL = totalCarouselData[i].zoomUrl.replace('515Wx515H', '2000Wx2000H');
            totalCarouselData[i].largeImage = lgImgURL;
          }
          let imagesArr = totalCarouselData.filter((item) => item.mimeType.indexOf('image') >= 0);
          imagesArr = !globals.siteConfig.pdpimageOrVideoConstraintEnabled ? imagesArr
            : imagesArr.slice(0, self.totalImagesCount);
          let videosArr = totalCarouselData.filter((item) => item.mimeType.indexOf('video') >= 0);
          videosArr = !globals.siteConfig.pdpimageOrVideoConstraintEnabled ? videosArr
            : videosArr.slice(0, self.totalVideoCount);
          self.totalCarouselData = [...imagesArr, ...videosArr];
          self.mobileCarouselObj.loopCarousel = PdpCarouselConfig.CYCLE;
          self.mobileCarouselObj.autoStart = PdpCarouselConfig.AUTO_START;
          self.mobileCarouselObj.gpRotatingData = JSON.parse(JSON.stringify(self.totalCarouselData));
          self.currentCarouselData = self.totalCarouselData.slice(self.currentTopIndex, self.itemsToDisplay);
          self.selectedItem = self.currentCarouselData[0];
          // Get Total Count
          self.totalCount = self.totalCarouselData.length;
          // Update current bottom index
          self.currentBottomIndex = self.itemsToDisplay;
          eventBus.$emit('carouselBinded');
        }
      }
      if (data && data.productIcons && data.productIcons.length) {
        this.productIconsData = data.productIcons;
      }
    });
    eventBus.$on('pdpProductInfo', (data) => {
      self.pdpProductInfo = data;
    });
    // If pdf param is true, trigger pdf modal
    if (this.$route.query.pdf === 'true') {
      this.$refs.iconDownload.click();
    }
    // To prevent the screen reader from announcing the element as a list
    setTimeout(() => {
      this.$el.querySelector('.thumbnail-container ul').setAttribute('role', 'presentation');
    }, 1000);
  },
  methods: {
    /**
         * Function moves the focus to top on click of the top arrow
         */
    moveTop() {
      this.isSlidingToPrevious = true;
      this.currentTopIndex += 1;
      this.currentBottomIndex -= 1;
      this.addToTopComputedArr(this.currentBottomIndex);
    },
    /**
         * Function moves the focus to bottom on click of the bottom arrow
         */
    moveBottom() {
      this.isSlidingToPrevious = false;
      this.currentTopIndex -= 1;
      this.currentBottomIndex += 1;
      this.addToBottomComputedArr(this.currentBottomIndex);
    },
    /**
         * Function updates the bottom array
         */
    addToBottomComputedArr(index) {
      // Splice the first item
      this.currentCarouselData.splice(0, 1);
      // Add the next item to the array
      this.currentCarouselData.push(this.totalCarouselData[index - 1]);
    },
    /**
         * Function updates the top array
         */
    addToTopComputedArr(index) {
      // Splice the last item
      this.currentCarouselData.splice(this.currentCarouselData.length - 1, 1);
      // Add item to the beginning of the array
      this.currentCarouselData.unshift(this.totalCarouselData[index - this.itemsToDisplay]);
    },
    /**
         * Function updates the main Product image when any of the images are clicked on
         */
    updatePicture(item) {
      this.playButtonClicked = false;
      this.selectedItem = item;
      globalEventBus.$emit('announce', `Selected Image ${item.altText}`);
    },
    /**
         * Function opens the modal when the Product image is clicked
         */
    expandPDPImage(event) {
      this.$refs.pdpImageModal.open(event);
    },
    /**
         * Function is used to set Carousel Name
         */
    setCarouselName() {
      this.mobileCarouselObj.componentId = PdpCarouselConfig.CAROUSEL_NAME;
    },

    enableDriftZoom(source) {
      let zoomTrigger = null;
      let paneContainer = null;

      if (source === 'mobile') {
        zoomTrigger = document.querySelectorAll('.vx-banner-carousel .selected-product-img')[this.driftMobileCount += 1];
        paneContainer = document.querySelector('.vx-pdp-product-info');
      } else if (source === 'desktop') {
        zoomTrigger = document.querySelector('.pdp-thumbnail .selected-product-img');
        paneContainer = document.querySelector('.pdp-product-description');
      }
      this.drift = new Drift(zoomTrigger, {
        paneContainer,
        inlineContainer: document.querySelector('#pdpProductCarousel'),
        sourceAttribute: 'data-zoom',
        inlineOffsetX: -30,
        inlineOffsetY: -250,
        hoverBoundingBox: true,
        boundingBoxContainer: document.querySelector('.main__inner-wrapper'),
      });

      this.drift.enable();
    },
    /**
         * Function is called when the download icon is clicked
         */
    handleDownloadList(event, defaultTab) {
      this.$refs.pdfModule.renderImage({
        id: 'img.pdf-itf1',
      });
      const requestConfig = {};
      this.pdpService.getSavedPdfDetails(requestConfig, this.handlePdfInfoResponse, this.handlePdfInfoError, this.certificationsName, this.prodId, this.onlyEncodedImage);
      this.$refs.downloadInfoModal.open(event);
      if (defaultTab === 'images') {
        setTimeout(() => {
          document.querySelector('#tab-image').click();
        }, 1);
      }
    },
    /**
         * Function is called on click of Share Icon which opens the Share Item Modal for B2B and Socila Share for B2C
         */
    handleShareItem(event) {
      if (this.globals.isB2B()) {
        this.$refs.shareItemModal.open(event);
      } else {
        eventBus.$emit('show-social-share', true);
      }
    },
    /**
         * Function is called on success emit of Share List component which closes the Share List Modal and shows a flyout banner with success message
         */
    onShareItemSuccess(success) {
      this.$refs.shareItemModal.close();
      const analyticsObject = {
        method: 'ShareViaEmail',
        contentType: 'ProductDetails',
        itemId: this.pdpProductInfo.code,
      };
      this.analyticsService.trackShareGA4(analyticsObject);
      this.showFlyout(this.flyoutStatus.success, success, true);
    },
    /**
         * Function is called on error emit of Share List component which closes the Select a List Modal and shows a flyout banner with error message
         */
    onShareItemError(error) {
      this.$refs.shareItemModal.close();
      this.showFlyout(this.flyoutStatus.error, error, true);
    },
    /**
         * Function handles the error of get PDP data service
         */
    handleErrorResponse() {},
    /**
         * Function creates pdf
         */
    createPDF(pdfInfo) {
      this.$refs.pdfModule.createPdfPDPFormat(pdfInfo);
    },
    /**
         * Function replaces text
         */
    replaceText(text) {
      const mapObj = {
        '™': '(TM)',
        '’': '\'',
        '–': '-',
        '<br>': '',
        '&bdquo;': '',
        '&euro;': 'E',
        '&ldquo;': '"',
        '”': '"',
        '„': ',',
        '€': 'E',
        Œ: '',
        '�': '',
        '“': '"',
        '&acirc;': '',
        '&oelig;': '',
        '&rsquo;': '\'',
      };
      const newText = text.replace(/™|’|–|<br>|&bdquo;|&euro;|&ldquo;|”|„|€|Œ|�|&acirc;|&oelig;|&rsquo;|“/gi, (matched) => mapObj[matched]);
      return newText;
    },
    /**
         * Function limits the characters in a string
         */
    limitCharacters(string) {
      if (string) {
        return string.length > 400 ? `${string.substring(0, 400)}...` : string;
      }
      return undefined;
    },
    /**
         * Removes HTML from string
         */
    stripHTML(string) {
      const tmp = document.createElement('div');
      tmp.innerHTML = string;
      return tmp.textContent || tmp.innerText || '';
    },
    /**
         * Function handles the response of image download get call
         */
    handleimageDownloadResponse(response) {
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, this.i18n.defaultDownloadedFilename);
        this.$refs.downloadInfoModal.close();
      }
      link.download = this.i18n.defaultDownloadedFilename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$refs.downloadInfoModal.close();
    },
    /**
         * Function handles the error of image download get call
         */
    handleImageDownloadError(error) {
      if (error) {
        this.$refs.downloadInfoModal.close();
        this.showFlyout('error', this.i18n.imageDownloadError, true);
      }
    },
    /**
         * Function handles the response of product certificate call
         */
    handlePdfInfoResponse(response) {
      if (response.data) {
        this.pdfInfo = {
          categoryDescription: true,
          productSellingStatement: true,
          gpCertificationsImages: response.data.gpCertificationsImages,
          headerLogo: response.data.headerLogo,
          isPdp: !this.isList,
          distributorImage: response.data.distributorImage,
        };
        this.pdpProductInfo.encodedImages = response.data.encodedImages;
      }
    },
    /**
         * Function is called to get image data
         */
    onImageDownloadInit(imageInfo) {
      const requestConfig = {};
      requestConfig.params = {
        productCode: this.prodId,
        imageformat: imageInfo.format,
        resolution: imageInfo.size,
        allimages: imageInfo.allImages,
        enhancedImages: imageInfo.enhancedImages,
      };
      this.pdpService.getImagesInZipFormat(requestConfig, this.handleimageDownloadResponse, this.handleImageDownloadError, this.prodId);
    },
    handlePdfInfoError() {},
  },
};
