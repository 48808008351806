var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showModal
    ? _c(
        "transition",
        { attrs: { name: "modal" } },
        [
          _c("focus-trap", [
            _c(
              "div",
              {
                staticClass:
                  "modal-mask d-flex flex-row justify-content-center align-items-center flex-wrap",
                attrs: {
                  role: "dialog",
                  "aria-label": _vm.heading,
                  tabindex: "0",
                  "aria-modal": "true"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "modal-wrapper", class: _vm.wrapperSize },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-container", class: _vm.size },
                      [
                        !_vm.hideHeading
                          ? _c("div", { staticClass: "top-bar d-flex" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "heading-container align-items-center"
                                },
                                [
                                  _vm.heading
                                    ? _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "my-xs-0 ml-sm-4 ml-xs-3 py-sm-3 heading-text",
                                          attrs: { tabindex: "-1" }
                                        },
                                        [_vm._v(_vm._s(_vm.heading))]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _vm.showCloseBtn
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "py-xs-3 pr-sm-5 pr-xs-2 right-close-box"
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "icon-x",
                                        attrs: {
                                          "aria-label": "close",
                                          tabindex: "0",
                                          role: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.closeModal($event)
                                          },
                                          keyup: function($event) {
                                            if (
                                              !("button" in $event) &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            _vm.closeModal($event)
                                          }
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "px-md-4 py-md-4 px-sm-4 py-sm-4 px-xs-3 pt-xs-4 modal-body",
                            class: { "modal-scrollable": _vm.isModalScrollable }
                          },
                          [
                            _vm.hideHeading
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "py-xs-3 pr-sm-5 pr-xs-2 right-close-box"
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "icon-x",
                                      attrs: {
                                        "aria-label": "close",
                                        tabindex: "0",
                                        role: "button"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.closeModal($event)
                                        },
                                        keyup: function($event) {
                                          if (
                                            !("button" in $event) &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          _vm.closeModal($event)
                                        }
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._t("component", [
                              _vm._v(
                                "\n                            default body\n                        "
                              )
                            ])
                          ],
                          2
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6f8349b0", { render: render, staticRenderFns: staticRenderFns })
  }
}