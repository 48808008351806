var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "lazy-container",
          rawName: "v-lazy-container",
          value: { selector: "img" },
          expression: "{ selector: 'img' }"
        }
      ],
      staticClass: "search-browse-product-tile d-flex",
      attrs: { role: "listitem" }
    },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tile-body d-flex flex-column" },
        [
          _vm.showCompareOption &&
          _vm.globals.siteConfig.isProductCompareEnabled
            ? _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  ref: _vm.product.code + "-checkbox",
                  staticClass: "color-secondary-light-grey",
                  attrs: {
                    type: "checkbox",
                    id: "checkbox" + _vm.product.code,
                    disabled: _vm.isCheckboxDisabled
                  },
                  domProps: { checked: _vm.product.checked },
                  on: {
                    click: function($event) {
                      _vm.checkBoxClicked($event)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    class: { "disable-compare": _vm.isCheckboxDisabled },
                    attrs: { for: "checkbox" + _vm.product.code }
                  },
                  [_vm._v(_vm._s(_vm.i18n.label.compare))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showSelectOption && _vm.globals.siteConfig.isProductCompareEnabled
            ? _c("div", { staticClass: "form-group refill" }, [
                _c("input", {
                  ref: _vm.product.code + "-checkbox",
                  staticClass: "color-secondary-light-grey",
                  attrs: {
                    type: "checkbox",
                    id: "checkbox" + _vm.product.code,
                    disabled: !(
                      ((_vm.product.materialStatus ===
                        _vm.ProductAvailability.OBSOLETE ||
                        _vm.product.materialStatus ===
                          _vm.ProductAvailability.PREDISCONTINUED) &&
                        _vm.product.stock.stockLevelStatus ===
                          _vm.ProductAvailability.IN_STOCK) ||
                      (_vm.product.materialStatus ===
                        _vm.MaterialStatus.ACTIVE_PRODUCT &&
                        (_vm.product.stock.stockLevelStatus ===
                          _vm.ProductAvailability.IN_STOCK ||
                          _vm.product.stock.stockLevelStatus ===
                            _vm.ProductAvailability.LOW_STOCK))
                    )
                  },
                  domProps: { checked: _vm.product.checked },
                  on: { click: _vm.selectCheckBoxClicked }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    class: {
                      "disable-compare": !(
                        ((_vm.product.materialStatus ===
                          _vm.ProductAvailability.OBSOLETE ||
                          _vm.product.materialStatus ===
                            _vm.ProductAvailability.PREDISCONTINUED) &&
                          _vm.product.stock.stockLevelStatus ===
                            _vm.ProductAvailability.IN_STOCK) ||
                        (_vm.product.materialStatus ===
                          _vm.MaterialStatus.ACTIVE_PRODUCT &&
                          (_vm.product.stock.stockLevelStatus ===
                            _vm.ProductAvailability.IN_STOCK ||
                            _vm.product.stock.stockLevelStatus ===
                              _vm.ProductAvailability.LOW_STOCK))
                      )
                    },
                    attrs: { for: "checkbox" + _vm.product.code }
                  },
                  [_vm._v(_vm._s(_vm.i18n.label.select))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showRemoveOption
            ? _c(
                "div",
                { staticClass: "remove-product-option d-flex align-center" },
                [
                  _c("span", {
                    staticClass: "icon-x-circle pr-xs-2",
                    attrs: {
                      role: "button",
                      tabindex: "0",
                      "aria-label": _vm.i18n.iconXCircleTitle,
                      title: _vm.i18n.iconXCircleTitle
                    },
                    on: {
                      click: function($event) {
                        _vm.removeComparePageProduct($event)
                      },
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.removeComparePageProduct($event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "remove-text" }, [
                    _vm._v(_vm._s(_vm.i18n.label.remove))
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "tile-image",
              attrs: {
                "aria-label": _vm.product.name
                  ? _vm.product.name
                  : _vm.i18n.noProductName,
                href: _vm.pdpContextPath + _vm.product.url
              }
            },
            [
              _vm.isBulkEnabled && !_vm.product.hasVariant
                ? _c("div", { staticClass: "form-group mt-xs-1 ml-xs-2" }, [
                    _c("input", {
                      ref: _vm.product.code + "-checkbox",
                      staticClass: "color-secondary-light-grey",
                      attrs: {
                        type: "checkbox",
                        id: "bulkCheckbox" + _vm.product.code
                      },
                      domProps: { checked: _vm.product.isBulk },
                      on: { click: _vm.selectCheckBoxClicked }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { for: "bulkCheckbox" + _vm.product.code } },
                      [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(_vm._s(_vm.i18n.label.select))
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isBundlesTile
                ? _c(
                    "div",
                    {
                      staticClass:
                        "form-group mt-xs-1 ml-xs-2 bundle-form-group"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "checkbox-container",
                          class: { disabled: _vm.isBundlesCheckboxDisabled }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.isProductSelected,
                                expression: "isProductSelected"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              disabled: _vm.isBundlesCheckboxDisabled,
                              "aria-label": _vm.i18n.label.select
                            },
                            domProps: {
                              checked: Array.isArray(_vm.isProductSelected)
                                ? _vm._i(_vm.isProductSelected, null) > -1
                                : _vm.isProductSelected
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = _vm.isProductSelected,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.isProductSelected = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.isProductSelected = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.isProductSelected = $$c
                                  }
                                },
                                _vm.handleProductSelection
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "checkmark" })
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex justify-content-center" }, [
                _vm.product.images &&
                _vm.product.images[0] &&
                _vm.product.images[0].url
                  ? _c("img", {
                      attrs: {
                        "data-src": _vm.globals.getThumbnailImageUrl(
                          _vm.product.images
                        ),
                        "data-loading":
                          _vm.globals.assetsPath + "images/spinner.gif",
                        alt: _vm.product.name
                          ? _vm.product.name
                          : _vm.i18n.noProductName
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.product.images
                  ? _c("img", {
                      attrs: {
                        "data-src":
                          _vm.globals.assetsPath + "images/no_image.svg",
                        "data-loading":
                          _vm.globals.assetsPath + "images/spinner.gif",
                        alt: _vm.product.name
                          ? _vm.product.name
                          : _vm.i18n.noProductName
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.promoText
                  ? _c("div", { staticClass: "info-text promo-text" }, [
                      _vm._v(_vm._s(_vm.product.promoText))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.materialStatus === "COMING_SOON"
                  ? _c("div", { staticClass: "info-text coming-soon" }, [
                      _vm._v(_vm._s(_vm.i18n.comingSoonBanner) + "\n        ")
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.product.promoText
                ? _c("div", { staticClass: "sr-only" }, [
                    _vm._v(_vm._s(_vm.product.promoText))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.product.materialStatus === "COMING_SOON"
                ? _c("div", { staticClass: "sr-only" }, [
                    _vm._v(_vm._s(_vm.i18n.comingSoonBanner))
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isFavoriteVisible && !_vm.isBundlesTile
                ? _c("span", {
                    staticClass: "mb-xs-3 icon-add-to-list tile-icon",
                    attrs: {
                      tabindex: "0",
                      role: "button",
                      title: _vm.i18n.iconAddToListTitle
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.handleSelectList($event)
                      },
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.handleSelectList($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isFavoriteVisible
                ? _c(
                    "div",
                    {
                      staticClass: "tile-icon fav-container",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.toggleFav($event)
                        }
                      }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "favorite tile-button",
                          class: { active: _vm.isActive },
                          attrs: {
                            "aria-label": _vm.isActive
                              ? _vm.i18n.iconSavedToFavoriteTitle
                              : _vm.i18n.iconSaveToFavoriteTitle
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "heart",
                              attrs: {
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                viewBox: "0 0 25 25",
                                preserveAspectRatio: "none",
                                width: "20",
                                height: "20"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Page-1",
                                    stroke: "none",
                                    "stroke-width": "2",
                                    fill: "none",
                                    "fill-rule": "evenodd"
                                  }
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "heart-outline",
                                        transform:
                                          "translate(1.000000, 0.000000),scale(0.75, 0.8)",
                                        stroke: "#999"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          id: "fill",
                                          d:
                                            "M22.3634,0.9763 C19.9054,0.9763 17.1654,1.9943 15.9764,5.2263 C15.8984,5.4373 15.5944,5.4293 15.5214,5.2163 C14.4144,1.9913 11.5714,0.9763 9.1154,0.9763 C4.4974,0.9763 0.7394,5.0363 0.7394,10.0263 C0.7394,12.3713 1.5684,14.5963 3.0754,16.2903 L15.0314,28.2673 C15.4224,28.6583 16.0564,28.6593 16.4484,28.2673 L28.1794,16.5343 C29.8234,14.8303 30.7394,12.5113 30.7394,10.0263 C30.7394,5.0363 26.9814,0.9763 22.3634,0.9763",
                                          fill: "#ffffff"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.showProductIndicators &&
          _vm.globals.siteConfig.showProductIndicators &&
          !_vm.product.hasVariant
            ? _c("div", { staticClass: "product-indicators-container" }, [
                _c(
                  "ul",
                  { staticClass: "px-xs-0 pt-xs-2 product-indicators" },
                  [
                    _vm.product.isCertified
                      ? _c("li", { staticClass: "pr-sm-2" }, [
                          _c("span", {
                            staticClass: "icon-certification",
                            attrs: {
                              title: _vm.iconCertificationTitle,
                              "aria-label": "certification",
                              tabindex: "0",
                              role: "button"
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.isDropShipEligible
                      ? _c("li", { staticClass: "pr-sm-2" }, [
                          _c("span", {
                            staticClass: "icon-shipping",
                            attrs: {
                              title: _vm.iconShippingTitle,
                              "aria-label": "shipping",
                              tabindex: "0",
                              role: "button"
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.isOnlineOnly
                      ? _c("li", { staticClass: "pr-sm-2" }, [
                          _c("span", {
                            staticClass: "icon-online",
                            attrs: {
                              title: _vm.iconOnlineTitle,
                              "aria-label": "online",
                              tabindex: "0",
                              role: "button"
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.isSubscribable
                      ? _c("li", { staticClass: "pr-sm-2" }, [
                          _c("span", {
                            staticClass: "icon-subscription",
                            attrs: {
                              title: _vm.iconSubscriptionTitle,
                              "aria-label": "subscription",
                              tabindex: "0",
                              role: "button"
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.isSeasonal
                      ? _c("li", { staticClass: "pr-sm-2" }, [
                          _c("span", {
                            staticClass: "icon-seasonal",
                            attrs: {
                              title: _vm.iconSeasonalTitle,
                              "aria-label": "seasonal",
                              tabindex: "0",
                              role: "button"
                            }
                          })
                        ])
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.productIcons
            ? _c("vx-product-icons", {
                attrs: {
                  i18n: _vm.i18n.productIconsTitle,
                  "product-icons": _vm.product.productIcons
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.product.name
            ? [
                _vm.isTruncatedName
                  ? _c(
                      "a",
                      {
                        staticClass: "product-name pt-md-2 my-sm-0",
                        attrs: {
                          href: _vm.pdpContextPath + _vm.product.url,
                          "data-toggle": "tooltip",
                          title: _vm.getProductName(_vm.product.name)
                        }
                      },
                      [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatProductName(
                                _vm.product.name,
                                _vm.characterLimit
                              )
                            )
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isTruncatedName
                  ? _c(
                      "a",
                      {
                        staticClass: "product-name pt-md-2 my-sm-0",
                        attrs: { href: _vm.pdpContextPath + _vm.product.url }
                      },
                      [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatProductName(
                                _vm.product.name,
                                _vm.characterLimit
                              )
                            )
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-section" },
            [
              _vm.globals.siteConfig.showProductStatus &&
              !_vm.product.hasVariant
                ? [
                    _vm.globals.siteConfig.isProductIdEnabled
                      ? _c("span", { staticClass: "sr-only" }, [
                          _vm._v(
                            _vm._s(_vm.i18n.label.productCodeA11y) +
                              "\n          " +
                              _vm._s(_vm.product.code)
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.globals.siteConfig.isProductIdEnabled
                      ? _c(
                          "p",
                          {
                            staticClass: "product-id pt-xs-2 m-xs-0 pb-xs-2",
                            attrs: {
                              "aria-hidden":
                                _vm.globals.siteConfig.isProductIdEnabled
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.i18n.label.productCode) +
                                "\n          " +
                                _vm._s(_vm.product.code)
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isBazaarVoice === "true"
                      ? _c("div", [
                          _vm.product.bvAverageRating
                            ? _c(
                                "div",
                                { staticClass: "star-rating" },
                                [
                                  _c("vx-star-rating", {
                                    attrs: {
                                      "product-rating":
                                        _vm.product.bvAverageRating,
                                      "product-reviews":
                                        _vm.product.bvNumberOfReviews,
                                      i18n: _vm.i18n,
                                      "show-rating": _vm.showRating,
                                      "show-reviews": _vm.showReviews,
                                      "number-of-stars": 5
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.globals.isB2B()
                      ? _c("div", [
                          (_vm.product.materialStatus ===
                            _vm.ProductAvailability.OBSOLETE ||
                            _vm.product.materialStatus ===
                              _vm.ProductAvailability.PREDISCONTINUED) &&
                          _vm.product.replacementProductCode &&
                          _vm.product.replacementProductCode !== ""
                            ? _c(
                                "p",
                                { staticClass: "error-text m-xs-0 pb-xs-2" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.i18n.discontinued) +
                                      "\n            " +
                                      _vm._s(_vm.i18n.replacedBy) +
                                      " " +
                                      _vm._s(
                                        _vm.product.replacementProductCode
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.product.materialStatus ===
                            _vm.ProductAvailability.OBSOLETE ||
                            _vm.product.materialStatus ===
                              _vm.ProductAvailability.PREDISCONTINUED) &&
                          !_vm.product.replacementProductCode
                            ? _c(
                                "p",
                                { staticClass: "error-text m-xs-0 pb-xs-2" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.i18n.discontinued) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.product.materialStatus ===
                          _vm.ProductAvailability.COMING_SOON
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "product-stock-status m-xs-0 pb-xs-2"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.i18n.availableSoon) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.checkPLPButtonStatus("stockMessage") &&
              _vm.globals.siteConfig.showInventoryMessages
                ? [
                    _c("div", { staticClass: "stock-status" }, [
                      _vm.product.stock.stockLevel &&
                      (_vm.product.stock.stockLevelStatus ===
                        _vm.ProductAvailability.LOW_STOCK ||
                        _vm.product.stock.stockLevelStatus.code ===
                          _vm.ProductAvailability.LOW_STOCK) &&
                      (_vm.globals.siteConfig.showInStockMsg ||
                        _vm.globals.siteConfig.showLowInventoryMessage)
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "product-stock-status m-xs-0 pb-xs-2",
                              class: {
                                "text-danger":
                                  _vm.globals.siteConfig.showLowInventoryMessage
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.globals.siteConfig
                                      .showLowInventoryMessage
                                      ? _vm.i18n.lowInventoryMessage
                                      : _vm.i18n.inStock
                                  )
                              )
                            ]
                          )
                        : _vm.product.stock.nextDeliveryDate
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "product-stock-status m-xs-0 pb-xs-2 text-danger"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.product.stock.nextDeliveryDate)
                              )
                            ]
                          )
                        : (_vm.product.stock.stockLevelStatus ===
                            _vm.ProductAvailability.IN_STOCK ||
                            _vm.product.stock.stockLevelStatus.code ===
                              _vm.ProductAvailability.IN_STOCK) &&
                          _vm.globals.siteConfig.showInStockMsg
                        ? _c(
                            "p",
                            {
                              staticClass: "product-stock-status m-xs-0 pb-xs-2"
                            },
                            [
                              _vm._v(
                                "\n            " + _vm._s(_vm.i18n.inStock)
                              )
                            ]
                          )
                        : _vm.product.stock.stockLevelStatus !==
                            _vm.ProductAvailability.IN_STOCK ||
                          (_vm.product.stock.stockLevelStatus.code !==
                            _vm.ProductAvailability.IN_STOCK &&
                            _vm.product.stock.stockLevelStatus.code !==
                              undefined &&
                            _vm.product.stock.stockLevelStatus.code !==
                              _vm.ProductAvailability.LOW_STOCK)
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "product-stock-status m-xs-0 pb-xs-2 text-danger"
                            },
                            [
                              _vm._v(
                                "\n            " + _vm._s(_vm.i18n.outOfStock)
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.product.notPurchasable
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "product-stock-status m-xs-0 pb-xs-2 text-danger"
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.i18n.notPurchasable) + "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.globals.siteConfig.isProductPriceEnabled &&
              !_vm.product.hasVariant &&
              !_vm.product.notPurchasable
                ? _c(
                    "div",
                    {
                      staticClass:
                        "pb-xs-3 my-sm-0 prices d-flex align-items-end"
                    },
                    [
                      _vm.product.price
                        ? _c("p", { staticClass: "product-price" }, [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v(_vm._s(_vm.i18n.label.a11yCurrentPrice))
                            ]),
                            _vm._v(
                              _vm._s(_vm.product.price.formattedValue) +
                                "\n        "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.product.weblistPrice &&
                      _vm.globals.siteConfig.isSlashedPriceEnabled
                        ? _c(
                            "p",
                            {
                              staticClass: "product-price product-slashed-price"
                            },
                            [
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v(_vm._s(_vm.i18n.label.a11ySlashedPrice))
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.product.weblistPrice.formattedValue
                                ) + "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.globals.siteConfig.isProductPriceEnabled &&
              _vm.product.hasVariant &&
              !_vm.product.notPurchasable
                ? _c("div", [
                    _vm.product.price || _vm.product.priceRange
                      ? _c("p", { staticClass: "product-price" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getVariantsPrice(_vm.product))
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isRefill
                ? [
                    _c("div", { staticClass: "quantity-label" }, [
                      _vm._v(_vm._s(_vm.i18n.label.quantity))
                    ]),
                    _vm._v(" "),
                    _c("vx-stepper-control", {
                      attrs: {
                        "min-value": _vm.product.minOrderQuantity || 0,
                        maxValue: _vm.product.maxOrderableQuantity,
                        value: _vm.refillQuantity
                      },
                      on: {
                        currentCount: function($event) {
                          _vm.getQuantity($event)
                        }
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.globals.siteConfig.addToCartEnabled && !_vm.hideButtonBlock
            ? [
                !_vm.isRefill && _vm.globals.isEStores()
                  ? [
                      _vm.product.hasVariant
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary tile-button",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  _vm.navigateToProduct(_vm.product.url)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.i18n.label.chooseOption))]
                          )
                        : _vm.verifyToDisplayNotifyme()
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-tertiary tile-button",
                              attrs: { type: "button", tabindex: "0" },
                              on: {
                                click: function($event) {
                                  _vm.handleNotifyMe($event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.i18n.label.notifyMe))]
                          )
                        : _c(
                            "button",
                            {
                              staticClass:
                                "btn button-color text-trans add-to-cart-btn tile-button",
                              class: {
                                disabled: _vm.verifyDisablingAddtocart()
                              },
                              attrs: {
                                type: "button",
                                disabled: _vm.verifyDisablingAddtocart(),
                                "aria-label": _vm.i18n.label.addToCart
                              },
                              on: { click: _vm.addToCart }
                            },
                            [_vm._v(_vm._s(_vm.i18n.label.addToCart))]
                          )
                    ]
                  : !_vm.isRefill
                  ? [
                      _vm.product.hasVariant
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary tile-button",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  _vm.navigateToProduct(_vm.product.url)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.i18n.label.chooseOption))]
                          )
                        : _vm.checkPLPButtonStatus()
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-tertiary tile-button",
                              attrs: { type: "button", tabindex: "0" },
                              on: {
                                click: function($event) {
                                  _vm.handleNotifyMe($event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.i18n.label.notifyMe))]
                          )
                        : _c(
                            "button",
                            {
                              staticClass:
                                "btn button-color add-to-cart-btn tile-button",
                              class: {
                                disabled:
                                  (_vm.product.materialStatus ===
                                    _vm.ProductAvailability.OBSOLETE ||
                                    _vm.product.materialStatus ===
                                      _vm.ProductAvailability
                                        .PREDISCONTINUED) &&
                                  _vm.product.stock &&
                                  _vm.product.stock.stockLevelStatus &&
                                  _vm.product.stock.stockLevelStatus ===
                                    _vm.ProductAvailability.OUT_OF_STOCK
                              },
                              attrs: {
                                type: "button",
                                disabled:
                                  ((_vm.product.materialStatus ===
                                    _vm.ProductAvailability.OBSOLETE ||
                                    _vm.product.materialStatus ===
                                      _vm.ProductAvailability
                                        .PREDISCONTINUED) &&
                                    _vm.product.stock &&
                                    _vm.product.stock.stockLevelStatus &&
                                    _vm.product.stock.stockLevelStatus ===
                                      _vm.ProductAvailability.OUT_OF_STOCK) ||
                                  !_vm.soldIndividually ||
                                  _vm.product.notPurchasable,
                                "aria-label": _vm.i18n.label.addToCart
                              },
                              on: { click: _vm.addToCart }
                            },
                            [_vm._v(_vm._s(_vm.i18n.label.addToCart))]
                          )
                    ]
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.guestListName
            ? [
                _c("vx-save-cart", {
                  attrs: {
                    prodQuantity: "1",
                    "cart-guid": _vm.checkVariant(_vm.product),
                    i18n: _vm.i18n,
                    "is-product": "true",
                    "guest-list-name": _vm.guestListName
                  },
                  on: { "list-saved": _vm.onSelectListSuccess }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "selectListModal",
          attrs: { size: "small", heading: _vm.i18n.selectListHeading },
          on: { close: _vm.modalClose }
        },
        [
          _c("vx-save-cart", {
            attrs: {
              slot: "component",
              prodQuantity: "1",
              "cart-guid": _vm.checkVariant(_vm.product),
              i18n: _vm.i18n,
              "is-product": "true"
            },
            on: { "list-saved": _vm.onSelectListSuccess },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "notifyMeModal",
          attrs: { size: "extra-small", heading: _vm.i18n.notifyMeHeading },
          on: { close: _vm.modalClose }
        },
        [
          _c("vx-notify-me", {
            attrs: {
              slot: "component",
              title: _vm.i18n.notifyMeContent,
              productCode: _vm.product.code,
              i18n: _vm.i18n
            },
            on: {
              "notify-me-success": _vm.onNotifyMeSuccess,
              "notify-me-error": _vm.onNotifyMeError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "asmModal",
          attrs: { size: "small", heading: _vm.i18n.label.asmHeading },
          on: { close: _vm.modalClose }
        },
        [
          _c(
            "div",
            {
              staticClass: "asm-message",
              attrs: { slot: "component" },
              slot: "component"
            },
            [_vm._v(_vm._s(_vm.i18n.label.asmMessage))]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7a45c78f", { render: render, staticRenderFns: staticRenderFns })
  }
}