import {
  Validator,
} from 'vee-validate';
import globals from '../../common/globals';
import mobileMixin from '../../common/mixins/mobile-mixin';
import detectDeviceMixin from '../../common/mixins/detect-device-mixin';
import ManageB2bOrgService from '../../common/services/manage-b2b-org-service';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import vxDropdownPrimary from '../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import {
  UnitDetails,
} from '../../common/mixins/vx-enums';
import vxTable from '../../common/vx-table/vx-table.vue';

export default {
  name: 'vx-business-unit-landing',
  components: {
    vxModal,
    vxDropdownPrimary,
    vxTable,
  },
  mixins: [mobileMixin, detectDeviceMixin],
  props: {
    i18n: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      globals,
      landingPageData: {},
      tableTitles: [this.i18n.unitName, this.i18n.adminsText, this.i18n.buyersText],
      businessUnitDetails: {
        companyName: '',
        parentUnit: '',
      },
      parentUnitsList: [],
      manageB2bOrgService: new ManageB2bOrgService(),
      displayedResultsLength: 10,
      childUnitsTable: {},
      UnitDetails,
      tableConfig: {},
    };
  },
  computed: {
    /**
     * Fetches the data available.
     */
    dataAvailable() {
      return this.landingPageData && Object.keys(this.landingPageData).length !== 0;
    },
    /**
     * Fetches the parent unit details
     */
    parentUnitDetails() {
      let phone = '';
      if (this.landingPageData.addresses && this.landingPageData.addresses[0].phone) {
        phone = this.landingPageData.addresses[0].phone;
      }
      return `${this.landingPageData.name}<br>
      ${this.landingPageData.role ? this.landingPageData.role : ''}<br>
      ${phone}`;
    },
    /**
     * Fecthes the parent unit address.
     */
    parentUnitAddress() {
      let address = '';
      if (this.landingPageData.addresses) {
        address = `${
          this.landingPageData.addresses[0].country
          && this.landingPageData.addresses[0].country.isocode
            ? this.landingPageData.addresses[0].country.isocode
            : ''
        }<br>
      ${this.landingPageData.addresses[0].line1 ? this.landingPageData.addresses[0].line1 : ''}<br>
      ${this.landingPageData.addresses[0].line2 ? this.landingPageData.addresses[0].line2 : ''}<br>
      ${this.landingPageData.addresses[0].town ? this.landingPageData.addresses[0].town : ''},
      ${
  this.landingPageData.addresses[0].region && this.landingPageData.addresses[0].region.isocode
    ? this.landingPageData.addresses[0].region.isocode
    : ''
}
      ${
  this.landingPageData.addresses[0].postalCode
    ? this.landingPageData.addresses[0].postalCode
    : ''
}`;
      }
      return address;
    },
  },
  created() {
    this.fetchLandingPageData();
    this.fetchUnitsDropdownList();
  },
  mounted() {
    const veeCustomErrorMessage = {
      en: {
        custom: {
          firstName: {
            required: this.i18n.firstNameRequiredError,
          },
          lastName: {
            required: this.i18n.lastNameRequiredError,
          },
          email: {
            required: this.i18n.emailRequiredError,
            email: this.i18n.emailInvalidError,
          },
          parentUnit: {
            required: this.i18n.parentUnitRequiredError,
          },
          companyName: {
            required: this.i18n.unitNameRequiredError,
            max: this.i18n.unitNameMaxError,
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
  },
  methods: {
    /**
     * Fetches business unit details.
     */
    fetchLandingPageData() {
      this.manageB2bOrgService.getBusinessLandingDataService({}, this.handleLandingPageData, this.handleLandingPageError);
    },
    /**
     * Handles the success call of getBus.inessLandingDataService
     * @param {Object} response contains business unit details
     */
    handleLandingPageData(response) {
      const { status, data } = response;

      if (status) {
        this.landingPageData = data;
      } else {
        this.handleErrorCallback();
      }
      if (this.childUnitsTable.data) {
        this.childUnitsTable.destroy();
      }
      this.initializeTableConfig();
    },
    handleLandingPageError() {

    },
    handleErrorCallback() {
      console.log('Error');
    },
    /**
     * Fetches business units
     */
    fetchUnitsDropdownList() {
      this.manageB2bOrgService.getBusinessUnits({}, this.handleUnitsDropdownResponse, this.handleUnitsDropdownError, '', true);
    },
    /**
     * Handles the success call of getBusinessUnits
     * @param {Object} response contains business units.
     */
    handleUnitsDropdownResponse(response) {
      const { status, data } = response;

      if (status) {
        this.parentUnitsList = data.units.map((child) => ({
          label: child.name,
          value: child.id,
        }));
      }
    },
    handleUnitsDropdownError() {

    },
    generateColumnDefs() {
      const columnConfigArray = [];
      this.tableTitles.forEach((val, index) => {
        const columnnConfig = {};
        if (index === 0) {
          columnnConfig.width = '60%';
        } else {
          columnnConfig.width = '20%';
        }
        columnnConfig.targets = index;
        columnnConfig.title = val;
        columnnConfig.createdCell = (cell) => {
          cell.setAttribute('data-title', val);
        };
        columnConfigArray.push(columnnConfig);
      }); return columnConfigArray;
    },

    handleAddBusinessUnit(event) {
      this.$refs.addBusinessUnitModal.open(event);
    },
    sendAddBusinessUnitRequest() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const childUnit = encodeURIComponent(this.businessUnitDetails.companyName);
          const parentUnit = encodeURIComponent(this.businessUnitDetails.parentUnit.value);
          if (childUnit && parentUnit) {
            // Creates a child unit service
            this.manageB2bOrgService.createChildUnitService(
              {},
              this.handleAddBusinessUnitResponse,
              this.handleAddBusinessUnitResponse,
              childUnit,
              parentUnit,
            );
          }
        } else {
          this.globals.setFocusByName(this.$el, this.globals.getElementName(this.errors));
        }
      });
    },
    /**
     * Handles the success of createChildUnitService
     * makes a getBusinessUnits call to fetch the business units
     */
    handleAddBusinessUnitResponse() {
      this.resetValues();
      this.fetchLandingPageData();
      this.$refs.addBusinessUnitModal.close();
      this.manageB2bOrgService.getBusinessUnits({}, this.handleUnitsDropdownResponse, this.handleUnitsDropdownError, '', true);
    },
    handleAddBusinessUnitError() {

    },
    /**
     * Resets the business unit details.
     */
    resetValues() {
      this.businessUnitDetails.companyName = '';
      this.businessUnitDetails.parentUnit = {};
    },
    initializeTableConfig() {
      const self = this;
      this.tableConfig = {
        rowCount: 10,
        emptyTable: this.i18n.noDataAvailable,
        columnConfiguration: [
          {
            customHeaderName: self.i18n.unitName,
            customTemplate(data) {
              return `<a
              href="${globals.getNavBaseUrl()}${self.globals.navigations.businessUnitsLanding}/?${self.UnitDetails.UNIT_ID}=${encodeURIComponent(data.id)}">
              ${data.name}
              </a>`;
            },
          },
          {
            customHeaderName: self.i18n.adminsText,
            field: 'noOfAdministrators',
          },
          {
            customHeaderName: self.i18n.buyersText,
            field: 'noOfCustomers',
          },
        ],
        childRow: {
          template(data) {
            return `<tr><td class="pb-sm-3 pb-xs-3 pt-xs-0"><a href="${self.globals.getNavBaseUrl()}${self.globals.navigations.businessUnitsLanding}/?${self.UnitDetails.UNIT_ID
            }=${encodeURIComponent(data.id)}">
           ${data.name}</a><td class="pb-sm-3 pb-xs-3 pt-xs-0" data-title="Admins">
           ${data.noOfAdministrators ? data.noOfAdministrators : ''}</td><td class="pb-sm-3 pb-xs-3 pt-xs-0" data-title="Buyers">
           ${data.noOfCustomers ? data.noOfCustomers : ''}</td></tr>`;
          },
          field: 'children',
          rowClass: 'nested-row',
        },
      };
    },
  },
};
