var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-juicer-feed" },
    [
      _c("lazy-component", [
        _c("ul", {
          staticClass: "juicer-feed",
          attrs: {
            "data-feed-id": _vm.juicerOptions.feedId,
            "data-per": _vm.juicerOptions.dataPer,
            "data-pages": _vm.juicerOptions.pages,
            truncate: _vm.juicerOptions.truncate,
            gutter: _vm.juicerOptions.gutter,
            "data-columns": _vm.juicerOptions.columns,
            "data-interval": _vm.juicerOptions.interval,
            "data-filter": _vm.juicerOptions.filter,
            "data-starting-at": _vm.juicerOptions.startingDate,
            "data-ending-at": _vm.juicerOptions.endingDate,
            "data-style": _vm.juicerOptions.slider,
            "data-overlay": _vm.juicerOptions.overlay,
            "data-after": _vm.juicerCallback()
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cf1e1cca", { render: render, staticRenderFns: staticRenderFns })
  }
}