var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12" },
      [_c("vx-order-confirmation", { attrs: { i18n: _vm.messages.checkout } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-8 col-sm-12 col-xs-12 pr-mb-5 pr-xs-1" },
      [_c("vx-guest-create-account")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-dac30850", { render: render, staticRenderFns: staticRenderFns })
  }
}