var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagination-container" }, [
    _vm.pagesList.length
      ? _c(
          "ul",
          { staticClass: "pagination-list" },
          [
            _c(
              "li",
              {
                class: [
                  "pagination-arrow",
                  _vm.currentPage > 1 ? "active-arrow" : "deactivated-arrow"
                ],
                on: { click: _vm.previousPage }
              },
              [_vm._v(" <\n      ")]
            ),
            _vm._v(" "),
            _vm._l(_vm.pagesList, function(page) {
              return _c(
                "li",
                {
                  key: page.id,
                  class: page === _vm.currentPage ? "active" : "clickable",
                  on: {
                    click: function($event) {
                      _vm.goToPage(page)
                    }
                  }
                },
                [_vm._v(_vm._s(page))]
              )
            }),
            _vm._v(" "),
            _c(
              "li",
              {
                class: [
                  "pagination-arrow",
                  _vm.currentPage < _vm.totalPages
                    ? "active-arrow"
                    : "deactivated-arrow"
                ],
                on: { click: _vm.nextPage }
              },
              [_vm._v("\n        > ")]
            )
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-46d66ee7", { render: render, staticRenderFns: staticRenderFns })
  }
}