var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "vx-voucher-details" }, [
    _vm._v("\n  " + _vm._s(_vm.voucherData.description) + "\n  "),
    _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c(
        "button",
        {
          staticClass:
            "close-button mt-xs-5 btn btn-primary modal-btn-primary-small modal-btn-xs-sticky",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              _vm.$emit("voucherDetailsClose")
            }
          }
        },
        [_vm._v(_vm._s(_vm.i18n.promotionsClose))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5491146d", { render: render, staticRenderFns: staticRenderFns })
  }
}