var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-auto-suggest-to-list" },
    [
      _c(
        "div",
        { staticClass: "selected-items-container" },
        _vm._l(_vm.selectedProducts, function(product) {
          return _c(
            "div",
            { key: product.code, staticClass: "selected-item" },
            [
              _vm._v("\n        " + _vm._s(product.code) + "\n        "),
              _c("span", {
                staticClass: "icon-x",
                attrs: { title: "i18n.iconXTitle" },
                on: {
                  click: function($event) {
                    _vm.onItemClickRemove(product)
                  }
                }
              })
            ]
          )
        })
      ),
      _vm._v(" "),
      _c("div", { staticClass: "search-input form-group" }, [
        _c("span", {
          staticClass: "icon-search",
          attrs: { title: "i18n.iconSearchTitle", "aria-hidden": "true" }
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "Search" },
          on: {
            input: function($event) {
              _vm.onInput($event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn btn-tertiary d-flex align-items-center selected-items-btn",
            attrs: { disabled: _vm.selectedProducts.length === 0 },
            on: { click: _vm.onClickEmitSelectedProducts }
          },
          [_c("span", { staticClass: "icon-plus" })]
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.products, function(product) {
        return _c(
          "div",
          {
            key: product.code,
            staticClass: "search-item",
            on: {
              click: function($event) {
                _vm.onItemClick(product)
              }
            }
          },
          [
            _vm._v(
              "\n    [" +
                _vm._s(product.code) +
                "] " +
                _vm._s(product.name) +
                "\n  "
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-03abd5b9", { render: render, staticRenderFns: staticRenderFns })
  }
}