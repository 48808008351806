/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-mixed-operators */
import globals from '../globals';

export default {
  name: 'vx-star-rating',
  components: {},
  props: [
    'config',
    'productRating',
    'productReviews',
    'i18n',
    'showRating',
    'showReviews',
    'numberOfStars',
  ],
  data() {
    return {
      globals,
      stars: [],
      emptyStar: 0,
      fullStar: 1,
      totalStars: this.numberOfStars,
      style: {
        fullStarColor: '',
        emptyStarColor: '',
        starWidth: 19,
        starHeight: 19,
      },
    };
  },
  created() {
    this.initStars();
    this.setStars();
    this.setConfigData();
  },
  async mounted() {
    this.getStarColor();
  },
  computed: {
    /**
     *  Used to calculate the points required to plot the star
     */
    getStarPoints() {
      const centerX = this.style.starWidth / 2;
      const centerY = this.style.starHeight / 2;

      const innerCircleArms = 5; // a 5 arms star

      const innerRadius = this.style.starWidth / innerCircleArms;
      const innerOuterRadiusRatio = 2.5; // Unique value - determines fatness/sharpness of star
      const outerRadius = innerRadius * innerOuterRadiusRatio;

      return this.calcStarPoints(
        centerX,
        centerY,
        innerCircleArms,
        innerRadius,
        outerRadius,
      );
    },
  },
  methods: {
    /**
     * Used to calculate the points required to plot the star
     * @param {number} centerX
     * @param {number} centerY
     * @param {number} innerCircleArms
     * @param {number} innerRadius
     * @param {number} outerRadius
     */
    calcStarPoints(
      centerX,
      centerY,
      innerCircleArms,
      innerRadius,
      outerRadius,
    ) {
      const angle = Math.PI / innerCircleArms;
      const angleOffsetToCenterStar = 60;

      const totalArms = innerCircleArms * 2;
      let points = '';
      for (let i = 0; i < totalArms; i += 1) {
        const isEvenIndex = i % 2 === 0;
        const r = isEvenIndex ? outerRadius : innerRadius;
        const currX = centerX + Math.cos(i * angle + angleOffsetToCenterStar) * r;
        const currY = centerY + Math.sin(i * angle + angleOffsetToCenterStar) * r;
        points += `${currX},${currY} `;
      }
      return points;
    },
    /**
     * Determine the total number of stars to be displayed
     */
    initStars() {
      for (let i = 0; i < this.totalStars; i += 1) {
        this.stars.push({
          raw: this.emptyStar,
          percent: `${this.emptyStar}%`,
        });
      }
    },
    /**
     *  Used to show the total stars
     */
    setStars() {
      let fullStarsCounter = Math.floor(this.productRating);
      for (let i = 0; i < this.stars.length; i += 1) {
        if (fullStarsCounter !== 0) {
          this.stars[i].raw = this.fullStar;
          this.stars[i].percent = this.calcStarFullness(this.stars[i]);
          fullStarsCounter -= 1;
        } else {
          const surplus = Math.round((this.productRating % 1) * 10) / 10; // Support just one decimal
          const roundedOneDecimalPoint = Math.round(surplus * 10) / 10;
          this.stars[i].raw = roundedOneDecimalPoint;
          return (this.stars[i].percent = this.calcStarFullness(this.stars[i]));
        }
      }
    },
    /**
     * Used to apply the required properties of the star icons
     */
    setConfigData() {
      if (this.config) {
        this.setBindedProp(this.style, this.config.style, 'fullStarColor');
        this.setBindedProp(this.style, this.config.style, 'emptyStarColor');
        this.setBindedProp(this.style, this.config.style, 'starWidth');
        this.setBindedProp(this.style, this.config.style, 'starHeight');
        if (this.config.isIndicatorActive) {
          this.isIndicatorActive = this.config.isIndicatorActive;
        }
      }
    },
    /**
     * Used to determine the nature of the star
     * @param {object} starData
     */
    getFullFillColor(starData) {
      return starData.raw !== this.emptyStar
        ? this.style.fullStarColor
        : this.style.emptyStarColor;
    },
    /**
     * Used to fill the star color
     * @param {object} starData
     */
    calcStarFullness(starData) {
      const starFullnessPercent = `${starData.raw * 100}%`;
      return starFullnessPercent;
    },
    /**
     * Binding Properties of local and parent objects
     * @param {object} localProp
     * @param {object} propParent
     * @param {string} propToBind
     */
    setBindedProp(localProp, propParent, propToBind) {
      if (propParent[propToBind]) {
        localProp[propToBind] = propParent[propToBind];
      }
    },
    /**
     * Get the colors of Full star and empty star
     */
    getStarColor() {
      if (this.globals && this.globals.siteVariables) {
        this.style.fullStarColor = this.globals.siteVariables.fullStarColor;
        this.style.emptyStarColor = this.globals.siteVariables.emptyStarColor;
      }
    },
  },
};
