var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tableConfig !== {} &&
      _vm.constructedTableData && Array.isArray(_vm.constructedTableData)
        ? _c(
            "table",
            {
              class: [
                "vx-data-table",
                "card-list-table",
                _vm.constructedTableData.length > 0
                  ? ""
                  : "data-table-min-height"
              ]
            },
            [
              _c("thead", [
                _c(
                  "tr",
                  { staticClass: "table-head" },
                  [
                    _vm.tableConfig.enableSelectAll
                      ? _c("th", { staticClass: "header-checkbox-padding" }, [
                          _c("label", { staticClass: "checkbox-container" }, [
                            _c("span", [_vm._v("Select all lists")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectAllCheckbox,
                                  expression: "selectAllCheckbox"
                                }
                              ],
                              attrs: { name: "selectAll", type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.selectAllCheckbox)
                                  ? _vm._i(_vm.selectAllCheckbox, null) > -1
                                  : _vm.selectAllCheckbox
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.selectAllCheckbox,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectAllCheckbox = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectAllCheckbox = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectAllCheckbox = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.resultantColumnConfiguration, function(
                      title,
                      index
                    ) {
                      return [
                        Object.hasOwnProperty.call(
                          title,
                          "customHeaderTemplate"
                        )
                          ? _c(
                              "th",
                              {
                                class: [
                                  _vm.sortSetting.column === index
                                    ? _vm.sortSetting.type
                                    : "sorting"
                                ],
                                on: {
                                  click: function($event) {
                                    _vm.sortColumn(index)
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      title.customHeaderTemplate
                                    )
                                  }
                                })
                              ]
                            )
                          : _c(
                              "th",
                              {
                                class: _vm.classifySortClass(index),
                                on: {
                                  click: function($event) {
                                    _vm.sortColumn(index)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(title.customHeaderName) +
                                    "\n            "
                                )
                              ]
                            )
                      ]
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _vm.constructedTableData.length && _vm.tableConfig.enableSelectAll
                ? _c(
                    "tbody",
                    { staticClass: "vx-selectall-table-body" },
                    [
                      _vm._l(_vm.constructedTableData, function(row, rowIndex) {
                        return [
                          _c(
                            "tr",
                            { key: row.id, staticClass: "data-row" },
                            [
                              _vm.tableConfig.enableSelectAll
                                ? _c("td", { staticClass: "item-checkbox" }, [
                                    _c(
                                      "label",
                                      { staticClass: "checkbox-container" },
                                      [
                                        _c("span", { attrs: { hidden: "" } }, [
                                          _vm._v("checkbox")
                                        ]),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: row.selected,
                                              expression: "row.selected"
                                            }
                                          ],
                                          staticClass: "check",
                                          attrs: {
                                            type: "checkbox",
                                            value: "",
                                            "aria-label": "Select list"
                                          },
                                          domProps: {
                                            checked: Array.isArray(row.selected)
                                              ? _vm._i(row.selected, "") > -1
                                              : row.selected
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.selectSingleItem(rowIndex)
                                            },
                                            change: function($event) {
                                              var $$a = row.selected,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = "",
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      row,
                                                      "selected",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      row,
                                                      "selected",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(row, "selected", $$c)
                                              }
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "checkmark" })
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.resultantColumnConfiguration, function(
                                fieldObject,
                                fieldIndex
                              ) {
                                return [
                                  _c(
                                    "td",
                                    {
                                      key: fieldObject.id,
                                      attrs: {
                                        "data-title":
                                          fieldObject.customHeaderName
                                      }
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.composeTableData(
                                              fieldObject,
                                              row
                                            )
                                          )
                                        }
                                      })
                                    ]
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      })
                    ],
                    2
                  )
                : _vm.constructedTableData.length &&
                  !_vm.tableConfig.enableSelectAll
                ? _c(
                    "tbody",
                    { staticClass: "vx-table-body" },
                    [
                      _vm._l(_vm.constructedTableData, function(row, rowIndex) {
                        return [
                          _c(
                            "tr",
                            { key: row.id, staticClass: "data-row" },
                            [
                              _vm._l(_vm.resultantColumnConfiguration, function(
                                fieldObject,
                                fieldIndex
                              ) {
                                return [
                                  fieldObject.accordionControl
                                    ? _c(
                                        "td",
                                        {
                                          on: {
                                            click: function($event) {
                                              _vm.toggleVisibility(rowIndex)
                                            }
                                          }
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "accordion-click",
                                            class: _vm.getChevronClass(rowIndex)
                                          })
                                        ]
                                      )
                                    : _c(
                                        "td",
                                        {
                                          key: fieldObject.id,
                                          attrs: {
                                            "data-title":
                                              fieldObject.customHeaderName
                                          }
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.composeTableData(
                                                  fieldObject,
                                                  row
                                                )
                                              )
                                            }
                                          })
                                        ]
                                      )
                                ]
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.verifyChildRowData(rowIndex)
                            ? [
                                _vm.verifyDataFieldIsArray()
                                  ? [
                                      _vm._l(
                                        row[_vm.tableConfig.childRow.field],
                                        function(data) {
                                          return [
                                            _c("tr", {
                                              class: _vm.getChildRowClass(),
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.getChildRowTemplate(data)
                                                )
                                              }
                                            })
                                          ]
                                        }
                                      )
                                    ]
                                  : [
                                      _c("tr", {
                                        class: _vm.getChildRowClass(),
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getChildRowTemplate(row)
                                          )
                                        }
                                      })
                                    ]
                              ]
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                : _vm.constructedTableData.length === 0
                ? _c("tbody", [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "dataTables_empty",
                          attrs: {
                            colspan: _vm.resultantColumnConfiguration.length
                          }
                        },
                        [_vm._v(_vm._s(_vm.displayEmptyTableMessage()))]
                      )
                    ])
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("vx-pagination", {
        attrs: {
          pageNumberOnClick: _vm.handlePaginationClick,
          totalDataLength: _vm.tableData.length,
          rowCount: _vm.tableConfig.rowCount
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b7aca80a", { render: render, staticRenderFns: staticRenderFns })
  }
}