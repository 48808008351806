var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-site-content" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "row d-flex flex-wrap" },
        [
          _c("vx-image-tile", {
            staticClass: "col-sm-6 col-sm-push-6 col-xs-12",
            attrs: { imageTileData: _vm.viewSiteContent["imageTile"] }
          }),
          _vm._v(" "),
          _c("vx-text-tile", {
            staticClass: "col-sm-6 col-sm-pull-6 col-xs-12",
            attrs: { textTileData: _vm.viewSiteContent["textTileTwo"] }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row m-xs-0" },
        [
          _c("vx-image-text", {
            attrs: {
              imageTileData: _vm.viewSiteContent["imageTile"],
              textTileData: _vm.viewSiteContent["textTile"],
              "component-theme": ""
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row m-xs-0" },
        [
          _c("vx-image-text", {
            attrs: {
              imageTileData: _vm.viewSiteContent["imageTile2"],
              textTileData: _vm.viewSiteContent["textTileTwo"],
              "component-theme": "text-white"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("vx-brand-bar", {
            attrs: { "brand-bar-data": _vm.viewSiteContent["brandBar"] }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("vx-marketing-promo", {
            attrs: { "promotion-data": _vm.viewSiteContent["promoData"] }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("vx-bundles", {
            attrs: { "bundles-data": _vm.viewSiteContent["bundlesData"] }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6f924e05", { render: render, staticRenderFns: staticRenderFns })
  }
}