var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "cross-reference-search" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.i18n.heading))]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex filters" }, [
        _c("div", { staticClass: "d-flex categorySearh" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", [_vm._v(_vm._s(_vm.i18n.categoryLabel))]),
              _vm._v(" "),
              _c("vx-dropdown-primary", {
                ref: "categories",
                attrs: { dropdownValues: _vm.formattedCategories },
                on: {
                  "selected-option": function($event) {
                    _vm.updateSelectedCategory($event)
                  },
                  primaryDropdownMounted: function($event) {
                    _vm.handleDefaultCategoryalue()
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", [_vm._v(_vm._s(_vm.i18n.subCategoryLabel))]),
              _vm._v(" "),
              _c("vx-dropdown-primary", {
                ref: "subcategories",
                attrs: {
                  dropdownValues: _vm.formattedSubCategories,
                  "is-disabled": !_vm.formattedSubCategories.length
                },
                on: {
                  "selected-option": function($event) {
                    _vm.updateSelectedSubCategory($event)
                  },
                  primaryDropdownMounted: function($event) {
                    _vm.handleDefaultSubCategoryalue()
                  }
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex textSearch" }, [
          _c("div", { staticClass: "or" }, [_vm._v("Or")]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group search-input" }, [
            _c("label", [_vm._v(_vm._s(_vm.i18n.searchLabel))]),
            _vm._v(" "),
            _c("div", { staticClass: "left-addon" }, [
              _c("span", {
                staticClass: "icon-search",
                attrs: { title: "Search Button", "aria-hidden": "true" },
                on: { click: _vm.handleTextSearch }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm"
                  }
                ],
                staticClass: "m-a-0 form-control",
                attrs: {
                  type: "text",
                  id: "searchSku",
                  "aria-label": "i18n.searchLabel"
                },
                domProps: { value: _vm.searchTerm },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchTerm = $event.target.value
                  }
                }
              })
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.searchResponse.products, function(product, index) {
        return _c(
          "div",
          { staticClass: "products" },
          [
            _c("div", { staticClass: "product-tile-heading" }, [
              _c("div", { staticClass: "d-flex" }, [
                _c("p", { staticClass: "code" }, [
                  _vm._v(_vm._s(_vm.i18n.mfgText) + " " + _vm._s(product.code))
                ]),
                _vm._v(" "),
                _c("p", {
                  staticClass: "name",
                  domProps: { innerHTML: _vm._s(product.name) }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex line" }, [
                _c("p", {
                  staticClass: "description",
                  domProps: { innerHTML: _vm._s(product.description) }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "suggestText" }, [
                  _vm._v(_vm._s(_vm.i18n.suggestText))
                ])
              ])
            ]),
            _vm._v(" "),
            product.competitorReplacementProducts
              ? _vm._l(product.competitorReplacementProducts, function(
                  competitor,
                  index
                ) {
                  return _c(
                    "vx-product-tile",
                    [
                      competitor &&
                      competitor.images &&
                      competitor.images[0] &&
                      competitor.images[0].url
                        ? _c("template", { slot: "image-slot" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.globals.getNavigationUrl("empty") +
                                    competitor.url
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    alt: competitor.name,
                                    src: _vm.globals.getThumbnailImageUrl(
                                      competitor.images
                                    )
                                  }
                                })
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !competitor.images
                        ? _c("template", { slot: "image-slot" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.globals.getNavigationUrl("empty") +
                                    competitor.url
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    alt: "no image",
                                    src:
                                      _vm.globals.assetsPath +
                                      "images/no_image.svg"
                                  }
                                })
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("template", { slot: "details" }, [
                        _c(
                          "p",
                          { staticClass: "product-title mb-xs-2 pr-xs-5" },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.globals.getNavigationUrl("empty") +
                                    competitor.url
                                }
                              },
                              [_vm._v(_vm._s(competitor.name))]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v(
                              _vm._s(_vm.i18n.itemId) +
                                " " +
                                _vm._s(competitor.code)
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "item-id",
                              attrs: { "aria-hidden": "true" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.i18n.itemId) + "\n              "
                              ),
                              _c("span", [_vm._v(_vm._s(competitor.code))])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.globals.siteConfig.hasCmirCode
                            ? _c("span", [
                                competitor.cmirCode
                                  ? _c("span", { staticClass: "separator" }, [
                                      _vm._v(" | ")
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                competitor.cmirCode
                                  ? _c("span", { staticClass: "cmir-code" }, [
                                      _vm._v(
                                        _vm._s(_vm.i18n.cmir) +
                                          "\n                "
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(competitor.cmirCode) +
                                            "\n                "
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(competitor.description)
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "icon-section" }, [
                          _c("span", {
                            staticClass: "mb-xs-3 icon-download",
                            attrs: {
                              title: _vm.pdfi18n.iconDownloadTitle,
                              tabindex: "0",
                              role: "button",
                              "aria-label": "pdfi18n.iconDownloadTitle"
                            },
                            on: {
                              click: function($event) {
                                _vm.handleDownloadInfo($event, competitor.code)
                              },
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter-space",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                _vm.handleDownloadInfo($event, competitor.code)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass:
                              "mb-xs-3 icon-add-to-list wishlist-btn",
                            attrs: {
                              title: _vm.pdfi18n.iconAddToListTitle,
                              tabindex: "0",
                              role: "pdfi18n.iconAddToListTitle",
                              "aria-label": _vm.pdfi18n.iconAddToListTitle
                            },
                            on: {
                              click: function($event) {
                                _vm.handleSelectList($event, competitor.code)
                              },
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter-space",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                _vm.handleSelectList($event, competitor.code)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.globals.siteConfig.isFindDistributorEnabled &&
                          competitor &&
                          competitor.code
                            ? _c("span", { staticClass: "find-distributor" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "" +
                                        _vm.globals.getNavBaseUrl() +
                                        _vm.globals.serviceUrls
                                          .findDistributor +
                                        "/pdp?productCode=" +
                                        competitor.code
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.i18n.findDistributor))]
                                )
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "right-section-slot" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "px-xs-0 py-xs-2 px-sm-0 pl-md-4 d-flex align-items-end"
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "btn btn-primary pull-right view-details",
                                attrs: {
                                  href:
                                    _vm.globals.getNavigationUrl("empty") +
                                    competitor.url
                                }
                              },
                              [_vm._v(_vm._s(_vm.i18n.viewDetails))]
                            )
                          ]
                        )
                      ])
                    ],
                    2
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            !product.competitorReplacementProducts ||
            !product.competitorReplacementProducts.length
              ? [
                  _c(
                    "div",
                    { staticClass: "no-replacement-prodcuts-message" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.i18n.noReplacementProdcutsMessage) +
                          "\n      "
                      )
                    ]
                  )
                ]
              : _vm._e()
          ],
          2
        )
      }),
      _vm._v(" "),
      _vm.guestListName
        ? [
            _c("vx-save-cart", {
              attrs: {
                prodQuantity: "1",
                "cart-guid": _vm.getprodId,
                i18n: _vm.pdfi18n,
                "is-product": "true",
                "guest-list-name": _vm.guestListName
              },
              on: { "list-saved": _vm.onSelectListSuccess }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.loadPdfGenerator
        ? _c(
            "vx-pdf-generator",
            {
              ref: "pdfModule",
              attrs: {
                name: "pdfversion",
                "has-sustainability-page": false,
                "pdf-data": _vm.pdpProductInfo
              },
              on: { pdpDownloadPDF: _vm.pdpPdfDownloaded }
            },
            [
              _c("div", { attrs: { slot: "details" }, slot: "details" }, [
                _c("div", { staticClass: "pdf-left-section" }, [
                  _vm.pdpProductInfo.name
                    ? _c("h5", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.replaceText(_vm.pdpProductInfo.name)
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo.description
                    ? _c("h5", [_vm._v("Description:")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pdpProductInfo.description
                    ? _c("p", {
                        staticStyle: { "font-size": "12px" },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.limitCharacters(_vm.pdpProductInfo.description)
                          )
                        }
                      })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.pdpProductInfo.featureList.length
                  ? _c("h5", { staticClass: "pdf-feature-heading" }, [
                      _vm._v("Features and Benefits:")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.pdpProductInfo.featureList
                  ? _c("div", { staticClass: "pdf-features" }, [
                      _c(
                        "ul",
                        {
                          staticClass: "features",
                          staticStyle: { "font-size": "12px" }
                        },
                        _vm._l(_vm.pdpProductInfo.featureList, function(
                          feature
                        ) {
                          return _vm.pdpProductInfo.featureList
                            ? _c("li", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.replaceText(feature))
                                }
                              })
                            : _vm._e()
                        })
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("img", { staticClass: "pdf-itf" }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "pdf-itf1",
                  attrs: {
                    src:
                      _vm.pdpProductInfo.images &&
                      _vm.pdpProductInfo.images[0] &&
                      _vm.pdpProductInfo.images[0].zoomUrl
                        ? _vm.pdpProductInfo.images[0].zoomUrl
                        : _vm.globals.assetsPath + "images/no_image.png"
                  }
                })
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "downloadInfoModal",
          attrs: { size: "large", heading: _vm.pdfi18n.downloadInfoHeading }
        },
        [
          _c("vx-download-info", {
            attrs: {
              slot: "component",
              "is-pdp": true,
              i18n: _vm.pdfi18n,
              "color-codes": _vm.colorCodes,
              "is-image-download-enabled":
                _vm.globals.siteConfig.downloadPdpImage,
              "is-pdf-download-enabled": _vm.globals.siteConfig.downloadPdpPdf
            },
            on: {
              onImageDownloadInit: function($event) {
                _vm.onImageDownloadInit($event)
              },
              onPdfDownloadInit: function($event) {
                _vm.createPDF($event)
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "selectListModal",
          attrs: { size: "small", heading: _vm.pdfi18n.selectListHeading }
        },
        [
          _c("vx-save-cart", {
            attrs: {
              slot: "component",
              prodQuantity: "1",
              "cart-guid": _vm.getprodId,
              i18n: _vm.pdfi18n,
              "is-product": "true"
            },
            on: { "list-saved": _vm.onSelectListSuccess },
            slot: "component"
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5b1cb222", { render: render, staticRenderFns: staticRenderFns })
  }
}