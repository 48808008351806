/**
 * This component is the common notify button component
 */

import vxModal from '../vx-modal/vx-modal.vue';
import vxNotifyMe from '../../search-browse/vx-notify-me/vx-notify-me.vue';
import globals from '../globals';
import flyoutBannerMixin from '../vx-flyout-banner/vx-flyout-banner-mixin';
import vxSpinner from '../vx-spinner/vx-spinner.vue';
import {
} from '../../../modules/event-bus';

export default {
  name: 'vx-notify-button',
  components: {
    vxModal,
    vxNotifyMe,
    vxSpinner,
  },
  mixins: [flyoutBannerMixin],
  data() {
    return {
      globals,
      productCode: '',
    };
  },
  props: {
    // Copy text coming from properties file
    i18n: {
      type: Object,
    },
  },
  mounted() {
  },
  methods: {
    /**
     * Function triggered on click of the Notify button
     */
    handleNotifyMe(event) {
      if (this.globals.loggedIn) {
        this.$refs.notifyMeModal.open(event);
      } else {
        this.navigateToLogin();
      }
    },
    /**
     * Function catches emit of success response of the Notify Me Service call
     */
    onNotifyMeSuccess(success) {
      this.$refs.notifyMeModal.close();
      this.showFlyout('success', success, true);
    },
    /**
     * Function catches emit of error response of the Notify Me Service call
     */
    onNotifyMeError(error) {
      this.$refs.notifyMeModal.close();
      this.showFlyout('error', error, true);
    },
    /**
     * Function to navigate to Login page
     */
    navigateToLogin() {
      const url = `${this.globals.getNavBaseUrl()}/login?site=${this.globals.siteId}`;
      window.location = url;
    },
  },
};
