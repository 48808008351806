var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-user-form" }, [
    _c(
      "form",
      {
        staticClass: "row",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitForm($event)
          }
        }
      },
      [
        _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
          _c("div", { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" }, [
            _c(
              "label",
              {
                attrs: {
                  for: "firstName",
                  "aria-hidden": _vm.isDeviceAndroid()
                }
              },
              [_vm._v(_vm._s(_vm.i18n.firstName))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    required: true,
                    regex: /^[A-Za-z\s\.'-]+$/,
                    max: 35
                  },
                  expression:
                    "{ required: true,\n                regex: /^[A-Za-z\\s\\.'-]+$/ ,max: 35 }"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userDetails.firstName,
                  expression: "userDetails.firstName"
                }
              ],
              staticClass: "form-control",
              class: { "input-error": _vm.errors.has("firstName") },
              attrs: {
                type: "text",
                maxlength: "35",
                name: "firstName",
                disabled: _vm.isEmailExists,
                "aria-label": _vm.i18n.firstName
              },
              domProps: { value: _vm.userDetails.firstName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.userDetails, "firstName", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("firstName")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", { staticClass: "error-msg" }, [
                    _vm._v(_vm._s(_vm.errors.first("firstName")))
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" }, [
            _c(
              "label",
              {
                attrs: { for: "lastName", "aria-hidden": _vm.isDeviceAndroid() }
              },
              [_vm._v(_vm._s(_vm.i18n.lastName))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    required: true,
                    regex: /^[A-Za-z\s\.'-]+$/,
                    max: 35
                  },
                  expression:
                    "{ required: true,\n                regex: /^[A-Za-z\\s\\.'-]+$/ ,max: 35 }"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userDetails.lastName,
                  expression: "userDetails.lastName"
                }
              ],
              staticClass: "form-control",
              class: { "input-error": _vm.errors.has("lastName") },
              attrs: {
                type: "text",
                maxlength: "35",
                name: "lastName",
                disabled: _vm.isEmailExists,
                "aria-label": _vm.i18n.lastName
              },
              domProps: { value: _vm.userDetails.lastName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.userDetails, "lastName", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("lastName")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", { staticClass: "error-msg" }, [
                    _vm._v(_vm._s(_vm.errors.first("lastName")))
                  ])
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
          _c("div", { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" }, [
            _c(
              "label",
              { attrs: { for: "email", "aria-hidden": _vm.isDeviceAndroid() } },
              [_vm._v(_vm._s(_vm.i18n.email))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email|max:80",
                  expression: "'required|email|max:80'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userDetails.email,
                  expression: "userDetails.email"
                }
              ],
              staticClass: "form-control",
              class: { "input-error": _vm.errors.has("email") },
              attrs: {
                type: "text",
                name: "email",
                "aria-label": _vm.i18n.email,
                maxlength: "80",
                disabled: _vm.isEmailExists
              },
              domProps: { value: _vm.userDetails.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.userDetails, "email", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userDetails.oldEmail,
                  expression: "userDetails.oldEmail"
                }
              ],
              attrs: { type: "hidden", name: "oldEmail" },
              domProps: { value: _vm.userDetails.oldEmail },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.userDetails, "oldEmail", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("email")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", { staticClass: "error-msg" }, [
                    _vm._v(_vm._s(_vm.errors.first("email")))
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-xs-3 mb-sm-5 form-group col-xs-12 col-md-6" },
            [
              _c(
                "label",
                {
                  staticClass: "dropdown-label",
                  attrs: { for: "businessUnitDropdown" }
                },
                [_vm._v(_vm._s(_vm.i18n.parentUnit))]
              ),
              _vm._v(" "),
              _c("vx-dropdown-primary", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                ref: "businessUnitDropdown",
                attrs: {
                  "describedby-aria": _vm.errors.has("businessUnit")
                    ? "business_unit_error_msg"
                    : null,
                  "required-aria": "true",
                  dropdownValues: _vm.businessUnitsList,
                  "aria-label": _vm.i18n.parentUnit,
                  name: "businessUnit",
                  dropdownError: _vm.errors.has("businessUnit"),
                  isDisabled: _vm.isEmailExists
                },
                on: {
                  "selected-option": function($event) {
                    _vm.userDetails.businessUnit = $event
                  }
                },
                model: {
                  value: _vm.userDetails.businessUnit,
                  callback: function($$v) {
                    _vm.$set(_vm.userDetails, "businessUnit", $$v)
                  },
                  expression: "userDetails.businessUnit"
                }
              }),
              _vm._v(" "),
              _vm.errors.has("businessUnit")
                ? _c("div", { staticClass: "mt-xs-2" }, [
                    _c(
                      "span",
                      {
                        staticClass: "error-msg",
                        attrs: {
                          "aria-live": "assertive",
                          id: "business_unit_error_msg"
                        }
                      },
                      [_vm._v(_vm._s(_vm.errors.first("businessUnit")))]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 mt-md-2" }, [
          _c("h3", { staticClass: "sub-heading" }, [
            _vm._v(_vm._s(_vm.i18n.selectRole))
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userDetails.role,
                  expression: "userDetails.role"
                }
              ],
              attrs: {
                type: "radio",
                id: "admin",
                name: "role",
                value: "b2badmingroup",
                disabled: _vm.isEmailExists
              },
              domProps: {
                checked: _vm._q(_vm.userDetails.role, "b2badmingroup")
              },
              on: {
                change: function($event) {
                  _vm.$set(_vm.userDetails, "role", "b2badmingroup")
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "admin" } }, [
              _vm._v(_vm._s(_vm.i18n.admin))
            ])
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userDetails.role,
                  expression: "userDetails.role"
                }
              ],
              attrs: {
                type: "radio",
                id: "buyer",
                name: "role",
                value: "b2bcustomergroup",
                disabled: _vm.isEmailExists
              },
              domProps: {
                checked: _vm._q(_vm.userDetails.role, "b2bcustomergroup")
              },
              on: {
                change: function($event) {
                  _vm.$set(_vm.userDetails, "role", "b2bcustomergroup")
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "buyer" } }, [
              _vm._v(_vm._s(_vm.i18n.buyer))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 mt-xs-2" }, [
          _c("h3", { staticClass: "sub-heading" }, [
            _vm._v(_vm._s(_vm.i18n.permissions))
          ]),
          _vm._v(" "),
          _c("div", [
            _c("label", { staticClass: "checkbox-container" }, [
              _vm._v(_vm._s(_vm.i18n.leaseSigner) + "\n                    "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.userDetails.leaseSigner,
                    expression: "userDetails.leaseSigner"
                  }
                ],
                attrs: { type: "checkbox", disabled: _vm.isEmailExists },
                domProps: {
                  checked: Array.isArray(_vm.userDetails.leaseSigner)
                    ? _vm._i(_vm.userDetails.leaseSigner, null) > -1
                    : _vm.userDetails.leaseSigner
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.userDetails.leaseSigner,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.userDetails,
                            "leaseSigner",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.userDetails,
                            "leaseSigner",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.userDetails, "leaseSigner", $$c)
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "checkmark" })
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.isEmailExists || !_vm.isFormModified
          ? _c("div", [
              _c(
                "div",
                { staticClass: "col-xs-12 alert-msg p-xs-3 mb-xs-5 mt-xs-3" },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(this.helpMessage) +
                      "\n            "
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 mt-xs-4 mt-sm-0 px-xs-3" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEmailExists,
                  expression: "isEmailExists"
                }
              ],
              staticClass:
                "btn btn-primary modal-btn-primary-small modal-btn-IEspacing",
              attrs: { type: "button" },
              on: { click: _vm.sendInvite }
            },
            [_vm._v(_vm._s(_vm.i18n.invite) + "\n            ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.isEmailExists &&
                    _vm.globals.userInfo.b2bUnitLevel === _vm.userStates.l1,
                  expression:
                    "!isEmailExists && globals.userInfo.b2bUnitLevel === userStates.l1"
                }
              ],
              staticClass:
                "btn btn-primary modal-btn-primary-small modal-btn-IEspacing mt-xs-4",
              attrs: { type: "submit" }
            },
            [_vm._v(_vm._s(_vm.i18n.save) + "\n            ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.isEmailExists &&
                    _vm.globals.userInfo.b2bUnitLevel !== _vm.userStates.l1,
                  expression:
                    "!isEmailExists && globals.userInfo.b2bUnitLevel !== userStates.l1"
                }
              ],
              staticClass:
                "btn btn-primary modal-btn-primary-small modal-btn-IEspacing",
              attrs: { type: "submit" }
            },
            [_vm._v(_vm._s(_vm.i18n.submit) + "\n            ")]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f3b8f434", { render: render, staticRenderFns: staticRenderFns })
  }
}