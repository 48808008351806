var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-order-confirmation" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "viewTermsModal",
          attrs: { size: "large", heading: _vm.leaseAgrementData.legalTermName }
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("p", {
              staticClass: "terms-content-intro",
              domProps: {
                innerHTML: _vm._s(_vm.leaseAgrementData.legalTermsText)
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _vm.dataLoaded
        ? [
            _c(
              "vx-modal",
              {
                ref: "cancelOrder",
                attrs: {
                  size: "medium",
                  heading: _vm.i18n.orderConfirmation.cancelOrder
                }
              },
              [
                _c("vx-cancel-order", {
                  attrs: {
                    slot: "component",
                    type: "modal",
                    i18n: _vm.i18n.orderConfirmation,
                    "order-id": _vm.orderDetails.code
                  },
                  on: {
                    "cancel-order-success": _vm.handleCancelOrderSuccess,
                    "cancel-order-error": _vm.handleCancelOrderError,
                    "keep-order": _vm.handleKeepOrder
                  },
                  slot: "component"
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c("h1", { staticClass: "confirmation-page-title m-xs-0" }, [
                _vm._v(_vm._s(_vm.i18n.orderConfirmation.thankYou))
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "confirmation-page-info pt-xs-4 pt-sm-3 col-xs-12 px-xs-0"
              },
              [
                _vm.orderDetails.isSubscription
                  ? [
                      _c("span", { staticClass: "col-xs-11 px-xs-0" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.i18n.orderConfirmation.subscptionOrderEmail
                            ) +
                            "\n        "
                        )
                      ])
                    ]
                  : [
                      _c("span", { staticClass: "col-xs-11 px-xs-0" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.i18n.orderConfirmation.orderEmail) +
                            "\n        "
                        )
                      ])
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _vm.globals.siteConfig.isReferAFriend
              ? _c("span", { attrs: { id: "extole_zone_confirmation" } })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-md-8 col-sm-12 col-xs-12 pr-xs-0 pl-xs-0 d-flex flex-row flex-wrap order-confirmation-section"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column confirmation-left-section"
                  },
                  [
                    _vm.giveAwayCouponCode || _vm.giveAwayCouponName
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "give-away-coupon py-xs-3 pl-xs-3 mt-xs-3"
                          },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.i18n.orderConfirmation.giveAwayCoupon
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _vm.giveAwayCouponName
                              ? _c("h5", [
                                  _vm._v(_vm._s(_vm.giveAwayCouponName))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.giveAwayCouponCode
                              ? _c("h5", [
                                  _vm._v(_vm._s(_vm.giveAwayCouponCode))
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 px-xs-0 order-number pt-xs-4 pt-sm-5"
                  },
                  [
                    _vm.orderDetails.isSubscription
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.i18n.orderConfirmation.subscptionOrder
                              ) +
                              "\n        "
                          )
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.i18n.orderConfirmation.order) +
                              "\n        "
                          )
                        ],
                    _vm._v(
                      "\n        #" + _vm._s(_vm.orderDetails.code) + "\n      "
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-4 col-sm-4 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                  },
                  [
                    _c("div", { staticClass: "order-info-heading" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderConfirmation.orderPlaced) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "order-info" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.getDateByTimeZone(_vm.orderDetails.created)
                          ) +
                          " " +
                          _vm._s(_vm.i18n.orderConfirmation.estName) +
                          "\n        "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-4 col-sm-4 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                  },
                  [
                    _c("div", { staticClass: "order-info-heading" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderConfirmation.orderStatus) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "order-info order-status" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderDetails.statusDisplay) +
                          "\n        "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                !_vm.isMultiple
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-md-4 col-sm-4 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                      },
                      [
                        _c("div", { staticClass: "order-info-heading" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.i18n.orderConfirmation.shippedTo) +
                              "\n        "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _c("span", [
                              _c("span", { staticClass: "delivery-address" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.firstName
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "delivery-address" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.lastName
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress &&
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress.companyName
                              ? _c(
                                  "span",
                                  { staticClass: "delivery-address" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetails.deliveryGroup[0].value
                                          .splitEntries[0].deliveryAddress
                                          .companyName
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "delivery-address" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.deliveryGroup[0].value
                                    .splitEntries[0].deliveryAddress.line1
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress.line2
                              ? _c(
                                  "span",
                                  { staticClass: "delivery-address" },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.orderDetails.deliveryGroup[0]
                                            .value.splitEntries[0]
                                            .deliveryAddress.line2
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", [
                              _c("span", { staticClass: "delivery-address" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.town
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "delivery-address" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.region
                                      .isocodeShort
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "delivery-address" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress
                                      .postalCode
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress &&
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress.country
                              ? _c(
                                  "span",
                                  { staticClass: "delivery-address" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetails.deliveryGroup[0].value
                                          .splitEntries[0].deliveryAddress
                                          .country.isocode
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress.palletShipment
                              ? _c("span", { staticClass: "pallet" }, [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.i18n.orderConfirmation
                                          .acceptsPalletShipmentMessage
                                      ) +
                                      ")"
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-4 col-sm-4 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                  },
                  [
                    _c("div", { staticClass: "order-info-heading" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderConfirmation.billedTo) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.orderDetails.paymentInfo &&
                    _vm.orderDetails.paymentInfo.cardType &&
                    _vm.orderDetails.paymentInfo.cardType.name &&
                    (_vm.orderDetails.paymentInfo.cardType.name.toLowerCase() ===
                      _vm.paymentTypes.googlePay.toLowerCase() ||
                      _vm.orderDetails.paymentInfo.cardType.name.toLowerCase() ===
                        _vm.paymentTypes.paypal.toLowerCase())
                      ? _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.accountHolderName
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.orderDetails.paymentInfo.cardNumber)
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.cardType.name
                                )
                              )
                            ])
                          ]
                        )
                      : _vm.orderDetails.paymentInfo &&
                        _vm.orderDetails.paymentInfo.cardType
                      ? _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.accountHolderName
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.cardType.name
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.orderDetails.paymentInfo.cardNumber)
                              )
                            ]),
                            _vm._v(" "),
                            _vm.orderDetails.paymentInfo.expiryMonth
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderDetails.paymentInfo.expiryMonth
                                    ) +
                                      "/" +
                                      _vm._s(
                                        _vm.orderDetails.paymentInfo.expiryYear
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderDetails.paymentInfo.billingAddress
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderDetails.paymentInfo
                                        .billingAddress.postalCode
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.i18n.orderConfirmation.notAvailable
                                ) +
                                "\n        "
                            )
                          ]
                        )
                  ]
                ),
                _vm._v(" "),
                _vm.globals.isB2B() && !_vm.isMultiple
                  ? [
                      _vm._l(
                        _vm.orderDetails.deliveryGroup[0].value.splitEntries,
                        function(entry) {
                          return [
                            _vm.checkInstallable(
                              _vm.formattedEntries[entry.entryNumber]
                            )
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-4 col-sm-4 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "order-info-heading" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.i18n.orderConfirmation
                                                .installation
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "order-info" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.getInstallDate(
                                              _vm.orderDetails
                                                .scheduleInstallation
                                                .preferredDate
                                            )
                                          ) +
                                          " |\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "install-time" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.orderDetails
                                                  .scheduleInstallation
                                                  .preferredTime
                                              )
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                !_vm.orderDetails.isSubscription
                  ? [
                      !_vm.isMultiple
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-md-4 col-sm-4 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                            },
                            [
                              _c("div", { staticClass: "order-info-heading" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.i18n.orderConfirmation.deliveryMethod
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _vm.orderDetails.deliveryMode
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "order-info shipping-method"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.orderDetails.deliveryMode.name
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                : [
                                    _vm.orderDetails.deliveryGroup &&
                                    _vm.orderDetails.deliveryGroup[0].value &&
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries &&
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryMode
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "order-info shipping-method"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.orderDetails
                                                    .deliveryGroup[0].value
                                                    .splitEntries[0]
                                                    .deliveryMode.name
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isMultiple && !_vm.orderDetails.isSubscription
                  ? [
                      _vm.orderDetails.deliveryMode
                        ? [
                            _vm.orderDetails.deliveryMode.description
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-4 col-sm-4 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "order-info-heading" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.i18n.orderConfirmation
                                                .deliveryInstructions
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "order-info" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.orderDetails.deliveryMode
                                              .description
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        : [
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryInstruction
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-4 col-sm-4 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "order-info-heading" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.i18n.orderConfirmation
                                                .deliveryInstructions
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "order-info" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.orderDetails.deliveryGroup[0]
                                              .value.splitEntries[0]
                                              .deliveryInstruction
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.globals.isB2B()
                  ? [
                      _vm.checkLeasable(_vm.orderDetails.deliveryGroup)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-md-4 col-sm-4 col-xs-6 pt-xs-4 pt-sm-5 pl-xs-0 order-info-block"
                            },
                            [
                              _c("div", { staticClass: "order-info-heading" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.i18n.orderConfirmation.leaseAgreement
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "order-info" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.orderDetails.leaseName) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "order-info" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        _vm.globals.getNavBaseUrl() +
                                        _vm.globals.navigations.leasePage +
                                        "/?" +
                                        _vm.order.orderId +
                                        "=" +
                                        _vm.orderDetails.code,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.i18n.orderConfirmation.download
                                      )
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderDetails.isSubscription
                  ? [
                      _c(
                        "p",
                        { staticClass: "col-xs-12 px-xs-0 pt-xs-5 sub-title" },
                        [_vm._v(_vm._s(_vm.i18n.orderConfirmation.itemOrdered))]
                      )
                    ]
                  : [
                      _c(
                        "p",
                        { staticClass: "col-xs-12 px-xs-0 pt-xs-5 sub-title" },
                        [
                          _vm._v(
                            _vm._s(_vm.i18n.orderConfirmation.itemsOrdered) +
                              "\n          (" +
                              _vm._s(_vm.orderDetails.totalProductQty) +
                              ")"
                          )
                        ]
                      )
                    ],
                _vm._v(" "),
                _vm._l(_vm.orderDetails.deliveryGroup, function(groups) {
                  return _c(
                    "div",
                    {
                      staticClass: "col-xs-12 px-xs-0",
                      class: { "pb-xs-5": _vm.isMultiple }
                    },
                    [
                      _vm.isMultiple
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 px-xs-4 py-xs-4 multi-shipping-details d-flex flex-row flex-wrap"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-6 pl-xs-0 multi-shipping-order-info"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "order-info-heading" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.i18n.orderConfirmation.shippedTo
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "order-info d-flex flex-column"
                                    },
                                    [
                                      _c("span", { staticClass: "mb-xs-0" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              groups.value.splitEntries[0]
                                                .deliveryAddress.firstName
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              groups.value.splitEntries[0]
                                                .deliveryAddress.lastName
                                            )
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      groups.value.splitEntries &&
                                      groups.value.splitEntries.length !== 0 &&
                                      groups.value.splitEntries[0] &&
                                      groups.value.splitEntries[0]
                                        .deliveryAddress &&
                                      groups.value.splitEntries[0]
                                        .deliveryAddress.companyName
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                groups.value.splitEntries[0]
                                                  .deliveryAddress.companyName
                                              )
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            groups.value.splitEntries[0]
                                              .deliveryAddress.line1
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      groups.value.splitEntries[0]
                                        .deliveryAddress.line2
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  groups.value.splitEntries[0]
                                                    .deliveryAddress.line2
                                                ) +
                                                "\n              "
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("span", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              groups.value.splitEntries[0]
                                                .deliveryAddress.town
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              groups.value.splitEntries[0]
                                                .deliveryAddress.region
                                                .isocodeShort
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              groups.value.splitEntries[0]
                                                .deliveryAddress.postalCode
                                            )
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      groups.value.splitEntries[0]
                                        .deliveryAddress &&
                                      groups.value.splitEntries[0]
                                        .deliveryAddress.country
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                groups.value.splitEntries[0]
                                                  .deliveryAddress.country
                                                  .isocode
                                              )
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      groups.value.splitEntries[0]
                                        .deliveryAddress.palletShipment
                                        ? _c(
                                            "span",
                                            { staticClass: "pallet" },
                                            [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    _vm.i18n.orderConfirmation
                                                      .acceptsPalletShipmentMessage
                                                  ) +
                                                  ")"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              groups.value.splitEntries[0]
                                .deliveryInstruction &&
                              !_vm.orderDetails.isSubscription
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xs-12 col-sm-6 pt-xs-4 pl-xs-0 multi-shipping-order-info pt-sm-0"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "order-info-heading" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.i18n.orderConfirmation
                                                    .deliveryInstructions
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "order-info" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  groups.value.splitEntries[0]
                                                    .deliveryInstruction
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-6 pl-xs-0 multi-shipping-order-info",
                                  class: groups.value.splitEntries[0]
                                    .deliveryInstruction
                                    ? "pt-xs-4"
                                    : ""
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "order-info-heading" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.i18n.orderConfirmation
                                              .deliveryMethod
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "order-info shipping-method"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            groups.value.splitEntries[0]
                                              .deliveryMode.name
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.globals.isB2B()
                                ? [
                                    _vm._l(groups.value.splitEntries, function(
                                      entry
                                    ) {
                                      return [
                                        _vm.checkInstallable(
                                          _vm.formattedEntries[
                                            entry.entryNumber
                                          ]
                                        )
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-xs-12 col-sm-6 pt-xs-4 pl-xs-0 multi-shipping-order-info"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "order-info-heading"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.i18n
                                                            .orderConfirmation
                                                            .installation
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "order-info" },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.getInstallDate(
                                                            _vm.orderDetails
                                                              .scheduleInstallation
                                                              .preferredDate
                                                          )
                                                        ) +
                                                        " |\n                  "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "install-time"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.orderDetails
                                                                .scheduleInstallation
                                                                .preferredTime
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(groups.value.splitEntries, function(productEntry) {
                        return productEntry.visible
                          ? _c(
                              "vx-product-tile",
                              {
                                staticClass: "px-xs-0 col-xs-12",
                                class: {
                                  "pb-xs-3": !_vm.isMultiple,
                                  "pb-lg-5": !_vm.isMultiple,
                                  "pb-sm-5": !_vm.isMultiple,
                                  "remove-top-border": _vm.isMultiple
                                }
                              },
                              [
                                _vm.formattedEntries &&
                                _vm.formattedEntries[
                                  productEntry.entryNumber
                                ] &&
                                _vm.formattedEntries[productEntry.entryNumber]
                                  .product &&
                                _vm.formattedEntries[productEntry.entryNumber]
                                  .product.images &&
                                _vm.formattedEntries[productEntry.entryNumber]
                                  .product.images.length !== 0 &&
                                _vm.formattedEntries[productEntry.entryNumber]
                                  .product.images[2] &&
                                _vm.formattedEntries[productEntry.entryNumber]
                                  .product.images[2].url
                                  ? _c("template", { slot: "image-slot" }, [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              _vm.globals.getNavigationUrl(
                                                "empty"
                                              ) +
                                              _vm.formattedEntries[
                                                productEntry.entryNumber
                                              ].product.url
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              alt: _vm.formattedEntries[
                                                productEntry.entryNumber
                                              ].product.name
                                                ? _vm.formattedEntries[
                                                    productEntry.entryNumber
                                                  ].product.name
                                                : _vm.i18n.noProductName,
                                              src: _vm.globals.getThumbnailImageUrl(
                                                _vm.formattedEntries[
                                                  productEntry.entryNumber
                                                ].product.images
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.formattedEntries[productEntry.entryNumber]
                                  .product.images
                                  ? _c("template", { slot: "image-slot" }, [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              _vm.globals.getNavigationUrl(
                                                "empty"
                                              ) +
                                              _vm.formattedEntries[
                                                productEntry.entryNumber
                                              ].product.url
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              alt: "no image",
                                              src:
                                                _vm.globals.assetsPath +
                                                "images/no_image.svg"
                                            }
                                          })
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "template",
                                  { slot: "details" },
                                  [
                                    _vm.formattedEntries &&
                                    _vm.formattedEntries[
                                      productEntry.entryNumber
                                    ] &&
                                    _vm.formattedEntries[
                                      productEntry.entryNumber
                                    ].product &&
                                    _vm.formattedEntries[
                                      productEntry.entryNumber
                                    ].product.name
                                      ? [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "product-title mb-xs-2"
                                            },
                                            [
                                              _c("a", {
                                                attrs: {
                                                  href:
                                                    _vm.globals.getNavigationUrl(
                                                      "empty"
                                                    ) +
                                                    _vm.formattedEntries[
                                                      productEntry.entryNumber
                                                    ].product.url,
                                                  tabindex: "0"
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.formattedEntries[
                                                      productEntry.entryNumber
                                                    ].product.name
                                                  )
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.i18n.orderConfirmation.itemId
                                          ) +
                                          " : " +
                                          _vm._s(
                                            _vm.formattedEntries[
                                              productEntry.entryNumber
                                            ].product.code
                                          ) +
                                          "\n            "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "product-code mb-xs-2",
                                        attrs: { "aria-hidden": "true" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.i18n.orderConfirmation.itemId
                                          ) +
                                            "\n              " +
                                            _vm._s(
                                              _vm.formattedEntries[
                                                productEntry.entryNumber
                                              ].product.code
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.globals.isB2B()
                                      ? _c("div", [
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ] &&
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product &&
                                          (_vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product.materialStatus ===
                                            _vm.ProductAvailability.OBSOLETE ||
                                            _vm.formattedEntries[
                                              productEntry.entryNumber
                                            ].product.materialStatus ===
                                              _vm.ProductAvailability
                                                .PREDISCONTINUED) &&
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product.replacementProductCode &&
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product.replacementProductCode !==
                                            ""
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "product-stock mb-xs-3"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        _vm.i18n
                                                          .orderConfirmation
                                                          .discontinued
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.i18n
                                                          .orderConfirmation
                                                          .replacedBy
                                                      ) +
                                                      "\n              " +
                                                      _vm._s(
                                                        _vm.formattedEntries[
                                                          productEntry
                                                            .entryNumber
                                                        ].product
                                                          .replacementProductCode
                                                      ) +
                                                      "\n            "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ] &&
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product &&
                                          (_vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product.materialStatus ===
                                            _vm.ProductAvailability.OBSOLETE ||
                                            _vm.formattedEntries[
                                              productEntry.entryNumber
                                            ].product.materialStatus ===
                                              _vm.ProductAvailability
                                                .PREDISCONTINUED) &&
                                          !_vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product.replacementProductCode
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "product-stock mb-xs-3"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        _vm.i18n
                                                          .orderConfirmation
                                                          .discontinued
                                                      ) +
                                                      "\n            "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "product-quantity mb-xs-3"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.i18n.orderConfirmation.quantity
                                          ) +
                                            " :\n              " +
                                            _vm._s(productEntry.qty)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.formattedEntries[
                                      productEntry.entryNumber
                                    ] &&
                                    _vm.formattedEntries[
                                      productEntry.entryNumber
                                    ].product &&
                                    _vm.formattedEntries[
                                      productEntry.entryNumber
                                    ].product.notPurchasable
                                      ? _c(
                                          "p",
                                          { staticClass: "error-text mb-xs-3" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.i18n.orderConfirmation
                                                    .notPurchasable
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.promotionData[productEntry.entryNumber]
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "product-promo mb-xs-3 mr-sm-3"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.i18n.orderConfirmation
                                                    .promo
                                                ) +
                                                ":\n              " +
                                                _vm._s(
                                                  _vm.promotionData[
                                                    productEntry.entryNumber
                                                  ]
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.orderDetails.isSubscription &&
                                    _vm.formattedEntries[
                                      productEntry.entryNumber
                                    ].subscriptionFrequency &&
                                    _vm.formattedEntries[
                                      productEntry.entryNumber
                                    ].subscriptionFrequency.entry
                                      ? _c(
                                          "p",
                                          {
                                            ref: "subscribeInfo",
                                            refInFor: true,
                                            staticClass: "subscription"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.i18n.orderConfirmation
                                                    .subscribeFrequencyHeading
                                                ) +
                                                _vm._s(
                                                  _vm.formattedEntries[
                                                    productEntry.entryNumber
                                                  ].subscriptionFrequency
                                                    .entry[0].key
                                                ) +
                                                "\n              "
                                            ),
                                            _c("span", {
                                              staticClass:
                                                "icon-alert-circle popover-subscribe",
                                              attrs: {
                                                role: "button",
                                                tabindex: "0",
                                                "aria-label":
                                                  _vm.i18n.orderConfirmation
                                                    .subscribeIconTitle,
                                                "data-toggle": "popover"
                                              },
                                              on: {
                                                mouseover: function($event) {
                                                  _vm.openTooltip()
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.globals.isB2C() &&
                                    _vm.globals.siteConfig.isGiftingEnabled
                                      ? [
                                          _vm.checkGiftable(
                                            _vm.formattedEntries[
                                              productEntry.entryNumber
                                            ]
                                          )
                                            ? [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "helper-text mb-xs-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.i18n
                                                          .orderConfirmation
                                                          .giftable
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.formattedEntries[
                                                  productEntry.entryNumber
                                                ].giftMessage
                                                  ? [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "gift-message-heading mb-xs-2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.i18n
                                                                .orderConfirmation
                                                                .giftMessageHeading
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "gift-message"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm
                                                                .formattedEntries[
                                                                productEntry
                                                                  .entryNumber
                                                              ].giftMessage
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ]
                                            : _vm._e()
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.globals.isB2B()
                                      ? [
                                          _vm.installableProducts[
                                            productEntry.entryNumber
                                          ]
                                            ? [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "helper-text mb-xs-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.i18n
                                                          .orderConfirmation
                                                          .installable
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "template",
                                  { slot: "right-section-slot" },
                                  [
                                    _vm.orderDetails.isSubscription &&
                                    !_vm.formattedEntries[
                                      productEntry.entryNumber
                                    ].product.notPurchasable
                                      ? [
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product.subscriptionPrice &&
                                          _vm.orderDetails
                                            .totalSubscriptionPrice
                                            .formattedValue
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "product-total-price",
                                                  attrs: {
                                                    role: "text",
                                                    "aria-label":
                                                      "total Price " +
                                                      _vm.orderDetails
                                                        .totalSubscriptionPrice
                                                        .formattedValue
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.orderDetails
                                                          .totalSubscriptionPrice
                                                          .formattedValue
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product.subsPercentageDiscount
                                            ? _c(
                                                "p",
                                                { staticClass: "subscription" },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.i18n
                                                          .orderConfirmation
                                                          .subscribeDiscount1
                                                      ) +
                                                      _vm._s(
                                                        _vm.formattedEntries[
                                                          productEntry
                                                            .entryNumber
                                                        ].product
                                                          .subsPercentageDiscount
                                                      ) +
                                                      _vm._s(
                                                        _vm.i18n
                                                          .orderConfirmation
                                                          .subscribeDiscount2
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      : [
                                          productEntry.price &&
                                          productEntry.price.formattedValue &&
                                          !productEntry.notPurchasable
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "product-total-price mb-xs-3"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        productEntry.price
                                                          .formattedValue
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                    _vm._v(" "),
                                    _vm.globals.siteConfig.reorderEnabled &&
                                    !_vm.orderDetails.isSubscription &&
                                    !_vm.formattedEntries[
                                      productEntry.entryNumber
                                    ].product.stock.isProductUnavailable
                                      ? [
                                          _c("vx-reorder-product", {
                                            attrs: {
                                              "single-product-data":
                                                _vm.formattedEntries[
                                                  productEntry.entryNumber
                                                ],
                                              i18n: _vm.i18n.reorderButton
                                            }
                                          })
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              2
                            )
                          : _vm._e()
                      })
                    ],
                    2
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-md-4 col-sm-6 col-xs-12 px-xs-0 pt-xs-3 order-summary-container"
              },
              [
                _c(
                  "vx-order-summary",
                  {
                    staticClass: "ml-md-5",
                    attrs: {
                      i18n: _vm.i18n.orderSummary,
                      "order-details": _vm.orderDetails,
                      page: "orderConfirmation",
                      "is-shipping-multiple": _vm.isMultiple,
                      "is-giftable": _vm.isGiftable,
                      "is-installable": _vm.isInstallable,
                      "contact-number": _vm.contactNumber
                    }
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "bottomButton" }, slot: "bottomButton" },
                      [
                        !_vm.globals.getIsLoggedIn() && _vm.showCancel
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "col-xs-12 mb-xs-3 btn btn-tertiary",
                                on: {
                                  click: function($event) {
                                    _vm.handleCancelOrder($event)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.i18n.orderConfirmation.cancelOrder)
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.globals.siteConfig.reorderEnabled &&
                        !_vm.orderDetails.isSubscription &&
                        !(
                          _vm.orderDetails.entries.length === 1 &&
                          _vm.orderDetails.entries[0].product.stock
                            .isProductUnavailable
                        )
                          ? [
                              _c("vx-reorder-product", {
                                attrs: {
                                  "multiple-products-data":
                                    _vm.formattedEntries,
                                  i18n: _vm.i18n.reorderButton
                                }
                              })
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6f2d9e36", { render: render, staticRenderFns: staticRenderFns })
  }
}