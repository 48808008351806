var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "product-solution",
      class: _vm.productSolutionData.componentTheme,
      attrs: { id: _vm.productSolutionData.componentId }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "row d-flex align-items-center justify-content-center flex-xs-column flex-sm-row"
        },
        [
          _c("div", { staticClass: "col-xs-12 col-sm-6 text-right" }, [
            _c("div", { staticClass: "product-solution__wrapper" }, [
              _vm.productSolutionData.imageLink
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.productSolutionData.isExternalImage
                          ? _vm.productSolutionData.imageLink
                          : _vm.globals.getUrlWithContextPath(
                              _vm.productSolutionData.imageLink
                            ),
                        target: _vm.productSolutionData.isExternalImage
                          ? "_blank"
                          : ""
                      }
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            media: "(min-width: 1200px)",
                            srcset: _vm.productSolutionData.backgroundImageD
                          }
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            media: "(min-width: 768px)",
                            srcset: _vm.productSolutionData.backgroundImageT
                          }
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            media: "(min-width: 360px)",
                            srcset: _vm.productSolutionData.backgroundImageM
                          }
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            src: _vm.backgroundImg,
                            alt: [_vm.backgroundAlt]
                          }
                        })
                      ])
                    ]
                  )
                : _c(
                    "span",
                    {
                      attrs: {
                        href: _vm.productSolutionData.isExternalImage
                          ? _vm.productSolutionData.imageLink
                          : _vm.globals.getUrlWithContextPath(
                              _vm.productSolutionData.imageLink
                            ),
                        target: _vm.productSolutionData.isExternalImage
                          ? "_blank"
                          : ""
                      }
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            media: "(min-width: 1200px)",
                            srcset: _vm.productSolutionData.backgroundImageD
                          }
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            media: "(min-width: 768px)",
                            srcset: _vm.productSolutionData.backgroundImageT
                          }
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            media: "(min-width: 360px)",
                            srcset: _vm.productSolutionData.backgroundImageM
                          }
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            src: _vm.backgroundImg,
                            alt: [_vm.backgroundAlt]
                          }
                        })
                      ])
                    ]
                  ),
              _vm._v(" "),
              _vm.productSolutionData.isVideo === "true"
                ? _c("a", {
                    staticClass:
                      "image-title__video-play-icon image-title__position__center icon-play",
                    style: { color: _vm.productSolutionData.playIconColor },
                    attrs: {
                      tabindex: "0",
                      target: _vm.productSolutionData.isExternalImage
                        ? "_blank"
                        : "",
                      href: "javascript:void(0)",
                      "aria-label": _vm.i18n.iconPlayTitle,
                      title: _vm.i18n.iconPlayTitle
                    },
                    on: {
                      click: function($event) {
                        _vm.openModal(_vm.productSolutionData.videoSrc)
                      }
                    }
                  })
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-6 mt-sm-5 text-block" },
            [
              _vm.productSolutionData.highlighterText
                ? _c("h4", {
                    staticClass: "highlight-header",
                    domProps: {
                      innerHTML: _vm._s(_vm.productSolutionData.highlighterText)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.productSolutionData.componentHeader
                ? _c("h2", {
                    staticClass: "header mt-xs-0",
                    style: {
                      color: _vm.productSolutionData.componentHeaderColor
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.productSolutionData.componentHeader)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.productSolutionData.subHeaderText
                ? _c("p", {
                    staticClass: "sub-header",
                    domProps: {
                      innerHTML: _vm._s(_vm.productSolutionData.subHeaderText)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.productSolutionData.tiles, function(tile) {
                return _c("div", { staticClass: "row tile" }, [
                  _c("div", { staticClass: "col-xs-2" }, [
                    _c("img", {
                      attrs: {
                        src: tile.imgSrcD,
                        alt: _vm.getResponsiveAlt(
                          tile.imgSrcAltTextD,
                          tile.imgSrcAltTextT,
                          tile.imgSrcAltTextM
                        )
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xs-10" }, [
                    _c("h6", {
                      staticClass: "tile-header",
                      style: { color: tile.headerColor },
                      domProps: { innerHTML: _vm._s(tile.header) }
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "tile-desc mb-xs-3",
                      domProps: { innerHTML: _vm._s(tile.tileText) }
                    })
                  ])
                ])
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.productSolutionData.ctaText &&
                _vm.productSolutionData.ctaLink
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-md-6 mb-xs-2 text-center btn-container"
                      },
                      [
                        !_vm.productSolutionData.jumpToHtml
                          ? _c("a", {
                              staticClass: "product-solution-cta",
                              class: _vm.productSolutionData.ctaStyle,
                              style: {
                                color: _vm.productSolutionData.ctaColor
                              },
                              attrs: {
                                href: _vm.productSolutionData.isExternal
                                  ? _vm.productSolutionData.ctaLink
                                  : _vm.globals.getUrlWithContextPath(
                                      _vm.productSolutionData.ctaLink
                                    ),
                                target: _vm.productSolutionData.isExternal
                                  ? "_blank"
                                  : ""
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.productSolutionData.ctaText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.productSolutionData.jumpToHtml
                          ? _c("a", {
                              staticClass: "product-solution-cta",
                              class: _vm.productSolutionData.ctaStyle,
                              style: {
                                color: _vm.productSolutionData.ctaColor
                              },
                              attrs: {
                                href: _vm.productSolutionData.isExternal
                                  ? _vm.productSolutionData.ctaLink
                                  : _vm.globals.getUrlWithContextPath(
                                      _vm.productSolutionData.ctaLink
                                    ),
                                target: _vm.productSolutionData.isExternal
                                  ? "_blank"
                                  : ""
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.productSolutionData.ctaText
                                )
                              },
                              on: {
                                click: function($event) {
                                  _vm.emitScrollSignal(
                                    _vm.productSolutionData.ctaLink
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.productSolutionData.ctaText2 &&
                _vm.productSolutionData.ctaLink2
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-md-6 mb-xs-2 text-center btn-container"
                      },
                      [
                        _c("a", {
                          staticClass: "product-solution-cta",
                          class: _vm.productSolutionData.ctaStyle,
                          style: { color: _vm.productSolutionData.ctaColor2 },
                          attrs: {
                            href: _vm.productSolutionData.isExternalCta2
                              ? _vm.productSolutionData.ctaLink2
                              : _vm.globals.getUrlWithContextPath(
                                  _vm.productSolutionData.ctaLink2
                                ),
                            target: _vm.productSolutionData.isExternalCta2
                              ? "_blank"
                              : ""
                          },
                          domProps: {
                            innerHTML: _vm._s(_vm.productSolutionData.ctaText2)
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ])
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5dd7dea1", { render: render, staticRenderFns: staticRenderFns })
  }
}