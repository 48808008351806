var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-uiux-product-categories-carousel",
      class: _vm.componentTheme
    },
    [
      _vm.componentTitle
        ? _c("h2", {
            staticClass: "header",
            domProps: { innerHTML: _vm._s(_vm.componentTitle) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "categories", attrs: { role: "tablist" } },
        _vm._l(_vm.tabsData, function(category) {
          return _c(
            "span",
            {
              staticClass: "category",
              class: {
                selected:
                  _vm.selectedCategory === category.uiuxCategory.categoryHeader
              },
              attrs: {
                role: "tab",
                "aria-selected":
                  _vm.selectedCategory === category.uiuxCategory.categoryHeader,
                tabindex:
                  _vm.selectedCategory === category.uiuxCategory.categoryHeader
                    ? 0
                    : -1
              },
              on: {
                click: function($event) {
                  _vm.selectCategory(category.uiuxCategory.categoryHeader)
                },
                keyup: [
                  function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    _vm.selectCategory(category.uiuxCategory.categoryHeader)
                  },
                  function($event) {
                    _vm.arrowNavigation($event)
                  }
                ]
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(category.uiuxCategory.categoryHeader) +
                  "\n    "
              )
            ]
          )
        })
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "products" },
        _vm._l(_vm.tabsData, function(category) {
          return _vm.selectedCategory === category.uiuxCategory.categoryHeader
            ? _c(
                "div",
                {
                  staticClass: "category-products",
                  attrs: { role: "tabpanel" }
                },
                [
                  _vm.showNavigationArrows
                    ? _c("button", {
                        staticClass: "swiper-button-prev icon-chevron-left",
                        class: { "swiper-button-disabled": _vm.firstSlide },
                        attrs: {
                          tabindex: "0",
                          "aria-label": _vm.i18n.iconChevronLeftTitle,
                          disabled: _vm.firstSlide ? true : false
                        },
                        on: {
                          click: function($event) {
                            _vm.slidePrev()
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "swiper",
                    {
                      ref: "swiper",
                      refInFor: true,
                      attrs: { options: _vm.swiperOptions }
                    },
                    _vm._l(category.productList, function(product, index) {
                      return _c("swiper-slide", { key: index }, [
                        _c(
                          "a",
                          {
                            staticClass: "product",
                            attrs: { href: product.url },
                            on: { keydown: _vm.focusedElement }
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "lazy-container",
                                    rawName: "v-lazy-container",
                                    value: { selector: "img" },
                                    expression: "{ selector: 'img' } "
                                  }
                                ]
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    "data-src": product.imageUrl,
                                    "data-loading":
                                      _vm.globals.assetsPath +
                                      "images/spinner.gif",
                                    alt: product.name
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            product.name
                              ? _c("div", {
                                  staticClass: "name",
                                  domProps: { innerHTML: _vm._s(product.name) }
                                })
                              : _vm._e()
                          ]
                        )
                      ])
                    })
                  ),
                  _vm._v(" "),
                  _vm.showNavigationArrows
                    ? _c("button", {
                        staticClass: "swiper-button-next icon-chevron-right",
                        class: { "swiper-button-disabled": _vm.lastSlide },
                        attrs: {
                          tabindex: "0",
                          "aria-label": _vm.i18n.iconChevronRightTitle,
                          disabled: _vm.lastSlide ? true : false
                        },
                        on: {
                          click: function($event) {
                            _vm.slideNext()
                          },
                          keydown: _vm.focusTrap
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        })
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3eb12f7c", { render: render, staticRenderFns: staticRenderFns })
  }
}