/**
 * Tabbed banner component
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-tabbed-banner',
  components: {},
  props: {
    carouselData: Object,
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      selectedItemTitle: null,
      selectedItemImageLink: null,
      shownHeader: null,
      shownDescription: null,
      shownButtonLink: null,
      shownButtonText: null,
    };
  },
  computed: {},
  mounted() {
    if (this.carouselData.ctaLink && this.carouselData.ctaText && this.carouselData.isExternalLink === true) {
      this.$refs.navButton.target = '_blank';
    }
    if (this.carouselData.gpTabbedData.length > 0) {
      this.selectItem(this.carouselData.gpTabbedData[0], true);
    }
  },
  methods: {
    selectItem(item, initialSelect) {
      this.selectedItemTitle = item.tabTitle;
      if (item.imageLink) {
        this.selectedItemImageLink = item.imageLink;
        this.$refs.item.classList.add('is-link');
      } else {
        this.selectedItemImageLink = null;
        this.$refs.item.classList.remove('is-link');
      }
      const backgroundImageURL = this.getResponsiveImage(item.backgroundImageD, item.backgroundImageT, item.backgroundImageM);
      this.$refs.item.style.background = `url("${backgroundImageURL}") no-repeat center rgba(0, 0, 0, 0.25)`;
      this.$refs.item.style.backgroundSize = 'cover';
      this.shownHeader = item.headerContent;
      this.$refs.header.style.color = item.headerColor ? item.headerColor : '#ffffff';
      if (this.$refs.header.childNodes[0] && this.$refs.header.childNodes[0].style) {
        this.$refs.header.childNodes[0].style.color = item.headerColor ? item.headerColor : '#ffffff';
      }
      this.shownDescription = item.bodyContent;
      this.$refs.description.style.color = item.bodyColor ? item.bodyColor : '#ffffff';
      this.shownButtonLink = item.ctaLink;
      this.shownButtonText = item.ctaText;
      if (item.shownButtonLink && item.shownButtonText) {
        this.$refs.shownButton.target = item.isExternalLink === true ? '_blank' : '_self';
      }
      // Only scroll to image item if elements are stacked on top of each other and this isn't the initial mounted selectItem() call
      const navStyle = window.getComputedStyle(document.querySelector('.nav'));
      if (parseInt(navStyle.getPropertyValue('margin-right'), 10) === 0 && !initialSelect) {
        document.querySelector('#link-container').scrollIntoView({ behavior: 'smooth' });
      }
    },
    goToImageLink() {
      if (this.selectedItemImageLink) {
        window.location.href = this.selectedItemImageLink;
      }
    },
  },
};
