import globals from '../globals';

export default {
  name: 'vx-category-desc',
  components: {},
  props: ['categoryDescription'],
  data() {
    return {
      globals,
    };
  },
  computed: {

  },
  methods: {},
};
