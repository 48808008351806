var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-users-landing pt-xs-4 pt-sm-0 mt-sm-5" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addUserModal",
          attrs: { size: "large", heading: _vm.i18n.addUser }
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _c("vx-user-form", {
                attrs: {
                  slot: "component",
                  "is-email-exists": _vm.isEmailExists,
                  "user-role": _vm.UserRoles.CUSTOMER,
                  "business-units-list": _vm.businessUnitsList,
                  "form-data": _vm.userDetails,
                  i18n: _vm.i18n,
                  type: "modal",
                  isEdit: false
                },
                on: {
                  showSpinner: _vm.showLoadingSpinner,
                  hideSpinner: _vm.hideLoadingSpinner,
                  closeUserModal: _vm.closeUserModal,
                  setUsers: _vm.setUsersData
                },
                slot: "component"
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c("h3", { staticClass: "header mt-xs-0" }, [
          _vm._v("\n            " + _vm._s(_vm.i18n.users) + "\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row d-flex px-xs-3" }, [
        _c("div", { staticClass: "col-sm-6 col-xs-12 py-xs-2 py-sm-3" }, [
          _c("div", { staticClass: "row vx-profile-card d-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 add-card card-default-height mb-xs-3 px-xs-3 py-xs-3 pl-sm-4 pr-sm-3 add-new-user"
              },
              [
                _c("div", { staticClass: "add-user-wrapper" }, [
                  _c("span", {
                    staticClass: "icon-big icon-plus",
                    attrs: { title: "Add", "aria-hidden": "true" }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "add-user btn-link add-create-btn",
                        attrs: {
                          href: "javascript:void(0)",
                          role: "button",
                          "aria-label": _vm.i18n.addUsers
                        },
                        on: {
                          click: function($event) {
                            _vm.openAddUserModal($event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.addUsers))]
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.showInviteMsg
        ? _c("div", { staticClass: "toast py-xs-3 px-xs-4" }, [
            _c("span", {
              staticClass: "icon-check",
              attrs: { title: _vm.i18n.iconSuccessTitle }
            }),
            _vm._v("\n        " + _vm._s(_vm.i18n.inviteUser) + "\n        "),
            _c("span", {
              staticClass: "icon-x pull-right",
              attrs: {
                title: _vm.i18n.iconCloseTitle,
                "aria-label": _vm.i18n.iconCloseTitle
              },
              on: {
                click: function($event) {
                  _vm.showInviteMsg = !_vm.showInviteMsg
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isMobile()
        ? _c(
            "div",
            { staticClass: "mb-xs-3" },
            [
              _c("vx-dropdown-primary", {
                ref: "sortDropdown",
                attrs: { dropdownValues: _vm.sortOptions },
                on: {
                  "selected-option": function($event) {
                    _vm.triggerSort($event, "#users-data-table")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("vx-table", {
        attrs: { tableConfig: _vm.tableConfig, tableData: _vm.users }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0ffd4e66", { render: render, staticRenderFns: staticRenderFns })
  }
}