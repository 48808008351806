import backgroundImageMixin from '../../common/mixins/backgroundImage-mixin';
import mobileMixin from '../../common/mixins/mobile-mixin';
import globals from '../../common/globals';
import { globalEventBus } from '../../../modules/event-bus';

export default {
  name: 'vx-side-by-side',
  components: {},
  mixins: [backgroundImageMixin, mobileMixin],
  props: {
    sbsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      globals,
    };
  },
  computed: {
    backgroundAlt() {
      return this.getResponsiveAlt(
        this.sbsData.sbsGlobalBgImgAltTextD,
        this.sbsData.sbsGlobalBgImgAltTextT,
        this.sbsData.sbsGlobalBgImgAltTextM,
      );
    },
  },
  async mounted() {
    // this.backgroundImage();
  },
  methods: {
    /**
     * returns background image that is authored
     * @return {object} background image
     */
    backgroundImage(data) {
      // this.sbsData.sbsImage = this.imageTileData.imageSrcM ? this.imageTileData.imageSrcM : this.imageTileData.imageSrcD;
      return (this.isTablet() && !this.isMobile()) || !this.isTablet()
        ? {
          backgroundImage: `url(${data.sbsImage})`,
        }
        : {
          backgroundImage: `url(${data.sbsImage})`,
        };
    },
    /**
     *
     * @param {object} data
     * @param {number} index
     * @return {string} string
     */
    getBackgroundAlt(data) {
      return data.sbsImageAltText;
    },
    /**
     * emits the scroll-to event with the link as payload
     * @param {string} link
     */
    emitScrollSignal(link) {
      globalEventBus.$emit('scroll-to', link);
    },
  },
};
