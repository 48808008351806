import vxModal from '../vx-modal/vx-modal.vue';
import globals from '../globals';
import CommonService from '../services/common-service';
import cookiesMixin from '../mixins/cookies-mixin';
import {
  cookies,
} from '../mixins/vx-enums';
import vxSpinner from '../vx-spinner/vx-spinner.vue';

export default {
  name: 'vx-marketing-modal',
  components: {
    vxModal,
    vxSpinner,
  },
  props: [],
  data() {
    return {
      globals,
      commonService: new CommonService(),
      cookies,
      marketingModalPage: '',
    };
  },
  mixins: [cookiesMixin],
  computed: {

  },
  async mounted() {
    this.onInit();
  },
  methods: {
    /**
     * This function is called on mounted
     * */
    onInit() {
      const self = this;
      if (this.globals.siteConfig.cookieExpirationTime) {
        window.setTimeout(() => {
          self.$refs.marketingModal.close();
        }, this.globals.siteConfig.cookieExpirationTime);
      }
      if (!self.readCookie(self.cookies.marketingModal)) {
        if (self.$refs.marketingModal) {
          self.$refs.marketingModal.open();
        }
        this.$refs.spinner.showSpinner();
        const requestConfig = {};
        self.commonService.getMarketingModalPage(requestConfig, this.handleMarketingModalPageResponse, this.handleMarketingModalPageError);
        self.createCookie(
          self.cookies.marketingModal,
          this.globals.siteConfig.cookieExpirationTime,
        );
      } else {
        this.$refs.spinner.hideSpinner();
      }
    },
    /**
     * This function handles the response of marketing modal service call
     */
    handleMarketingModalPageResponse(response) {
      this.$refs.spinner.hideSpinner();
      this.marketingModalPage = response.data;
    },
    /**
     * This function handles the error of marketing modal service call
     */
    handleMarketingModalPageError() {
      this.$refs.spinner.hideSpinner();
    },
  },
};
