var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-guest-checkout mt-xs-3 mt-sm-0" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "my-xs-0 guest-heading" }, [
        _vm._v("\n        " + _vm._s(_vm.i18n.heading) + "\n    ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mt-xs-4 mb-xs-0 description" }, [
        _vm._v("\n        " + _vm._s(_vm.i18n.description) + "\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "my-xs-4 form-group" }, [
        _c("label", { attrs: { for: "us", "aria-hidden": "true" } }, [
          _vm._v(
            "\n            " + _vm._s(_vm.i18n.emailBtnLabel) + "\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            ref: "guestForm",
            attrs: { action: "login/checkout/guest", method: "post" }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.guestEmail,
                  expression: "guestEmail"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email|max:80",
                  expression: "'required|email|max:80'"
                }
              ],
              staticClass: "form-control",
              class: { "input-error": _vm.errors.has("email") },
              attrs: {
                type: "text",
                maxlength: "80",
                disabled: _vm.disableField,
                name: "email",
                "aria-label": _vm.i18n.emailBtnLabel
              },
              domProps: { value: _vm.guestEmail },
              on: {
                focus: _vm.hideServerError,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.guestEmail = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("email")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", { staticClass: "error-msg" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("email")) +
                        "\n                "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                name: "CSRFToken",
                "aria-hidden": "true"
              },
              domProps: { value: _vm.globals.csrfToken }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn-block btn btn-tertiary form-blue-button",
          attrs: {
            type: "submit",
            disabled: _vm.errors.any() || _vm.disableField
          },
          on: {
            click: function($event) {
              _vm.handleGuestCheckoutSubmit($event)
            }
          }
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.i18n.buttonText.toUpperCase()) + "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "my-xs-4 description support-help" }, [
        _vm._v("\n        " + _vm._s(_vm.i18n.supportHelp) + "\n        "),
        _c("a", { attrs: { href: "#" } }, [
          _vm._v(
            "\n            " + _vm._s(_vm.i18n.supportPhone) + "\n        "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-77559659", { render: render, staticRenderFns: staticRenderFns })
  }
}