var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "juicer-page" },
    [_c("h1", [_vm._v(_vm._s(_vm.msg))]), _vm._v(" "), _c("vx-juicer-feed")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-620cb082", { render: render, staticRenderFns: staticRenderFns })
  }
}