import globals from '../../common/globals';

export default {
  name: 'vx-guest-create-account',
  components: {},
  props: {
    // Text coming from property file
    i18n: Object,
  },
  data() {
    return {
      globals,
    };
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },
};
