import vxDropdownPrimary from '../../../../components/common/vx-dropdown-primary/vx-dropdown-primary.vue';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import globals from '../../../../components/common/globals';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxPermissionsForm from '../vx-permissions-form/vx-permissions-form.vue';
import ManageB2bOrgService from '../../../../components/common/services/manage-b2b-org-service';
import vxTable from '../../../../components/common/vx-table/vx-table.vue';
import vxTableUtilsMixin from '../../../../components/common/mixins/vxTable-mixin';

export default {
  name: 'vx-permisions-landing-page',
  components: {
    vxDropdownPrimary,
    vxSpinner,
    vxPermissionsForm,
    vxModal,
    vxTable,
  },
  props: {
    i18n: {
      type: Object,
      required: true,
    },
  },
  mixins: [mobileMixin, vxTableUtilsMixin],
  data() {
    return {
      globals,
      tableHeadings: [],
      sortOptions: [],
      MobileSortOptions: this.i18n.mobileSortOptions,
      tableConfig: {},
      permissions: {
        permissions: [],
      },
    };
  },
  computed: {},
  mounted() {
    this.$refs.spinner.showSpinner();
    this.fetchPermissinons();
    this.initializeTableConfig();
  },
  methods: {
    generateSortOptions(data) {
      data.forEach((val, ind) => {
        const sortObj = {};
        sortObj.label = val;
        sortObj.value = ind;
        this.sortOptions.push(sortObj);
      });
    },
    /**
     * fetchPermissinons makes a service getPermissions call to fetch the permissions.
     */
    fetchPermissinons() {
      const manageB2bOrgService = new ManageB2bOrgService();
      manageB2bOrgService.getPermissions(
        {},
        this.getPermissionsResponse,
        this.getPermissionsResponseError,
      );
    },

    /**
     * Handles the success of getPermissions.
     * @param {Object} response contains the permissions
     */
    getPermissionsResponse(response) {
      this.$refs.spinner.hideSpinner();
      const { status, data } = response;
      if (status) {
        this.permissions = data;
      } else {
        this.handleErrorCallback(data);
      }
    },
    handleErrorCallback() {},
    /**
     * Opens permission modal
     */
    openPermissionsModal(event) {
      this.$refs.permissionModal.open(event);
    },
    /**
     * closes permission modal
     */
    closePermissionsModal() {
      this.$refs.permissionModal.close();
    },
    /**
     * Shows spinner
     */
    showLoadingSpinner() {
      this.$refs.spinner.showSpinner();
    },
    /**
     * Hides spinner
     */
    hideLoadingSpinner() {
      this.$refs.spinner.hideSpinner();
    },
    initializeTableConfig() {
      const tableHeadings = this.i18n.permissionColumns.split(',');
      this.tableConfig = {
        rowCount: 10,
        emptyTable: this.i18n.noPermissions,
        columnConfiguration: [
          {
            customHeaderName: tableHeadings[0],
            customTemplate: (data) => `<a href="${this.globals.getB2BBaseURL()}${this.globals.navigations.permissionLanding}?pDetail=${encodeURIComponent(data.code)}">${data.code}</a>`,
          },
          {
            customHeaderName: tableHeadings[1],
            field: 'b2BPermissionTypeData.name',
          },
          {
            customHeaderName: tableHeadings[2],
            field: 'unit.name',
          },
          {
            customHeaderName: tableHeadings[3],
            customTemplate: (data) => (data.timeSpan ? data.timeSpan : ''),
          },
          {
            customHeaderName: tableHeadings[4],
            customTemplate: (data) => `${data.value} ${data.currency.isocode}`,
          },
          {
            customHeaderName: tableHeadings[5],
            customTemplate: (data) => `${data.active ? this.i18n.enabled : this.i18n.disabled}`,
          },
        ],
      };
    },
  },
};
