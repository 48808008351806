import vxAutoSuggestToList from '../../../../components/search-browse/vx-auto-suggest-to-list/vx-auto-suggest-to-list';

export default {
  name: 'vx-add-product-to-list',
  components: {
    vxAutoSuggestToList,
  },
  props: {
    /**
     * Labels, button and caption texts
     */
    i18n: {
      type: Object,
    },
  },
  data() {
    return {
      productNames: '',
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  methods: {
    /**
     * This function emits an event and data to add products to the list ,it gets handled in vx-list-details.
     */
    handleAddProductsToList() {
      this.emitAddProductsToList(this.productNames);
    },

    handleFoundProductsToList(productCodes) {
      this.emitAddProductsToList(productCodes);
    },

    emitAddProductsToList(productCodes) {
      this.$emit('addProductsToList', productCodes);
      this.closeAddProductToList();
    },
    /**
     * This function emits an event to close the popup. It gets handled in vx-list-details.
     */
    closeAddProductToList() {
      this.$emit('closeAddProductToList');
    },
  },
};
