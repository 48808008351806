var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-profile-preferences pt-xs-4 pt-sm-5" },
    [
      _c("vx-spinner", {
        ref: "profileSpinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "editPersonalDetailsModal",
          attrs: { size: "small", heading: "Edit Personal Details" }
        },
        [
          _c("vx-edit-personal-details", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n.editPersonalDetails,
              userDetails: _vm.userDetails,
              "is-gender-enabled": _vm.isGenderEnabled,
              "is-dob-enabled": _vm.isDobEnabled
            },
            on: {
              "updated-personal-details": function($event) {
                _vm.updatedPersonalDetailSuccess($event)
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "updatePasswordModal",
          attrs: { size: "small", heading: "Update Password" }
        },
        [
          _c("vx-change-password", {
            attrs: { slot: "component", i18n: _vm.i18n.updatePassword },
            on: {
              "update-password-success": function($event) {
                _vm.updatePasswordSuccess($event)
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "communicationPreferencesModal",
          attrs: { size: "medium", heading: "Edit Communication Preferences" }
        },
        [
          _c("vx-communication-preferences", {
            attrs: {
              slot: "component",
              userDetails: _vm.userDetails,
              i18n: _vm.i18n.communicationPreferences
            },
            on: {
              "update-preferences-success": function($event) {
                _vm.updatePreferencesSuccess($event)
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "taxExemptionModal",
          attrs: { size: "extra-small", heading: _vm.i18n.taxExemption.heading }
        },
        [
          _c("vx-tax-exemption", {
            attrs: { slot: "component", i18n: _vm.i18n.taxExemption },
            on: {
              "update-tax-exemption-failure": _vm.updatedTaxExemptionFailure,
              "update-tax-exemption-success": function($event) {
                _vm.updatedTaxExemptionSuccess($event)
              },
              "show-spinner": _vm.showSpinner
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 profile-cards-container" }, [
        _c("div", { staticClass: "row d-flex" }, [
          _c("h1", { staticClass: "my-xs-0 container-heading" }, [
            _vm._v(_vm._s(_vm.i18n.pageTitle))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row d-flex py-xs-2 py-sm-3" }, [
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-6 my-xs-2 mb-sm-3 mt-sm-0" },
            [
              _c(
                "vx-profile-card",
                {
                  attrs: { "is-default-height": true, cardClasses: "py-xs-3" }
                },
                [
                  _c("template", { slot: "heading" }, [
                    _c("li", { staticClass: "card-heading" }, [
                      _c("h4", [_vm._v(_vm._s(_vm.i18n.txtPersonalDetails))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "details" }, [
                    _c("li", { staticClass: "card-item" }, [
                      _vm._v(_vm._s(_vm.userDetails.name))
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "card-item" }, [
                      _vm._v(_vm._s(_vm.userDetails.uid))
                    ]),
                    _vm._v(" "),
                    _vm.userDetails.cellPhone
                      ? _c("li", { staticClass: "card-item" }, [
                          _vm._v(_vm._s(_vm.userDetails.cellPhone))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isGenderEnabled && _vm.userDetails.gender
                      ? _c("li", { staticClass: "card-item" }, [
                          _vm._v(_vm._s(_vm.genderDetails))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isDobEnabled && _vm.userDetails.dateOfBirth
                      ? _c("li", { staticClass: "card-item" }, [
                          _vm._v(
                            _vm._s(_vm.i18n.birthDateLabel) +
                              "\n              " +
                              _vm._s(_vm.userDetails.monthName) +
                              " " +
                              _vm._s(this.dateDisplay) +
                              _vm._s(_vm.monthSuffix)
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "icons" }, [
                    _c("li", [
                      _c("span", {
                        staticClass: "icon-default icon-big icon-edit",
                        attrs: {
                          "aria-label": _vm.i18n.iconEditTitle,
                          tabindex: "0",
                          role: "button"
                        },
                        on: {
                          click: function($event) {
                            _vm.callEditPersonalDetailsModal($event)
                          },
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter-space",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            _vm.callEditPersonalDetailsModal($event)
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "footer" }, [
                    _vm._v("\n            " + _vm._s("") + "\n\n          ")
                  ])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.globals.siteConfig.editPasswordEnabled
            ? _c(
                "div",
                { staticClass: "col-xs-12 col-sm-6 my-xs-2 mb-sm-3 mt-sm-0" },
                [
                  _c(
                    "vx-profile-card",
                    {
                      attrs: {
                        "is-default-height": true,
                        cardClasses: "py-xs-3"
                      }
                    },
                    [
                      _c("template", { slot: "heading" }, [
                        _c("li", { staticClass: "card-heading" }, [
                          _c("h4", [
                            _vm._v(_vm._s(_vm.i18n.updatePassword.password))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "details" }, [
                        _c("li", { staticClass: "card-item-password" }, [
                          _c("span", { staticClass: "dot" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dot" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dot" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dot" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dot" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dot" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dot" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dot" })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "icons" }, [
                        _c("li", [
                          _c("span", {
                            staticClass: "icon-default icon-big icon-edit",
                            attrs: {
                              "aria-label": _vm.i18n.iconEditTitle,
                              tabindex: "0",
                              role: "button"
                            },
                            on: {
                              click: function($event) {
                                _vm.callUpdatePasswordModal($event)
                              },
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter-space",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                _vm.callUpdatePasswordModal($event)
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "footer" }, [
                        _vm._v("\n            " + _vm._s("") + "\n\n          ")
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.siteConfig.editCommPrefEnabled && !_vm.globals.isB2C()
            ? _c(
                "div",
                { staticClass: "col-xs-12 my-xs-2 mb-sm-3 mt-sm-0 col-sm-6" },
                [
                  _c(
                    "vx-profile-card",
                    {
                      attrs: {
                        "is-default-height": true,
                        cardClasses: "py-xs-3"
                      }
                    },
                    [
                      _c("template", { slot: "heading" }, [
                        _c("li", { staticClass: "card-heading" }, [
                          _c("h4", [
                            _vm._v(
                              _vm._s(_vm.i18n.communicationPreferences.title)
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.globals.isB2C()
                        ? _c("template", { slot: "details" }, [
                            _vm.userDetails.addToMarketComm
                              ? _c("li", { staticClass: "card-item" }, [
                                  _vm._v(_vm._s(_vm.i18n.marketingChoices))
                                ])
                              : _c("li", { staticClass: "card-item" }, [
                                  _vm._v(_vm._s(_vm.i18n.marketingChoicesFalse))
                                ])
                          ])
                        : _c("template", { slot: "details" }, [
                            _c("li", { staticClass: "card-item" }, [
                              _vm._v(
                                _vm._s(_vm.communicationPreferencesMessage)
                              )
                            ])
                          ]),
                      _vm._v(" "),
                      _c("template", { slot: "icons" }, [
                        _c("li", [
                          _c("span", {
                            staticClass: "icon-default icon-big icon-edit",
                            attrs: {
                              "aria-label": _vm.i18n.iconEditTitle,
                              tabindex: "0",
                              role: "button"
                            },
                            on: {
                              click: function($event) {
                                _vm.callCommunicationPreferencesModal($event)
                              },
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter-space",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                _vm.callCommunicationPreferencesModal($event)
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "footer" }, [
                        _vm._v("\n            " + _vm._s("") + "\n\n          ")
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.siteConfig.hasTaxExemption && _vm.showTaxExemption
            ? _c(
                "div",
                { staticClass: "col-xs-12 my-xs-2 mb-sm-3 mt-sm-0 col-sm-6" },
                [
                  _c(
                    "vx-profile-card",
                    {
                      attrs: {
                        "is-default-height": true,
                        cardClasses: "py-xs-3"
                      }
                    },
                    [
                      _c("template", { slot: "heading" }, [
                        _c("li", { staticClass: "card-heading" }, [
                          _c("h4", [
                            _vm._v(_vm._s(_vm.i18n.taxExemption.titleStatus))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "details" }, [
                        _c(
                          "li",
                          {
                            staticClass: "card-item-link link-small link-bold"
                          },
                          [
                            _vm._v(
                              "\n              Tax Exemption is " +
                                _vm._s(_vm.taxExemptionStatus) +
                                "\n            "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "icons" }, [
                        _c("li", [
                          _c("span", {
                            staticClass: "icon-default icon-big icon-edit",
                            attrs: {
                              "aria-label": _vm.i18n.iconEditTitle,
                              tabindex: "0",
                              role: "button"
                            },
                            on: {
                              click: function($event) {
                                _vm.callTaxExemptionModal($event)
                              },
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter-space",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                _vm.callTaxExemptionModal($event)
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "footer" }, [
                        _vm._v("\n            " + _vm._s("") + "\n\n          ")
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.userDetails.unit && _vm.showTaxExemption
            ? _c(
                "div",
                { staticClass: "col-xs-12 my-xs-2 mb-sm-3 mt-sm-0 col-sm-6" },
                [
                  _c(
                    "vx-profile-card",
                    {
                      attrs: {
                        "card-type": "column",
                        cardClasses: "py-xs-3",
                        "has-icons": false,
                        "is-default-height": true,
                        "has-footer": false
                      }
                    },
                    [
                      _c("template", { slot: "heading" }, [
                        _c("li", { staticClass: "card-heading" }, [
                          _c("h4", [
                            _vm._v(_vm._s(_vm.i18n.parentBusinessUnit.title))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.userDetails.unit
                        ? _c("template", { slot: "details" }, [
                            _c("ul", { staticClass: "col-xs-6" }, [
                              _vm.userDetails.unit.name
                                ? _c("li", { staticClass: "card-item" }, [
                                    _vm._v(_vm._s(_vm.userDetails.unit.name))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("li", { staticClass: "card-item" }, [
                                _vm._v(_vm._s(_vm.userRole))
                              ]),
                              _vm._v(" "),
                              _vm.userDetails.unit.addresses
                                ? _c("li", { staticClass: "card-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.userDetails.unit.addresses[0].phone
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm.userDetails.unit.addresses
                              ? _c("ul", { staticClass: "col-xs-6" }, [
                                  _vm.userDetails.unit.addresses[0].country
                                    ? _c("li", { staticClass: "card-item" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.userDetails.unit.addresses[0]
                                              .country.name
                                          )
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "card-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.userDetails.unit.addresses[0].line1
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "card-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.userDetails.unit.addresses[0].line2
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "card-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.userDetails.unit.addresses[0].town
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.userDetails.unit.addresses[0].region
                                    ? _c("li", { staticClass: "card-item" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.userDetails.unit.addresses[0]
                                              .region.name
                                          )
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "card-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.userDetails.unit.addresses[0]
                                          .postalCode
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("template", { slot: "footer" }, [
                        _vm._v("\n            " + _vm._s("") + "\n          ")
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0d86991c", { render: render, staticRenderFns: staticRenderFns })
  }
}