var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "view-site-content-vx-text-tile d-flex justify-content-center py-xs-3 px-xs-3",
      class: _vm.alignmentClass,
      style: [_vm.backgroundStyle, _vm.textWidth],
      attrs: {
        "aria-label": _vm.textTileData.backgroundImageD ? _vm.backgroundAlt : ""
      }
    },
    [
      _c("div", { staticClass: "w-xs-100" }, [
        _vm.textTileData.header
          ? _c("h3", {
              staticClass: "my-xs-0",
              style: [_vm.fontStyleHeader],
              domProps: { innerHTML: _vm._s(_vm.textTileData.header) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.textTileData.text
          ? _c("p", {
              staticClass: "mb-xs-3",
              class: [_vm.textTileData.header ? "mt-xs-3 mt-sm-6" : ""],
              style: [_vm.fontStyleText],
              domProps: { innerHTML: _vm._s(_vm.textTileData.text) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.textTileData.ctaText &&
        _vm.textTileData.ctaLink &&
        !_vm.textTileData.isVideoCta &&
        !_vm.textTileData.jumpToHtml
          ? _c(
              "a",
              {
                staticClass: "mb-xs-0 cta-text",
                class: [
                  _vm.textTileData.header || _vm.textTileData.text
                    ? "mt-xs-3 mt-sm-5"
                    : ""
                ],
                style: [_vm.fontStyleCta],
                attrs: {
                  href: _vm.textTileData.isExternalLink
                    ? _vm.textTileData.ctaLink
                    : _vm.globals.getUrlWithContextPath(
                        _vm.textTileData.ctaLink
                      ),
                  target: _vm.textTileData.isExternalLink ? "_blank" : ""
                }
              },
              [
                _c("button", {
                  ref: "button",
                  staticClass: "form-blue-button",
                  class: _vm.textTileData.ctaStyle,
                  attrs: {
                    "aria-label":
                      _vm.textTileData.header + " " + _vm.textTileData.ctaText,
                    role: "presentation",
                    tabindex: "-1"
                  },
                  domProps: { innerHTML: _vm._s(_vm.textTileData.ctaText) }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.textTileData.ctaText &&
        _vm.textTileData.ctaLink &&
        !_vm.textTileData.isVideoCta &&
        _vm.textTileData.jumpToHtml
          ? _c(
              "a",
              {
                staticClass: "mb-xs-0 cta-text",
                class: [
                  _vm.textTileData.header || _vm.textTileData.text
                    ? "mt-xs-3 mt-sm-5"
                    : ""
                ],
                style: [_vm.fontStyleCta],
                attrs: {
                  href: _vm.textTileData.isExternalLink
                    ? _vm.textTileData.ctaLink
                    : _vm.globals.getUrlWithContextPath(
                        _vm.textTileData.ctaLink
                      )
                },
                on: {
                  click: function($event) {
                    _vm.emitScrollSignal(_vm.textTileData.ctaLink)
                  }
                }
              },
              [
                _c("button", {
                  ref: "button",
                  staticClass: "form-blue-button",
                  class: _vm.textTileData.ctaStyle,
                  attrs: {
                    "aria-label":
                      _vm.textTileData.header + " " + _vm.textTileData.ctaText,
                    role: "presentation",
                    tabindex: "-1"
                  },
                  domProps: { innerHTML: _vm._s(_vm.textTileData.ctaText) }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.textTileData.ctaText &&
        _vm.textTileData.ctaLink &&
        _vm.textTileData.isVideoCta
          ? _c(
              "a",
              {
                staticClass: "mb-xs-0 cta-text",
                class: [
                  _vm.textTileData.header || _vm.textTileData.text
                    ? "mt-xs-3 mt-sm-5"
                    : ""
                ],
                style: [_vm.fontStyleCta],
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.openVideo(_vm.textTileData.ctaLink)
                  }
                }
              },
              [
                _c("button", {
                  ref: "button",
                  staticClass: "form-blue-button",
                  class: _vm.textTileData.ctaStyle,
                  attrs: {
                    "aria-label":
                      _vm.textTileData.header + " " + _vm.textTileData.ctaText,
                    role: "presentation",
                    tabindex: "-1"
                  },
                  domProps: { innerHTML: _vm._s(_vm.textTileData.ctaText) }
                })
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2e75af46", { render: render, staticRenderFns: staticRenderFns })
  }
}