var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "vx-cta-button",
      class: _vm.componentTheme,
      attrs: { href: _vm.ctaButtonData.ctaLink }
    },
    [
      _c(
        "button",
        {
          ref: "button",
          staticClass: "form-blue-button",
          class: this.ctaButtonData.ctaStyle
        },
        [_vm._v("\n        " + _vm._s(_vm.ctaButtonData.ctaText) + "\n    ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6f4afcb4", { render: render, staticRenderFns: staticRenderFns })
  }
}