var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-permissions-details" },
    [
      _vm.isMounted
        ? [
            _c("vx-spinner", {
              ref: "spinner",
              attrs: {
                image: _vm.globals.assetsPath + "images/spinner.gif",
                "full-screen": ""
              }
            }),
            _vm._v(" "),
            _c("h3", { staticClass: "mt-xs-5 mb-xs-0" }, [
              _vm._v(_vm._s(_vm.i18n.permissionsDetailsHeading))
            ]),
            _vm._v(" "),
            _c("vx-details-section", {
              attrs: {
                "current-page": "permissions",
                "i18n-user-status": _vm.i18n.userStatus,
                "edit-button": true,
                i18n: _vm.i18n,
                "details-data": _vm.detailPageData.detailsData,
                "disable-button": true
              },
              on: {
                edit: function($event) {
                  _vm.editPermissions($event)
                },
                disable: function($event) {
                  _vm.openDisableModal($event)
                },
                enable: _vm.enablePermissions
              }
            }),
            _vm._v(" "),
            _c(
              "vx-modal",
              {
                ref: "editPermissionModal",
                attrs: { size: "large", heading: _vm.i18n.editHeading }
              },
              [
                _c("vx-permissions-form", {
                  attrs: {
                    slot: "component",
                    type: "modal",
                    "is-edit": true,
                    i18n: _vm.i18n,
                    "form-data": _vm.detailPageData.detailsData,
                    "time-period-values": _vm.i18n.timeSpanLabels
                  },
                  on: {
                    submitForm: _vm.submitForm,
                    fetchPDetails: _vm.fetchPermissionDetails,
                    showSpinner: _vm.showLoadingSpinner,
                    hideSpinner: _vm.hideLoadingSpinner,
                    closePermissionsForm: _vm.hidePermissionsForm,
                    setPCode: function($event) {
                      _vm.updatePermissionCode($event)
                    }
                  },
                  slot: "component"
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "vx-modal",
              {
                ref: "disableUnitModal",
                attrs: {
                  heading: _vm.i18n.disableUnitModalHeading,
                  size: "extra-small"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "disable-permission-modal d-flex flex-column",
                    attrs: { slot: "component" },
                    slot: "component"
                  },
                  [
                    _c("p", { staticClass: "warning-text mb-xs-0 pb-xs-5" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.i18n.disableUnitModalMessage) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "button",
                        {
                          staticClass: "modal-dual-btn form-blue-button",
                          on: { click: _vm.sendDisableRequest }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.i18n.disableUnitModalDisableText) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4b82be06", { render: render, staticRenderFns: staticRenderFns })
  }
}