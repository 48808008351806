var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-info-section pt-xs-3 pb-xs-5" }, [
    _c(
      "div",
      { staticClass: "vx-info-section__header d-flex justify-content-between" },
      [
        _vm.childUnits !== 0
          ? _c(
              "h3",
              { staticClass: "vx-info-section__header-title my-xs-0 py-xs-3" },
              [
                _vm._v(
                  _vm._s(_vm.i18n.groupLabel) + " - " + _vm._s(_vm.childUnits)
                )
              ]
            )
          : _c(
              "h3",
              { staticClass: "vx-info-section__header-title my-xs-0 py-xs-3" },
              [_vm._v(_vm._s(_vm.i18n.groupLabel))]
            ),
        _vm._v(" "),
        _vm.existingButton && _vm.newButton
          ? _c(
              "div",
              {
                staticClass: "vx-info-section__header-buttons align-self-center"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "existing-button px-xs-2",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.addExisting(_vm.i18n.groupLabel)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.i18n.addText) +
                        " " +
                        _vm._s(_vm.i18n.existingText)
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "new-button pl-xs-2",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.addNew(_vm.i18n.groupLabel)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.newText))]
                )
              ]
            )
          : !_vm.existingButton && _vm.newButton
          ? _c(
              "div",
              {
                staticClass: "vx-info-section__header-buttons align-self-center"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "new-button pl-xs-2",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.addNew(_vm.i18n.groupLabel)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.i18n.addText) + " " + _vm._s(_vm.i18n.newText)
                    )
                  ]
                )
              ]
            )
          : _vm.existingButton
          ? _c(
              "div",
              {
                staticClass: "vx-info-section__header-buttons align-self-center"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "existing-button px-xs-2",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.addExisting(_vm.i18n.groupLabel)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.i18n.addText) +
                        " " +
                        _vm._s(_vm.i18n.existingText)
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "vx-info-section__body d-flex flex-wrap" },
      _vm._l(_vm.displayInfoData, function(item) {
        return _c(
          "div",
          { staticClass: "vx-info-section__info-card" },
          [
            _c(
              "vx-profile-card",
              {
                staticClass: "mx-xs-3 mb-xs-5",
                attrs: {
                  hasIcons: _vm.iconClass !== undefined && _vm.iconClass !== "",
                  cardClasses: "py-xs-4",
                  "has-footer": false,
                  isDisabled: item.status !== undefined ? !item.status : false,
                  showTrash: true
                }
              },
              [
                _c("template", { slot: "heading" }, [
                  _c(
                    "li",
                    {
                      staticClass:
                        "card-heading-link d-flex justify-content-between word-wrap-all"
                    },
                    [
                      _c("a", { attrs: { href: "" + item.link } }, [
                        _c("h4", [_vm._v(_vm._s(item.label))])
                      ]),
                      _vm._v(" "),
                      (item.status !== undefined
                      ? !item.status
                      : false)
                        ? _c("span", { staticClass: "disabled-text" }, [
                            _vm._v(_vm._s(_vm.i18n.disabledText))
                          ])
                        : _vm._e()
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("template", { slot: "details" }, [
                  _c("li", {
                    staticClass: "card-item word-wrap-all",
                    domProps: { innerHTML: _vm._s(item.info) }
                  })
                ]),
                _vm._v(" "),
                item.units
                  ? _c("template", { slot: "icons" }, [
                      _vm.iconClass !== undefined &&
                      _vm.iconClass !== "" &&
                      item.units > 1
                        ? _c("li", { staticClass: "card-icon" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "javascript:void(0)",
                                  "aria-label": _vm.i18n.iconDeleteTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.handleDelete(
                                      _vm.i18n.groupLabel,
                                      item.label,
                                      item
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "icon-default icon-big icon-trash",
                                  attrs: { title: _vm.i18n.iconDeleteTitle }
                                })
                              ]
                            )
                          ])
                        : _c("li", [_vm._v("​")])
                    ])
                  : _c("template", { slot: "icons" }, [
                      _vm.iconClass !== undefined &&
                      _vm.iconClass !== "" &&
                      _vm.deleteAll
                        ? _c("li", { staticClass: "card-icon" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "javascript:void(0)",
                                  "aria-label": _vm.i18n.iconDeleteTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.handleDelete(
                                      _vm.i18n.groupLabel,
                                      item.label,
                                      item
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "icon-default icon-big icon-trash",
                                  attrs: { title: _vm.i18n.iconDeleteTitle }
                                })
                              ]
                            )
                          ])
                        : _c("li", [_vm._v("​")])
                    ]),
                _vm._v(" "),
                _c("template", { slot: "footer" }, [
                  _vm._v("\n          ​\n        ")
                ])
              ],
              2
            )
          ],
          1
        )
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-663b97d5", { render: render, staticRenderFns: staticRenderFns })
  }
}