var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("B2BOrg")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [_c("vx-user-permissions-table")],
        1
      ),
      _vm._v(" "),
      _c("vx-business-unit-detail", {
        staticClass: "col-xs-12",
        attrs: { i18n: _vm.messages.manageB2BOrg.businessUnit }
      }),
      _vm._v(" "),
      _c("vx-business-unit-landing", {
        staticClass: "col-xs-12",
        attrs: { i18n: _vm.messages.manageB2BOrg.businessUnitLanding }
      }),
      _vm._v(" "),
      _c("vx-users-landing", {
        attrs: { i18n: _vm.messages.manageB2BOrg.usersLanding }
      }),
      _vm._v(" "),
      _c("vx-user-details", {
        attrs: { i18n: _vm.messages.manageB2BOrg.userDetails }
      }),
      _vm._v(" "),
      _c("vx-user-groups-landing", {
        staticClass: "col-xs-12",
        attrs: { i18n: _vm.messages.manageB2BOrg.userGroupLanding }
      }),
      _vm._v(" "),
      _c("vx-user-group-details", {
        staticClass: "col-xs-12",
        attrs: { i18n: _vm.messages.manageB2BOrg.userGroupDetails }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d2feff14", { render: render, staticRenderFns: staticRenderFns })
  }
}