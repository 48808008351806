/**
 * This component is the list page view on search results page
 */

import vxProductTileVertical from '../vx-product-tile-vertical/vx-product-tile-vertical.vue';
import vxAddToComparePanel from '../vx-add-to-compare-panel/vx-add-to-compare-panel.vue';
import { eventBus } from '../../../../modules/event-bus';
import cookiesMixin from '../../../../components/common/mixins/cookies-mixin';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-list-page',
  data() {
    return {
      compareProduct: [],
      disableButtonFalse: false,
      totalCheckedCount: 0,
      uncheckProduct: false,
      receivedCount: 0,
      assetLevel: '',
      showCompareCheckbox: true,
      viewportCount: 4,
      viewportSize: 0,
    };
  },
  props: {
    /**
         * product catalog for respective categories
         */
    products: {
      type: Array,
    },
    /**
         * indicates whether the site is configured for favorites
         */
    isFavorites: {
      type: Boolean,
      default: false,
    },
    /**
         * indicates whether the site is configured for Bazaar Voice
         */
    isBazaarVoice: {
      type: String,
      default: '',
    },
    /**
         * Copy text coming from properties files for search browse components
         */
    searchBrowseI18n: {
      type: Object,
    },
    /**
         * indicates whether the site is configured for single product view
         */
    singleProductEnabled: {
      type: Boolean,
      default: false,
    },
    /**
         * indicates whether the site is configured for bulk
         */
    isBulkEnabled: {
      type: Boolean,
      default: false,
    },
    goToPage: {
      type: Function,
    },
    searchDataPagination: {
      type: Object,
    },
    currentPageNumber: {
      type: Number,
    },
    listView: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [cookiesMixin, mobileMixin],
  components: {
    vxProductTileVertical,
    'vx-panel-view': vxAddToComparePanel,
  },
  created() {
    this.viewportSize = window.innerWidth;
    this.getViewportSize(this.viewportSize);
  },
  watch: {
    products() {
      this.$nextTick(() => {
        eventBus.$emit('editedCompare', this.compareProduct);
      });
    },
  },
  methods: {
    getViewportSize(e) {
      if (e < 768) {
        this.viewportCount = 2;
      }
    },
    /**
         * This function is to add products to be compared
         */
    addToCompare(event) {
      this.updateTotalCount();
      // Check for first product for comparison
      if (this.totalCheckedCount === 1 && event.assetCode) {
        this.assetLevel = event.assetCode;
        this.checkboxClicked(event);
      }
      // Check for comparison conditions if panel has one or more product
      if (this.totalCheckedCount > 1 && this.totalCheckedCount <= this.viewportCount && event.assetCode) {
        if (this.assetLevel === event.assetCode) {
          this.checkboxClicked(event);
        } else {
          this.uncheckProduct = true;
        }
      }
      // commented code for future purpose
      // else if (this.receivedCount > 0) {
      //   this.uncheckProduct = true;
      // }
    },
    /**
         * This function is called on click of checkbox
         */
    checkboxClicked() {
      this.uncheckProduct = false;
      this.updateCompareProductData();
      this.toggleCompareButton();
      eventBus.$emit('editedCompare', this.compareProduct);
    },
    /**
         * This function is to disable view details button if compared products is less than 2
         */
    toggleCompareButton() {
      if (this.compareProduct.length < 2) {
        this.disableButtonFalse = true;
      } else {
        this.disableButtonFalse = false;
      }
    },
    /**
         * This function is to remove product from compare panel
         */
    removeFromCompare() {
      this.updateCompareProductData();
      this.toggleCompareButton();
      this.updateTotalCount();
      eventBus.$emit('editedCompare', this.compareProduct);
    },
    /**
         * This function is to update panel count on removal of product from campare panel
         */
    updateTotalCountPanel() {
      this.totalCheckedCount -= 1;
      eventBus.$emit('editedCompare', this.compareProduct);
    },
    /**
         * This function is to update total count of selected products
         */
    updateTotalCount() {
      const cookieData = this.getCompareCookieData();
      if (cookieData) {
        this.totalCheckedCount = cookieData.length;
      } else {
        this.totalCheckedCount = 0;
      }
    },
    /**
         * This function is to update counter
         */
    updateCounter(count) {
      this.receivedCount = +count;
    },
    /**
         * This function is to get compare cookie data
         */
    getCompareCookieData() {
      let cookieData = this.readCookie('CompareCookie');
      if (cookieData) {
        cookieData = JSON.parse(cookieData);
      } else {
        cookieData = null;
      }
      return cookieData;
    },
    /**
         * This function is to update compare product data
         */
    updateCompareProductData() {
      const self = this;
      if (!this.isBulkEnabled) {
        const cookieData = this.getCompareCookieData();
        if (cookieData) {
          self.compareProduct = cookieData;
        } else {
          self.compareProduct = [];
        }
      }
    },
    /**
         * This function is to set asset code from compare cookie
         */
    setAssetCode() {
      const cookieData = this.getCompareCookieData();
      if (cookieData && cookieData.length !== 0) {
        this.assetLevel = cookieData[0].assetCode;
      } else {
        this.assetLevel = null;
      }
    },
  },
  mounted() {
    const self = this;
    self.updateCompareProductData();
    self.setAssetCode();
  },
  updated() {
    eventBus.$on('productLength', (data) => { this.compareProduct.length = data; });
    this.toggleCompareButton();
  },
};
