var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registration-and-login-sign-in" },
    [
      _vm.globals.isEStores()
        ? _c("div", { staticClass: "estores-help-text" }, [
            _vm._v("\n    " + _vm._s(_vm.i18n.estoresHelpText) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "forgotPasswordModal",
          attrs: { heading: _vm.i18n.passwordModalHeading }
        },
        [
          _c("vx-forgot-password", {
            attrs: { slot: "component", i18n: _vm.i18n },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.globals.isB2C() &&
      _vm.globals.getCookie("flow") !== "checkout" &&
        !_vm.globals.getIsSubscription()
        ? _c("h1", { staticClass: "b2c-heading mx-xs-0 my-xs-0" }, [
            _vm._v("\n    " + _vm._s(_vm.i18n.headingforB2c))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.globals.isB2C() &&
      (_vm.globals.getCookie("flow") === "checkout" ||
        _vm.globals.getIsSubscription())
        ? _c("h1", { staticClass: "b2c-guest-heading mx-xs-0 my-xs-0" }, [
            _vm._v("\n    " + _vm._s(_vm.i18n.headingForReturningCustomer))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.globals.isB2B() &&
      _vm.globals.getCookie("flow") !== "checkout" &&
        !_vm.globals.getIsSubscription()
        ? _c("h1", { staticClass: "b2b-heading mx-xs-0 my-xs-0" }, [
            !_vm.globals.isEStores()
              ? _c("span", { staticClass: "headingPart1" }, [
                  _vm._v(_vm._s(_vm.i18n.headingforB2bPart1))
                ])
              : _c("span", [_vm._v(_vm._s(_vm.i18n.headingforEStore))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.globals.isB2B() &&
      (_vm.globals.getCookie("flow") === "checkout" ||
        _vm.globals.getIsSubscription())
        ? _c("h1", { staticClass: "b2b-heading mx-xs-0 my-xs-0" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.i18n.headingForReturningCustomer) + "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        { ref: "loginForm", attrs: { action: _vm.actionUrl, method: "post" } },
        [
          _c("div", { staticClass: "my-xs-4 form-group" }, [
            _c("label", { attrs: { "aria-hidden": _vm.isDeviceAndroid() } }, [
              _vm._v(_vm._s(_vm.i18n.email))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.email,
                  expression: "user.email"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email|max:80",
                  expression: "'required|email|max:80'"
                }
              ],
              staticClass: "form-control",
              class: [{ "input-error": _vm.errors.has("j_username") }],
              attrs: {
                type: "text",
                maxlength: "80",
                disabled: _vm.disableField,
                name: "j_username",
                "aria-label": _vm.i18n.email,
                "aria-describedby": "username_error_msg"
              },
              domProps: { value: _vm.user.email },
              on: {
                focus: _vm.hideServerError,
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "email", $event.target.value)
                  },
                  function($event) {
                    _vm.user.email = $event.target.value
                  }
                ]
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("j_username"),
                    expression: "errors.has('j_username')"
                  }
                ],
                staticClass: "mt-xs-2",
                attrs: { "aria-hidden": !_vm.errors.has("j_username") }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "error-msg",
                    attrs: { id: "username_error_msg" }
                  },
                  [_vm._v(_vm._s(_vm.errors.first("j_username")))]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-xs-3 mb-sm-3 mb-md-4 form-group has-feedback" },
            [
              _c("label", { attrs: { "aria-hidden": _vm.isDeviceAndroid() } }, [
                _vm._v(_vm._s(_vm.i18n.password))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.password,
                    expression: "user.password"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: true,
                      regex: _vm.globals.PASSWORD_REGEX
                    },
                    expression:
                      "{ required: true, regex: globals.PASSWORD_REGEX }"
                  }
                ],
                ref: "password",
                staticClass: "password form-control",
                class: [{ "input-error": _vm.errors.has("j_password") }],
                attrs: {
                  disabled: _vm.disableField,
                  type: "password",
                  name: "j_password",
                  maxlength: "255",
                  "aria-label": _vm.i18n.password,
                  "aria-errormessage": _vm.errors.first("j_password"),
                  "aria-describedby": "password_error_msg"
                },
                domProps: { value: _vm.user.password },
                on: {
                  focus: _vm.hideServerError,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "password", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "icon-eye toggle-password",
                class: { "icn-eye-collapsed": _vm.showPassword },
                attrs: {
                  tabindex: "0",
                  role: "button",
                  "aria-label": _vm.showPassword
                    ? _vm.i18n.iconEyeHideTitle
                    : _vm.i18n.iconEyeShowTitle,
                  title: _vm.showPassword
                    ? _vm.i18n.iconEyeHideTitle
                    : _vm.i18n.iconEyeShowTitle
                },
                on: { click: _vm.togglePassword }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("j_password"),
                      expression: "errors.has('j_password')"
                    }
                  ],
                  staticClass: "mt-xs-2",
                  attrs: { "aria-hidden": !_vm.errors.has("j_password") }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: { id: "password_error_msg" }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("j_password")))]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm.globals.isEStores()
            ? _c(
                "div",
                {
                  staticClass:
                    "mt-xs-4 pt-xs-4 mb-xs-4 form-group login-horizontal"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mb-xs-3 profileDesc",
                      attrs: { "aria-label": _vm.i18n.profileDesc }
                    },
                    [_vm._v(_vm._s(_vm.i18n.profileDesc))]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { attrs: { "aria-hidden": _vm.isDeviceAndroid() } },
                    [_vm._v(_vm._s(_vm.i18n.profileName))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.profile,
                        expression: "user.profile"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true, regex: /^[0-9-A-z]*$/ },
                        expression: "{required:true, regex:/^[0-9-A-z]*$/}"
                      }
                    ],
                    staticClass: "form-control",
                    class: [{ "input-error": _vm.errors.has("profileName") }],
                    attrs: {
                      type: "text",
                      maxlength: "40",
                      name: "profileName",
                      disabled: _vm.disableField,
                      "aria-label": _vm.i18n.profileName,
                      "aria-describedby": "profilename_error_msg"
                    },
                    domProps: { value: _vm.user.profile },
                    on: {
                      focus: _vm.hideServerError,
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "profile", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("profileName"),
                          expression: "errors.has('profileName')"
                        }
                      ],
                      staticClass: "mt-xs-2",
                      attrs: { "aria-hidden": !_vm.errors.has("profileName") }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: { id: "profilename_error_msg" }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("profileName")))]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.siteConfig.showForgotPassword
            ? _c(
                "p",
                { staticClass: " pt-sm-2 pt-xs-3 mx-xs-0 mt-xs-0 mb-xs-4" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        tabindex: "0",
                        role: "link",
                        "aria-label": _vm.i18n.forgotPassword
                      },
                      on: {
                        click: function($event) {
                          _vm.handleForgotPassword($event)
                        },
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter-space",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          _vm.handleForgotPassword($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.forgotPassword))]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.serverError
            ? _c("div", { staticClass: "mt-xs-4 mb-xs-3" }, [
                _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.serverError))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "baseSiteId" },
            domProps: { value: _vm.globals.siteId }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "CSRFToken" },
            domProps: { value: _vm.globals.csrfToken }
          }),
          _vm._v(" "),
          !_vm.isCheckout
            ? _c(
                "button",
                {
                  staticClass: "btn-block btn btn-primary",
                  attrs: {
                    type: "submit",
                    disabled: _vm.errors.any() || _vm.disableField
                  },
                  on: { click: _vm.submitClicked }
                },
                [_vm._v(_vm._s(_vm.i18n.submit))]
              )
            : _c(
                "button",
                {
                  staticClass: "btn-block btn btn-primary",
                  attrs: {
                    type: "submit",
                    disabled: _vm.errors.any() || _vm.disableField
                  },
                  on: { click: _vm.submitClicked }
                },
                [_vm._v(_vm._s(_vm.i18n.checkoutSubmit))]
              ),
          _vm._v(" "),
          !_vm.globals.isEStores()
            ? _c("p", { staticClass: "pt-xs-2 mx-xs-0 my-xs-0" }, [
                _vm.globals.isB2B()
                  ? _c("span", { staticClass: "no-account-text" }, [
                      _vm._v(_vm._s(_vm.i18n.noGPAccount))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.globals.isB2C()
                  ? _c("span", { staticClass: "no-account-text" }, [
                      _vm._v(_vm._s(_vm.i18n.noGPAccount))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("a", [
                  _c(
                    "span",
                    {
                      attrs: { tabindex: "0", role: "link" },
                      on: {
                        click: _vm.createAccount,
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter-space",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          return _vm.createAccount($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.createAccount))]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.isEStores()
            ? _c("p", { staticClass: "pt-xs-2 mx-xs-0 my-xs-0" }, [
                _vm.globals.isB2B()
                  ? _c("span", { staticClass: "no-account-text" }, [
                      _vm._v(_vm._s(_vm.i18n.noGPeStoresAccount))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("a", [
                  _c(
                    "span",
                    {
                      attrs: { tabindex: "0", role: "link" },
                      on: {
                        click: _vm.createAccount,
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter-space",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          return _vm.createAccount($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.registerHere))]
                  )
                ]),
                _c("span", [_vm._v(_vm._s(_vm.i18n.dot))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.isEStores()
            ? _c(
                "p",
                { staticClass: "pt-xs-2 mx-xs-0 my-xs-0 no-account-text" },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.i18n.contactUsHelpText) + "\n    "
                  )
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-fe6aa376", { render: render, staticRenderFns: staticRenderFns })
  }
}