export default {
  name: 'vx-empty-cart',
  components: {},
  props: {
    // Text coming from property file
    i18n: Object,
    // Indicates if this module is to be used for mini cart
    isMiniCart: Boolean,
    // Indicates whether to show the message for empty cart
    msgVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
