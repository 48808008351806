var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-share-list" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("p", { attrs: { role: "text" } }, [
          _vm._v(
            _vm._s(_vm.i18n.shareList.shareListDescription) +
              " " +
              _vm._s(_vm.listId) +
              " " +
              _vm._s(_vm.i18n.shareList.shareListContent)
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-xs-4 mb-xs-5 form-group" }, [
          _c(
            "label",
            {
              attrs: { for: "recipients", "aria-hidden": _vm.isDeviceAndroid() }
            },
            [_vm._v(_vm._s(_vm.i18n.shareList.shareListEmail))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.recipientValue,
                expression: "recipientValue"
              },
              {
                name: "validate",
                rawName: "v-validate:recipients",
                value: "required|email",
                expression: "'required|email'",
                arg: "recipients"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("recipients") },
            attrs: {
              type: "text",
              maxlength: "80",
              name: "recipients",
              "aria-label": _vm.i18n.shareList.shareListEmail
            },
            domProps: { value: _vm.recipientValue },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.recipientValue = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("recipients")
            ? _c("div", { staticClass: "mt-xs-2" }, [
                _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.errors.first("recipients")))
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c(
            "button",
            {
              staticClass:
                "submit btn button-color text-trans modal-btn-primary-small modal-btn-xs-sticky",
              attrs: { type: "submit", disabled: _vm.errors.any() }
            },
            [_vm._v(_vm._s(_vm.i18n.shareList.shareListButton))]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-08f726ec", { render: render, staticRenderFns: staticRenderFns })
  }
}