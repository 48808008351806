/**
 * Wide banner carousel
 */
import {
  swiper,
  swiperSlide,
} from 'vue-awesome-swiper';
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import { eventBus } from '../../../../modules/event-bus';

export default {
  name: 'vx-uiux-wide-banner-carousel',
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    carouselData: {
      type: Object,
    },
    componentTheme: String,
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      swiper: '',
      showNavigationArrows: false,
      firstSlide: true,
      lastSlide: false,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        freeMode: false,
        watchOverflow: true,
        loop: false,
        simulateTouch: this.enableSimulateTouch,
      },
      i18n: this.$root.messages.common.slider,
      swiperWrapperClass: '.swiper-container',
    };
  },
  computed: {},
  created() {
    if (this.swiperModifierClass) {
      this.swiperOptions.containerModifierClass = this.swiperModifierClass;
    }
  },
  mounted() {
    for (let i = 0; i < this.carouselData.gpRotatingData.length; i += 1) {
      if (this.carouselData.gpRotatingData[i].headingColor) {
        this.$refs[`header${i}`][0].style.color = this.carouselData.gpRotatingData[i].headingColor;
        if (this.$refs[`header${i}`][0].childNodes[0] && this.$refs[`header${i}`][0].childNodes[0].style) {
          this.$refs[`header${i}`][0].childNodes[0].style.color = this.carouselData.gpRotatingData[i].headingColor;
        }
      }
      if (this.carouselData.gpRotatingData[i].subHeadingColor) {
        this.$refs[`description${i}`][0].style.color = this.carouselData.gpRotatingData[i].subHeadingColor;
      }
      if (this.carouselData.gpRotatingData[i].ctaColor) {
        this.$refs[`button${i}`][0].style.color = this.carouselData.gpRotatingData[i].ctaColor;
        this.$refs[`button${i}`][0].style.backgroundColor = 'transparent';
        this.$refs[`button${i}`][0].style.border = `3px solid ${this.carouselData.gpRotatingData[i].ctaColor}`;
      }
      if (this.carouselData.gpRotatingData[i].isExternalLink) {
        this.$refs[`link${i}`][0].target = '_blank';
      }
    }
    if (this.carouselData.gpRotatingData.length > 0) {
      if (this.swiperModifierClass) {
        if (document.querySelector(`.${this.swiperModifierClass.concat('initialized')}`)) {
          this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'initialized');
        } else if (document.querySelector(`.${this.swiperModifierClass.concat('horizontal')}`)) {
          this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'horizontal');
        } else if (document.querySelector(`.${this.swiperModifierClass.concat('android')}`)) {
          this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'android');
        }
      }
      const self = this;
      self.onInit();
      self.swiper = document.querySelector(this.swiperWrapperClass).swiper;
      self.swiper.on('reachEnd', () => {
        self.firstSlide = false;
        self.lastSlide = true;
      });
      self.swiper.on('reachBeginning', () => {
        self.firstSlide = true;
        self.lastSlide = false;
      });
      window.addEventListener('resize', () => {
        self.onInit();
      });
    }
  },
  watch: {
    carouselData() {
      this.onInit();
    },
  },
  methods: {
    /**
         * Function sets the slides as per Viewport
         */
    onLazyLoad() {
      for (let i = 0; i < this.carouselData.gpRotatingData.length; i += 1) {
        const backgroundImageURL = this.getResponsiveImage(
          this.carouselData.gpRotatingData[i].backgroundImageD,
          this.carouselData.gpRotatingData[i].backgroundImageT,
          this.carouselData.gpRotatingData[i].backgroundImageM,
        );
        this.$refs[`slide${i}`][0].style.background = `url("${backgroundImageURL}") no-repeat center`;
        this.$refs[`slide${i}`][0].style.backgroundSize = 'cover';
      }
    },

    onInit() {
      const self = this;
      self.showNavigationArrows = self.carouselData.gpRotatingData.length > 1;
    },
    /*
         * Function sets the slides to previous product
         */
    slidePrev() {
      this.swiper.slidePrev();
      this.lastSlide = false;
    },
    /**
         * Function sets the slides to next product
         */
    slideNext() {
      this.swiper.slideNext();
      this.firstSlide = false;
    },
    openModal(videoSRC) {
      if (videoSRC) {
        eventBus.$emit('open-player', videoSRC);
      }
    },
  },
};
