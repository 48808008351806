import detectDeviceMixin from '../../common/mixins/detect-device-mixin';

export default {
  name: 'vx-add-custom-attribute',
  components: {},
  mixins: [detectDeviceMixin],
  props: {
    /**
   * Labels, button and caption texts
   */
    i18n: {
      type: Object,
    },
    /**
     * Data to prepopulate the modal attributes if already done once.
     */
    attributeList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      attributeInfo: {
        attributeOne: '',
        attributeTwo: '',
        attributeThree: '',
      },
      showPlaceholder: true,
    };
  },
  computed: {
    /**
     * This function watches for any of the attribute values to change to enable the submit button.
     * Since, it will remain disabled if none of the values change from previous session.
     */
    isAccordionValuesChanged() {
      if (this.attributeInfo.attributeOne.length !== 0 || this.attributeInfo.attributeTwo.length !== 0 || this.attributeInfo.attributeThree.length !== 0) { return this.attributeInfo.attributeOne !== this.attributeList[0].value || this.attributeInfo.attributeTwo !== this.attributeList[1].value || this.attributeInfo.attributeThree !== this.attributeList[2].value; }
      return false;
    },
  },
  watch: {
  },
  mounted() {
    this.prePopulateAttributes();
  },
  methods: {
    /**
     * This function prepopulates the text field with preexisting custom attributes added by the user.
     */
    prePopulateAttributes() {
      if (this.attributeList) {
        if (this.attributeList[0].value) {
          this.attributeInfo.attributeOne = this.attributeList[0].value;
        }
        if (this.attributeList[1].value) {
          this.attributeInfo.attributeTwo = this.attributeList[1].value;
        }
        if (this.attributeList[2].value) {
          this.attributeInfo.attributeThree = this.attributeList[2].value;
        }
      }
    },
    /**
     * This function submits the details entered for custom attributes by emitting an event to it's parent which is vx-list-details.
     */
    submitForm() {
      const self = this;
      self.$emit('onCustomAttributeInit', this.attributeInfo);
      this.isAccordionValuesChanged = false;
    },
  },
};
