var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-user-details" },
    [
      _vm.showResetPasswordMsg
        ? _c("div", { staticClass: "toast py-xs-3 px-xs-4" }, [
            _c("span", {
              staticClass: "icon-check",
              attrs: { title: _vm.i18n.iconSuccessTitle }
            }),
            _vm._v("\n    " + _vm._s(_vm.i18n.resetPassword) + "\n    "),
            _c("span", {
              staticClass: "icon-x pull-right",
              attrs: {
                title: _vm.i18n.iconCloseTitle,
                role: "button",
                tabindex: "0",
                "aria-label": _vm.i18n.iconCloseTitle
              },
              on: {
                click: function($event) {
                  _vm.showResetPasswordMsg = !_vm.showResetPasswordMsg
                },
                keyup: function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k(
                      $event.keyCode,
                      "enter-space",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  _vm.showResetPasswordMsg = !_vm.showResetPasswordMsg
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showInviteMsg
        ? _c("div", { staticClass: "toast py-xs-3 px-xs-4" }, [
            _c("span", {
              staticClass: "icon-check",
              attrs: { title: _vm.i18n.iconSuccessTitle }
            }),
            _vm._v("\n    " + _vm._s(_vm.i18n.inviteUser) + "\n    "),
            _c("span", {
              staticClass: "icon-x pull-right",
              attrs: {
                title: _vm.i18n.iconCloseTitle,
                role: "button",
                tabindex: "0",
                "aria-label": _vm.i18n.iconCloseTitle
              },
              on: {
                click: function($event) {
                  _vm.showInviteMsg = !_vm.showInviteMsg
                },
                keyup: function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k(
                      $event.keyCode,
                      "enter-space",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  _vm.showInviteMsg = !_vm.showInviteMsg
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("vx-details-section", {
        attrs: {
          "i18n-user-status": _vm.i18n.userStatus,
          i18n: _vm.i18n.unitDetail,
          "details-data": _vm.detailPageData.detailsData,
          "edit-button": true,
          "disable-button": true,
          "link-button": true
        },
        on: {
          disable: _vm.handleDisableClick,
          enable: _vm.handleEnableClick,
          edit: _vm.handleEditClick,
          link: _vm.handleResetPasswordClick
        }
      }),
      _vm._v(" "),
      _c("vx-info-section", {
        attrs: {
          i18n: _vm.i18n.parentUnits,
          "info-data": _vm.detailPageData.parentUnitGroupData,
          "delete-all": _vm.deleteAllPermissions,
          "new-button": false,
          "existing-button": true,
          "icon-class": "icon-trash",
          "display-active-only": false
        },
        on: {
          delete: _vm.handleRemoveClick,
          addExistingTo: _vm.handleAddExistingClick
        }
      }),
      _vm._v(" "),
      _c("vx-info-section", {
        attrs: {
          i18n: _vm.i18n.permissions,
          "info-data": _vm.detailPageData.permissionsGroupData,
          "new-button": false,
          "existing-button": true,
          "icon-class": "icon-trash",
          "display-active-only": false
        },
        on: {
          delete: _vm.handleRemoveClick,
          addExistingTo: _vm.handleAddExistingClick
        }
      }),
      _vm._v(" "),
      _c("vx-info-section", {
        attrs: {
          i18n: _vm.i18n.userGroups,
          "info-data": _vm.detailPageData.userGroupData,
          "new-button": false,
          "existing-button": true,
          "icon-class": "icon-trash",
          "display-active-only": false
        },
        on: {
          delete: _vm.handleRemoveClick,
          addExistingTo: _vm.handleAddExistingClick
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "disableUserModal",
          attrs: {
            heading: _vm.i18n.disableUserModal.heading,
            size: "extra-small"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c("p", { staticClass: "mb-xs-0 pb-xs-5" }, [
                _vm._v(_vm._s(_vm.i18n.disableUserModal.message1))
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary modal-btn-primary-small modal-btn-xs-sticky align-self-center",
                  on: { click: _vm.sendDisableRequest }
                },
                [_vm._v(_vm._s(_vm.i18n.disableUserModal.disableText))]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "enableUserModal",
          attrs: {
            heading: _vm.i18n.enableUnitModal.heading,
            size: "extra-small"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c("p", { staticClass: "mb-xs-0 pb-xs-5" }, [
                _vm._v(
                  _vm._s(_vm.i18n.enableUnitModal.message) +
                    " " +
                    _vm._s(_vm.detailPageData.unitDetailsData.name) +
                    " ?"
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary modal-btn-primary-small modal-btn-xs-sticky align-self-center",
                  on: { click: _vm.sendEnableRequest }
                },
                [_vm._v(_vm._s(_vm.i18n.enableUnitModal.enableText))]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "removeModal",
          attrs: {
            heading:
              _vm.i18n.removeModal.headingText + " " + _vm.removedInfo.from,
            size: "extra-small"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _vm.removedInfo.from === _vm.i18n.userGroups.groupLabel
                ? _c("p", { staticClass: "mb-xs-0 pb-xs-5" }, [
                    _vm._v(
                      _vm._s(_vm.i18n.removeModal.message) +
                        "\n        " +
                        _vm._s(_vm.removedInfo.delete) +
                        " ?"
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.removedInfo.from === _vm.i18n.permissions.groupLabel
                ? _c("p", { staticClass: "mb-xs-0 pb-xs-5" }, [
                    _vm._v(_vm._s(_vm.i18n.permissionRemoveMessage))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.removedInfo.from === _vm.i18n.parentUnits.groupLabel
                ? _c("p", { staticClass: "mb-xs-0 pb-xs-5" }, [
                    _vm._v(_vm._s(_vm.i18n.parentUnitRemoveMessage))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary modal-btn-primary-small modal-btn-xs-sticky align-self-center",
                  on: { click: _vm.sendRemoveRequest }
                },
                [_vm._v(_vm._s(_vm.i18n.removeModal.removeText))]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addExistingModal",
          attrs: {
            heading:
              _vm.i18n.addExistingModal.heading + " " + _vm.existingAddedTo,
            size: "extra-small"
          },
          on: { close: _vm.resetAddedValues }
        },
        [
          _c(
            "div",
            {
              staticClass: "row",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _vm.existingAddedTo === _vm.i18n.parentUnits.groupLabel
                ? _c(
                    "div",
                    { staticClass: "col-xs-12" },
                    _vm._l(_vm.existingParentUnits, function(item, index) {
                      return _c("div", { staticClass: "mb-xs-5" }, [
                        _c("div", { staticClass: "mb-xs-0" }, [
                          _c("label", { staticClass: "checkbox-container" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.businessUnitDetailsUrl + item.id
                                }
                              },
                              [_vm._v(_vm._s(item.id))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.existingItems,
                                  expression: "existingItems"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: item,
                                checked: Array.isArray(_vm.existingItems)
                                  ? _vm._i(_vm.existingItems, item) > -1
                                  : _vm.existingItems
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.existingItems,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.existingItems = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.existingItems = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.existingItems = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "pl-xs-5 mb-xs-0" }, [
                          _vm._v(_vm._s(item.name))
                        ])
                      ])
                    })
                  )
                : _vm.existingAddedTo === _vm.i18n.permissions.groupLabel
                ? _c(
                    "div",
                    { staticClass: "col-xs-12" },
                    _vm._l(_vm.existingPermissions, function(item, index) {
                      return _c(
                        "div",
                        { staticClass: "mb-xs-5 existing-permissions-info" },
                        [
                          _c("div", { staticClass: "mb-xs-0" }, [
                            _c("label", { staticClass: "checkbox-container" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.permissionDetailsUrl + item.code
                                  }
                                },
                                [_vm._v(_vm._s(item.code))]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.existingItems,
                                    expression: "existingItems"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: item.code,
                                  checked: Array.isArray(_vm.existingItems)
                                    ? _vm._i(_vm.existingItems, item.code) > -1
                                    : _vm.existingItems
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.existingItems,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = item.code,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.existingItems = $$a.concat([
                                            $$v
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.existingItems = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.existingItems = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "pl-xs-5 mb-xs-2" }, [
                            _vm._v(_vm._s(item.b2BPermissionTypeData.name))
                          ]),
                          _vm._v(" "),
                          item.periodRange
                            ? _c("p", { staticClass: "pl-xs-5 mb-xs-2" }, [
                                _vm._v(_vm._s(item.periodRange))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", { staticClass: "pl-xs-5 mb-xs-2" }, [
                            _vm._v(
                              _vm._s(item.currency.symbol) + _vm._s(item.value)
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "pl-xs-5 mb-xs-2" }, [
                            _vm._v(_vm._s(item.unit.name))
                          ])
                        ]
                      )
                    })
                  )
                : _vm.existingAddedTo === _vm.i18n.userGroups.groupLabel
                ? _c(
                    "div",
                    { staticClass: "col-xs-12" },
                    _vm._l(_vm.existingUserGroups, function(item, index) {
                      return _c("div", { staticClass: "mb-xs-5" }, [
                        _c("div", { staticClass: "mb-xs-0" }, [
                          _c("label", { staticClass: "checkbox-container" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.userGroupDetailsUrl + item.uid
                                }
                              },
                              [_vm._v(_vm._s(item.uid))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.existingItems,
                                  expression: "existingItems"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: item.uid,
                                checked: Array.isArray(_vm.existingItems)
                                  ? _vm._i(_vm.existingItems, item.uid) > -1
                                  : _vm.existingItems
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.existingItems,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item.uid,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.existingItems = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.existingItems = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.existingItems = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          staticClass: "pl-xs-5 mb-xs-0",
                          domProps: { innerHTML: _vm._s(item.name) }
                        }),
                        _vm._v(" "),
                        _c("p", {
                          staticClass: "pl-xs-5 mb-xs-0",
                          domProps: { innerHTML: _vm._s(item.unit.uid) }
                        })
                      ])
                    })
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.parentUnitsError,
                      expression: "parentUnitsError"
                    }
                  ],
                  staticClass: "col-xs-12 alert-msg p-xs-3 mb-xs-5 mt-xs-3"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.i18n.deleteDefaultUnitError) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-xs-12 text-center" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-primary modal-btn-primary-small modal-btn-xs-sticky",
                    on: { click: _vm.sendAddExistingRequest }
                  },
                  [_vm._v(_vm._s(_vm.i18n.addExistingModal.saveText))]
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "editUserModal",
          attrs: { size: "large", heading: _vm.i18n.editUserModal.editUser }
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _c("vx-user-form", {
                attrs: {
                  slot: "component",
                  "is-email-exists": _vm.isEmailExists,
                  "user-role": _vm.UserRoles.CUSTOMER,
                  "business-units-list": _vm.businessUnitsList,
                  "form-data": _vm.editUserData,
                  i18n: _vm.i18n.editUserModal,
                  "help-message": _vm.i18n.noFormChangeMsg,
                  type: "modal",
                  isEdit: true
                },
                on: {
                  showSpinner: _vm.showLoadingSpinner,
                  hideSpinner: _vm.hideLoadingSpinner,
                  closeUserModal: _vm.closeUserModal,
                  setUsers: _vm.setUsersData
                },
                slot: "component"
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9d1c366e", { render: render, staticRenderFns: staticRenderFns })
  }
}