const accessibleDropDown = function(className) {
  const elements = document.querySelectorAll(['a', className].join(','));
  elements.forEach((element) => element.addEventListener('hover', (event) => {
    const parent = event.target.closest('.js-primary-menu-item');
    parent.classList.add('hovered');
    parent.setAttribute('aria-expanded', 'true');
  }));
};

const sortPriceAsNumber = (sortBy) => function(a, b) {
  const getNumber = (string) => Number(string.replace(/[^0-9.-]+/g, ''));
  return getNumber(a[sortBy]) - getNumber(b[sortBy]);
};

export { accessibleDropDown, sortPriceAsNumber };
