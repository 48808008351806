/**
 * vx-find-distibutor is used to get
 *  the distributors data on search
 */
import Paginate from 'vuejs-paginate';
import get from 'lodash.get';
import vxStoreSearch from '../vx-store-search/vx-store-search.vue';
import vxStoreList from '../../../../../components/view-site-content/find-distributor/vx-store-list/vx-store-list.vue';
import vxStoreLocation from '../../../../../components/view-site-content/find-distributor/vx-store-location/vx-store-location.vue';
import ViewSiteContentService from '../../../../../components/common/services/view-site-content-service';
import PdpService from '../../../../../components/common/services/pdp-service';
import VxSpinner from '../../../../../components/common/vx-spinner/vx-spinner.vue';
import globals from '../../../../../components/common/globals';
import storeLocatorMixin from '../../../../../components/common/mixins/store-locator-mixin';
import mobileMixin from '../../../../../components/common/mixins/mobile-mixin';
import googleMapsMixin from '../../../../../components/common/mixins/google-maps-mixin';
import flyoutBannerMixin from '../../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import detectDeviceMixin from '../../../../../components/common/mixins/detect-device-mixin';
import { flyoutStatus } from '../../../../../components/common/mixins/vx-enums';
import { globalEventBus } from '../../../../../modules/event-bus';

export default {
  name: 'vx-find-distributor',
  components: {
    vxStoreList,
    vxStoreLocation,
    vxStoreSearch,
    ViewSiteContentService,
    VxSpinner,
    'vx-paginate': Paginate,
  },
  props: {
    i18n: {
      type: Object,
      required: true,
    },
    isPdpPage: {
      type: String,
      default: 'false',
    },
    prdCode: {
      type: String,
    },
    timeoutVal: {
      type: Number,
      default: 15000,
    },
    listPageUrl: {
      type: String,
      default: '',
    },
  },
  mixins: [
    storeLocatorMixin,
    mobileMixin,
    googleMapsMixin,
    flyoutBannerMixin,
    detectDeviceMixin,
  ],
  data() {
    return {
      globals,
      viewSiteContentService: new ViewSiteContentService(),
      pdpService: new PdpService(),
      storesList: [],
      productList: [],
      isNearByClicked: false,
      permissionDenied: false,
      pagination: {
        next: '&#62;',
        previous: '&#60;',
        totalPages: '',
        pageRange: 5,
        totalResults: '',
      },
      distributorFilters: {
        radius: 50,
        longitude: '',
        latitude: '',
        pageSize: 6,
        currentPage: 0,
        searchText: '',
        productCode: '',
        wishlistUid: '',
        industry: '',
      },
      productInfo: {},
      noOfDistributor: 0,
      locationTimeout: '',
      resetFilterFlag: false,
      userPosition: {
        coords: {
          latitude: '',
          longitude: '',
        },
      },
      errorMsgFlag: false,
      flyoutStatus,
      showSearchError: false,
      selectedProductlist: {
        label: '',
        value: '',
      },
      existingList: true,
      isLinkShown: false,
      industriesList: [],
      searchResults: '',
      disabledSearchResults: '',
    };
  },
  computed: {},
  mounted() {
    // check if navigating from PDP
    this.isFromPdp();
    this.getLocation(false);
    if (globals.getIsLoggedIn() && !this.isFromPdp()) {
      this.getAllWishList();
    }
    globalEventBus.$on('list-create-or-update', (data) => {
      if (globals.getIsLoggedIn() && !this.isFromPdp()) {
        if (!data.existingList) {
          this.$refs.pageSpinner.showSpinner();
          this.existingList = false;
          this.getAllWishList();
        } else if (this.selectedProductlist.value === data.wishlistId) {
          this.getProductDistributors(this.selectedProductlist);
        }
      }
    });
    this.getIndustriesList();
  },
  methods: {
    /**
         * gets the distributor data and setting in the distributorFilters Object
         * @param {string} evt contains the  search
         * @return {string} null
         */
    getDistributorsData(evt) {
      this.$refs.pageSpinner.showSpinner();
      this.distributorFilters = {
        ...this.distributorFilters,
        searchText: evt,
      };
      this.paginationCallback();
    },
    /**
         * gets the location with lat and long coordinates
         * @param {boolean} isFindNearby is a boolean
         * @return {string} null
         */
    getLocation(isFindNearby) {
      if (navigator.geolocation) {
        this.dismissFlyout();
        if (
          this.isIEBrowser()
                    && this.userPosition.coords.latitude === ''
                    && this.userPosition.coords.longitude === ''
        ) {
          this.locationTimeout = setTimeout(
            this.geoErrorHandler,
            this.timeoutVal,
          );
        }
        navigator.geolocation.getCurrentPosition(
          this.currentPosition,
          this.geoErrorHandler,
        );
        this.$refs.pageSpinner.showSpinner();
      }
      this.isNearByClicked = isFindNearby;
    },
    /**
         * it is the error handler for getLocation method
         * @param {object} error
         * @return {object} null
         */
    geoErrorHandler(error) {
      clearTimeout(this.locationTimeout);
      if (error && error.code === error.PERMISSION_DENIED) {
        if (this.isFromPdp() && !this.isNearByClicked) {
          this.getProductInfo(this.prdCode);
        } else {
          if (this.errorMsgFlag) {
            this.showFlyout(
              this.flyoutStatus.error,
              `${this.i18n.findDistributor.permissionDeniedError}`,
              false,
            );
          }
          this.$refs.pageSpinner.hideSpinner();
        }
      } else {
        if (this.errorMsgFlag) {
          this.showFlyout(
            this.flyoutStatus.error,
            `${this.i18n.findDistributor.permissionDeniedError}`,
            false,
          );
        }
        this.$refs.pageSpinner.hideSpinner();
      }
      this.errorMsgFlag = true;
      if (
        this.isIEBrowser()
                && this.userPosition.coords.latitude !== ''
                && this.userPosition.coords.longitude !== ''
      ) {
        this.$refs.pageSpinner.showSpinner();
        this.currentPosition(this.userPosition);
      }
    },
    /**
         * sets the current position as lat and long keys
         *  in the distributor filter object
         * @param {object} position
         * @return {object} null
         */
    currentPosition(position) {
      clearTimeout(this.locationTimeout);
      this.resetFilterFlag = !this.resetFilterFlag;
      this.distributorFilters = {
        ...this.distributorFilters,
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
        searchText: '',
        radius: 50,
      };
      if (!this.isFromPdp()) {
        this.paginationCallback();
      } else if (!this.isNearByClicked) {
        this.getProductInfo(this.prdCode);
      } else {
        this.paginationCallback();
      }
      if (this.isIEBrowser()) {
        this.userPosition.coords.latitude = position.coords.latitude;
        this.userPosition.coords.longitude = position.coords.longitude;
      }
    },
    /**
         * used to handle the response from fetch distributors call
         * @param {object} resp
         * @return {object} null
         */
    handleDistributorsResponse(resp) {
      this.storesList = resp.data.stores;
      this.pagination.totalPages = resp.data.pagination.totalPages;
      this.pagination.totalResults = resp.data.pagination.totalResults;
      this.noOfDistributor = resp.data.pagination.totalResults;
      if (this.storesList.length) {
        this.storeSelected(this.storesList[0], true);
      }
      this.$refs.pageSpinner.hideSpinner();
    },
    /**
         * used to handle the error response from fetch distributor call
         * @param {object} err
         * @return {object} null
         */
    handleDistributorsError() {
      this.$refs.pageSpinner.hideSpinner();
    },
    /**
         * used to get the updated distributors by passing
         * the evt obect as parameter and will update
         * the distributor filter object
         * @param {object} evt
         * @return {object} null
         */
    getUpdatedDistributors(evt) {
      this.distributorFilters = {
        ...this.distributorFilters,
        radius: evt.value,
      };
      if (
        this.distributorFilters.searchText
                || (this.distributorFilters.latitude && this.distributorFilters.longitude)
      ) {
        this.$refs.pageSpinner.showSpinner();
        this.paginationCallback();
      }
    },
    handleIndustryChange(evt) {
      this.distributorFilters = {
        ...this.distributorFilters,
        industry: evt.value,
      };
      if (
        this.distributorFilters.searchText
                || (this.distributorFilters.latitude && this.distributorFilters.longitude)
      ) {
        this.$refs.pageSpinner.showSpinner();
        this.paginationCallback();
      }
    },
    /**
         * used to fetch the distributors
         * by making a serice call
         */
    fetchDistributors() {
      this.viewSiteContentService.fetchStoresWithLatLong(
        this.distributorFilters,
        this.handleDistributorsResponse,
        this.handleDistributorsError,
      );
    },
    /**
         * used to set the current page number
         * and in error case it will again rest the page number to 0
         * @param {number} pageNum
         * @return {object} null
         */
    paginationCallback(pageNum) {
      this.distributorFilters = {
        ...this.distributorFilters,
        currentPage: pageNum - 1,
      };
      this.showSearchError = false;
      if (this.pagination.totalPages > this.distributorFilters.currentPage) {
        this.$refs.pageSpinner.showSpinner();
      } else {
        this.distributorFilters = {
          ...this.distributorFilters,
          currentPage: 0,
        };
      }
      setTimeout(() => {
        [].filter.call([document.querySelector('.pagination>li:first-child>a')], (item) => item)
          .forEach((item) => item.setAttribute('aria-label', 'Previous distributor'));
        [].filter.call([document.querySelector('.pagination>li:last-child>a')], (item) => item)
          .forEach((item) => item.setAttribute('aria-label', 'Next distributor'));
        this.searchResults = document.querySelectorAll('.page-item>a');
        this.disabledSearchResults = document.querySelectorAll('.page-item.disabled>a');
        this.searchResults.forEach((element) => {
          element.setAttribute('aria-label', `Page ${element.innerText}`);
          element.setAttribute('tabindex', '0');
        });
        this.disabledSearchResults.forEach((element) => {
          element.setAttribute('tabindex', '-1');
        });
      }, 1000);
      this.dismissFlyout();
      this.fetchDistributors();
    },
    /**
         * checks if user is navigating from PDP
         * @return {boolean} wether true or false
         */
    isFromPdp() {
      return this.isPdpPage === true;
    },
    /**
         * used to ge tht info of the product
         * by hitting the pdpService
         * @param {string} id
         * @return {object} null
         */
    getProductInfo(id) {
      this.pdpService.getProductData(
        {},
        this.handleSuccessResponse,
        this.handleErrorResponse,
        id,
      );
    },
    /**
         * handles the success response of the getProductData method
         * @param {object} response
         * @return {object} null
         */
    handleSuccessResponse(response) {
      const { data } = response;
      let primaryImg = {};
      let findImg = {};
      if (data.images) {
        findImg = data.images.find((img) => (img.imageType === 'PRIMARY'));
      }
      primaryImg = findImg ? {
        ...primaryImg,
        pImage: findImg.thumbnailUrl,
        pAltText: findImg.altText,
      } : {
        ...primaryImg,
        pImage: data.images[0].thumbnailUrl,
        pAltText: data.images[0].altText,
      };
      this.productInfo = {
        ...this.productInfo,
        image: primaryImg.pImage ? primaryImg.pImage : '',
        altText: primaryImg.pAltText ? primaryImg.pAltText : '',
        prdTitle: data.name ? data.name : '',
        prdSKUId: data.code ? data.code : '',
        prdCustId: data.cmirCode ? data.cmirCode : '',
        pdpLink: data.url ? data.url : '',
        displayAttributes: data.displayAttributes,
      };
      this.getDistributorFromProdID(true);
    },
    handleErrorResponse() {
    },
    /**
         * hits the getShoppingLists method in viewSiteContentService
         */
    getAllWishList() {
      this.viewSiteContentService.getShoppingLists(
        {},
        this.handleGetWishlistResponse,
        this.handleGetWishlistError,
      );
    },
    /**
         * handles the success response of getShoppingLists method
         * @param {object} response
         * @return {object} null
         */
    handleGetWishlistResponse(response) {
      let data = response.data.wishlists;
      if (data && data.length > 0) {
        if (response.data.wishlists.length > 1) {
          data = data
            .sort(
              (list1, list2) => new Date(list1.modifiedTime) - new Date(list2.modifiedTime),
            )
            .reverse();
        }
        data.map((item, index) => this.$set(this.productList, index, {
          label: item.name,
          value: item.wishlistUid,
        }));
        this.isLinkShown = false;
      } else if (globals.getIsLoggedIn()) {
        this.$set(this.productList, 0, {
          label: this.i18n.findDistributor.noProductListLabel,
          value: '',
          disable: true,
        });
        this.isLinkShown = true;
      } else {
        this.productList = [];
        this.isLinkShown = false;
      }
      if (!this.existingList) {
        this.$refs.pageSpinner.hideSpinner();
      }
    },
    handleGetWishlistError() {
    },
    /**
         *get the selcted distributors list
         * @param {object} selectedList
         * @return {object} null
         */
    getProductDistributors(selectedList) {
      this.selectedProductlist = selectedList;
      if (Object.keys(selectedList).length > 0) {
        this.distributorFilters = {
          ...this.distributorFilters,
          wishlistUid: selectedList.value,
        };
      } else {
        this.distributorFilters = {
          ...this.distributorFilters,
          wishlistUid: '',
        };
      }
      if (
        this.distributorFilters.searchText
                || (this.distributorFilters.latitude && this.distributorFilters.longitude)
      ) {
        this.$refs.pageSpinner.showSpinner();
        this.paginationCallback();
      } else {
        this.showSearchError = true;
        this.showFlyout(
          this.flyoutStatus.error,
          `${this.i18n.findDistributor.searchCriteriaError}`,
          false,
        );
      }
    },
    /**
         * gets the distributors from the product ID passed as param
         * @param {string} prodStock
         * @retun {object} null
         */
    // TODO: use this function to filter data based on product
    getDistributorFromProdID(prodStock) {
      this.distributorFilters = {
        ...this.distributorFilters,
        productCode: prodStock ? this.prdCode : '',
        wishlistUid: '',
      };
      if (
        (this.distributorFilters.latitude
                    && this.distributorFilters.longitude)
                || this.distributorFilters.searchText
      ) {
        this.$refs.pageSpinner.showSpinner();
        this.paginationCallback();
      } else {
        this.$refs.pageSpinner.hideSpinner();
        this.showSearchError = true;
        this.showFlyout(
          this.flyoutStatus.error,
          `${this.i18n.findDistributor.searchCriteriaError}`,
          false,
        );
      }
    },
    getIndustriesList() {
      this.viewSiteContentService.getIndustriesList(
        {},
        this.handleGetIndustriesResponse,
        this.handleGetIndustriesError,
      );
    },
    handleGetIndustriesResponse(response) {
      const industries = get(response, ['data', 'industryTypeValues']);
      this.industriesList = industries.map((industry) => ({ label: industry, value: industry }));
      this.industriesList.unshift({ label: 'All', value: '' });
    },
    handleGetIndustriesError() {
    },
    handleProductSelection(productCode) {
      this.distributorFilters = {
        ...this.distributorFilters,
        productCode,
      };
      this.isPdpPage = true;
      if (productCode === '') {
        this.productInfo = {
          ...this.productInfo,
          image: '',
          altText: '',
          prdTitle: '',
          prdSKUId: '',
          prdCustId: '',
          pdpLink: '',
        };
        this.prdCode = '';
      } else {
        this.getProductInfo(productCode);
        this.prdCode = productCode;
      }
      if (
        this.distributorFilters.searchText
                || (this.distributorFilters.latitude && this.distributorFilters.longitude)
      ) {
        this.$refs.pageSpinner.showSpinner();
        this.paginationCallback();
      }
    },
  },
};
