var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notify-me" }, [
    _c(
      "form",
      {
        ref: "notifyMe",
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.handleSubmit($event)
          }
        }
      },
      [
        _c(
          "h4",
          {
            staticClass: "pb-lg-4 pb-md-4 pb-sm-4 pb-xs-4 px-xs-2 title-content"
          },
          [_vm._v(_vm._s(_vm.title))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group px-xs-2" }, [
          _c("p", { staticClass: "pb-lg-1 pb-md-1 pb-sm-1 pb-xs-1" }, [
            _vm._v(_vm._s(_vm.i18n.emailAddress))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.notifyMeEmailId,
                expression: "form.notifyMeEmailId"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|email",
                expression: "'required|email'"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("notifyMe") },
            attrs: {
              type: "text",
              name: "notifyMe",
              "aria-describedby": "notify_error_msg",
              "aria-label": _vm.i18n.emailAddress
            },
            domProps: { value: _vm.form.notifyMeEmailId },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "notifyMeEmailId", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "error-style", attrs: { id: "notify_error_msg" } },
            [_vm._v(_vm._s(_vm.errors.first("notifyMe")))]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c(
            "button",
            {
              staticClass:
                "btn btn-primary mt-md-5 mt-sm-5 modal-btn-primary-small modal-btn-xs-sticky",
              attrs: {
                disabled: _vm.errors.any(),
                type: "submit",
                "data-dismiss": "modal",
                "aria-label": _vm.i18n.add.toLowerCase()
              }
            },
            [_vm._v(_vm._s(_vm.i18n.add))]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-694fa68c", { render: render, staticRenderFns: staticRenderFns })
  }
}