var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.frequentlyBoughtTogetherParsedArray.length > 0
    ? _c(
        "div",
        { staticClass: "vx-frequently-bought-together" },
        [
          _c("vx-spinner", {
            ref: "spinner",
            attrs: {
              image: _vm.globals.assetsPath + "images/spinner.gif",
              "full-screen": ""
            }
          }),
          _vm._v(" "),
          _c(
            "vx-modal",
            {
              ref: "selectListModal",
              attrs: {
                size: "small",
                heading:
                  _vm.messages["en-US"].searchBrowse.searchResult.selectAList
                    .selectListHeading
              }
            },
            [
              _c("vx-save-cart", {
                attrs: {
                  slot: "component",
                  i18n:
                    _vm.messages["en-US"].searchBrowse.searchResult.selectAList,
                  "is-bulk-enabled": _vm.isBulkEnabled,
                  "selected-bulk-products":
                    _vm.frequentlyBoughtTogetherParsedArray,
                  "is-product": "true"
                },
                on: { "list-saved": _vm.onSelectListSuccess },
                slot: "component"
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.guestListName
            ? [
                _c("vx-save-cart", {
                  attrs: {
                    i18n:
                      _vm.messages["en-US"].searchBrowse.searchResult
                        .selectAList,
                    "is-bulk-enabled": _vm.isBulkEnabled,
                    "is-product": "true",
                    "guest-list-name": _vm.guestListName,
                    "selected-bulk-products":
                      _vm.frequentlyBoughtTogetherParsedArray
                  },
                  on: { "list-saved": _vm.onSelectListSuccess }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "header" }, [
              _vm._v("\n            FREQUENTLY BOUGHT TOGETHER\n        ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "images-container" },
              _vm._l(_vm.frequentlyBoughtTogetherParsedArray, function(
                item,
                index
              ) {
                return _c("div", { staticClass: "image-container" }, [
                  _c("a", { attrs: { href: item.url, target: "_blank" } }, [
                    item.images
                      ? _c("img", { attrs: { src: item.images[0].url } })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  item.images &&
                  index < _vm.frequentlyBoughtTogetherParsedArray.length - 1
                    ? _c("span", { staticClass: "plus-sign" }, [
                        _vm._v("\n                    +\n                ")
                      ])
                    : _vm._e()
                ])
              })
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _c(
              "div",
              { staticClass: "items" },
              _vm._l(_vm.frequentlyBoughtTogetherParsedArray, function(item) {
                return _c("div", { staticClass: "item" }, [
                  _c("span", { staticClass: "checkmark" }, [
                    _vm._v("\n                    ✓\n                ")
                  ]),
                  _vm._v(" "),
                  _c("a", { attrs: { href: item.url, target: "_blank" } }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.name) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "price" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.formattedValue) +
                          "\n                    "
                      )
                    ])
                  ])
                ])
              })
            ),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                {
                  staticClass: "add-all-to-cart-button form-blue-button",
                  on: {
                    click: function($event) {
                      _vm.handleBulkAddToCart()
                    }
                  }
                },
                [_vm._v("\n                ADD ALL TO CART\n            ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "add-all-to-list-button",
                  on: {
                    click: function($event) {
                      _vm.handleBulkAddToList()
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n                    ADD ALL TO LIST\n                "
                    )
                  ])
                ]
              )
            ])
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-567e2e33", { render: render, staticRenderFns: staticRenderFns })
  }
}