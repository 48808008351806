/**
 * Title component will be used to display titles for authorable components.
 */
export default {
  name: 'vx-title',
  components: {},
  props: {
    sectionTitle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
