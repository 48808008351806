var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-image-text d-flex flex-wrap",
      class: [
        {
          "flex-sm-row-reverse": _vm.imageTileData.imageAlignment === "right",
          "no-image":
            !_vm.imageTileData.imageSrcD || _vm.imageTileData.imageWidth == 0
        },
        _vm.componentTheme
      ],
      attrs: { id: _vm.imageTileData.componentId }
    },
    [
      _vm.imageTileData.imageSrcD && _vm.imageTileData.imageWidth > 0
        ? _c("vx-image-tile", {
            staticClass: "col-xs-12",
            attrs: { "image-tile-data": _vm.imageTileData }
          })
        : _vm._e(),
      _vm._v(" "),
      (_vm.textTileData.text ||
        _vm.textTileData.ctaText ||
        _vm.textTileData.text) &&
      100 - _vm.imageTileData.imageWidth > 0
        ? _c("vx-text-tile", { attrs: { "text-tile-data": _vm.textTileData } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-928afd88", { render: render, staticRenderFns: staticRenderFns })
  }
}