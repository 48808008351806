var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row login-form-container" }, [
    _c("div", { staticClass: "d-flex login-component flex-row flex-wrap" }, [
      _c(
        "div",
        {
          staticClass:
            "col-md-4 col-sm-6 col-xs-12 px-sm-5 pb-sm-3 mx-sm-0 business-login login-left-content-slot"
        },
        [
          _c("div", { staticClass: "login-title-box" }, [
            _vm._v("LOGIN TO GPXPRESS®")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "registration-and-login-sign-in" }, [
            _vm.isASM()
              ? _c("section", [
                  _c("h1", { staticStyle: { "font-size": "32px" } }, [
                    _vm._v("Log in to continue.")
                  ])
                ])
              : _c(
                  "section",
                  [
                    _c("vx-spinner", {
                      ref: "spinner",
                      attrs: {
                        image: _vm.globals.assetsPath + "images/spinner.gif",
                        "full-screen": ""
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "vx-modal",
                      {
                        ref: "forgotPasswordModal",
                        attrs: { heading: _vm.i18n.passwordModalHeading }
                      },
                      [
                        _c("vx-forgot-password", {
                          attrs: { slot: "component", i18n: _vm.i18n },
                          slot: "component"
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        ref: "loginForm",
                        staticClass: "gp-express-login",
                        attrs: {
                          action: "https://login.gp.com/login",
                          method: "post",
                          enctype: "multipart/form-data"
                        }
                      },
                      [
                        _c("fieldset", [
                          _c("div", { staticClass: "my-xs-4 form-group" }, [
                            _c(
                              "label",
                              {
                                attrs: { "aria-hidden": _vm.isDeviceAndroid() }
                              },
                              [_vm._v(_vm._s(_vm.i18n.email))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.email,
                                  expression: "user.email"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email|max:80",
                                  expression: "'required|email|max:80'"
                                }
                              ],
                              staticClass: "form-control",
                              class: [
                                { "input-error": _vm.errors.has("j_username") }
                              ],
                              attrs: {
                                type: "text",
                                maxlength: "80",
                                disabled: _vm.disableField,
                                name: "j_username",
                                "aria-label": _vm.i18n.email,
                                "aria-describedby": "username_error_msg"
                              },
                              domProps: { value: _vm.user.email },
                              on: {
                                focus: _vm.hideServerError,
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.user,
                                      "email",
                                      $event.target.value
                                    )
                                  },
                                  function($event) {
                                    _vm.user.email = $event.target.value
                                  }
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("j_username"),
                                    expression: "errors.has('j_username')"
                                  }
                                ],
                                staticClass: "mt-xs-2",
                                attrs: {
                                  "aria-hidden": !_vm.errors.has("j_username")
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "error-msg",
                                    attrs: { id: "username_error_msg" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.errors.first("j_username")) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mb-xs-3 mb-sm-3 mb-md-4 form-group has-feedback"
                            },
                            [
                              _c(
                                "label",
                                {
                                  attrs: {
                                    "aria-hidden": _vm.isDeviceAndroid()
                                  }
                                },
                                [_vm._v(_vm._s(_vm.i18n.password))]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.password,
                                    expression: "user.password"
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: true,
                                      regex: _vm.globals.PASSWORD_REGEX
                                    },
                                    expression:
                                      "{ required: true, regex: globals.PASSWORD_REGEX }"
                                  }
                                ],
                                ref: "password",
                                staticClass: "password form-control",
                                class: [
                                  {
                                    "input-error": _vm.errors.has("j_password")
                                  }
                                ],
                                attrs: {
                                  disabled: _vm.disableField,
                                  type: "password",
                                  name: "j_password",
                                  maxlength: "255",
                                  "aria-label": _vm.i18n.password,
                                  "aria-errormessage": _vm.errors.first(
                                    "j_password"
                                  ),
                                  "aria-describedby": "password_error_msg"
                                },
                                domProps: { value: _vm.user.password },
                                on: {
                                  focus: _vm.hideServerError,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.user,
                                      "password",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "icon-eye toggle-password",
                                class: {
                                  "icn-eye-collapsed": _vm.showPassword
                                },
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  title: _vm.showPassword
                                    ? _vm.i18n.iconEyeHideTitle
                                    : _vm.i18n.iconEyeShowTitle
                                },
                                on: { click: _vm.togglePassword }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("j_password"),
                                      expression: "errors.has('j_password')"
                                    }
                                  ],
                                  staticClass: "mt-xs-2",
                                  attrs: {
                                    "aria-hidden": !_vm.errors.has("j_password")
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "error-msg",
                                      attrs: { id: "password_error_msg" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.errors.first("j_password")
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _vm.globals.siteConfig.showForgotPassword
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "login-forgot-password pt-sm-2 pt-xs-3 mx-xs-0 mt-xs-0 mb-xs-4"
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "forgot-password",
                                    attrs: {
                                      href: "https://gpxpr.es/forgotpassword",
                                      tabindex: "0",
                                      role: "link",
                                      "aria-label": _vm.i18n.forgotPassword
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.i18n.forgotPassword) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "remember-me" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.gpProLoginRememberMe,
                                expression: "gpProLoginRememberMe"
                              }
                            ],
                            attrs: {
                              id: "login-checkbox",
                              type: "checkbox",
                              "aria-labeledby": "remember-box"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.gpProLoginRememberMe)
                                ? _vm._i(_vm.gpProLoginRememberMe, null) > -1
                                : _vm.gpProLoginRememberMe
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.gpProLoginRememberMe,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.gpProLoginRememberMe = $$a.concat([
                                        $$v
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.gpProLoginRememberMe = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.gpProLoginRememberMe = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              attrs: {
                                "aria-label": "Remember Me",
                                id: "remember-box",
                                for: "login-checkbox"
                              }
                            },
                            [
                              _vm._v(
                                "\n                Remember Me\n              "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _vm.serverError
                          ? _c("div", { staticClass: "mt-xs-3 mb-xs-3" }, [
                              _c("span", { staticClass: "error-msg" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.serverError) +
                                    "\n              "
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            name: "launchurl",
                            type: "hidden",
                            value: "https://gpxpress.srv.gapac.com/"
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            name: "realm",
                            type: "hidden",
                            value: "gpxpress"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "form-blue-button",
                            attrs: {
                              id: "formSubmit_gppro_loginPage",
                              type: "submit",
                              disabled: _vm.errors.any() || _vm.disableField
                            },
                            on: { click: _vm.submitClicked }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.i18n.submit) +
                                "\n            "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(1)
                  ],
                  1
                )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _vm._v(
        "\n            GPXpress® is GP PRO's one source for distribution operations, sales and marketing. This platform\n            helps bring "
      ),
      _c("span", { staticClass: "bold-blue" }, [
        _vm._v(
          "efficiency,\n              transparency and knowledgeable support to the order and fulfillment journey"
        )
      ]),
      _vm._v(
        ". Member can\n            manage pricing, view order\n            statuses in real time, live chat more!\n          "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("a", { attrs: { href: "https://gpxpr.es/Register" } }, [
        _c("button", { staticClass: "login-cta-button white-button" }, [
          _vm._v("\n              REGISTER\n            ")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-527cd068", { render: render, staticRenderFns: staticRenderFns })
  }
}