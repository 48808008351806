var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      ref: "listPage",
      staticClass: "search-browse-list-page",
      class: { "pt-xs-0": _vm.singleProductEnabled && _vm.isMobile() }
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex",
          class:
            _vm.singleProductEnabled && _vm.isMobile()
              ? "tile-group-single-product"
              : "tile-group"
        },
        _vm._l(_vm.products, function(product, index) {
          return _vm.products.length
            ? _c("vx-product-tile-vertical", {
                key: product && product.code ? product.code + index : product,
                attrs: {
                  product: product,
                  uncheckProduct: _vm.uncheckProduct,
                  "show-compare-option": !_vm.isBulkEnabled,
                  "is-bulk-enabled": _vm.isBulkEnabled,
                  "is-favorite-visible": _vm.isFavorites,
                  "is-item-id-hide": true,
                  "is-bazaar-voice": _vm.isBazaarVoice,
                  i18n: _vm.searchBrowseI18n.productTile,
                  showDisplayAttributes: _vm.listView,
                  showCertifications: true
                },
                on: {
                  checkedElement: function($event) {
                    _vm.addToCompare($event)
                  },
                  unCheckedElement: function($event) {
                    _vm.removeFromCompare($event)
                  },
                  incrementCounter: _vm.updateCounter,
                  decrementCounter: _vm.updateCounter
                }
              })
            : _vm._e()
        })
      ),
      _vm._v(" "),
      _vm.compareProduct.length
        ? _c("vx-panel-view", {
            attrs: {
              "compared-products": _vm.compareProduct,
              disableButtonFalse: _vm.disableButtonFalse,
              maxProductsCompare: _vm.viewportCount,
              i18n: _vm.searchBrowseI18n.comparePanel
            },
            on: {
              panelDelete: function($event) {
                _vm.updateTotalCountPanel()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bc5e900c", { render: render, staticRenderFns: staticRenderFns })
  }
}