import globals from '../../../components/common/globals';

export default {
  name: 'vx-how-to-buy',
  data() {
    return {
      globals,
    };
  },
  methods: {
    goToLink(className) {
      const elem = document.getElementsByClassName(className);
      elem[0].scrollIntoView('smooth');
    },
  },

};
