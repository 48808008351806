var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "compare-page-container" }, [
    _vm.showTitle && _vm.i18n.contentSearch.heading
      ? _c(
          "h1",
          {
            staticClass:
              "compare-page-heading m-xs-3 mx-md-5 px-md-2 py-md-2 ml-sm-5 mt-sm-0 mb-sm-4"
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.i18n.contentSearch.heading) + "\n    "
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "compare-result-page d-flex flex-column" },
      [
        _c(
          "div",
          {
            staticClass: "compare-tiles d-flex mx-xs-0 pt-xs-4 pt-sm-3 pt-md-5"
          },
          [
            _c("div", { staticClass: "empty-space" }),
            _vm._v(" "),
            _vm._l(_vm.productsCompareData.products, function(item, index) {
              return _c("vx-product-tile-vertical", {
                key: index,
                attrs: {
                  product: item,
                  showRemoveOption:
                    (_vm.productsCompareData.products.length > 2
                      ? true
                      : false) && _vm.showRemoveOption,
                  "is-favorite-visible": _vm.isFavorites,
                  "is-bazaar-voice": _vm.isBazaarVoice,
                  i18n: _vm.i18n.productTile,
                  showCertifications: true
                }
              })
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("vx-compare-table", {
          attrs: { productData: _vm.productsCompareData }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3885f55a", { render: render, staticRenderFns: staticRenderFns })
  }
}