var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-image-download" }, [
    _c("h4", { staticClass: "pt-xs-4" }, [
      _vm._v(_vm._s(_vm.i18n.imageDownloadDescription))
    ]),
    _vm._v(" "),
    _c("p", [_vm._v(_vm._s(_vm.i18n.imageDownloadcontent))]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group box-container" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.imageInfo.allImages,
            expression: "imageInfo.allImages"
          }
        ],
        attrs: {
          id: "downloadAllImage",
          type: "checkbox",
          "aria-label": _vm.i18n.allImageDownloadCheckbox
        },
        domProps: {
          checked: Array.isArray(_vm.imageInfo.allImages)
            ? _vm._i(_vm.imageInfo.allImages, null) > -1
            : _vm.imageInfo.allImages
        },
        on: {
          change: [
            function($event) {
              var $$a = _vm.imageInfo.allImages,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(_vm.imageInfo, "allImages", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.imageInfo,
                      "allImages",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.imageInfo, "allImages", $$c)
              }
            },
            _vm.downloadAllImageChange
          ]
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          attrs: {
            for: "downloadAllImage",
            "aria-hidden": _vm.isDeviceAndroid()
          }
        },
        [_vm._v(_vm._s(_vm.i18n.allImageDownloadCheckbox))]
      )
    ]),
    _vm._v(" "),
    _c("p", [_vm._v(_vm._s(_vm.i18n.allImageDownloadOption))]),
    _vm._v(" "),
    _c("div", { staticClass: "pt-xs-4 px-xs-0 col-sm-6 col-xs-12" }, [
      _c("h4", [_vm._v(_vm._s(_vm.i18n.imageDownloadFileFormat))]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("vx-radio-button-group", {
            ref: "format",
            attrs: {
              "radiobutton-values": _vm.formatOptions,
              name: "formatOptions"
            },
            model: {
              value: _vm.imageInfo.format,
              callback: function($$v) {
                _vm.$set(_vm.imageInfo, "format", $$v)
              },
              expression: "imageInfo.format"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pt-sm-4 px-xs-0 col-sm-6 col-xs-12" }, [
      _c("h4", [_vm._v(_vm._s(_vm.i18n.imageDownloadFileSize))]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("vx-radio-button-group", {
            ref: "size",
            attrs: {
              "radiobutton-values": _vm.sizeOptions,
              name: "sizeOptions"
            },
            model: {
              value: _vm.imageInfo.size,
              callback: function($$v) {
                _vm.$set(_vm.imageInfo, "size", $$v)
              },
              expression: "imageInfo.size"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-xs-12 px-xs-0 d-flex justify-content-center" },
      [
        _c(
          "button",
          {
            staticClass:
              "submit btn button-color text-trans modal-btn-primary-small",
            attrs: { type: "submit", disabled: _vm.errors.any() },
            on: {
              click: function($event) {
                _vm.downloadImagesZip()
              }
            }
          },
          [_vm._v(_vm._s(_vm.i18n.imageDownloadButton))]
        )
      ]
    ),
    _vm._v(" "),
    !_vm.globals.isGppro()
      ? _c(
          "div",
          {
            staticClass:
              "mt-xs-4 col-xs-12 d-flex flex-column align-items-center disclaimer modal-btn-IEspacing modal-btn-EDGEspacing"
          },
          [
            _c("p", [_vm._v(_vm._s(_vm.i18n.disclaimerLine1))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.i18n.disclaimerLine2))]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex" }, [
              _c("p", { staticClass: "disclaimer-line" }, [
                _vm._v(_vm._s(_vm.i18n.disclaimerLine3) + " "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.i18n.privacyPolicyLink,
                      "aria-label": _vm.i18n.disclaimerLine4
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.disclaimerLine4))]
                ),
                _vm._v(" " + _vm._s(_vm.i18n.disclaimerLine5))
              ])
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4d154f27", { render: render, staticRenderFns: staticRenderFns })
  }
}