var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-delete-list-modal" }, [
    _c("form", { ref: "deleteListModal" }, [
      _c(
        "div",
        { staticClass: "mb-md-5 mb-xs-4 form-group" },
        [
          !_vm.multipleLists
            ? _c("p", { attrs: { role: "text" } }, [
                _vm._v(_vm._s(_vm.i18n.deleteList.content))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.multipleLists
            ? _c("p", { attrs: { role: "text" } }, [
                _vm._v(_vm._s(_vm.i18n.deleteList.multipleListContent))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.selectedListsName, function(list) {
            return _c("div", { attrs: { role: "text" } }, [
              _vm._v(_vm._s(list))
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-center" }, [
        _c(
          "button",
          {
            staticClass:
              "btn button-color text-trans modal-btn-primary-small modal-btn-xs-sticky",
            attrs: { type: "button" },
            on: { click: _vm.deleteList }
          },
          [_vm._v(_vm._s(_vm.i18n.deleteList.delete))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9ba108c8", { render: render, staticRenderFns: staticRenderFns })
  }
}