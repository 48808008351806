import vxRadioButtonGroup from '../../common/vx-radio-button-group/vx-radio-button-group.vue';
// import shippingMethodDetails from './vx-single-shipping-method-mock';
import CheckoutService from '../../common/services/checkout-service';
import globals from '../../common/globals';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import {
  checkoutEventBus,
} from '../../../modules/event-bus';
import detectDeviceMixin from '../../common/mixins/detect-device-mixin';

export default {
  name: 'vx-single-shipping-method',
  mixins: [detectDeviceMixin],
  components: {
    vxRadioButtonGroup,
    vxSpinner,
  },
  props: {
    // Indicates if user us trying to change shipping method
    isEditable: Boolean,
    // Text coming from property file
    i18n: Object,
    // Checkout related data
    checkoutData: Object,
    // Indicates if user changed address
    addressChanged: Boolean,
  },
  data() {
    return {
      selectedShippingMethod: {
        value: '',
        instructions: '',
        deliveryDetails: '',
        label: '',
        name: '',
        cost: '',
      },
      shippingMethodDetails: {},
      deliveryMethods: [],
      checkoutService: new CheckoutService(),
      globals,
      dataLoaded: false,
    };
  },
  computed: {},
  watch: {
    'checkoutData.deliveryMode': function() {
      this.$nextTick(() => {
        this.showSavedData();
      });
    },
  },
  mounted() {
    // this.selectedShippingMethodData();
    this.getShippingMethod();
  },
  methods: {
    /**
     * This function triggers the call that gets shipping method
     */
    getShippingMethod() {
      this.checkoutService.getSingleMethod({}, this.handleGetMethodResponse, this.handleGetMethodError);
      this.$refs.spinner.showSpinner();
    },
    /**
     * This function handles the response of single shipping method call
     */
    handleGetMethodResponse(response) {
      this.$refs.spinner.hideSpinner();
      if (response && response.data) {
        this.shippingMethodDetails = response.data;
        this.createDeliveryMethodArray();
        this.showSavedData();
      }
    },
    /**
     * This function handles the error of single shipping method call
     */
    handleGetMethodError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * This function creates array for shipping method radio buttons
     */
    createDeliveryMethodArray() {
      this.shippingMethodDetails.deliveryModes.forEach((item) => {
        let label = '';
        if (this.globals.siteConfig.showDeliveryCost) {
          label = `${item.name} : ${item.deliveryCost.formattedValue}`;
        } else {
          label = item.name;
        }
        this.deliveryMethods.push({
          label,
          value: item.code,
          // deliveryDetails: item.description,
          name: item.name,
          cost: item.deliveryCost.formattedValue,
        });
      }); this.dataLoaded = true;
    },
    /**
     * This function sets data for shippingMethodDetails Object
     * @param  {Object} data shipping method array
     */
    setData(data) {
      this.selectedShippingMethod.label = data.label;
      this.selectedShippingMethod.value = data.value;
      // this.selectedShippingMethod.deliveryDetails = data.deliveryDetails;
      this.selectedShippingMethod.name = data.name;
      this.selectedShippingMethod.cost = data.cost;
    },
    /**
     * This function sets delivery mode
     */
    setValue() {
      let value = '';
      if (this.shippingMethodDetails.deliveryMode) {
        value = this.shippingMethodDetails.deliveryMode.code;
      }
      return value;
    },
    /**
     * This function sets the lowest shipping method as default in the shipping method radio button
     * or set already saved data if present in the getShippingMethod call in the shipping method radio button
     */
    handleDefault() {
      if (this.selectedShippingMethod.value) {
        this.deliveryMethods.forEach((item) => {
          if (this.selectedShippingMethod.value === item.value) {
            this.$refs.shippingMethod.setSelectedByValue(item.value);
            this.setData(item);
          }
        });
      } else {
        this.$refs.shippingMethod.setSelectedByValue(this.deliveryMethods[0].value);
        this.setData(this.deliveryMethods[0]);
      }
    },
    /**
     * This function stores the shipping method from radio button values when user selectes one
     */
    handleSelected(event) {
      this.setData(event);
    },
    /**
     * This function shows already saved data if present in the getShippingMethod or checkout call
     * (if user refreshes the page after saving multiple shipping method section)
     */
    showSavedData() {
      if (this.checkoutData.deliveryMode && !this.addressChanged) {
        checkoutEventBus.$emit('show-saved-mode');
        // this.selectedShippingMethod.deliveryDetails = data.deliveryDetails;
        this.selectedShippingMethod.instructions = this.checkoutData.deliveryMode.description;
        const savedData = this.deliveryMethods.filter((method) => method.value === this.checkoutData.deliveryMode.code);
        if (savedData.length) {
          this.setData(savedData[0]);
        } else {
          this.selectedShippingMethod.name = this.checkoutData.deliveryMode.name;
          this.selectedShippingMethod.cost = this.checkoutData.deliveryMode.deliveryCost.formattedValue;
        }
      } else {
        checkoutEventBus.$emit('show-edit-mode');
      }
    },
    /**
     * This function saves single Shipping method
     */
    saveSingleMethod() {
      const requestConfig = {};
      requestConfig.params = {
        deliveryModeId: this.selectedShippingMethod.value,
        deliveryInst: this.selectedShippingMethod.instructions,
      };
      this.checkoutService.saveSingleMethod(requestConfig, this.handleSaveMethodResponse, this.handleSaveMethodError);
      this.$refs.spinner.showSpinner();
    },
    /**
     * This function handles response of save single Shipping method call
     */
    handleSaveMethodResponse() {
      checkoutEventBus.$emit('show-saved-mode');
      checkoutEventBus.$emit('update-cart', 'refresh');
      this.$refs.spinner.hideSpinner();
    },
    /**
     * This function handles error of save single Shipping method call
     */
    handleSaveMethodError() {
      this.$refs.spinner.hideSpinner();
    },
  },
};
