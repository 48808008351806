/**
 * Sub categories component
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-uiux-sub-categories',
  components: {},
  props: {
    imageTextData: Object,
    componentTheme: String,
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
    };
  },
  computed: {},
  mounted() {
    if (this.imageTextData.headerColor) {
      this.$refs.header.style.color = this.imageTextData.headerColor;
      if (this.$refs.header.childNodes[0] && this.$refs.header.childNodes[0].style) {
        this.$refs.header.childNodes[0].style.color = this.imageTextData.headerColor;
      }
    }
    if (this.imageTextData.ctaText && this.imageTextData.ctaLink && this.imageTextData.isExternalLink === true) {
      this.$refs.button.target = '_blank';
    }
    if (this.componentTheme) {
      if (this.componentTheme.indexOf('custom-desktop-wrapper-') > -1) {
        const componentThemeArray = this.componentTheme.split(' ');
        for (let i = 0; i < componentThemeArray.length; i += 1) {
          if (componentThemeArray[i].indexOf('custom-desktop-wrapper-') > -1) {
            const width = componentThemeArray[i].substring(23);
            const categoriesList = document.querySelector('.vx-uiux-sub-categories');
            categoriesList.style.maxWidth = `${width}px`;
            categoriesList.style.marginLeft = 'auto';
            categoriesList.style.marginRight = 'auto';
            break;
          }
        }
      }
      if (this.componentTheme.indexOf('max-columns-') > -1) {
        const componentThemeArray = this.componentTheme.split(' ');
        for (let i = 0; i < componentThemeArray.length; i += 1) {
          if (componentThemeArray[i].indexOf('max-columns-') > -1) {
            const numOfColumns = componentThemeArray[i].substring(12);
            const widthPercentagePerColumn = 100 / numOfColumns;
            document.querySelectorAll('.sub-category').forEach((category) => {
              // eslint-disable-next-line no-param-reassign
              category.style.width = `calc(${widthPercentagePerColumn}% -20px)`;
            });
            break;
          }
        }
      }
    }
  },
  methods: {},
};
