import vxMultipleShippingMethod from '../vx-multiple-shipping-method/vx-multiple-shipping-method.vue';
import vxSingleShippingMethod from '../vx-single-shipping-method/vx-single-shipping-method.vue';
import globals from '../../../../components/common/globals';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import {
  checkoutEventBus,
} from '../../../../modules/event-bus';
import detectDeviceMixin from '../../../../components/common/mixins/detect-device-mixin';
import checkoutTrackingMixin from '../../../../components/common/mixins/checkout-tracking-mixin';

export default {
  name: 'vx-shipping-method',
  mixins: [detectDeviceMixin, checkoutTrackingMixin],
  components: {
    vxMultipleShippingMethod,
    vxSingleShippingMethod,
    vxSpinner,
  },
  props: {
    // Text coming from property file
    i18n: Object,
    // Checkout related data
    checkoutData: Object,
    // Indicates if Multiple/Single Shipping is opted
    isShippingMultiple: {
      default: false,
      type: Boolean,
    },
    // Index of Shipping method section on checkout page
    sectionIndex: Number,
    // Indicates if split address is opted in shipping address section
    splitApplicable: Boolean,
    // Indicates if user changed address
    addressChanged: Boolean,
  },
  data() {
    return {
      isEditable: true,
      globals,
      savedFlag: false,
    };
  },
  computed: {},
  created() {
    // Emits that shipping method have been saved
    checkoutEventBus.$on('show-saved-mode', () => {
      this.isEditable = false;
      checkoutEventBus.$emit('section-complete', 'shippingMethod');
      checkoutEventBus.$emit('shipping-method-added');
      if (!this.savedFlag) {
        this.savedFlag = true;
        this.sendEventToDataLayer('checkout_option', 4, 'Save and Continue');
      }
    });
    // Emits when user tries to change shipping method
    checkoutEventBus.$on('show-edit-mode', () => {
      this.isEditable = true;
      checkoutEventBus.$emit('section-edit', 'shippingMethod');
      checkoutEventBus.$emit('shipping-method-edit');
    });
  },
  mounted() {
    this.sendEventToDataLayer('checkout', 4, 'Shipping Method');
  },
  methods: {
    /**
         * This function indicates that user is trying to change shipping method
         */
    editMethod() {
      this.sendEventToDataLayer('checkout', 4, 'Shipping Method');

      this.savedFlag = false;
      this.isEditable = true;
      checkoutEventBus.$emit('section-edit', 'shippingMethod');
      checkoutEventBus.$emit('shipping-method-edit');
    },
  },
};
