var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row vx-add-to-cart" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "col-xs-12 btn btn-primary add-to-cart-btn",
          on: {
            click: function($event) {
              _vm.addToCart($event)
            }
          }
        },
        [_vm._v(_vm._s(_vm.i18n.addToCart))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-352835fb", { render: render, staticRenderFns: staticRenderFns })
  }
}