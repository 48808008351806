var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-details-tab" }, [
    _c("div", { staticClass: "product-details-tab__container" }, [
      _c("div", { staticClass: "section row" }, [
        _c(
          "div",
          { staticClass: "top-left-section col-md-6 col-sm-6 col-xs-12" },
          [
            _c(
              "h3",
              { staticClass: "product-details-tab__topleftheading pl-md-4" },
              [_vm._v(_vm._s(_vm.i18n.topLeftHeading))]
            ),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "product-details-tab__topleftcontent pr-md-5 pr-sm-5 pr-xs-3 pl-md-4"
                },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.productDetailsTabData.description)
                    }
                  }),
                  _vm._v(" "),
                  _vm.productDetailsTabData.priceText
                    ? _c("span", { staticClass: "price-text" }, [
                        _vm._v(_vm._s(_vm.productDetailsTabData.priceText))
                      ])
                    : _vm._e()
                ]
              ),
              _vm.productDetailsTabData &&
              _vm.productDetailsTabData.gpCertifications
                ? _c(
                    "div",
                    { staticClass: "img-container pl-md-4 pt-md-4 pt-sm-4" },
                    [
                      _vm._l(
                        _vm.productDetailsTabData.gpCertifications,
                        function(product) {
                          return [
                            product.url
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: product.url,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      product.id
                                        ? _c("img", {
                                            staticClass:
                                              "imgBlock mr-sm-3 mb-sm-3",
                                            attrs: {
                                              src:
                                                product.gpcertificationsImage,
                                              alt: product.id
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              : _c("div", [
                                  product.id
                                    ? _c("img", {
                                        staticClass: "imgBlock mr-sm-3 mb-sm-3",
                                        attrs: {
                                          src: product.gpcertificationsImage,
                                          alt: product.id
                                        }
                                      })
                                    : _vm._e()
                                ])
                          ]
                        }
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("p")
            ])
          ]
        ),
        _vm._v(" "),
        _vm.globals.siteConfig.showProductFeatures
          ? _c(
              "div",
              { staticClass: "top-right-section col-md-6 col-sm-6 col-xs-12" },
              [
                _c(
                  "h3",
                  { staticClass: "product-details-tab__toprightheading" },
                  [_vm._v(_vm._s(_vm.i18n.topRightHeading))]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "product-details-tab__toprightcontent px-xs-3 py-xs-1"
                    },
                    _vm._l(_vm.productDetailsTabData.featureList, function(
                      productFeature,
                      index
                    ) {
                      return _c("li", [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(productFeature) }
                        })
                      ])
                    })
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.productDetailsTabData.hasOwnProperty("specifications") &&
        _vm.productDetailsTabData.specifications.length > 0 &&
        _vm.globals.siteConfig.showProductSpecs
          ? _c(
              "div",
              {
                staticClass:
                  "col-md-12 col-sm-12 col-xs-12 product-details-tab__pspecholder px-xs-0 pb-sm-5 pb-md-5 px-md-6"
              },
              [
                _c(
                  "h3",
                  {
                    staticClass:
                      "product-details-tab__bottomheading pl-xs-3 pl-sm-5 pl-md-0"
                  },
                  [_vm._v(_vm._s(_vm.i18n.bottomHeading))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "product-details-tab__pspeccontainer" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-container px-xs-0 px-sm-0 mx-xs-0 ml-xs-0"
                      },
                      _vm._l(_vm.productDetailsTabData.specifications, function(
                        productSpecData,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            staticClass:
                              "col-md-3 col-sm-6 col-xs-12 px-xs-0 px-sm-0 py-xs-3 py-sm-3 col onlybottomborder onlytopborder",
                            class: { onlyleftborder: index % 2 === 1 }
                          },
                          [
                            _c("vx-product-details-tab-specs", {
                              attrs: {
                                productDetailsSpecificationData: productSpecData,
                                indexNo: index
                              }
                            })
                          ],
                          1
                        )
                      })
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-34712fc8", { render: render, staticRenderFns: staticRenderFns })
  }
}