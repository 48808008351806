/**
 * Banner with two tiles on bottom right hand side
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-uiux-text-side-subcats',
  components: {},
  props: {
    imageTextData: {
      type: Object,
    },
    componentTheme: String,
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
    };
  },
  computed: {},
  mounted() {
    const backgroundImageURL = this.getResponsiveImage(this.imageTextData.imageData.imageSrcD, this.imageTextData.imageData.imageSrcT, this.imageTextData.imageData.imageSrcM);
    this.$el.style.background = `url("${backgroundImageURL}") no-repeat center rgba(0, 0, 0, 0.5)`;
    this.$el.style.backgroundSize = 'cover';
    if (this.imageTextData.headerColor) {
      this.$refs.header.style.color = this.imageTextData.headerColor;
      if (this.$refs.header.childNodes[0] && this.$refs.header.childNodes[0].style) {
        this.$refs.header.childNodes[0].style.color = this.imageTextData.headerColor;
      }
    }
    if (this.imageTextData.textColor) {
      this.$refs.description.style.color = this.imageTextData.textColor;
    }
    if (this.imageTextData.ctaColor) {
      this.$refs.button.style.color = this.imageTextData.ctaColor;
      this.$refs.button.style.backgroundColor = 'transparent';
      this.$refs.button.style.border = `3px solid ${this.imageTextData.ctaColor}`;
    }
  },
  methods: {
    goToLink(link) {
      if (link) {
        window.location.href = link;
      }
    },
  },
};
