var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-sbs p-xs-3 row d-flex flex-xs-column flex-sm-row",
      style: {
        backgroundColor: _vm.sbsData.sbsGlobalBG,
        backgroundImage: _vm.setBackGroundImage(
          _vm.sbsData.sbsGlobalBgImgD,
          _vm.sbsData.sbsGlobalBgImgT,
          _vm.sbsData.sbsGlobalBgImgM
        )
      },
      attrs: { id: _vm.sbsData.componentId, "aria-label": [_vm.backgroundAlt] }
    },
    _vm._l(_vm.sbsData.sbsMktgData, function(data, index) {
      return _c(
        "div",
        {
          staticClass: "col-xs-12 col-sm-6 p-xs-3",
          class:
            data.sbsBody && data.sbsTitle
              ? "d-flex flex-xs-column flex-sm-row"
              : ""
        },
        [
          data.sbsImage
            ? _c("a", {
                staticClass: "c-sbs__img-wrapper",
                style: _vm.backgroundImage(data, index),
                attrs: {
                  href: data.isExternalImage
                    ? data.imageLink
                    : _vm.globals.getUrlWithContextPath(data.imageLink),
                  target: data.isExternalImage ? "_blank" : "",
                  "aria-label": _vm.getBackgroundAlt(data, index)
                }
              })
            : _vm._e(),
          _vm._v(" "),
          data.sbsBody || data.sbsTitle
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-xs-column text-xs-center p-xs-4 w-xs-100",
                  style: { backgroundColor: data.sbsBodyBg }
                },
                [
                  _c("h3", {
                    staticClass: "mt-xs-0",
                    style: { color: data.sbsTitleColor },
                    domProps: { innerHTML: _vm._s(data.sbsTitle) }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "c-sbs__text-wrapper mb-xs-3",
                    style: { color: data.sbsBodyTxtColor },
                    domProps: { innerHTML: _vm._s(data.sbsBody) }
                  }),
                  _vm._v(" "),
                  data.ctaLink && data.ctaText && !data.jumpToHtml
                    ? _c("a", {
                        staticClass: "c-sbs__learnMore",
                        style: { color: data.ctaColor },
                        attrs: {
                          href: data.isExternalLink
                            ? data.ctaLink
                            : _vm.globals.getUrlWithContextPath(data.ctaLink),
                          target: data.isExternalLink ? "_blank" : ""
                        },
                        domProps: { innerHTML: _vm._s(data.ctaText) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  data.ctaLink && data.ctaText && data.jumpToHtml
                    ? _c("a", {
                        staticClass: "c-sbs__learnMore",
                        style: { color: data.ctaColor },
                        attrs: {
                          href: data.isExternalLink
                            ? data.ctaLink
                            : _vm.globals.getUrlWithContextPath(data.ctaLink),
                          target: data.isExternalLink ? "_blank" : ""
                        },
                        domProps: { innerHTML: _vm._s(data.ctaText) },
                        on: {
                          click: function($event) {
                            _vm.emitScrollSignal(data.ctaLink)
                          }
                        }
                      })
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      )
    })
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0fb2b321", { render: render, staticRenderFns: staticRenderFns })
  }
}