var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.singleProductData
    ? _c(
        "div",
        { staticClass: "vx-reorder-product" },
        [
          _c("vx-spinner", {
            ref: "spinner",
            attrs: {
              image: _vm.globals.assetsPath + "images/spinner.gif",
              "full-screen": ""
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "col-xs-12 btn btn-primary add-to-cart-btn",
              attrs: {
                title: _vm.i18n.tooltip,
                disabled: _vm.singleProductData.product.notPurchasable
              },
              on: {
                click: function($event) {
                  _vm.handleAddToCart($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.pluralizeReorderButton))]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-660dfa9b", { render: render, staticRenderFns: staticRenderFns })
  }
}