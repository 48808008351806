const searchBrowse = {
    comparePanel: {
        panelMessage1: 'Compare up to ',
        panelMessage2: 'products',
        primaryButtonText: 'View Details',
        compareProductsA11y: 'compare products',
        label: {
            remove: 'Remove',
        },
        iconXCircleTitle: 'Error',
        iconXTitle: 'Close',
    },
    autoSuggest: {
        heading: 'Suggested Results',
        viewAllLinkText: 'View all',
        iconXTitle: 'Close',
        iconSearchTitle: 'Search',
        allySearchLabel: 'Search For Product',
    },
    compareResultPage: {
        heading: 'Product Comparison',
    },
    contentSearch: {
        headingPartA: 'Search Result for',
        subHeadingPartA: 'Your search for',
        subHeadingPartB: 'returned',
        subHeadingPartC: 'results',
        allResults: 'All Results'
    },
    productTile: {
        label: {
            compare: 'Compare',
            select: 'Select',
            remove: 'Remove',
            addToCart: 'Add to Cart',
            findAStore: 'Find a Store',
            notifyMe: 'Notify Me',
            productCode: 'ITEM ID',
            productCodeA11y: 'Item ID:',
            chooseOption: 'Choose Option',
            a11ySlashedPrice: 'Slashed Price',
            a11yCurrentPrice: 'Current Price',
            quantity: 'Quantity',
            asmHeading: 'ASM Alert',
            asmMessage: 'Please click Start Session button to start guest session'
        },
        notifyMeHeading: 'Notify Me',
        notifyMeContent:
            'Please add the email address you would like to be notified with when this product becomes available.',
        emailAddress: 'Email Address',
        asmHeading: 'ASM Alert',
        asmMessage: 'Please click Start Session button to start guest session',
        submit: 'Submit',
        notifyMeResponse: 'Recipient will be informed when in Stock',
        notifyError: 'Please enter in a valid Email Address.',
        selectListHeading: 'Select a List',
        createNewListLabel: 'Create New List',
        save: 'Save',
        selectListResponse: 'Item added to List',
        emptyListError: 'Please enter in a valid New List Name.',
        saveAListError: 'Existing List has already been selected.',
        existingListError:
            'List Name is already in use. Please enter in a New List Name.',
        comingSoonBanner: 'Coming Soon',
        inStock: 'In Stock',
        outOfStock: 'Not Available Online',
        lowInventoryMessage: 'Low Inventory',
        writeReview: 'Write a review',
        leftInStock: ' left in stock',
        only: 'Only ',
        discontinued: 'Discontinued',
        replacedBy: 'and Replaced By Similar Product',
        submitLabel: 'SUBMIT',
        availableSoon: 'Available Soon',
        add: 'ADD',
        maxPurchaseableQuantityErrorCode: '197',
        maxPurchaseableQuantityErrorMessage: 'One of the product in the cart exceeds Maximum allowable quantity. The quantity is updated to meet the threshold.',
        maxPurchaseableQuantityUpdateMessage1: 'Maximum order quantity for this item is',
        maxPurchaseableQuantityUpdateMessage2: 'your cart has been automatically updated.',
        lowStockErrorCode: '198',
        lowStockErrorMessage: 'One of the product in the cart is running low on stock only available quantity is added to your cart',
        iconXCircleTitle: 'Error',
        iconAddToListTitle: 'Add to list',
        iconCertificationTitle: 'Green Certified',
        iconShippingTitle: 'Free Shipping Available',
        iconOnlineTitle: 'Only Available Online',
        iconSubscriptionTitle: 'Subscribe',
        iconSeasonalTitle: 'Seasonal Product',
        iconSavedToFavoriteTitle: 'Saved To Favorites',
        iconSaveToFavoriteTitle: 'Save To Favorites',
        maxValueUpdatedStatus: 'Requested quantity not available. Cart updated with max quantity in stock.',
        mfg: 'MFG',
        cust: 'CUST',
        productAvailability: 'Available for this location',
        averageRatingLabel: 'Average Rating',
        totalReviewsLabel: 'Total Reviews',
        guestList: 'Guest List',
        productIconsTitle: {
            freeShipping: 'Free Shipping Eligible',
            onlineOnly: 'Only Available Online',
            subscribable: 'Subscription Eligible',
            seasonal: 'Seasonal Product',
            bundleAvailable: 'Bundle Eligible',
            certification: 'Certifications Available',
            customisation: 'Customizable Product',
            installation: 'Installation Service Available',
            sample: 'Sample Eligible',
        },
        noProductName: 'no product name',
        notPurchasable: 'Not Purchasable',
        addToCartSuccessMessage: 'item(s) have been added to your cart',
        addListToCartError: 'One or more products were not added to your cart due to stock availability',
    },
    searchResult: {
        clearAllText: 'Clear All',
        countUnit: 'Items',
        searchResults: 'Search results for',
        refineResults: 'Refine Results',
        noCategoryResuts: {
            heading: 'Oops, something went wrong!',
            message1:
                'No Products are available for Category, Please select the other Category',
            message2:
                'If you need immediate assistance wirth anything, please contact GPXpress 877-GPXPRESS(479-7737).',
            message3:
                'Sorry, we could not find any results that fit this criteria.'
        },
        noResultMessage:
            'Sorry, we could not find any results for your search term. Please make sure all words are spelled correctly or try different keywords',
        sortPrefix: 'Sort By:',
        iconFilterTitle: 'Filter',
        iconXTitle: 'Close',
        averageRatingLabel: 'Average Rating',
        totalReviewsLabel: 'Total Reviews',
        facetUpLabel: ' & Up',
        contentTab: 'Content & Documents',
        products: 'Products',
        productsSelected: 'Selected',
        selectAll: 'Select All',
        selectAllTitle:
            'Use the Select All checkbox to share all products, add all products to a list, or add all products to the cart.',
        share: 'SHARE',
        compare: 'COMPARE',
        addToList: 'ADD TO LIST',
        addToCart: 'ADD TO CART',
        orderSample: 'ORDER SAMPLE',
        filterPanel: 'Filter Panel',
        addToCartSuccess: 'Products are added successfully to cart.',
        addToListSuccess: 'items have been added to the following list:',
        addToCartError:
            'Some of the selected products are currently not available and cannot be added to the cart.',
        bulkAddToCartError:
            'All of the selected products are currently not available and cannot be added to the cart.',
        guestList: 'Guest List',
        selectAList: {
            selectListHeading: 'Select a List',
            createNewListLabel: 'Create New List',
            save: 'Save',
            selectListResponse: 'Item added to List',
            emptyListError: 'Please enter in a valid New List Name.',
            saveAListError: 'Existing List has already been selected.',
            existingListError:
                'List Name is already in use. Please enter in a New List Name.',
        },
        shareItemModal: {
            shareItemHeading: 'Share Item',
            content:
                'Fill out the information below to send an email with information about this product.',
            recipientEmail: 'Recipient Emails*',
            helperText: 'Separate multiple email addresses with a comma.',
            senderName: 'Sender' + 's Name*',
            senderEmail: 'Sender' + 's Email Address*',
            a11ySenderOptionalMsgLabel: 'Please Enter Optional Message',
            subject: 'Subject*',
            message: 'Message (optional)',
            attachPDF: 'Include product detail PDF',
            addLink: 'Include links to additional product information',
            disclaimerLine1:
                'For consumers and customers located in the European Economic Area (EEA):',
            disclaimerLine2:
                'Georgia-Pacific subscribes to the Safe Harbor privacy principles.',
            disclaimerLine3: 'Please visit our ',
            disclaimerLine4: 'Privacy Policy ',
            disclaimerLine5: 'to learn how you can exercise and amend your choices.',
            privacyPolicyLink:
                'https://www.gp.com/legal/legal-notices/privacy-notice',
            sendEmail: 'SEND EMAIL',
            recipientEmailValidation: 'Please enter a valid Email Address',
            senderNameRequired: 'Please enter in a valid Sender\'s Name.',
            senderNameRegexError: 'Please enter in a valid Sender\'s Name',
            senderEmailRequired: 'Please enter in a valid Sender\'s Email Address',
            subjectRequired: 'Please enter in a Subject.',
            shareItemResponse: 'Product Shared Successfully',
            notifyMeHeading: 'Notify Me',
            notifyMeContent:
                'Please add the email address you would like to be notified with when this product becomes available.',
            emailAddress: 'Email Address',
            add: 'ADD',
        },
        shareModal: {
            title: 'Share',
            viewButton: 'View',
            downloadButton: 'Download',
            senderNameLabel: 'Name',
            senderEmailLabel: 'From Email Address',
            linkLabel: 'Link',
            emailLabel: 'Email to',
            copyLabel: 'Copy me',
            commentLabel: 'Add a comment',
            submitLabel: 'Send',
            error: {
                emailToRequired: 'Please enter the Email Address',
                emailToError: 'Please enter in a valid Email Address',
                senderNameRequired: 'Please enter the Name',
                senderNameError: 'Please enter in a valid Name',
                emailFromRequired: 'Please enter the Email Address',
                emailFromError: 'Please enter in a valid Email Address',
            },
            shareResourceSuccessMsg: 'Resource Shared Successfully',
            shareCartWithoutSelection: 'Nothing was selected to share.',
        },
    },
    crossReferenceSearch: {
        heading: 'Cross Reference Search',
        categoryLabel: 'Select Category',
        subCategoryLabel: 'Select Sub Category',
        searchLabel: 'Search by MFG Item #',
        mfgText: 'Mfg:',
        suggestText: 'Suggested Replacements',
        searchErrorMessage:
            'Sorry, your search resulted in no cross-reference items',
        itemId: 'ITEM ID',
        cmir: 'CMIR',
        noReplacementProdcutsMessage: 'Sorry, no suggested replacements available.',
        viewDetails: 'View Details',
        findDistributor: 'Find a distributor',
        selectOptionLabel: 'Select Option',
    }
};
export default searchBrowse;