var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-details-section d-flex pt-xs-3 pb-xs-3 pb-sm-4" },
    [
      _c(
        "div",
        { staticClass: "vx-details-section__info" },
        [
          _vm._t("info-section", [
            _vm.isUnitDetails
              ? _c(
                  "h3",
                  {
                    staticClass:
                      "vx-details-section__info-heading my-xs-0 pb-xs-6"
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.detailsData.displayData["Company Name"]) +
                        " " +
                        _vm._s(_vm.i18n.heading) +
                        "\n            "
                    )
                  ]
                )
              : _c(
                  "h3",
                  {
                    staticClass:
                      "vx-details-section__info-heading my-xs-0 pb-xs-4"
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.i18n.heading) +
                        "\n            "
                    )
                  ]
                ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-details-section__info-body d-flex flex-wrap" },
              [
                _vm._l(_vm.detailsData.displayData, function(info, label) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-details-section__info-block pb-xs-3 pb-sm-0 word-wrap-all"
                      },
                      [
                        _c("h4", { staticClass: "my-xs-0 pb-xs-3 " }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(label) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          staticClass: "mb-xs-0 pb-xs-5",
                          domProps: {
                            innerHTML: _vm._s(
                              label === _vm.statusText && !_vm.currentPage
                                ? _vm.showStatus()
                                : info
                            )
                          }
                        })
                      ]
                    )
                  ]
                })
              ],
              2
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vx-details-section__buttons d-flex justify-content-start flex-column mt-xs-2 mt-sm-0"
        },
        [
          _vm.editButton
            ? _c(
                "button",
                {
                  staticClass: "btn btn-tertiary mb-xs-3 form-blue-button",
                  on: {
                    click: function($event) {
                      _vm.handleEdit($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.i18n.editText) + "\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.disableButton && _vm.detailsData.status
            ? _c(
                "button",
                {
                  staticClass:
                    "disable btn btn-default mb-xs-3 form-blue-button",
                  on: {
                    click: function($event) {
                      _vm.handleState($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.i18n.disableText) +
                      "\n        "
                  )
                ]
              )
            : _vm.disableButton && !_vm.detailsData.status
            ? _c(
                "button",
                {
                  staticClass:
                    "enable btn btn-default mb-xs-3 form-blue-button",
                  on: {
                    click: function($event) {
                      _vm.handleState($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.i18n.enableText) +
                      "\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.linkButton
            ? _c("a", { on: { click: _vm.handleLink } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.i18n.hyperlinkText) +
                    "\n        "
                )
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-46d0afe6", { render: render, staticRenderFns: staticRenderFns })
  }
}