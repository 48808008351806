/**
 * Search Browse Service provides services for Search Browse Module.
 */

import RootService from './root-service';
import globals from '../globals';

class SearchBrowseService extends RootService {
  /**
   * Notify Me service call is triggered when user clicks on Add button on Notify Me modal
   */
  notifyMe(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    const endpoint = `${globals.getRestUrl('notifyMe')}`;
    config.url = endpoint;
    this.patch(config, successCallback, errorCallback);
  }

  /**
   * Compare Results service call is triggered when user clicks Compare button
   * @param  {String}   query string of product codes who are selected for comparison
   * @param  {String}   pageType string of page type for comparisons
   */
  getCompareResults(requestConfig, successCallback, errorCallback, query, pageType) {
    const config = requestConfig;
    const endpoint = `${globals.getRestUrl('compareUrl')}${query}&pageType=${pageType}&fields=FULL`;
    config.url = endpoint;
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Autosuggest service call is triggered when user types the 3rd character in the autosuggest input field in the header
   */
  getAutosuggest(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    const endpoint = `${globals.getRestUrl('autoComplete')}`;
    config.url = endpoint;
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Categories get service call is to populate the dropdown next to input field in the header
   * @param  {String}   productCatalog string of product catalog
   * @param  {String}   rootCategory string of root category
   */
  getCategories(requestConfig, successCallback, errorCallback, productCatalog, rootCategory) {
    const config = requestConfig;
    const endpoint = `${globals.getRestUrl(
      'allCategoryUrl',
    )}/${productCatalog}/Online/categories/${rootCategory}`;
    config.url = endpoint;
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Search Results get service call is to populate the dropdown next to input field in the header
   * @param  {String}   query string of productsc
   * @param  {String}   userId string of user id
   */
  getSearchResults(requestConfig, successCallback, errorCallback, query, userId) {
    const config = requestConfig;
    config.headers = {
      pragma: 'no-cache',
    };
    const facetUrl = 'fields=FULL';
    if (query.indexOf('?') >= 0) {
      config.url = `${globals.getRestUrl('searchUrl') + query}&${facetUrl}`;
    } else {
      config.url = `${globals.getRestUrl('searchUrl') + query}?${facetUrl}`;
    }
    if (userId) {
      config.params = {
        userId,
        nocache: new Date().getTime(),
      };
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Add To Cart service call is triggered on click of add to cart button
   */
  addToCart(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    const endpoint = `${globals.getRestUrl('addProductToCart', 'cart')}`;
    config.url = endpoint;
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Save to List service call adds the product to list
   */
  saveAList(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('createList', 'user');
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Shopping List get service call which gives all the shopping list names of that user
   */
  getShoppingLists(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('getAllWishLists', 'user');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Delete Product from List service call which deletes product from the list
   */
  deleteCartItem(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('deleteListEntry', 'user');
    this.delete(config, successCallback, errorCallback);
  }

  /**
   * Add Bulk Entries to Cart service call which adds bulk products to cart
   */
  addBulkToCart(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('bulkAddToCart', 'cart');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Bulk Share service call which shares the multiple products
   */
  bulkShareItem(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('shareProduct');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Cross Reference Search service call which gives result for search category
   * @param  {String}   params string of search category
   */
  getCrossReferenceSearch(requestConfig, successCallback, errorCallback, params) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('crossReferenceSearch')}${params.selectedCategory}`;
    if (params.selectedSubCategory) {
      config.url += `/${params.selectedSubCategory}`;
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Cross Reference Search service call which gives result for search term
   * @param  {String}   params string of search term
   */
  crossReferenceSearchByText(requestConfig, successCallback, errorCallback, params) {
    const config = requestConfig;
    config.url = globals.getRestUrl('crossReferenceSearchByText');
    config.url = config.url.replace('{query}', params.searchTerm).replace('{site}', globals.getSiteId());
    this.get(config, successCallback, errorCallback);
  }
}

export {
  SearchBrowseService as
  default,
};
