var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-cart-suggestions" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.suggestionsData && _vm.suggestionsData.length > 0
        ? _c("vx-slider", {
            attrs: {
              "product-data": _vm.suggestionsData,
              title: _vm.title,
              "is-favorites": _vm.isFavorites,
              "slider-slides": _vm.sliderSlides,
              "is-bazaar-voice": _vm.isBazaarVoice,
              "search-browse-i18n": _vm.i18n,
              sliderSlidesOverwrite: true
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7889b0c4", { render: render, staticRenderFns: staticRenderFns })
  }
}