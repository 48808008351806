export default {
  name: 'vx-pagination',
  props: {
    pageNumberOnClick: {
      type: Function,
    },
    totalDataLength: {
      type: Number,
    },
    rowCount: {
      type: Number,
    },
  },
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      pageNeighbours: 2,
      LEFT_PAGE: '...',
      RIGHT_PAGE: '...',
      pagesList: [],
    };
  },
  methods: {
    initializePaginationData() {
      /**
       * totalNumbers: the total page numbers to show on the control
       * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
       */
      const totalNumbers = this.pageNeighbours * 2 + 3;
      const totalBlocks = totalNumbers + 2;

      if (this.totalPages > totalBlocks) {
        const startPage = Math.max(2, this.currentPage - this.pageNeighbours);
        const endPage = Math.min(
          this.totalPages - 1,
          this.currentPage + this.pageNeighbours,
        );
        let pages = this.range(startPage, endPage);

        /**
         * hasLeftSpill: has hidden pages to the left
         * hasRightSpill: has hidden pages to the right
         * spillOffset: number of hidden pages either to the left or to the right
         */
        const hasLeftSpill = startPage > 2;
        const hasRightSpill = this.totalPages - endPage > 1;
        const spillOffset = totalNumbers - (pages.length + 1);

        switch (true) {
          // handle: (1) < {5 6} [7] {8 9} (10)
          case hasLeftSpill && !hasRightSpill: {
            const extraPages = this.range(
              startPage - spillOffset,
              startPage - 1,
            );
            pages = [this.LEFT_PAGE, ...extraPages, ...pages];
            break;
          }

          // handle: (1) {2 3} [4] {5 6} > (10)
          case !hasLeftSpill && hasRightSpill: {
            const extraPages = this.range(endPage + 1, endPage + spillOffset);
            pages = [...pages, ...extraPages, this.RIGHT_PAGE];
            break;
          }

          // handle: (1) < {4 5} [6] {7 8} > (10)
          case hasLeftSpill && hasRightSpill:
          default: {
            pages = [this.LEFT_PAGE, ...pages, this.RIGHT_PAGE];
            break;
          }
        }
        this.pagesList = [1, ...pages, this.totalPages];
        return;
      }
      this.pagesList = this.range(1, this.totalPages);
    },
    range(from, to, step = 1) {
      let i = from;
      const range = [];

      while (i <= to) {
        range.push(i);
        i += step;
      }
      return range;
    },
    goToPage(pageNumber) {
      if (this.currentPage !== pageNumber) {
        this.currentPage = pageNumber;
        this.initializePaginationData();
        this.pageNumberOnClick(pageNumber);
      }
    },
    nextPage() {
      if (this.currentPage + 1 <= this.totalPages) {
        this.goToPage(this.currentPage + 1);
      }
    },
    previousPage() {
      if (this.currentPage - 1 !== 0) {
        this.goToPage(this.currentPage - 1);
      }
    },
  },
  mounted() {
    this.totalPages = Math.ceil(this.totalDataLength / this.rowCount);
    this.initializePaginationData();
  },
  watch: {
    totalDataLength() {
      this.totalPages = Math.ceil(this.totalDataLength / this.rowCount);
      this.initializePaginationData();
    },
  },
};
