var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-xs-3 vx-shipping-address row" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.dataLoaded
        ? [
            _c("div", { staticClass: "row mx-xs-0 shipping-address-title" }, [
              _c(
                "div",
                {
                  staticClass: "col-sm-6 px-xs-0",
                  class: {
                    "col-xs-12": _vm.isEditable,
                    "col-xs-8": _vm.isSaved
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "shipping-address-section-header" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "shipping-address-heading",
                          attrs: { role: "heading" }
                        },
                        [
                          _c("div", { staticClass: "section-header" }, [
                            _vm._v(
                              _vm._s(_vm.sectionIndex) +
                                ".\n              " +
                                _vm._s(
                                  _vm.i18n.shippingAddress
                                    .shippingAddressHeading
                                )
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-sm-6 px-xs-0",
                  class: {
                    "col-xs-12 mt-xs-3 mt-sm-0": _vm.isEditable,
                    "col-xs-4": _vm.isSaved
                  }
                },
                [
                  _vm.isEditable
                    ? _c("div", { staticClass: "shipping-method-type" }, [
                        _c(
                          "a",
                          { class: { disabled: _vm.disableShipToMultiple } },
                          [
                            _vm.isSingle
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "shipping-location-type d-flex"
                                  },
                                  [
                                    _vm.globals &&
                                    _vm.globals.siteConfig
                                      .isMultipleShippingEnabled &&
                                    !_vm.globals.getIsSubscription()
                                      ? _c(
                                          "span",
                                          {
                                            attrs: {
                                              tabindex: "0",
                                              role: "button",
                                              "aria-label":
                                                _vm.i18n.shippingAddress
                                                  .multiLocationHeading
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.toggleMethodType($event)
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !("button" in $event) &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter-space",
                                                    undefined,
                                                    $event.key,
                                                    undefined
                                                  )
                                                ) {
                                                  return null
                                                }
                                                _vm.toggleMethodType($event)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.i18n.shippingAddress
                                                  .multiLocationHeading
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.userLevel
                                      ? [
                                          this.savedAddresses.length !== 0 &&
                                          _vm.globals &&
                                          _vm.globals.siteConfig
                                            .isMultipleShippingEnabled &&
                                          !_vm.globals.getIsSubscription()
                                            ? _c(
                                                "span",
                                                { staticClass: "px-xs-3" },
                                                [_vm._v("|")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          this.savedAddresses.length !== 0
                                            ? _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    tabindex: "0",
                                                    role: "button"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.addNewAddressSingle(
                                                        $event
                                                      )
                                                    },
                                                    keyup: function($event) {
                                                      if (
                                                        !("button" in $event) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter-space",
                                                          undefined,
                                                          $event.key,
                                                          undefined
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      _vm.addNewAddressSingle(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.i18n.shippingAddress
                                                        .add
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isMultiple
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "shipping-location-type d-flex"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          tabindex: "0",
                                          role: "button",
                                          "aria-label":
                                            _vm.i18n.shippingAddress
                                              .singleLocationHeading
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.toggleMethodType($event)
                                          },
                                          keyup: function($event) {
                                            if (
                                              !("button" in $event) &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter-space",
                                                undefined,
                                                $event.key,
                                                undefined
                                              )
                                            ) {
                                              return null
                                            }
                                            _vm.toggleMethodType($event)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.i18n.shippingAddress
                                              .singleLocationHeading
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.userLevel
                                      ? [
                                          _c(
                                            "span",
                                            { staticClass: "px-xs-3" },
                                            [_vm._v("|")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                tabindex: "0",
                                                role: "button"
                                              },
                                              on: {
                                                click: _vm.addNewAddressMultiple
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.shippingAddress.add
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSaved
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "shipping-method-change d-flex justify-content-end"
                        },
                        [
                          _c("a", [
                            _c(
                              "span",
                              {
                                staticClass: "shipping-location-type",
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label": _vm.i18n.shippingAddress.change
                                },
                                on: {
                                  click: function($event) {
                                    _vm.editMethod($event)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.editMethod($event)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.i18n.shippingAddress.change))]
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _vm.isMultiple && _vm.isEditable
              ? _c(
                  "div",
                  {
                    staticClass: "sub-heading-message mt-xs-3 col-xs-12 px-xs-0"
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.i18n.shippingAddress
                            .multipleShippingAddressSubHeading
                        )
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isMultiple && _vm.isSaved
              ? _c(
                  "div",
                  { staticClass: "sub-heading mt-xs-2 col-xs-12 px-xs-0" },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.i18n.shippingAddress.shippedMultiple)
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isSingle
              ? _c(
                  "div",
                  { staticClass: "col-xs-12 px-xs-0" },
                  [
                    _c("vx-single-shipping-address", {
                      ref: "singleShipping",
                      attrs: {
                        "is-guest": _vm.isGuest,
                        "pallet-shipment": _vm.isPalletShipment,
                        "visible-entries": _vm.visibleEntries,
                        i18n: _vm.i18n,
                        "user-addresses": _vm.savedAddresses,
                        "user-level": _vm.userLevel
                      },
                      on: {
                        "call-get-addresses": _vm.getAddressesRequest,
                        "call-guest-get-addresses":
                          _vm.getGuestAddressesRequest,
                        addressSaved: _vm.onAddressSaved,
                        "is-shipping-multiple": _vm.findShippingType
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isMultiple
              ? _c(
                  "div",
                  { staticClass: "col-xs-12 px-xs-0" },
                  [
                    _c("vx-multi-shipping-address", {
                      ref: "multiShipping",
                      attrs: {
                        "pallet-shipment": _vm.isPalletShipment,
                        "is-guest": _vm.isGuest,
                        "visible-entries": _vm.visibleEntries,
                        "user-addresses": _vm.savedAddresses,
                        i18n: _vm.i18n,
                        isShippingMultiple: _vm.isShippingMultiple
                      },
                      on: {
                        "call-get-addresses": _vm.getAddressesRequest,
                        "call-guest-get-addresses":
                          _vm.getGuestAddressesRequest,
                        addressSaved: _vm.onAddressSaved
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9caaee90", { render: render, staticRenderFns: staticRenderFns })
  }
}