import {
  ProductAvailability,
} from './vx-enums';

const ctaLogicMixin = {
  data() {
    return {
      ProductAvailability,
    };
  },
  methods: {
    // function to handle the notify CTA
    shouldNotifymeDisplayedForProduct(pdpProductInfo) {
      if (pdpProductInfo.stock) {
        // if product is out-of-stock
        if (this.isOutOfStock(pdpProductInfo)) {
          // if product is purchasable
          if (!pdpProductInfo.notPurchasable) {
            return (this.isProductActive(pdpProductInfo) || this.isPrediscontinued(pdpProductInfo) || this.isComingSoon(pdpProductInfo));
          }
        }
      }
      return false;
    },
    // function to handle disabling Add to cart
    shouldAddTocartDisabledForProduct(pdpProductInfo) {
      if (pdpProductInfo.stock) {
        if (this.isInStock(pdpProductInfo)) { // if product is in stock
          return this.handleInStockCasesForDiabledCart(pdpProductInfo);
        } if (this.isOutOfStock(pdpProductInfo)) { // if product is out-of-stock
          return this.handleOutofstockCasesForDisabledCart(pdpProductInfo);
        }
      }
      return false;
    },
    // function to handle displaying Add to cart
    shouldAddtocartDispalyedForProduct(pdpProductInfo) {
      if (pdpProductInfo.stock) {
        // if product is in stock
        if (this.isInStock(pdpProductInfo)) {
          return this.handleInStockCases(pdpProductInfo);
        } if (this.isOutOfStock(pdpProductInfo)) { // if product is out-of-stock
          return this.handleOutofstockCases(pdpProductInfo);
        }
      }
      return false;
    },
    isObsolete(pdpProductInfo) {
      return pdpProductInfo.materialStatus === this.ProductAvailability.OBSOLETE;
    },
    isInStock(pdpProductInfo) {
      return pdpProductInfo.stock.stockLevelStatus === this.ProductAvailability.IN_STOCK;
    },
    isOutOfStock(pdpProductInfo) {
      return pdpProductInfo.stock.stockLevelStatus === this.ProductAvailability.OUT_OF_STOCK;
    },
    isComingSoon(pdpProductInfo) {
      return pdpProductInfo.materialStatus === this.ProductAvailability.COMING_SOON;
    },
    isProductActive(pdpProductInfo) {
      return pdpProductInfo.materialStatus === this.ProductAvailability.ACTIVE;
    },
    isPrediscontinued(pdpProductInfo) {
      return pdpProductInfo.materialStatus === this.ProductAvailability.PREDISCONTINUED;
    },
    handleInStockCases(pdpProductInfo) {
      if (pdpProductInfo.notPurchasable) { // if not purchasable
        return (this.isProductActive(pdpProductInfo) || this.isPrediscontinued(pdpProductInfo) || this.isObsolete(pdpProductInfo) || this.isComingSoon(pdpProductInfo));
      } if (!pdpProductInfo.notPurchasable) { // if purchasable
        return (this.isProductActive(pdpProductInfo) || this.isPrediscontinued(pdpProductInfo) || this.isObsolete(pdpProductInfo) || this.isComingSoon(pdpProductInfo));
      }
      return false;
    },
    handleOutofstockCases(pdpProductInfo) {
      // if not purchasable
      if (pdpProductInfo.notPurchasable) {
        return (this.isProductActive(pdpProductInfo) || this.isPrediscontinued(pdpProductInfo) || this.isObsolete(pdpProductInfo) || this.isComingSoon(pdpProductInfo));
      } if (!pdpProductInfo.notPurchasable) { // if purchasable
        return (this.isObsolete(pdpProductInfo));
      }
      return false;
    },
    handleInStockCasesForDiabledCart(pdpProductInfo) {
      // if not purchasable
      if (pdpProductInfo.notPurchasable) {
        return (this.isProductActive(pdpProductInfo) || this.isPrediscontinued(pdpProductInfo) || this.isObsolete(pdpProductInfo) || this.isComingSoon(pdpProductInfo));
      } if (!pdpProductInfo.notPurchasable) {
        return (this.isObsolete(pdpProductInfo) || this.isComingSoon(pdpProductInfo));
      }
      return false;
    },
    handleOutofstockCasesForDisabledCart(pdpProductInfo) {
      // if not purchasable
      if (pdpProductInfo.notPurchasable) {
        return (this.isProductActive(pdpProductInfo) || this.isPrediscontinued(pdpProductInfo) || this.isObsolete(pdpProductInfo) || this.isComingSoon(pdpProductInfo));
      } if (!pdpProductInfo.notPurchasable) { // if purchasable
        return this.isObsolete(pdpProductInfo);
      }
      return false;
    },
  },
};

export default ctaLogicMixin;
