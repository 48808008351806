/**
 * Minimized header component will be used to display header with minimal options on Employee store brand.
 */
import globals from '../globals';

export default {
  name: 'vx-minimized-header',
  components: {},
  props: {
    /**
     * Image Name
     * @type {String}
     */
    imgUrl: {
      type: String,
      default: '',
    },
    /**
     * Image Alt Text
     * @type {String}
     */
    imgAltText: {
      type: String,
      default: '',
    },
    i18n: {
      type: Object,
    },
  },
  data() {
    return {
      globals,
    };
  },
  computed: {},
  methods: {},
};
