var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-subscriptions-landing" },
    [
      _c(
        "vx-modal",
        {
          ref: "cancelSubscriptionModal",
          attrs: {
            size: "medium",
            heading: _vm.i18n.cancelSubscription.heading
          }
        },
        [
          _c("vx-cancel-subscription", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n.cancelSubscription,
              subscriptionId: _vm.subscriptioncode
            },
            on: {
              "cancel-Subscription-success":
                _vm.handleCancelSubscriptionSuccess,
              "cancel-Subscription-error": _vm.handleCancelSubscriptionError,
              "keep-subscription": _vm.handleKeepSubscription
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "editSubscriptionModal",
          attrs: {
            size: "large",
            heading: _vm.i18n.subscriptionsLanding.editSubscription
          }
        },
        [
          _c("vx-edit-subscription", {
            attrs: {
              slot: "component",
              "subscription-entry": _vm.subscriptionEntryTemp,
              i18n: _vm.i18n
            },
            on: {
              "updated-subscription": function($event) {
                _vm.updatedSubscriptionSuccess($event)
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.subscriptionData &&
      _vm.subscriptionData.carts &&
      _vm.subscriptionData.carts.length
        ? [
            _c("h3", { staticClass: "subscription-length" }, [
              _vm._v(
                _vm._s(_vm.subscriptionData.carts.length) +
                  _vm._s(_vm.i18n.subscriptionsLanding.subscription)
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "subscriptions-container" },
              _vm._l(_vm.displayedSubscriptions, function(
                subscriptionEntry,
                key
              ) {
                return _c(
                  "div",
                  { staticClass: "product-tile-container" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "upper-info-section d-flex justify-content-between"
                      },
                      [
                        _c("div", { staticClass: "subscription-info d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "subscription-info-container" },
                            [
                              _c(
                                "div",
                                { staticClass: "subscription-heading" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.i18n.subscriptionsLanding
                                        .subscriptionID
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "info" }, [
                                _vm._v(_vm._s(subscriptionEntry.code))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          subscriptionEntry.entries[0].splitEntries.length
                            ? _c(
                                "div",
                                { staticClass: "subscription-info-container" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "subscription-heading" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.i18n.subscriptionsLanding.shipTo
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      ref: "subscribeAddressPopover",
                                      refInFor: true,
                                      staticClass: "info"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            subscriptionEntry.entries[0]
                                              .splitEntries[0].deliveryAddress
                                              .firstName
                                          ) +
                                          " " +
                                          _vm._s(
                                            subscriptionEntry.entries[0]
                                              .splitEntries[0].deliveryAddress
                                              .lastName
                                          ) +
                                          "\n                "
                                      ),
                                      _c("span", {
                                        staticClass:
                                          "icon-info popover-subscribe-address",
                                        attrs: {
                                          role: "button",
                                          tabindex: "0",
                                          "data-toggle": "popover",
                                          "data-content": _vm.getPopoverAddressContent(
                                            subscriptionEntry
                                          )
                                        },
                                        on: {
                                          mouseover: function($event) {
                                            _vm.openSubscriptionAddressTooltip()
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "subscription-info-container" },
                            [
                              _c(
                                "div",
                                { staticClass: "subscription-heading" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.i18n.subscriptionsLanding.status)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "info" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.handleSubscriptionStatus(
                                      subscriptionEntry.subscriptionCartStatus
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "subscription-icons" }, [
                          _c("span", {
                            staticClass: "icon-edit",
                            attrs: {
                              title: _vm.i18n.subscriptionsLanding.edit,
                              tabindex: "0",
                              role: "button",
                              "aria-label": _vm.i18n.subscriptionsLanding.edit
                            },
                            on: {
                              click: function($event) {
                                _vm.handleEditSubscription(
                                  $event,
                                  subscriptionEntry
                                )
                              },
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter-space",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                _vm.handleEditSubscription(
                                  $event,
                                  subscriptionEntry
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "icon-trash",
                            attrs: {
                              title: _vm.i18n.subscriptionsLanding.cancel,
                              "aria-label":
                                _vm.i18n.subscriptionsLanding.cancel,
                              tabindex: "0",
                              role: "button"
                            },
                            on: {
                              click: function($event) {
                                _vm.handleCancelSubscription(
                                  $event,
                                  subscriptionEntry
                                )
                              },
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter-space",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                _vm.handleCancelSubscription(
                                  $event,
                                  subscriptionEntry
                                )
                              }
                            }
                          })
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "vx-product-tile",
                      [
                        subscriptionEntry.nextActivationDate
                          ? _c("template", { slot: "upper-full-slot" }, [
                              _c("div", { staticClass: "next-shipment-info" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.i18n.subscriptionsLanding.nextShipment
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.formatDate(
                                        subscriptionEntry.nextActivationDate
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        subscriptionEntry.entries[0].product.images
                          ? _c("template", { slot: "image-slot" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.globals.getNavigationUrl("empty") +
                                      subscriptionEntry.entries[0].product.url
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.globals.getThumbnailImageUrl(
                                        subscriptionEntry.entries[0].product
                                          .images
                                      ),
                                      alt:
                                        subscriptionEntry.entries[0].product
                                          .images[2].altText
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              subscriptionEntry.entries[0].product
                                .materialStatus ===
                              _vm.ProductAvailability.COMING_SOON
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "coming-soon-status hidden-xs "
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.i18n.subscriptionsLanding
                                              .comingSoonLabel
                                          )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !subscriptionEntry.entries[0].product.images
                          ? _c("template", { slot: "image-slot" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.globals.getNavigationUrl("empty") +
                                      subscriptionEntry.entries[0].product.url
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.globals.assetsPath +
                                        "images/no_image.svg",
                                      alt: "no image"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              subscriptionEntry.entries[0].product
                                .materialStatus ===
                              _vm.ProductAvailability.COMING_SOON
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "coming-soon-status hidden-xs"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.i18n.subscriptionsLanding
                                              .comingSoonLabel
                                          )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("template", { slot: "details" }, [
                          _c("p", { staticClass: "product-title" }, [
                            _c("a", {
                              attrs: {
                                href:
                                  _vm.globals.getNavigationUrl("empty") +
                                  subscriptionEntry.entries[0].product.url
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  subscriptionEntry.entries[0].product.name
                                )
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _c("span", { staticClass: "item-id" }, [
                              _vm._v(
                                _vm._s(_vm.i18n.subscriptionsLanding.itemID) +
                                  "\n                "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    subscriptionEntry.entries[0].product.code
                                  )
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "product-info" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.i18n.subscriptionsLanding.quantityLabel
                                ) +
                                _vm._s(subscriptionEntry.entries[0].quantity)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "product-info" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.i18n.subscriptionsLanding
                                    .subscriptionLabel
                                ) +
                                _vm._s(
                                  subscriptionEntry.entries[0]
                                    .subscriptionFrequency.entry[0].key
                                ) +
                                "\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("template", { slot: "right-section-slot" }, [
                          _c("div", { staticClass: "product-price" }, [
                            subscriptionEntry.entries[0].product &&
                            subscriptionEntry.entries[0].product
                              .subscriptionPrice &&
                            subscriptionEntry.entries[0].product
                              .subscriptionPrice.formattedValue &&
                            !subscriptionEntry.entries[0].product.notPurchasable
                              ? _c(
                                  "p",
                                  {
                                    ref: "subscribeInfoPopover",
                                    refInFor: true,
                                    staticClass: "subscription-price-info"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          subscriptionEntry.entries[0].product
                                            .subscriptionPrice.formattedValue
                                        ) +
                                        "\n                "
                                    ),
                                    _c("span", {
                                      staticClass:
                                        "icon-info popover-subscribe-info",
                                      attrs: {
                                        role: "button",
                                        tabindex: "0",
                                        "data-content": _vm.getPopoverInfoContent(),
                                        "data-toggle": "popover"
                                      },
                                      on: {
                                        mouseover: function($event) {
                                          _vm.openSubscriptionInfoTooltip()
                                        }
                                      }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              })
            ),
            _vm._v(" "),
            _c("vx-paginate", {
              attrs: {
                "page-count": _vm.pagination.totalPages,
                "page-range": _vm.pagination.pageRange,
                "margin-pages": 2,
                "click-handler": _vm.paginationCallback,
                "prev-text": _vm.pagination.previous,
                "next-text": _vm.pagination.next,
                "container-class":
                  "pagination col-xs-12 d-flex justify-content-center",
                "page-class": "page-item"
              }
            })
          ]
        : [
            _c("div", { staticClass: "empty-table mx-sm-3 my-sm-5" }, [
              _c("div", [
                _c("p", { staticClass: "mb-xs-0" }, [
                  _vm._v(_vm._s(_vm.i18n.subscriptionsLanding.noSubscriptions))
                ])
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-95443936", { render: render, staticRenderFns: staticRenderFns })
  }
}