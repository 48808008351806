/* Handles split quantity logic and population of address drop-down */
import { Validator } from 'vee-validate';
import vxStepperControl from '../../common/vx-stepper-control/vx-stepper-control.vue';
import vxDropdownPrimary from '../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import globals from '../../common/globals';
import { checkoutEventBus } from '../../../modules/event-bus';
import flyoutBannerMixin from '../../common/vx-flyout-banner/vx-flyout-banner-mixin';
import mobileMixin from '../../common/mixins/mobile-mixin';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import vxAddEditAddress from '../../common/vx-add-edit-address/vx-add-edit-address.vue';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import ManageProfileShoppingListService from '../../common/services/manage-profile-shopping-lists-service';

import {
  myAccountAddressStatus,
} from '../../common/mixins/vx-enums';

export default {
  name: 'vx-split-address',
  components: {
    vxStepperControl,
    vxDropdownPrimary,
    vxModal,
    vxAddEditAddress,
    vxSpinner,
  },
  mixins: [mobileMixin, flyoutBannerMixin],
  props: {
    i18n: Object,
    // Saved addresses of user
    addresses: Array,
    // Product infortmation
    productDetails: Object,
    // Index of product in cart
    entryNumber: Number,
    // Flag for apply split logic
    splitApplicable: Boolean,
    // Indicates if Multiple/Single Shipping is opted
    isShippingMultiple: Boolean,
    // Default address
    defaultAddress: Object,
  },
  data() {
    return {
      isSplit: false,
      globals,
      selectedAddress: {
        label: '',
        value: {},
      },
      addressId: '',
      validationSuccess: true,
      shippingAddresses: [],
      productAddressObject: {},
      splitEntryObject: {},
      splitEntry: [],
      totalQuantity: 0,
      totalQuantityError: false,
      totalAddressError: false,
      noPhoneNumber: false,
      noCompanyName: false,
      shppingDetailsAddresses: {},
      indexSelected: 0,
      addressToEdit: {},
      updatedAddress: {},
      manageProfileShoppingListService: new ManageProfileShoppingListService(),
      editClickEvent: {},
      duplicateAddressError: false,
      quantityError: false,
      fullySplitError: false,
    };
  },
  computed: {},
  mounted() {
    this.addingNewEntry(
      this.productDetails.quantity,
      this.productDetails.product.code,
      this.productDetails.basePrice.value,
    );
    const veeCustomErrorMessage = {
      en: {
        custom: {
          selectedAddress: {
            required: 'Please select any address',
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
    checkoutEventBus.$on('restriction-error', () => {
      this.shippingAddresses = [];
    });
    this.handleSelectedAddresses();

    if (this.globals.isB2B()) {
      this.handleDropdownLabelB2B(this.addresses);
    } else if (this.globals.isB2C()) {
      this.handleDropdownLabelB2C(this.addresses);
    }
    this.handleSelectedAddresses();
  },
  methods: {
    /**
     * This function adds new split row
     * @param  {Number} quantity product quantity
     * @param  {Number} code product code
     * @param  {Number} price product price
     */
    addingNewEntry(quantity, code, price) {
      if (!this.isSplit) {
        this.splitEntry = [];
        this.productDetails.splitEntries = {};
      }
      this.splitEntryObject = {
        qty: quantity,
        productCode: code,
        price,
      };
      this.splitEntry.push(this.splitEntryObject);
      if (this.splitEntry.length === this.productDetails.quantity) {
        this.totalQuantityError = true;
      }
    },
    /**
     * This function sets dropdown value dynamically
     * @param  {Object} data address
     */
    setAddressDropdownValue(data) {
      if (
        this.$refs.shippingAddressDropdown
        && this.$refs.shippingAddressDropdown.length
        && this.$refs.shippingAddressDropdown.length !== 0
      ) {
        this.$refs.shippingAddressDropdown.forEach((item) => {
          const currentDropdownLabel = item.getDropdownLabel();
          if (currentDropdownLabel === this.i18n.filledDropdownLabel) item.setDropDownItem(data);
        });
      }
      this.selectedAddress = data;
      for (let i = 0; i < this.splitEntry.length; i += 1) {
        if (this.$refs.shippingAddressDropdown[i].getDropdownLabel() === 'Select option') {
          if (this.globals.isB2C()) {
            this.$refs.shippingAddressDropdown.setDropDownItem(this.selectedAddress);
          } else {
            this.$refs.shippingAddressDropdown[i].setDropDownItem(this.selectedAddress);
          }
          break;
        }
      }
    },
    /**
     * This function helps in splitting the quantity of product
     */
    splitTheQuantity() {
      if (this.splitEntry.length === 1) {
        const splitAmtCeil = Math.ceil(this.productDetails.quantity / 2);
        const splitAmtFloor = Math.floor(this.productDetails.quantity / 2);

        this.handleQuantity(
          this.splitEntry[0],
          0,
          splitAmtCeil,
        );
        this.addingNewEntry(
          splitAmtFloor,
          this.productDetails.product.code,
          this.productDetails.basePrice.value,
        );
      } else if (this.splitEntry.length < this.productDetails.quantity) {
        let splitSum = 0;
        for (let i = 0; i < this.splitEntry.length; i += 1) {
          splitSum += this.splitEntry[i].qty;
        }
        if (splitSum < this.productDetails.quantity) {
          this.addingNewEntry(
            this.productDetails.quantity - splitSum,
            this.productDetails.product.code,
            this.productDetails.basePrice.value,
          );
        } else {
          this.fullySplitError = true;
        }
      } else {
        this.totalQuantityError = true;
      }
      this.addQuantityCheck();
    },
    /**
     * This function splits the entry on toggling view mode
     */
    productSplitEntryData() {
      const shippingDetailsArray = [];
      const shippingAddressArray = [];
      let selectedItemData = {};
      let splitError = {};
      this.totalQuantity = 0;
      this.totalAddressError = false;
      this.noPhoneNumber = false;
      this.noCompanyName = false;
      const isB2B = this.globals.isB2B();
      for (let i = 0; i < this.splitEntry.length; i += 1) {
        if (this.globals.isB2C()) {
          selectedItemData = this.$refs.shippingAddressDropdown.selectedItem;
        } else {
          selectedItemData = this.$refs.shippingAddressDropdown[i].selectedItem;
        }
        if (this.$refs.stepperController && this.$refs.stepperController[i]) {
          this.splitEntry[i].qty = this.$refs.stepperController[i].counter;
        }
        this.totalQuantity += this.splitEntry[i].qty;
        if (selectedItemData && selectedItemData.label && selectedItemData.value && selectedItemData.value.phone && (!isB2B || (isB2B && selectedItemData.value.companyName))) {
          // Address details array
          this.shippingAddresses.push({
            productCode: this.productDetails.product.code,
            label: selectedItemData.label,
            value: selectedItemData.value,
            id: selectedItemData.value.id,
          });
          // Request Shipping details  Array
          shippingDetailsArray.push({
            country: selectedItemData.value.country,
            productCode: this.productDetails.product.code,
            region: selectedItemData.value.region,
          });
          // Request Shipping Address array with split entry
          shippingAddressArray.push({
            deliveryAddress: {
              id: selectedItemData.value.id,
            },
            price: this.splitEntry[i].price,
            productCode: this.splitEntry[i].productCode,
            qty: this.splitEntry[i].qty,
          });
          this.shppingDetailsAddresses = {
            productData: {
              entryNumber: this.entryNumber,
              splitEntry: shippingAddressArray,
            },
            addressData: shippingDetailsArray,
            allAddresses: this.shippingAddresses,
          };
        } else if (selectedItemData && selectedItemData.value && (!selectedItemData.value.phone || !selectedItemData.value.companyName)) {
          this.noPhoneNumber = !selectedItemData.value.phone;
          this.noCompanyName = !selectedItemData.value.companyName && this.globals.isB2B();
        } else {
          this.totalAddressError = true;
        }
      }
      if (this.totalQuantity !== this.productDetails.quantity) {
        this.quantityError = true;
      }
      if (this.totalAddressError || this.noPhoneNumber || this.noCompanyName || this.duplicateAddressError || this.quantityError) {
        splitError = {
          addressEmptyError: true,
        };
      }
      if (

        splitError && splitError.addressEmptyError
      ) {
        this.$emit('splitError', splitError);
      } else {
        return this.shppingDetailsAddresses;
      }
      return undefined;
    },
    /**
     * This function splits the address
     */
    splitAddresses(event) {
      this.handleDropdownLabelB2B();
      this.isSplit = true;
      checkoutEventBus.$emit('enable-split');
      this.splitTheQuantity();
      if (!this.selectedAddress.label) {
        this.selectedAddress.label = this.$refs.shippingAddressDropdown[0].setDropdownLabel(this.selectedAddress.label);
        this.selectedAddress.value = this.$refs.shippingAddressDropdown[0].setDropdownValue(this.selectedAddress.value);
      }
      this.$nextTick(() => {
        if (this.selectedAddress && this.selectedAddress.label && this.selectedAddress.value) {
          this.$refs.shippingAddressDropdown[0].dropdownOptionChange(this.selectedAddress, event);
        }
        this.addQuantityCheck();
      });
    },
    /**
     * This function deletes the row in split address
     * @param  {Number} index splitrow index
     */
    deleteSplitRow(index, event) {
      this.totalQuantityError = false;
      if (index >= 0 && this.splitEntry.length > 1) {
        this.splitEntry.splice(index, 1);
        if (this.splitEntry.length === 1) {
          this.$set(this.splitEntry, 0, {
            qty: this.productDetails.quantity,
            productCode: this.productDetails.product.code,
            price: this.productDetails.basePrice.value,
          });
          this.isSplit = false;
          checkoutEventBus.$emit('disable-split');
          const i = !index ? 1 : 0;
          this.selectedAddress = {};
          if (this.$refs.shippingAddressDropdown[i].getDropdownLabel() !== 'Select option') {
            this.selectedAddress.label = this.$refs.shippingAddressDropdown[i].getDropdownLabel();
            this.selectedAddress.value = this.$refs.shippingAddressDropdown[i].getDropdownValue();
          }
          this.$nextTick(() => {
            if (this.selectedAddress && this.selectedAddress.label && this.selectedAddress.value) {
              this.$refs.shippingAddressDropdown[0].dropdownOptionChange(this.selectedAddress, event);
            }
          });
        }
      } else if (index === 0 && this.splitEntry.length === 1) {
        this.splitEntry[index].qty = this.productDetails.quantity;
        this.isSplit = false;
        checkoutEventBus.$emit('disable-split');
      }
      this.addQuantityCheck();
      this.duplicateAddressError = this.hasDuplicateAddresses();
      this.fullySplitError = false;
    },
    /**
     * This function maps the shipping address
     */
    mappedShippingAddress() {
      if (this.shippingAddresses && this.shippingAddresses.length !== 0) {
        this.$emit('shippingAddresses', this.shippingAddresses);
        return this.productAddressObject;
      }
      this.$emit('shippingAddressesError', true);
      return undefined;
    },
    /**
     * This function handles the quantity
     * @param  {Object} item split entry
     * @param  {Number} index splitrow index
     * @param  {Number} itemQuantity quantity of product
     */
    handleQuantity(item, index, itemQuantity) {
      this.fullySplitError = false;
      this.splitEntry[index].qty = itemQuantity;
      this.addQuantityCheck();
    },
    /**
     * This function handles the dropdown label for b2c sites
     */
    handleDropdownLabelB2C() {
      if (this.addresses.length === 0) {
        this.$refs.shippingAddressDropdown.setDropdownLabel(this.i18n.splitAddress.emptyDropdownLabel);
      } else {
        this.$refs.shippingAddressDropdown.setDropdownLabel(this.i18n.splitAddress.filledDropdownLabel);
      }
    },
    /**
     * This function handles the dropdown label for b2b sites
     */
    handleDropdownLabelB2B() {
      // For B2B shippingAddressDropdown is an array
      this.$nextTick(() => {
        if (this.addresses.length === 0) {
          for (let i = 0; i < this.splitEntry.length; i += 1) {
            if (this.$refs.shippingAddressDropdown[i]) {
              this.$refs.shippingAddressDropdown[i].setDropdownLabel(this.i18n.splitAddress.emptyDropdownLabel);
            }
          }
        } else {
          for (let i = 0; i < this.splitEntry.length; i += 1) {
            if (this.$refs.shippingAddressDropdown[i]) {
              this.$refs.shippingAddressDropdown[i].setDropdownLabel(this.i18n.splitAddress.filledDropdownLabel);
            }
          }
        }
      });
    },
    /**
     * This function concatenates all the address details in a comma seperated format
     * @param  {Object} address address details
     */
    formAddressString(address) {
      let userAddress = '';
      let companyName = '';
      const name = `${address.firstName} ${address.lastName}`;
      if (address && address.companyName) {
        companyName = address.companyName;
      }
      if (address) {
        userAddress = [
          name,
          companyName,
          address.line1,
          address.line2,
          address.town,
          address.region.isocodeShort,
          address.postalCode,
          address.country.isocode,
        ]
          .filter(Boolean)
          .join(', ');
      }
      return userAddress;
    },
    handleSelectedAddresses() {
      if (this.isShippingMultiple) {
        if (this.productDetails.splitEntries.length) {
          this.productDetails.splitEntries.forEach((address, index) => {
            let addressObject = {};
            if (address.deliveryAddress.approvalStatus.toUpperCase() === myAccountAddressStatus.active) {
              addressObject = {
                label: this.formAddressString(address.deliveryAddress),
                value: address.deliveryAddress,
              };
            } else {
              this.$refs.shippingAddressDropdown[index].setDropdownLabel(this.i18n.filledDropdownLabel);
            }

            if (this.globals.isB2C()) {
              this.$refs.shippingAddressDropdown.setDropDownItem(addressObject);
            } else if (this.globals.isB2B()) {
              this.$nextTick(() => {
                if (this.$refs.shippingAddressDropdown[index]) {
                  this.$refs.shippingAddressDropdown[index].setDropDownItem(addressObject);
                }
              });
            }
          });
        } else if (this.productDetails && this.productDetails.splitEntries.length === 0) {
          this.settingDefaultAddress();
        }
      }
    },
    /**
     * This function sets the default address for a user
     */
    settingDefaultAddress() {
      if (this.defaultAddress && this.defaultAddress.value && this.defaultAddress.label) {
        for (let i = 0; i < this.splitEntry.length; i += 1) {
          if (this.globals.isB2C()) {
            this.$nextTick(() => {
              this.$refs.shippingAddressDropdown.setDropDownItem(this.defaultAddress);
            });
          } else {
            this.$nextTick(() => {
              this.$refs.shippingAddressDropdown[i].setDropDownItem(this.defaultAddress);
            });
          }
        }
      }
    },
    /**
     * This function handles the address change
     * @param  {Object} data address
     * @param  {Number} index splitrow index
     */
    handleAddressChange(data, index) {
      if (!this.hasDuplicateAddresses()) {
        this.indexSelected = index;
        this.validationSuccess = true;
        this.duplicateAddressError = false;
      } else {
        this.duplicateAddressError = true;
      }
    },

    editAddress(address, index) {
      this.indexSelected = index;
      this.addressToEdit = address.value;
      this.addressModalHeading = 'Edit Address';
      this.$refs.addNewAddressModal.open();
      // eslint-disable-next-line no-restricted-globals
      this.editClickEvent = event;
    },

    updatedAddr(data) {
      this.$refs.addNewAddressModal.close();
      const addressObj = {
        ...data.address.value,
        fromPage: 'my-account',
      };
      const requestConfig = {};
      requestConfig.data = addressObj;
      this.updatedAddress.label = this.formAddressString(data.address.value);
      this.updatedAddress.value = data.address.value;

      this.manageProfileShoppingListService.updateAddress(requestConfig, this.handleSaveAddressResponse, this.handleSaveAddressError, data.address.value.id);
    },

    handleSaveAddressError() {
      this.$refs.spinner.hideSpinner();
    },
    handleSaveAddressResponse() {
      this.$refs.spinner.hideSpinner();
      this.addressAvailable = true;
      this.dropdownError = false;
      for (let i = 0; i < this.addresses.length; i += 1) {
        if (this.addresses[i].value.id === this.updatedAddress.value.id) {
          this.addresses[i] = this.updatedAddress;
          this.$refs.shippingAddressDropdown[this.indexSelected || 0].dropdownOptionChange(this.updatedAddress, this.editClickEvent);
          break;
        }
      }
    },

    addQuantityCheck() {
      let totalItemQuantity = 0;
      for (let i = 0; i < this.splitEntry.length; i += 1) {
        if (this.splitEntry[i]) {
          totalItemQuantity += this.splitEntry[i].qty;
        }
      }
      let splitError = {};
      if (totalItemQuantity !== this.productDetails.quantity) {
        this.quantityError = true;
        splitError = {
          addressEmptyError: true,
        };
      } else {
        this.quantityError = false;
        splitError = {
          addressEmptyError: false,
        };
      }
      this.$emit('splitError', splitError);
    },

    hasDuplicateAddresses() {
      const labelArray = [];
      for (let i = 0; i < this.splitEntry.length; i += 1) {
        const currentLabel = this.$refs.shippingAddressDropdown[i].getDropdownLabel();
        if (labelArray.indexOf(currentLabel) >= 0 && currentLabel !== 'Select option') {
          return true;
        }
        labelArray.push(currentLabel);
      }
      return false;
    },

    /**
         * Function shows spinner while updating stepper control value
         */
    showSpinnerOnUpdate() {
      this.$refs.spinner.showSpinner();
    },
    /**
       * Function hides spinner after updating the stepper control value
       */
    hideSpinnerOnUpdate() {
      this.$refs.spinner.hideSpinner();
    },
  },
  watch: {
    indexSelected(data) {
      this.indexSelected = data;
    },
  },
};
