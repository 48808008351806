/* Business unit details page */
import {
  Validator,
} from 'vee-validate';
import ManageB2bOrgService from '../../../../components/common/services/manage-b2b-org-service';
import mb2bInfoDataMixin from '../../../../components/common/mixins/mb2b-info-data-mixin';
import {
  UserRoles,
  UnitDetails,
  UserDetails,
  userStates,
} from '../../../../components/common/mixins/vx-enums';
import globals from '../../../../components/common/globals';
import vxDetailsSection from '../vx-details-section/vx-details-section.vue';
import vxInfoSection from '../vx-info-section/vx-info-section.vue';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxCompanyDetails from '../../registration-login/vx-company-details/vx-company-details.vue';
import vxDropdownPrimary from '../../../../components/common/vx-dropdown-primary/vx-dropdown-primary.vue';
import detectDeviceMixin from '../../../../components/common/mixins/detect-device-mixin';
import vxUserForm from '../vx-user-form/vx-user-form.vue';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';

/**
 * Component displays the complete details about business unit.
 */

export default {
  name: 'vx-business-unit-detail',
  components: {
    vxDetailsSection,
    vxInfoSection,
    vxModal,
    vxCompanyDetails,
    vxDropdownPrimary,
    vxUserForm,
    vxSpinner,
  },
  mixins: [mb2bInfoDataMixin, detectDeviceMixin],
  props: {
    i18n: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      globals,
      userStates,
      businessUnitUsers: [],
      detailPageData: {
        unitDetailsData: {},
        detailsData: {
          displayData: {},
        },
        childGroupData: [],
        adminGroupData: [],
        buyerGroupData: [],
      },
      addedUserDetails: {
        firstName: '',
        lastName: '',
        uid: '',
        parentUnit: '',
        role: '',
        leaseSigner: true,
      },
      childUnitDetails: {
        companyName: '',
        parentUnit: {
          value: '',
          label: '',
        },
      },
      parentUnitsList: [],
      limitedParentUnitsList: [],
      isEmailExists: false,
      unitId: '',
      removedInfo: {},
      removedUser: {},
      removedUserData: [],
      existingAddedTo: '',
      addedExistingData: [],
      UserRoles,
      UnitDetails,
      UserDetails,
      manageB2bOrgService: new ManageB2bOrgService(),
      user: {
        country: {
          label: '',
          value: '',
        },
        firstName: '',
        lastName: '',
        uid: '',
      },
      emailExistsError: '',
      showInviteMsg: '',
      userDetailsUrl: '',
    };
  },
  computed: {
    /**
         * Filters the data based on the user role as ADMIN.
         */
    admins() {
      return this.detailPageData.unitDetailsData.administrators
        .filter((child) => child.roles[0] === UserRoles.ADMIN)
        .map((child) => child.uid);
    },
    /**
         * Filters the data based on the user role as CUSTOMER.
         */
    buyers() {
      return this.detailPageData.unitDetailsData.customers
        .filter((child) => child.roles[0] === UserRoles.CUSTOMER)
        .map((child) => child.uid);
    },
    /**
         * Checks whether the business unit has parent or not.
         */
    hasParent() {
      return this.detailPageData.unitDetailsData.parent !== undefined;
    },
    /**
         * Returns the unit name of the business unit.
         */
    unitName() {
      let name = '';
      if (
        this.detailPageData.unitDetailsData.addresses
                && this.detailPageData.unitDetailsData.addresses.length
      ) {
        name = this.detailPageData.unitDetailsData.addresses[0].companyName;
      } else {
        name = this.detailPageData.unitDetailsData.name;
      }
      return name;
    },
    /**
         * returns the formatted address from the data.
         */
    formattedAddress() {
      let address = '';
      if (this.detailPageData.unitDetailsData.addresses) {
        address = `${
          this.detailPageData.unitDetailsData.addresses[0].country
                    && this.detailPageData.unitDetailsData.addresses[0].country.isocode
            ? this.detailPageData.unitDetailsData.addresses[0].country.isocode
            : ''
        }<br>
      ${
  this.detailPageData.unitDetailsData.addresses[0].line1
    ? this.detailPageData.unitDetailsData.addresses[0].line1
    : ''
}<br>
      ${
  this.detailPageData.unitDetailsData.addresses[0].line2
    ? this.detailPageData.unitDetailsData.addresses[0].line2
    : ''
}<br>
      ${
  this.detailPageData.unitDetailsData.addresses[0].town
    ? this.detailPageData.unitDetailsData.addresses[0].town
    : ''
},
      ${
  this.detailPageData.unitDetailsData.addresses[0].region
    ? this.detailPageData.unitDetailsData.addresses[0].region.isocode
    : ''
} ${
  this.detailPageData.unitDetailsData.addresses[0].postalCode
    ? this.detailPageData.unitDetailsData.addresses[0].postalCode
    : ''
}`;
      }
      return address;
    },
    /**
         * Returns the phone number from address
         */
    unitInfo() {
      if (this.detailPageData.unitDetailsData.addresses) {
        return this.detailPageData.unitDetailsData.addresses[0].phone;
      }
      return undefined;
    },
    /**
         * Returns the unit details.
         */
    unitDetailI18n() {
      return {
        heading: this.i18n.detailsText,
        companyLabel: this.i18n.companyName,
        addressLabel: this.i18n.addressLabel,
        infoLabel: this.i18n.infoLabel,
        editText: this.i18n.editText,
        disableText: this.i18n.disableUnitText,
        enableText: this.i18n.enableUnitText,
      };
    },
    /**
         * Returns the child info i18n values.
         */
    childInfoI18n() {
      return {
        groupLabel: this.i18n.childInfogroupLabel,
        addText: this.i18n.addText,
        newText: this.i18n.newText,
        existingText: this.i18n.existingText,
        disabledText: this.i18n.disabledText,
        iconDeleteTitle: this.i18n.iconDeleteTitle,
      };
    },
    /**
         * Returns the admin info i18n values.
         */
    adminInfoI18n() {
      return {
        groupLabel: this.i18n.adminInfogroupLabel,
        addText: this.i18n.addText,
        newText: this.i18n.newText,
        existingText: this.i18n.existingText,
        iconDeleteTitle: this.i18n.iconDeleteTitle,
      };
    },
    /**
         * Returns the buyer info i18n values.
         */
    buyerInfoI18n() {
      return {
        groupLabel: this.i18n.buyerInfogroupLabel,
        addText: this.i18n.addText,
        newText: this.i18n.newText,
        existingText: this.i18n.existingText,
        iconDeleteTitle: this.i18n.iconDeleteTitle,
      };
    },
    addExistingHeading() {
      return this.existingAddedTo === this.adminInfoI18n.groupLabel
        ? `${this.i18n.addExistingheading} ${this.i18n.user}`
        : `${this.i18n.addExistingheading} ${this.i18n.user}`;
    },
  },
  created() {
    this.getUnitId();
    this.fetchBusinessUnitData();
    this.fetchBusinessUnitUsers();
    this.fetchUnitsDropdownList();
  },
  mounted() {
    const veeCustomErrorMessage = {
      en: {
        custom: {
          firstName: {
            required: this.i18n.firstNameRequiredError,
            regex: this.i18n.firstNameRegexError,
            max: this.i18n.firstNameMaxError,
          },
          lastName: {
            required: this.i18n.lastNameRequiredError,
            regex: this.i18n.lastNameRegexError,
            max: this.i18n.lastNameMaxError,
          },
          email: {
            required: this.i18n.emailRequiredError,
            email: this.i18n.emailInvalidError,
          },
          parentUnit: {
            required: this.i18n.parentUnitDropdownError,
          },
          companyName: {
            required: this.i18n.businessUnitRequiredError,
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
    this.userDetailsUrl = `${this.globals.getB2BBaseURL()}${
      this.globals.navigations.userLanding
    }?${this.UserDetails.USER_ID}=`;
  },
  methods: {
    /**
         * Getting unit ID from the url
         */
    getUnitId() {
      this.unitId = this.globals.getUrlParam(UnitDetails.UNIT_ID);
    },
    /**
         * Generating data for details section
         */
    generateDetailData() {
      const { companyLabel } = this.unitDetailI18n;
      const { addressLabel } = this.unitDetailI18n;
      const { infoLabel } = this.unitDetailI18n;
      Object.assign(this.detailPageData.detailsData, {
        id: this.detailPageData.unitDetailsData.uid,
        status: this.detailPageData.unitDetailsData.active,
        parent: this.detailPageData.unitDetailsData.parent,
        displayData: {
          [companyLabel]: this.unitName,
          [addressLabel]: this.formattedAddress,
          [infoLabel]: this.unitInfo,
        },
      });
    },
    /**
         * Fetching business unit data
         */
    fetchBusinessUnitData() {
      this.manageB2bOrgService.getBusinessUnitsDataService(
        {},
        this.handleBusinessUnitData,
        this.handleBusinessUnitDataError,
        this.unitId,
      );
    },
    /**
         * Handling business unit data
         * @param {object} unitData business unit data
         */
    handleBusinessUnitData(response) {
      const { status, data } = response;
      if (status) {
        this.detailPageData.unitDetailsData = data;
        this.generateDetailData();
        if (this.detailPageData.unitDetailsData.children) {
          this.generateInfoData(
            'childGroupData',
            'children',
            true,
            globals.navigations.businessUnitsLanding,
            UnitDetails.UNIT_ID,
          );
        }
        if (this.detailPageData.unitDetailsData.administrators) {
          this.generateInfoData(
            'adminGroupData',
            'administrators',
            true,
            globals.navigations.userLanding,
            UserDetails.USER_ID,
          );
        }
        if (this.detailPageData.unitDetailsData.customers) {
          this.generateInfoData(
            'buyerGroupData',
            'customers',
            true,
            globals.navigations.userLanding,
            UserDetails.USER_ID,
          );
        }
      } else {
        this.handleErrorCallback(data);
      }
    },
    handleBusinessUnitDataError() {
    },
    /**
         * Fetchin business unit users data.
         */
    fetchBusinessUnitUsers() {
      this.manageB2bOrgService.getB2bUsersService(
        {},
        this.handleBusinessUnitUsersData,
        this.handleBusinessUnitUsersDataError,
      );
    },
    /**
         * Handling business unit data
         * @param {object} unitData business unit data
         */
    handleBusinessUnitUsersData(response) {
      const { status, data } = response;
      const self = this;
      if (status) {
        this.businessUnitUsers = data.users.filter(
          (child) => child.displayUid !== self.globals.uid,
        );
      } else {
        this.handleErrorCallback(data);
      }
    },
    handleBusinessUnitUsersDataError() {
    },
    /**
         * Fetches the business units
         */
    fetchUnitsDropdownList() {
      this.manageB2bOrgService.getBusinessUnits(
        {},
        this.handleUnitsDropdownResponse,
        this.handleUnitsDropdownError,
        '',
      );
      this.manageB2bOrgService.getBusinessUnits(
        {},
        this.handleLimitedUnitsDropdownResponse,
        this.handleLimitedUnitsDropdownError,
        '',
        true,
      );
    },
    /**
         * Handling business unit dropdown data
         * @param {object} unitData business unit data
         */
    handleUnitsDropdownResponse(response) {
      const { status, data } = response;
      if (status) {
        this.parentUnitsList = data.units.map((child) => ({
          label: child.name,
          value: child.id,
        }));
      }
    },
    handleUnitsDropdownError() {
    },
    /**
         * Handling business unit data
         * @param {object} unitData business unit data
         */
    handleLimitedUnitsDropdownResponse(response) {
      const { status, data } = response;
      if (status) {
        this.limitedParentUnitsList = data.units.map((child) => ({
          label: child.name,
          value: child.id,
        }));
      }
    },
    handleLimitedUnitsDropdownError() {
    },
    handleErrorCallback() {
    },
    /**
         * Handling user details data
         * @param {object} UserDetails business unit data
         */
    handleUserDetailsResponse(response) {
      const { status, data } = response;
      if (status) {
        this.user.uid = data.displayUid;
        this.user.firstName = data.firstName;
        this.user.lastName = data.lastName;
        this.user.country.label = data.defaultAddress
          ? data.defaultAddress.country.isocode
          : '';
        this.user.country.value = data.defaultAddress
          ? data.defaultAddress.country.isocode
          : '';
      }
    },
    handleUserDetailsError() {
    },
    /**
         * Called on cicking the edit button of businesss unit.
         * A call is made to getUsersDetails.
         */
    handleEditClick(event) {
      if (this.hasParent) {
        this.childUnitDetails.companyName = this.detailPageData.unitDetailsData.name;
        this.$refs.editChildUnitModal.open(event);
      } else {
        const userId = `${globals.uid}|${globals.getSiteId()}`;
        this.$refs.editCompanyDetailsModal.open(event);
        this.manageB2bOrgService.getUsersDetails(
          {},
          this.handleUserDetailsResponse,
          this.handleUserDetailsError,
          `${encodeURIComponent(userId)}`,
        );
      }
    },
    parentUnitDropdownInit() {
      this.$refs.parentUnitDropdown.setDropdownValue(
        this.detailPageData.unitDetailsData.unit.uid,
      );
      this.childUnitDetails.parentUnit.value = this.detailPageData.unitDetailsData.unit.uid;
    },
    /**
         * Called on clicking the submit button of edit request.
         */
    sendEditRequest() {
      this.$validator.validateAll()
        .then((result) => {
          if (result) {
            const childUnit = encodeURIComponent(
              this.childUnitDetails.companyName,
            );
            const parentUnit = encodeURIComponent(
              this.childUnitDetails.parentUnit.value,
            );
            if (this.hasParent) {
              if (childUnit && parentUnit) {
                this.manageB2bOrgService.createChildUnitService(
                  {},
                  this.handleEditResponse,
                  this.handleEditError,
                  childUnit,
                  parentUnit,
                  this.detailPageData.unitDetailsData.uid,
                );
              }
            } else {
              this.closeEditParentModal();
            }
          } else {
            this.globals.setFocusByName(
              this.$el,
              this.globals.getElementName(this.errors),
            );
          }
        });
    },
    /**
         * This function is called On success of createChildUnitService
         * @param {object} response contains the details of the child user.
         */
    handleEditResponse(response) {
      const { status } = response;
      if (status) {
        this.detailPageData.unitDetailsData.name = this.childUnitDetails.companyName;
        this.detailPageData.unitDetailsData.parent = this.childUnitDetails.parentUnit.label;
        this.generateDetailData();
        this.resetValues();
        this.$refs.editChildUnitModal.close();
      }
    },
    handleEditError() {
    },
    /**
         * Onclick of close icon ,Closes the parent model.
         */
    closeEditParentModal() {
      this.fetchBusinessUnitData();
      this.generateDetailData();
      this.$refs.editCompanyDetailsModal.close();
    },
    /**
         * Called when user clicks on disable button of the business unit.
         * Opens the disable unit model.
         */
    handleDisableClick(event) {
      this.$refs.disableUnitModal.open(event);
    },
    /**
         * Called on clicking of submit button of disable unit.
         * On success disables the user.
         */
    sendDisableRequest() {
      this.manageB2bOrgService.disableUnitsService(
        {},
        (response) => {
          const { status } = response;
          if (status) {
            this.detailPageData.unitDetailsData.active = false;
            this.generateDetailData();
            this.$refs.disableUnitModal.close();
          }
        },
        this.handleDisableUnitsError,
        this.unitId,
        false,
      );
    },
    /**
         * Called when user clicks on enable button of the business unit.
         * Opens the enable unit model.
         */
    handleEnableClick() {
      this.$refs.enableUnitModal.open();
    },
    /**
         * Called on clicking of submit button of enable unit.
         * On success enables the user.
         */
    sendEnableRequest() {
      this.manageB2bOrgService.disableUnitsService(
        {},
        (response) => {
          const { status } = response;
          if (status) {
            this.detailPageData.unitDetailsData.active = true;
            this.generateDetailData();
            this.$refs.enableUnitModal.close();
          }
        },
        this.handleDisableUnitsError,
        this.unitId,
        true,
      );
    },
    handleDisableUnitsError() {
    },
    /**
         * closed the modal
         * @param {object} removedInfo contains the removed information of the user
         */
    handleRemoveClick(removedInfo) {
      this.$refs.removeModal.open();
      this.removedInfo = removedInfo;
    },
    /**
         * Used for generating the user data.
         */
    generateRemoveUserData() {
      this.removedUserData = [];
      if (this.removedInfo.from === this.buyerInfoI18n.groupLabel) {
        this.removedUser = this.detailPageData.unitDetailsData.customers.filter(
          (child) => child.uid === this.removedInfo.item.uid,
        );
        this.$set(this.removedUserData, 0, this.removedUser[0]);
      } else if (this.removedInfo.from === this.adminInfoI18n.groupLabel) {
        this.removedUser = this.detailPageData.unitDetailsData.administrators.filter(
          (child) => child.uid === this.removedInfo.item.uid,
        );
        this.$set(this.removedUserData, 0, this.removedUser[0]);
      }
    },
    /**
         * Used for reoving the existing user
         */
    sendRemoveRequest() {
      this.generateRemoveUserData();
      const payload = {
        users: [...this.removedUserData],
      };
      const requestConfig = {};
      requestConfig.data = payload;
      this.manageB2bOrgService.removeExistingUserService(
        requestConfig,
        this.handleRemoveResponse,
        this.handleRemoveError,
        this.unitId,
      );
    },
    /**
         * This function is called On success of removeExistingUserService
         * @param {object} response contains the details of the status, user data.
         */
    handleRemoveResponse(response) {
      const { status } = response;
      if (status) {
        if (this.removedInfo.from === this.buyerInfoI18n.groupLabel) {
          const removedIndex = this.detailPageData.unitDetailsData.customers.indexOf(
            this.removedUser,
          );
          this.detailPageData.unitDetailsData.customers.splice(removedIndex, 1);
          this.generateInfoData(
            'buyerGroupData',
            'customers',
            true,
            globals.navigations.userLanding,
            UserDetails.USER_ID,
          );
        } else if (this.removedInfo.from === this.adminInfoI18n.groupLabel) {
          const removedIndex = this.detailPageData.unitDetailsData.administrators.indexOf(
            this.removedUser,
          );
          this.detailPageData.unitDetailsData.administrators.splice(
            removedIndex,
            1,
          );
          this.generateInfoData(
            'adminGroupData',
            'administrators',
            true,
            globals.navigations.userLanding,
            UserDetails.USER_ID,
          );
        }
        this.removedUserData = [];
        this.$refs.removeModal.close();
      }
    },
    handleRemoveError() {
    },
    /**
         * Opens a addExistingModal modal.
         * Fetches the data as per condition and displays.
         * @param {object} section contains the type of the user like Administrators,Buyers.
         */
    handleAddExistingClick(section) {
      this.$refs.addExistingModal.open();
      this.existingAddedTo = section;
      if (this.existingAddedTo === this.buyerInfoI18n.groupLabel) {
        this.addedExistingData = [...this.buyers];
      } else if (this.existingAddedTo === this.adminInfoI18n.groupLabel) {
        this.addedExistingData = [...this.admins];
      }
    },
    /**
         * Generated an exisiting user data.
         */
    generateAddExistingUserData() {
      if (this.existingAddedTo === this.buyerInfoI18n.groupLabel) {
        this.addedExistingData = this.addedExistingData.map((user) => ({
          uid: user,
          roles: [this.UserRoles.CUSTOMER],
        }));
      } else if (this.existingAddedTo === this.adminInfoI18n.groupLabel) {
        this.addedExistingData = this.addedExistingData.map((user) => ({
          uid: user,
          roles: [this.UserRoles.ADMIN],
        }));
      }
    },
    /**
         * Called On click of save button of add existing user.
         * Makes a call to addExistingUserService
         */
    sendAddExistingRequest() {
      this.generateAddExistingUserData();
      const payload = {
        users: [...this.addedExistingData],
      };
      const requestConfig = {};
      requestConfig.data = payload;
      let roleLabel = '';
      if (this.existingAddedTo === this.buyerInfoI18n.groupLabel) {
        roleLabel = this.UserRoles.CUSTOMER;
      } else if (this.existingAddedTo === this.adminInfoI18n.groupLabel) {
        roleLabel = this.UserRoles.ADMIN;
      }
      this.manageB2bOrgService.addExistingUserService(
        requestConfig,
        this.handleAddExistingResponse,
        this.handleAddExistingError,
        this.unitId,
        roleLabel,
      );
    },
    /**
         * This function is called On success of addExistingUserService
         * @param {object} response contains the succes response.
         */
    handleAddExistingResponse(response) {
      const { status } = response;
      if (status) {
        this.fetchBusinessUnitData();
        this.$refs.addExistingModal.close();
        this.resetAddedValues();
      }
    },
    handleAddExistingError() {
    },
    resetAddedValues() {
      this.addedExistingData = [];
    },
    /**
         * Opens a add user modal.
         * @param {String} label is they type of user such as buyer, administrator, child unit.
         */
    handleAddNewUserClick(label) {
      this.isEmailExists = false;
      if (label === this.i18n.adminInfogroupLabel) {
        this.addedUserDetails.role = 'b2badmingroup';
      } else {
        this.addedUserDetails.role = 'b2bcustomergroup';
      }
      this.$refs.addUserModal.open(label);
    },
    /**
         * Adds administrator to the business unit.
         */
    addAdministrator() {
      this.fetchBusinessUnitData();
      this.fetchBusinessUnitUsers();
      this.resetUserValues();
      this.$refs.addUserModal.close();
    },
    /**
         * Adds chuld unit to the busniess unit.
         */
    handleAddChildUnit(event) {
      this.childUnitDetails.companyName = '';
      this.childUnitDetails.parentUnit = {};
      this.$refs.addChildUnitModal.open(event);
    },
    /**
         * Makes a createChildUnitService call for adding the child unit.
         */

    sendAddChildUnitRequest() {
      this.$validator.validateAll()
        .then((result) => {
          if (result) {
            const childUnit = encodeURIComponent(
              this.childUnitDetails.companyName,
            );
            const parentUnit = encodeURIComponent(
              this.childUnitDetails.parentUnit.value,
            );
            if (childUnit && parentUnit) {
              this.manageB2bOrgService.createChildUnitService(
                {},
                this.handleAddChildUnitResponse,
                this.handleAddChildUnitError,
                childUnit,
                parentUnit,
              );
            }
          } else {
            this.globals.setFocusByName(
              this.$el,
              this.globals.getElementName(this.errors),
            );
          }
        });
    },
    /**
         * Handles succes response of createChildUnitService
         * @param {object} response contains child unit details
         */
    handleAddChildUnitResponse(response) {
      const { status } = response;
      if (status) {
        this.fetchBusinessUnitData();
        this.manageB2bOrgService.getBusinessUnits(
          {},
          this.handleLimitedUnitsDropdownResponse,
          this.handleLimitedUnitsDropdownError,
          '',
          true,
        );
        this.generateInfoData(
          'childGroupData',
          'children',
          true,
          globals.navigations.businessUnitsLanding,
          UnitDetails.UNIT_ID,
        );
        this.resetValues();
        this.$refs.addChildUnitModal.close();
      }
    },
    handleAddChildUnitError() {
    },
    resetValues() {
      this.childUnitDetails.companyName = '';
      this.childUnitDetails.parentUnit.value = '';
    },
    /**
         * Resets the user details.
         */
    resetUserValues() {
      this.addedUserDetails = {
        firstName: '',
        lastName: '',
        uid: '',
        parentUnit: '',
        role: '',
        leaseSigner: true,
      };
    },
    showLoadingSpinner() {
      this.$refs.spinner.showSpinner();
    },
    hideLoadingSpinner() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * Closes the user modal.
         */
    closeUserModal() {
      this.showInviteMsg = true;
      this.isEmailExists = false;
      this.$refs.addUserModal.close();
      this.generateDetailData();
    },
  },
};
