var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "vx-product-category d-flex flex-xs-column flex-sm-row flex-wrap justify-content-center"
    },
    [
      !_vm.showMore
        ? _c(
            "div",
            _vm._l(_vm.tabBody.slice(0, 3), function(prd) {
              return _c(
                "div",
                {
                  staticClass:
                    "col-xs-12 col-sm-6 col-md-4 vx-product-category__item p-xs-0 p-sm-3 p-md-3"
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 product-container p-xs-0" },
                    [
                      _c("img", {
                        attrs: { src: prd.tipImageUrl, alt: "prd.tipAltText" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-product-category__wrapper d-flex align-items-center justify-content-center p-xs-3"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vx-product-category__content text-center"
                            },
                            [
                              _c("h2", {
                                staticClass: "color-white",
                                domProps: { innerHTML: _vm._s(prd.tipText) }
                              }),
                              _vm._v(" "),
                              prd.tipLinkUrl && prd.tipLinkText
                                ? _c("a", {
                                    staticClass:
                                      "btn btn-transparent text-uppercase",
                                    attrs: {
                                      href: prd.external
                                        ? prd.tipLinkUrl
                                        : _vm.globals.getUrlWithContextPath(
                                            prd.tipLinkUrl
                                          ),
                                      target: prd.external ? "_blank" : ""
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(prd.tipLinkText)
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            })
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showMore
        ? _c(
            "div",
            _vm._l(_vm.tabBody, function(prd) {
              return _c(
                "div",
                {
                  staticClass:
                    "col-xs-12 col-sm-6 col-md-4 vx-product-category__item p-xs-0 p-sm-3 p-md-3"
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 product-container p-xs-0" },
                    [
                      _c("img", {
                        attrs: { src: prd.tipImageUrl, alt: "prd.tipAltText" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-product-category__wrapper d-flex align-items-center justify-content-center p-xs-3"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vx-product-category__content text-center"
                            },
                            [
                              _c("h2", {
                                staticClass: "color-white",
                                domProps: { innerHTML: _vm._s(prd.tipText) }
                              }),
                              _vm._v(" "),
                              prd.tipLinkUrl && prd.tipLinkText
                                ? _c("a", {
                                    staticClass:
                                      "btn btn-transparent text-uppercase",
                                    attrs: {
                                      href: prd.external
                                        ? prd.tipLinkUrl
                                        : _vm.globals.getUrlWithContextPath(
                                            prd.tipLinkUrl
                                          ),
                                      target: prd.external ? "_blank" : ""
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(prd.tipLinkText)
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            })
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "show-more-width" }, [
        !_vm.showMore
          ? _c(
              "p",
              {
                staticClass: "see-more",
                on: {
                  click: function($event) {
                    _vm.showMore = true
                  }
                }
              },
              [_vm._v(" Show More")]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9aff17aa", { render: render, staticRenderFns: staticRenderFns })
  }
}