var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-communication-preferences" },
    [
      _c("vx-spinner", {
        ref: "communicationPreferencesSpinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("h4", [_vm._v(_vm._s(_vm.i18n.txtMarketingCommunication))]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: Object.keys(_vm.frequency).length > 0,
                  expression: "Object.keys(frequency).length > 0"
                }
              ],
              staticClass: "form-group mt-xs-3 mb-xs-2"
            },
            [
              _vm.frequency.question
                ? _c("label", [_vm._v(_vm._s(_vm.frequency.question))])
                : _vm._e(),
              _vm._v(" "),
              _c("vx-dropdown-primary", {
                ref: "frequencyDropdown",
                attrs: { dropdownValues: _vm.frequencyDropdownValues },
                on: {
                  "selected-option": function($event) {
                    _vm.selectedFrequency = $event.value
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.schema, function(questionData) {
            return _c(
              "div",
              { staticClass: "form-group" },
              [
                questionData.question && !_vm.globals.isB2B()
                  ? _c("label", [_vm._v(_vm._s(questionData.question))])
                  : _vm._e(),
                _vm._v(" "),
                questionData.selectionType === "RADIO"
                  ? _c("vx-radio-button-group", {
                      attrs: {
                        "radiobutton-values":
                          questionData.marketingPreferencesAnsList,
                        name: "questionData.question"
                      },
                      model: {
                        value: _vm.questionAnswers[questionData.question],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.questionAnswers,
                            questionData.question,
                            $$v
                          )
                        },
                        expression: "questionAnswers[questionData.question]"
                      }
                    })
                  : _vm._l(questionData.marketingPreferencesAnsList, function(
                      answer
                    ) {
                      return [
                        _c(
                          "label",
                          {
                            staticClass: "checkbox-container",
                            on: { click: _vm.handleButtonDisable }
                          },
                          [
                            _vm._v(_vm._s(answer.description) + "\n          "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: answer.selected,
                                  expression: "answer.selected"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(answer.selected)
                                  ? _vm._i(answer.selected, null) > -1
                                  : answer.selected
                              },
                              on: {
                                change: function($event) {
                                  var $$a = answer.selected,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          answer,
                                          "selected",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          answer,
                                          "selected",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(answer, "selected", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" })
                          ]
                        )
                      ]
                    })
              ],
              2
            )
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "mt-xs-5 mb-xs-2 modal-btn-primary-large modal-btn-xs-sticky form-blue-button",
              attrs: {
                "aria-label": _vm.i18n.btnSave,
                disabled: _vm.saveDisabled
              },
              on: { click: _vm.applyPreferences }
            },
            [_vm._v("\n      " + _vm._s(_vm.i18n.btnSave) + "\n    ")]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3024bec7", { render: render, staticRenderFns: staticRenderFns })
  }
}