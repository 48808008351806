var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.globals.getIsLoggedIn()
    ? _c("div", { staticClass: "vx-guest-create-account" }, [
        _c("p", { staticClass: "text mb-xs-4" }, [
          _vm._v(_vm._s(_vm.i18n.text))
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-tertiary btn-small-text",
            on: {
              click: function($event) {
                _vm.globals.navigateToUrl("register")
              }
            }
          },
          [_vm._v(_vm._s(_vm.i18n.button))]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2bf5ee70", { render: render, staticRenderFns: staticRenderFns })
  }
}