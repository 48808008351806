/* eslint-disable no-prototype-builtins */
import find from 'lodash.find';
import vxShareCart from '../../../../components/manage-shopping-cart/vx-share-cart/vx-share-cart.vue';
import vxCartProductTile from '../vx-cart-product-tile/vx-cart-product-tile.vue';
// import shoppingCartData from './vx-shopping-cart-mock-data';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxSaveCart from '../../../../components/manage-shopping-cart/vx-save-cart/vx-save-cart.vue';
import globals from '../../../../components/common/globals';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import {
  cartEventBus,
  eventBus,
  globalEventBus,
} from '../../../../modules/event-bus';
import ManageShoppingCartService from '../../../../components/common/services/manage-shopping-cart-service';
import AnalyticsService from '../../../../components/common/services/analytics-service';
import {
  flyoutStatus,
} from '../../../../components/common/mixins/vx-enums';

export default {
  name: 'vx-shopping-cart',
  mixins: [flyoutBannerMixin],
  components: {
    vxShareCart,
    vxCartProductTile,
    vxModal,
    vxSaveCart,
    vxSpinner,
  },
  props: {
    // Text coming from property file
    i18n: Object,
    /**
         * indicator for lease error
         */
    customerLeaseable: Boolean,
  },
  data() {
    return {
      shoppingCartData: {
        entries: [],
      },
      globals,
      flyoutStatus,
      promotionData: Object,
      cartHasVisibleItems: true,
      installationPrice: Object,
      spinnerComponent: null,
      manageShoppingCartService: new ManageShoppingCartService(),
      analyticsService: new AnalyticsService(),
      guestListName: '',
      giveAwayCouponDescription: '',
      individualProducts: [],
      analyticsObject: {},
    };
  },
  created() {
    // listening to emits of delete and edit item from cart-product tile component
    cartEventBus.$on('cart-update', () => {
      this.callShoppingCart();
    });
    cartEventBus.$on('cart-update-error', () => {
      // this.callShoppingCart('FULL');
    });
  },
  computed: {},
  mounted() {
    const self = this;
    this.spinnerComponent = this.$refs.spinner;
    this.callShoppingCart();
    // Send a new 'action:cart' to reCAPTCHA
    setTimeout(() => {
      if (self.globals.siteConfig.isReCaptchaEnabled && window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(self.globals.reCaptchaSiteKey, { action: 'cart' });
        });
      }
    }, 1000);
  },
  methods: {
    /**
         * This function handles the opening of share cart modal
         */
    openShareCartModal(event) {
      this.$refs.shareCartModal.open(event);
    },
    /**
         * This function handles the success of the share cart modal
         */
    handleShareCartSuccess() {
      this.$refs.shareCartModal.close();
    },
    /**
         * This function handles the error of the share cart modal
         */
    handleShareCartError() {
      // this.$refs.shareCartModal.close();
    },
    /**
         * This function handles the save cart functionality
         */
    handleSaveACart(event) {
      if (this.globals.loggedIn) {
        this.$refs.saveCartModal.open(event);
      } else {
        this.globals.setCookie('flow', 'cart');
        if (this.globals.siteConfig.isGuestList) {
          // this.guestListName = this.i18n.guestList;
          setTimeout(() => {
            this.guestListName = this.i18n.guestList;
            this.showFlyout(this.flyoutStatus.success, `${this.i18n.guestList} Saved Successfully`, true);
          }, 300);
        } else {
          this.globals.navigateToUrl('login');
          globalEventBus.$emit(
            'announce',
            'For adding to list, you need to login',
          );
          setTimeout(() => {
            this.globals.navigateToUrl('login');
          }, 300);
        }
      }
      this.analyticsObject = {
        shoppingCartData: this.shoppingCartData,
      };
    },
    /**
         * This function gets cart details
         */
    callShoppingCart() {
      const requestConfig = {};
      this.manageShoppingCartService.getFullCartService(requestConfig, this.handleShoppingCartResponse, this.handleShoppingCartError);
      this.spinnerComponent.showSpinner();
    },
    /**
         * This function handles the response of cart details
         */
    handleShoppingCartResponse(response) {
      this.spinnerComponent.hideSpinner();
      if (response && response.data) {
        this.shoppingCartData = response.data;
        this.individualProducts = this.shoppingCartData.entries;
        if (this.shoppingCartData.appliedOrderPromotions.length) {
          this.shoppingCartData.appliedOrderPromotions.forEach((promotion) => {
            if (promotion.giveAwayCouponCodes.length && promotion.description) {
              this.giveAwayCouponDescription = promotion.description;
            } else {
              this.giveAwayCouponDescription = '';
            }
          });
        } else {
          this.giveAwayCouponDescription = '';
        }
        eventBus.$emit('cart-details', this.shoppingCartData.entries);
        if (this.globals.siteId === 'gppro') {
          this.analyticsService.trackViewCartGA4(this.shoppingCartData);
        }
        cartEventBus.$emit('total-items-updated', response.data.totalItems, response.data.code);
        if (this.globals.isB2B()) {
          if (this.globals.getIsLoggedIn()) {
            this.showLeaseError();
          }
          this.installationPrice = this.getInstallationPrice();
        }
        this.promotionData = this.getProductPromotions();
        if (this.shoppingCartData.entries.length !== 0) {
          this.cartHasVisibleItems = true;
        } else {
          this.cartHasVisibleItems = false;
        }
        this.$root.$emit('cartHasVisibleItems', this.cartHasVisibleItems);
        this.$root.$emit('full-cart-called', this.shoppingCartData);
        this.$root.$emit('cart-reloaded', true);
      }
    },
    /**
         * This function handles the error of cart details
         */
    handleShoppingCartError() {
      this.spinnerComponent.hideSpinner();
      this.cartHasVisibleItems = false;
      this.$root.$emit('cartHasVisibleItems', this.cartHasVisibleItems);
    },
    /**
         * This function shows lease error
         */
    showLeaseError() {
      for (let i = 0; i < this.shoppingCartData.entries.length; i += 1) {
        if (this.shoppingCartData.entries[i].leasable && !this.customerLeaseable) {
          this.$root.$emit('showLeaseError', true);
          this.showFlyout('error', this.i18n.leaseCartError, false);
          return false;
        }
        this.$root.$emit('showLeaseError', false);
        this.dismissFlyout();
      }
      return undefined;
    },
    /**
         * This function closes the save list modal
         */
    handleListSaved() {
      this.$refs.saveCartModal.close();
    },
    /**
         * This function gets product promotions details
         */
    getProductPromotions() {
      const promotionProductData = {};
      for (let i = 0; i < this.shoppingCartData.appliedProductPromotions.length; i += 1) {
        for (let j = 0; j < this.shoppingCartData.appliedProductPromotions[i].consumedEntries.length; j += 1) {
          const { orderEntryNumber } = this.shoppingCartData
            .appliedProductPromotions[i].consumedEntries[j];
          const { description } = this.shoppingCartData.appliedProductPromotions[i];
          if (!this.shoppingCartData.entries[orderEntryNumber].promotionsRevoked) {
            promotionProductData[orderEntryNumber] = description;
          }
        }
      }
      return promotionProductData;
    },
    /**
         * This function gets price of installation products
         */
    getInstallationPrice() {
      let invisibleEntries = [];
      const installationPrice = {};
      invisibleEntries = this.shoppingCartData.entries.filter((item) => !!item.visible === false);
      for (let i = 0; i < invisibleEntries.length; i += 1) {
        const installableProduct = find(invisibleEntries[i].additionalAttributes.entry, {
          key: 'installedEntry',
        });
        if (installableProduct) {
          installationPrice[installableProduct.value] = invisibleEntries[i].totalPrice.formattedValue;
        }
      }
      return installationPrice;
    },

    clearCart() {
      const requestConfig = {};
      this.$refs.spinner.showSpinner();
      if (this.globals.siteId === 'gppro') {
        this.analyticsService.trackRemoveFromCartGA4Cart(this.shoppingCartData);
      }
      this.manageShoppingCartService.deleteCartService(requestConfig, this.handleClearCartResponse, this.handleClearCartError);
    },

    handleClearCartResponse() {
      setTimeout(() => {
        this.spinnerComponent.hideSpinner();
        const url = `${this.globals.getNavigationUrl(
          'cart',
        )}`;
        window.location = url;
      }, 1500);
    },

    handleClearCartError() {
      this.spinnerComponent.hideSpinner();
      this.showFlyout(this.flyoutStatus.error, this.i18n.clearCartError, false);
    },
  },
};
