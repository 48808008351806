var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "access-customer-service-contact-method contact-type-container py-xs-3",
      class: _vm.liveChatTheme
    },
    [
      _c(
        "div",
        { staticClass: "d-flex contact-method justify-content-center" },
        [
          _c("div", { staticClass: "contact-type ml-xs-3 ml-sm-0" }, [
            _c("div", { staticClass: "chat-section row mx-sm-0" }, [
              _c("div", { staticClass: "col-xs-12 px-xs-3" }, [
                _c("h2", { staticClass: "contact-type-heading my-xs-0" }, [
                  _vm._v("Chat")
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "submit btn btn-primary mt-xs-2 mt-sm-0 mb-xs-2 mb-md-0",
                    attrs: { type: "submit" },
                    on: { click: _vm.chatCustomerSupport }
                  },
                  [_vm._v("Chat Now")]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "contact-type mt-xs-4 mt-md-0 ml-xs-3 ml-sm-0" },
            [
              _c("div", { staticClass: "chat-section row mx-sm-0" }, [
                _c("div", { staticClass: "col-xs-12 px-xs-3" }, [
                  _c("h2", { staticClass: "contact-type-heading my-xs-0" }, [
                    _vm._v("Form")
                  ]),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "submit btn btn-primary mt-xs-2 mt-sm-0 mb-xs-2 mb-md-0",
                      attrs: { type: "submit" },
                      on: { click: _vm.navigateToContact }
                    },
                    [_vm._v("Complete Form")]
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-xs-2" }, [
      _vm._v("\n            Live chat with us:"),
      _c("br"),
      _vm._v("\n            7:30am-5pm CST (M-Th)"),
      _c("br"),
      _vm._v("\n            7:30am-4pm CST (Fri)\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-xs-2" }, [
      _vm._v("\n            Want GPXpress"),
      _c("sup", [_vm._v("®")]),
      _vm._v(" Support to"),
      _c("br", { staticClass: "d-mob-none" }),
      _vm._v("\n            contact you? Click below to"),
      _c("br", { staticClass: "d-mob-none" }),
      _vm._v("\n            complete our contact form.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "contact-type mt-xs-4 mt-md-0 ml-xs-3 ml-sm-0" },
      [
        _c("div", { staticClass: "chat-section row mx-sm-0" }, [
          _c("div", { staticClass: "col-xs-12 px-xs-3" }, [
            _c("h2", { staticClass: "contact-type-heading my-xs-0" }, [
              _vm._v("Phone")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-xs-2" }, [
              _vm._v("\n            Call "),
              _c("a", { attrs: { href: "tel:18664355647" } }, [
                _vm._v("1-866-435-5647")
              ]),
              _vm._v(" to"),
              _c("br", { staticClass: "d-mob-none" }),
              _vm._v("\n            speak with an agent"),
              _c("br", { staticClass: "d-mob-none" }),
              _vm._v("\n            instantly."),
              _c("br"),
              _vm._v("\n            7am-6pm CST (M-Th)"),
              _c("br"),
              _vm._v("\n            7am-5pm CST (Fri)\n          ")
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "contact-type mt-xs-4 mt-md-0 ml-xs-3 ml-sm-0" },
      [
        _c("div", { staticClass: "chat-section row mx-sm-0" }, [
          _c("div", { staticClass: "col-xs-12 px-xs-3" }, [
            _c("h2", { staticClass: "contact-type-heading my-xs-0" }, [
              _vm._v("Email")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-xs-2" }, [
              _vm._v("\n            Email us at any time at"),
              _c("br", { staticClass: "d-mob-none" }),
              _vm._v(" "),
              _c("a", { attrs: { href: "mailto:gpxpress@gapac.com" } }, [
                _vm._v("gpxpress@gapac.com")
              ]),
              _vm._v(" and a"),
              _c("br", { staticClass: "d-mob-none" }),
              _vm._v("\n            member of our team will"),
              _c("br", { staticClass: "d-mob-none" }),
              _vm._v("\n            respond within one business"),
              _c("br", { staticClass: "d-mob-none" }),
              _vm._v("\n            day.\n          ")
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-075fbb4e", { render: render, staticRenderFns: staticRenderFns })
  }
}