import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import manageProfileShoppingListsi18n from '../../../../locale/gp-pro/manage-profile-shopping-lists-i18n';

export default {
  name: 'vx-profile-card',
  mixins: [flyoutBannerMixin],
  components: {},
  props: {
    /**
         * Card Type: 'social','add','column'
         * @type {String}
         */
    cardType: String,
    /**
         * Disabling Profile and Column Card
         * @type {Boolean}
         */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
         * Enabling Profile and Column Card
         * @type {Boolean}
         */
    isEnabled: {
      type: Boolean,
      default: false,
    },
    /**
         * Provide default height of 176px
         * @type {Boolean}
         */
    isDefaultHeight: {
      type: Boolean,
      default: false,
    },
    /**
         * Add Custom CSS Classes to Card Container
         * @type {String}
         */
    cardClasses: {
      type: String,
      default: '',
    },
    /**
         * Show/Hide Icons from the Card
         * @type {Boolean}
         */
    hasIcons: {
      type: Boolean,
      default: true,
    },
    /**
         * Show/Hide Footer from the Card
         * @type {Boolean}
         */
    hasFooter: {
      type: Boolean,
      default: true,
    },
    /*
         * Checking that if the address is default
         * @type {Boolean}
         */
    isDefault: {
      type: Boolean,
      default: false,
    },
    /*
         * Checking that if the address is default billing address
         * @type {Boolean}
         */
    isDefaultBilling: {
      type: Boolean,
      default: false,
    },
    /*
         * Checking that if the address is default billing address
         * @type {Boolean}
         */
    isDefaultShipping: {
      type: Boolean,
      default: false,
    },
    /*
         * Checking that if the pallet shipment is default
         * @type {Boolean}
         */
    isPalletShipment: {
      type: Boolean,
      default: false,
    },
    data() {
      return {
        i18n: manageProfileShoppingListsi18n.addAddress,
      };
    },
    unitId: {
      type: String,
    },
    /*
        * Showing trash icon even for disabled tiles.
        */
    showTrash: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
  },
  methods: {
    /**
         * Emits event to enable user address
         */
    enableAddress() {
      this.$emit('enableUserAddress');
      this.showFlyout('success', this.i18n.addressEnabled, true, 10000);
    },
    /**
         * Emits event to disable user address
         */
    disableAddress() {
      this.$emit('disableUserAddress');
      this.showFlyout('success', this.i18n.addressDisabled, true, 10000);
    },
    /**
         * Emits event to reject an address submitted for review
         */
    rejectAddress() {
      this.$emit('rejectUserAddress');
    },
    /**
         * Emits event to approve an address submitted for review
         */
    approveAddress() {
      this.$emit('approveUserAddress');
    },
  },
};
