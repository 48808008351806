import mobileMixin from '../../common/mixins/mobile-mixin';
import viewSiteContent from '../../../locale/view-site-content-i18n';
import vxVideoPlayer from '../../common/vx-video-player/vx-video-player.vue';

export default {
  name: 'vx-image-tile',
  components: { vxVideoPlayer },
  props: {
    imageTileData: {
      type: Object,
      required: true,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      i18n: viewSiteContent.imageTile,
      backgroundImg: '',
    };
  },
  computed: {
    backgroundImage() {
      return { 'background-image': `url(${this.backgroundImg})` };
    },

    backgroundAlt() {
      return this.getResponsiveAlt(
        this.imageTileData.imageSrcAltTextD,
        this.imageTileData.imageSrcAltTextT,
        this.imageTileData.imageSrcAltTextM,
      );
    },
  },
  async mounted() {
    this.backgroundImg = this.getResponsiveImage(
      this.imageTileData.imageSrcD,
      this.imageTileData.imageSrcT,
      this.imageTileData.imageSrcM,
    );
  },
  methods: {
    /**
     * Emitting event to open the video player by sending the source url.
     * @param {String} src
     */
    openVideo(src) {
      this.$refs.videoPlayer.openPlayer(src);
    },
  },
};
