/**
 * Main hero component on homepage
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-double-cta-banner',
  components: {},
  props: {
    ctaBannerData: Object,
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
    };
  },
  computed: {},
  mounted() {
    const self = this;
    if (this.ctaBannerData.backgroundImageD) {
      const backgroundImageURL = this.getResponsiveImage(this.ctaBannerData.backgroundImageD, this.ctaBannerData.backgroundImageT, this.ctaBannerData.backgroundImageM);
      this.$el.style.background = `url("${backgroundImageURL}") no-repeat center`;
      this.$el.style.backgroundSize = 'cover';
    } else {
      this.$el.style.backgroundColor = this.ctaBannerData.backgroundColor;
    }
    if (this.ctaBannerData.componentTheme === 'text-box-left') {
      this.$refs.textBox.classList.add('text-box-left');
    }
    if (this.ctaBannerData.ctaColor) {
      this.$refs.loginButton.style.color = this.ctaBannerData.ctaColor;
    }
    if (this.ctaBannerData.ctaColor2) {
      this.$refs.connectWithUsButton.style.color = this.ctaBannerData.ctaColor2;
      this.$refs.connectWithUsButton.style.borderColor = this.ctaBannerData.ctaColor2;
    }
    /* To check if this component is loaded on home and make the below updates:
      1. Change h2 to h1 for ADA & SEO
      2. Send a new 'action:homepage' to reCAPTCHA
    */
    if (window.location.pathname === '/gp/gppro/USD/' || window.location.pathname === '/gp/') {
      this.$el.querySelector('.description-text').outerHTML = `<h1 class="description-text">${this.ctaBannerData.subHeaderText}</h1>`;
      setTimeout(() => {
        if (self.globals.siteConfig.isReCaptchaEnabled && window.grecaptcha) {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(self.globals.reCaptchaSiteKey, { action: 'homepage' });
          });
        }
      }, 1000);
    }
  },
  methods: {

  },
};
