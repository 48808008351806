import { swiper, swiperSlide } from 'vue-awesome-swiper';
/*
1. set the large image dynamically as background
2. use desktop and other resposive images dynamically
3. connect the component with backend and check for the output

*/
export default {
  name: 'vx-multi-icon',
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    multiIconData: {
      type: Object,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      swiperOption: {
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      desktopIconRowData: [],
      selectedData: {},
    };
  },
  mounted() {
    this.fetchSigleData(this.selectedIndex);
    this.splitIconDataForRow();
    this.multiIconData.iconComponents.forEach((slideData, i) => {
      if (slideData.imageData.imageSrcM) {
        this.$refs[`slide${i}`][0].style.background = `url("${slideData.imageData.imageSrcM}") no-repeat center`;
        this.$refs[`slide${i}`][0].style.backgroundSize = 'cover';
      }
    });
  },
  methods: {
    fetchSigleData(index) {
      this.selectedIndex = index;
      document.getElementsByClassName('image-container')[0].style.background = `url(${this.multiIconData.iconComponents[index].imageData.imageSrcD}) top center / contain no-repeat`;
      this.selectedData = this.multiIconData.iconComponents[index];
    },
    splitIconDataForRow() {
      const dataCopy = [...this.multiIconData.iconComponents];
      let totalRows = Math.ceil(dataCopy.length / 3);
      while (totalRows > 0) {
        const tempData = dataCopy.splice(0, 3);
        this.desktopIconRowData.push(tempData);
        totalRows -= 1;
      }
    },
    computeIndex(row, column) {
      return (3 * row) + (column);
    },
  },
};
