var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "view-site-content-vx-text-tile d-flex justify-content-center py-xs-3 px-xs-3",
      class: _vm.alignmentClass,
      style: [_vm.backgroundStyle, _vm.textWidth],
      attrs: {
        "aria-label": _vm.textTileData.backgroundImageD ? _vm.backgroundAlt : ""
      }
    },
    [
      _c(
        "div",
        { staticClass: "w-xs-100" },
        [
          _vm.textTileData.header
            ? _c("div", {
                staticClass: "my-xs-0 text-title-header",
                style: [_vm.fontStyleHeader],
                domProps: { innerHTML: _vm._s(_vm.textTileData.header) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.textTileData.text
            ? _c("p", {
                staticClass: "mb-xs-0",
                class: [_vm.textTileData.header ? "mt-xs-3 mt-sm-5" : ""],
                style: [_vm.fontStyleText],
                domProps: { innerHTML: _vm._s(_vm.textTileData.text) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isPriceSpider
            ? _c("vx-price-spider", {
                staticClass: "mb-xs-0 cta-text",
                class: [
                  _vm.textTileData.header || _vm.textTileData.text
                    ? "mt-xs-3 mt-sm-5"
                    : "",
                  _vm.textTileData.ctaStyle
                ],
                style: [_vm.fontStyleCta],
                attrs: {
                  config: {
                    pskey: _vm.globals.getPriceSpiderID(),
                    pscountry: "US",
                    pslanguage: "en",
                    pssku: _vm.psSKU,
                    src: "//cdn.pricespider.com/1/lib/ps-widget.js",
                    pslabel: _vm.textTileData.ctaText
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isPriceSpider &&
          _vm.textTileData.ctaText &&
          _vm.textTileData.ctaLink &&
          !_vm.textTileData.isVideoCta &&
          !_vm.textTileData.jumpToHtml
            ? _c("a", {
                staticClass: "mb-xs-0 cta-text",
                class: [
                  _vm.textTileData.header || _vm.textTileData.text
                    ? "mt-xs-3 mt-sm-5"
                    : "",
                  _vm.textTileData.ctaStyle
                ],
                style: [_vm.fontStyleCta],
                attrs: {
                  href: _vm.textTileData.isExternalLink
                    ? _vm.textTileData.ctaLink
                    : _vm.globals.getUrlWithContextPath(
                        _vm.textTileData.ctaLink
                      ),
                  role: "link",
                  target: _vm.textTileData.isExternalLink ? "_blank" : "",
                  "aria-label":
                    _vm.stripHtmlTags(_vm.textTileData.header) +
                    " " +
                    _vm.textTileData.ctaText
                },
                domProps: { innerHTML: _vm._s(_vm.textTileData.ctaText) }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isPriceSpider &&
          _vm.textTileData.ctaText &&
          _vm.textTileData.ctaLink &&
          !_vm.textTileData.isVideoCta &&
          _vm.textTileData.jumpToHtml
            ? _c("a", {
                staticClass: "mb-xs-0 cta-text",
                class: [
                  _vm.textTileData.header || _vm.textTileData.text
                    ? "mt-xs-3 mt-sm-5"
                    : "",
                  _vm.textTileData.ctaStyle
                ],
                style: [_vm.fontStyleCta],
                attrs: {
                  href: _vm.textTileData.isExternalLink
                    ? _vm.textTileData.ctaLink
                    : _vm.globals.getUrlWithContextPath(
                        _vm.textTileData.ctaLink
                      ),
                  "aria-label": _vm.stripHtmlTags(_vm.textTileData.header)
                },
                domProps: { innerHTML: _vm._s(_vm.textTileData.ctaText) },
                on: {
                  click: function($event) {
                    _vm.emitScrollSignal(_vm.textTileData.ctaLink)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isPriceSpider &&
          _vm.textTileData.ctaText &&
          _vm.textTileData.ctaLink &&
          _vm.textTileData.isVideoCta
            ? _c("a", {
                staticClass: "mb-xs-0 cta-text",
                class: [
                  _vm.textTileData.header || _vm.textTileData.text
                    ? "mt-xs-3 mt-sm-5"
                    : "",
                  _vm.textTileData.ctaStyle
                ],
                style: [_vm.fontStyleCta],
                attrs: {
                  href: "javascript:void(0)",
                  "aria-label": _vm.stripHtmlTags(_vm.textTileData.header)
                },
                domProps: { innerHTML: _vm._s(_vm.textTileData.ctaText) },
                on: {
                  click: function($event) {
                    _vm.openVideo(_vm.textTileData.ctaLink)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f9f7578a", { render: render, staticRenderFns: staticRenderFns })
  }
}