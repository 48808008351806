var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-quick-order" }, [
    _c(
      "div",
      { staticClass: "px-xs-3 px-md-5" },
      [
        _c("vx-spinner", {
          ref: "spinner",
          attrs: {
            image: _vm.globals.assetsPath + "images/spinner.gif",
            "full-screen": ""
          }
        }),
        _vm._v(" "),
        _c(
          "vx-modal",
          {
            ref: "shareListModal",
            attrs: {
              size: "extra-small",
              heading: _vm.i18n.quickOrder.shareListHeading
            }
          },
          [
            _c("vx-share-cart", {
              attrs: {
                slot: "component",
                wishlistEntries: _vm.searchHistory,
                type: "modal",
                i18n: _vm.i18n.shareList,
                quickList: _vm.fromquickOrder
              },
              on: {
                "share-success": _vm.handleShareCartSuccess,
                "share-error": _vm.handleShareCartError
              },
              slot: "component"
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "vx-modal",
          {
            ref: "saveListModal",
            attrs: {
              size: "extra-small",
              heading: _vm.i18n.quickOrder.selectListLabel
            }
          },
          [
            _c("vx-save-cart", {
              attrs: {
                slot: "component",
                wishlistEntries: _vm.searchHistory,
                i18n: _vm.i18n.saveList
              },
              on: { "list-saved": _vm.handleListSaved },
              slot: "component"
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "vx-modal",
          {
            ref: "notifyMeModal",
            attrs: {
              size: "extra-small",
              heading: _vm.i18n.productTile.notifyMeHeading
            }
          },
          [
            _c("vx-notify-me", {
              attrs: {
                slot: "component",
                title: _vm.i18n.productTile.notifyMeContent,
                productCode: _vm.notifyCode,
                i18n: _vm.i18n.productTile
              },
              on: {
                "notify-me-success": _vm.onNotifyMeSuccess,
                "notify-me-error": _vm.onNotifyMeError
              },
              slot: "component"
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "vx-modal",
          {
            ref: "uploadCsv",
            attrs: {
              size: "extra-small",
              heading: _vm.i18n.quickOrder.uploadModalHeading
            }
          },
          [
            _c("vx-csv-upload", {
              attrs: {
                slot: "component",
                i18n: _vm.i18n,
                templateError: _vm.templateError
              },
              on: {
                upload: function($event) {
                  _vm.fileUploaded($event)
                }
              },
              slot: "component"
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("h1", { staticClass: "mb-xs-3" }, [
          _vm._v(_vm._s(_vm.i18n.quickOrder.quickOrderPageHeading))
        ]),
        _vm._v(" "),
        _c("h6", { staticClass: "pb-xs-3" }, [
          _vm._v(_vm._s(_vm.i18n.quickOrder.pageInfo))
        ]),
        _vm._v(" "),
        _c("h6", { staticClass: "search-info-text pt-xs-1" }, [
          _vm._v(_vm._s(_vm.i18n.quickOrder.searchInfo))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-lg-10 col-md-12 px-xs-0 quick-search-wrapper" },
          [
            _c("div", { staticClass: "form-group quick-search pb-xs-1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchInput,
                    expression: "searchInput"
                  }
                ],
                staticClass: "form-control col-md-9 col-xs-12",
                class: { "error-border": _vm.isError || _vm.isDuplicateError },
                attrs: {
                  "aria-label": "Search Product",
                  type: "text",
                  name: "quickSearch",
                  "aria-required": "true",
                  "aria-invalid": _vm.isError
                },
                domProps: { value: _vm.searchInput },
                on: {
                  keyup: _vm.checkInputState,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchInput = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _vm.isError
                ? _c(
                    "p",
                    {
                      staticClass: "error visible-xs",
                      attrs: {
                        "aria-hidden": !_vm.isError,
                        tabindex: "0",
                        role: "text"
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.quickOrder.productsNotFound))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isDuplicateError
                ? _c(
                    "p",
                    {
                      staticClass: "error visible-xs",
                      attrs: {
                        "aria-hidden": !_vm.isDuplicateError,
                        tabindex: "0",
                        role: "text"
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.quickOrder.duplicate))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-tertiary col-xs-12 col-md-3 add-items",
                  attrs: { type: "button", disabled: _vm.searchInput === "" },
                  on: { click: _vm.searchProducts }
                },
                [_vm._v(_vm._s(_vm.i18n.quickOrder.addItems))]
              )
            ]),
            _vm._v(" "),
            _vm.isError
              ? _c(
                  "p",
                  {
                    staticClass: "error hidden-xs",
                    attrs: {
                      "aria-hidden": !_vm.isError,
                      tabindex: "0",
                      role: "text"
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.quickOrder.productsNotFound))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isDuplicateError
              ? _c(
                  "p",
                  {
                    staticClass: "error hidden-xs",
                    attrs: {
                      "aria-hidden": !_vm.isDuplicateError,
                      tabindex: "0",
                      role: "text"
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.quickOrder.duplicate))]
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-2 col-md-12 upload-section" }, [
          _c("div", { staticClass: "form-group d-flex align-items-center" }, [
            _c("h6", [_vm._v("OR")]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-tertiary ml-xs-3",
                on: { click: _vm.openUploadFile }
              },
              [_vm._v(_vm._s(_vm.i18n.quickOrder.csvUpload))]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "pt-xs-4 pt-sm-5 pt-md-4 px-xs-0 mb-xs-0 mb-xs-4 mb-sm-3 mb-md-4 order-options"
          },
          [
            _vm.purchasable.length || _vm.nonPurchasable.length
              ? _c(
                  "div",
                  { staticClass: "px-xs-0 pt-md-4 col-xs-12 col-sm-6" },
                  [
                    _c("span", { staticClass: "save-cart pr-xs-4" }, [
                      _c("span", {
                        staticClass: "cart-icons icon-add-to-list cursor-icon",
                        attrs: {
                          title: _vm.i18n.quickOrder.iconAddToListTitle,
                          tabindex: "0",
                          role: "button",
                          "aria-label": _vm.i18n.quickOrder.iconAddToListTitle
                        },
                        on: {
                          click: function($event) {
                            _vm.handleSaveACart($event)
                          },
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter-space",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            _vm.handleSaveACart($event)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "share-cart pr-xs-4" }, [
                      _c("span", {
                        staticClass: "cart-icons icon-share cursor-icon",
                        attrs: {
                          title: _vm.i18n.quickOrder.iconShareTitle,
                          tabindex: "0",
                          role: "button",
                          "aria-label": _vm.i18n.quickOrder.iconShareTitle
                        },
                        on: {
                          click: function($event) {
                            _vm.openShareCartModal($event)
                          },
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter-space",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            _vm.openShareCartModal($event)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "seperator pr-xs-4" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "clear-all cursor-icon",
                        attrs: { tabindex: "0", role: "button" },
                        on: {
                          click: function($event) {
                            _vm.clearAll($event)
                          },
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter-space",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            _vm.clearAll($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.i18n.quickOrder.clearAll) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.purchasable.length
              ? _c(
                  "div",
                  {
                    staticClass:
                      "px-xs-0 pt-xs-4 pt-sm-0 pt-md-4 col-xs-12 col-sm-6 sub-total top"
                  },
                  [
                    _c("span", { staticClass: "pull-right" }, [
                      _vm.totalQuantity === 1
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.i18n.quickOrder.subTotal) +
                                _vm._s(_vm.totalQuantity) +
                                " " +
                                _vm._s(_vm.i18n.quickOrder.item)
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.i18n.quickOrder.subTotal) +
                                _vm._s(_vm.totalQuantity) +
                                " " +
                                _vm._s(_vm.i18n.quickOrder.items)
                            )
                          ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "total-price" }, [
                        _vm._v(_vm._s(_vm.getFormattedPrice(_vm.subTotal)))
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _vm.nonPurchasable.length
          ? _c(
              "div",
              { staticClass: "col-xs-12 px-xs-0 non-purchasable-items" },
              [
                _c(
                  "vx-accordion",
                  { attrs: { "is-slot-based": "" } },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "bold-heading",
                        attrs: { slot: "heading" },
                        slot: "heading"
                      },
                      [_vm._v(_vm._s(_vm.i18n.quickOrder.nonPurchasable))]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.nonPurchasable, function(product) {
                      return _c(
                        "vx-product-tile",
                        {
                          key: product.id,
                          attrs: { slot: "component" },
                          slot: "component"
                        },
                        [
                          _c("template", { slot: "image-slot" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.globals.contextPath + product.url
                                }
                              },
                              [
                                product.images
                                  ? _c("img", {
                                      attrs: {
                                        src: _vm.globals.getThumbnailImageUrl(
                                          product.images
                                        ),
                                        alt: product.name
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !product.images
                                  ? _c("img", {
                                      attrs: {
                                        src:
                                          _vm.globals.assetsPath +
                                          "images/no_image.svg",
                                        alt: "No Image"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "details" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "product-title product-title-trim mb-xs-2"
                              },
                              [
                                _c("a", {
                                  attrs: {
                                    href: _vm.globals.contextPath + product.url
                                  },
                                  domProps: { innerHTML: _vm._s(product.name) }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "product-code mb-xs-2" }, [
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v(
                                  _vm._s(_vm.i18n.productTile.itemId) +
                                    " : " +
                                    _vm._s(product.code)
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { attrs: { "aria-hidden": "true" } }, [
                                _vm._v(
                                  _vm._s(_vm.i18n.productTile.itemId) +
                                    " " +
                                    _vm._s(product.code)
                                )
                              ]),
                              _vm._v(" "),
                              product.cmirCode
                                ? _c("span", [
                                    _c("span", [_vm._v(" | ")]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.i18n.productTile.cmir) +
                                          " " +
                                          _vm._s(product.cmirCode)
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            !(
                              product.stock.stockLevelStatus ===
                                _vm.ProductAvailability.LOW_STOCK ||
                              product.stock.stockLevelStatus.code ===
                                _vm.ProductAvailability.LOW_STOCK
                            ) ||
                            product.materialStatus ===
                              _vm.ProductAvailability.OBSOLETE ||
                            product.materialStatus ===
                              _vm.ProductAvailability.PREDISCONTINUED ||
                            product.offlineDate < _vm.currentDate
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "product-stock mb-xs-3",
                                    attrs: { role: "status", tabindex: "0" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(product.stock.stockStatusMessage)
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !product.notPurchasable
                              ? _c(
                                  "p",
                                  { staticClass: "product-price mb-xs-3" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "product-current-price" },
                                      [
                                        _vm._v(
                                          _vm._s(product.price.formattedValue)
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "right-section-slot" }, [
                            product.discontinued &&
                            product.stock.stockLevelStatus ===
                              _vm.ProductAvailability.OUT_OF_STOCK &&
                            product.hasOwnProperty("replacementProductCode")
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-tertiary notify mb-xs-3",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.useReplacement(product)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.i18n.productTile.useReplacement
                                      )
                                    )
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-tertiary notify mb-xs-3",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.notifyMe(product)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.i18n.productTile.notifyMe)
                                    )
                                  ]
                                ),
                            _vm._v(" "),
                            !product.notPurchasable
                              ? _c(
                                  "p",
                                  { staticClass: "product-total-price" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getFormattedPrice(
                                          product.price.finalPrice
                                        )
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ],
                        2
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-12 px-xs-0 purchasable-items" },
          _vm._l(_vm.purchasable, function(product) {
            return _c(
              "vx-product-tile",
              { key: product.code, staticClass: "mt-xs-5" },
              [
                _c("template", { slot: "top-right" }, [
                  _c("span", { staticClass: "col-xs-2 px-xs-0" }, [
                    _c("span", {
                      staticClass: "cart-icons icon-trash cursor-icon",
                      attrs: {
                        tabindex: "0",
                        role: "button",
                        "aria-label": _vm.i18n.productTile.iconTrashTitle,
                        title: _vm.i18n.productTile.iconTrashTitle
                      },
                      on: {
                        click: function($event) {
                          _vm.deleteProduct(product.code)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("template", { slot: "image-slot" }, [
                  _c(
                    "a",
                    { attrs: { href: _vm.globals.contextPath + product.url } },
                    [
                      product.images
                        ? _c("img", {
                            attrs: {
                              src: _vm.globals.getThumbnailImageUrl(
                                product.images
                              ),
                              alt: product.name
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !product.images
                        ? _c("img", {
                            attrs: {
                              src:
                                _vm.globals.assetsPath + "images/no_image.svg",
                              alt: "No Image"
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("template", { slot: "details" }, [
                  _c(
                    "p",
                    { staticClass: "product-title product-title-trim mb-xs-2" },
                    [
                      _c("a", {
                        attrs: { href: _vm.globals.contextPath + product.url },
                        domProps: { innerHTML: _vm._s(product.name) }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "product-code mb-xs-2" }, [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(
                        _vm._s(_vm.i18n.productTile.itemId) +
                          " : " +
                          _vm._s(product.code)
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v(
                        _vm._s(_vm.i18n.productTile.itemId) +
                          " " +
                          _vm._s(product.code)
                      )
                    ]),
                    _vm._v(" "),
                    product.cmirCode
                      ? _c("span", [
                          _c("span", [_vm._v(" | ")]),
                          _vm._v(" "),
                          _c("span", { attrs: { tabindex: "0" } }, [
                            _vm._v(
                              _vm._s(_vm.i18n.productTile.cmir) +
                                " " +
                                _vm._s(product.cmirCode)
                            )
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  !(
                    product.stock.stockLevelStatus ===
                      _vm.ProductAvailability.LOW_STOCK ||
                    product.stock.stockLevelStatus.code ===
                      _vm.ProductAvailability.LOW_STOCK
                  ) ||
                  product.materialStatus === _vm.ProductAvailability.OBSOLETE ||
                  product.materialStatus ===
                    _vm.ProductAvailability.PREDISCONTINUED ||
                  product.offlineDate < _vm.currentDate
                    ? _c(
                        "p",
                        {
                          staticClass: "product-stock mb-xs-3",
                          attrs: { role: "status" }
                        },
                        [_vm._v(_vm._s(product.stock.stockStatusMessage))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "product-price mb-xs-3" }, [
                    !product.notPurchasable
                      ? _c("span", { staticClass: "product-current-price" }, [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Current price")
                          ]),
                          _vm._v(_vm._s(product.price.formattedValue))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    product.weblistPrice &&
                    _vm.globals.siteConfig.isSlashedPriceEnabled &&
                    !product.notPurchasable
                      ? _c(
                          "span",
                          { staticClass: "product-old-price" },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Slashed price")
                            ]),
                            _vm._v(" "),
                            _c("strike", [
                              _vm._v(
                                _vm._s(product.weblistPrice.formattedValue)
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "stepper-custom" }, [
                      _c("p", { staticClass: "product-quantity" }, [
                        _vm._v(_vm._s(_vm.i18n.productTile.quantity))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _c("vx-stepper-control", {
                            attrs: {
                              "min-value": 0,
                              maxValue: product.maxOrderQuantity || 999,
                              value: product.quantity
                            },
                            on: {
                              currentCount: function($event) {
                                _vm.handleQuantity(product.code, $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("template", { slot: "right-section-slot" }, [
                  !product.notPurchasable
                    ? _c("p", { staticClass: "product-total-price" }, [
                        _vm._v(
                          _vm._s(
                            _vm.getFormattedPrice(product.price.finalPrice)
                          )
                        )
                      ])
                    : _vm._e()
                ])
              ],
              2
            )
          })
        ),
        _vm._v(" "),
        _vm.purchasable.length
          ? _c("div", { staticClass: "col-xs-12 sub-total px-xs-0 py-xs-4" }, [
              _c("span", { staticClass: "pull-right" }, [
                _vm.totalQuantity === 1
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.i18n.quickOrder.subTotal) +
                          _vm._s(_vm.totalQuantity) +
                          " " +
                          _vm._s(_vm.i18n.quickOrder.item)
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm.i18n.quickOrder.subTotal) +
                          _vm._s(_vm.totalQuantity) +
                          " " +
                          _vm._s(_vm.i18n.quickOrder.items)
                      )
                    ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "total-price",
                    attrs: {
                      "aria-label":
                        "i18n.quickOrder.subTotalLabel" + _vm.subTotal,
                      role: "status"
                    }
                  },
                  [_vm._v(_vm._s(_vm.getFormattedPrice(_vm.subTotal)))]
                )
              ])
            ])
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-xs-12 add-cart-footer" }, [
      _c(
        "button",
        {
          staticClass:
            "btn btn-primary col-lg-3 col-md-4 col-xs-12 col-sm-4 mr-sm-3 mr-md-4 pull-right add-list-to-cart-btn",
          attrs: {
            disabled: !_vm.purchasable.length,
            "aria-label": _vm.i18n.quickOrder.addToCart
          },
          on: {
            click: function($event) {
              _vm.addItemsToCart()
            }
          }
        },
        [_vm._v(_vm._s(_vm.i18n.quickOrder.addToCart))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-51f1ec64", { render: render, staticRenderFns: staticRenderFns })
  }
}