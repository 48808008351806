/**
 * Banner text component
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import { eventBus } from '../../../../modules/event-bus';

export default {
  name: 'vx-uiux-banner-text',
  components: {},
  props: {
    uiuxTextData: Object,
    uiuxImageData: Object,
    componentTheme: String,
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      playIconSrc: `${globals.assetsPath}images/gp-pro/play-video-icon.png`,
    };
  },
  computed: {},
  mounted() {
    if (this.uiuxTextData.backgroundColor) {
      this.$refs.leftSide.style.backgroundColor = this.uiuxTextData.backgroundColor;
    }
    if (this.uiuxTextData.textAlignment) {
      this.$refs.leftSide.style.textAlign = this.uiuxTextData.textAlignment;
    }
    if (this.uiuxTextData.headerColor) {
      this.$refs.header.style.color = this.uiuxTextData.headerColor;
      if (this.$refs.header.childNodes[0] && this.$refs.header.childNodes[0].style) {
        this.$refs.header.childNodes[0].style.color = this.uiuxTextData.headerColor;
      }
    }
    if (this.uiuxTextData.textColor) {
      this.$refs.description.style.color = this.uiuxTextData.textColor;
    }
    if (this.uiuxTextData.ctaText && this.uiuxTextData.ctaLink && this.uiuxTextData.isExternalLink === true) {
      this.$refs.buttonAnchor.target = '_blank';
    }
  },
  methods: {
    openModal() {
      const videoSRC = this.uiuxImageData.videoSrc;
      eventBus.$emit('open-player', videoSRC);
    },
    onLazyLoad() {
      const backgroundImageURL = this.getResponsiveImage(this.uiuxImageData.imageSrcD, this.uiuxImageData.imageSrcT, this.uiuxImageData.imageSrcM);
      this.$refs.rightSide.style.background = `url("${backgroundImageURL}") no-repeat center`;
      this.$refs.rightSide.style.backgroundSize = 'cover';
    },
    onMouseOver() {
      this.playIconSrc = `${globals.assetsPath}images/gp-pro/play-video-icon-dark.png`;
    },
    onMouseLeave() {
      this.playIconSrc = `${globals.assetsPath}images/gp-pro/play-video-icon.png`;
    },
  },
};
