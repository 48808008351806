var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-hero-banner" },
    [
      _vm.heroBannerDataCopy.componetType === "imageWrapper"
        ? _vm._t("default", [
            _vm.heroBannerDataCopy.imageLink
              ? _c(
                  "a",
                  {
                    staticClass: "banner",
                    class: _vm.heroBannerDataCopy.componentTheme,
                    style: [_vm.backgroundImage],
                    attrs: {
                      href: _vm.heroBannerDataCopy.imageLink
                        ? _vm.heroBannerDataCopy.isExternalImage
                          ? _vm.heroBannerDataCopy.imageLink
                          : _vm.globals.getUrlWithContextPath(
                              _vm.heroBannerDataCopy.imageLink
                            )
                        : "javascript:void(0)",
                      "aria-label": [_vm.backgroundAlt],
                      target: _vm.heroBannerDataCopy.isExternalImage
                        ? "_blank"
                        : ""
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "banner__promotion",
                        class: _vm.heroBannerDataCopy.textPosition
                      },
                      [
                        _vm.heroBannerDataCopy.headerLogo
                          ? _c("div", { staticClass: "headerLogo" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.heroBannerDataCopy.headerLogo,
                                  alt:
                                    _vm.heroBannerDataCopy
                                      .headerLogoImageAltText
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.headingText
                          ? _c("div", {
                              staticClass: "banner__heading my-xs-2 my-sm-4",
                              style: {
                                color: _vm.heroBannerDataCopy.headingColor
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.headingText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.subHeadingText
                          ? _c("h3", {
                              staticClass: "banner__subheading my-xs-2 my-sm-4",
                              style: {
                                color: _vm.heroBannerDataCopy.subHeadingColor
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.subHeadingText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.ctaText &&
                        _vm.heroBannerDataCopy.linkTo &&
                        !_vm.heroBannerDataCopy.jumpToHtml
                          ? _c("a", {
                              staticClass: "btn",
                              class: _vm.heroBannerDataCopy.ctaStyle,
                              style: {
                                color: _vm.heroBannerDataCopy.ctaColor,
                                backgroundColor:
                                  _vm.heroBannerDataCopy.ctaBgColor
                              },
                              attrs: {
                                href: _vm.heroBannerDataCopy.isExternalLink
                                  ? _vm.heroBannerDataCopy.linkTo
                                  : _vm.globals.getUrlWithContextPath(
                                      _vm.heroBannerDataCopy.linkTo
                                    ),
                                target: _vm.heroBannerDataCopy.isExternalLink
                                  ? "_blank"
                                  : ""
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.ctaText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.ctaText &&
                        _vm.heroBannerDataCopy.linkTo &&
                        _vm.heroBannerDataCopy.jumpToHtml
                          ? _c("a", {
                              staticClass: "btn",
                              class: _vm.heroBannerDataCopy.ctaStyle,
                              style: {
                                color: _vm.heroBannerDataCopy.ctaColor,
                                backgroundColor:
                                  _vm.heroBannerDataCopy.ctaBgColor
                              },
                              attrs: {
                                href: _vm.heroBannerDataCopy.isExternalLink
                                  ? _vm.heroBannerDataCopy.linkTo
                                  : _vm.globals.getUrlWithContextPath(
                                      _vm.heroBannerDataCopy.linkTo
                                    ),
                                target: _vm.heroBannerDataCopy.isExternalLink
                                  ? "_blank"
                                  : ""
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.ctaText
                                )
                              },
                              on: {
                                click: function($event) {
                                  _vm.emitScrollSignal(
                                    _vm.heroBannerDataCopy.linkTo
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "banner",
                    class: _vm.heroBannerDataCopy.componentTheme,
                    style: [_vm.backgroundImage],
                    attrs: { "aria-label": [_vm.backgroundAlt] }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "banner__promotion",
                        class: [
                          _vm.heroBannerDataCopy.textPosition,
                          "hero-banner-text-content"
                        ],
                        style: [_vm.backgroundStyle]
                      },
                      [
                        _vm.heroBannerDataCopy.headerLogo
                          ? _c("div", { staticClass: "headerLogo" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.heroBannerDataCopy.headerLogo,
                                  alt:
                                    _vm.heroBannerDataCopy
                                      .headerLogoImageAltText
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.headingText
                          ? _c("div", {
                              staticClass: "banner__heading my-xs-2 my-sm-4 ",
                              style: {
                                color: _vm.heroBannerDataCopy.headingColor
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.headingText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.subHeadingText
                          ? _c("h3", {
                              staticClass: "banner__subheading my-xs-2 my-sm-4",
                              style: {
                                color: _vm.heroBannerDataCopy.subHeadingColor
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.subHeadingText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.ctaText &&
                        _vm.heroBannerDataCopy.linkTo &&
                        !_vm.heroBannerDataCopy.jumpToHtml
                          ? _c("a", {
                              staticClass: "btn",
                              class: _vm.heroBannerDataCopy.ctaStyle,
                              style: {
                                color: _vm.heroBannerDataCopy.ctaColor,
                                backgroundColor:
                                  _vm.heroBannerDataCopy.ctaBgColor
                              },
                              attrs: {
                                href: _vm.heroBannerDataCopy.isExternalLink
                                  ? _vm.heroBannerDataCopy.linkTo
                                  : _vm.globals.getUrlWithContextPath(
                                      _vm.heroBannerDataCopy.linkTo
                                    ),
                                target: _vm.heroBannerDataCopy.isExternalLink
                                  ? "_blank"
                                  : ""
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.ctaText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.ctaText &&
                        _vm.heroBannerDataCopy.linkTo &&
                        _vm.heroBannerDataCopy.jumpToHtml
                          ? _c("a", {
                              staticClass: "btn",
                              class: _vm.heroBannerDataCopy.ctaStyle,
                              style: {
                                color: _vm.heroBannerDataCopy.ctaColor,
                                backgroundColor:
                                  _vm.heroBannerDataCopy.ctaBgColor
                              },
                              attrs: {
                                href: _vm.heroBannerDataCopy.isExternalLink
                                  ? _vm.heroBannerDataCopy.linkTo
                                  : _vm.globals.getUrlWithContextPath(
                                      _vm.heroBannerDataCopy.linkTo
                                    ),
                                target: _vm.heroBannerDataCopy.isExternalLink
                                  ? "_blank"
                                  : ""
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.ctaText
                                )
                              },
                              on: {
                                click: function($event) {
                                  _vm.emitScrollSignal(
                                    _vm.heroBannerDataCopy.linkTo
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ]
                )
          ])
        : _vm.heroBannerDataCopy.componetType === "divWrapper"
        ? _vm._t("default", [
            _vm.heroBannerDataCopy.showTextFullWidth
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "banner banner__text-full-width",
                      style: {
                        backgroundColor: _vm.heroBannerDataCopy.wrapperBg
                      }
                    },
                    [
                      _c("div", { staticClass: "banner__promotion p-xs-4" }, [
                        _vm.heroBannerDataCopy.headerLogo
                          ? _c("div", { staticClass: "headerLogo" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.heroBannerDataCopy.headerLogo,
                                  alt:
                                    _vm.heroBannerDataCopy
                                      .headerLogoImageAltText
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.headingText
                          ? _c("div", {
                              staticClass:
                                "banner__heading mt-xs-0 my-xs-2 my-sm-4 ",
                              style: {
                                color: _vm.heroBannerDataCopy.headingColor
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.headingText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.subHeadingText
                          ? _c("h3", {
                              staticClass: "banner__subheading",
                              style: {
                                color: _vm.heroBannerDataCopy.subHeadingColor
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.subHeadingText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.ctaText &&
                        _vm.heroBannerDataCopy.linkTo &&
                        !_vm.heroBannerDataCopy.jumpToHtml
                          ? _c("a", {
                              staticClass: "btn",
                              class: _vm.heroBannerDataCopy.ctaStyle,
                              style: {
                                color: _vm.heroBannerDataCopy.ctaColor,
                                backgroundColor:
                                  _vm.heroBannerDataCopy.ctaBgColor
                              },
                              attrs: {
                                href: _vm.heroBannerDataCopy.isExternalLink
                                  ? _vm.heroBannerDataCopy.linkTo
                                  : _vm.globals.getUrlWithContextPath(
                                      _vm.heroBannerDataCopy.linkTo
                                    ),
                                target: _vm.heroBannerDataCopy.isExternalLink
                                  ? "_blank"
                                  : ""
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.ctaText
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.heroBannerDataCopy.ctaText &&
                        _vm.heroBannerDataCopy.linkTo &&
                        _vm.heroBannerDataCopy.jumpToHtml
                          ? _c("a", {
                              staticClass: "btn",
                              class: _vm.heroBannerDataCopy.ctaStyle,
                              style: {
                                color: _vm.heroBannerDataCopy.ctaColor,
                                backgroundColor:
                                  _vm.heroBannerDataCopy.ctaBgColor
                              },
                              attrs: {
                                href: _vm.heroBannerDataCopy.isExternalLink
                                  ? _vm.heroBannerDataCopy.linkTo
                                  : _vm.globals.getUrlWithContextPath(
                                      _vm.heroBannerDataCopy.linkTo
                                    ),
                                target: _vm.heroBannerDataCopy.isExternalLink
                                  ? "_blank"
                                  : ""
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.heroBannerDataCopy.ctaText
                                )
                              },
                              on: {
                                click: function($event) {
                                  _vm.emitScrollSignal(
                                    _vm.heroBannerDataCopy.linkTo
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass: "banner banner__div",
                      class: _vm.heroBannerDataCopy.componentTheme,
                      style: {
                        backgroundColor: _vm.heroBannerDataCopy.wrapperBg
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "banner__promotion",
                          class: _vm.heroBannerDataCopy.textPosition
                        },
                        [
                          _vm.heroBannerDataCopy.headerLogo
                            ? _c("div", { staticClass: "headerLogo" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.heroBannerDataCopy.headerLogo,
                                    alt:
                                      _vm.heroBannerDataCopy
                                        .headerLogoImageAltText
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.heroBannerDataCopy.headingText
                            ? _c("div", {
                                staticClass: "banner__heading",
                                style: {
                                  color: _vm.heroBannerDataCopy.headingColor
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.heroBannerDataCopy.headingText
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.heroBannerDataCopy.subHeadingText
                            ? _c("h3", {
                                staticClass: "banner__subheading",
                                style: {
                                  color: _vm.heroBannerDataCopy.subHeadingColor
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.heroBannerDataCopy.subHeadingText
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.heroBannerDataCopy.ctaText &&
                          _vm.heroBannerDataCopy.linkTo &&
                          !_vm.heroBannerDataCopy.jumpToHtml
                            ? _c("a", {
                                staticClass: "btn",
                                class: _vm.heroBannerDataCopy.ctaStyle,
                                style: {
                                  color: _vm.heroBannerDataCopy.ctaColor,
                                  backgroundColor:
                                    _vm.heroBannerDataCopy.ctaBgColor
                                },
                                attrs: {
                                  href: _vm.heroBannerDataCopy.isExternalLink
                                    ? _vm.heroBannerDataCopy.linkTo
                                    : _vm.globals.getUrlWithContextPath(
                                        _vm.heroBannerDataCopy.linkTo
                                      ),
                                  target: _vm.heroBannerDataCopy.isExternalLink
                                    ? "_blank"
                                    : ""
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.heroBannerDataCopy.ctaText
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.heroBannerDataCopy.ctaText &&
                          _vm.heroBannerDataCopy.linkTo &&
                          _vm.heroBannerDataCopy.jumpToHtml
                            ? _c("a", {
                                staticClass: "btn",
                                class: _vm.heroBannerDataCopy.ctaStyle,
                                style: {
                                  color: _vm.heroBannerDataCopy.ctaColor,
                                  backgroundColor:
                                    _vm.heroBannerDataCopy.ctaBgColor
                                },
                                attrs: {
                                  href: _vm.heroBannerDataCopy.isExternalLink
                                    ? _vm.heroBannerDataCopy.linkTo
                                    : _vm.globals.getUrlWithContextPath(
                                        _vm.heroBannerDataCopy.linkTo
                                      ),
                                  target: _vm.heroBannerDataCopy.isExternalLink
                                    ? "_blank"
                                    : ""
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.heroBannerDataCopy.ctaText
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    _vm.emitScrollSignal(
                                      _vm.heroBannerDataCopy.linkTo
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
          ])
        : _vm.heroBannerDataCopy.componetType === "videoWrapper"
        ? _vm._t("default", [
            _c(
              "div",
              {
                staticClass: "banner banner__video-layer",
                class: _vm.heroBannerDataCopy.componentTheme,
                style: [_vm.backgroundImage],
                attrs: { "aria-label": [_vm.backgroundAlt] }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "banner__promotion",
                    class: _vm.heroBannerDataCopy.textPosition
                  },
                  [
                    _vm.heroBannerDataCopy.headingText
                      ? _c("div", {
                          staticClass: "banner__heading",
                          style: { color: _vm.heroBannerDataCopy.headingColor },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.heroBannerDataCopy.headingText
                            )
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.heroBannerDataCopy.subHeadingText
                      ? _c("h3", {
                          staticClass: "banner__subheading",
                          style: {
                            color: _vm.heroBannerDataCopy.subHeadingColor
                          },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.heroBannerDataCopy.subHeadingText
                            )
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.heroBannerDataCopy.ctaText
                      ? _c("a", {
                          staticClass: "btn",
                          class: _vm.heroBannerDataCopy.ctaStyle,
                          style: {
                            color: _vm.heroBannerDataCopy.ctaColor,
                            backgroundColor: _vm.heroBannerDataCopy.ctaBgColor
                          },
                          attrs: {
                            href: "javascript:void(0)",
                            target: _vm.heroBannerDataCopy.isExternalLink
                              ? "_blank"
                              : ""
                          },
                          domProps: {
                            innerHTML: _vm._s(_vm.heroBannerDataCopy.ctaText)
                          },
                          on: { click: _vm.openModal }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "banner__video-play-icon banner__position__center icon-play",
                    style: { color: _vm.heroBannerDataCopy.playIconColor },
                    attrs: {
                      "aria-label": _vm.i18n.iconPlayTitle,
                      href: "javascript:void(0)",
                      title: _vm.i18n.iconPlayTitle
                    },
                    on: { click: _vm.openModal }
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(_vm._s(_vm.i18n.iconPlayTitle))
                    ])
                  ]
                )
              ]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-04a3a8a6", { render: render, staticRenderFns: staticRenderFns })
  }
}