// import shippingMethodDetails from './vx-multiple-shipping-method-mock';
import vxDropdownPrimary from '../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import vxProductTile from '../../common/vx-product-tile/vx-product-tile.vue';
import globals from '../../common/globals';
import vxAccordion from '../../common/vx-accordion/vx-accordion.vue';
import CheckoutService from '../../common/services/checkout-service';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import {
  checkoutEventBus,
} from '../../../modules/event-bus';
import {
  ProductAvailability,
  discount,
} from '../../common/mixins/vx-enums';
import mobileMixin from '../../common/mixins/mobile-mixin';
import vxProductIcons from '../../common/vx-product-icons/vx-product-icons.vue';

export default {
  name: 'vx-multiple-shipping-method',
  mixins: [mobileMixin],
  components: {
    vxDropdownPrimary,
    vxProductTile,
    vxAccordion,
    vxSpinner,
    vxProductIcons,
  },
  props: {
    // Indicates if user is trying to change shipping method
    isEditable: Boolean,
    // Text coming from property file
    i18n: Object,
    // Checkout related data
    checkoutData: Object,
    // Indicates if split address is opted in shipping address section
    splitApplicable: Boolean,
    // Indicates if user changed address
    addressChanged: Boolean,
  },
  data() {
    return {
      selectedShippingMethod: [],
      shippingMethodDetails: {},
      globals,
      formattedEntries: {},
      ProductAvailability,
      checkoutService: new CheckoutService(),
      dataLoaded: false,
      discount,
    };
  },
  computed: {},
  mounted() {
    this.getShippingMethod();
    checkoutEventBus.$on('cart-entries-count-updated', () => {
      this.getShippingMethod();
    });
  },
  methods: {
    /**
     * This function triggers the call that gets all the shipping method for all the address selected in multiple shipping address section
     */
    getShippingMethod() {
      this.checkoutService.getMultipleMethod({}, this.handleGetMethodResponse, this.handleGetMethodError);
      this.$refs.spinner.showSpinner();
    },
    /**
     * This function handles the response of multiple shipping method call
     */
    handleGetMethodResponse(response) {
      this.$refs.spinner.hideSpinner();
      if (response && response.data) {
        this.shippingMethodDetails = response.data;
        this.selectedShippingMethodData();
        this.showSavedData();
        this.formattedEntries = this.arrayToObject(this.checkoutData.entries, 'entryNumber');
      }
    },
    /**
     * This function handles the error of multiple shipping method call
     */
    handleGetMethodError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * This function creates array for shipping method dropdown
     * @param  {Array} array deliveryModes array
     */
    createDeliveryMethodArray(array) {
      const deliveryMethods = [];
      array.forEach((item) => {
        deliveryMethods.push({
          label: `${item.name} : ${item.deliveryCost.formattedValue}`,
          value: item.code,
          // deliveryDetails: item.description,
        });
      });
      return deliveryMethods;
    },
    /**
     * This function gets the count of products for each address group
     * @param  {Array} array deliveryGroup array
     */
    getProductCount(array) {
      let productCount = 0;
      array.value.splitEntries.forEach((item) => {
        productCount += Number(item.qty);
      });
      return productCount;
    },
    /**
     * This function creates an object that stores the user input (like instructions)
     * and other related data like shipping method name and price that needs to shown to the user
     */
    selectedShippingMethodData() {
      this.selectedShippingMethod = [];
      this.shippingMethodDetails.deliveryGroup.forEach((item) => {
        this.selectedShippingMethod.push({
          // value: item.value.deliveryMode.code || '',
          value: this.setValue(item.value),
          instructions: item.value.deliveryInstruction || '',
          deliveryDetails: '',
          label: '',
        });
      });
      this.dataLoaded = true;
    },
    /**
     * This function sets delivery mode
     * @param  {Object} item deliveryMode Object
     */
    setValue(item) {
      let value = '';
      if (item.deliveryMode) {
        value = item.deliveryMode.code;
      }
      return value;
    },
    /**
     * This function shows already saved data if present in the getShippingMethod call
     * (if user refreshes the page after saving multiple shipping method section)
     */
    showSavedData() {
      let isDataAvailable = false;
      this.$nextTick(function() {
        this.shippingMethodDetails.deliveryGroup.forEach((item, index) => {
          const self = this;
          if (item.value.deliveryMode && !this.addressChanged) {
            checkoutEventBus.$emit('show-saved-mode');
            isDataAvailable = true;
            const { dropdownValues } = self.$refs.deliveryMethodDropdown[index];
            dropdownValues.forEach((value) => {
              if (value.value === item.value.deliveryMode.code) {
                this.selectedShippingMethod[index].value = value.value;
                this.selectedShippingMethod[index].label = value.label;
                this.selectedShippingMethod[index].deliveryDetails = value.deliveryDetails;
              }
            });
          } else {
            checkoutEventBus.$emit('show-edit-mode');
          }
        });
        if (isDataAvailable) {
          this.saveMultipleMethod();
        }
      });
    },
    /**
     * This function stores the shipping method from dropdown values when user selectes one
     * @param  {Number} index dropdown index
     */
    handleSelected(event, index) {
      this.selectedShippingMethod[index].value = event.value;
      this.selectedShippingMethod[index].deliveryDetails = event.deliveryDetails;
      this.selectedShippingMethod[index].label = event.label;
    },
    /**
     * This function sets the lowest shipping method as default in the shipping method dropdown
     * or set already saved data if present in the getShippingMethod call in the shipping method dropdown
     * @param  {Number} index dropdown index
     */
    handleDefault(index) {
      const { dropdownValues } = this.$refs.deliveryMethodDropdown[index];
      if (this.selectedShippingMethod[index].value) {
        dropdownValues.forEach((item) => {
          if (item.value === this.selectedShippingMethod[index].value) {
            this.$refs.deliveryMethodDropdown[index].setDropdownLabel(item.label);
            this.selectedShippingMethod[index].value = item.value;
            this.selectedShippingMethod[index].label = item.label;
            this.selectedShippingMethod[index].deliveryDetails = item.deliveryDetails;
          }
        });
      } else {
        this.$refs.deliveryMethodDropdown[index].setDropdownLabel(dropdownValues[0].label);
        this.selectedShippingMethod[index].value = dropdownValues[0].value;
        this.selectedShippingMethod[index].label = dropdownValues[0].label;
        this.selectedShippingMethod[index].deliveryDetails = dropdownValues[0].deliveryDetails;
      }
    },
    /**
     * This function creates request body for saving Multiple Shipping Method Section
     */
    createRequestBody() {
      const requestBody = [];
      this.shippingMethodDetails.deliveryGroup.forEach((item, index) => {
        requestBody.push({
          deliveryInstruction: this.selectedShippingMethod[index].instructions,
          deliveryMode: {
            code: this.selectedShippingMethod[index].value,
            name: this.selectedShippingMethod[index].label.split(':')[0],
          },
          splitEntries: [],
        });
        item.value.splitEntries.forEach((entry) => {
          requestBody[index].splitEntries.push({
            code: entry.code,
          });
        });
      });
      return requestBody;
    },
    /**
     * This function saves multiple Shipping method
     */
    saveMultipleMethod() {
      const requestConfig = {};
      requestConfig.data = this.createRequestBody();
      this.checkoutService.saveMultipleMethod(requestConfig, this.handleSaveMethodResponse, this.handleSaveMethodError);
      this.$refs.spinner.showSpinner();
    },
    /**
     * This function handles response of save multiple Shipping method call
     */
    handleSaveMethodResponse() {
      checkoutEventBus.$emit('show-saved-mode');
      this.$refs.spinner.hideSpinner();
      checkoutEventBus.$emit('update-cart');
    },
    /**
     * This function handles error of save multiple Shipping method call
     */
    handleSaveMethodError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * This function converts array to obejct with a particular key
     * @param  {Array} array array to be modified into object
     * @param  {String} keyField key to be used for the modified object
     */
    arrayToObject(array, keyField) {
      const modifiedObj = array.reduce((obj, item) => {
        // eslint-disable-next-line no-param-reassign
        obj[item[keyField]] = item;
        return obj;
      }, {});
      return modifiedObj;
    },
  },
};
