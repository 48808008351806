var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "vx-shopping-cart row" },
    [
      _c(
        "vx-modal",
        {
          ref: "shareCartModal",
          attrs: { size: "extra-small", heading: _vm.i18n.shareListHeading }
        },
        [
          _c("vx-share-cart", {
            attrs: {
              slot: "component",
              type: "modal",
              "cart-id": _vm.shoppingCartData.guid,
              i18n: _vm.i18n
            },
            on: {
              "share-success": _vm.handleShareCartSuccess,
              "share-error": _vm.handleShareCartError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "saveCartModal",
          attrs: { size: "extra-small", heading: _vm.i18n.saveListHeading }
        },
        [
          _c("vx-save-cart", {
            attrs: {
              slot: "component",
              "cart-guid": _vm.shoppingCartData.guid,
              i18n: _vm.i18n
            },
            on: { "list-saved": _vm.handleListSaved },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.guestListName
        ? [
            _c("vx-save-cart", {
              attrs: {
                i18n: _vm.i18n,
                "cart-guid": _vm.shoppingCartData.guid,
                "guest-list-name": _vm.guestListName
              },
              on: { "list-saved": _vm.handleListSaved }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-xs-4 mt-sm-5 mt-md-3 cart-heading-container" },
        [
          _c("div", [
            _vm.giveAwayCouponDescription
              ? _c("h5", { staticClass: "pt-md-2 pt-sm-0 pb-xs-3 my-xs-0" }, [
                  _vm._v(_vm._s(_vm.giveAwayCouponDescription))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "shopping-cart-heading" }, [
            _c("h1", { staticClass: "cart-heading" }, [
              _vm._v(_vm._s(_vm.i18n.shoppingCartHeading))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "item no-of-cart-items" }, [
              _vm._v(
                "(" + _vm._s(_vm.shoppingCartData.totalItems) + "\n        "
              ),
              _vm.shoppingCartData.totalItems === 1
                ? _c("span", [_vm._v(_vm._s(_vm.i18n.item))])
                : _c("span", [_vm._v(_vm._s(_vm.i18n.items))]),
              _vm._v(")")
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-xs-12 pt-xs-4 px-xs-0 mb-sm-0 mb-xs-4 cart-id-container"
        },
        [
          _c("span", { staticClass: "cart-id" }, [
            _vm._v(
              _vm._s(_vm.i18n.cartId) + ": " + _vm._s(_vm.shoppingCartData.code)
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "shift-right" }, [
            _vm.globals.siteConfig.isSaveCartEnabled
              ? _c(
                  "span",
                  {
                    staticClass: "save-cart pr-xs-4",
                    attrs: { role: "presentation" }
                  },
                  [
                    _c("span", {
                      staticClass: "cart-icons icon-add-to-list",
                      attrs: {
                        "aria-label": _vm.i18n.iconAddToListTitle,
                        tabindex: "0",
                        role: "button"
                      },
                      on: {
                        click: function($event) {
                          _vm.handleSaveACart($event)
                        },
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter-space",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          _vm.handleSaveACart($event)
                        }
                      }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "share-cart" }, [
              _c("span", {
                staticClass: "cart-icons icon-share",
                attrs: {
                  "aria-label": _vm.i18n.iconShareTitle,
                  tabindex: "0",
                  role: "link"
                },
                on: {
                  click: function($event) {
                    _vm.openShareCartModal($event)
                  },
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k(
                        $event.keyCode,
                        "enter-space",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    _vm.openShareCartModal($event)
                  }
                }
              })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xs-12 product-tile" },
        _vm._l(_vm.individualProducts, function(product) {
          return product.visible
            ? _c("vx-cart-product-tile", {
                key: product.product.code,
                attrs: {
                  "promotion-data": _vm.promotionData,
                  "product-data": product,
                  "is-b2b-lease": _vm.customerLeaseable,
                  i18n: _vm.i18n,
                  "installation-price": _vm.installationPrice
                }
              })
            : _vm._e()
        })
      ),
      _vm._v(" "),
      _vm.globals.isB2B()
        ? _c("div", { staticClass: "row d-flex pt-xs-4 px-xs-3" }, [
            _c(
              "a",
              {
                attrs: { href: "#", role: "button" },
                on: {
                  click: function($event) {
                    _vm.clearCart()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.i18n.clearCart))]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5cd2556d", { render: render, staticRenderFns: staticRenderFns })
  }
}