/**
 * Tiles carousel component
 */
import {
  swiper,
  swiperSlide,
} from 'vue-awesome-swiper';
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import { eventBus } from '../../../../modules/event-bus';

export default {
  name: 'vx-uiux-tiles-carousel',
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    carouselData: Object,
    swiperModifierClass: {
      type: String,
      default: '',
    },
    componentTheme: String,
    enableSimulateTouch: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      swiper: '',
      showNavigationArrows: false,
      firstSlide: true,
      lastSlide: false,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 56,
        freeMode: false,
        watchOverflow: true,
        loop: false,
        breakpoints: {
          767: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          1199: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 56,
          },
        },
        simulateTouch: this.enableSimulateTouch,
      },
      showSelect: false,
      viewPort: {
        mobile: 767,
        desktop: 1440,
        tablet: 1199,
      },
      i18n: this.$root.messages.common.slider,
      swiperWrapperClass: '.swiper-container',
    };
  },
  computed: {},
  created() {
    if (this.showSelectCheckbox) {
      this.showSelect = true;
    }
    if (this.swiperModifierClass) {
      this.swiperOptions.containerModifierClass = this.swiperModifierClass;
    }
  },
  mounted() {
    if (this.carouselData.gpRotatingData && this.carouselData.gpRotatingData.length > 0) {
      if (this.swiperModifierClass) {
        if (document.querySelector(`.${this.swiperModifierClass.concat('initialized')}`)) {
          this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'initialized');
        } else if (document.querySelector(`.${this.swiperModifierClass.concat('horizontal')}`)) {
          this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'horizontal');
        } else if (document.querySelector(`.${this.swiperModifierClass.concat('android')}`)) {
          this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'android');
        }
      }
      const self = this;
      self.onInit();
      self.swiper = this.$el.querySelector('.swiper-container').swiper;
      self.swiper.on('reachEnd', () => {
        self.firstSlide = false;
        self.lastSlide = true;
      });
      self.swiper.on('reachBeginning', () => {
        self.firstSlide = true;
        self.lastSlide = false;
      });
      window.addEventListener('resize', () => {
        self.onInit();
      });
    }
  },
  watch: {
    carouselData() {
      this.onInit();
    },
  },
  methods: {
    /**
         * Function sets the slides as per Viewport
         */
    onInit() {
      const self = this;
      if (self.isMobile()) {
        self.showNavigationArrows = self.carouselData.gpRotatingData.length > 1;
      } else if (self.isTablet()) {
        self.showNavigationArrows = self.carouselData.gpRotatingData.length > 2;
      } else {
        self.showNavigationArrows = self.carouselData.gpRotatingData.length > 3;
      }
    },
    /*
         * Function sets the slides to previous product
         */
    slidePrev() {
      this.swiper.slidePrev();
      this.lastSlide = false;
    },
    /**
         * Function sets the slides to next product
         */
    slideNext() {
      this.swiper.slideNext();
      this.firstSlide = false;
    },
    openModal(videoSRC) {
      if (videoSRC) {
        eventBus.$emit('open-player', videoSRC);
      }
    },
  },
};
