/**
 * News and knowledge articles
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-uiux-news-knowledge',
  components: {},
  props: {
    componentTitle: {
      type: String,
    },
    tabsData: {
      type: Array,
    },
    componentTheme: {
      type: String,
      default: '',
    },
    displayCount: {
      type: Number,
      default: 2,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      additionalArticlesShown: true,
      selectedCategory: null,
      target: '',
      tabs: '',
      allTabs: '',
      allTabPanes: '',
      selectedTab: '',
      selectedTabPane: '',
      tabParent: '',
      selectedTabPos: '',
      allItems: '',
      firstItem: '',
      lastItem: '',
    };
  },
  computed: {},
  mounted() {
    if (this.tabsData[0]) {
      this.selectCategory(this.tabsData[0].gpMarketingCategoryData.marketingCategoryHeader);
    }
    this.hideAdditionalArticles();
    this.tabs = document.querySelectorAll('[role="tab"]');
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category;
    },
    replaceSpaceWithDash(string) {
      return string.replace(/ /g, '-');
    },
    reverseArray(array) {
      return array.slice().reverse();
    },
    showAdditionalArticles() {
      const self = this;
      this.additionalArticlesShown = true;
      for (let x = 0; x < this.tabsData.length; x += 1) {
        let category = this.tabsData[x].gpMarketingCategoryData.marketingCategoryHeader;
        category = this.replaceSpaceWithDash(category);
        document.querySelectorAll(`.category.${category} .article`).forEach((item, index) => {
          if (index > (self.displayCount - 1)) {
            const articleArray = Array.from(item.querySelectorAll('.article a'));
            articleArray.forEach((linkEL) => {
              linkEL.setAttribute('id', 'show-article');
              linkEL.setAttribute('tabindex', '0');
              document.getElementById('show-article').focus();
            });
            // eslint-disable-next-line no-param-reassign
            item.style.display = 'flex'; // TODO: maybe it'll be better to replace this with vue-based animation and v-if?
          }
        });
      }
    },
    hideAdditionalArticles() {
      const self = this;
      for (let x = 0; x < this.tabsData.length; x += 1) {
        let category = this.tabsData[x].gpMarketingCategoryData.marketingCategoryHeader;
        category = this.replaceSpaceWithDash(category);
        document.querySelectorAll(`.category.${category} .article`).forEach((item, index) => {
          if (index > (self.displayCount - 1)) {
            // eslint-disable-next-line no-param-reassign
            item.style.display = 'none'; // TODO: maybe it'll be better to replace this with vue-based animation and v-if?
          }
        });
      }
    },
    /**
     *  Method to enable arrow navigation
    * */
    arrowNavigation(event) {
      this.target = event.target;
      const keyCode = event.keyCode || event.which;
      for (const [key, value] of Object.entries(this.tabs)) {
        if (this.target.innerText === `${value.innerText}`) {
          // Left Arrow
          if (key === 0 && keyCode === 37) {
            this.tabs[this.tabs.length - 1].focus();
          } else if (keyCode === 37) {
            this.tabs[parseInt(key, 10) - 1].focus();
          }
          // Right Arrow
          if (key === this.tabs.length - 1 && keyCode === 39) {
            this.tabs[0].focus();
          } else if (keyCode === 39) {
            this.tabs[parseInt(key, 10) + 1].focus();
          }
        }
      }
    },
    /**
     *  Method to determine the last and first element in the tabpanel
    * */
    focusedElement(e) {
      const keyCode = e.keyCode || e.which;
      this.allTabs = this.$el.querySelectorAll('.tab');
      if (this.allTabs.length > 0) {
        this.selectedTab = this.$el.querySelector('.tab.selected');
        this.tabParent = this.selectedTab.parentNode;
        this.selectedTabPos = Array.prototype.indexOf.call(this.tabParent.children, this.selectedTab);
        this.allTabPanes = this.$el.querySelectorAll('.category');
        this.selectedTabPane = this.allTabPanes[this.selectedTabPos].classList;
        this.allItems = this.$el.querySelectorAll(`.${this.selectedTabPane[1]} .article:not([style]) a:last-child`);
        this.firstItem = this.$el.querySelector(`.${this.selectedTabPane[1]} :first-child.article:not([style])>a`);
        this.lastItem = this.allItems[this.allItems.length - 1];
        if (keyCode === 9 && this.selectedTab.innerText === this.selectedTabPane[1].replace(/-/g, ' ') && document.activeElement === this.lastItem && !e.shiftKey) {
          if (this.selectedTabPos === this.allTabs.length - 1) {
            this.selectCategory(this.tabsData[0].gpMarketingCategoryData.marketingCategoryHeader);
          } else {
            this.selectCategory(this.tabsData[this.selectedTabPos + 1].gpMarketingCategoryData.marketingCategoryHeader);
            this.selectedTab.focus();
          }
        }
      }
    },
  },
};
