var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-overview" },
    [
      _c(
        "vx-accordion",
        {
          attrs: {
            "is-slot-based": "",
            opened: _vm.globals.getIsSubscription()
          }
        },
        [
          _vm.globals.getIsSubscription()
            ? _c(
                "span",
                {
                  staticClass: "bold-heading",
                  attrs: { slot: "heading" },
                  slot: "heading"
                },
                [_vm._v(_vm._s(_vm.i18n.subscribeHeading))]
              )
            : _c(
                "span",
                {
                  staticClass: "bold-heading",
                  attrs: { slot: "heading" },
                  slot: "heading"
                },
                [
                  _vm._v(
                    _vm._s(_vm.i18n.heading) +
                      " (" +
                      _vm._s(_vm.getCartQuantity()) +
                      ")"
                  )
                ]
              ),
          _vm._v(" "),
          _vm._l(_vm.visibleEntries, function(productEntry) {
            return [
              _vm._l(productEntry.splitEntries, function(entries) {
                return productEntry.splitEntries
                  ? _c(
                      "vx-product-tile",
                      { attrs: { slot: "component" }, slot: "component" },
                      [
                        productEntry.product &&
                        productEntry.product.images &&
                        productEntry.product.images.length !== 0 &&
                        productEntry.product.images[2] &&
                        productEntry.product.images[2].url
                          ? _c("template", { slot: "image-slot" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.globals.getNavigationUrl("empty") +
                                      productEntry.product.url
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.globals.getThumbnailImageUrl(
                                        productEntry.product.images
                                      ),
                                      alt:
                                        productEntry.product.images[2].altText
                                    }
                                  })
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !productEntry.product.images
                          ? _c("template", { slot: "image-slot" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.globals.getNavigationUrl("empty") +
                                      productEntry.product.url
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.globals.assetsPath +
                                        "images/no_image.svg",
                                      alt: "No Image"
                                    }
                                  })
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "details" },
                          [
                            productEntry &&
                            productEntry.product &&
                            productEntry.product.name
                              ? [
                                  _c(
                                    "p",
                                    { staticClass: "product-title mb-xs-2" },
                                    [
                                      _c("a", {
                                        attrs: {
                                          href:
                                            _vm.globals.getNavigationUrl(
                                              "empty"
                                            ) + productEntry.product.url
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            productEntry.product.name
                                          )
                                        }
                                      })
                                    ]
                                  )
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.i18n.itemId) +
                                  " : " +
                                  _vm._s(productEntry.product.code) +
                                  "\n                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "product-code mb-xs-2",
                                attrs: { "aria-hidden": "true" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.i18n.itemId) +
                                    " " +
                                    _vm._s(productEntry.product.code)
                                )
                              ]
                            ),
                            _vm._v(" "),
                            productEntry &&
                            productEntry.product &&
                            productEntry.product.materialStatus ===
                              _vm.ProductAvailability.OBSOLETE &&
                            productEntry.product.replacementProductCode &&
                            productEntry.product.replacementProductCode !== ""
                              ? _c("p", { staticClass: "error-text" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.i18n.discontinued) +
                                      " " +
                                      _vm._s(_vm.i18n.replacedBy) +
                                      " " +
                                      _vm._s(
                                        productEntry.product
                                          .replacementProductCode
                                      ) +
                                      "\n                    "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            productEntry &&
                            productEntry.product &&
                            productEntry.product.materialStatus ===
                              _vm.ProductAvailability.OBSOLETE &&
                            !productEntry.product.replacementProductCode
                              ? _c("p", { staticClass: "error-text" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.i18n.discontinued) +
                                      "\n                    "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            productEntry &&
                            productEntry.product &&
                            productEntry.product.materialStatus !==
                              _vm.ProductAvailability.OBSOLETE
                              ? [
                                  productEntry.product.stock
                                    .stockLevelStatus ===
                                    _vm.ProductAvailability.LOW_STOCK &&
                                  _vm.globals.siteConfig.showLowInventoryMessage
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "product-stock mb-xs-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.i18n.lowInventoryMessage
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  productEntry.product.stock
                                    .stockLevelStatus !==
                                    _vm.ProductAvailability.IN_STOCK &&
                                  productEntry.product.stock
                                    .stockLevelStatus !==
                                    _vm.ProductAvailability.LOW_STOCK
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "product-stock mb-xs-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(_vm.i18n.outOfStock) +
                                              "\n                        "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.globals.getIsSubscription() &&
                            !productEntry.product.notPurchasable
                              ? _c(
                                  "p",
                                  { staticClass: "product-price mb-xs-3" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "product-current-price" },
                                      [
                                        _c("span", { staticClass: "sr-only" }, [
                                          _vm._v(
                                            "\n                                Current price\n                            "
                                          )
                                        ]),
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              productEntry.basePrice
                                                .formattedValue
                                            ) +
                                            "\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    productEntry.product.weblistPrice &&
                                    _vm.globals.siteConfig.isSlashedPriceEnabled
                                      ? _c(
                                          "span",
                                          { staticClass: "product-old-price" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "sr-only" },
                                              [
                                                _vm._v(
                                                  "\n                                Slashed price\n                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("strike", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    productEntry.product
                                                      .weblistPrice
                                                      .formattedValue
                                                  ) +
                                                  "\n                            "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "product-quantity mb-xs-2 mb-sm-3"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.i18n.quantity) +
                                    ": " +
                                    _vm._s(entries.qty)
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.promotionData[productEntry.entryNumber]
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "product-promo mb-xs-3 mr-sm-3"
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(_vm.i18n.promo) +
                                        ":\n                        " +
                                        _vm._s(
                                          _vm.promotionData[
                                            productEntry.entryNumber
                                          ]
                                        ) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.globals.getIsSubscription() &&
                            productEntry.subscriptionFrequency &&
                            productEntry.subscriptionFrequency.entry.length
                              ? _c(
                                  "p",
                                  {
                                    ref: "subscribeInfo",
                                    refInFor: true,
                                    staticClass: "subscription"
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.i18n.subscribeFrequencyHeading
                                        ) +
                                        _vm._s(
                                          productEntry.subscriptionFrequency
                                            .entry[0].key
                                        ) +
                                        "\n                        "
                                    ),
                                    _c("span", {
                                      staticClass:
                                        "icon-alert-circle popover-subscribe",
                                      attrs: {
                                        role: "button",
                                        tabindex: "0",
                                        "aria-label":
                                          _vm.i18n.subscribeIconTitle,
                                        "data-toggle": "popover"
                                      },
                                      on: {
                                        mouseover: function($event) {
                                          _vm.openTooltip()
                                        }
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.restrictedEntries.length !== 0 &&
                            _vm.restrictionError
                              ? [
                                  _vm.restrictedItems(productEntry.product.code)
                                    ? _c("div", { staticClass: "pt-xs-3" }, [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "shipping-restriction"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.i18n.restrictionError)
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.giftWrapDetails, function(giftProduct) {
                              return _vm.globals.isB2C()
                                ? [
                                    giftProduct.entryNumber ===
                                    productEntry.entryNumber
                                      ? [
                                          giftProduct.giftWrapMessage ||
                                          giftProduct.giftWrapSelected
                                            ? [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "gift-message-heading mb-xs-2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.i18n.giftingOptions
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                giftProduct.giftWrapMessage
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "gift-message mb-xs-3"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.i18n.giftMessage
                                                          ) +
                                                            ":\n                                    " +
                                                            _vm._s(
                                                              giftProduct.giftWrapMessage
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                giftProduct.giftWrapSelected
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "gift-message"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.i18n.giftWrapped
                                                          ) +
                                                            ":\n                                    " +
                                                            _vm._s(
                                                              giftProduct.giftWrapCost
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            }),
                            _vm._v(" "),
                            _vm.isMultiple
                              ? [
                                  _c(
                                    "p",
                                    { staticClass: "shipping-method mb-xs-2" },
                                    [
                                      entries.deliveryAddress
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.i18n.delivery) + ":"
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      entries.deliveryMode
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getShippingMethod(
                                                  entries.deliveryMode.name
                                                )
                                              )
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  entries.deliveryAddress
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "shipping-address mb-xs-3 mb-sm-0"
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.getAddress(
                                                  entries.deliveryAddress
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "right-section-slot" },
                          [
                            _vm.globals.getIsSubscription()
                              ? [
                                  _vm.totalPrice.formattedValue
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "product-total-price",
                                          attrs: {
                                            role: "text",
                                            "aria-label":
                                              "total Price " +
                                              _vm.totalPrice.formattedValue
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.totalPrice.formattedValue
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  productEntry.product.subsPercentageDiscount
                                    ? _c("p", { staticClass: "subscription" }, [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.i18n.subscribeDiscount1
                                            ) +
                                            _vm._s(
                                              productEntry.product
                                                .subsPercentageDiscount
                                            ) +
                                            _vm._s(_vm.i18n.subscribeDiscount2)
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              : [
                                  productEntry.splitEntries &&
                                  productEntry.splitEntries.length &&
                                  productEntry.splitEntries.length === 1 &&
                                  productEntry.productPromotion &&
                                  productEntry.productPromotion.value &&
                                  productEntry.productPromotion.value !=
                                    _vm.discount.discountZero
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "product-discount",
                                          attrs: {
                                            role: "text",
                                            "aria-label":
                                              "discount " +
                                              productEntry.productPromotion
                                                .formattedValue
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                            -" +
                                              _vm._s(
                                                productEntry.productPromotion
                                                  .formattedValue
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  entries &&
                                  entries.price &&
                                  entries.price.formattedValue
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "product-total-price",
                                          attrs: {
                                            role: "text",
                                            "aria-label":
                                              "total price " +
                                              entries.price.formattedValue
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                entries.price.formattedValue
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                          ],
                          2
                        )
                      ],
                      2
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              !productEntry.splitEntries.length
                ? _c(
                    "vx-product-tile",
                    { attrs: { slot: "component" }, slot: "component" },
                    [
                      productEntry.product &&
                      productEntry.product.images &&
                      productEntry.product.images.length !== 0 &&
                      productEntry.product.images[2] &&
                      productEntry.product.images[2].url
                        ? _c("template", { slot: "image-slot" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.globals.getNavigationUrl("empty") +
                                    productEntry.product.url
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.globals.getThumbnailImageUrl(
                                      productEntry.product.images
                                    ),
                                    alt: productEntry.product.images[2].altText
                                  }
                                })
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !productEntry.product.images
                        ? _c("template", { slot: "image-slot" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.globals.getNavigationUrl("empty") +
                                    productEntry.product.url
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.globals.assetsPath +
                                      "images/no_image.svg",
                                    alt: "No Image"
                                  }
                                })
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "template",
                        { slot: "details" },
                        [
                          productEntry &&
                          productEntry.product &&
                          productEntry.product.name
                            ? [
                                _c(
                                  "p",
                                  { staticClass: "product-title mb-xs-2" },
                                  [
                                    _c("a", {
                                      attrs: {
                                        href:
                                          _vm.globals.getNavigationUrl(
                                            "empty"
                                          ) + productEntry.product.url
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          productEntry.product.name
                                        )
                                      }
                                    })
                                  ]
                                )
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.i18n.itemId) +
                                " : " +
                                _vm._s(productEntry.product.code) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "product-code mb-xs-2",
                              attrs: { "aria-hidden": "true" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.i18n.itemId) +
                                  " " +
                                  _vm._s(productEntry.product.code)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          productEntry &&
                          productEntry.product &&
                          productEntry.product.materialStatus ===
                            _vm.ProductAvailability.OBSOLETE &&
                          productEntry.product.replacementProductCode &&
                          productEntry.product.replacementProductCode !== ""
                            ? _c("p", { staticClass: "error-text" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.i18n.discontinued) +
                                    " " +
                                    _vm._s(_vm.i18n.replacedBy) +
                                    " " +
                                    _vm._s(
                                      productEntry.product
                                        .replacementProductCode
                                    ) +
                                    "\n                    "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          productEntry &&
                          productEntry.product &&
                          productEntry.product.materialStatus ===
                            _vm.ProductAvailability.OBSOLETE &&
                          !productEntry.product.replacementProductCode
                            ? _c("p", { staticClass: "error-text" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.i18n.discontinued) +
                                    "\n                    "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          productEntry &&
                          productEntry.product &&
                          productEntry.product.materialStatus !==
                            _vm.ProductAvailability.OBSOLETE
                            ? [
                                productEntry.product.stock.stockLevelStatus ===
                                  _vm.ProductAvailability.LOW_STOCK &&
                                _vm.globals.siteConfig.showLowInventoryMessage
                                  ? _c(
                                      "p",
                                      { staticClass: "product-stock mb-xs-3" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.i18n.lowInventoryMessage)
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                productEntry.product.stock.stockLevelStatus !==
                                  _vm.ProductAvailability.IN_STOCK &&
                                productEntry.product.stock.stockLevelStatus !==
                                  _vm.ProductAvailability.LOW_STOCK
                                  ? _c(
                                      "p",
                                      { staticClass: "product-stock mb-xs-3" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.i18n.outOfStock)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.globals.getIsSubscription() &&
                          !productEntry.product.notPurchasable
                            ? _c(
                                "p",
                                { staticClass: "product-price mb-xs-3" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "product-current-price" },
                                    [
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(
                                          "\n                                Current price\n                            "
                                        )
                                      ]),
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            productEntry.basePrice
                                              .formattedValue
                                          ) +
                                          "\n                        "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  productEntry.product.weblistPrice &&
                                  _vm.globals.siteConfig.isSlashedPriceEnabled
                                    ? _c(
                                        "span",
                                        { staticClass: "product-old-price" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "sr-only" },
                                            [
                                              _vm._v(
                                                "\n                                Slashed price\n                            "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("strike", [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  productEntry.product
                                                    .weblistPrice.formattedValue
                                                ) +
                                                "\n                            "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "product-quantity mb-xs-2 mb-sm-3" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.i18n.quantity) +
                                  ": " +
                                  _vm._s(productEntry.quantity) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.promotionData[productEntry.entryNumber]
                            ? _c(
                                "p",
                                {
                                  staticClass: "product-promo mb-xs-3 mr-sm-3"
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.i18n.promo) +
                                      ":\n                        " +
                                      _vm._s(
                                        _vm.promotionData[
                                          productEntry.entryNumber
                                        ]
                                      ) +
                                      "\n                    "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.globals.getIsSubscription() &&
                          productEntry.subscriptionFrequency &&
                          productEntry.subscriptionFrequency.entry.length
                            ? _c(
                                "p",
                                {
                                  ref: "subscribeInfo",
                                  refInFor: true,
                                  staticClass: "subscription"
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.i18n.subscribeFrequencyHeading
                                      ) +
                                      _vm._s(
                                        productEntry.subscriptionFrequency
                                          .entry[0].key
                                      ) +
                                      "\n                        "
                                  ),
                                  _c("span", {
                                    staticClass:
                                      "icon-alert-circle popover-subscribe",
                                    attrs: {
                                      role: "button",
                                      tabindex: "0",
                                      "aria-label": _vm.i18n.subscribeIconTitle,
                                      "data-toggle": "popover"
                                    },
                                    on: {
                                      mouseover: function($event) {
                                        _vm.openTooltip()
                                      }
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.restrictionError
                            ? [
                                _vm.restrictedItems(productEntry.product.code)
                                  ? _c("div", { staticClass: "pt-xs-3" }, [
                                      _c(
                                        "p",
                                        { staticClass: "shipping-restriction" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.i18n.restrictionError)
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.giftWrapDetails, function(giftProduct) {
                            return _vm.globals.isB2C()
                              ? [
                                  giftProduct.entryNumber ===
                                  productEntry.entryNumber
                                    ? [
                                        giftProduct.giftWrapMessage ||
                                        giftProduct.giftWrapSelected
                                          ? [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "gift-message-heading mb-xs-2"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.i18n.giftingOptions
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              giftProduct.giftWrapMessage
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "gift-message mb-xs-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.i18n.giftMessage
                                                        ) +
                                                          ":\n                                    " +
                                                          _vm._s(
                                                            giftProduct.giftWrapMessage
                                                          )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              giftProduct.giftWrapSelected
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "gift-message"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.i18n.giftWrapped
                                                        ) +
                                                          ":\n                                    " +
                                                          _vm._s(
                                                            giftProduct.giftWrapCost
                                                          )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ]
                              : _vm._e()
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "template",
                        { slot: "right-section-slot" },
                        [
                          _vm.globals.getIsSubscription()
                            ? [
                                _vm.totalPrice.formattedValue
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "product-total-price",
                                        attrs: {
                                          role: "text",
                                          "aria-label":
                                            "total Price " +
                                            _vm.totalPrice.formattedValue
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.totalPrice.formattedValue
                                            ) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                productEntry.product.subsPercentageDiscount
                                  ? _c("p", { staticClass: "subscription" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.i18n.subscribeDiscount1) +
                                          _vm._s(
                                            productEntry.product
                                              .subsPercentageDiscount
                                          ) +
                                          _vm._s(_vm.i18n.subscribeDiscount2) +
                                          "\n                        "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            : [
                                productEntry &&
                                productEntry.productPromotion &&
                                productEntry.productPromotion.value &&
                                productEntry.productPromotion.value !=
                                  _vm.discount.discountZero &&
                                productEntry.productPromotion.formattedValue
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "product-discount",
                                        attrs: {
                                          role: "text",
                                          "aria-label":
                                            "'discount '+ productEntry.productPromotion.formattedValue"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            -" +
                                            _vm._s(
                                              productEntry.productPromotion
                                                .formattedValue
                                            )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "product-total-price",
                                    attrs: {
                                      role: "text",
                                      "aria-label":
                                        "total Price " +
                                        productEntry.totalPrice.formattedValue
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          productEntry.totalPrice.formattedValue
                                        )
                                    )
                                  ]
                                )
                              ]
                        ],
                        2
                      )
                    ],
                    2
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2eed9ed5", { render: render, staticRenderFns: staticRenderFns })
  }
}