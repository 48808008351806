var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row checkout-page" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-md-8 px-xs-0 pr-sm-5" },
      [_c("vx-checkout", { attrs: { i18n: _vm.messages.checkout } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "col-xs-12 col-md-4 px-xs-0",
        staticStyle: { "margin-top": "71px" }
      },
      [
        _c("vx-order-summary", {
          attrs: { i18n: _vm.messages.manageShoppingCart.orderSummary }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-xs-12 px-xs-0" },
      [
        _c("vx-guest-marketing-communications", {
          attrs: { i18n: _vm.messages.checkout.guestMarketCommunication }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5b5b026c", { render: render, staticRenderFns: staticRenderFns })
  }
}