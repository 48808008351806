var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-uiux-product-carousel",
      class: _vm.productUiuxCarouselData.componentTheme
    },
    [
      _vm.productUiuxCarouselData.componentHeader
        ? _c("div", {
            staticClass: "header",
            domProps: {
              innerHTML: _vm._s(_vm.productUiuxCarouselData.componentHeader)
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productUiuxCarouselData.uiUxProducts.length > 0
        ? _c(
            "div",
            { staticClass: "products" },
            [
              _vm.showNavigationArrows
                ? _c("button", {
                    staticClass: "swiper-button-prev icon-chevron-left",
                    class: { "swiper-button-disabled": _vm.firstSlide },
                    attrs: {
                      tabindex: "0",
                      "aria-label": _vm.i18n.iconChevronLeftTitle,
                      disabled: _vm.firstSlide ? true : false
                    },
                    on: {
                      click: function($event) {
                        _vm.slidePrev()
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "swiper",
                { ref: "swiper", attrs: { options: _vm.swiperOptions } },
                _vm._l(_vm.modifiedProducts, function(product, index) {
                  return _c("swiper-slide", { key: index }, [
                    _c(
                      "a",
                      { staticClass: "product", attrs: { href: product.url } },
                      [
                        _c("img", {
                          attrs: { src: product.imageUrl, alt: product.name }
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "name",
                          domProps: {
                            innerHTML: _vm._s(
                              product.name.length > 130
                                ? product.name.substring(0, 130) + "..."
                                : product.name
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "price" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(product.price) +
                              "\n                    "
                          )
                        ])
                      ]
                    )
                  ])
                })
              ),
              _vm._v(" "),
              _vm.showNavigationArrows
                ? _c("button", {
                    staticClass: "swiper-button-next icon-chevron-right",
                    class: { "swiper-button-disabled": _vm.lastSlide },
                    attrs: {
                      tabindex: "0",
                      "aria-label": _vm.i18n.iconChevronRightTitle,
                      disabled: _vm.lastSlide ? true : false
                    },
                    on: {
                      click: function($event) {
                        _vm.slideNext()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.productUiuxCarouselData.ctaLink && _vm.productUiuxCarouselData.ctaText
        ? _c("a", { attrs: { href: _vm.productUiuxCarouselData.ctaLink } }, [
            _c(
              "button",
              {
                staticClass: "form-blue-button",
                class: this.productUiuxCarouselData.ctaStyle
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.productUiuxCarouselData.ctaText) +
                    "\n        "
                )
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7d5394e0", { render: render, staticRenderFns: staticRenderFns })
  }
}