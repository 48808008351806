/* eslint-disable no-undef */
import axios from 'axios';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import globals from '../../common/globals';
import { checkoutEventBus } from '../../../modules/event-bus';
import {
  paymentTypes,
  placeOrder,
  cookies,
} from '../../common/mixins/vx-enums';
import flyoutBannerMixin from '../../common/vx-flyout-banner/vx-flyout-banner-mixin';
import CheckoutService from '../../common/services/checkout-service';
import AnalyticsService from '../../common/services/analytics-service';
import optInMixin from '../../common/mixins/optin-mixin';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import vxTermsCondition from '../../common/vx-terms-condition/vx-terms-condition.vue';

export default {
  name: 'vx-place-order',
  mixins: [flyoutBannerMixin, optInMixin],
  components: {
    vxSpinner,
    vxTermsCondition,
    vxModal,
  },
  props: {
    i18n: Object,
    orderDetails: Object,
  },
  data() {
    return {
      globals,
      paymentTypes,
      placeOrder,
      cookies,
      isButtonDisabled: true,
      isPayPal: false,
      analyticsService: new AnalyticsService(),
      // orderButtonLabel: this.i18n.placeOrderLabel,
      isSubscribed: false,
      isApplePay: false,
      isGooglePay: false,
      isCaptchaError: false,
      recaptchaToken: '',
      intSource: '',
      user: {
        addToMarketComm: true,
        email: '',
      },
      existingOpt: true,
      forcedUserLogout: false,
    };
  },
  computed: {},
  mounted() {
    checkoutEventBus.$on('enable-place-order', () => {
      this.isButtonDisabled = false;
    });
    checkoutEventBus.$on('disable-place-order', () => {
      this.isButtonDisabled = true;
    });
    checkoutEventBus.$on('place-order', () => {
      this.placeOrderRequest();
    });
    checkoutEventBus.$on('update-payment-type', (paymentType) => {
      this.isPayPal = paymentType === paymentTypes.paypal;
      this.isApplePay = paymentType === paymentTypes.applePay;
      this.isGooglePay = paymentType === paymentTypes.googlePay;
    });
    if (this.globals.getIsSubscription()) {
      // this.orderButtonLabel = this.i18n.subscribeOrderLabel;
      this.isSubscribed = true;
    }
    this.intSource = this.globals.getIsLoggedIn() ? 'login_co ' : 'guest_co ';
    this.user.email = this.globals.userInfo.email;
    if (this.globals.isB2C() && this.user.email) {
      this.GetUserOptInStatus(this.user.email, (data) => {
        const brands = [this.globals.siteId];
        this.existingOpt = false;
        if (data.results.success) {
          if (data.results.opts.length > 0) {
            for (let i = 0; i < brands.length; i += 1) {
              const brand = brands[i];
              for (let j = 0; j < data.results.opts.length; j += 1) {
                this.existingOpt = brand.toLowerCase() === data.results.opts[j].brand.toLowerCase();
              }
              if (!this.existingOpt) {
                this.handleNotOptedIn();
              }
            }
          } else {
            this.handleNotOptedIn();
          }
        } else {
          this.handleNotOptedIn();
        }
      });
    } else if (this.globals.isB2C()) {
      this.handleNotOptedIn();
    }
  },
  methods: {
    /**
     * Calls place order service
     * @param {object} event
     */
    placeOrderRequest() {
      const checkoutService = new CheckoutService();
      this.$refs.spinner.showSpinner(60000, this.handlePlaceOrderError);
      const self = this;
      const continuePlaceOrder = function() {
        const placeOrderNoToken = () => {
          checkoutService.placeOrder(
            {},
            self.handlePlaceOrderResponse,
            self.handlePlaceOrderError,
          );
        };

        /**
           * Renders the HTML element with id 'google-recaptcha v3' as a reCAPTCHA widget.
           */
        if (self.globals.siteConfig.isReCaptchaEnabled && grecaptcha) {
          grecaptcha.ready(() => {
            grecaptcha.execute(self.globals.reCaptchaSiteKey, { action: 'e_commerce' }).then((token) => {
              checkoutService.placeOrder(
                {},
                self.handlePlaceOrderResponse,
                self.handlePlaceOrderError,
                token,
              );
            });
          });
        } else {
          placeOrderNoToken.call();
        }
      };

      if ((this.globals.isB2C() && this.user.addToMarketComm && !this.existingOpt)) {
        let email = '';
        if (this.orderDetails.paymentInfo && this.orderDetails.paymentInfo.billingAddress && this.orderDetails.paymentInfo.billingAddress.email) {
          email = this.orderDetails.paymentInfo.billingAddress.email;
        } else if (this.globals.userInfo.email) {
          email = this.globals.userInfo.email;
        } else if (document.querySelectorAll('.address-view.guest-email')[0].innerText) {
          email = document.querySelectorAll('.address-view.guest-email')[0].innerText;
        } else {
          continuePlaceOrder();
        }
        if (email) {
          const cmmConfig = {
            email,
            'opt-in': 1,
            spprograms: this.globals.getspprograms('place-order'),
            brands: this.globals.siteId.toLowerCase(),
            int_source: this.intSource,
          };
          const callSendOpp = function() {
            self.SendOpt(cmmConfig, continuePlaceOrder);
          };
          this.optinAnalyticsHandler('submit', true, email, callSendOpp);
        }
      } else if (this.globals.isB2C() && !this.user.addToMarketComm && !this.existingOpt) {
        // if user unchecked optin checkbox
        this.optinAnalyticsHandler('submit', false, undefined, continuePlaceOrder);
      } else { // if checkbox wasn't displayed for user
        continuePlaceOrder();
      }
    },
    /**
     * After successfull response we need to navigate user to order confirmation page.
     * @param {object} response
     */
    handlePlaceOrderResponse(response) {
      const queryParam = response.data.code;
      this.globals.deleteCookie(cookies.subscrCartId);
      // sending the data to Google Analytics on Order Confirmation
      this.analyticsService.trackProductPurchasedGA4(response.data, () => {
        const url = `${this.globals.getNavigationUrl(
          'orderConfirmation',
        )}/${queryParam}`;

        if (this.globals.isEStores()) {
          axios.get(url)
            .then(() => {
              window.location = url;
            })
            .catch(() => {
              document.querySelector('.vx-place-order').style.display = 'none';
              document.querySelector('.promotion-section').style.display = 'none';
              const chkSecChildren = document.querySelector('.checkout-sections').children;
              for (let i = 0; i < chkSecChildren.length - 1; i += 1) {
                chkSecChildren[i].style.display = 'none';
              }
              const thanksH1 = document.querySelector('.main-heading');
              thanksH1.innerText = 'Thank you for your Order!';

              if (!this.forcedUserLogout) {
                this.forcedUserLogout = true;
                axios.get('/gp/logout')
                  .then(() => {
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = `<div class="confirmation-page-info pt-xs-4 pt-sm-3 col-xs-12 px-xs-0">
              <span class="col-xs-11 px-xs-0">
                Your order is complete. We'll send you an email confirmation shortly.
              </span>
            <div class="col-md-8 col-sm-12 col-xs-12 pr-xs-0 pl-xs-0 d-flex flex-row flex-wrap order-confirmation-section">
              <div class="d-flex flex-column confirmation-left-section">
              </div>
              <div class="col-xs-12 px-xs-0 order-number pt-xs-4 pt-sm-5">
                Order #${queryParam}
              </div>

              <div class="order-info-block">
                <div class="order-info-heading">
                  We are experiencing an issue displaying your order confirmation.
                  <a href="/gp/my-account/order/${queryParam}">Please click here to view your order history in the my account section.</a></br>
                  Please call GPXpress® at (866) 435-5647 with any questions.
                </div>
              </div>
            </div>`;
                    thanksH1.parentElement.insertBefore(tempDiv, thanksH1.nextSibling);
                    document.querySelector('.vx-overview').classList.add('col-xs-12');
                  });
              }
            });
        } else {
          window.location = url;
        }
      });
    },
    /**
     * Error handler for Place order service call
     * @param {object} error
     */
    handlePlaceOrderError(error) {
      this.$refs.spinner.hideSpinner();
      if (
        error.response.data.errors[0].code === this.i18n.lowQuantityErrorCode
        || error.response.data.errors[0].code
          === this.i18n.lowQuantityErrorCodeSubscription
      ) {
        if (!this.globals.getIsSubscription()) {
          this.globals.navigateToUrl(placeOrder.cart);
          this.showFlyoutNextPage(
            placeOrder.error,
            error.response.data.errors[0].message,
            true,
          );
        } else {
          const productUrl = this.orderDetails.entries[0].product.url;
          const fullProductUrl = this.globals.getNavigationUrl(placeOrder.empty) + productUrl;
          window.location = fullProductUrl;
          this.showFlyoutNextPage(
            placeOrder.error,
            error.response.data.errors[0].message,
            true,
          );
        }
      } else if (
        error.response.data.errors[0].code === this.i18n.paymentErrorCode
      ) {
        this.showFlyout(
          placeOrder.error,
          this.i18n.paymentErrorCodeMessage,
          false,
        );
      }
    },

    openTermsModal(event) {
      if (document.querySelector('.checkout-order-summary-details')) {
        document.querySelector('.checkout-order-summary-details').style.zIndex = 9;
        document.querySelector('.order-summary').scrollIntoView(false);
      }
      this.$refs.termsConditionModal.open(event);
    },
    closetermsConditionModal() {
      this.$refs.termsConditionModal.close();
      if (document.querySelector('.checkout-order-summary-details')) {
        document.querySelector('.checkout-order-summary-details').style.zIndex = '';
      }
    },
    handleNotOptedIn() {
      this.existingOpt = false;
      this.$nextTick(() => {
        this.isCheckBoxOnPage();
      });
    },

    createRequestbody() {},
  },
};
