/**
 * This component is the common favorite button component
 */

import globals from '../globals';
import vxSpinner from '../vx-spinner/vx-spinner.vue';
import flyoutBannerMixin from '../vx-flyout-banner/vx-flyout-banner-mixin';
import PdpService from '../services/pdp-service';
import {
  favorites,
} from '../mixins/vx-enums';

export default {
  name: 'vx-favorite-btn',
  components: {
    vxSpinner,
  },
  props: {
    // Copy text coming from properties file
    i18n: {
      type: Object,
    },
  },
  mixins: [flyoutBannerMixin],
  data() {
    return {
      globals,
      favorites,
      pdpService: new PdpService(),
      productId: '',
      isActive: false,
      isFavoriteFilled: false,
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    };
  },
  computed: {},
  mounted() {
  },
  methods: {
    /**
     * Function to toggle favorites
     */
    toggleFavorite() {
      this.isActive = !this.isActive;
      this.handleFavorites();
    },
    /**
     * Function to get all wishlists details
     */
    handleFavorites() {
      if (this.globals.loggedIn) {
        this.pdpService.getShoppingLists(
          {},
          this.handleGetWishlistResponse,
          this.handleGetWishlistError,
        );
      } else {
        this.navigateToLogin();
      }
    },
    /**
     * Function handles response of get all wishlists service call
     */
    handleGetWishlistResponse(response) {
      if (response) {
        const item = response.data.wishlists.filter((list) => list.name === this.favorites.favorites);
        if (this.isActive) {
          const requestdata = {
            entryType: 'PRODUCT',
            product: {
              code: this.productId,
            },
            wishlistUid: item[0].wishlistUid,
            quantity: '1',
          };
          const requestConfig = {};
          requestConfig.data = requestdata;
          this.pdpService.saveAList(requestConfig, this.handleSaveListResponse, this.handleSaveListError);
        } else {
          this.removeFavorite(item[0].wishlistUid);
        }
      }
    },
    /**
     * Function handles error of get all wishlists service call
     */
    handleGetWishlistError(error) {
      if (error) {
        this.showFlyout('error', error.data.description, true);
      }
    },
    /**
     * Function is to handle the save list response
     */
    handleSaveListResponse(response) {
      if (response) {
        this.showFlyout('success', response.data.description, true);
        window.updateFavoriteProduct(this.productId);
      }
    },
    /**
     * Function is to handle the save list error
     */
    handleSaveListError(error) {
      if (error) {
        this.showFlyout('error', error.data.description, true);
      }
    },
    /**
     * Function is to navigate user to Login page
     */
    navigateToLogin() {
      const url = `${this.globals.getNavBaseUrl()}/login?site=${this.globals.siteId}`;
      window.location = url;
    },
    /**
     * Function is to remove the item from Favorites List
     * @param  {String}   wishlistUid string of Wishlist id
     */
    removeFavorite(wishlistUid) {
      const requestBody = {
        wishlistUid,
        product: {
          code: this.productId,
        },
      };
      const requestConfig = {};
      requestConfig.data = requestBody;
      this.pdpService.deleteCartItem(requestConfig, this.handleRemoveFavoriteResponse, this.handleRemoveFavoriteError);
    },
    /**
     * Function handles the response of delete cart item service
     */
    handleRemoveFavoriteResponse() {
      window.updateFavoriteProduct(this.productId);
    },
    /**
     * Function handles the error of delete cart item service
     */
    handleRemoveFavoriteError() {},
  },
};
