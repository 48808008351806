/**
 * Banner text component
 */
import globals from '../../../../components/common/globals';
import cookiesMixin from '../../../../components/common/mixins/cookies-mixin';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import messages from '../../../../locale/gp-pro/messages';
import SearchBrowseService from '../../../../components/common/services/search-browse-service';
import vxProductTileVertical from '../../search-browse/vx-product-tile-vertical/vx-product-tile-vertical.vue';
import vxCompareTable from '../../search-browse/vx-compare-table/vx-compare-table.vue';
import {
  eventBus,
} from '../../../../modules/event-bus';

export default {
  name: 'vx-pdp-compare-products',
  components: {
    vxProductTileVertical,
    vxCompareTable,
  },
  props: {
    productId: {
      type: String,
    },
  },
  mixins: [cookiesMixin, mobileMixin],
  data() {
    return {
      globals,
      messages,
      searchBrowseService: new SearchBrowseService(),
      isBulkEnabled: true,
      pageType: 'SEARCH',
      hideComponent: false,
      compareProducts: [],
      pdpCompareIds: [this.productId],
      productsCompareData: {},
    };
  },
  computed: {},
  mounted() {
    const self = this;
    eventBus.$on('compareProducts', (data) => {
      self.compareProducts = data.references;
      for (let i = 0; i < self.compareProducts.length; i += 1) {
        self.pdpCompareIds.push(self.compareProducts[i].target.code);
      }
      self.onInitCheck();
    });
  },
  methods: {
    onInitCheck() {
      const self = this;
      const queryString = self.generateQuery(this.pdpCompareIds);
      self.generateUrlRequest(queryString);
    },
    handleCompareResponse(response) {
      if (this.isMobile()) {
        this.productsCompareData = response.data;
        this.productsCompareData.products = this.productsCompareData.products.slice(0, 2);
      } else {
        this.productsCompareData = response.data;
      }
    },
    handleCompareError() {
      this.hideComponent = true;
    },
    generateUrlRequest(query) {
      const requestConfig = {};
      const self = this;
      self.searchBrowseService.getCompareResults(requestConfig, self.handleCompareResponse, self.handleCompareError, query, self.pageType);
    },
    generateQuery(productCode) {
      const initialText = '?productCodes=';
      const prodList = initialText + productCode.join(':');
      return prodList;
    },
  },
};
