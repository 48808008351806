var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("permissions-page", {
    attrs: {
      permissionColumns: "ID,Type,Business Unit,Time Period,Amount,Status",
      i18n: _vm.messages.manageB2BOrg.userPermissions
    }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-52b87ebc", { render: render, staticRenderFns: staticRenderFns })
  }
}