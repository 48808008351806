import messages from '../../../../locale/messages';
import vxProductResourcesTab from '../vx-product-resources-tab/vx-product-resources-tab.vue';
import vxProductDetailsTab from '../vx-product-details-tab/vx-product-details-tab.vue';
import vxPurchasingOptionsTab from '../vx-purchasing-options-tab/vx-purchasing-options-tab.vue';
import vxProductDetailsTabSpecs from '../vx-product-details-tab-specs/vx-product-details-tab-specs.vue';
import vxRelatedProductsTab from '../vx-related-products-tab/vx-related-products-tab.vue';
import vxCompareResultPage from '../../../../components/search-browse/vx-compare-result-page/vx-compare-result-page.vue';
import vxUpsellCarousel from '../vx-upsell-carousel/vx-upsell-carousel.vue';
import cookiesMixin from '../../../../components/common/mixins/cookies-mixin';
import {
  eventBus,
} from '../../../../modules/event-bus';
import globals from '../../../../components/common/globals';

export default {
  name: 'vx-tab-container',
  components: {
    vxProductResourcesTab,
    vxProductDetailsTab,
    vxProductDetailsTabSpecs,
    'vx-related-products-tab': vxRelatedProductsTab,
    vxCompareResultPage,
    vxUpsellCarousel,
    vxPurchasingOptionsTab,
  },
  props: {
    // Copy text coming from properties files
    i18n: {
      type: Object,
    },
    // indicates whether the site is configured for favorites
    isFavorites: {
      type: Boolean,
      default: false,
    },
    // indicates whether the site is configured for Bazaar Voice
    isBazaarVoice: {
      type: String,
      default: '',
    },
    // indicates whether the related products are enabled for the product
    isRelatedEnabled: {
      type: Boolean,
      default: false,
    },
    // Copy text coming from properties files for search browse components
    searchBrowseI18n: {
      type: Object,
    },
  },
  mixins: [cookiesMixin],
  data() {
    return {
      bvProductInlineRating: 'BVRRInlineRating-',
      pdpProductsData: null,
      referenceTypes: {
        compare: 'SELECT',
        related: 'SIMILAR',
        viewed: 'UPSELLING',
      },
      messages: messages['en-US'],
      compareCookieName: 'CompareCookie',
      compareProductIds: [],
      relatedProductIds: [],
      upsellProductIds: [],
      isRelatedProducts: true,
      globals,
      hash: '',
      allTabPanes: '',
      selectedTabPane: '',
      selectedTabPaneLinks: '',
      selectedTabPaneButtons: '',
      selectedTab: '',
      tabParent: '',
      selectedTabPos: '',
      tabs: '',
      target: '',
      allTabLinks: '',
      firstItem: '',
      lastItem: '',
    };
  },
  computed: {},
  mounted() {
    /* eslint no-undef: "error" */
    const self = this;
    eventBus.$on('noRelatedProducts', (data) => {
      if (data) {
        this.isRelatedProducts = false;
        if (self.getUrlHash() === '#related') {
          document.querySelector('a#tab-details').click();
        }
      }
    });
    eventBus.$on('pdpProductsData', (data) => {
      self.pdpProductsData = data;
      self.pdpProductsData.tabInfo.sort((a, b) => a.sequence - b.sequence);
      self.bvProductInlineRating += data.code;
      self.onPdpData();
      setTimeout(() => {
        if (self.pdpProductsData.defaultTabName) {
          document.querySelector(`#tabs li a[name="${self.pdpProductsData.defaultTabName}"]`).tab('show');
        }
      }, 500);
    });
    eventBus.$on('lastButtonCheck', () => {
      this.focusNextTab();
    });
    setTimeout(() => {
      this.tabs = document.querySelectorAll('.nav-link[role=tab]');
    }, 2000);
    eventBus.$on('purchasingOptions', () => {
      // let scrollPage = true;
      if (window.innerWidth <= 767) {
        // Code to execute if the screen width is considered mobile
        const purchaseopTab = document.getElementById('purchasing-options');
        const anchor = purchaseopTab.querySelector('a');
        if (anchor.classList.contains('collapsed')) {
          anchor.click();
        }
      } else {
        // Code to execute if the screen width is larger device
        const purchasingOptionLink = document.getElementById('pdp-purchasing-option');
        if (purchasingOptionLink != null && !purchasingOptionLink.classList.contains('active')) {
          purchasingOptionLink.click();
        }
      }
      setTimeout(() => {
        const targetElement = document.getElementById('collapse-purchasing-options');
        targetElement.scrollIntoView();
      }, 300);
    });
  },
  updated() {
    const hash = this.getUrlHash();
    if (hash === '#specifications') {
      document.querySelector('a#tab-specifications').click();
    }
    if (hash === '#related') {
      if (this.isRelatedEnabled) {
        document.querySelector('a#tab-related-products').click();
      }
    }
    if (hash === '#assets') {
      document.querySelector('span.icon-download').click();
    }
  },
  methods: {
    /**
         * Function is called on mounted to get the complete product data
         */
    onPdpData() {
      const self = this;
      const pdpData = self.pdpProductsData;
      // check for product resources
      if (pdpData.productReferences) {
        /** Compare Product Data  * */
        self.compareProductIds = self.getProductIds(pdpData.productReferences, self.referenceTypes.compare);
        /** Related Product Data  * */
        self.relatedProductIds = self.getProductIds(pdpData.productReferences, self.referenceTypes.related);
        /** Upsell Carousel * */
        self.upsellProductIds = self.getProductIds(pdpData.productReferences, self.referenceTypes.viewed);
        if (self.upsellProductIds.length > 0) {
          eventBus.$emit('upsellData', pdpData.code);
        }
      }
    },
    /**
         * Function get Product Ids
         * @param  {Array} referencesArr reference array
         * @param  {String} referenceType   type of reference value
         * @return {Array} retruns array of Product Ids
         */
    getProductIds(referencesArr, referenceType) {
      let filteredRefencesData = [];
      filteredRefencesData = referencesArr.filter((item) => item.referenceType === referenceType);
      return filteredRefencesData.map((item) => item.target.code);
    },
    initiateReszieEvent() {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    },
    getUrlHash() {
      return window.location.hash;
    },
    /**
     * Function to enable left arrow navigation
    */
    leftArrow(event) {
      this.target = event.target.id;
      for (const [key, value] of Object.entries(this.tabs)) {
        if (this.target === `${value.id}`) {
          if (key === 0) {
            this.tabs[this.tabs.length - 1].focus();
          } else {
            this.tabs[parseInt(key, 10) - 1].focus();
          }
        }
      }
    },
    /**
     * Function to enable right arrow navigation
     */
    rightArrow(event) {
      this.target = event.target.id;
      for (const [key, value] of Object.entries(this.tabs)) {
        if (this.target === `${value.id}`) {
          if (key === this.tabs.length - 1) {
            this.tabs[0].focus();
          } else {
            this.tabs[parseInt(key, 10) + 1].focus();
          }
        }
      }
    },
    // Method to determine the last and first element in the tabpanel
    focusedElement(e) {
      const keyCode = e.keyCode || e.which;
      this.allTabLinks = this.$el.querySelectorAll('.nav-link[role=tab]');
      this.selectedTab = this.$el.querySelector('.tab-item.active');
      this.tabParent = this.selectedTab.parentNode;
      this.selectedTabPos = Array.prototype.indexOf.call(this.tabParent.children, this.selectedTab);
      this.allTabPanes = this.$el.querySelectorAll('.tab-pane[role=tabpanel]');
      this.selectedTabPane = this.$el.querySelector('.tab-pane.active[role=tabpanel]');
      this.selectedTabPaneLinks = this.$el.querySelectorAll('.tab-pane.active[role=tabpanel] a');
      this.selectedTabPaneButtons = this.$el.querySelectorAll('.tab-pane.active[role=tabpanel] .grid-view button');
      if (keyCode === 9 && this.selectedTabPaneLinks.length > 1 && !e.shiftKey) {
        this.selectedTabPaneLinks[1].focus();
      }
    },
    /**
     * Method to focus on next tab heading from last link in the previous tab
     */
    focusNextTab() {
      this.selectedTabPaneButtons = this.$el.querySelectorAll('.tab-pane.active[role=tabpanel] .grid-view button');
      if (document.activeElement === this.selectedTabPaneButtons[this.selectedTabPaneButtons.length - 1]) {
        this.allTabLinks[this.selectedTabPos].focus();
        this.allTabLinks[this.selectedTabPos + 1].click();
      }
    },
  },
};
