/* Handles the add address form */
import {
  TheMask,
} from 'vue-the-mask';
import {
  Validator,
} from 'vee-validate';

import vxDropdownPrimary from '../vx-dropdown-primary/vx-dropdown-primary.vue';
import globals from '../globals';
import vxAddressVerification from '../vx-address-verification/vx-address-verification.vue';
import ManageB2bOrgService from '../services/manage-b2b-org-service';
import vxModal from '../vx-modal/vx-modal.vue';
import vxSpinner from '../vx-spinner/vx-spinner.vue';
import detectDeviceMixin from '../mixins/detect-device-mixin';
import CommonService from '../services/common-service';
import mobileMixin from '../mixins/mobile-mixin';
import googleMapsMixin from '../mixins/google-maps-mixin';

import {
  userStates,
  booleanFlags,
  defaultCountry,
} from '../mixins/vx-enums';
import {
  eventBus,
} from '../../../modules/event-bus';

export default {
  name: 'vx-add-edit-address',
  components: {
    vxDropdownPrimary,
    vxAddressVerification,
    vxModal,
    TheMask,
    vxSpinner,
  },
  mixins: [detectDeviceMixin, mobileMixin, googleMapsMixin],
  props: {
    // Text coming from property file
    i18n: Object,
    /**
     * Save button text
     */
    isSingleShipping: {
      type: Boolean,
      default: false,
    },
    // Save button text
    buttonText: {
      type: String,
      default: 'SAVE',
    },
    // Submit button text
    submitButtonText: {
      type: String,
      default: 'Submit',
    },
    // Edit Existing address
    editAddress: {
      type: Object,
      default: {},
    },
    // To toggle profile checkboxes
    isProfile: {
      type: Boolean,
      default: false,
    },
    // For address verification
    isBussinessUnit: {
      type: Boolean,
      default: false,
    },
    // Checking that if the pallet shipment is default
    palletShipment: {
      type: Boolean,
      default: false,
    },
    // Indicates if this is the first address added by the user
    isFirstAddress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      globals,
      userStates,
      commonService: new CommonService(),
      masked: true,
      selectedAddress: {},
      businessUnitsDropDown: [],
      regionsData: [],
      countryData: {},
      /**  *country and region list should come from service */
      countryList: {
        label: 'Country',
        options: [],
      },
      regionList: {
        label: 'State',
        options: [],
      },
      address: {
        firstName: '',
        lastName: '',
        companyName: '',
        phone: '',
        titleCode: '',
        line1: '',
        line2: '',
        town: '',
        id: '',
        postalCode: '',
        country: '',
        region: '',
        defaultAddress: false,
        defaultBillingAddress: false,
        shippingAddress: false,
        palletShipment: false,
        unit: {},
      },
      autocomplete: {},
      selectedAutofillAddress: false,
      unverifiedAddress: {},
      defaultCountry,
      usingGoogleAddr: false,
      regionLabel: '',
    };
  },
  computed: {},
  mounted() {
    const self = this;
    const initAutoComplete = function() {
      self.autocomplete = new self.google.maps.places.Autocomplete(
        document.getElementById('autocompleteShipping'),
        { types: ['geocode'] },
      );
      self.autocomplete.setFields(['address_component']);
      const contriesRestrictions = self.globals.isB2C() ? ['us'] : ['us', 'ca'];
      self.autocomplete.setComponentRestrictions({
        country: contriesRestrictions,
      });
      self.autocomplete.addListener('place_changed', self.fillInAddress);
    };

    if (this.google) {
      initAutoComplete();
    } else {
      this.$on('mapMounted', () => {
        initAutoComplete();
      });
    }

    if (this.editAddress) {
      this.address = {
        ...this.editAddress,
      };
    }
    this.callCountryService();
    if (this.isBussinessUnit) {
      this.fetchBusinessUnitValues();
    }

    if (this.$refs.countryDropdown && this.address.hasOwnProperty('country')) {
      this.$refs.countryDropdown.setDropdownValue(this.address.country.isocode);
      this.callRegionService(this.address.country.value);
    }

    if (
      this.$refs.businessUnitsDropDown
      && this.address.hasOwnProperty('bUnit')
      && this.isBussinessUnit
    ) {
      this.$refs.businessUnitsDropDown.setDropdownValue(this.address.bUnit.value);
    }
    if (this.$refs.stateDropdown && this.address.hasOwnProperty('region') && this.address.region.isocode) {
      this.setStateInEditMode();
    }

    const veeCustomErrorMessage = {
      en: {
        custom: {
          firstName: {
            required: this.i18n.firstNameRequiredError,
            regex: this.i18n.firstNameRegexError,
            max: this.i18n.firstNameMaxError,
          },
          lastName: {
            required: this.i18n.lastNameRequiredError,
            regex: this.i18n.lastNameRegexError,
            max: this.i18n.lastNameMaxError,
          },
          phoneNumber: {
            required: this.i18n.phoneRequiredError,
            min: this.i18n.phoneMinError,
          },
          country: {
            required: this.i18n.countryError,
          },
          unit: {
            required: this.i18n.businessUnitError,
          },
          addressLine1: {
            required: this.i18n.addressLine1Error,
            regex: this.i18n.addressLineRegexError,
          },
          addressLine2: {
            regex: this.i18n.addressLineRegexError,
          },
          city: {
            required: this.i18n.cityError,
          },
          State: {
            required: this.i18n.stateError,
          },
          zipcode: {
            required: this.i18n.zipcodeError,
            regex: this.i18n.zipCodeRegexError,
          },
          companyName: {
            required: this.i18n.companyNameError,
            regex: this.i18n.companyNameRegexError,
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
  },
  updated() {},
  methods: {
    /**
     * This function triggers on selecting address which is returned by address
     * verification service, we will send that address to save
     * @param  {Object} data address
     */
    handleSelectedAddressResponse(data) {
      this.closeAddressVerificationModal();
      this.selectedAddress = data;
      if (!data.id) {
        this.selectedAddress.value.id = this.address.id;
      }
      this.$emit('onAddressSaved', { address: this.selectedAddress, showSelectedAddress: true });
    },
    /**
     * This function gets business unit level
     */
    fetchBusinessUnitValues() {
      const mb2BService = new ManageB2bOrgService();
      // this.$emit('showSpinner');
      mb2BService.getBusinessUnits({}, this.onBusinessUnitsResponse, this.onBusinessUnitsError, '');
    },
    /**
     * This function handles the response of business unit level call
     */
    onBusinessUnitsResponse(response) {
      // this.$refs.spinner.hideSpinner();
      const { status, data } = response;
      if (status && data.units) {
        this.getBusinessUnitDDvalues(data.units);
        // this.$emit('hideSpinner');
      }
    },
    /**
     * This function handles the error of business unit level call
     */
    onBusinessUnitsError(error) {
      // this.$refs.spinner.hideSpinner();
      console.log('onBusinessUnitsError', error);
    },
    /**
     * This functions sets  value in businessUnitsDropDown
     * @param  {Array} data Business Unit
     */
    getBusinessUnitDDvalues(data) {
      data.forEach((val) => {
        const obj = {};
        obj.label = val.name;
        obj.value = val.id;
        this.businessUnitsDropDown.push(obj);
      }); this.$nextTick(() => {
        if (this.$refs.businessUnitDropdown && this.address.hasOwnProperty('unit')) {
          this.$refs.businessUnitDropdown.setDropdownValue(this.address.unit.uid);
        }
        if (this.$refs.businessUnitDropdown && this.globals.userInfo.unit) {
          const currentUnit = this.globals.userInfo.unit;
          const currentUnitObj = this.businessUnitsDropDown.filter((e) => e.value === currentUnit);

          this.$refs.businessUnitDropdown.selectedItem = {
            label: currentUnitObj[0].label,
            value: currentUnitObj[0].value,
          };
          this.$refs.businessUnitDropdown.setDropdownLabel(currentUnitObj[0].label);

          this.setDropdownOption('unit', currentUnitObj[0]);
        }
      });
    },
    /**
     * This function handles error of address verification modal
     */
    handleSelectedAddressError() {
      this.closeAddressVerificationModal();
    },
    /**
     * This function sets values in country dropdown
     */
    setDropdownOption(key, evt) {
      this.usingGoogleAddr = false;
      if (this.address.country && key === 'country' && evt.label !== this.address.country.label) {
        if (evt.value === 'CA') {
          this.regionLabel = 'Province';
        } else if (evt.value === 'US') { this.regionLabel = 'State'; }
        this.callRegionService(evt.value);
      }
      this.$set(this.address, key, evt);
    },
    /**
     * This function gets all states in a country
     * @param  {String} isoCode country code
     */
    callRegionService(isoCode) {
      this.commonService.getRegions({}, this.handleGetRegionsResponse, this.handleGetRegionsError, isoCode);
      // this.$refs.spinner.showSpinner();
    },
    /**
     * This function handles the response on regions call
     */
    handleGetRegionsResponse(response) {
      // this.$refs.spinner.hideSpinner();
      this.regionsData = response.data.regions;
      if (!this.selectedAutofillAddress) {
        this.$refs.stateDropdown.resetDropdown();
      }
      this.regionList.options = [];
      for (let i = 0; i < this.regionsData.length; i += 1) {
        this.$set(this.regionList.options, i, {
          label: this.regionsData[i].name,
          value: this.regionsData[i].isocodeShort,
          countryIso: this.regionsData[i].countryIso,
          isocode: this.regionsData[i].isocode,
        });
      }
      if (this.editAddress) {
        this.setStateInEditMode();
      }
    },
    /**
     * This function sets state drppdown in edit mode
     */
    setStateInEditMode() {
      this.$nextTick(() => {
        if (this.$refs.stateDropdown && this.address.hasOwnProperty('region') && this.address.region.isocode) {
          this.$refs.stateDropdown.setDropdownValue(this.address.region.isocode.split('-')[1]);
        }
      });
    },
    /**
     * This function handles the error on regions call
     */
    handleGetRegionsError() {
      // this.$refs.spinner.hideSpinner();
    },
    /**
     * This function gets country list
     */
    callCountryService() {
      this.commonService.getCountries({}, this.handleGetCountriesResponse, this.handleGetCountriesError, booleanFlags.isShippingAddress);
      // this.$refs.spinner.showSpinner();
    },
    /**
     * This function handles the response on country call
     */
    handleGetCountriesResponse(response) {
      // this.$refs.spinner.hideSpinner();
      if (response && response.data) {
        this.countryData = response.data.countries;
        this.$refs.countryDropdown.resetDropdown();
        this.countryList.options = [];
        for (let i = 0; i < this.countryData.length; i += 1) {
          this.$set(this.countryList.options, i, {
            label: this.countryData[i].name,
            value: this.countryData[i].isocode,
          });
        }
        if (this.editAddress && this.editAddress.country) {
          this.$refs.countryDropdown.setDropdownLabel(this.editAddress.country.name);
          this.$refs.countryDropdown.selectedItem = {
            label: this.editAddress.country.name,
            value: this.editAddress.country.isocode,
          };
          this.setDropdownOption('country', this.$refs.countryDropdown.selectedItem);
        } else {
          this.$refs.countryDropdown.setDropdownLabel(this.defaultCountry.label);
          this.$refs.countryDropdown.selectedItem = {
            label: this.defaultCountry.label,
            value: this.defaultCountry.value,
          };
          this.setDropdownOption('country', this.defaultCountry);
        }
      }
    },
    /**
     * This function handles the error on country call
     */
    handleGetCountriesError() {
      // this.$refs.spinner.hideSpinner();
    },
    /**
     * This function opens address verification modal
     */
    callAddressVerification() {
      const self = this;
      const $countryDropdown = self.$refs.countryDropdown;
      const $stateDropdown = self.$refs.stateDropdown;
      this.$validator.validateAll().then((result) => {
        if (result) {
          self.unverifiedAddress = {
            titleCode: 'mr',
            firstName: self.address.firstName,
            lastName: self.address.lastName,
            companyName: self.address.companyName,
            phone: self.address.phone,
            line1: self.address.line1,
            town: self.address.town,
            id: self.address.id,
            postalCode: self.address.postalCode,
            country: {
              isocode: $countryDropdown.selectedItem.value,
              name: $countryDropdown.selectedItem.label,
            },
            region: {
              countryIso: $countryDropdown.selectedItem.value,
              isocode: `${$countryDropdown.selectedItem.value}-${
                $stateDropdown.selectedItem.value
              }`,
              isocodeShort: $stateDropdown.selectedItem.value,
              name: $stateDropdown.selectedItem.label,
            },
            defaultAddress: self.address.defaultAddress,
            defaultBillingAddress: self.address.defaultBillingAddress,
            shippingAddress: self.address.defaultAddress,
            palletShipment: self.address.palletShipment,
            billingAddress: self.address.defaultBillingAddress,
            userId: self.address.userId,
          };
          if (self.isBussinessUnit) {
            self.unverifiedAddress = {
              ...self.unverifiedAddress,
              unit: {
                uid: self.address.unit.value || self.address.unit.uid,
              },
            };
          }
          self.unverifiedAddress.line2 = self.address.line2 || '';
          eventBus.$emit('removeScrollbar', true);
          // TODO: Uncomment after address verification
          if (self.usingGoogleAddr) {
            let userAddress = '';
            let companyName = '';
            let addressLine2 = '';
            const region = `<span class='address-content'>${self.unverifiedAddress.town}</span> <span class='address-content'>${self.unverifiedAddress.region.isocodeShort}</span> <span class='address-content'>${self.unverifiedAddress.postalCode}</span>`;
            const name = `${self.unverifiedAddress.firstName} ${self.unverifiedAddress.lastName}`;
            if (self.unverifiedAddress && self.unverifiedAddress.companyName) {
              companyName = self.unverifiedAddress.companyName;
            }
            if (self.unverifiedAddress && self.unverifiedAddress.line2) {
              addressLine2 = self.unverifiedAddress.line2;
            }
            if (self.unverifiedAddress) {
              userAddress = [
                name,
                companyName,
                self.unverifiedAddress.line1,
                addressLine2,
                region,
                self.unverifiedAddress.country.isocode,
              ]
                .filter(Boolean);
            }

            self.selectedAddress = { label: userAddress, value: self.unverifiedAddress };
            self.$emit('onAddressSaved', { address: self.selectedAddress, showSelectedAddress: true });
          } else {
            self.$refs.addressVerificationModal.open();
          }

          // TODO: Temporary Code for checking add and edit Address
          // if (self.address.hasOwnProperty("id")) {
          //   self.unverifiedAddress['id'] = self.address.id;
          // }
          // this.handleSelectedAddressResponse(this.unverifiedAddress);
        } else {
          self.globals.setFocusByName(self.$el, self.globals.getElementName(self.errors));
        }
      });
    },
    /**
     * This function closes address verification modal
     */
    closeAddressVerificationModal() {
      eventBus.$emit('removeScrollbar', false);
      this.$refs.addressVerificationModal.close();
    },
    fillInAddress() {
      this.selectedAutofillAddress = true;
      const place = this.autocomplete.getPlace();
      let streetNum = '';
      let streetName = '';
      let zipcode = '';
      let zipcodeSuffix = '';
      for (let i = 0; i < place.address_components.length; i += 1) {
        const addressType = place.address_components[i].types[0];
        const addressVal = place.address_components[i];
        switch (addressType) {
          case 'street_number':
            streetNum = addressVal.long_name;
            break;
          case 'route':
            streetName += ` ${addressVal.long_name}`;
            break;
          case 'locality':
            this.address.town = addressVal.long_name;
            break;
          case 'administrative_area_level_1':
            this.$refs.stateDropdown.setDropdownLabel(addressVal.long_name);
            this.$refs.stateDropdown.selectedItem = {
              label: addressVal.long_name,
              value: addressVal.short_name,
            };
            this.setDropdownOption('region', this.$refs.stateDropdown.selectedItem);
            break;
          case 'country':
            this.$refs.countryDropdown.setDropdownLabel(addressVal.long_name);
            this.$refs.countryDropdown.selectedItem = {
              label: addressVal.long_name,
              value: addressVal.short_name,
            };
            this.setDropdownOption('country', this.$refs.countryDropdown.selectedItem);
            break;
          case 'postal_code':
            zipcode = addressVal.long_name;
            break;
          case 'postal_code_suffix':
            zipcodeSuffix += `-${addressVal.long_name}`;
            break;
          default:
        }
      }
      this.address.line1 = `${streetNum}${streetName}`;
      this.address.postalCode = zipcode + (zipcodeSuffix || '');
      this.usingGoogleAddr = true;
    },
  },
};
