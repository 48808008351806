var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-accordion" }, [
    !_vm.isSlotBased
      ? _c("div", { staticClass: "panel panel-default" }, [
          _c(
            "div",
            {
              staticClass: "panel-heading",
              attrs: {
                role: "presentation",
                id: "heading" + _vm.accordionIdentifier
              }
            },
            [
              _c("h3", { staticClass: "panel-title" }, [
                _c(
                  "a",
                  {
                    staticClass: "collapsed",
                    attrs: {
                      role: "button",
                      "data-toggle": "collapse",
                      "data-parent": "#accordion",
                      href: "#" + _vm.accordionIdentifier,
                      "aria-expanded": "false",
                      "aria-controls": _vm.accordionIdentifier
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.accordionData.accordionTitle) + " ")
                    ])
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "panel-collapse collapse",
              attrs: {
                id: _vm.accordionIdentifier,
                role: "tabpanel",
                "aria-labelledby": "heading" + _vm.accordionIdentifier
              }
            },
            [
              _c("div", {
                staticClass: "panel-body",
                domProps: { innerHTML: _vm._s(_vm.accordionData.accordionBody) }
              })
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isSlotBased
      ? _c(
          "div",
          { staticClass: "slot-based" },
          [
            _c(
              "div",
              {
                staticClass: "accordion-header",
                on: {
                  click: function($event) {
                    _vm.toggleAccordion($event)
                  },
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k(
                        $event.keyCode,
                        "enter-space",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    _vm.toggleAccordion($event)
                  }
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "heading-container" },
                  [
                    _vm._t("heading", [
                      _vm.accordionData &&
                      (_vm.accordionData.hideAccordion ||
                        _vm.accordionData.viewAccordion)
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.isOpened
                                  ? _vm.accordionData.hideAccordion
                                  : _vm.accordionData.viewAccordion
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _c("span", { staticClass: "icon-container" }, [
                  _c("span", {
                    class: {
                      "icon-minus": _vm.isOpened,
                      "icon-plus": !_vm.isOpened
                    },
                    attrs: {
                      tabindex: "0",
                      role: "button",
                      "aria-label": _vm.isOpened ? "collapse" : "expand"
                    }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _vm.preserveAccordionState
              ? [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isOpened,
                          expression: "isOpened"
                        }
                      ],
                      staticClass: "accordion-body"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "accordion-content" },
                        [
                          _vm._t("component", [
                            _vm._v(
                              "\n            Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n          "
                            )
                          ])
                        ],
                        2
                      )
                    ]
                  )
                ]
              : [
                  _vm.isOpened
                    ? _c("div", { staticClass: "accordion-body" }, [
                        _c(
                          "div",
                          { staticClass: "accordion-content" },
                          [
                            _vm._t("component", [
                              _vm._v(
                                "\n            Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n          "
                              )
                            ])
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2a71f83b", { render: render, staticRenderFns: staticRenderFns })
  }
}