/**
 * vx-hero-banner is used to author CMS hero component where an image,
 *  text and video SRC can be authored.
 */
import { eventBus, globalEventBus } from '../../../../modules/event-bus';
import videoPlayer from '../../../../components/common/vx-video-player/vx-video-player.vue';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import { backgroundColor } from '../../../../components/common/mixins/vx-enums';
import globals from '../../../../components/common/globals';
import viewSiteContent from '../../../../locale/view-site-content-i18n';

export default {
  name: 'vx-hero-banner',
  components: {
    vxModal,
    videoPlayer,
  },
  mixins: [mobileMixin],
  props: {
    heroBannerData: {
      type: Object,
      required: true,
    },
    isCategoriesBanner: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      backgroundColor,
      globals,
      i18n: viewSiteContent.heroBanner,
      backgroundImg: '',
      heroBannerDataCopy: this.heroBannerData,
    };
  },
  computed: {
    /**
         * used to set the background color for the banner
         * @return {object} object with background color as key
         */
    backgroundStyle() {
      return !this.heroBannerDataCopy.subHeadBgColor || this.heroBannerDataCopy.subHeadBgColor === ''
        ? {
          'background-color': this.backgroundColor.transparent,
        }
        : {
          'background-color': this.heroBannerDataCopy.subHeadBgColor,
        };
    },
    /**
         * sets the background image
         * @return {object} background-image
         */
    backgroundImage() {
      return { 'background-image': `url(${this.backgroundImg})` };
    },
    /**
         * returns the alt text of the background
         */
    backgroundAlt() {
      return this.getResponsiveAlt(this.heroBannerDataCopy.backgroundImageAltTextD, this.heroBannerDataCopy.backgroundImageAltTextT, this.heroBannerDataCopy.backgroundImageAltTextM);
    },
  },
  created() {},
  mounted() {
    this.backgroundImg = this.getResponsiveImage(this.heroBannerDataCopy.backgroundImageD, this.heroBannerDataCopy.backgroundImageT, this.heroBannerDataCopy.backgroundImageM);
    if (this.isCategoriesBanner) {
      this.heroBannerDataCopy = this.globals.catergoryBannerData;
      this.backgroundImg = this.getResponsiveImage(this.heroBannerDataCopy.backgroundImageD);
    }
  },
  methods: {
    /**
         * opens the video modal
         */
    openModal() {
      const videoSRC = this.heroBannerDataCopy.videoSrc;
      eventBus.$emit('open-player', videoSRC);
    },
    /**
         * emits the scroll-to event with link as payload
         * @param {string} link
         * @return {object} null
         */
    emitScrollSignal(link) {
      globalEventBus.$emit('scroll-to', link);
    },
  },
};
