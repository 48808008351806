import debounce from 'lodash.debounce';
import detectDeviceMixin from '../../../../components/common/mixins/detect-device-mixin';

export default {
  name: 'vx-stepper-control',
  props: {
    // initial value
    value: {
      type: Number,
      default: 1,
    },
    // minimum value
    minValue: {
      type: Number,
      default: 1,
    },
    // maximum value
    maxValue: {
      type: Number,
      default: 999,
    },
    // stock level value
    stockLevel: {
      type: Number,
      default: 100,
    },
    // indicates to disable stepper control
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [detectDeviceMixin],
  updated() {
    this.showSpinner();
  },
  data() {
    return {
      counter: JSON.parse(this.value),
    };
  },
  watch: {
    counter: debounce(function(newVal, oldVal) {
      const regex = RegExp('[^0-9]', 'g');
      newVal = regex.test(newVal) ? undefined : parseInt(newVal, 10);
      if (regex.test(oldVal)) {
        return;
      }
      if (this.stockLevel > 0) {
        if (this.minValue > this.stockLevel) {
          this.counter = parseInt(this.minValue, 10);
          this.$emit('minValueUpdated', parseInt(this.minValue, 10));
          this.$emit('disableAddToCart', parseInt(this.stockLevel, 10));
          this.hideSpinner();
        } else if (!regex.test(newVal) && newVal !== parseInt(oldVal, 10) && newVal >= parseInt(this.minValue, 10) && newVal <= parseInt(this.maxValue, 10) && newVal <= parseInt(this.stockLevel, 10)) {
          this.$emit('currentCount', newVal);
          this.hideSpinner();
        } else if (!regex.test(newVal) && newVal !== oldVal && newVal >= parseInt(this.minValue, 10) && newVal > parseInt(this.maxValue, 10)) {
          if (parseInt(this.maxValue, 10) > parseInt(this.stockLevel, 10) && newVal > parseInt(this.stockLevel, 10)) {
            this.counter = parseInt(this.stockLevel, 10);
            this.$emit('stockLevelUpdated', parseInt(this.stockLevel, 10));
            this.hideSpinner();
          } else {
            this.counter = parseInt(this.maxValue, 10);
            this.$emit('maxValueUpdated', parseInt(this.maxValue, 10));
            this.hideSpinner();
          }
        } else if (!regex.test(newVal) && newVal !== oldVal && newVal <= parseInt(this.maxValue, 10) && newVal < parseInt(this.minValue, 10) && newVal < parseInt(this.stockLevel, 10) && parseInt(this.minValue, 10) < parseInt(this.stockLevel, 10)) {
          if (parseInt(this.minValue, 10) > parseInt(this.stockLevel, 10)) {
            this.counter = parseInt(this.stockLevel, 10);
            this.$emit('stockLevelUpdated', parseInt(this.stockLevel, 10));
            this.hideSpinner();
          } else {
            this.counter = parseInt(this.minValue, 10);
            this.$emit('minValueUpdated', parseInt(this.minValue, 10));
            this.hideSpinner();
          }
        } else if (!regex.test(newVal) && newVal !== oldVal && newVal >= parseInt(this.minValue, 10) && newVal <= parseInt(this.maxValue, 10) && newVal > parseInt(this.stockLevel, 10)) {
          this.counter = parseInt(this.stockLevel, 10);
          this.$emit('stockLevelUpdated', parseInt(this.stockLevel, 10));
          this.hideSpinner();
        } else {
          this.counter = parseInt(oldVal, 10);
          this.hideSpinner();
        }
      } else if (!regex.test(newVal) && newVal !== parseInt(oldVal, 10) && newVal >= parseInt(this.minValue, 10) && newVal <= parseInt(this.maxValue, 10)) {
        this.$emit('currentCount', newVal);
        this.hideSpinner();
      } else if (!regex.test(newVal) && newVal !== oldVal && newVal >= parseInt(this.minValue, 10) && newVal > parseInt(this.maxValue, 10)) {
        this.counter = parseInt(this.maxValue, 10);
        this.$emit('maxValueUpdated', parseInt(this.maxValue, 10));
        this.hideSpinner();
      } else if (!regex.test(newVal) && newVal !== oldVal && newVal <= parseInt(this.maxValue, 10) && newVal < parseInt(this.minValue, 10)) {
        this.counter = parseInt(this.minValue, 10);
        this.$emit('minValueUpdated', parseInt(this.minValue, 10));
        this.hideSpinner();
      }
    }, 1000),
    value(newVal) {
      this.counter = parseInt(newVal, 10);
    },
  },
  methods: {
    /**
             * Function to increment count handler
             */
    incrementCount() {
      this.counter = parseInt(this.counter, 10) + 1;
    },
    /**
             * Function to decrement count handler
             */
    decrementCount() {
      if (parseInt(this.counter, 10) > 1) {
        this.counter = parseInt(this.counter, 10) - 1;
      }
    },
    /**
             * Function to show spinner on update of stepper control
             */
    showSpinner() {
      this.$emit('spinnerShow');
    },
    /**
             * Function to hide spinner on update of stepper control
             */
    hideSpinner() {
      this.$emit('spinnerHide');
    },
  },
};
