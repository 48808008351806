/**
 * header-mixin provides the methods for create account and clearing koch auth data.
 */

import globals from '../globals';
import kauthMixin from './kauth-mixin';

const headerMixin = {
  mixins: [kauthMixin],
  data() {
    return {
      globals,
    };
  },
  methods: {
    /**
     * Create account handler function
     */
    createAccountButHandler(profileNameForEstores) {
      if (this.globals.getIsSubscription()) {
        this.globals.setCookie('flow', 'checkout');
        this.globals.navigateToUrlWithParams('register');
      } else if (this.globals.isEStores()) {
        this.globals.navigateToUrlWithParams('register', profileNameForEstores, 'profileName');
      } else {
        this.globals.navigateToUrl('register');
      }
    },
    /**
     * Clears the Koch Auth Data
     */
    clearKauthData() {
      if (this.globals.getIsLoggedIn()) {
        this.deleteKauthData();
      }
    },
  },
};

export default headerMixin;
