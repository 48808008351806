var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-lease-agreement px-xs-3 row" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.country
        ? [
            _c(
              "vx-modal",
              {
                ref: "viewTermsModal",
                attrs: {
                  size: "large",
                  heading: _vm.i18n.leaseAgreement.heading
                }
              },
              [
                _c("vx-review-lease-agreement", {
                  ref: "agreementForm",
                  attrs: {
                    slot: "component",
                    i18n: _vm.i18n.reviewLeaseAgreement,
                    country: _vm.currentCountry,
                    "lease-accepted": _vm.leaseAccepted
                  },
                  on: {
                    onAcceptingTerms: _vm.toggleViewMode,
                    acceptedTerms: _vm.acceptedTerms
                  },
                  slot: "component"
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "section-header mb-xs-0 col-xs-12 px-xs-0" }, [
        _vm._v(
          _vm._s(_vm.sectionIndex) +
            ". " +
            _vm._s(_vm.i18n.leaseAgreement.heading)
        )
      ]),
      _vm._v(" "),
      !_vm.leaseAccepted
        ? _c("p", { staticClass: "sub-heading mt-xs-3 col-xs-12 px-xs-0" }, [
            _vm._v(_vm._s(_vm.i18n.leaseAgreement.subHeading))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.country
        ? [
            _vm._l(_vm.country, function(item, index) {
              return _c("div", [
                _c(
                  "div",
                  { staticClass: "section-address mt-xs-4 col-xs-12 px-xs-0" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-4 col-xs-6" }, [
                        _c("p", { staticClass: "title mb-xs-0" }, [
                          _vm._v(_vm._s(_vm.i18n.leaseAgreement.country))
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(item.name))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-4 col-xs-6" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-xs-12" }, [
                            _c("p", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.i18n.leaseAgreement.quantityHeading
                                ) +
                                  "(" +
                                  _vm._s(_vm.getQuantity(item.isocode)) +
                                  ")"
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(_vm.leasableItems, function(leasableItem) {
                                return _c(
                                  "div",
                                  { staticClass: "col-xs-12" },
                                  _vm._l(leasableItem.splitEntries, function(
                                    splitEntryItem
                                  ) {
                                    return splitEntryItem.deliveryAddress
                                      .country.name === item.name
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "mb-xs-4" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "item-header mb-xs-0"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      leasableItem.product.name
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "item-sku" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      leasableItem.product.code
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  })
                                )
                              })
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-4 col-xs-12" }, [
                        _vm.isEditable
                          ? _c(
                              "div",
                              { staticClass: "col-xs-12 px-xs-0 lease-btn" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn button-color text-trans",
                                    class: {
                                      "accepted-btn": item.leaseAccepted
                                    },
                                    attrs: {
                                      tabindex: "0",
                                      role: "button",
                                      "aria-label":
                                        _vm.i18n.leaseAgreement.leaseBtnLabel
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.reviewAgreement($event, item, index)
                                      },
                                      keyup: function($event) {
                                        if (
                                          !("button" in $event) &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter-space",
                                            undefined,
                                            $event.key,
                                            undefined
                                          )
                                        ) {
                                          return null
                                        }
                                        _vm.reviewAgreement($event, item, index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.i18n.leaseAgreement.leaseBtnLabel
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.leaseAccepted !== undefined && !item.leaseAccepted
                          ? _c(
                              "div",
                              { staticClass: "decline-error-message" },
                              [
                                _vm._v(
                                  _vm._s(_vm.i18n.leaseAgreement.declineMessage)
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  ]
                )
              ])
            }),
            _vm._v(" "),
            _vm.isEditable
              ? _c(
                  "div",
                  { staticClass: "col-xs-12 px-xs-0 lease-btn mt-xs-5" },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn button-color text-trans save-and-continue mr-xs-0",
                        attrs: {
                          disabled: _vm.leaseAccepted,
                          tabindex: "0",
                          role: "button",
                          "aria-label": _vm.i18n.leaseAgreement.saveButton
                        },
                        on: {
                          click: function($event) {
                            _vm.saveSection($event)
                          },
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter-space",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            _vm.saveSection($event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.leaseAgreement.saveButton))]
                    )
                  ]
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8c532014", { render: render, staticRenderFns: staticRenderFns })
  }
}