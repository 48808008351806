var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-brand-bar d-flex flex-column align-items-center",
      class: _vm.brandBarData.componentTheme,
      style: [_vm.backgroundStyle],
      attrs: {
        "aria-label": [_vm.backgroundAlt],
        id: _vm.brandBarData.componentId
      }
    },
    [
      _c("div", { staticClass: "w-xs-100 text-center" }, [
        _vm.brandBarData.header
          ? _c("h3", {
              staticClass: "vx-brand-bar__header my-xs-0 pb-md-5 pb-xs-3",
              style: [_vm.fontStyleHeader],
              domProps: { innerHTML: _vm._s(_vm.brandBarData.header) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.brandBarData.subHeader
          ? _c("p", {
              staticClass: "vx-brand-bar__sub-header mt-xs-3",
              style: [_vm.fontStyleSubHeader],
              domProps: { innerHTML: _vm._s(_vm.brandBarData.subHeader) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.brandBarData.header || _vm.brandBarData.subHeader
          ? _c("div", {
              staticClass: "title-underline",
              class: _vm.brandBarData.subHeader ? "" : "mt-xs-0",
              style: _vm.brandBarData.subHeaderColor
                ? { "border-bottom-color": _vm.brandBarData.subHeaderColor }
                : _vm.brandBarData.headerColor
                ? { "border-bottom-color": _vm.brandBarData.headerColor }
                : ""
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "vx-brand-bar__brands d-flex flex-wrap justify-content-center align-items-center"
          },
          _vm._l(_vm.brandBarData.brands, function(item) {
            return _c("span", { staticClass: "vx-brand-bar__brand-link" }, [
              item.brandLink && !item.jumpToHtml
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: item.isExternalImage
                          ? item.brandLink
                          : _vm.globals.getUrlWithContextPath(item.brandLink),
                        target: item.isExternalImage ? "_blank" : ""
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "brand-logo",
                        attrs: {
                          src: item.brandImage,
                          alt: item.brandImageAltText
                            ? item.brandImageAltText
                            : item.brandName
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              item.brandLink && item.jumpToHtml
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: item.isExternalImage
                          ? item.brandLink
                          : _vm.globals.getUrlWithContextPath(item.brandLink),
                        target: item.isExternalImage ? "_blank" : ""
                      },
                      on: {
                        click: function($event) {
                          _vm.emitScrollSignal(item.brandLink)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "brand-logo",
                        attrs: {
                          src: item.brandImage,
                          alt: item.brandImageAltText
                            ? item.brandImageAltText
                            : item.brandName
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !item.brandLink && !item.jumpToHtml
                ? _c("span", [
                    _c("img", {
                      staticClass: "brand-logo",
                      attrs: {
                        src: item.brandImage,
                        alt: item.brandImageAltText
                          ? item.brandImageAltText
                          : item.brandName
                      }
                    })
                  ])
                : _vm._e()
            ])
          })
        ),
        _vm._v(" "),
        _vm.brandBarData.ctaText &&
        _vm.brandBarData.ctaLink &&
        !_vm.brandBarData.jumpToHtml
          ? _c("a", {
              staticClass: "btn",
              class: _vm.brandBarData.ctaStyle,
              style: [_vm.fontStyleCta],
              attrs: {
                href: _vm.brandBarData.isExternalLink
                  ? _vm.brandBarData.ctaLink
                  : _vm.globals.getUrlWithContextPath(_vm.brandBarData.ctaLink),
                target: _vm.brandBarData.isExternalLink ? "_blank" : ""
              },
              domProps: { innerHTML: _vm._s(_vm.brandBarData.ctaText) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.brandBarData.ctaText &&
        _vm.brandBarData.ctaLink &&
        _vm.brandBarData.jumpToHtml
          ? _c("a", {
              staticClass: "btn",
              class: _vm.brandBarData.ctaStyle,
              style: [_vm.fontStyleCta],
              attrs: {
                href: _vm.brandBarData.isExternalLink
                  ? _vm.brandBarData.ctaLink
                  : _vm.globals.getUrlWithContextPath(_vm.brandBarData.ctaLink),
                target: _vm.brandBarData.isExternalLink ? "_blank" : ""
              },
              domProps: { innerHTML: _vm._s(_vm.brandBarData.ctaText) },
              on: {
                click: function($event) {
                  _vm.emitScrollSignal(_vm.brandBarData.ctaLink)
                }
              }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.brandBarData.ctaText &&
      _vm.brandBarData.ctaLink &&
      !_vm.brandBarData.jumpToHtml
        ? _c("a", {
            staticClass: "btn",
            class: _vm.brandBarData.ctaStyle,
            style: [_vm.fontStyleCta],
            attrs: {
              href: _vm.brandBarData.isExternalLink
                ? _vm.brandBarData.ctaLink
                : _vm.globals.getUrlWithContextPath(_vm.brandBarData.ctaLink),
              target: _vm.brandBarData.isExternalLink ? "_blank" : ""
            },
            domProps: { innerHTML: _vm._s(_vm.brandBarData.ctaText) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.brandBarData.ctaText &&
      _vm.brandBarData.ctaLink &&
      _vm.brandBarData.jumpToHtml
        ? _c("a", {
            staticClass: "btn",
            class: _vm.brandBarData.ctaStyle,
            style: [_vm.fontStyleCta],
            attrs: {
              href: _vm.brandBarData.isExternalLink
                ? _vm.brandBarData.ctaLink
                : _vm.globals.getUrlWithContextPath(_vm.brandBarData.ctaLink),
              target: _vm.brandBarData.isExternalLink ? "_blank" : ""
            },
            domProps: { innerHTML: _vm._s(_vm.brandBarData.ctaText) },
            on: {
              click: function($event) {
                _vm.emitScrollSignal(_vm.brandBarData.ctaLink)
              }
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0b676aca", { render: render, staticRenderFns: staticRenderFns })
  }
}