var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row vx-notify-button" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "col-xs-12 btn btn-tertiary notify-me-btn",
          on: {
            click: function($event) {
              _vm.handleNotifyMe($event)
            }
          }
        },
        [_vm._v(_vm._s(_vm.i18n.notifyMe))]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "notifyMeModal",
          attrs: { size: "extra-small", heading: _vm.i18n.notifyMeHeading },
          on: { close: _vm.modalClose }
        },
        [
          _c("vx-notify-me", {
            attrs: {
              slot: "component",
              title: _vm.i18n.notifyMeContent,
              productCode: _vm.productCode,
              i18n: _vm.i18n
            },
            on: {
              "notify-me-success": _vm.onNotifyMeSuccess,
              "notify-me-error": _vm.onNotifyMeError
            },
            slot: "component"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b0e3960a", { render: render, staticRenderFns: staticRenderFns })
  }
}