var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.categoryDescription &&
    _vm.globals.siteConfig.isCategoryDescriptionEnabled
    ? _c("div", { staticClass: "vx-category-desc mt-xs-5 px-sm-2" }, [
        _c("div", {
          staticClass: "category-details py-xs-5 mx-xs-3 mx-sm-4 mx-md-5",
          domProps: { innerHTML: _vm._s(_vm.categoryDescription) }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3535623b", { render: render, staticRenderFns: staticRenderFns })
  }
}