import {
  Validator,
} from 'vee-validate';
import globals from '../../../../components/common/globals';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import CheckoutService from '../../../../components/common/services/checkout-service';

export default {
  name: 'vx-guest-checkout',
  components: {
    vxSpinner,
  },
  props: {
    // Text coming from property file
    i18n: Object,
    // Indicates if guest checkout is enabled
    checkoutEnabled: String,
  },
  data() {
    return {
      checkoutService: new CheckoutService(),
      globals,
      guestEmail: '',
      disableField: false,
    };
  },
  computed: {},
  mounted() {
    // Validation on email field
    const veeCustomErrorMessage = {
      en: {
        custom: {
          email: {
            required: this.i18n.emailRequiredError,
            email: this.i18n.emailInvalidError,
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
  },
  methods: {
    /**
         * This function triggers the guest checkout call if there is a valid email id or sets the focus to email field to show email validation error
         */
    handleGuestCheckoutSubmit(event) {
      event.preventDefault();
      if (this.guestEmail) {
        if (this.checkoutEnabled) {
          this.$refs.guestForm.submit();
          // this.checkoutService.getGuestCheckout({}, this.handleGuestCheckoutResponse, this.handleGuestCheckoutError, this.guestEmail);
          this.$refs.spinner.showSpinner();
        }
      } else {
        this.globals.setFocusByName(this.$el, this.globals.getElementName(this.errors));
      }
    },
    /**
         * This function handles the success response of guest checkout call and navigates the user to checkout page
         */
    handleGuestCheckoutResponse() {
      this.$refs.spinner.hideSpinner();
      this.globals.navigateToUrl('checkout');
    },
    /**
         * This function handles the error of guest checkout
         */
    handleGuestCheckoutError() {
      this.$refs.spinner.hideSpinner();
    },
    hideServerError() {
    },
  },
};
