var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group stepper-control" }, [
    _c("div", { staticClass: "input-group" }, [
      _c(
        "button",
        {
          staticClass: "btn input-group-btn decrement-count px-xs-0 py-xs-0",
          attrs: {
            "aria-label": "Decrement Quantity",
            disabled:
              _vm.counter == "" ||
              _vm.counter == 1 ||
              _vm.counter === _vm.minValue ||
              _vm.isDisabled
          },
          on: { click: _vm.decrementCount }
        },
        [_c("span", { staticClass: "icon-minus" })]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.counter,
            expression: "counter"
          }
        ],
        staticClass: "px-xs-0",
        attrs: {
          type: "number",
          min: _vm.minValue,
          disabled: _vm.isDisabled,
          "aria-label": "Stepper Value of Quantity"
        },
        domProps: { value: _vm.counter },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.counter = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn input-group-btn increment-count px-xs-0 py-xs-0",
          attrs: {
            "aria-label": "Increment Quantity",
            disabled: _vm.counter >= _vm.maxValue || _vm.isDisabled
          },
          on: { click: _vm.incrementCount }
        },
        [_c("span", { staticClass: "icon-plus" })]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "assistive-text",
        attrs: { "aria-live": "assertive", "aria-atomic": "true" }
      },
      [_vm._v("\n        Stepper Value " + _vm._s(_vm.counter) + "\n    ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-33df3270", { render: render, staticRenderFns: staticRenderFns })
  }
}