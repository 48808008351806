var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-gifting-details" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.giftDetails.images
        ? [
            _c(
              "div",
              {
                staticClass: "gift-image d-flex justify-content-center mb-xs-4"
              },
              [_c("img", { attrs: { src: _vm.giftDetails.images[0].zoomUrl } })]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "gift-details mb-xs-0" }, [
        _vm._v(_vm._s(_vm.giftDetails.description))
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bb0e2426", { render: render, staticRenderFns: staticRenderFns })
  }
}