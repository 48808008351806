var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-add-edit-address" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addressVerificationModal",
          attrs: {
            size: "medium",
            heading: _vm.i18n.addressVerificationHeading
          },
          on: { close: _vm.closeAddressVerificationModal }
        },
        [
          _c("vx-address-verification", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n,
              unverifiedAddressProp: _vm.unverifiedAddress,
              isBussinessUnit: _vm.isBussinessUnit
            },
            on: {
              selectedAddress: _vm.handleSelectedAddressResponse,
              error: _vm.handleSelectedAddressError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("header", [
        _c("div", { staticClass: "section-header" }, [
          _vm._v("\n      " + _vm._s(_vm.i18n.addressHeader) + "\n    ")
        ])
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-xs-0",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c("p", { staticClass: "mb-xs-4 info-text" }, [
            _vm._v("\n      " + _vm._s(_vm.i18n.subHeading) + "\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section row" }, [
            _c(
              "div",
              { staticClass: "mb-xs-4 col-sm-6 col-xs-12 form-group" },
              [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "firstName",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.i18n.firstName) + "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        regex: /^[A-Za-z\s\.'-]+$/,
                        max: 35
                      },
                      expression:
                        "{ required: true, regex: /^[A-Za-z\\s\\.'-]+$/ ,max: 35 }"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.address.firstName,
                      expression: "address.firstName"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("firstName") },
                  attrs: {
                    type: "text",
                    maxlength: "35",
                    name: "firstName",
                    "aria-label": _vm.i18n.firstName,
                    "aria-describedby": _vm.errors.has("firstName")
                      ? "address_firstname_error_msg"
                      : null
                  },
                  domProps: { value: _vm.address.firstName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.address, "firstName", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("firstName")
                  ? _c(
                      "div",
                      {
                        staticClass: "mt-xs-2",
                        attrs: { "aria-hidden": !_vm.errors.has("firstName") }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            attrs: {
                              "aria-live": "polite",
                              role: "alert",
                              id: "address_firstname_error_msg"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.first("firstName")) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-xs-4 col-sm-6 col-xs-12 form-group" },
              [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "lastName",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.lastName))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        regex: /^[A-Za-z\s\.'-]+$/,
                        max: 35
                      },
                      expression:
                        "{ required: true, regex: /^[A-Za-z\\s\\.'-]+$/ ,max: 35 }"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.address.lastName,
                      expression: "address.lastName"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("lastName") },
                  attrs: {
                    type: "text",
                    maxlength: "35",
                    name: "lastName",
                    "aria-label": _vm.i18n.lastName,
                    "aria-describedby": _vm.errors.has("lastName")
                      ? "address_lastname_error_msg"
                      : null
                  },
                  domProps: { value: _vm.address.lastName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.address, "lastName", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("lastName")
                  ? _c(
                      "div",
                      {
                        staticClass: "mt-xs-2",
                        attrs: { "aria-hidden": !_vm.errors.has("lastName") }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            attrs: {
                              "aria-live": "polite",
                              role: "alert",
                              id: "address_lastname_error_msg"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.first("lastName")) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section" }, [
            _c("div", { staticClass: "section row" }, [
              _vm.globals.isB2B()
                ? _c(
                    "div",
                    { staticClass: "mb-xs-4 col-sm-6 col-xs-12 form-group" },
                    [
                      _vm._t("companyNameLabel", [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "companyName",
                              "aria-hidden": _vm.isDeviceAndroid()
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.i18n.newUsercompanyName) +
                                "\n            "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.globals.isB2B()
                        ? _c("input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true, max: 35 },
                                expression: "{required: true, max:35}"
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.address.companyName,
                                expression: "address.companyName"
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "input-error": _vm.errors.has("companyName")
                            },
                            attrs: {
                              type: "text",
                              maxlength: "35",
                              name: "companyName",
                              "aria-label": _vm.i18n.companyName
                            },
                            domProps: { value: _vm.address.companyName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.address,
                                  "companyName",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { max: 35 },
                                expression: "{max:35}"
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.address.companyName,
                                expression: "address.companyName"
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "input-error": _vm.errors.has("companyName")
                            },
                            attrs: {
                              type: "text",
                              maxlength: "35",
                              name: "companyName",
                              "aria-label": _vm.i18n.companyName
                            },
                            domProps: { value: _vm.address.companyName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.address,
                                  "companyName",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                      _vm._v(" "),
                      _vm.errors.has("companyName")
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c(
                              "span",
                              {
                                staticClass: "error-msg",
                                attrs: { "aria-live": "polite", role: "alert" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.errors.first("companyName")) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-xs-4 col-sm-6 col-xs-12 form-group" },
                [
                  _vm._t("phoneNumberLabel", [
                    _c(
                      "label",
                      {
                        attrs: {
                          for: "phoneNumber",
                          "aria-hidden": _vm.isDeviceAndroid()
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.i18n.phoneNumber) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("the-mask", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true, min: 15 },
                        expression: "{ required: true, min: 15 }"
                      }
                    ],
                    staticClass: "phone-number form-control",
                    class: { "input-error": _vm.errors.has("phoneNumber") },
                    attrs: {
                      masked: _vm.masked,
                      mask: "+1 ###-###-####",
                      type: "tel",
                      name: "phoneNumber",
                      "aria-label": _vm.i18n.phoneNumber
                    },
                    model: {
                      value: _vm.address.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.address, "phone", $$v)
                      },
                      expression: "address.phone"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("phoneNumber")
                    ? _c("div", { staticClass: "mt-xs-2" }, [
                        _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            attrs: { "aria-live": "polite", role: "alert" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.first("phoneNumber")) +
                                "\n            "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "section row" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "mb-xs-4 col-sm-6 col-xs-12 form-group dropdown"
                  },
                  [
                    _c(
                      "label",
                      {
                        attrs: {
                          for: "country",
                          "aria-hidden": !_vm.isTablet()
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.i18n.country) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("vx-dropdown-primary", {
                      ref: "countryDropdown",
                      attrs: {
                        "describedby-aria": _vm.errors.has("country")
                          ? "address_country_error_msg"
                          : null,
                        "required-aria": "true",
                        dropdownValues: _vm.countryList.options,
                        "aria-label": _vm.i18n.country,
                        name: "country",
                        dropdownError: _vm.errors.has("country")
                      },
                      on: {
                        "selected-option": function($event) {
                          _vm.setDropdownOption("country", $event)
                        }
                      },
                      model: {
                        value: _vm.address.country,
                        callback: function($$v) {
                          _vm.$set(_vm.address, "country", $$v)
                        },
                        expression: "address.country"
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("country")
                      ? _c("div", { staticClass: "mt-xs-2" }, [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: { id: "address_country_error_msg" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.first("country")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isBussinessUnit
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "mb-xs-4 form-group col-xs-12 col-md-6"
                        },
                        [
                          _c(
                            "label",
                            {
                              attrs: {
                                for: "unit",
                                "aria-hidden": !_vm.isTablet()
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.i18n.bussinessUnit) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("vx-dropdown-primary", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            ref: "businessUnitDropdown",
                            attrs: {
                              "describedby-aria": _vm.errors.has("unit")
                                ? "bussinessunit_error_msg"
                                : null,
                              "required-aria": "true",
                              name: "unit",
                              "aria-label": _vm.i18n.bussinessUnit,
                              dropdownValues: _vm.businessUnitsDropDown,
                              dropdownError: _vm.errors.has("unit"),
                              isDisabled: "true"
                            },
                            on: {
                              "selected-option": function($event) {
                                _vm.setDropdownOption("unit", $event)
                              }
                            },
                            model: {
                              value: _vm.address.unit,
                              callback: function($$v) {
                                _vm.$set(_vm.address, "unit", $$v)
                              },
                              expression: "address.unit"
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.has("unit")
                            ? _c("div", { staticClass: "mt-xs-2" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "error-msg",
                                    attrs: {
                                      "aria-live": "assertive",
                                      id: "bussinessunit_error_msg"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.errors.first("unit")) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section row" }, [
            _c(
              "div",
              { staticClass: "mb-xs-4 col-sm-6 col-xs-12 form-group" },
              [
                _vm._t("addressLine1Label", [
                  _vm.globals.isB2C()
                    ? _c(
                        "label",
                        {
                          attrs: {
                            for: "addressLine1",
                            "aria-hidden": _vm.isDeviceAndroid()
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.i18n.b2caddressLine1) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.globals.isB2B()
                    ? _c(
                        "label",
                        {
                          attrs: {
                            for: "addressLine1",
                            "aria-hidden": _vm.isDeviceAndroid()
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.i18n.b2baddressLine1) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        max: 60,
                        regex: /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i
                      },
                      expression:
                        "{ required: true, max:60,\n                  regex: /^(?!.*(?:(.*((p|post)[-.\\s]*(o|off|office)[-.\\s]*(box|bin)[-.\\s]*)|.*((p |post)[-.\\s]*(box|bin)[-.\\s]*)))).*$/i  }"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.address.line1,
                      expression: "address.line1"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("addressLine1") },
                  attrs: {
                    type: "text",
                    id: "autocompleteShipping",
                    maxlength: "60",
                    name: "addressLine1",
                    "aria-label": _vm.globals.isB2C()
                      ? _vm.i18n.b2caddressLine1
                      : _vm.i18n.b2baddressLine1,
                    "aria-describedby": _vm.errors.has("addressLine1")
                      ? "address_line1_error_msg"
                      : null
                  },
                  domProps: { value: _vm.address.line1 },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.address, "line1", $event.target.value)
                      },
                      function($event) {
                        $event.target.value = $event.target.value.substr(0, 60)
                      }
                    ],
                    change: function($event) {
                      _vm.addressLine1 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("addressLine1")
                  ? _c(
                      "div",
                      {
                        attrs: {
                          "aria-hidden": !_vm.errors.has("addressLine1")
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            attrs: {
                              "aria-live": "polite",
                              role: "alert",
                              id: "address_line1_error_msg"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.first("addressLine1")) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-xs-4 col-sm-6 col-xs-12 form-group" },
              [
                _vm._t("addressLine2Label", [
                  _vm.globals.isB2C()
                    ? _c(
                        "label",
                        {
                          attrs: {
                            for: "addressLine2",
                            "aria-hidden": _vm.isDeviceAndroid()
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.i18n.b2caddressLine2) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.globals.isB2B()
                    ? _c(
                        "label",
                        {
                          attrs: {
                            for: "addressLine2",
                            "aria-hidden": _vm.isDeviceAndroid()
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.i18n.b2baddressLine2) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        max: 40,
                        regex: /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i
                      },
                      expression:
                        "{ max:40,\n                  regex: /^(?!.*(?:(.*((p|post)[-.\\s]*(o|off|office)[-.\\s]*(box|bin)[-.\\s]*)|.*((p |post)[-.\\s]*(box|bin)[-.\\s]*)))).*$/i  }"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.address.line2,
                      expression: "address.line2"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("addressLine2") },
                  attrs: {
                    type: "text",
                    maxlength: "40",
                    name: "addressLine2",
                    "aria-label": _vm.globals.isB2C()
                      ? _vm.i18n.b2caddressLine2
                      : _vm.i18n.b2baddressLine2
                  },
                  domProps: { value: _vm.address.line2 },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.address, "line2", $event.target.value)
                      },
                      function($event) {
                        $event.target.value = $event.target.value.substr(0, 40)
                      }
                    ],
                    change: function($event) {
                      _vm.addressLine2 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("addressLine2")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.first("addressLine2")) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section row" }, [
            _c(
              "div",
              { staticClass: "mb-xs-4 col-sm-6 col-xs-12 form-group" },
              [
                _c(
                  "label",
                  {
                    attrs: { for: "city", "aria-hidden": _vm.isDeviceAndroid() }
                  },
                  [_vm._v(_vm._s(_vm.i18n.city))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|alpha_spaces|max:40",
                      expression: "'required|alpha_spaces|max:40'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.address.town,
                      expression: "address.town"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("city") },
                  attrs: {
                    type: "text",
                    maxlength: "40",
                    name: "city",
                    "aria-label": _vm.i18n.city,
                    "aria-describedby": _vm.errors.has("city")
                      ? "address_city_error_msg"
                      : null
                  },
                  domProps: { value: _vm.address.town },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.address, "town", $event.target.value)
                      },
                      function($event) {
                        $event.target.value = $event.target.value.substr(0, 40)
                      }
                    ],
                    change: function($event) {
                      _vm.city = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("city")
                  ? _c(
                      "div",
                      {
                        staticClass: "mt-xs-2",
                        attrs: { "aria-label": !_vm.errors.has("city") }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            attrs: {
                              "aria-live": "polite",
                              role: "alert",
                              id: "address_city_error_msg"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.first("city")) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-xs-4 col-sm-6 col-xs-12 form-group dropdown" },
              [
                _vm._t("stateLabel", [
                  _c("label", { attrs: { "aria-hidden": !_vm.isTablet() } }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.i18n.state) + "\n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("vx-dropdown-primary", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  ref: "stateDropdown",
                  attrs: {
                    "describedby-aria": _vm.errors.has("State")
                      ? "address_state_error_msg"
                      : null,
                    "required-aria": "true",
                    dropdownValues: _vm.regionList.options,
                    "aria-label": _vm.i18n.state,
                    name: "State",
                    dropdownError: _vm.errors.has("State")
                  },
                  on: {
                    "selected-option": function($event) {
                      _vm.setDropdownOption("region", $event)
                    }
                  },
                  model: {
                    value: _vm.address.region,
                    callback: function($$v) {
                      _vm.$set(_vm.address, "region", $$v)
                    },
                    expression: "address.region"
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("State")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "assertive",
                            id: "address_state_error_msg"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.first("State")) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section row" }, [
            _c(
              "div",
              { staticClass: "mb-xs-4 col-sm-6 col-xs-12 form-group" },
              [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "zipcode",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.i18n.zipcode) + "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        regex: /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/
                      },
                      expression:
                        "{ required: true, regex: /^((\\d{5}-\\d{4})|(\\d{5})|([A-Z]\\d[A-Z]\\s\\d[A-Z]\\d))$/ }"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.address.postalCode,
                      expression: "address.postalCode"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("zipcode") },
                  attrs: {
                    type: "text",
                    maxlength: "30",
                    name: "zipcode",
                    "aria-label": _vm.i18n.zipcode,
                    "aria-describedby": _vm.errors.has("zipcode")
                      ? "address_zip_error_msg"
                      : null
                  },
                  domProps: { value: _vm.address.postalCode },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.address, "postalCode", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("zipcode")
                  ? _c(
                      "div",
                      {
                        staticClass: "mt-xs-2",
                        attrs: { "aria-label": !_vm.errors.has("zipcode") }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            attrs: {
                              "aria-live": "polite",
                              role: "alert",
                              id: "address_zip_error_msg"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.first("zipcode")) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          ]),
          _vm._v(" "),
          _vm.isProfile
            ? [
                _c(
                  "div",
                  { staticClass: "default-shipping-address form-group" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.address.defaultAddress,
                          expression: "address.defaultAddress"
                        }
                      ],
                      attrs: {
                        id: "shippingAddress",
                        type: "checkbox",
                        "aria-label": _vm.i18n.defaultShippingAddress
                      },
                      domProps: {
                        checked: Array.isArray(_vm.address.defaultAddress)
                          ? _vm._i(_vm.address.defaultAddress, null) > -1
                          : _vm.address.defaultAddress
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.address.defaultAddress,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.address,
                                  "defaultAddress",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.address,
                                  "defaultAddress",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.address, "defaultAddress", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: {
                          for: "shippingAddress",
                          "aria-hidden": _vm.isDeviceAndroid()
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.i18n.defaultShippingAddress) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                false
                  ? _c(
                      "div",
                      { staticClass: "default-billing-address form-group" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.address.defaultBillingAddress,
                              expression: "address.defaultBillingAddress"
                            }
                          ],
                          attrs: {
                            id: "defaultBillingAddress",
                            type: "checkbox",
                            "aria-label": _vm.i18n.defaultBillingAddress
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.address.defaultBillingAddress
                            )
                              ? _vm._i(
                                  _vm.address.defaultBillingAddress,
                                  null
                                ) > -1
                              : _vm.address.defaultBillingAddress
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.address.defaultBillingAddress,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.address,
                                      "defaultBillingAddress",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.address,
                                      "defaultBillingAddress",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.address,
                                  "defaultBillingAddress",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "defaultBillingAddress",
                              "aria-hidden": _vm.isDeviceAndroid()
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.defaultBillingAddress) +
                                "\n        "
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.globals.isB2BWhiteLabel()
                  ? _c(
                      "div",
                      { staticClass: "mb-xs-4 pallet-shipments form-group" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.address.palletShipment,
                              expression: "address.palletShipment"
                            }
                          ],
                          attrs: {
                            id: "palletShipments",
                            type: "checkbox",
                            "aria-label": _vm.i18n.palletShipments
                          },
                          domProps: {
                            checked: Array.isArray(_vm.address.palletShipment)
                              ? _vm._i(_vm.address.palletShipment, null) > -1
                              : _vm.address.palletShipment
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.address.palletShipment,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.address,
                                      "palletShipment",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.address,
                                      "palletShipment",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.address, "palletShipment", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "palletShipments",
                              "aria-hidden": _vm.isDeviceAndroid()
                            }
                          },
                          [_vm._v(_vm._s(_vm.i18n.palletShipments))]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            : [
                (_vm.globals.isB2BWhiteLabel() && _vm.palletShipment) ||
                _vm.globals.siteConfig.isPalletEnabled
                  ? _c(
                      "div",
                      { staticClass: "mb-xs-4 pallet-shipments form-group" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.address.palletShipment,
                              expression: "address.palletShipment"
                            }
                          ],
                          attrs: {
                            id: "palletShipments",
                            type: "checkbox",
                            "aria-label": _vm.i18n.palletShipments
                          },
                          domProps: {
                            checked: Array.isArray(_vm.address.palletShipment)
                              ? _vm._i(_vm.address.palletShipment, null) > -1
                              : _vm.address.palletShipment
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.address.palletShipment,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.address,
                                      "palletShipment",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.address,
                                      "palletShipment",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.address, "palletShipment", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "palletShipments",
                              "aria-hidden": _vm.isDeviceAndroid()
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.palletShipments) +
                                "\n        "
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
          _vm._v(" "),
          _vm._t("saveButton", [
            _vm.isSingleShipping && _vm.globals.siteConfig.showPoBoxShippingMsg
              ? _c("div", { staticClass: "sub-heading-message px-xs-0" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.i18n.shippingAddressSubText) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  { "d-flex justify-content-start": _vm.isFirstAddress },
                  "modal-btn-EDGEspacing"
                ]
              },
              [
                _vm.globals.isB2B()
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "mt-xs-4 submit btn button-color text-trans modal-btn-IEspacing",
                        class: {
                          "modal-btn-primary-xl center-block": !_vm.isFirstAddress
                        },
                        attrs: { type: "button", disabled: _vm.errors.any() },
                        on: {
                          click: function($event) {
                            _vm.callAddressVerification()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.globals.userInfo.b2bUnitLevel ===
                                _vm.userStates.l1
                                ? _vm.buttonText
                                : _vm.submitButtonText
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass:
                          "mt-xs-4 submit btn btn-primary modal-btn-IEspacing",
                        class: {
                          "modal-btn-primary-xl center-block": !_vm.isFirstAddress
                        },
                        attrs: { type: "button", disabled: _vm.errors.any() },
                        on: {
                          click: function($event) {
                            _vm.callAddressVerification()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.buttonText) + "\n        "
                        )
                      ]
                    )
              ]
            )
          ])
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b048f5fc", { render: render, staticRenderFns: staticRenderFns })
  }
}