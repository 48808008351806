/**
 * This Component is a modal which comes up on the click of download button on List Page. User will get downloading options in this modal.
 */

import globals from '../../common/globals';
import ManageProfileShoppingListService from '../../common/services/manage-profile-shopping-lists-service';
import vxRadioButtonGroup from '../../common/vx-radio-button-group/vx-radio-button-group.vue';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';

export default {
  name: 'vx-download-product-info',
  components: {
    vxRadioButtonGroup,
    vxSpinner,
  },
  props: {
    // Copy text coming from properties files
    i18n: {
      type: Object,
    },
    // Indicates wheather products are available or not.
    noProducts: {
      type: Boolean,
    },
  },
  data() {
    return {
      globals,
      manageProfileShoppingListService: new ManageProfileShoppingListService(),
      downloadOptions: [
        {
          label: `${
            this.i18n.downloadProductInfo.downloadProductInfoOptionAll
          }`,
          name: `${this.i18n.downloadProductInfo.downloadProductInfoOptionAll}`,
          value: `${
            this.i18n.downloadProductInfo.downloadProductInfoAllproductsValue
          }`,
          selected: true,
          disabled: false,
        },
        {
          label: `${
            this.i18n.downloadProductInfo.downloadProductInfoOptionCurrent
          }`,
          name: `${
            this.i18n.downloadProductInfo.downloadProductInfoOptionCurrent
          }`,
          value: `${
            this.i18n.downloadProductInfo.downloadProductInfoCurrentValue
          }`,
          selected: false,
          disabled: false,
        },
      ],
      selectedDownloadOpt: `${
        this.i18n.downloadProductInfo.downloadProductInfoAllproductsValue
      }`,
    };
  },
  mounted() {
    if (this.noProducts) {
      this.$refs.downloadOptions.setSelectedByValue(
        this.downloadOptions[0].value,
      );
      this.selectedDownloadOpt = `${
        this.i18n.downloadProductInfo.downloadProductInfoAllproductsValue
      }`;
    } else {
      this.$refs.downloadOptions.setSelectedByValue(
        this.downloadOptions[1].value,
      );
      this.downloadOptions[1].disabled = false;
      this.selectedDownloadOpt = `${
        this.i18n.downloadProductInfo.downloadProductInfoCurrentValue
      }`;
    }
  },
  methods: {
    /**
     * Function is called when download button is clicked.
     */
    downloadExcel() {
      this.$emit('onExcelDownloadInit', this.selectedDownloadOpt);
    },
    /**
     * Function is called on changing radio button options for downloading product info.
     */
    selectedDownloadOption(data) {
      this.selectedDownloadOpt = data;
    },
  },
};
