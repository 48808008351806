var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-marketing-modal" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "marketingModal",
          attrs: {
            size: "small",
            heading: "Marketing Modal",
            "hide-heading": "true"
          }
        },
        [
          _c("iframe", {
            staticStyle: { width: "100%", height: "100vh" },
            attrs: {
              slot: "component",
              frameborder: "0",
              src: _vm.globals.getNavBaseUrl() + _vm.marketingModalPage
            },
            slot: "component"
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-61ca400a", { render: render, staticRenderFns: staticRenderFns })
  }
}