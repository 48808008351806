var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-business-unit" },
    [
      _vm.showInviteMsg
        ? _c("div", { staticClass: "toast py-xs-3 px-xs-4" }, [
            _c("span", {
              staticClass: "icon-check",
              attrs: { title: _vm.i18n.iconSuccessTitle }
            }),
            _vm._v("\n    " + _vm._s(_vm.i18n.inviteUser) + "\n    "),
            _c("span", {
              staticClass: "icon-x pull-right",
              attrs: {
                title: _vm.i18n.iconCloseTitle,
                "aria-label": _vm.i18n.iconCloseTitle
              },
              on: {
                click: function($event) {
                  _vm.showInviteMsg = !_vm.showInviteMsg
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "vx-details-section",
        {
          attrs: {
            i18n: _vm.unitDetailI18n,
            "details-data": _vm.detailPageData.detailsData,
            "edit-button": true,
            "disable-button": _vm.hasParent,
            "link-button": false,
            isUnitDetails: ""
          },
          on: {
            disable: _vm.handleDisableClick,
            enable: _vm.handleEnableClick,
            edit: function($event) {
              _vm.handleEditClick($event)
            }
          }
        },
        [
          _vm.hasParent
            ? _c("template", { slot: "info-section" }, [
                _c(
                  "h3",
                  {
                    staticClass:
                      "vx-details-section__info-heading my-xs-0 pb-xs-4 word-wrap-all"
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.detailPageData.unitDetailsData.name) +
                        "\n        " +
                        _vm._s(_vm.i18n.detailsText) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "word-wrap-all my-xs-0" }, [
                  _vm._v(
                    _vm._s(_vm.i18n.parentUnit) +
                      " " +
                      _vm._s(_vm.detailPageData.unitDetailsData.unit.name)
                  )
                ])
              ])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("vx-info-section", {
        attrs: {
          i18n: _vm.childInfoI18n,
          "info-data": _vm.detailPageData.childGroupData,
          "new-button": true,
          "existing-button": false,
          displayActiveOnly: false
        },
        on: {
          addNewTo: function($event) {
            _vm.handleAddChildUnit($event)
          }
        }
      }),
      _vm._v(" "),
      _c("vx-info-section", {
        attrs: {
          i18n: _vm.adminInfoI18n,
          "info-data": _vm.detailPageData.adminGroupData,
          "new-button": true,
          "existing-button": true,
          "icon-class": "icon-trash",
          displayActiveOnly: false
        },
        on: {
          delete: _vm.handleRemoveClick,
          addNewTo: function($event) {
            _vm.handleAddNewUserClick($event)
          },
          addExistingTo: _vm.handleAddExistingClick
        }
      }),
      _vm._v(" "),
      _c("vx-info-section", {
        attrs: {
          i18n: _vm.buyerInfoI18n,
          "info-data": _vm.detailPageData.buyerGroupData,
          "new-button": true,
          "existing-button": true,
          "icon-class": "icon-trash",
          displayActiveOnly: false
        },
        on: {
          delete: _vm.handleRemoveClick,
          addNewTo: function($event) {
            _vm.handleAddNewUserClick($event)
          },
          addExistingTo: _vm.handleAddExistingClick
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "editCompanyDetailsModal",
          attrs: { size: "large", heading: _vm.i18n.companyModalHeading }
        },
        [
          _c("vx-company-details", {
            attrs: {
              slot: "component",
              userData: _vm.user,
              i18n: _vm.i18n,
              "is-editable": ""
            },
            on: {
              companyDetailsubmitted: _vm.sendEditRequest,
              skip: _vm.closeEditParentModal
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addChildUnitModal",
          attrs: { size: "small", heading: _vm.i18n.addChildUnitheading },
          on: { close: _vm.resetValues }
        },
        [
          _c(
            "div",
            {
              staticClass: "row",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c(
                "form",
                {
                  staticClass: "col-xs-12 px-xs-0",
                  attrs: { novalidate: "true" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.sendAddChildUnitRequest($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" },
                    [
                      _c(
                        "label",
                        {
                          attrs: {
                            for: "companyName",
                            "aria-hidden": _vm.isDeviceAndroid()
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.companyLabel))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.childUnitDetails.companyName,
                            expression: "childUnitDetails.companyName"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "input-error": _vm.errors.has("companyName") },
                        attrs: {
                          type: "text",
                          name: "companyName",
                          "aria-label": _vm.i18n.companyLabel
                        },
                        domProps: { value: _vm.childUnitDetails.companyName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.childUnitDetails,
                              "companyName",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("companyName")
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c("span", {
                              staticClass: "error-icon icon-alert-triangle",
                              attrs: { title: _vm.i18n.iconAlertTitle }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "error-msg" }, [
                              _vm._v(_vm._s(_vm.errors.first("companyName")))
                            ])
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-xs-3 mb-sm-5 form-group col-xs-12 col-md-6"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "dropdown-label",
                          attrs: { for: "parentUnitDropdown" }
                        },
                        [_vm._v(_vm._s(_vm.i18n.parentUnit))]
                      ),
                      _vm._v(" "),
                      _c("vx-dropdown-primary", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        ref: "parentUnitDropdown",
                        attrs: {
                          "describedby-aria": _vm.errors.has("parentUnit")
                            ? "parent_unit_add_error_msg"
                            : null,
                          "required-aria": "true",
                          dropdownValues: _vm.limitedParentUnitsList,
                          "aria-label": _vm.i18n.parentUnit,
                          name: "parentUnit",
                          dropdownError: _vm.errors.has("parentUnit")
                        },
                        on: {
                          "selected-option": function($event) {
                            _vm.childUnitDetails.parentUnit = $event
                          }
                        },
                        model: {
                          value: _vm.childUnitDetails.parentUnit.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.childUnitDetails.parentUnit,
                              "value",
                              $$v
                            )
                          },
                          expression: "childUnitDetails.parentUnit.value"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("parentUnit")
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c("span", {
                              staticClass: "error-icon icon-alert-triangle",
                              attrs: { title: _vm.i18n.iconAlertTitle }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "error-msg",
                                attrs: {
                                  "aria-live": "assertive",
                                  id: "parent_unit_add_error_msg"
                                }
                              },
                              [_vm._v(_vm._s(_vm.errors.first("parentUnit")))]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xs-12 text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary modal-btn-primary-small modal-btn-xs-sticky save-btn",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.i18n.saveText))]
                    )
                  ])
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.hasParent
        ? _c(
            "vx-modal",
            {
              ref: "editChildUnitModal",
              attrs: {
                size: "small",
                heading:
                  _vm.i18n.editChildheading +
                  " " +
                  _vm.detailPageData.unitDetailsData.name
              },
              on: { close: _vm.resetValues }
            },
            [
              _c(
                "div",
                {
                  staticClass: "row",
                  attrs: { slot: "component" },
                  slot: "component"
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "col-xs-12 px-xs-0",
                      attrs: { novalidate: "true" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.sendEditRequest($event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mb-xs-4 form-group col-xs-12 col-md-6"
                        },
                        [
                          _c(
                            "label",
                            {
                              attrs: {
                                for: "companyName",
                                "aria-hidden": _vm.isDeviceAndroid()
                              }
                            },
                            [_vm._v(_vm._s(_vm.i18n.companyLabel))]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.childUnitDetails.companyName,
                                expression: "childUnitDetails.companyName"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "companyName",
                              "aria-label": _vm.i18n.companyLabel
                            },
                            domProps: {
                              value: _vm.childUnitDetails.companyName
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.childUnitDetails,
                                  "companyName",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.has("companyName")
                            ? _c("div", { staticClass: "mt-xs-2" }, [
                                _c("span", {
                                  staticClass: "error-icon icon-alert-triangle",
                                  attrs: { title: _vm.i18n.iconAlertTitle }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "error-msg" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("companyName"))
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mb-xs-3 mb-sm-5 form-group col-xs-12 col-md-6"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "dropdown-label",
                              attrs: { for: "parentUnitDropdown" }
                            },
                            [_vm._v(_vm._s(_vm.i18n.parentUnit))]
                          ),
                          _vm._v(" "),
                          _c("vx-dropdown-primary", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            ref: "parentUnitDropdown",
                            attrs: {
                              "describedby-aria": _vm.errors.has("parentUnit")
                                ? "parent_unit_edit_error_msg"
                                : null,
                              "required-aria": "true",
                              dropdownValues: _vm.limitedParentUnitsList,
                              "aria-label": _vm.i18n.parentUnit,
                              name: "parentUnit",
                              dropdownError: _vm.errors.has("parentUnit")
                            },
                            on: {
                              "selected-option": function($event) {
                                _vm.childUnitDetails.parentUnit = $event
                              },
                              primaryDropdownMounted: _vm.parentUnitDropdownInit
                            },
                            model: {
                              value: _vm.childUnitDetails.parentUnit.value,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.childUnitDetails.parentUnit,
                                  "value",
                                  $$v
                                )
                              },
                              expression: "childUnitDetails.parentUnit.value"
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.has("parentUnit")
                            ? _c("div", { staticClass: "mt-xs-2" }, [
                                _c("span", {
                                  staticClass: "error-icon icon-alert-triangle",
                                  attrs: { title: _vm.i18n.iconAlertTitle }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "error-msg",
                                    attrs: {
                                      "aria-live": "assertive",
                                      id: "parent_unit_edit_error_msg"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("parentUnit"))
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xs-12 text-center" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary modal-btn-primary-small modal-btn-xs-sticky save-btn",
                            attrs: { type: "submit" }
                          },
                          [_vm._v(_vm._s(_vm.i18n.saveText))]
                        )
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "disableUnitModal",
          attrs: { heading: _vm.i18n.disableUnitText, size: "extra-small" }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c("p", { staticClass: "mb-xs-0 pb-xs-5" }, [
                _vm._v(_vm._s(_vm.i18n.disableUnitMessage))
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary modal-btn-primary-small modal-btn-xs-sticky align-self-center",
                  on: { click: _vm.sendDisableRequest }
                },
                [_vm._v(_vm._s(_vm.i18n.disableText))]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "enableUnitModal",
          attrs: { heading: _vm.i18n.enableUnitText, size: "extra-small" }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c("p", { staticClass: "mb-xs-0 pb-xs-5" }, [
                _vm._v(_vm._s(_vm.i18n.enableUnitMessage))
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary modal-btn-primary-small modal-btn-xs-sticky align-self-center",
                  on: { click: _vm.sendEnableRequest }
                },
                [_vm._v(_vm._s(_vm.i18n.enableText))]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "removeModal",
          attrs: { heading: _vm.i18n.removeModalHeading, size: "extra-small" }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c("p", { staticClass: "mb-xs-0 pb-xs-5" }, [
                _vm._v(_vm._s(_vm.i18n.removeModalMessage))
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary modal-btn-primary-small modal-btn-xs-sticky align-self-center",
                  on: { click: _vm.sendRemoveRequest }
                },
                [_vm._v(_vm._s(_vm.i18n.removeText))]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addExistingModal",
          attrs: { heading: _vm.addExistingHeading, size: "extra-small" },
          on: { close: _vm.resetAddedValues }
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _vm.existingAddedTo === this.adminInfoI18n.groupLabel
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12" },
                    _vm._l(_vm.businessUnitUsers, function(item, index) {
                      return _c("div", { staticClass: "mb-xs-5" }, [
                        _c("div", { staticClass: "mb-xs-0" }, [
                          _c("label", { staticClass: "checkbox-container" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.userDetailsUrl +
                                    encodeURIComponent(item.uid)
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.addedExistingData,
                                  expression: "addedExistingData"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: item.uid,
                                checked: Array.isArray(_vm.addedExistingData)
                                  ? _vm._i(_vm.addedExistingData, item.uid) > -1
                                  : _vm.addedExistingData
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.addedExistingData,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item.uid,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.addedExistingData = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.addedExistingData = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.addedExistingData = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "pl-xs-5 mb-xs-0" }, [
                          _vm._v(_vm._s(item.displayUid))
                        ])
                      ])
                    })
                  )
                ])
              : _vm.existingAddedTo === this.buyerInfoI18n.groupLabel
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12" },
                    _vm._l(_vm.businessUnitUsers, function(item, index) {
                      return _c("div", { staticClass: "mb-xs-5" }, [
                        _c("div", { staticClass: "mb-xs-0" }, [
                          _c("label", { staticClass: "checkbox-container" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.userDetailsUrl +
                                    encodeURIComponent(item.uid)
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.addedExistingData,
                                  expression: "addedExistingData"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: item.uid,
                                checked: Array.isArray(_vm.addedExistingData)
                                  ? _vm._i(_vm.addedExistingData, item.uid) > -1
                                  : _vm.addedExistingData
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.addedExistingData,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item.uid,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.addedExistingData = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.addedExistingData = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.addedExistingData = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "pl-xs-5 mb-xs-0" }, [
                          _vm._v(_vm._s(item.displayUid))
                        ])
                      ])
                    })
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12 text-center" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary save-btn modal-btn-primary-small modal-btn-xs-sticky justify-content-center",
                  on: { click: _vm.sendAddExistingRequest }
                },
                [_vm._v(_vm._s(_vm.i18n.saveText))]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addUserModal",
          attrs: { size: "medium", heading: _vm.i18n.addUser },
          on: { close: _vm.resetUserValues }
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _c("vx-user-form", {
                attrs: {
                  slot: "component",
                  "is-email-exists": _vm.isEmailExists,
                  "user-role": _vm.UserRoles.ADMIN,
                  "business-units-list": _vm.parentUnitsList,
                  "form-data": _vm.addedUserDetails,
                  i18n: _vm.i18n,
                  type: "modal",
                  isEdit: false
                },
                on: {
                  showSpinner: _vm.showLoadingSpinner,
                  hideSpinner: _vm.hideLoadingSpinner,
                  setUsers: _vm.addAdministrator,
                  closeUserModal: _vm.closeUserModal
                },
                slot: "component"
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d8050aba", { render: render, staticRenderFns: staticRenderFns })
  }
}