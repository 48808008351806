/**
 * This Component is a modal which comes up on the click of Notify button for a product with no stock
 */
import {
  Validator,
} from 'vee-validate';
import globals from '../../common/globals';
import SearchBrowseService from '../../common/services/search-browse-service';

export default {
  name: 'vx-notify-me',
  computed: {},
  async mounted() {
    const veeCustomErrorMessage = {
      en: {
        custom: {
          notifyMe: {
            required: this.i18n.notifyError,
            email: this.i18n.notifyError,
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
    this.form.productCode = this.productCode;
  },
  components: {},
  props: {
    /**
     * Copy text coming from properties file
     */
    i18n: {
      type: Object,
    },
    /**
     * Code of the product is passed
     */
    productCode: {
      type: String,
      default: '',
    },
    /**
     * Title of the product is passed
     */
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        EMAIL: true,
        notifyMeEmailId: '',
        productCode: '',
      },
      globals,
      searchBrowseService: new SearchBrowseService(),
    };
  },
  methods: {
    /**
     * This function is called on the click of Add Button
     */
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const requestConfig = {};
          requestConfig.params = {
            productCode: this.form.productCode,
            EMAIL: this.form.EMAIL,
            notifyMeEmailId: this.form.notifyMeEmailId,
          };
          requestConfig.data = this.form;
          this.searchBrowseService.notifyMe(requestConfig, this.handleSubmitResponse, this.handleSubmitError);
        } else {
          this.globals.setFocusByName(this.$el, this.globals.getElementName(this.errors));
        }
      });
    },
    /**
     * This function handles response of notifyMe service call
     */
    handleSubmitResponse(response) {
      if (response) {
        this.$refs.notifyMe.reset();
        this.$emit('notify-me-success', this.i18n.notifyMeResponse);
      }
    },
    /**
     * This function handles error of notifyMe service call
     */
    handleSubmitError(error) {
      if (error) {
        this.$refs.notifyMe.reset();
        this.$emit('notify-me-error', error.response.data.errors[0].message);
      }
    },
  },
};
