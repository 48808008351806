/* eslint-disable no-mixed-operators */
/* eslint-disable no-prototype-builtins */
/**
 *  This component is the vertical view of product details
 */
import get from 'lodash.get';
import globals from '../../common/globals';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import vxSaveCart from '../../manage-shopping-cart/vx-save-cart/vx-save-cart.vue';
import vxNotifyMe from '../vx-notify-me/vx-notify-me.vue';
import flyoutBannerMixin from '../../common/vx-flyout-banner/vx-flyout-banner-mixin';
import {
  ProductAvailability,
  favorites,
  MaterialStatus,
  pages,
} from '../../common/mixins/vx-enums';
import mobileMixin from '../../common/mixins/mobile-mixin';
import {
  eventBus,
  cartEventBus,
  globalEventBus,
} from '../../../modules/event-bus';
import cookiesMixin from '../../common/mixins/cookies-mixin';
import AnalyticsService from '../../common/services/analytics-service';
import SearchBrowseService from '../../common/services/search-browse-service';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import vxStarRating from '../../common/vx-star-rating/vx-star-rating.vue';
import vxStepperControl from '../../common/vx-stepper-control/vx-stepper-control.vue';
import vxProductIcons from '../../common/vx-product-icons/vx-product-icons.vue';
import ctaLogicMixin from '../../common/mixins/cta-logic-mixin';

export default {
  data() {
    return {
      ProductAvailability,
      favorites,
      disableCheckbox: false,
      removeCompare: '',
      isHidden: true,
      pdpContextPath: '',
      globals,
      isHalfFilled: false,
      ratingCal: '',
      isActive: '',
      isCheckboxDisabled: false,
      analyticsService: new AnalyticsService(),
      maxCompareCount: '',
      maxCompare: {
        mobile: 2,
        desktop: 4,
      },
      searchBrowseService: new SearchBrowseService(),
      characterLimit: 150,
      isTruncatedName: false,
      showRating: true,
      showReviews: true,
      guestListName: '',
      MaterialStatus,
      refillQuantity: 0,
      isProductSelected: this.bundleProductStatus,
      soldIndividually: true,
      pages,
    };
  },
  props: {
    // Copy text coming from properties file
    i18n: {
      type: Object,
    },
    // Copy text coming from properties file
    product: {
      type: Object,
    },
    // indicates whether the product is unchecked
    uncheckProduct: {
      type: Boolean,
      default: false,
    },
    // indicates whether to show remove icon
    showRemoveOption: {
      type: Boolean,
      default: false,
    },
    // indicates whether to show compare checkbox
    showCompareOption: {
      type: Boolean,
      default: false,
    },
    // indicates whether to show select checkbox
    showSelectOption: {
      type: Boolean,
      default: false,
    },
    // indicates whether to show favorite icon
    isFavoriteVisible: {
      type: Boolean,
      default: false,
    },
    // indicates whether to show product indicators
    showProductIndicators: {
      type: Boolean,
      default: false,
    },
    // indicates whether to hide item id
    isItemIdHide: {
      type: Boolean,
      default: false,
    },
    // indicates whether refill is enabled
    isRefill: {
      type: Boolean,
      default: false,
    },
    // indicates whether the site is configured for Bazaar Voice
    isBazaarVoice: {
      type: String,
      default: '',
    },
    // indicates whether the site is configured for bulk
    isBulkEnabled: {
      type: Boolean,
      default: false,
    },
    // string which states bundle product status
    bundleProductStatus: {
      type: String,
      default: '',
    },
    // indicates whether the bundles checkbox is disabled
    isBundlesCheckboxDisabled: {
      type: Boolean,
      default: false,
    },
    // indicates whether to hide button block
    hideButtonBlock: {
      type: Boolean,
      default: false,
    },
    // indicates whether its the bundles tile
    isBundlesTile: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [cookiesMixin, flyoutBannerMixin, mobileMixin, ctaLogicMixin],
  name: 'vx-product-tile-vertical',
  components: {
    vxModal,
    vxSaveCart,
    vxNotifyMe,
    vxSpinner,
    vxStarRating,
    vxStepperControl,
    vxProductIcons,
  },
  watch: {
    refillQuantity() {
      this.$set(this.product, 'quantity', this.refillQuantity);
    },
  },
  created() {
    this.soldIndividually = this.product.soldIndividually;
  },
  methods: {
    /**
     * Function is triggered on the click of favorites icon
     */
    handleFavorites() {
      if (this.globals.loggedIn) {
        this.$refs.spinner.showSpinner();
        this.searchBrowseService.getShoppingLists(
          {},
          this.handleGetWishlistResponse,
          this.handleGetWishlistError,
        );
      } else {
        this.navigateToLogin();
      }
    },
    /**
     * Function to remove the item from Favorites List
     * @param  {String} wishlistUid wishlist id
     */
    deleteCartItem(wishlistUid) {
      const requestBody = {
        wishlistUid,
        product: {
          code: this.checkVariant(this.product),
        },
      };
      const requestConfig = {};
      requestConfig.data = requestBody;
      this.searchBrowseService.deleteCartItem(
        requestConfig,
        this.handleDeleteCartItemResponse,
        this.handleDeleteCartItemError,
      );
    },
    /**
     * Function handles the response of delete cart item service
     */
    handleDeleteCartItemResponse() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * Function handles the error of delete cart item service
     */
    handleDeleteCartItemError() { },
    /**
     * Function to get all saved wish lists of cart
     */
    handleGetWishlistResponse(response) {
      if (response) {
        const item = response.data.wishlists.filter(
          (list) => list.name.toLowerCase() === this.favorites.favorites,
        );
        if (this.isActive) {
          const requestdata = {
            entryType: 'PRODUCT',
            wishlistUid: item[0].wishlistUid,
            product: {
              code: this.checkVariant(this.product),
            },
            quantity: '1',
          };
          const requestConfig = {};
          requestConfig.data = requestdata;
          this.searchBrowseService.saveAList(
            requestConfig,
            this.handleSaveCartResponse,
            this.handleSaveCartError,
          );
        } else {
          this.deleteCartItem(item[0].wishlistUid);
        }
      }
    },
    /**
     * Function handles the error of get all wishlists service call
     */
    handleGetWishlistError(error) {
      if (error) {
        this.$refs.spinner.hideSpinner();
        this.showFlyout('error', error.data.description, true);
      }
    },
    /**
     * Function handles the response of save a list service call
     */
    handleSaveCartResponse(response) {
      if (response) {
        this.$refs.spinner.hideSpinner();
        this.showFlyout('success', response.data.description, true);
      }
    },
    /**
     * Function handles the error of save a list service call
     */
    handleSaveCartError(error) {
      if (error) {
        this.showFlyout('error', error.data.description, true);
      }
    },
    /**
     * Function to toggle state of favorite icon
     */
    toggleFav() {
      this.isActive = !this.isActive;
      this.handleFavorites();
      if (!this.isActive) {
        globalEventBus.$emit('announce', 'removed product from favourites');
      }
    },
    /**
     * Function triggered on click checkbox clicked
     */
    checkBoxClicked(event) {
      const self = this;
      if (event && !event.currentTarget.disabled) {
        this.product.checked = !this.product.checked;
        // this.$emit('checkedBox', this.product.checked);
        const comparedProduct = {
          code: self.product.code,
          assetCode: self.product.assetCode,
          images: self.product.images,
          name: encodeURI(self.product.name),
        };
        if (this.product.checked) {
          // create cookie append
          this.updateCookie('CompareCookie', comparedProduct, 'checked');
          this.$nextTick(() => {
            // this.$emit('incrementCounter', '1');
            this.$emit('checkedElement', this.product);
          });
        } else {
          // edit cookie pop
          this.updateCookie('CompareCookie', comparedProduct, 'unchecked');
          this.$nextTick(() => {
            // this.$emit('decrementCounter', '-1');
            this.$emit('unCheckedElement', this.product);
          });
        }
      }
    },
    /**
     * Function to update cookie
     * @param  {String} cookieName string of cookie name
     * @param  {String} cookieVal string of cookie value
     * @param  {String} status string of status
     */
    updateCookie(cookieName, cookieVal, status) {
      // let arr = [];
      const self = this;
      if (this.readCookie(cookieName)) {
        const cookieData = JSON.parse(this.readCookie(cookieName));
        const cookieProductCodes = cookieData.map((item) => item.code);
        if (status === 'checked' && cookieData.length < self.maxCompareCount) {
          if (cookieProductCodes.indexOf(cookieVal.code) === -1) {
            cookieData.push(cookieVal);
          }
        } else {
          cookieData.forEach((item, index) => {
            if (item.code === cookieVal.code) {
              cookieData.splice(index, 1);
            }
          });
        }
        this.createCookie(cookieName, JSON.stringify(cookieData));
      } else {
        const arr = [];
        arr.push(cookieVal);
        this.createCookie(cookieName, JSON.stringify(arr));
      }
    },
    /**
     * Function triggered on click of select checkbox
     */
    selectCheckBoxClicked(event) {
      if (this.isRefill) {
        this.$set(this.product, 'quantity', this.refillQuantity);
      }
      if (event) {
        if (this.isBulkEnabled) {
          this.product.isBulk = !this.product.isBulk;
        } else {
          this.product.checked = !this.product.checked;
        }
        if (this.product.checked || this.product.isBulk) {
          eventBus.$emit('selectCheckedElement', this.product);
        } else {
          eventBus.$emit('selectUnCheckedElement', this.product);
        }
      }
    },
    /**
     * Function called when user enters quantity in stepper control
     */
    getQuantity(evt) {
      this.refillQuantity = evt;
    },
    /**
     * Function to remove product from compare page
     */
    removeComparePageProduct() {
      this.updateCookie('CompareCookie', this.product, 'unchecked');
      eventBus.$emit('comparePageRemove', this.product.code);
      this.isHidden = false;
    },
    /**
     * Function trigerred on click of Add To Cart button
     */
    addToCart() {
      if (
        this.globals.asmLaunchMode === 'true'
        && !this.globals.getCookie('asmCartId')
      ) {
        this.$refs.asmModal.open();
      } else {
        this.$refs.spinner.showSpinner();
        let productQuantity = 1;
        if (this.product.minOrderQuantity) {
          productQuantity = this.product.minOrderQuantity;
        }
        const requestObjParams = {
          quantity: productQuantity,
          product: {
            code: `${this.product.code}`,
          },
        };
        const requestConfig = {};
        requestConfig.data = requestObjParams;
        this.searchBrowseService.addToCart(
          requestConfig,
          this.handleAddProductResponse,
          this.handleAddProductErrorResponse,
        );

        const analyticsObject = {
          code: this.product.code,
          name: this.product.name,
          quantity: '1', // the default quantity set to 1 as there is no stepper control to edit quantity
          price: this.product.price.value,
          currencyCode: this.product.price.currencyIso,
        };
        // sending the data to Google Analytics on Add to Cart button click
        this.analyticsService.trackAddToCart(analyticsObject);
        if (this.globals.siteId === 'gppro') {
          this.analyticsService.trackAddToCartGA4(analyticsObject);
        }
      }
    },

    /**
     * Function handles the response of add product service call
     */
    handleAddProductResponse(response) {
      this.$refs.spinner.hideSpinner();
      if (get(response, 'data')) {
        if (get(response, ['data', 'statusCode']) !== 'success') {
          let message = null;
          if (response.data.statusCode === this.ProductAvailability.OFFLINE) {
            message = this.i18n.addListToCartError;
          } else if (response.data.statusCode === this.ProductAvailability.MAX_PURCHASABLE_QTY_ERROR_CODE) {
            message = this.i18n.maxPurchaseableQuantityErrorMessage;
          } else if (response.data.statusCode === this.ProductAvailability.LOW_STOCK_ERROR_CODE) {
            message = this.i18n.lowStockErrorMessage;
          }
          this.showFlyout('error', message, true);
        } else {
          if (get(response, ['data', 'entry', 'product', 'assetCode']) === 'refill') {
            this.$refs.refillsModal.open();
          }
          cartEventBus.$emit('cart-update');
          cartEventBus.$emit('call-basic-cart');
          this.showFlyout(
            'success',
            `1 ${this.i18n.addToCartSuccessMessage}`,
            true,
            Number(this.globals.siteConfig.bannerTimer),
          );
        }
      }
    },
    /**
     * Function handles the error of add product service call
     */
    handleAddProductErrorResponse(error) {
      this.$refs.spinner.hideSpinner();
      if (
        error
        && error.response
        && error.response.data
        && error.response.data.errors.length !== 0
        && error.response.data.errors[0]
        && error.response.data.errors[0].code
      ) {
        if (
          error.response.data.errors[0].code
          === this.i18n.maxPurchaseableQuantityErrorCode
        ) {
          this.showFlyout(
            'error',
            `${this.i18n.maxPurchaseableQuantityUpdateMessage1} ${
              this.product.maxOrderableQuantity
            }, ${this.i18n.maxPurchaseableQuantityUpdateMessage2}`,
            true,
          );
        } else if (
          error.response.data.errors[0].code === this.i18n.lowStockErrorCode
        ) {
          this.showFlyout('error', this.i18n.lowStockErrorMessage, true);
        }
      }
    },
    /**
     * Function calculates rating
     * @param  {String} rating string of rating
     */
    calculateRating(rating) {
      this.ratingCal = Math.floor(rating);
      if (rating - this.ratingCal > 0) {
        this.isHalfFilled = true;
      }
    },
    /**
     * Function triggered on click of select a list icon
     */
    handleSelectList(event) {
      if (this.globals.loggedIn) {
        if (this.$parent.$el.classList.contains('swiper-slide')) {
          document.querySelector('.vx-slider').classList.add('modal-opened');
        }
        this.$refs.selectListModal.open(event);
        this.guestListName = '';
      } else if (this.globals.siteConfig.isGuestList) {
        this.guestListName = this.i18n.guestList;
      } else {
        this.navigateToLogin();
      }
    },
    /**
     * Function to navigate to login page
     */
    navigateToLogin() {
      const url = `${this.globals.getNavBaseUrl()}/login?site=${
        this.globals.siteId
      }`;
      window.location = url;
    },
    /**
     * Function to navigate to a particular product
     * @param  {String} variantURL string of variant url
     */
    navigateToProduct(variantURL) {
      const url = this.pdpContextPath + variantURL;
      window.location = url;
    },
    /**
     * Function is triggered on click of notify button
     */
    handleNotifyMe(event) {
      if (this.$parent.$el.classList.contains('swiper-slide')) {
        document.querySelector('.vx-slider').classList.add('modal-opened');
      }
      this.$refs.notifyMeModal.open(event);
    },
    /**
     * Function handles the response of select a list service call
     */
    onSelectListSuccess() {
      if (this.$refs.selectListModal) {
        this.$refs.selectListModal.close();
      }
      if (this.$parent.$el.classList.contains('swiper-slide')) {
        document.querySelector('.vx-slider').classList.remove('modal-opened');
      }
      this.showFlyout('success', this.i18n.selectListResponse, true);
    },
    /**
     * Function handles the error of notify service call
     */
    onNotifyMeError(error) {
      this.$refs.notifyMeModal.close();
      if (this.$parent.$el.classList.contains('swiper-slide')) {
        document.querySelector('.vx-slider').classList.remove('modal-opened');
      }
      this.showFlyout('error', error, true);
    },
    /**
     * Function handles the success of notify service call
     */
    onNotifyMeSuccess(success) {
      this.$refs.notifyMeModal.close();
      if (this.$parent.$el.classList.contains('swiper-slide')) {
        document.querySelector('.vx-slider').classList.remove('modal-opened');
      }
      this.showFlyout('success', success, true);
    },
    /**
     * Function is triggered on click of cross icon
     */
    modalClose() {
      if (this.$parent.$el.classList.contains('swiper-slide')) {
        document.querySelector('.vx-slider').classList.remove('modal-opened');
      }
    },
    /**
     * Function to format product name
     * @param  {String} productName string of product name
     * @param  {Number} characterLimit number of character limit
     */
    formatProductName(productName, characterLimit) {
      if (productName) {
        const formattedName = productName.replace(/<[^>]*>/g, '');
        if (formattedName.length > characterLimit) {
          this.isTruncatedName = true;
          return `${formattedName.substr(0, characterLimit)}...`;
        }
        return formattedName;
      }
      return undefined;
    },
    /**
     * Function to get product name
     * @param  {String} productName string of product name
     */
    getProductName(productName) {
      const fullName = productName.replace(/<[^>]*>/g, '');
      return fullName;
    },
    /**
     * Function triggered from the compare cookie
     */
    isProductComparable(data) {
      const self = this;
      if (data.length) {
        // This check is for the removal of last product from compare panel making the length as 0
        if (data.length === self.maxCompareCount) {
          // Disable checkboxes if maximum threshold value reached for comparison
          if (self.product.checked) {
            // Do not disable if products are checked
            self.isCheckboxDisabled = false;
          } else {
            self.isCheckboxDisabled = true;
          }
        } else if (
          // Disable checkboxes if products does not belong to same asset code
          data[0].assetCode
          && self.product.assetCode
          && data[0].assetCode === self.product.assetCode
        ) {
          self.isCheckboxDisabled = false;
        } else {
          // Disable checkbox by default if it does not matches above conditions
          self.isCheckboxDisabled = true;
        }
      } else if (self.product.assetCode) {
        // On removal of last product, enable checkboxes of products with assetcodes
        self.isCheckboxDisabled = false;
      }
    },
    /**
     * Function triggered on click of find a store button
     */
    findAStore() {
      const findStorePage = this.globals.getNavBaseUrl() + this.globals.serviceUrls.locateStore;
      window.location.href = findStorePage;
    },
    /**
     * Function to check variants exist for a product
     * @param  {Object} product object of product details
     */
    checkVariant(product) {
      if (product.hasVariant) {
        for (let i = 0; i < product.variantOptions.length; i += 1) {
          if (product.variantOptions[i].url === product.url) {
            return product.variantOptions[i].code;
          }
        }
      } else {
        return product.code;
      }
      return undefined;
    },
    /**
     * Function gets prices of variants
     * @param  {Object} product object of product details
     */
    getVariantsPrice(product) {
      if (
        product.priceRange
        && product.priceRange.minPrice
        && product.priceRange.maxPrice
      ) {
        if (
          product.priceRange.minPrice.formattedValue
          === product.priceRange.maxPrice.formattedValue
        ) {
          return product.priceRange.minPrice.formattedValue;
        }
        return `${product.priceRange.minPrice.formattedValue} - ${
          product.priceRange.maxPrice.formattedValue
        }`;
      }
      return product.price.formattedValue;
    },
    /**
     * Function is triggered on click of select checkbox
     */
    handleProductSelection() {
      this.$emit('productSelected', this.isProductSelected);
    },

    checkPLPButtonStatus(element) {
      if (this.globals.isB2C()) {
        if (element === 'stockMessage') {
          return this.product && this.product.stock && !this.product.hasVariant;
        }
        return !this.showSelectOption && (this.product.stock && (this.product.stock.stockLevelStatus === this.ProductAvailability.OUT_OF_STOCK));
      } if (this.globals.isB2B()) {
        if (element === 'stockMessage') {
          return this.product && this.product.materialStatus !== this.ProductAvailability.OBSOLETE && this.product.materialStatus !== this.ProductAvailability.PREDISCONTINUED && this.product.materialStatus !== this.ProductAvailability.COMING_SOON && this.product.stock && this.globals.siteConfig.showInventoryMessages && !this.product.hasVariant;
        }
        return (!this.showSelectOption && ((this.product.stock && ((this.product.stock.stockLevelStatus && this.product.stock.stockLevelStatus.code && this.product.stock.stockLevelStatus.code === this.ProductAvailability.OUT_OF_STOCK && this.product.materialStatus !== this.ProductAvailability.OBSOLETE && this.product.materialStatus !== this.ProductAvailability.PREDISCONTINUED) || this.product.stock.stockLevelStatus === this.ProductAvailability.OUT_OF_STOCK && this.product.materialStatus !== this.ProductAvailability.OBSOLETE && this.product.materialStatus !== this.ProductAvailability.PREDISCONTINUED) && (!(this.product.stock.hasOwnProperty('nextAvailableDate'))
          || (this.product.stock.hasOwnProperty('nextAvailableDate') && this.product.stock.nextAvailableDate === null))) || this.product.materialStatus === this.ProductAvailability.COMING_SOON));
      }
      return false;
    },
    verifyToDisplayNotifyme() {
      return this.shouldNotifymeDisplayedForProduct(this.product, this.pages.plp);
    },
    verifyDisablingAddtocart() {
      return this.shouldAddTocartDisabledForProduct(this.product, this.pages.plp);
    },
  },
  async mounted() {
    const self = this;
    this.calculateRating(this.product.bvAverageRating);
    this.isActive = this.product.isFavorite;
    self.pdpContextPath = window.ACC.config.encodedContextPath;
    if (
      this.product.minOrderQuantity
      && (((this.product.materialStatus === this.ProductAvailability.OBSOLETE || this.product.materialStatus === this.ProductAvailability.PREDISCONTINUED)
        && this.product.stock.stockLevelStatus
        === this.ProductAvailability.IN_STOCK)
        || (this.product.materialStatus === this.MaterialStatus.ACTIVE_PRODUCT
          && (this.product.stock.stockLevelStatus
            === this.ProductAvailability.IN_STOCK
            || this.product.stock.stockLevelStatus
            === this.ProductAvailability.LOW_STOCK)))
    ) {
      this.refillQuantity = this.product.minOrderQuantity;
    } else if (
      !this.product.minOrderQuantity
      && (((this.product.materialStatus === this.ProductAvailability.OBSOLETE || this.product.materialStatus === this.ProductAvailability.PREDISCONTINUED)
        && this.product.stock.stockLevelStatus
        === this.ProductAvailability.IN_STOCK)
        || (this.product.materialStatus === this.MaterialStatus.ACTIVE_PRODUCT
          && (this.product.stock.stockLevelStatus
            === this.ProductAvailability.IN_STOCK
            || this.product.stock.stockLevelStatus
            === this.ProductAvailability.LOW_STOCK)))
    ) {
      this.refillQuantity = 1;
    } else {
      this.refillQuantity = 0;
    }
    // Initial check to disable/enable compare checkboxes if cookie is present
    if (self.showCompareOption) {
      // set max compare count as per viewport
      if (self.isMobile()) {
        self.maxCompareCount = self.maxCompare.mobile;
      } else {
        self.maxCompareCount = self.maxCompare.desktop;
      }
      // Disable checkboxes by default if product does not have asset code
      if (typeof self.product.assetCode === 'undefined') {
        self.isCheckboxDisabled = true;
        self.$set(self.product, 'checked', false);
      }
      // read cookie and enable/disable checkboxes accordingly
      let cookieData = self.readCookie('CompareCookie');
      if (cookieData !== null && cookieData !== undefined) {
        cookieData = JSON.parse(cookieData);
        this.isProductComparable(cookieData);
      }
    }
    // Event for product removed from panel.
    eventBus.$on('removeFromPanel', (data) => {
      if (self.product.code === data) {
        self.$nextTick(() => {
          self.$set(self.product, 'checked', false);
        });
      }
    });
  },
  updated() {
    const self = this;
    if (this.uncheckProduct) {
      self.$set(self.product, 'checked', false);
    }
    // Event fired on addition or deletion of any product for comparison
    eventBus.$on('editedCompare', (data) => {
      this.isProductComparable(data);
    });
  },
};
