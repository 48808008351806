var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.productResourceData.dataSheets.dataSheets &&
    _vm.productResourceData.dataSheets.dataSheets.length > 0) ||
    (_vm.productResourceData.productResourceVideos.video &&
      _vm.productResourceData.productResourceVideos.video.length > 0)
    ? _c(
        "div",
        { staticClass: "product-resources-tab" },
        [
          _c(
            "vx-modal",
            {
              ref: "shareResourceModal",
              attrs: { size: "medium", heading: _vm.i18n.shareModal.title }
            },
            [
              _c("vx-share-resource", {
                attrs: {
                  slot: "component",
                  i18n: _vm.i18n.shareModal,
                  resource: _vm.shareResource
                },
                on: { "share-resource-success": _vm.onShareResourceSuccess },
                slot: "component"
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container product-resource-tab-selection" },
            [
              _c("div", { staticClass: "row heading-row" }, [
                _c("div", { staticClass: "col-xs-12 heading-cols" }, [
                  _c(
                    "ul",
                    { staticClass: "nav nav-tabs resource-tab-selection" },
                    [
                      _vm.productResourceData.dataSheets.dataSheets &&
                      _vm.productResourceData.dataSheets.dataSheets.length > 0
                        ? _c("li", { staticClass: "active" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "product-resources-tab__topleftheading",
                                attrs: {
                                  "data-toggle": "tab",
                                  href: "#productInformation",
                                  "aria-expanded": "true"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.toggleTabViews("productinfo")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.i18n.topLeftHeading) +
                                    "\n                        "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.productResourceData.productResourceVideos.video &&
                      _vm.productResourceData.productResourceVideos.video
                        .length > 0
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "product-resources-tab__toprightheading",
                                attrs: {
                                  "data-toggle": "tab",
                                  href: "#productResources",
                                  "aria-expanded": "false"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.toggleTabViews("productresources")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.i18n.topRightHeading) +
                                    "\n                        "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product-resources-tab__container tab-content" },
            [
              _c("div", { staticClass: "section row" }, [
                _vm.productResourceData.dataSheets.dataSheets &&
                _vm.productResourceData.dataSheets.dataSheets.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-md-6 col-sm-6 col-xs-12 product-resources-tab__productinfo-container",
                        class: {
                          "show-tab": _vm.showProductInfoTab,
                          "hide-tab": _vm.showProductResTab
                        },
                        attrs: { id: "productInformation" }
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "product-resources-tab__topleftheading subheadings pl-md-4"
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.i18n.topLeftHeading) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "product-resources-tab__topleftcontent pr-md-5 pr-sm-5 pr-xs-3 pl-md-4"
                            },
                            [
                              _vm.productInfoDocuments
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "product-resources-tab__subheading1 pt-xs-3 pt-sm-4 pb-xs-2"
                                    },
                                    [_vm._v(_vm._s(_vm.i18n.subHeading1))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "product-resources-tab__toprightcontent pl-xs-0"
                                },
                                _vm._l(
                                  _vm.productResourceData.dataSheets.dataSheets,
                                  function(product, index) {
                                    return !product.mimeType.includes(
                                      "Video"
                                    ) && !product.mimeType.includes("Audio")
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "product-resources-tab__list py-xs-3"
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pl-xs-2 pr-xs-0 mr-xs-0 img-icon col-xs-2"
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          product.resourceURL,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "imgBlock",
                                                        attrs: {
                                                          src: product.url,
                                                          alt: product.altText
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "description col-xs-10"
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          product.resourceURL,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "product-resources-tab__pname pt-xs-1 pb-xs-2",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            product.altText
                                                          )
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "product-resources-tab__dlink",
                                                      attrs: { role: "list" }
                                                    },
                                                    [
                                                      _c(
                                                        "li",
                                                        {
                                                          attrs: {
                                                            role: "listitem"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "download-link",
                                                              attrs: {
                                                                href:
                                                                  product.resourceURL,
                                                                target: "_blank"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                " +
                                                                  _vm._s(
                                                                    _vm.i18n
                                                                      .subHeading2
                                                                  ) +
                                                                  "\n                                            "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "li",
                                                        {
                                                          attrs: {
                                                            role: "listitem"
                                                          }
                                                        },
                                                        [
                                                          _vm.globals.siteConfig
                                                            .shareResourceEnabled
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mb-xs-3 share-resource-btn",
                                                                  attrs: {
                                                                    tabindex:
                                                                      "0",
                                                                    role:
                                                                      "button",
                                                                    "aria-label":
                                                                      _vm.i18n
                                                                        .share
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.handleShareResource(
                                                                        product
                                                                      )
                                                                    },
                                                                    keyup: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !(
                                                                          "button" in
                                                                          $event
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter-space",
                                                                          undefined,
                                                                          $event.key,
                                                                          undefined
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      _vm.handleShareResource(
                                                                        product
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                " +
                                                                      _vm._s(
                                                                        _vm.i18n
                                                                          .share
                                                                      ) +
                                                                      "\n                                            "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  }
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "product-resources-tab__topleftcontent pr-md-5 pr-sm-5 pr-xs-3 pl-md-4"
                            },
                            [
                              _vm.productInfoVideos
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "product-resources-tab__subheading1 pt-sm-4 pb-xs-2"
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.i18n.subHeading3) +
                                          "\n                        "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "product-resources-tab__toprightcontent pl-xs-0"
                                },
                                _vm._l(
                                  _vm.productResourceData.dataSheets.dataSheets,
                                  function(product, index) {
                                    return product.mimeType.includes("Video") ||
                                      product.mimeType.includes("Audio")
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "product-resources-tab__list py-xs-3"
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pl-xs-2 pr-xs-0 mr-xs-0 img-icon col-xs-2"
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          product.resourceURL,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "imgBlock",
                                                        attrs: {
                                                          src: product.url,
                                                          alt: product.altText
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "description col-xs-10"
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          product.resourceURL,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "product-resources-tab__pname pt-xs-1 pb-xs-2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            " +
                                                              _vm._s(
                                                                product.altText
                                                              ) +
                                                              "\n                                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "product-resources-tab__dlink",
                                                      attrs: { role: "list" }
                                                    },
                                                    [
                                                      _c(
                                                        "li",
                                                        {
                                                          attrs: {
                                                            role: "listitem"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "view-link",
                                                              attrs: {
                                                                href:
                                                                  product.resourceURL,
                                                                target: "_blank"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.i18n
                                                                      .view
                                                                  ) +
                                                                  "\n                                        "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "li",
                                                        {
                                                          attrs: {
                                                            role: "listitem"
                                                          }
                                                        },
                                                        [
                                                          _vm.globals.siteConfig
                                                            .shareResourceEnabled
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mb-xs-3 share-resource-btn",
                                                                  attrs: {
                                                                    tabindex:
                                                                      "0",
                                                                    role:
                                                                      "button",
                                                                    "aria-label":
                                                                      _vm.i18n
                                                                        .share
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.handleShareResource(
                                                                        product
                                                                      )
                                                                    },
                                                                    keyup: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !(
                                                                          "button" in
                                                                          $event
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter-space",
                                                                          undefined,
                                                                          $event.key,
                                                                          undefined
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      _vm.handleShareResource(
                                                                        product
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                            " +
                                                                      _vm._s(
                                                                        _vm.i18n
                                                                          .share
                                                                      ) +
                                                                      "\n                                        "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  }
                                )
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.productResourceData.productResourceVideos.video &&
                _vm.productResourceData.productResourceVideos.video.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-md-6 col-sm-6 col-xs-12 product-resources-tab__productres-container",
                        class: {
                          "show-tab": _vm.showProductResTab,
                          "hide-tab": _vm.showProductInfoTab
                        },
                        attrs: { id: "productResources" }
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "product-resources-tab__toprightheading subheadings"
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.i18n.topRightHeading) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm.productResourcesDocuments
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "product-resources-tab__subheading1 pt-xs-3 pt-sm-4 pb-xs-2"
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.i18n.subHeading1) +
                                      "\n                    "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              staticClass:
                                "product-resources-tab__toprightcontent pl-xs-0"
                            },
                            _vm._l(
                              _vm.productResourceData.productResourceVideos
                                .video,
                              function(product, index) {
                                return !product.mimeType.includes("Video") &&
                                  !product.mimeType.includes("Audio")
                                  ? _c(
                                      "li",
                                      {
                                        staticClass:
                                          "product-resources-tab__list py-xs-3"
                                      },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pl-xs-2 pr-xs-0 mr-xs-0 img-icon col-xs-2"
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: product.resourceURL,
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "imgBlock",
                                                    attrs: {
                                                      src: product.url,
                                                      alt: product.altText
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "description col-xs-10"
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: product.resourceURL,
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "product-resources-tab__pname pt-xs-1 pb-xs-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          product.altText
                                                        ) +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "product-resources-tab__dlink",
                                                  attrs: { role: "list" }
                                                },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      attrs: {
                                                        role: "listitem"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "download-link",
                                                          attrs: {
                                                            href:
                                                              product.resourceURL,
                                                            target: "_blank",
                                                            download:
                                                              "sample.pdf"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            " +
                                                              _vm._s(
                                                                _vm.i18n
                                                                  .subHeading2
                                                              ) +
                                                              "\n                                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "li",
                                                    {
                                                      attrs: {
                                                        role: "listitem"
                                                      }
                                                    },
                                                    [
                                                      _vm.globals.siteConfig
                                                        .shareResourceEnabled
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mb-xs-3 share-resource-btn",
                                                              attrs: {
                                                                tabindex: "0",
                                                                role: "button",
                                                                "aria-label":
                                                                  _vm.i18n.share
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.handleShareResource(
                                                                    product
                                                                  )
                                                                },
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !(
                                                                      "button" in
                                                                      $event
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter-space",
                                                                      undefined,
                                                                      $event.key,
                                                                      undefined
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  _vm.handleShareResource(
                                                                    product
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.i18n
                                                                      .share
                                                                  ) +
                                                                  "\n                                        "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              }
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm.productResourcesVideos
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "product-resources-tab__subheading1 pt-sm-4 pb-xs-2"
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.i18n.subHeading3) +
                                      "\n                    "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              staticClass:
                                "product-resources-tab__toprightcontent pl-xs-0"
                            },
                            _vm._l(
                              _vm.productResourceData.productResourceVideos
                                .video,
                              function(product, index) {
                                return product.mimeType.includes("Video") ||
                                  product.mimeType.includes("Audio")
                                  ? _c(
                                      "li",
                                      {
                                        staticClass:
                                          "product-resources-tab__list py-xs-3"
                                      },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pl-xs-2 pr-xs-0 mr-xs-0 img-icon col-xs-2"
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: product.resourceURL,
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          product.resourceURL,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "imgBlock",
                                                        attrs: {
                                                          src: product.url,
                                                          alt: product.altText
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "description col-xs-10"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "pt-xs-1 pb-xs-2"
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          product.resourceURL,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "product-resources-tab__pname"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                product.altText
                                                              ) +
                                                              "\n                                            "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "product-resources-tab__dlink",
                                                  attrs: { role: "list" }
                                                },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      attrs: {
                                                        role: "listitem"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "view-link",
                                                          attrs: {
                                                            href:
                                                              product.resourceURL,
                                                            target: "_blank"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            " +
                                                              _vm._s(
                                                                _vm.i18n.view
                                                              ) +
                                                              "\n                                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "li",
                                                    {
                                                      attrs: {
                                                        role: "listitem"
                                                      }
                                                    },
                                                    [
                                                      _vm.globals.siteConfig
                                                        .shareResourceEnabled
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mb-xs-3 share-resource-btn",
                                                              attrs: {
                                                                tabindex: "0",
                                                                role: "button",
                                                                "aria-label":
                                                                  _vm.i18n.share
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.handleShareResource(
                                                                    product
                                                                  )
                                                                },
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !(
                                                                      "button" in
                                                                      $event
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter-space",
                                                                      undefined,
                                                                      $event.key,
                                                                      undefined
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  _vm.handleShareResource(
                                                                    product
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.i18n
                                                                      .share
                                                                  ) +
                                                                  "\n                                        "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              }
                            )
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c77e1f72", { render: render, staticRenderFns: staticRenderFns })
  }
}