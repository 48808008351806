var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-shipping-method row" }, [
    _c("div", { staticClass: "col-xs-8" }, [
      _c(
        "p",
        { staticClass: "section-header mb-xs-0", attrs: { role: "heading" } },
        [_vm._v(_vm._s(_vm.sectionIndex) + ". " + _vm._s(_vm.i18n.heading))]
      )
    ]),
    _vm._v(" "),
    _c(
      "a",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isEditable,
            expression: "!isEditable"
          }
        ],
        staticClass: "d-flex justify-content-end col-xs-4"
      },
      [
        _c(
          "span",
          {
            staticClass: "shipping-method-change",
            attrs: {
              tabindex: "0",
              role: "button",
              "aria-label": _vm.i18n.changeButton
            },
            on: {
              click: function($event) {
                _vm.editMethod($event)
              },
              keyup: function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k(
                    $event.keyCode,
                    "enter-space",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                _vm.editMethod($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.i18n.changeButton))]
        )
      ]
    ),
    _vm._v(" "),
    _vm.isShippingMultiple
      ? _c(
          "div",
          { staticClass: "col-xs-12" },
          [
            _c("vx-multiple-shipping-method", {
              attrs: {
                "address-changed": _vm.addressChanged,
                i18n: _vm.i18n,
                "is-editable": _vm.isEditable,
                "split-applicable": _vm.splitApplicable,
                "checkout-data": _vm.checkoutData
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isShippingMultiple
      ? _c(
          "div",
          { staticClass: "col-xs-12" },
          [
            _c("vx-single-shipping-method", {
              attrs: {
                "address-changed": _vm.addressChanged,
                i18n: _vm.i18n,
                "is-editable": _vm.isEditable,
                "checkout-data": _vm.checkoutData
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3a0d9956", { render: render, staticRenderFns: staticRenderFns })
  }
}