var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "dropdown-primary", attrs: { tabindex: "-1" } },
    [
      _c("div", { staticClass: "dropdown", on: { keydown: _vm.typeAhead } }, [
        _c(
          "div",
          {
            staticClass: "form-control btn-default dropdown-btn",
            class: {
              "input-error": _vm.dropdownError,
              "multi-dropdown": _vm.multiSelectDropdown
            },
            attrs: {
              role: "combobox",
              "data-toggle": "dropdown",
              disabled: _vm.isDisabled || _vm.disable,
              "aria-haspopup": "true",
              "aria-expanded": "false",
              tabindex: _vm.isDisabled ? "-1" : "0",
              "aria-label": _vm.ariaLabel,
              "aria-required": _vm.requiredAria ? true : null,
              "aria-describedby": _vm.describedbyAria,
              "aria-invalid": _vm.dropdownError ? "true" : "false"
            },
            on: { click: _vm.selectItemOnClick },
            model: {
              value: _vm.dropdownInput,
              callback: function($$v) {
                _vm.dropdownInput = $$v
              },
              expression: "dropdownInput"
            }
          },
          [
            _c("p", { staticClass: "labelText" }, [
              _vm._v(
                _vm._s(
                  _vm.dropdownInput != "Select option"
                    ? _vm.dropdownInput
                    : "Select " + _vm.ariaLabel
                )
              )
            ]),
            _c("span", [_vm._v(" ")]),
            _vm._v(" "),
            _c("span", { staticClass: "icon-chevron-down" })
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          {
            staticClass: "dropdown-menu",
            attrs: { role: _vm.dropdownValues.length > 0 ? "menu" : false }
          },
          [
            _vm.multiSelectDropdown
              ? _vm._l(_vm.dropdownValues, function(item, index) {
                  return _c(
                    "li",
                    {
                      class: {
                        selected: item.label === _vm.dropdownInput,
                        disabled: item.disable
                      },
                      attrs: {
                        role: "menuitem",
                        "aria-selected": item.label === _vm.dropdownInput
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.multiSelectOptions,
                            expression: "multiSelectOptions"
                          }
                        ],
                        attrs: { type: "checkbox", name: item.label },
                        domProps: {
                          value: item,
                          checked: Array.isArray(_vm.multiSelectOptions)
                            ? _vm._i(_vm.multiSelectOptions, item) > -1
                            : _vm.multiSelectOptions
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.multiSelectOptions,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = item,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.multiSelectOptions = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.multiSelectOptions = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.multiSelectOptions = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          on: {
                            click: function($event) {
                              _vm.selectCheckBoxClicked(item, $event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    ]
                  )
                })
              : _vm.$parent.$refs.addNewAddressModal
              ? _vm._l(_vm.dropdownValues, function(item, index) {
                  return _c("div", [
                    _c(
                      "li",
                      {
                        class: [
                          {
                            selected: item.label === _vm.dropdownInput,
                            disabled: item.disable
                          },
                          "display-inline-block"
                        ],
                        staticStyle: { "padding-right": "40px" },
                        attrs: {
                          role: "menuitem",
                          "aria-selected": item.label === _vm.dropdownInput
                        },
                        on: {
                          click: function($event) {
                            _vm.dropdownOptionChange(item, $event)
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticStyle: { "white-space": "break-spaces" },
                            attrs: {
                              href: "#",
                              "data-value": item.value,
                              "aria-label": item.ariaLabel
                                ? item.ariaLabel
                                : item.label
                            },
                            on: { keydown: _vm.collapseDropdown }
                          },
                          [
                            _vm._v(_vm._s(item.label)),
                            item.stock
                              ? _c("span", { staticClass: "pr-xs-3" }, [
                                  _vm._v(_vm._s(item.stock))
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      staticClass:
                        "icon-default align-items-end  pr-xs-3 pr-md-4",
                      staticStyle: {
                        "margin-top": "-28px",
                        float: "right",
                        position: "relative",
                        cursor: "pointer"
                      },
                      attrs: { title: "Edit" },
                      on: {
                        click: function($event) {
                          _vm.editDropdownAddress(item)
                        }
                      }
                    })
                  ])
                })
              : _vm._l(_vm.dropdownValues, function(item, index) {
                  return _c(
                    "li",
                    {
                      class: [
                        {
                          selected: item.label === _vm.dropdownInput,
                          disabled: item.disable
                        },
                        "display-inline-block"
                      ],
                      attrs: {
                        role: "menuitem",
                        "aria-selected": item.label === _vm.dropdownInput
                      },
                      on: {
                        click: function($event) {
                          _vm.dropdownOptionChange(item, $event)
                        }
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "#",
                            "data-value": item.value,
                            "aria-label": item.ariaLabel
                              ? item.ariaLabel
                              : item.label
                          },
                          on: { keydown: _vm.collapseDropdown }
                        },
                        [
                          _vm._v(_vm._s(item.label)),
                          item.stock
                            ? _c("span", { staticClass: "pr-xs-3" }, [
                                _vm._v(_vm._s(item.stock))
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4efa273f", { render: render, staticRenderFns: staticRenderFns })
  }
}