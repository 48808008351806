import vxCartProductTile from '../vx-cart-product-tile/vx-cart-product-tile.vue';
// import shoppingCartData from '../vx-shopping-cart/vx-shopping-cart-mock-data';
import globals from '../../common/globals';
import vxEmptyCart from '../vx-empty-cart/vx-empty-cart.vue';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import ManageShoppingCartService from '../../common/services/manage-shopping-cart-service';
import {
  cartEventBus,
  eventBus,
} from '../../../modules/event-bus';

export default {
  name: 'vx-mini-cart',
  components: {
    vxCartProductTile,
    vxEmptyCart,
    vxSpinner,
  },
  props: {
    // Text coming from property file
    i18n: Object,
    // Indicates if cart is Sample (gpxpress)
    isSampleCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shoppingCartData: {},
      globals,
      displayCount: 3,
      manageShoppingCartService: new ManageShoppingCartService(),
      dataLoaded: false,
    };
  },
  created() {
    // listening to emits of delete and edit item from cart-product tile component
    cartEventBus.$on('cart-update', () => {
      this.callShoppingCart();
    });
    cartEventBus.$on('cart-update-error', () => {
      // this.callShoppingCart('FULL');
    });
  },
  computed: {},
  mounted() {
    this.callMiniCart();
  },
  methods: {
    /**
     * This function gets mini cart details
     */
    callMiniCart() {
      this.dataLoaded = false;
      this.manageShoppingCartService.getDefaultCart({}, this.handleMiniCartResponse, this.handleMiniCartError);
      this.$refs.spinner.showSpinner();
    },
    /**
     * This function handles the response of mini cart details call
     */
    handleMiniCartResponse(response) {
      if (this.$refs.spinner) {
        this.$refs.spinner.hideSpinner();
      }
      if (response && response.data) {
        this.shoppingCartData = response.data;
        this.dataLoaded = true;
      }
    },
    /**
     * This function gets cart details
     */
    callShoppingCart() {
      const requestConfig = {};
      this.manageShoppingCartService.getFullCartService(requestConfig, this.handleShoppingCartResponse, this.handleShoppingCartError);
      // this.spinnerComponent.showSpinner();
    },
    /**
     * This function handles the response of cart details
     */
    handleShoppingCartResponse(response) {
      // this.spinnerComponent.hideSpinner();
      if (response && response.data) {
        this.shoppingCartData = response.data;
        this.individualProducts = this.shoppingCartData.entries;
        if (this.shoppingCartData.appliedOrderPromotions.length) {
          this.shoppingCartData.appliedOrderPromotions.forEach((promotion) => {
            if (promotion.giveAwayCouponCodes.length && promotion.description) {
              this.giveAwayCouponDescription = promotion.description;
            } else {
              this.giveAwayCouponDescription = '';
            }
          });
        } else {
          this.giveAwayCouponDescription = '';
        }
        eventBus.$emit('cart-details', this.shoppingCartData.entries);
        cartEventBus.$emit('total-items-updated', response.data.totalItems, response.data.code);
        if (this.globals.isB2B()) {
          if (this.globals.getIsLoggedIn()) {
            this.showLeaseError();
          }
          this.installationPrice = this.getInstallationPrice();
        }
        this.promotionData = this.getProductPromotions();
        if (this.shoppingCartData.entries.length !== 0) {
          this.cartHasVisibleItems = true;
        } else {
          this.cartHasVisibleItems = false;
        }
        this.$root.$emit('cartHasVisibleItems', this.cartHasVisibleItems);
        this.$root.$emit('full-cart-called', this.shoppingCartData);
        this.$root.$emit('cart-reloaded', true);
      }
    },
    /**
     * This function handles the error of cart details
     */
    handleShoppingCartError() {
      this.spinnerComponent.hideSpinner();
      this.cartHasVisibleItems = false;
      this.$root.$emit('cartHasVisibleItems', this.cartHasVisibleItems);
    },
    /**
     * This function handles the error of mini cart details call
     */
    handleMiniCartError() {
      if (this.$refs.spinner) {
        this.$refs.spinner.hideSpinner();
        this.dataLoaded = true;
      }
    },
    /**
     * This function shows last n elements from mini cart
     * @param  {Array} array products from mini cart response
     */
    lastNelements(array) {
      const filteredArray = array.filter((item) => item.visible);
      return filteredArray.slice(Math.max(filteredArray.length - this.displayCount, 0));
    },
  },
};
