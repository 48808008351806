var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-library desktop-wrapper",
      style: { "background-color": _vm.textBackgroundColor }
    },
    [
      _c("div", { staticClass: "title-text-container row" }, [
        _c(
          "div",
          {
            staticClass: "col-md-6 col-sm-6 video-heading",
            style: { color: _vm.headerTextColor }
          },
          [_vm._v(_vm._s(_vm.videoLibraryData.headerText))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-md-6 col-sm-6 video-paragraph",
            style: { color: _vm.bodyTextColor, "text-align": _vm.textAlignment }
          },
          [_vm._v(_vm._s(_vm.videoLibraryData.bodyText))]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "video-library-carousel" },
        [
          _vm.videoLibraryData.videoComponents
            ? _c("lazy-component", { on: { show: _vm.onLazyLoad } })
            : _vm._e(),
          _vm._v(" "),
          _c("vx-video-player", { ref: "videoPlayer" }),
          _vm._v(" "),
          _c(
            "swiper",
            {
              ref: "swiperTop",
              staticClass: "swiper gallery-top",
              attrs: { options: _vm.swiperOptionTop }
            },
            [
              _vm._l(_vm.videoLibraryData.videoComponents, function(
                slide,
                index
              ) {
                return _c(
                  "swiper-slide",
                  { key: index, staticClass: "swipe-slide-top" },
                  [
                    _c(
                      "div",
                      { staticClass: "slide", class: "slide" + index },
                      [
                        _c("div", {
                          staticClass: "play-icon",
                          on: {
                            click: function($event) {
                              _vm.playVideo(slide.videoSrc)
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "headerText" + index,
                        refInFor: true,
                        staticClass: "gallery-top-text"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(slide.headerText) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-pagination",
                attrs: { slot: "pagination" },
                slot: "pagination"
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "thumb-container" },
            [
              _c(
                "div",
                {
                  staticClass: "pointer-previous",
                  class: { "swiper-button-disabled": _vm.firstSlide },
                  attrs: { tabindex: "0" },
                  on: {
                    click: function($event) {
                      _vm.slidePrev()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.generateIconUrl("prev"),
                      alt: "",
                      srcset: ""
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "swiper",
                {
                  ref: "swiperThumbs",
                  staticClass: "swiper gallery-thumbs",
                  attrs: { options: _vm.swiperOptionThumbs }
                },
                _vm._l(_vm.videoLibraryData.videoComponents, function(
                  slide,
                  index
                ) {
                  return _c("swiper-slide", { key: index }, [
                    _c("div", {
                      staticClass: "thumb-image",
                      class: "thumbslide" + index
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "thumbText" + index,
                        refInFor: true,
                        staticClass: "thumb-text"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(slide.headerText) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                })
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pointer-next",
                  class: { "swiper-button-disabled": _vm.lastSlide },
                  attrs: { tabindex: "0" },
                  on: {
                    click: function($event) {
                      _vm.slideNext()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.generateIconUrl("next"),
                      alt: "",
                      srcset: ""
                    }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6934ea30", { render: render, staticRenderFns: staticRenderFns })
  }
}