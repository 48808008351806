/**
 * registration-login-service is a common service
 *  file for registration and login services.
 */
import RootService from './root-service';
import globals from '../globals';

class RegistrationLoginService extends RootService {
  /**
  * getSurveydetails is used to retrive the surveyDetails.
  * @param {object} requestConfig
  * @param {function} successCallback
  * @param {function} errorCallback
  */
  getSurveyDetails(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('surveyDetails');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * postCompanyDetails is used to post the company details.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  postCompanyDetails(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('surveyDetails');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * getRegions is used to retrive the regions.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   * @param {string} isoCode
   */
  getRegions(requestConfig, successCallback, errorCallback, isoCode) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('regions')}/${isoCode}`;
    this.get(config, successCallback, errorCallback);
  }

  /**
   * registerUser is used to post User details.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  registerUser(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('register');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * forgotPassword is a service which
   *  posts the details to retrive the password link.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  forgotPassword(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('forgotPassword');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * updatePassword is a service to update the password.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  updatePassword(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('updatePassword');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * getKochAuthToken is a service used to post details to koch.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   * @param {string} kochCode
   */
  getKochAuthToken(requestConfig, successCallback, errorCallback, kochCode) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('kochAuthToken')}/${kochCode}`;
    this.post(config, successCallback, errorCallback);
  }
}
export default RegistrationLoginService;
