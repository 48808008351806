import globals from '../../../../components/common/globals';
import { eventBus, globalEventBus } from '../../../../modules/event-bus';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-text-tile',
  components: {},
  mixins: [mobileMixin],
  props: {
    textTileData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      globals,
      fontStyleHeader: {
        color: this.textTileData.headerColor,
      },
      fontStyleText: {
        color: this.textTileData.textColor,
      },
      fontStyleCta: {
        color: this.textTileData.ctaColor,
      },
      alignmentClass: {
        'align-left': this.textTileData.textAlignment === 'left',
        'align-center': this.textTileData.textAlignment === 'center',
      },
      backgroundImg: '',
    };
  },
  computed: {
    backgroundStyle() {
      return !this.backgroundImg
        ? {
          'background-color': this.textTileData.backgroundColor,
        }
        : {
          'background-image': `url(${this.backgroundImg})`,
        };
    },
    textWidth() {
      return this.textTileData.imageWidth
        ? {
          width: `${100 - parseInt(this.textTileData.imageWidth, 10)}%`,
        }
        : {
          width: '',
        };
    },
    backgroundAlt() {
      return this.getResponsiveAlt(this.textTileData.backgroundImageAltTextD, this.textTileData.backgroundImageAltTextT, this.textTileData.backgroundImageAltTextM);
    },
  },
  mounted() {
    this.backgroundImg = this.getResponsiveImage(this.textTileData.backgroundImageD, this.textTileData.backgroundImageT, this.textTileData.backgroundImageM);
    if (this.textTileData.ctaColor) {
      this.$refs.button.style.color = this.textTileData.ctaColor;
      this.$refs.button.style.backgroundColor = 'transparent';
      this.$refs.button.style.border = `3px solid ${this.textTileData.ctaColor}`;
    }
  },
  methods: {
    /**
         * Function to open video in the modal by sending the video source.
         * @param {String} src
         */
    openVideo(src) {
      eventBus.$emit('open-player', src);
    },
    /**
         * Function to emit scroll signal to scroll the page to particular position by sending the hash id.
         * @param {String} link
         */
    emitScrollSignal(link) {
      globalEventBus.$emit('scroll-to', link);
    },
  },
};
