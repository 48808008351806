var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-order-summary-details" },
    [
      _c(
        "vx-modal",
        {
          ref: "installableMoreInfo",
          attrs: { size: "large", heading: _vm.i18n.installableModalHeading }
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("div", { staticClass: "pb-xs-4" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.i18n.installationServicesInfo) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary close-button",
                  on: {
                    click: function($event) {
                      _vm.$refs.installableMoreInfo.close()
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.i18n.promotionsClose) + "\n                "
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.page === _vm.pageType.cart
        ? _c("span", {
            staticClass: "mobile-pull-up-arrow",
            class: {
              "icon-chevron-up": !_vm.orderSummaryClicked,
              "icon-chevron-down": _vm.orderSummaryClicked
            },
            attrs: {
              tabindex: "0",
              role: "button",
              "aria-label": _vm.orderSummaryClicked
                ? "close order summary"
                : "open order summary"
            },
            on: {
              keyup: function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k(
                    $event.keyCode,
                    "enter-space",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                _vm.openOrderSummary($event)
              },
              click: function($event) {
                _vm.openOrderSummary($event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.page === _vm.pageType.orderConfirmation &&
      (_vm.globals.getIsSubscription() || _vm.orderSummaryData.isSubscription)
        ? [
            _c(
              "h2",
              { staticClass: "mt-xs-0 mb-xs-0 mb-sm-4 order-summary-heading" },
              [_vm._v(_vm._s(_vm.i18n.subscriptionSummaryHeading))]
            )
          ]
        : [
            _c(
              "h2",
              { staticClass: "mt-xs-0 mb-xs-0 mb-sm-4 order-summary-heading" },
              [_vm._v(_vm._s(_vm.i18n.orderSummaryHeading))]
            )
          ],
      _vm._v(" "),
      _vm.page === _vm.pageType.cart &&
      !_vm.orderSummaryClicked &&
      _vm.isTablet()
        ? _c("div", [
            _c("div", { staticClass: "sub-total" }, [
              _c("span", { staticClass: "cart-calculation sub-total-label" }, [
                _vm._v(_vm._s(_vm.i18n.totalPrice))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "cart-calculation sub-total-value" }, [
                _vm._v(_vm._s(_vm.orderSummaryData.totalPrice.formattedValue))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pb-xs-2 sub-total-helper-text" }, [
                _vm._v(_vm._s(_vm.i18n.orderHelpingText))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section-divider" })
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.orderSummaryClicked &&
        _vm.isTablet() &&
        _vm.page === _vm.pageType.cart) ||
      !_vm.isTablet() ||
      _vm.page === _vm.pageType.checkout ||
      _vm.page === _vm.pageType.orderConfirmation ||
      _vm.page === _vm.pageType.orderDetails ||
      _vm.page === _vm.pageType.orderApprovalDetails
        ? _c(
            "div",
            [
              _c("div", { staticClass: "my-xs-4 cart-total" }, [
                _c(
                  "span",
                  { staticClass: "cart-calculation cart-total-label" },
                  [_vm._v(" " + _vm._s(_vm.i18n.cartProductTotal) + " ")]
                ),
                _vm._v(" "),
                _vm.page !== _vm.pageType.cart &&
                _vm.page !== _vm.pageType.orderDetails &&
                _vm.page !== _vm.pageType.orderConfirmation
                  ? _c(
                      "span",
                      { staticClass: "cart-item-number-label ml-xs-2" },
                      [
                        _vm._v(
                          "(" +
                            _vm._s(_vm.orderSummaryData.totalItems) +
                            "\n      " +
                            _vm._s(
                              _vm.orderSummaryData.totalItems > 1
                                ? _vm.i18n.itemLabel
                                : _vm.i18n.itemLabelSingle
                            ) +
                            ")\n    "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.page === _vm.pageType.orderDetails ||
                _vm.page === _vm.pageType.orderConfirmation
                  ? _c(
                      "span",
                      { staticClass: "cart-item-number-label ml-xs-2" },
                      [
                        _vm._v(
                          "(" +
                            _vm._s(_vm.orderSummaryData.totalProductQty) +
                            "\n      " +
                            _vm._s(
                              _vm.orderSummaryData.totalProductQty > 1
                                ? _vm.i18n.itemLabel
                                : _vm.i18n.itemLabelSingle
                            ) +
                            ")\n    "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "cart-calculation cart-total-value" },
                  [_vm._v(_vm._s(_vm.orderSummaryData.subTotal.formattedValue))]
                )
              ]),
              _vm._v(" "),
              _vm.page !== _vm.pageType.cart
                ? _c(
                    "div",
                    { staticClass: "my-xs-4 cart-total" },
                    [
                      _vm.page === _vm.pageType.checkout &&
                      !_vm.orderSummaryData.deliveryCost &&
                      !_vm.isShippingMultiple
                        ? [
                            _c(
                              "span",
                              {
                                staticClass: "cart-calculation cart-total-label"
                              },
                              [_vm._v(_vm._s(_vm.i18n.shipping))]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "cart-calculation cart-total-value"
                              },
                              [_vm._v(_vm._s(_vm.i18n.defaultShippingCost))]
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderSummaryData.deliveryCost &&
                      !_vm.isShippingMultiple
                        ? [
                            _c(
                              "span",
                              {
                                staticClass: "cart-calculation cart-total-label"
                              },
                              [_vm._v(_vm._s(_vm.i18n.shipping))]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "cart-calculation cart-total-value"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderSummaryData.deliveryCost
                                      .formattedValue
                                  )
                                )
                              ]
                            )
                          ]
                        : _vm.orderSummaryData.totalDeliveryCost &&
                          _vm.isShippingMultiple
                        ? [
                            _c(
                              "span",
                              {
                                staticClass: "cart-calculation cart-total-label"
                              },
                              [_vm._v(_vm._s(_vm.i18n.shipping))]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "cart-calculation cart-total-value"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.orderSummaryData.totalDeliveryCost)
                                )
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.orderSummaryData.totalDiscounts.value
                ? _c(
                    "div",
                    {
                      staticClass: "cart-savings",
                      class:
                        _vm.orderSummaryData.priceDiscounts ||
                        _vm.orderSummaryData.promotionalDiscounts
                          ? "mb-xs-2"
                          : "mb-xs-3"
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "cart-calculation cart-savings-label" },
                        [_vm._v(_vm._s(_vm.i18n.totalDiscounts))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "cart-calculation cart-savings-value savings"
                        },
                        [
                          _vm._v(
                            "-" +
                              _vm._s(
                                _vm.orderSummaryData.totalDiscounts
                                  .formattedValue
                              )
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.orderSummaryData.priceDiscounts &&
              _vm.orderSummaryData.priceDiscounts.value
                ? _c("div", { staticClass: "mb-xs-2" }, [
                    _c("span", { staticClass: "discounts-label" }, [
                      _vm._v(_vm._s(_vm.i18n.priceDiscounts))
                    ]),
                    _vm._v(" "),
                    _vm.orderSummaryData.priceDiscounts &&
                    _vm.orderSummaryData.priceDiscounts.formattedValue
                      ? _c("span", { staticClass: "discounts-value" }, [
                          _vm._v(
                            "-" +
                              _vm._s(
                                _vm.orderSummaryData.priceDiscounts
                                  .formattedValue
                              )
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderSummaryData.promotionalDiscounts &&
              _vm.orderSummaryData.promotionalDiscounts.value
                ? _c("div", { staticClass: "mb-xs-3" }, [
                    _c("span", { staticClass: "discounts-label" }, [
                      _vm._v(_vm._s(_vm.i18n.promotionalDiscounts))
                    ]),
                    _vm._v(" "),
                    _vm.orderSummaryData.promotionalDiscounts &&
                    _vm.orderSummaryData.promotionalDiscounts.formattedValue
                      ? _c("span", { staticClass: "discounts-value" }, [
                          _vm._v(
                            "-" +
                              _vm._s(
                                _vm.orderSummaryData.promotionalDiscounts
                                  .formattedValue
                              )
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.page !== _vm.pageType.cart
                ? _c("div", { staticClass: "mb-xs-3 cart-total" }, [
                    _c(
                      "span",
                      { staticClass: "cart-calculation cart-savings-label" },
                      [_vm._v(_vm._s(_vm.i18n.tax))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "cart-calculation cart-savings-value" },
                      [
                        _vm._v(
                          _vm._s(_vm.orderSummaryData.totalTax.formattedValue)
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.page !== _vm.pageType.cart
                ? [
                    _vm.isGiftable
                      ? _c(
                          "div",
                          { staticClass: "order-helper-text pb-xs-3" },
                          [
                            _vm._v(
                              _vm._s(_vm.i18n.gift) + "\n                "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.globals.getUrlWithContextPath(
                                    _vm.globals.navigations["learnMore"]
                                  )
                                }
                              },
                              [_vm._v(_vm._s(_vm.i18n.learnMore))]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isInstallable
                      ? _c(
                          "div",
                          { staticClass: "order-helper-text pb-xs-3" },
                          [
                            _vm._v(
                              _vm._s(_vm.i18n.install) + "\n                "
                            ),
                            _c("p", [
                              _c(
                                "a",
                                { on: { click: _vm.openInstallationModal } },
                                [_vm._v(_vm._s(_vm.i18n.learnMore))]
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.page === _vm.pageType.checkout ||
              _vm.page === _vm.pageType.orderConfirmation ||
              _vm.page === _vm.pageType.orderDetails
                ? [
                    _vm.globals.getIsSubscription() ||
                    _vm.orderSummaryData.isSubscription
                      ? _c(
                          "div",
                          { staticClass: "order-helper-text pb-xs-3" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.i18n.subscribeHelpText) +
                                "\n                "
                            ),
                            _c("br"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.globals.getUrlWithContextPath(
                                    _vm.globals.navigations["learnMore"]
                                  )
                                }
                              },
                              [_vm._v(_vm._s(_vm.i18n.learnMore))]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "section-divider" }),
              _vm._v(" "),
              _c("div", { staticClass: "mb-xs-3 sub-total" }, [
                _c(
                  "span",
                  { staticClass: "cart-calculation pt-xs-4 sub-total-label" },
                  [_vm._v(_vm._s(_vm.i18n.totalPrice))]
                ),
                _vm._v(" "),
                _vm.page !== _vm.pageType.cart
                  ? _c(
                      "span",
                      {
                        staticClass: "cart-calculation pt-xs-3 sub-total-value"
                      },
                      [
                        !_vm.globals.isEStores() ||
                        (_vm.globals.isEStores() &&
                          _vm.orderSummaryData.totalPriceWithTax
                            .formattedValue !== "$0.00")
                          ? _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.orderSummaryData.totalPriceWithTax
                                      .formattedValue
                                  ) +
                                  "\n        "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.globals.isEStores() &&
                        _vm.orderSummaryData.totalPriceWithTax
                          .formattedValue === "$0.00"
                          ? _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.i18n.noCharge) +
                                  "\n        "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _c(
                      "span",
                      {
                        staticClass: "cart-calculation pt-xs-3 sub-total-value"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderSummaryData
                              .totalPriceWithoutShippingWithoutTax
                          )
                        )
                      ]
                    ),
                _vm._v(" "),
                _vm.page === _vm.pageType.cart
                  ? _c("div", { staticClass: "sub-total-helper-text" }, [
                      _vm._v(_vm._s(_vm.i18n.orderHelpingText))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.page === _vm.pageType.cart
                ? [
                    _vm.orderSummaryData.potentialOrderPromotions &&
                    _vm.orderSummaryData.potentialOrderPromotions.length !== 0
                      ? _c(
                          "div",
                          { staticClass: "my-xs-4 shipping-progress-wrapper" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pb-xs-2 shipping-progress-helper-text"
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.orderSummaryData
                                        .potentialOrderPromotions[0].description
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "progress shipping-progress" },
                              [
                                _c("div", {
                                  staticClass: "progress-bar",
                                  style: _vm.getProgressbarStyle(
                                    _vm.orderSummaryData
                                  ),
                                  attrs: { role: "progressbar" }
                                })
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1afd12e0", { render: render, staticRenderFns: staticRenderFns })
  }
}