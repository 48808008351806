/**
 * vx-banner-carousel is the container for hero banner.
 *
 */
import vxHeroBanner from '../vx-hero-banner/vx-hero-banner.vue';
import vxHeroBannerResponsive from '../../../../components/view-site-content/vx-hero-banner-responsive/vx-hero-banner-responsive.vue';
import { eventBus } from '../../../../modules/event-bus';
import viewSiteContent from '../../../../locale/view-site-content-i18n';

export default {
  name: 'vx-banner-carousel',
  components: {
    vxHeroBanner,
    vxHeroBannerResponsive,
  },
  props: {
    carouselData: Object,
  },
  data() {
    return {
      carouselId: this.carouselData.componentId, // to generate the random string which serves as unique id
      i18n: viewSiteContent.bannerCarousel,
    };
  },
  computed: {},
  mounted() {
    /* eslint no-undef: "error" */
    const carousel = document.querySelector(`#${this.carouselId}`);
    if (carousel.querySelector('.left')) {
      carousel.querySelector('.left').style.display = 'none';
    }
    carousel.addEventListener('slid.bs.carousel', () => this.checkitem());
    if (document.querySelector(`#${this.carouselId}`).querySelector('.left') !== null) {
      eventBus.$on('carouselBinded', () => {
        setTimeout(() => {
          document.querySelector(`#${this.carouselId}`).querySelector('.left').style.display = 'none';
        }, 100);
      });
    }
  },
  methods: {
    /**
         * used to check if the slide is first or last,
         *  based on which we will disable the navigation arrows of left and right
         */
    checkitem() {
      this.$emit('slide-changed', {});
      const carousel = document.querySelector(`#${this.carouselId}`);
      const slides = carousel.querySelectorAll('.carousel-inner .item');
      if (slides[0].classList.contains('active')) {
        carousel.querySelector('.left').style.display = 'none';
        carousel.querySelector('.right').style.display = 'initial';
        if (this.$el.querySelector('.item.active .selected-product-img')) {
          this.$el.querySelector('.item.active .selected-product-img').focus();
        }
      } else if (slides[slides.length - 1].classList.contains('active')) {
        carousel.querySelector('.left').style.display = 'initial';
        carousel.querySelector('.right').style.display = 'none';
        if (this.$el.querySelector('.item.active .selected-product-img')) {
          this.$el.querySelector('.item.active .selected-product-img').focus();
        }
      } else {
        carousel.querySelectorAll('.carousel-control').forEach((control) => {
          // eslint-disable-next-line no-param-reassign
          control.style.display = 'initial';
        });
      }
    },
    getIntervalTime() {
      return this.carouselData.autoStart ? this.carouselData.autoStart : false;
    },
  },
};
