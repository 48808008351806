var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-add-custom-attribute" }, [
    _c(
      "form",
      {
        ref: "addCustomAttributeForm",
        attrs: { id: "add-custom-attribute" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitForm($event)
          }
        }
      },
      [
        _c("div", { staticClass: "attribute-fields" }, [
          _c("div", { staticClass: "section row" }, [
            _c("div", { staticClass: "attribute-1 col-xs-12 form-group" }, [
              _c(
                "label",
                {
                  attrs: {
                    for: "attribute1",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.attribute1))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.attributeInfo.attributeOne,
                    expression: "attributeInfo.attributeOne"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  name: "attribute1",
                  maxlength: "20",
                  "aria-label": _vm.i18n.attribute1,
                  placeholder: _vm.i18n.attributePlaceholder,
                  type: "text"
                },
                domProps: { value: _vm.attributeInfo.attributeOne },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.attributeInfo,
                      "attributeOne",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section row" }, [
            _c("div", { staticClass: "attribute-2 col-xs-12 form-group" }, [
              _c(
                "label",
                {
                  attrs: {
                    for: "attribute2",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.attribute2))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.attributeInfo.attributeTwo,
                    expression: "attributeInfo.attributeTwo"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  name: "attribute2",
                  "aria-label": _vm.i18n.attribute2,
                  maxlength: "20",
                  placeholder: _vm.i18n.attributePlaceholder,
                  type: "text"
                },
                domProps: { value: _vm.attributeInfo.attributeTwo },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.attributeInfo,
                      "attributeTwo",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section row" }, [
            _c("div", { staticClass: "attribute-3 col-xs-12 form-group" }, [
              _c(
                "label",
                {
                  attrs: {
                    for: "attribute3",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.attribute3))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.attributeInfo.attributeThree,
                    expression: "attributeInfo.attributeThree"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  name: "attribute3",
                  "aria-label": _vm.i18n.attribute3,
                  maxlength: "20",
                  placeholder: _vm.i18n.attributePlaceholder,
                  type: "text"
                },
                domProps: { value: _vm.attributeInfo.attributeThree },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.attributeInfo,
                      "attributeThree",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "mt-md-3 mt-sm-3 mt-xs-4 submit modal-btn-primary-small modal-bottom-spacing form-blue-button",
            attrs: {
              type: "submit",
              disabled: !_vm.isAccordionValuesChanged,
              "aria-label": _vm.i18n.save
            }
          },
          [_vm._v("\n            " + _vm._s(_vm.i18n.save) + "\n        ")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a00f7112", { render: render, staticRenderFns: staticRenderFns })
  }
}