/**
 * Product Details Tab Specs Component is the specifications which are shown in Product Details Tab on Product Detail Page
 * Gets its data from its parent component (vx-product-details-tab) as a prop
 */

export default {
  name: 'product-details-tab-specs',
  components: {},
  props: {
    // Specifications of the product
    productDetailsSpecificationData: {
      type: Object,
      required: true,
    },
    // index of the product
    indexNo: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
