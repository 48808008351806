var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-permissions-form" }, [
    _c(
      "form",
      {
        staticClass: "row",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitForm($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" },
          [
            _c(
              "label",
              {
                attrs: {
                  for: "permissionTypeDD",
                  "aria-hidden": !_vm.isTablet()
                }
              },
              [_vm._v(" " + _vm._s(_vm.i18n.permissionType) + " ")]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            !_vm.isEdit
              ? _c("vx-dropdown-primary", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  ref: "permissionTypeDropdown",
                  attrs: {
                    "describedby-aria": _vm.errors.has("permissionTypeDD")
                      ? "permission_type_DD_error_msg"
                      : null,
                    "required-aria": "true",
                    dropdownValues: _vm.permissionType,
                    name: "permissionTypeDD",
                    dropdownError: _vm.errors.has("permissionTypeDD")
                  },
                  on: {
                    "selected-option": function($event) {
                      _vm.setTimeperiodLabel($event)
                    }
                  },
                  model: {
                    value: _vm.permissionsModel.type,
                    callback: function($$v) {
                      _vm.$set(_vm.permissionsModel, "type", $$v)
                    },
                    expression: "permissionsModel.type"
                  }
                })
              : _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.permissionsModel.type) +
                      "\n      "
                  )
                ]),
            _vm._v(" "),
            _vm.errors.has("permissionTypeDD")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", {
                    staticClass: "error-icon icon-alert-triangle",
                    attrs: { title: _vm.i18n.iconAlertTitle }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: {
                        "aria-live": "assertive",
                        id: "permission_type_DD_error_msg"
                      }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("permissionTypeDD")))]
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" }, [
          _c(
            "label",
            {
              attrs: {
                for: "permissionId",
                "aria-hidden": _vm.isDeviceAndroid()
              }
            },
            [_vm._v(_vm._s(_vm.i18n.PermissionID))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.permissionsModel.permissionId,
                expression: "permissionsModel.permissionId"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("permissionId") },
            attrs: {
              type: "text",
              name: "permissionId",
              "aria-label": _vm.i18n.PermissionID
            },
            domProps: { value: _vm.permissionsModel.permissionId },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.permissionsModel,
                  "permissionId",
                  $event.target.value
                )
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("permissionId")
            ? _c("div", { staticClass: "mt-xs-2" }, [
                _c("span", {
                  staticClass: "error-icon icon-alert-triangle",
                  attrs: { title: _vm.i18n.iconAlertTitle }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.errors.first("permissionId")))
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" },
          [
            _c(
              "label",
              {
                attrs: { for: "businessUnit", "aria-hidden": !_vm.isTablet() }
              },
              [_vm._v(" " + _vm._s(_vm.i18n.businessUnit) + " ")]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("vx-dropdown-primary", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              ref: "businessUnitDropdown",
              attrs: {
                "describedby-aria": _vm.errors.has("businessUnit")
                  ? "business_unit_error_msg"
                  : null,
                "required-aria": "true",
                name: "businessUnit",
                dropdownValues: _vm.businessUnitsDropDown,
                dropdownError: _vm.errors.has("businessUnit")
              },
              on: {
                "selected-option": function($event) {
                  _vm.permissionsModel.bussinessUnit = $event
                }
              },
              model: {
                value: _vm.permissionsModel.bussinessUnit,
                callback: function($$v) {
                  _vm.$set(_vm.permissionsModel, "bussinessUnit", $$v)
                },
                expression: "permissionsModel.bussinessUnit"
              }
            }),
            _vm._v(" "),
            _vm.errors.has("businessUnit")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", {
                    staticClass: "error-icon icon-alert-triangle",
                    attrs: { title: _vm.i18n.iconAlertTitle }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: {
                        "aria-live": "assertive",
                        id: "business_unit_error_msg"
                      }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("businessUnit")))]
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.permissionsModel.type === _vm.i18n.timeSpanPermissionValue ||
        _vm.permissionsModel.code === _vm.i18n.timeSpanPermissionValue
          ? _c(
              "div",
              { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" },
              [
                _c(
                  "label",
                  {
                    attrs: { for: "timePeriod", "aria-hidden": !_vm.isTablet() }
                  },
                  [_vm._v(" " + _vm._s(_vm.i18n.timePeriod) + " ")]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("vx-dropdown-primary", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  ref: "timePeriodDropdown",
                  attrs: {
                    "describedby-aria": _vm.errors.has("timePeriod")
                      ? "time_period_error_msg"
                      : null,
                    "required-aria": "true",
                    name: "timePeriod",
                    dropdownValues: _vm.timePeriodDropdown,
                    dropdownError: _vm.errors.has("timePeriod")
                  },
                  on: {
                    "selected-option": function($event) {
                      _vm.permissionsModel.timePeriod = $event
                    }
                  },
                  model: {
                    value: _vm.permissionsModel.timePeriod,
                    callback: function($$v) {
                      _vm.$set(_vm.permissionsModel, "timePeriod", $$v)
                    },
                    expression: "permissionsModel.timePeriod"
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("timePeriod")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c("span", {
                        staticClass: "error-icon icon-alert-triangle",
                        attrs: { title: _vm.i18n.iconAlertTitle }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "assertive",
                            id: "time_period_error_msg"
                          }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("timePeriod")))]
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" }, [
          _c(
            "label",
            {
              attrs: {
                for: "thresholdAmount",
                "aria-hidden": _vm.isDeviceAndroid()
              }
            },
            [_vm._v(_vm._s(_vm.i18n.thresholdAmount))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|decimal:2",
                expression: "'required|decimal:2'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.permissionsModel.thresholdAmount,
                expression: "permissionsModel.thresholdAmount"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("thresholdAmount") },
            attrs: {
              type: "telephone",
              name: "thresholdAmount",
              "aria-label": _vm.i18n.thresholdAmount
            },
            domProps: { value: _vm.permissionsModel.thresholdAmount },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.permissionsModel,
                  "thresholdAmount",
                  $event.target.value
                )
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("thresholdAmount")
            ? _c("div", { staticClass: "mt-xs-2" }, [
                _c("span", {
                  staticClass: "error-icon icon-alert-triangle",
                  attrs: { title: _vm.i18n.iconAlertTitle }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.errors.first("thresholdAmount")))
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" },
          [
            _c(
              "label",
              {
                attrs: { for: "currencyType", "aria-hidden": !_vm.isTablet() }
              },
              [_vm._v(_vm._s(_vm.i18n.currencyType) + " ")]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("vx-dropdown-primary", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              ref: "currencyDropdown",
              attrs: {
                "describedby-aria": _vm.errors.has("currencyType")
                  ? "currency_type_error_msg"
                  : null,
                "required-aria": "true",
                name: "currencyType",
                dropdownValues: _vm.currencyList,
                dropdownError: _vm.errors.has("currencyType")
              },
              on: {
                "selected-option": function($event) {
                  _vm.permissionsModel.currencyType = $event
                }
              },
              model: {
                value: _vm.permissionsModel.currency,
                callback: function($$v) {
                  _vm.$set(_vm.permissionsModel, "currency", $$v)
                },
                expression: "permissionsModel.currency"
              }
            }),
            _vm._v(" "),
            _vm.errors.has("currencyType")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c("span", {
                    staticClass: "error-icon icon-alert-triangle",
                    attrs: { title: _vm.i18n.iconAlertTitle }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: {
                        "aria-live": "assertive",
                        id: "currency_type_error_msg"
                      }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("currencyType")))]
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.isErrorMsg !== ""
          ? _c("div", [
              _c(
                "div",
                { staticClass: "col-xs-12 alert-msg p-xs-3 mb-xs-5 mt-xs-3" },
                [_vm._v("\n        " + _vm._s(_vm.isErrorMsg) + "\n      ")]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group col-xs-12 d-flex justify-content-center" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary px-xs-5 modal-btn-primary-large",
                attrs: { type: "submit" }
              },
              [_vm._v(_vm._s(_vm.i18n.save))]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1d5468a3", { render: render, staticRenderFns: staticRenderFns })
  }
}