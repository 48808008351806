import {
  Validator,
} from 'vee-validate';
import globals from '../../../../components/common/globals';
import errorCodes from '../../../../components/common/error-codes';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxDropdownPrimary from '../../../../components/common/vx-dropdown-primary/vx-dropdown-primary.vue';
import ManageB2bOrgService from '../../../../components/common/services/manage-b2b-org-service';
import {
  userStates,
  UserRoles,
} from '../../../../components/common/mixins/vx-enums';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import detectDeviceMixin from '../../../../components/common/mixins/detect-device-mixin';
import vxUserForm from '../vx-user-form/vx-user-form.vue';
import vxTable from '../../../../components/common/vx-table/vx-table.vue';
import vxTableUtilsMixin from '../../../../components/common/mixins/vxTable-mixin';

export default {
  name: 'vx-users-landing',
  components: {
    vxDropdownPrimary,
    vxModal,
    ManageB2bOrgService,
    vxSpinner,
    vxUserForm,
    vxTable,
  },
  props: {
    i18n: {
      type: Object,
      required: true,
    },
  },
  mixins: [mobileMixin, detectDeviceMixin, vxTableUtilsMixin],
  data() {
    return {
      globals,
      errorCodes,
      UserRoles,
      userStates,
      manageB2bOrgService: '',
      tableTitles: [],
      sortOptions: this.i18n.mobileSortOptions,
      userDetails: {
        firstName: '',
        lastName: '',
        uid: '',
        businessUnit: '',
        role: 'b2bcustomergroup',
        leaseSigner: true,
      },
      users: [],
      businessUnitsList: [],
      isEmailExists: false,
      showInviteMsg: false,
      userDetailsUrl: '',
      tableConfig: {},
    };
  },
  computed: {},
  mounted() {
    this.$refs.spinner.showSpinner();
    this.manageB2bOrgService = new ManageB2bOrgService();
    this.manageB2bOrgService.getUsersData(
      {},
      this.handleUsersData,
      this.handleUsersError,
    );
    this.manageB2bOrgService.getBusinessUnits(
      {},
      this.handleBusinessUnitsData,
      this.handleBusinessUnitsError,
      '',
    );
    this.userDetailsUrl = `${this.globals.getB2BBaseURL()}${
      this.globals.navigations.userDetails
    }?uid=`;

    const veeCustomErrorMessage = {
      en: {
        custom: {
          firstName: {
            required: this.i18n.firstNameRequiredError,
            regex: this.i18n.firstNameRegexError,
            max: this.i18n.firstNameMaxError,
          },
          lastName: {
            required: this.i18n.lastNameRequiredError,
            regex: this.i18n.lastNameRegexError,
            max: this.i18n.lastNameMaxError,
          },
          email: {
            required: this.i18n.emailRequiredError,
            email: this.i18n.emailInvalidError,
          },
          businessUnit: {
            required: this.i18n.parentUnitDropdownError,
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
    this.tableTitles = this.i18n.tableHeaders.split(',').map((title) => title.trim());
    this.initializeTableConfig();
  },

  methods: {
    /**
     * Handles the user data
     * @param {Object} response contains user data
     */
    handleUsersData(response) {
      this.$refs.spinner.hideSpinner();
      const { status, data } = response;

      if (status) {
        this.users = data.users;
      }
    },
    handleUsersError() {
      this.$refs.spinner.hideSpinner();
    },

    /**
     * Handles bussiness unit response
     * @param {Object} response contains the business unit
     */
    handleBusinessUnitsData(response) {
      const { status, data } = response;

      if (status) {
        this.businessUnitsList = data.units.map(this.createBusinessUnitsList);
      }
    },
    handleBusinessUnitsError() {
    },

    createBusinessUnitsList(item) {
      return {
        label: item.name,
        value: item.id,
      };
    },
    /**
     * To open the Add User Modal.
     */
    openAddUserModal(event) {
      this.$refs.addUserModal.open(event);
    },

    resetValues() {
      this.userDetails = {
        ...this.userDetails,
        firstName: '',
        lastName: '',
        uid: '',
        businessUnit: '',
        role: 'b2bcustomergroup',
        leaseSigner: true,
      };
      this.isEmailExists = false;
    },

    showLoadingSpinner() {
      this.$refs.spinner.showSpinner();
    },
    hideLoadingSpinner() {
      this.$refs.spinner.hideSpinner();
    },
    closeUserModal() {
      this.showInviteMsg = true;
      this.$refs.addUserModal.close();
    },
    /**
     * Sets the user data
     * @param {Object} userDetails
     */
    setUsersData(userDetails) {
      if (this.globals.userInfo.b2bUnitLevel === userStates.l1) {
        const siteId = `|${globals.getSiteId()}`;
        window.location.href = `${this.userDetailsUrl}${
          userDetails.email
        }${encodeURIComponent(siteId)}`;
      } else {
        this.manageB2bOrgService.getUsersData(
          {},
          this.handleUsersData,
          this.handleUsersError,
        );
        this.$refs.addUserModal.close();
        this.resetValues();
      }
    },
    initializeTableConfig() {
      this.tableConfig = {
        rowCount: 5,
        emptyTable: this.i18n.noDataAvailable,
        columnConfiguration: [
          {
            customHeaderName: this.tableTitles[0],
            customTemplate(data) {
              let name = '';
              if (
                (userStates.pending === data.userApprovalStatus
                  || userStates.rejected === data.userApprovalStatus)
                && !data.edited
              ) {
                name = `${data.firstName}<div class="review">${userStates.underReview}</div>`;
              } else {
                name = `<a href="${globals.getB2BBaseURL()}${
                  globals.navigations.userLanding
                }?uid=${encodeURIComponent(data.uid)}">${data.firstName}</a>`;
                if (data.edited) {
                  name += `<div class="review">${userStates.underReview}</div>`;
                }
              }
              return name;
            },
          },
          {
            customHeaderName: this.tableTitles[1],
            customTemplate(data) {
              let name = '';
              if (
                (userStates.pending === data.userApprovalStatus
                  || userStates.rejected === data.userApprovalStatus)
                && !data.edited
              ) {
                name = `${data.lastName}`;
              } else {
                name = `<a href="${globals.getB2BBaseURL()}${
                  globals.navigations.userLanding
                }?uid=${encodeURIComponent(data.uid)}">${data.lastName}</a>`;
              }
              return name;
            },
          },
          {
            customHeaderName: this.tableTitles[2],
            customTemplate: (data) => {
              let role = '';
              if (data.roles[0] === this.i18n.b2badmingroup) {
                role = data.leaseSigner
                  ? this.i18n.adminWithLeaseSigner
                  : this.i18n.admin;
              } else if (data.roles[0] === this.i18n.b2bcustomergroup) {
                role = data.leaseSigner
                  ? this.i18n.buyerWithLeaseSigner
                  : this.i18n.buyer;
              }
              return role;
            },
          },
          {
            customHeaderName: this.tableTitles[3],
            customTemplate(data) {
              let parentUnits = '';
              for (let i = 0; i < data.units.length; i += 1) {
                if (i > 0) {
                  parentUnits += '<div class="mt-xs-3"></div>';
                }
                if (
                  (userStates.pending === data.userApprovalStatus
                    || userStates.rejected === data.userApprovalStatus)
                  && !data.edited
                ) {
                  parentUnits += data.units[i].name;
                } else {
                  parentUnits += `<a href="${globals.getB2BBaseURL()}${
                    globals.navigations.businessUnitsLanding
                  }?unitid=${data.units[i].id}">${data.units[i].name}</a>`;
                }
              }
              return parentUnits;
            },
          },
          {
            customHeaderName: this.tableTitles[4],
            customTemplate: (data) => {
              if (
                data.active
                && userStates.pending === data.userApprovalStatus
              ) {
                return this.i18n.pending;
              } if (data.active) {
                return this.i18n.enabled;
              }
              return this.i18n.disabled;
            },
          },
        ],
      };
    },
  },
};
