var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-page" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.msg))]),
      _vm._v(" "),
      _c("image-title", { attrs: { "img-title-data": _vm.imgTitleData } }),
      _vm._v(" "),
      _c("product-solution", {
        attrs: { "product-solution-data": _vm.productSolutionData }
      }),
      _vm._v(" "),
      _c("product-category-solution", {
        attrs: { "tabs-data": _vm.productSolutionData1 }
      }),
      _vm._v(" "),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "two-column-text" }, [
      _vm._v(
        "\n    GP PRO, a division of Georgia-Pacific, and our Dixie® brand proudly stand behind those who Serve Greatness. Working alongside the nation’s top foodservice companies, we’ve engineered one of the industry’s broadest portfolios for on- and off-premise eating needs.\n    "
      ),
      _c("br"),
      _vm._v(
        "The Dixie® collection specializes in advanced dispensing systems and foodservice disposables that emphasize waste and cost reduction, food safety and sustainability. In an industry where change is the norm, customers rely on our premium Dixie Ultra® innovations to deliver the next-generation patron experience.\n    "
      ),
      _c("br"),
      _vm._v(
        "Knowing how a clean (or dirty) restroom can affect the overall impression of an operation, our GP PRO restroom solutions are also engineered for quality, performance and savings. From back-of-house to front, dining room to restroom, customers can get all the benefits from a single source.\n  "
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-30297a69", { render: render, staticRenderFns: staticRenderFns })
  }
}