import find from 'lodash.find';
import get from 'lodash.get';
import findIndex from 'lodash.findindex';
import uniqBy from 'lodash.uniqby';
import flattenDeep from 'lodash.flattendeep';
import globals from '../../../../components/common/globals';
import vxShippingAddress from '../vx-shipping-address/vx-shipping-address.vue';
import vxShippingMethod from '../vx-shipping-method/vx-shipping-method.vue';
import vxGiftOptions from '../../../../components/checkout/vx-gift-options/vx-gift-options.vue';
import vxPaymentBilling from '../../../../components/checkout/vx-payment-billing/vx-payment-billing.vue';
import vxOverview from '../vx-overview/vx-overview.vue';
import vxScheduleInstallation from '../../../../components/checkout/vx-schedule-installation/vx-schedule-installation.vue';
import vxLeaseAgreement from '../../../../components/checkout/vx-lease-agreement/vx-lease-agreement.vue';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import {
  checkoutEventBus,
  globalEventBus,
} from '../../../../modules/event-bus';
import {
  cartTypes,
  paymentStatus,
  cookies,
  flyoutStatus,
} from '../../../../components/common/mixins/vx-enums';
import vxOrderSummary from '../../manage-shopping-cart/vx-order-summary/vx-order-summary.vue';
import vxPlaceOrder from '../../../../components/checkout/vx-place-order/vx-place-order.vue';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import CheckOutService from '../../../../components/common/services/checkout-service';
import cookiesMixin from '../../../../components/common/mixins/cookies-mixin';
import checkoutTrackingMixin from '../../../../components/common/mixins/checkout-tracking-mixin';

export default {
  name: 'vx-checkout',
  mixins: [flyoutBannerMixin, cookiesMixin, checkoutTrackingMixin],
  components: {
    vxShippingAddress,
    vxShippingMethod,
    vxGiftOptions,
    vxPaymentBilling,
    vxOverview,
    vxScheduleInstallation,
    vxLeaseAgreement,
    vxSpinner,
    vxOrderSummary,
    vxPlaceOrder,
  },
  props: {
    // Text coming from property file
    i18n: Object,
    // Determines b2b unit level of the user
    b2bUnitLevel: String,
    // Email id of the guest user
    guestEmail: {
      type: String,
      default: '',
    },
    // Merchant name for apple pay
    applePayMerchantName: String,
  },
  data() {
    return {
      globals,
      checkOutService: new CheckOutService(),
      visibleSections: [],
      cookies,
      sections: [{
        id: 'shippingAddress',
        heading: this.i18n.checkout.shippingAddressHeading,
        component: 'vx-shipping-address',
        visible: true,
        enabled: true,
      },
      {
        id: 'leaseAgreement',
        heading: this.i18n.checkout.leaseAgreementHeading,
        component: 'vx-lease-agreement',
        visible: false,
        enabled: false,
      },
      {
        id: 'scheduleinstallation',
        heading: this.i18n.checkout.scheduleInstallationHeading,
        component: 'vx-schedule-installation',
        visible: false,
        enabled: false,
      },
      {
        id: 'shippingMethod',
        heading: this.i18n.checkout.shippingMethodHeading,
        component: 'vx-shipping-method',
        visible: true,
        enabled: false,
      },
      {
        id: 'giftOptions',
        heading: this.i18n.checkout.giftOptionsHeading,
        component: 'vx-gift-options',
        visible: false,
        enabled: false,
      },
      {
        id: 'paymentBilling',
        heading: this.i18n.checkout.PaymentHeading,
        component: 'vx-payment-billing',
        visible: true,
        enabled: false,
      },
      {
        id: 'overview',
        heading: this.i18n.checkout.overviewHeading,
        component: 'vx-overview',
        visible: true,
        enabled: true,
      },
      ],
      giftableProducts: [],
      leasableProducts: [],
      installableProducts: [],
      checkoutData: {},
      visibleCheckoutEntries: [],
      dataLoaded: false,
      isShippingMultiple: false,
      cartTypes,
      userLevel: false,
      promotionData: Object,
      isShippingAddressAdded: false,
      isShippingMethodAdded: false,
      isPaymentAdded: false,
      leaseCountry: [],
      isGiftable: false,
      isInstallable: false,
      splitApplicable: false,
      addressChanged: false,
      isPaymentButtonDisabled: false,
      shippingAddress: {},
      isPromoCodeApplied: false,
      initialTrackingFlag: false,
    };
  },
  computed: {},
  mounted() {
    const self = this;
    // Check for subscription
    // First check from url
    if (this.globals.getIsSubscription()) {
      this.callSubscriptionCart();
    } else {
      this.callCart(cartTypes.FULL);
    }
    // Full cart call to update the cart data if anything is added from above sections
    checkoutEventBus.$on('update-cart', (calculationType) => {
      let calculationTypeData = calculationType;
      if (typeof (calculationType) === 'object') {
        calculationTypeData = calculationType.calculationType;
        this.isPromoCodeApplied = calculationType.isPromoCodeApplied;
      } else {
        this.isPromoCodeApplied = false;
      }
      this.refresh = calculationTypeData === 'refresh';
      if (this.globals.getIsSubscription()) {
        this.callSubscriptionCart(calculationTypeData);
      } else {
        this.callCart(cartTypes.FULL);
      }
    });
    // Handling next section when shipping method is saved
    checkoutEventBus.$on('section-complete', (sectioncompletedId) => {
      if (sectioncompletedId === 'shippingMethod' && !this.checkoutData.totalPrice.value) {
        const paymentBillingIndex = this.getSectionIndex('paymentBilling');
        this.setVisible(paymentBillingIndex, false);
      } else {
        this.enableNextVisibleSection(sectioncompletedId);
      }
    });
    // Collapse all section when change button of one section is clicked
    checkoutEventBus.$on('section-edit', (sectioncompletedId) => {
      this.disableNextVisibleSections(sectioncompletedId);
    });
    // Determines if multiple shipping is opted by the user
    checkoutEventBus.$on('shipping-multiple', () => {
      this.isShippingMultiple = true;
    });
    // Determines if single shipping is opted by the user
    checkoutEventBus.$on('shipping-single', () => {
      this.isShippingMultiple = false;
    });
    // Determines if split address should be enabled in multiple shipping address section
    checkoutEventBus.$on('enable-split', () => {
      this.splitApplicable = true;
    });
    // Determines if split address should be enabled in multiple shipping address section
    checkoutEventBus.$on('disable-split', () => {
      this.splitApplicable = false;
    });
    // Determines is user is B2B L1 user or anonymous
    if (this.globals.isB2B()) {
      this.checkB2bUserLevel();
    } else {
      this.userLevel = true;
    }
    // Determines that user is trying to change Shipping address
    checkoutEventBus.$on('shipping-address-edit', () => {
      this.isShippingAddressAdded = false;
      this.togglePlaceOrderButton();
    });
    // Determines that user has saved Shipping address
    checkoutEventBus.$on('shipping-address-added', () => {
      this.isShippingAddressAdded = true;
      this.togglePlaceOrderButton();
    });
    // Determines that user is trying to change Shipping method
    checkoutEventBus.$on('shipping-method-edit', () => {
      this.isShippingMethodAdded = false;
      this.togglePlaceOrderButton();
    });
    // Determines that user has saved Shipping method
    checkoutEventBus.$on('shipping-method-added', () => {
      this.isShippingMethodAdded = true;
      this.togglePlaceOrderButton();
      this.addressChanged = false;
    });
    // Determines that user is trying to change Payment method
    checkoutEventBus.$on('payment-method-edit', () => {
      this.isPaymentAdded = false;
      this.togglePlaceOrderButton();
    });
    // Determines that user has saved payment method
    checkoutEventBus.$on('payment-method-added', () => {
      this.sendEventToDataLayer('checkout_option', 5, 'Save and Continue');
      this.isPaymentAdded = true;
      this.togglePlaceOrderButton();
    });
    // Determines that user has selected an address in shipping address section
    checkoutEventBus.$on('use-this-address-clicked', () => {
      this.addressChanged = true;
    });
    // Determines the status of payment through paypal
    checkoutEventBus.$on('paypal-status', (data) => {
      if (data.status === paymentStatus.accept) {
        checkoutEventBus.$emit('place-order');
      } else {
        this.$refs.spinner.hideSpinner();
        /* Commented as this error message is not in scope for R2.
                  this.showFlyout(
                  paymentStatus.error,
                  `${this.i18n.paypal.paymentError}`,
                  false,
                ); */
      }
    });
    // Determines the status of payment through apple pay
    checkoutEventBus.$on('apple-pay-status', (data) => {
      if (data) {
        checkoutEventBus.$emit('place-order');
      } else {
        this.showFlyout(
          flyoutStatus.error,
          this.i18n.paymentBilling.applePayFailureError,
          true,
        );
      }
    });
    // Determines the status of payment through google pay
    checkoutEventBus.$on('google-pay-status', (data) => {
      if (data) {
        checkoutEventBus.$emit('place-order');
      } else {
        this.showFlyout(flyoutStatus.error, this.i18n.paymentBilling.googlePayFailureError, false);
        checkoutEventBus.$emit('payment-method-default');
      }
    });
    // Determines that google pay is not working
    checkoutEventBus.$on('google-pay-payment-sheet', () => {
      if (!this.checkoutData.isSubscription) {
        this.showFlyout(flyoutStatus.error, this.i18n.paymentBilling.googlePaymentSheetFailureError, false);
      }
    });
    // Storing apple pay merchant name.
    if (this.applePayMerchantName) {
      this.globals.setStorage('applePayMerchantName', this.applePayMerchantName);
    }
    // Check shipping Restriction
    this.checkShippingRestriction();
    // Send a new 'action:checkout' to reCAPTCHA
    if (self.globals.siteConfig.isReCaptchaEnabled && window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(self.globals.reCaptchaSiteKey, { action: 'checkout' });
      });
    }
  },
  methods: {
    /**
         * This function sets the visibility of a section on checkout page
         * @param  {Number} index index of the respective section
         * @param  {Boolean} value boolean value to set the section visible
         */
    setVisible(index, value) {
      this.sections[index].visible = value;
    },
    /**
         * This function disables the next visisble section
         * @param  {String} currentSectionId id of the current section
         */
    disableNextVisibleSections(currentSectionId) {
      const index = this.getVisibleSectionIndex(currentSectionId);
      this.visibleSections.forEach((el, i) => {
        if (i > index && i < this.visibleSections.length - 1) {
          this.$set(el, 'enabled', false);
        }
      });
    },
    /**
         * This function enables the next visisble section
         * @param  {String} sectioncompletedId id of the completed section
         */
    enableNextVisibleSection(sectioncompletedId) {
      const completedSectionIndex = this.getVisibleSectionIndex(
        sectioncompletedId,
      );
      this.visibleSections[completedSectionIndex + 1].enabled = true;
    },
    /**
         * This function checks if the product has gift/lease/install
         * @param  {Array} array array of products
         */
    checkAdditionalAttributes(array) {
      this.visibleCheckoutEntries = array.filter((item) => item.visible);
      if (this.visibleCheckoutEntries.length > 0) {
        if (this.globals.isB2B()) {
          // check if the product is leased
          this.checkLease(this.visibleCheckoutEntries);
        }
        const additionalAttributesArray = this.visibleCheckoutEntries.filter(
          (item) => item.additionalAttributes,
        );
        if (additionalAttributesArray.length > 0) {
          if (this.globals.isB2B()) {
            // check if the product is already opted for installation
            this.checkInstallation(additionalAttributesArray);
          }
          // check if the product is already opted for gifting
          if (
            this.globals.isB2C()
                        && this.globals.siteConfig.isGiftingEnabled
          ) {
            this.checkGiftable(additionalAttributesArray);
          }
        }
      }
    },
    /**
         * This function checks if we need to show Gift Option section
         * @param  {Array} array array of products
         */
    checkGiftable(array) {
      this.giftableProducts = array.filter((item) => find(item.additionalAttributes.entry, {
        key: 'giftOpted',
        value: 'true',
      }));
      if (this.giftableProducts.length > 0) {
        const giftableIndex = this.getSectionIndex('giftOptions');
        this.setVisible(giftableIndex, true);
        this.isGiftable = true;
      }
    },
    /**
         * This function checks if we need to show Schedule Installation section
         * @param  {Array} array array of products
         */
    checkInstallation(array) {
      this.installableProduct = array.filter((item) => find(item.additionalAttributes.entry, {
        key: 'installed',
        value: 'true',
      }));
      if (this.installableProduct.length > 0) {
        const installableIndex = this.getSectionIndex('scheduleinstallation');
        this.setVisible(installableIndex, true);
        this.isInstallable = true;
      }
    },
    /**
         * This function checks if we need to show Lease Agreement section
         * @param  {Array} array array of products
         */
    checkLease(array) {
      this.leasableProducts = [];
      this.leasableProducts = array.filter((item) => item.leasable);
      if (
        this.checkoutData.deliveryOrderGroups
                && this.checkoutData.deliveryOrderGroups[0]
                && this.checkoutData.deliveryOrderGroups[0].entries
                && this.checkoutData.deliveryOrderGroups[0].entries[0]
                && this.checkoutData.deliveryOrderGroups[0].entries[0].splitEntries
                && this.checkoutData.deliveryOrderGroups[0].entries[0].splitEntries[0]
      ) {
        this.leaseCountry = [];
        const leaseCountries = this.checkoutData.deliveryOrderGroups[0].entries;
        this.leasableQtyItems = [];
        this.leaseCountry = leaseCountries.map((item) => {
          if (item.splitEntries.length > 0) {
            return item.splitEntries.map((entry) => {
              const leasableQtyItem = {};
              if (item.leasable && get(entry, ['deliveryAddress', 'country', 'isocode'])) {
                leasableQtyItem[
                  entry.deliveryAddress.country.isocode
                ] = parseInt(entry.qty, 10);
                this.leasableQtyItems.push(leasableQtyItem);
              }
              if (get(entry, ['deliveryAddress', 'country'])) {
                return entry.deliveryAddress.country;
              }
              this.showFlyout(
                'error',
                'Error: Please re-enter your shipping address',
                false,
              ); this.disableNextVisibleSections('shippingAddress');
              return undefined;
            });
          }
          return undefined;
        });
        const leaseFlatenCountry = flattenDeep(this.leaseCountry);
        this.leaseCountry = uniqBy(leaseFlatenCountry, 'isocode');
      }
      if (this.leasableProducts.length > 0) {
        const leaseableIndex = this.getSectionIndex('leaseAgreement');
        this.setVisible(leaseableIndex, true);
      }
    },
    /**
         * This function does subscription cart call
         * @param  {String} calculationType calculation type of the subscription cart call
         */
    callSubscriptionCart(calculationType) {
      const subscriptionCartId = this.globals.getCookie(cookies.subscrCartId);
      this.checkOutService.getSubscriptionCart(
        {},
        this.handleCartResponse,
        this.handleCartError,
        subscriptionCartId,
        calculationType,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
         * This function does cart call
         */
    callCart() {
      this.checkOutService.fullCart(
        {},
        this.handleCartResponse,
        this.handleCartError,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
         * This function handles the response of cart call and subscription cart call
         */
    handleCartResponse(response) {
      // refresh the delivery modes if prmotions are added/removed
      checkoutEventBus.$emit('refresh-delivery-modes', response);
      this.$refs.spinner.hideSpinner();
      if (response && response.data) {
        const deliveryOrderGroupData = response.data.deliveryOrderGroups;
        if (this.isPromoCodeApplied && deliveryOrderGroupData && deliveryOrderGroupData.length && deliveryOrderGroupData[0].entries.length) {
          const isGiveAwayPresent = deliveryOrderGroupData[0].entries.filter((item) => item.giveAway === true);
          if (isGiveAwayPresent.length) {
            globalEventBus.$emit('enable-shipping-address', true);
          }
        }
        // Checks if free product has been added through promotion in the checkout page
        if (response.data.entries && this.checkoutData.entries && this.checkoutData.entries.length !== response.data.entries.length) {
          checkoutEventBus.$emit('cart-entries-count-updated');
        }
        this.checkoutData = response.data;
        if (this.checkoutData.entries[0].splitEntries[0] && this.checkoutData.entries[0].splitEntries[0].deliveryAddress) {
          this.shippingAddress = this.checkoutData.entries[0].splitEntries[0].deliveryAddress;
        }
        if (!this.initialTrackingFlag) {
          this.sendEventToDataLayer('checkout', 1, 'Checkout Initialized', this.checkoutData.entries);
          this.initialTrackingFlag = true;
        }
        // Disabling change button when 100% discount promotion is applied after choosing payment
        const paymentBillingIndex = this.getSectionIndex('paymentBilling');
        if (this.isPaymentAdded && !this.checkoutData.totalPrice.value) {
          this.setVisible(paymentBillingIndex, true);
          this.isPaymentButtonDisabled = true;
        } else if (!this.checkoutData.totalPrice.value) {
          this.setVisible(paymentBillingIndex, false);
          this.togglePlaceOrderButton();
        } else {
          this.setVisible(paymentBillingIndex, true);
          this.isPaymentButtonDisabled = false;
          if (this.isShippingMethodAdded) {
            this.visibleSections.push(this.sections[paymentBillingIndex]);
            // this.visibleSections[paymentVisibleIndex].enabled = true;
          }
          this.togglePlaceOrderButton();
        }
        // Checks if the product has gift/lease/install
        this.checkAdditionalAttributes(this.checkoutData.entries);
        // Stores what all sections will be visisble on the checkout page
        this.visibleSections = this.getVisibleSections(this.sections);
        // Stores all promotion aplplied
        this.promotionData = this.getProductPromotions();
        this.dataLoaded = true;
        // Checks if Lease Agreement section should be repeated for Multiple Countries
        if (this.isShippingAddressAdded) {
          checkoutEventBus.$emit('update-lease-products', this.checkoutData);
        }
      }
      if (this.refresh) {
        this.refresh = false;
        if (this.globals.getIsSubscription()) {
          this.callSubscriptionCart();
        } else {
          this.callCart(cartTypes.FULL);
        }
      }
      // Payment through paypal
      const queryParam = window.location.search.substr(1)
        .split('=');
      if (queryParam[0] === paymentStatus.paymentDecision) {
        if (queryParam[1] === paymentStatus.accept) {
          checkoutEventBus.$emit('place-order');
        } else {
          this.$refs.spinner.hideSpinner();
        }
      }
    },
    /**
         * This function handles the error of cart call and subscription cart call
         */
    handleCartError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function gets all the sections that should be shown on checkout page
         * @param  {Array} array array of sections
         */
    getVisibleSections(array) {
      const visibleArray = array.filter((item) => item.visible);
      return visibleArray;
    },
    /**
         * This function gets index of the sections
         * @param  {String} sectionId id of the respective section
         */
    getSectionIndex(sectionId) {
      return findIndex(this.sections, (section) => section.id === sectionId);
    },
    /**
         * This function gets index of the sections that are present on checkout page
         * @param  {String} sectionId id of the respective section
         */
    getVisibleSectionIndex(sectionId) {
      return findIndex(
        this.visibleSections,
        (section) => section.id === sectionId,
      );
    },
    /**
         * This function determines is user is B2B L1 user or anonymous
         */
    checkB2bUserLevel() {
      if (this.b2bUnitLevel === 'L1' || this.globals.uid === 'anonymous') {
        this.userLevel = true;
      }
    },
    /**
         * This function gets the data of all the promotions appplied
         */
    getProductPromotions() {
      const promotionProductData = {};
      for (
        let i = 0; i < this.checkoutData.appliedProductPromotions.length; i += 1
      ) {
        for (
          let j = 0; j
                < this.checkoutData.appliedProductPromotions[i].consumedEntries.length; j += 1
        ) {
          const { orderEntryNumber } = this.checkoutData.appliedProductPromotions[i]
            .consumedEntries[j];
          try {
            const description = `${
              this.checkoutData.appliedProductPromotions[i].description
            }-${
              this.checkoutData.deliveryOrderGroups.entries[orderEntryNumber]
                .promotionsRevoked
            }`;
            if (
              !this.checkoutData.deliveryOrderGroups.entries[orderEntryNumber]
                .promotionsRevoked
            ) {
              promotionProductData[orderEntryNumber] = description;
            }
          } catch (error) {
            const { description } = this.checkoutData.appliedProductPromotions[i];
            promotionProductData[orderEntryNumber] = description;
          }
        }
      }
      return promotionProductData;
    },
    /**
         * This function disables/enables the Place order Button
         * Enabled if Shipping Address, Shipping Method and Payment sections have been completed
         * Enabled if Shipping Address, Shipping Method have been completed and 100% discount voucher has been applied
         */
    togglePlaceOrderButton() {
      if (
        this.isShippingAddressAdded
                && this.isShippingMethodAdded
                && this.isPaymentAdded
      ) {
        checkoutEventBus.$emit('enable-place-order');
      } else if (
        this.isShippingAddressAdded
                && this.isShippingMethodAdded
                && !this.checkoutData.totalPrice.value
      ) {
        checkoutEventBus.$emit('enable-place-order');
      } else {
        checkoutEventBus.$emit('disable-place-order');
      }
    },
    /**
         * This function checks if there are any shipping restriction
         */
    checkShippingRestriction() {
      if (
        this.checkoutData.shippingRestrictions
                && this.checkoutData.shippingRestrictions.length !== 0
      ) {
        this.showFlyout(
          'error',
          `${this.i18n.shippingAddress.flyoutRestrictionErrorPart1} ${
            this.checkoutData.shippingRestrictions[0].country.isocode
          }${this.i18n.shippingAddress.flyoutRestrictionErrorPart2}`,
          false,
        );
        checkoutEventBus.$emit('disable-place-order');
      } else {
        this.dismissFlyout();
        checkoutEventBus.$emit('enable-place-order');
      }
    },
  },
};
