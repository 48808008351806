<template>

    <vx-price-spider-render :config="config" :i18n="i18n">
      <slot></slot>
    </vx-price-spider-render>
</template>
<script>
export default {
  name: 'vx-price-spider',
  props: {
    i18n: {
      type: Object,
      required: false,
    },
    config: {
      type: Object,
      required: false,
    },
  },
  components: {
    vxPriceSpiderRender: () => import(/* webpackChunkName: "vx-price-spider" */ './vx-price-spider.vue'),
  },
};
</script>
