var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-my-account-address row" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addNewAddressModal",
          attrs: {
            size: "large",
            heading: _vm.addressModalHeading,
            "is-modal-scrollable": _vm.modalScrollable
          }
        },
        [
          _c(
            "vx-add-edit-address",
            {
              ref: "addressForm",
              attrs: {
                slot: "component",
                i18n: _vm.i18n.addAddress,
                editAddress: _vm.selectedAddress,
                isProfile: true,
                "is-bussiness-unit": _vm.globals.isB2B(),
                "submit-button-text": _vm.i18n.addAddress.submitAddress
              },
              on: { onAddressSaved: _vm.saveAddress },
              slot: "component"
            },
            [
              _c("template", { slot: "header" }, [
                _vm._v("\n        " + _vm._s("") + "\n      ")
              ])
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "deleteAddressModal",
          attrs: { size: "small", heading: "Delete Address" }
        },
        [
          _c("vx-delete-address", {
            attrs: {
              slot: "component",
              address: _vm.selectedAddress,
              i18n: _vm.i18n.deleteAddress
            },
            on: { onAddressDeleted: _vm.deletedAddress },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xs-12" },
        [
          _c("div", { staticClass: "row" }, [
            _c("h1", { staticClass: "col-xs-12 mb-xs-0 pt-xs-3 pb-xs-2" }, [
              _vm._v(_vm._s(_vm.i18n.myAccountAddress.addressHeading))
            ])
          ]),
          _vm._v(" "),
          _vm.globals.siteConfig.addAddressEnabled
            ? _c(
                "div",
                { staticClass: "row d-flex px-xs-3 address-cards-container" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-sm-6 col-xs-12 py-xs-2 py-sm-3 add-card-wrapper",
                      class: {
                        "address-contains-mobile": _vm.addresses.length > 0
                      }
                    },
                    [
                      _c(
                        "vx-profile-card",
                        {
                          attrs: {
                            "card-type": "add",
                            "is-default-height": true
                          }
                        },
                        [
                          _c("template", { slot: "details" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "javascript:void(0)",
                                  "aria-label":
                                    _vm.i18n.myAccountAddress.a11yAddAddress,
                                  role: "button"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.openAddressModal(true, "", $event)
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass: "icon-big icon-plus",
                                  attrs: {
                                    title:
                                      _vm.i18n.myAccountAddress.iconAddTitle,
                                    "aria-hidden": "true"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-xs-1 link-large link-bold"
                                  },
                                  [_vm._v("Add Address")]
                                )
                              ]
                            )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.addresses, function(address) {
                    return _vm.addresses.length > 0 && _vm.globals.isB2C()
                      ? _c(
                          "div",
                          { staticClass: "col-sm-6 col-xs-12 py-xs-2 py-sm-3" },
                          [
                            _c(
                              "vx-profile-card",
                              {
                                attrs: {
                                  "card-classes":
                                    "px-xs-1 pl-xs-1 pt-xs-3 pb-xs-2"
                                }
                              },
                              [
                                _c("template", { slot: "heading" }, [
                                  _vm._v(_vm._s(""))
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "details" }, [
                                  _c("li", { staticClass: "card-item" }, [
                                    _vm._v(
                                      _vm._s(address.firstName) +
                                        " " +
                                        _vm._s(address.lastName)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  address && address.companyName
                                    ? _c("li", { staticClass: "card-item" }, [
                                        _vm._v(_vm._s(address.companyName))
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "card-item" }, [
                                    _vm._v(_vm._s(address.line1))
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "card-item" }, [
                                    _vm._v(_vm._s(address.line2))
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "card-item" }, [
                                    _vm._v(
                                      _vm._s(address.town) +
                                        " " +
                                        _vm._s(address.region.isocodeShort) +
                                        " " +
                                        _vm._s(address.postalCode)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "card-item" }, [
                                    _vm._v(_vm._s(address.country.isocode))
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "icons" }, [
                                  _c("li", { staticClass: "card-icon" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          tabindex: "0",
                                          role: "button",
                                          "aria-label":
                                            _vm.i18n.myAccountAddress
                                              .iconEditTitle
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.openAddressModal(
                                              false,
                                              address,
                                              $event
                                            )
                                          },
                                          keyup: function($event) {
                                            if (
                                              !("button" in $event) &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter-space",
                                                undefined,
                                                $event.key,
                                                undefined
                                              )
                                            ) {
                                              return null
                                            }
                                            _vm.openAddressModal(
                                              false,
                                              address,
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "icon-default icon-default icon-edit",
                                          attrs: {
                                            title:
                                              _vm.i18n.myAccountAddress
                                                .iconEditTitle
                                          }
                                        })
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.globals.siteConfig.deleteAddressEnabled
                                    ? _c(
                                        "li",
                                        { staticClass: "card-icon mt-xs-2" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                tabindex: "0",
                                                role: "button",
                                                "aria-label":
                                                  _vm.i18n.myAccountAddress
                                                    .iconDeleteTitle
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.openDeleteModal(
                                                    address,
                                                    $event
                                                  )
                                                },
                                                keyup: function($event) {
                                                  if (
                                                    !("button" in $event) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter-space",
                                                      undefined,
                                                      $event.key,
                                                      undefined
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  _vm.openDeleteModal(
                                                    address,
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "icon-default icon-trash",
                                                attrs: {
                                                  title:
                                                    _vm.i18n.myAccountAddress
                                                      .iconDeleteTitle
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c(
                                  "template",
                                  { slot: "footer" },
                                  [
                                    address.defaultAddress
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "mb-xs-0 card-item default-shipping card-item-bold"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.i18n.myAccountAddress
                                                    .txtDefaultShippingAddress
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    address.palletShipment
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "mb-xs-0 card-item card-item-bold"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.i18n.myAccountAddress
                                                    .txtDefaultPalletShipments
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !address.shippingAddress &&
                                    !address.billingAddress
                                      ? [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s("") +
                                              "\n            "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s("") +
                                              "\n            "
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.hideUnderReviewSection
            ? [
                _vm.globals.isB2B()
                  ? _c("div", { staticClass: "row mt-xs-2 mt-sm3" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 panel-heading pt-xs-5 px-xs-0 ml-xs-3"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.i18n.myAccountAddress.txtUnderReview)
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.globals.isB2B()
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "row d-flex px-xs-3 address-cards-container"
                      },
                      [
                        _vm._l(_vm.addresses, function(address) {
                          return [
                            address.approvalStatus === "PENDINGBYADMIN" ||
                            address.approvalStatus === "PENDINGBYGP"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm-6 col-xs-12 py-xs-2 py-sm-3"
                                  },
                                  [
                                    _c(
                                      "vx-profile-card",
                                      {
                                        attrs: {
                                          "card-classes": "px-xs-2 py-xs-4",
                                          "has-icons": false,
                                          hasFooter:
                                            address.approvalStatus ===
                                              "PENDINGBYADMIN" &&
                                            _vm.isAdmin === true
                                        },
                                        on: {
                                          rejectUserAddress: function($event) {
                                            _vm.rejectUserAddress(
                                              address.id,
                                              address.unit.uid,
                                              address.userId
                                            )
                                          },
                                          approveUserAddress: function($event) {
                                            _vm.approveUserAddress(
                                              address.id,
                                              address.unit.uid,
                                              address.userId
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("template", { slot: "heading" }, [
                                          _c(
                                            "h4",
                                            { staticClass: "pb-md-2 pb-xs-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.myAccountAddress
                                                    .pendingReview
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("template", { slot: "details" }, [
                                          _c(
                                            "li",
                                            { staticClass: "card-item" },
                                            [
                                              _vm._v(
                                                _vm._s(address.firstName) +
                                                  " " +
                                                  _vm._s(address.lastName)
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          address && address.companyName
                                            ? _c(
                                                "li",
                                                { staticClass: "card-item" },
                                                [
                                                  _vm._v(
                                                    _vm._s(address.companyName)
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "li",
                                            { staticClass: "card-item" },
                                            [_vm._v(_vm._s(address.line1))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "li",
                                            { staticClass: "card-item" },
                                            [_vm._v(_vm._s(address.line2))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "li",
                                            { staticClass: "card-item" },
                                            [
                                              _vm._v(
                                                _vm._s(address.town) +
                                                  " " +
                                                  _vm._s(
                                                    address.region.isocodeShort
                                                  ) +
                                                  " " +
                                                  _vm._s(address.postalCode)
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "li",
                                            { staticClass: "card-item" },
                                            [
                                              _vm._v(
                                                _vm._s(address.country.isocode)
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          address.defaultBillingAddress
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "mb-xs-0 card-item card-item-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.i18n
                                                          .myAccountAddress
                                                          .txtDefaultBillingAddress
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          address.defaultAddress
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "mb-xs-0 card-item default-shipping card-item-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.i18n
                                                          .myAccountAddress
                                                          .txtDefaultShippingAddress
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          address.palletShipment
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "mb-xs-0 card-item card-item-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.i18n
                                                          .myAccountAddress
                                                          .txtDefaultPalletShipments
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.isB2B() && (!_vm.hideUnderReviewSection || _vm.isActive)
            ? _c("div", { staticClass: "row mt-xs-2 mt-sm3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 panel-heading pt-xs-5 px-xs-0 ml-xs-3"
                  },
                  [_vm._v(_vm._s(_vm.i18n.myAccountAddress.txtActive))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.isB2B()
            ? _c(
                "div",
                { staticClass: "row d-flex px-xs-3 address-cards-container" },
                [
                  _vm._l(_vm.addresses, function(address) {
                    return [
                      address.approvalStatus === "ACTIVE"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-sm-6 col-xs-12 py-xs-2 py-sm-3"
                            },
                            [
                              _c(
                                "vx-profile-card",
                                {
                                  attrs: {
                                    "card-classes": "px-xs-2 pt-xs-4 pb-xs-3",
                                    "is-enabled": true,
                                    "is-pallet-shipment":
                                      address.palletShipment,
                                    "is-default-billing":
                                      address.billingAddress,
                                    "is-default-shipping":
                                      address.shippingAddress,
                                    isEnabled: true
                                  },
                                  on: {
                                    disableUserAddress: function($event) {
                                      _vm.disableUserAddress(
                                        address.id,
                                        address.unit.uid,
                                        address.userId
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("template", { slot: "heading" }, [
                                    _vm._v(_vm._s(""))
                                  ]),
                                  _vm._v(" "),
                                  _c("template", { slot: "details" }, [
                                    _c("li", { staticClass: "card-item" }, [
                                      _vm._v(
                                        _vm._s(address.firstName) +
                                          " " +
                                          _vm._s(address.lastName)
                                      )
                                    ]),
                                    _vm._v(" "),
                                    address && address.companyName
                                      ? _c("li", { staticClass: "card-item" }, [
                                          _vm._v(_vm._s(address.companyName))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "card-item" }, [
                                      _vm._v(_vm._s(address.line1))
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "card-item" }, [
                                      _vm._v(_vm._s(address.line2))
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "card-item" }, [
                                      _vm._v(
                                        _vm._s(address.town) +
                                          " " +
                                          _vm._s(address.region.isocodeShort) +
                                          " " +
                                          _vm._s(address.postalCode)
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "card-item" }, [
                                      _vm._v(_vm._s(address.country.isocode))
                                    ]),
                                    _vm._v(" "),
                                    address.defaultBillingAddress
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "mb-xs-0 card-item card-item-bold"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.i18n.myAccountAddress
                                                    .txtDefaultBillingAddress
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    address.defaultAddress
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "mb-xs-0 card-item card-item-bold default-shipping"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.i18n.myAccountAddress
                                                    .txtDefaultShippingAddress
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    address.palletShipment
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "mb-xs-0 card-item card-item-bold"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.i18n.myAccountAddress
                                                    .txtDefaultPalletShipments
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _c("template", { slot: "icons" }, [
                                    _c("li", { staticClass: "card-icon" }, [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            tabindex: "0",
                                            role: "button",
                                            "aria-label":
                                              _vm.i18n.myAccountAddress
                                                .iconEditTitle
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.openAddressModal(
                                                false,
                                                address,
                                                $event
                                              )
                                            },
                                            keyup: function($event) {
                                              if (
                                                !("button" in $event) &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter-space",
                                                  undefined,
                                                  $event.key,
                                                  undefined
                                                )
                                              ) {
                                                return null
                                              }
                                              _vm.openAddressModal(
                                                false,
                                                address,
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "icon-default icon-default icon-big icon-edit",
                                            attrs: {
                                              title:
                                                _vm.i18n.myAccountAddress
                                                  .iconEditTitle
                                            }
                                          })
                                        ]
                                      )
                                    ])
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.isB2B() && (!_vm.hideUnderReviewSection || _vm.isDisabled)
            ? _c("div", { staticClass: "row mt-xs-2 mt-sm3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 panel-heading pt-xs-5 px-xs-0 ml-xs-3"
                  },
                  [_vm._v(_vm._s(_vm.i18n.myAccountAddress.txtDisabled))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.isB2B()
            ? _c(
                "div",
                { staticClass: "row d-flex px-xs-3 address-cards-container" },
                [
                  _vm._l(_vm.addresses, function(address) {
                    return [
                      address.approvalStatus === "DISABLED"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-sm-6 col-xs-12 py-xs-2 py-sm-3"
                            },
                            [
                              _c(
                                "vx-profile-card",
                                {
                                  attrs: {
                                    "card-classes": "px-xs-2 py-xs-4",
                                    "is-disabled": true,
                                    "has-icons": false,
                                    isDisabled: true
                                  },
                                  on: {
                                    enableUserAddress: function($event) {
                                      _vm.enableUserAddress(
                                        address.id,
                                        address.unit.uid,
                                        address.userId
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("template", { slot: "heading" }, [
                                    _vm._v(_vm._s(""))
                                  ]),
                                  _vm._v(" "),
                                  _c("template", { slot: "details" }, [
                                    _c("li", { staticClass: "card-item" }, [
                                      _vm._v(
                                        _vm._s(address.firstName) +
                                          " " +
                                          _vm._s(address.lastName)
                                      )
                                    ]),
                                    _vm._v(" "),
                                    address && address.companyName
                                      ? _c("li", { staticClass: "card-item" }, [
                                          _vm._v(_vm._s(address.companyName))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "card-item" }, [
                                      _vm._v(_vm._s(address.line1))
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "card-item" }, [
                                      _vm._v(_vm._s(address.line2))
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "card-item" }, [
                                      _vm._v(
                                        _vm._s(address.town) +
                                          " " +
                                          _vm._s(address.region.isocodeShort) +
                                          " " +
                                          _vm._s(address.postalCode)
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "card-item" }, [
                                      _vm._v(_vm._s(address.country.isocode))
                                    ]),
                                    _vm._v(" "),
                                    address.defaultBillingAddress
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "mb-xs-0 card-item card-item-bold"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.i18n.myAccountAddress
                                                    .txtDefaultBillingAddress
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    address.defaultAddress
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "mb-xs-0 card-item card-item-bold default-shipping"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.i18n.myAccountAddress
                                                    .txtDefaultShippingAddress
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    address.palletShipment
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "mb-xs-0 card-item card-item-bold"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.i18n.myAccountAddress
                                                    .txtDefaultPalletShipments
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _c("template", { slot: "icons" }, [
                                    _vm._v(_vm._s(""))
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7ab3097a", { render: render, staticRenderFns: staticRenderFns })
  }
}