const mobileMixin = {
  data() {
    return {
      mobileBreakPoint: 768,
      tabletBreakPoint: 1200,
    };
  },
  mounted() {

  },
  methods: {
    /**
     * Checks whether the window of the device is less than 768px
     */
    isMobile() {
      if (window.innerWidth < this.mobileBreakPoint) {
        return true;
      }
      return false;
    },
    /**
     * Checks whether the window of the device is less than 1200px
     */
    isTablet() {
      if (window.innerWidth < this.tabletBreakPoint) {
        return true;
      }
      return false;
    },
    /**
   * It returns the alt text based on the device on which user is accessing
   * @param {string} altD
   * @param {string} altT
   * @param {string} altM
   */
    getResponsiveAlt(altD, altT, altM) {
      let alt = 'Image';
      if (altD || altT || altM) {
        if (this.isMobile() && altM) {
          alt = altM;
        } else if (this.isTablet() && altT) {
          alt = altT;
        } else if (altD) {
          alt = altD;
        }
      }
      return alt;
    },
    /**
     * It returns the image based on the device on which user is accessing
     * @param {object} imageD
     * @param {object} imageT
     * @param {object} imageM
     */
    getResponsiveImage(imageD, imageT, imageM) {
      let image = '';
      if (imageD || imageT || imageM) {
        if (this.isMobile() && (imageM || imageT)) {
          image = imageM || imageT;
        } else if (this.isTablet() && (imageM || imageT)) {
          image = imageT || imageM;
        } else if (imageD) {
          image = imageD;
        }
      }
      return image;
    },
  },
};

export default mobileMixin;
