import vxProfileCard from '../../common/vx-profile-card/vx-profile-card.vue';
import vxTaxExemption from '../vx-tax-exemption/vx-tax-exemption.vue';
import vxCommunicationPreferences from '../vx-communication-preferences/vx-communication-preferences.vue';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxChangePassword from '../vx-change-password/vx-change-password.vue';
import vxEditPersonalDetails from '../vx-edit-personal-details/vx-edit-personal-details.vue';
import globals from '../../../../components/common/globals';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import PardotService from '../../../../components/common/services/pardot-service';
import {
  UserRoles,
  monthNames,
  dateSuffix,
  PreferNotToIdentify,
  singleDigitDate,
} from '../../../../components/common/mixins/vx-enums';
import ManageProfileShoppingListService from '../../../../components/common/services/manage-profile-shopping-lists-service';

export default {
  name: 'vx-profile-preferences',
  mixins: [flyoutBannerMixin],
  components: {
    'vx-profile-card': vxProfileCard,
    'vx-tax-exemption': vxTaxExemption,
    'vx-communication-preferences': vxCommunicationPreferences,
    vxModal,
    vxSpinner,
    vxChangePassword,
    vxEditPersonalDetails,
  },
  props: {
    /**
     * Labels, button and caption texts
     */
    i18n: {
      type: Object,
      required: true,
    },
    /**
     * Boolean to show Tax Exemption card
     */
    showTaxExemption: {
      type: Boolean,
      default: false,
    },
    /**
     * Boolean to check if Gender data is available for the user
     */
    isGenderEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Boolean to check if Date of birth data is available for the user
     */
    isDobEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userDetails: {},
      globals,
      appToken: '',
      taxExemptionStatus: '',
      socialAccount: '',
      userRole: '',
      monthSuffix: '',
      dateSuffix,
      dateDisplay: '',
      genderDetails: '',
      PreferNotToIdentify,
      preferNotToIdentify: 'Prefer not to identify',
      manageProfileShoppingListService: new ManageProfileShoppingListService(),
      communicationPreferencesMessage: null,
      PardotService: new PardotService(),
    };
  },
  computed: {},
  mounted() {
    this.getUserDetails();
  },
  watch: {
    userDetails: {
      handler() {
        if (this.globals.isB2B()) {
          this.getPardotProspect();
        }
      },
      deep: true,
    },
  },
  methods: {
    /**
     * This function is to show spinner
     */
    showSpinner() {
      this.$refs.profileSpinner.showSpinner();
    },
    /**
     * This function is to hide spinner
     */
    hideSpinner() {
      this.$refs.profileSpinner.hideSpinner();
    },
    /**
     * This function is to get user details
     */
    getUserDetails() {
      const requestConfig = {};
      this.manageProfileShoppingListService.getUserDetails(
        requestConfig,
        this.handleUserDetailsResponse,
        this.handleUserDetailsError,
      );
      this.$refs.profileSpinner.showSpinner();
    },
    getPardotProspect() {
      // TODO: See if business would like to display a message here instead of only hiding spinner
      this.$refs.profileSpinner.showSpinner(this.globals.pardotFailureTimeout);
      const { uid } = this.userDetails;
      this.PardotService.readProspectWithEmail(uid, this.handleCommunicationPreferences, this.handleCommunicationPreferencesError);
    },
    handleCommunicationPreferences(response) {
      this.$refs.profileSpinner.hideSpinner();
      if (response) {
        if (this.globals.isB2B()) {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(response, 'text/xml');
          const status = xmlDoc.getElementsByTagName('rsp')[0].getAttribute('stat');
          const optedOut = xmlDoc.getElementsByTagName('opted_out')[0].innerHTML === '1';
          if (status === 'ok') {
            if (optedOut) {
              // user has already opted-out
              this.communicationPreferencesMessage = this.i18n.marketingChoicesOptedOut;
            } else {
              // user has opted-in
              this.communicationPreferencesMessage = this.i18n.marketingChoices;
            }
          }
        }
      }
    },

    handleCommunicationPreferencesError(response) {
      this.$refs.profileSpinner.hideSpinner();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response, 'text/xml');
      const status = xmlDoc.getElementsByTagName('rsp')[0].getAttribute('stat');
      if (status === 'fail') {
        // user profile does not exist
        const code = xmlDoc.getElementsByTagName('err')[0].getAttribute('code');
        if (code === '4') {
          this.communicationPreferencesMessage = this.i18n.marketingChoicesFalse;
        }
      }
    },

    /**
     * This function is to get tax exemption status
     */
    getTaxExemptionStatus() {
      const requestConfig = {};
      this.manageProfileShoppingListService.getTaxExemptionStatus(
        requestConfig,
        this.handleGetTaxExemption,
        this.handleGetTaxExemptionError,
      );
      this.$refs.profileSpinner.showSpinner();
    },
    /**
     * This function is to open tax exemption modal
     */
    callTaxExemptionModal(event) {
      this.$refs.taxExemptionModal.open(event);
    },
    /**
     * This function is to open communication preferences modal
     */
    callCommunicationPreferencesModal(event) {
      this.$refs.communicationPreferencesModal.open(event);
    },
    /**
     * This function is to open update password modal
     */
    callUpdatePasswordModal(event) {
      this.$refs.updatePasswordModal.open(event);
    },
    /**
     * This function is to open edit personal details modal
     */
    callEditPersonalDetailsModal(event) {
      this.$refs.editPersonalDetailsModal.open(event);
    },
    /**
     * This function is to handle get user details response
     */
    handleUserDetailsResponse(response) {
      this.userDetails = response.data;
      if (this.userDetails.roles && this.userDetails.roles.length) {
        if (this.userDetails.roles[0] === UserRoles.ADMIN) {
          this.userRole = UserRoles.DISPLAY_ADMIN;
        } else {
          this.userRole = UserRoles.DISPLAY_BUYER;
        }
      }
      this.userDetails.uid = response.data.uid.split('|')[0];
      if (
        response.data.gender
        && response.data.gender === this.PreferNotToIdentify.otherGender
      ) {
        this.genderDetails = this.preferNotToIdentify;
      } else {
        this.genderDetails = response.data.gender;
      }
      if (response.data.dateOfBirth) {
        this.userDetails.date = response.data.dateOfBirth.split('-')[0];
        if (singleDigitDate.indexOf(this.userDetails.date) > -1) {
          this.dateDisplay = this.userDetails.date.replace(/0/g, '');
        } else {
          this.dateDisplay = this.userDetails.date;
        }
        this.userDetails.month = response.data.dateOfBirth.split('-')[1];
        this.userDetails.monthName = monthNames[parseInt(response.data.dateOfBirth.split('-')[1], 10) - 1];
        switch (this.userDetails.date) {
          case '01':
          case '21':
          case '31':
            this.monthSuffix = this.dateSuffix.first;
            break;
          case '02':
          case '22':
            this.monthSuffix = this.dateSuffix.second;
            break;
          case '03':
          case '23':
            this.monthSuffix = this.dateSuffix.third;
            break;
          default:
            this.monthSuffix = this.dateSuffix.other;
        }
      }
      if (this.globals.isB2B()) {
        this.getTaxExemptionStatus();
      } else {
        this.$refs.profileSpinner.hideSpinner();
      }
    },
    /**
     * This function is to handle get user details error
     */
    handleUserDetailsError(error) {
      if (error) {
        this.$refs.profileSpinner.hideSpinner();
      }
    },
    /**
     * This function is to handle get tax exemption status response
     */
    handleGetTaxExemption(response) {
      this.$refs.profileSpinner.hideSpinner();
      if (response.data) {
        this.taxExemptionStatus = response.data.toLowerCase().replace('_', ' ');
      }
    },
    /**
     * This function is to handle get tax exemption status error
     */
    handleGetTaxExemptionError() {
      this.$refs.profileSpinner.hideSpinner();
    },
    /**
     * This function is to handle updated tax exemption failure
     */
    updatedTaxExemptionFailure(failure) {
      this.$refs.taxExemptionModal.close();
      this.showFlyout('error', failure, true, 10000);
    },
    /**
     * This function is to handle updated tax exemption success
     */
    updatedTaxExemptionSuccess(success) {
      this.hideSpinner();
      this.$refs.taxExemptionModal.close();
      this.getTaxExemptionStatus();
      this.showFlyout('success', success, true, 10000);
    },
    /**
     * This function is to handle updated personal details success
     */
    updatedPersonalDetailSuccess(success) {
      this.$refs.editPersonalDetailsModal.close();
      this.getUserDetails();
      this.showFlyout('success', success, true, 10000);
    },
    /**
     * This function is to handle updated password success
     */
    updatePasswordSuccess(success) {
      this.$refs.updatePasswordModal.close();
      this.showFlyout('success', success, true, 10000);
    },
    /**
     * This function is to handle updated communication preferences success
     */
    updatePreferencesSuccess(success) {
      this.$refs.communicationPreferencesModal.close();
      this.showFlyout('success', success, true, 10000);
      this.getUserDetails();
    },
  },
};
