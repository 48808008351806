var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row vx-favorite-btn" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tile-icon wishlist-btn",
          attrs: {
            tabindex: "0",
            role: "button",
            "aria-label": _vm.i18n.favorites
          },
          on: {
            click: function($event) {
              _vm.toggleFavorite($event)
            },
            keyup: function($event) {
              if (
                !("button" in $event) &&
                _vm._k(
                  $event.keyCode,
                  "enter-space",
                  undefined,
                  $event.key,
                  undefined
                )
              ) {
                return null
              }
              _vm.toggleFavorite($event)
            }
          }
        },
        [
          _c(
            "button",
            {
              staticClass: "favorite",
              class: { active: _vm.isActive },
              attrs: { "aria-label": _vm.i18n.favorites }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "heart",
                  attrs: {
                    version: "1.1",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink"
                  }
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Page-1",
                        stroke: "none",
                        "stroke-width": "1",
                        fill: "none",
                        "fill-rule": "evenodd"
                      }
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "heart-outline",
                            transform: "translate(1.000000, 0.000000)",
                            stroke: "#999"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              id: "fill",
                              d:
                                "M22.3634,0.9763 C19.9054,0.9763 17.1654,1.9943 15.9764,5.2263 C15.8984,5.4373 15.5944,5.4293 15.5214,5.2163 C14.4144,1.9913 11.5714,0.9763 9.1154,0.9763 C4.4974,0.9763 0.7394,5.0363 0.7394,10.0263 C0.7394,12.3713 1.5684,14.5963 3.0754,16.2903 L15.0314,28.2673 C15.4224,28.6583 16.0564,28.6593 16.4484,28.2673 L28.1794,16.5343 C29.8234,14.8303 30.7394,12.5113 30.7394,10.0263 C30.7394,5.0363 26.9814,0.9763 22.3634,0.9763",
                              fill: "#ffffff"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5726ad09", { render: render, staticRenderFns: staticRenderFns })
  }
}