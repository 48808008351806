import vxImageTile from '../../view-site-content/vx-image-tile/vx-image-tile.vue';
import vxTextTile from '../../view-site-content/vx-text-tile/vx-text-tile.vue';

export default {
  name: 'vx-uiux-performance-text',
  components: {
    'vx-image-tile': vxImageTile,
    'vx-text-tile': vxTextTile,
  },
  props: {
    uiuxImageTileData: {
      type: Object,
    },
    uiuxTextTileData: {
      type: Object,
    },
    componentTheme: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
