import globals from '../globals';

const recaptchaMixin = {
  data() {
    return {
      globals,
    };
  },
  created() {
    if (this.globals.siteConfig.isReCaptchaEnabled && this.globals.reCaptchaSiteKey) {
      window.recaptchaOnLoad = function() {
        try {
          const gRecaptchaResponse = document.querySelectorAll('[name="g-recaptcha-response"]');
          if (gRecaptchaResponse) {
            for (let gr = 0; gr < gRecaptchaResponse.length; gr += 1) {
              gRecaptchaResponse[gr].setAttribute('aria-hidden', 'true');
              gRecaptchaResponse[gr].setAttribute('aria-label', 'do not use');
              gRecaptchaResponse[gr].setAttribute('aria-readonly', 'true');
            }
          }
        } catch (e) {
          console.error(e);
        }
      };
    }
  },
  mounted() {
    // Loading Google Recaptcha script tag and the onload handler
    if (this.globals.siteConfig.isReCaptchaEnabled && this.globals.reCaptchaSiteKey) {
      const recapthaScript = document.createElement('script');
      recapthaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${this.globals.reCaptchaSiteKey}&onload=recaptchaOnLoad`);
      const headTag = document.getElementsByTagName('HEAD');
      if (headTag && headTag.length > 0) {
        headTag[0].appendChild(recapthaScript);
      }
    }
  },
};

export default recaptchaMixin;
