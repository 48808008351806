/**
 * Products carousel
 */
import {
  swiper,
  swiperSlide,
} from 'vue-awesome-swiper';
import axios from 'axios';
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-uiux-product-carousel',
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    productUiuxCarouselData: {
      type: Object,
    },
    swiperModifierClass: {
      type: String,
      default: '',
    },
    enableSimulateTouch: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      swiper: '',
      showNavigationArrows: false,
      firstSlide: true,
      lastSlide: false,
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 0,
        freeMode: false,
        watchOverflow: true,
        loop: false,
        breakpoints: {
          767: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          1199: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1440: {
            slidesPerView: 5,
            spaceBetween: 0,
          },
        },
        simulateTouch: this.enableSimulateTouch,
      },
      viewPort: {
        mobile: 767,
        desktop: 1440,
        tablet: 1199,
      },
      config: {
        authUrl: '/authorizationserver/oauth/token?client_id=mobile_android&client_secret=secret&grant_type=client_credentials',
        urlPrefix: '',
        auth_token: '',
      },
      i18n: this.$root.messages.common.slider,
      swiperWrapperClass: '.swiper-container',
      modifiedProducts: [],
    };
  },
  computed: {},
  created() {
    if (this.swiperModifierClass) {
      this.swiperOptions.containerModifierClass = this.swiperModifierClass;
    }
  },
  mounted() {
    this.modifiedProducts = this.productUiuxCarouselData.uiUxProducts.map((product) => ({ ...product }));

    if (this.swiperModifierClass) {
      if (document.querySelector(`.${this.swiperModifierClass.concat('initialized')}`)) {
        this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'initialized');
      } else if (document.querySelector(`.${this.swiperModifierClass.concat('horizontal')}`)) {
        this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'horizontal');
      } else if (document.querySelector(`.${this.swiperModifierClass.concat('android')}`)) {
        this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'android');
      }
    }
    const self = this;
    setTimeout(() => {
      self.onInit();
      self.swiper = document.querySelector('.vx-uiux-product-carousel .swiper-container').swiper;
      self.swiper.on('reachEnd', () => {
        self.firstSlide = false;
        self.lastSlide = true;
      });
      self.swiper.on('reachBeginning', () => {
        self.firstSlide = true;
        self.lastSlide = false;
      });
    }, 100);
    window.addEventListener('resize', () => {
      self.onInit();
    });
  },
  watch: {
    productUiuxCarouselData() {
      this.onInit();
    },
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category;
    },
    /**
         * Function sets the slides as per Viewport
         */
    async onInit() {
      const self = this;
      if (self.isMobile()) {
        self.showNavigationArrows = self.productUiuxCarouselData.uiUxProducts.length > 1;
      } else if (self.isTablet()) {
        self.showNavigationArrows = self.productUiuxCarouselData.uiUxProducts.length > 3;
      } else {
        self.showNavigationArrows = self.productUiuxCarouselData.uiUxProducts.length > 5;
      }

      // Assuming self.productUiuxCarouselData.uiUxProducts is your array of objects
      self.modifiedProducts.forEach(async (product) => {
        if (!product.imageUrl) {
          console.log('Found a product with a blank imgURL:', product.name);
          product.imageUrl = await this.getBackupImg(product.code);
          console.log(product.imageUrl);
          this.$forceUpdate();
        }
      });
    },
    /*
         * Function sets the slides to previous product
         */
    slidePrev() {
      this.swiper.slidePrev();
      this.lastSlide = false;
    },
    /**
         * Function sets the slides to next product
         */
    slideNext() {
      this.swiper.slideNext();
      this.firstSlide = false;
    },

    async GetAuthToken() {
      if (!this.config.access_token) {
        try {
          const response = await axios.post(this.config.urlPrefix + this.config.authUrl);
          if (response.data && response.data.access_token) {
            this.config.access_token = response.data.access_token;
            return true; // Resolve the promise with true on successful authentication
          }
          return false; // Resolve the promise with false if access token is missing
        } catch (error) {
          console.error('Authentication error:', error);
          throw error; // Rethrow the error to propagate it
        }
      }
      return true; // If access token already exists, resolve with true
    },

    async getBackupImg(sku) {
      try {
        const tokenSuccess = await this.GetAuthToken();

        if (tokenSuccess) {
          const options = {
            headers: {
              Authorization: `bearer ${this.config.access_token}`,
              'Content-Type': 'application/json',
            },
          };
          const response = await axios.get(
            `/gpcommercewebservices/v2/gppro/products/${sku}?fields=FULL`,
            options,
          );

          if (
            response.data
            && response.data.galleryImages
            && response.data.galleryImages[2]
          ) {
            return response.data.galleryImages[2];
          }
        }
        return ''; // Return a default value if no image URL is found or token not successful
      } catch (error) {
        console.error('Error:', error);
        return ''; // Return a default value on error
      }
    },
  },
};
