var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-star-rating" }, [
    _c(
      "div",
      { staticClass: "star-rating" },
      [
        _vm._l(_vm.stars, function(star, index) {
          return _c("div", { key: index, staticClass: "star-container" }, [
            _c(
              "svg",
              {
                staticClass: "star-svg",
                style: [
                  {
                    fill:
                      "url(#gradient" +
                      star.raw +
                      index +
                      (_vm.productReviews + _vm.productRating) +
                      ")"
                  },
                  { width: _vm.style.starWidth },
                  { height: _vm.style.starHeight }
                ],
                attrs: { "stroke-width": "1.3" }
              },
              [
                _c("polygon", {
                  staticStyle: { "fill-rule": "nonzero" },
                  attrs: { points: _vm.getStarPoints }
                }),
                _vm._v(" "),
                _c(
                  "defs",
                  [
                    _c(
                      "linearGradient",
                      {
                        attrs: {
                          id:
                            "gradient" +
                            star.raw +
                            index +
                            (_vm.productReviews + _vm.productRating)
                        }
                      },
                      [
                        _c("stop", {
                          attrs: {
                            id:
                              "stop1" +
                              index +
                              (_vm.productReviews + _vm.productRating),
                            offset: star.percent,
                            "stop-opacity": "1",
                            "stop-color": _vm.getFullFillColor(star)
                          }
                        }),
                        _vm._v(" "),
                        _c("stop", {
                          attrs: {
                            id:
                              "stop2" +
                              index +
                              (_vm.productReviews + _vm.productRating),
                            offset: star.percent,
                            "stop-opacity": "0",
                            "stop-color": _vm.getFullFillColor(star)
                          }
                        }),
                        _vm._v(" "),
                        _c("stop", {
                          attrs: {
                            id:
                              "stop3" +
                              index +
                              (_vm.productReviews + _vm.productRating),
                            offset: star.percent,
                            "stop-opacity": "1",
                            "stop-color": _vm.style.emptyStarColor
                          }
                        }),
                        _vm._v(" "),
                        _c("stop", {
                          attrs: {
                            id:
                              "stop4" +
                              index +
                              (_vm.productReviews + _vm.productRating),
                            offset: "100%",
                            "stop-opacity": "1",
                            "stop-color": _vm.style.emptyStarColor
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ])
        }),
        _vm._v(" "),
        _vm.showRating
          ? _c("div", { staticClass: "review-section" }, [
              _c("span", { staticClass: "sr-only" }, [
                _vm._v(_vm._s(_vm.i18n.averageRatingLabel))
              ]),
              _vm._v("\n      " + _vm._s(_vm.productRating))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showReviews && _vm.productReviews
          ? _c("div", { staticClass: "review-section" }, [
              _c("span", { staticClass: "sr-only" }, [
                _vm._v(_vm._s(_vm.i18n.totalReviewsLabel))
              ]),
              _vm._v("(" + _vm._s(_vm.productReviews) + ")")
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7085788a", { render: render, staticRenderFns: staticRenderFns })
  }
}