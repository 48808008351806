var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.imageTileData.isVideo
    ? _c(
        "section",
        {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy:background-image",
              value: _vm.backgroundImg,
              expression: "backgroundImg",
              arg: "background-image"
            }
          ],
          staticClass: "view-site-content-vx-image-tile px-xs-0",
          style: [
            {
              width: _vm.imageTileData.imageWidth
                ? _vm.imageTileData.imageWidth + "%"
                : ""
            }
          ],
          attrs: { "aria-label": [_vm.backgroundAlt] }
        },
        [
          _vm.imageTileData.imageLink
            ? _c("a", {
                staticClass: "d-flex",
                staticStyle: { height: "100%" },
                attrs: { href: _vm.imageTileData.imageLink }
              })
            : _vm._e()
        ]
      )
    : _c(
        "section",
        {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy:background-image",
              value: _vm.backgroundImg,
              expression: "backgroundImg",
              arg: "background-image"
            }
          ],
          staticClass: "view-site-content-vx-image-tile px-xs-0",
          style: [
            {
              width: _vm.imageTileData.imageWidth
                ? _vm.imageTileData.imageWidth + "%"
                : ""
            }
          ],
          attrs: { "aria-label": [_vm.backgroundAlt] }
        },
        [
          _c("a", {
            staticClass: "icon-play",
            style: { color: _vm.imageTileData.playIconColor },
            attrs: {
              href: "javascript:void(0)",
              "aria-label": _vm.i18n.iconPlayTitle,
              title: _vm.i18n.iconPlayTitle
            },
            on: {
              keyup: function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k(
                    $event.keyCode,
                    "enter-space",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                _vm.openVideo(_vm.imageTileData.videoSrc)
              },
              click: function($event) {
                _vm.openVideo(_vm.imageTileData.videoSrc)
              }
            }
          }),
          _vm._v(" "),
          _c("vx-video-player", { ref: "videoPlayer" })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2a513133", { render: render, staticRenderFns: staticRenderFns })
  }
}