// import updatePasswordCopy from './update-password-i18n';
import { Validator } from 'vee-validate';
import globals from '../../../../components/common/globals';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import loginMixin from '../../../../components/common/mixins/login-mixin';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import RegistrationLoginService from '../../../../components/common/services/registration-login-service';
import {
  globalEventBus,
} from '../../../../modules/event-bus';
import detectDeviceMixin from '../../../../components/common/mixins/detect-device-mixin';
import vxPasswordCheckList from '../../../../components/registration-login/vx-password-checkList/vx-password-checkList.vue';

/**
 * Component for updating password
 */

export default {
  name: 'vx-update-password',
  mixins: [flyoutBannerMixin, loginMixin, detectDeviceMixin],
  components: {
    vxSpinner,
    vxPasswordCheckList,
  },
  props: {
    heading: String,
    actionUrl: String,
    i18n: Object,
  },
  data() {
    return {
      globals,
      registrationLoginService: new RegistrationLoginService(),
      // i18n: updatePasswordCopy,
      passwordUpdateText: '',
      passwordUpdateConfirmationText: '',
      showPassword: false,
      isNewPassword: false,
      userEmailId: '',
      updatePasswordServerError: '',
      passwordConfirmError: false,
    };
  },
  computed: {
    /**
     * If heading attribute is passed through props and contains any data
     * then headingData function returns props data else it returns default data from i18n
     */
    headingData() {
      return this.heading || this.i18n.updatePasswordTitle;
    },
  },
  mounted() {
    const veeCustomErrorMessage = {
      en: {
        custom: {
          Password: {
            required: this.i18n.passwordRequiredError,
            regex: this.i18n.passwordInvalidError,
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
  },
  methods: {
    /**
     * New password function will be called on click of reset password  which validates
     * password feild and sends request data in update password ajax call
     */
    newPassword() {
      if (this.checkFieldValidation() && this.checkPasswordsMatch() && this.passwordUpdateText !== '') {
        const token = this.globals.getUrlParam('token');
        const requestdata = {
          password: this.passwordUpdateText,
          token,
        };
        // $emit will emit/send requestData to parent component.
        const requestConfig = {};
        requestConfig.params = requestdata;
        this.isNewPassword = false;
        this.registrationLoginService.updatePassword(requestConfig, this.handleResponse, this.handleError);
        this.$refs.spinner.showSpinner();
      }
    },
    /**
     * Vaidates the password data if it as per the rules.
     * @return {Boolean} true on success and false on failure, sets the focus back to first field in the form on failure.
     */
    checkFieldValidation() {
      return this.$validator.validateAll()
        .then((result) => {
          if (result) {
            return true;
          }
          this.globals.setFocusByName(this.$el, this.globals.getElementName(this.errors));
          return false;
        });
    },
    /*
     * Make sure the two password strings match
     */
    checkPasswordsMatch() {
      if (this.$refs.password.value === this.$refs.passwordConfirm.value) {
        this.passwordConfirmError = false;
        return true;
      }
      this.passwordConfirmError = true;
      return false;
    },
    /**
     * Show hide password
     */
    togglePassword() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.$refs.password.type = 'text';
        this.$refs.passwordConfirm.type = 'text';
      } else {
        this.$refs.password.type = 'password';
        this.$refs.passwordConfirm.type = 'password';
      }
    },
    /**
     * Update password response handling
     * @param {Object} response contains the success data
     */
    handleResponse(response) {
      this.userEmailId = response.data.uid;
      this.showFlyoutNextPage('success', this.i18n.passwordSuccessMsg, true);
      this.submitLogin();
      // More proper is to handle this via emit signal
      this.$emit('submit');
      this.$refs.spinner.hideSpinner();
    },
    /**
     *  Update password error handling
     * @param {Object} error conatins data such as error code, message.
     */
    handleError(error) {
      this.$refs.spinner.hideSpinner();
      this.isNewPassword = true;
      if (error.response.data) {
        this.updatePasswordServerError = this.i18n[
          error.response.data.errors[0].message
        ];
        globalEventBus.$emit('announce', this.updatePasswordServerError);
      }
    },
  },
};
