/**
 * Footer component will be used to display footer on all the pages.
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-footer-section',
  components: {},
  props: {
    footerData: {
      type: Object,
      required: true,
    },
    copyrightText: {
      type: String,
    },
    isCheckoutFooter: {
      type: Boolean,
      required: true,
    },
    isHomePage: {
      type: Boolean,
      required: false,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      socialLinks: {
        facebook: '',
        pinterest: '',
        linkedin: '',
        youtube: '',
        twitter: '',
        instagram: '',
        snapchat: '',
        socialText: '',
      },
      footerColumnLength: 4,
      footerDataLength: this.footerData.columns.length,
    };
  },
  computed: {
    filteredFooterData() {
      let filteredFooterData = [];
      if (this.isCheckoutFooter) {
        filteredFooterData = this.footerData.columns;
      } else {
        filteredFooterData = this.footerData.columns.slice(0, this.footerDataLength - 2);
      }
      return filteredFooterData;
    },
  },
  mounted() {
    const footerHeight = document.getElementsByClassName('footer-section')[0]
      .offsetHeight;
    this.$root.$emit('footer-height', footerHeight);
    this.setAdaIcon();
  },
  methods: {
    /**
         * In mobile mode - Handling accordion expand and collapse.
         * Setting aria labels for ADA purpose.
         * @param {*} event
         */
    toggle(event, index) {
      if (!this.isCheckoutFooter) {
        event.currentTarget.parentElement.classList.toggle('active');
        if (this.isTablet()) {
          if (event.target.tagName === 'H2') {
            if (event.currentTarget.children[0].classList.contains('icon-chevron-down')) {
              event.currentTarget.children[0].classList.remove('icon-chevron-down');
              event.currentTarget.children[0].classList.add('icon-chevron-up');
              event.target.setAttribute('aria-expanded', true);
              event.target.setAttribute('aria-label', this.filteredFooterData[index].title);
            } else {
              event.currentTarget.children[0].classList.remove('icon-chevron-up');
              event.currentTarget.children[0].classList.add('icon-chevron-down');
              event.target.setAttribute('aria-expanded', false);
              event.target.setAttribute('aria-label', this.filteredFooterData[index].title);
            }
          } else if (event.target.tagName === 'BUTTON') {
            if (event.target.classList.contains('icon-chevron-down')) {
              event.target.classList.remove('icon-chevron-down');
              event.target.classList.add('icon-chevron-up');
              event.target.setAttribute('aria-expanded', true);
              event.target.setAttribute('aria-label', this.filteredFooterData[index].title);
            } else {
              event.target.classList.remove('icon-chevron-up');
              event.target.classList.add('icon-chevron-down');
              event.target.setAttribute('aria-expanded', false);
              event.target.setAttribute('aria-label', this.filteredFooterData[index].title);
            }
          }
        }
      }
    },
    setAdaIcon() {
      const firstElInfoColumn = document.querySelectorAll('.misc-links')[0].firstElementChild;
      const eaLinkEl = document.createElement('a');
      eaLinkEl.href = 'https://www.essentialaccessibility.com/georgia-pacific-consumer-products?utm_source=georgiapacifichomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=georgiapacificconsumerproducts';
      eaLinkEl.target = '_blank';
      eaLinkEl.className = 'misc-link pr-xs-3';
      const eaImgEl = document.createElement('img');
      eaImgEl.src = `${this.globals.assetsPath}images/eaIcon-b2b.png`;
      eaImgEl.style.height = '31px';
      eaImgEl.style.width = '80px';
      firstElInfoColumn.parentNode.insertBefore(eaLinkEl, firstElInfoColumn);
      eaLinkEl.appendChild(eaImgEl);
      eaImgEl.alt = 'eA - Assistive Technology';
    },
  },
};
