import axios from 'axios';
import { Validator } from 'vee-validate';
import detectDeviceMixin from '../../../../components/common/mixins/detect-device-mixin';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import CommonService from '../../../../components/common/services/common-service';
import vxDropdownPrimary from '../../../../components/common/vx-dropdown-primary/vx-dropdown-primary.vue';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import globals from '../../../../components/common/globals';
import RegistrationLoginService from '../../../../components/common/services/registration-login-service';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import { defaultCountry, flyoutStatus } from '../../../../components/common/mixins/vx-enums';

export default {
  name: 'vx-create-account-estores',
  mixins: [detectDeviceMixin, mobileMixin, flyoutBannerMixin],
  components: {
    vxDropdownPrimary,
    vxSpinner,
  },
  props: {
    i18n: Object,
  },
  data() {
    return {
      globals,
      commonService: new CommonService(),
      registrationLoginService: new RegistrationLoginService(),
      user: {
        profileName: '',
        companyName: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        ext: '',
        jobTitle: '',
        country: '',
        state: '',
        zipCode: '',
        address1: '',
        address2: '',
        city: '',
        termsConditionChecked: false,
      },
      countryList: {
        label: 'Country',
        options: [],
      },
      regionList: {
        label: 'State',
        options: [],
      },
      defaultCountry,
      prePopulateProfileName: null,
      enableBriteVerify: true /* false */,
      authUrl: '/authorizationserver/oauth/token?client_id=mobile_android&client_secret=secret&grant_type=client_credentials',
      urlPrefix: '',
      auth_token: '',
    };
  },
  mounted() {
    this.callCountryService();
    this.user.profileName = this.globals.getUrlParam('profileName') || '';
    this.prePopulateProfileName = this.globals.getUrlParam('profileName');
    this.submitButton = this.$el.querySelector('.estoresRegSubmitBtn');
    const veeCustomErrorMessage = {
      en: {
        custom: {
          profileName: {
            required: this.i18n.profileNameRequiredError,
            regex: this.i18n.profileNameInvalidError,
            max: this.i18n.profileNameInvalidError,
          },
          companyName: {
            required: this.i18n.companyNameRequiredError,
            max: this.i18n.companyNameMaxError,
          },
          firstName: {
            required: this.i18n.firstNameRequiredError,
          },
          lastName: {
            required: this.i18n.lastNameRequiredError,
          },
          emailAddress: {
            required: this.i18n.emailAddressRequiredError,
            email: this.i18n.emailAddressInvalidError,
          },
          phoneNumber: {
            required: this.i18n.phoneNumberRequiredError,
            min: this.i18n.phoneNumberMinEror,
          },
          jobTitle: {
            required: this.i18n.jobTitleRequiredError,
          },
          address1: {
            required: this.i18n.address1RequiredError,
          },
          city: {
            required: this.i18n.cityRequiredError,
          },
          country: {
            required: this.i18n.countryRequiredError,
          },
          state: {
            required: this.i18n.stateRequiredError,
          },
          zipCode: {
            required: this.i18n.zipCodeRequiredError,
          },
          termsCondition: {
            required: this.i18n.termsConditionRequiredError,
          },
          ext: {
            regex: this.i18n.extInvalidError,
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
  },
  methods: {
    /**
     * Calls the service for populating all the countries
     */
    callCountryService() {
      this.$refs.spinner.showSpinner();
      this.commonService.getCountries(
        {},
        this.handleGetCountriesResponse,
        this.handleGetCountriesError,
      );
      this.callRegionService(this.defaultCountry);
    },
    /**
     * Populates the country details in the Frontend and sets United States of America in the Country dropdown
     * @param {object} response
     */
    handleGetCountriesResponse(response) {
      this.$refs.spinner.hideSpinner();
      if (response && response.data) {
        this.countryData = response.data.countries.reverse();
        this.$refs.countryDropdown.resetDropdown();
        this.countryList.options = [];
        for (let i = 0; i < this.countryData.length; i += 1) {
          this.$set(this.countryList.options, i, {
            label: this.countryData[i].name,
            value: this.countryData[i].isocode,
          });
        }
      }
      this.$refs.countryDropdown.setDropdownLabel(this.defaultCountry.label);
    },
    /**
     * Hides the spinner when the service call for populating the Country fails
     */
    handleGetCountriesError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * Calls the service for populating states
     * @param {object} evt
     */
    callRegionService(evt) {
      this.user.country = evt;
      this.commonService.getRegions(
        {},
        this.handleGetRegionsResponse,
        this.handleGetRegionsError,
        evt.value,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
     * Populates the state details in the Frontend and sets the first state as selected in the dropdown
     * @param {object} response
     */
    handleGetRegionsResponse(response) {
      this.$refs.spinner.hideSpinner();
      this.regionsData = response.data.regions;
      this.$refs.stateDropdown.resetDropdown();
      this.regionList.options = [];
      for (let i = 0; i < this.regionsData.length; i += 1) {
        this.$set(this.regionList.options, i, {
          label: this.regionsData[i].name,
          value: this.regionsData[i].isocodeShort,
          countryIso: this.regionsData[i].countryIso,
          isocode: this.regionsData[i].isocode,
        });
      }
      if (this.regionList.options && this.regionList.options.length) {
        this.$refs.stateDropdown.setDropdownLabel(
          this.regionList.options[0].label,
        );
        this.user.state = this.regionList.options[0];
      }
    },
    /**
     * Hides the spinner when service call for populating the state
     */
    handleGetRegionsError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * Carries out Validations when register button is pressed and registerUser call happens when all validation pass
     * @param {object} event
     */
    submitForm(event) {
      event.preventDefault();
      this.$validator.validateAll().then((result) => {
        this.DisableSubmit();
        this.ValidateBriteVerify((success) => {
          if (success) {
            if (result) {
              const requestConfig = {};
              requestConfig.data = this.createRequestBody();
              this.registrationLoginService.registerUser(
                requestConfig,
                this.handleRegisterResponse,
                this.handleRegisterError,
              );
              this.$refs.spinner.showSpinner();
            }
          } else {
            this.showFlyoutNextPage(
              'error',
              'The value of the email field is not valid',
              false,
              true,
            );
            this.EnableSubmit();
          }
        });
      });
    },
    /**
     * Creates request body for registration
     */
    createRequestBody() {
      const reqbody = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        password: '',
        country: this.user.country.value,
        titleCode: 'mr',
        gender: '',
        dateOfBirth: '',
        uid: this.user.emailAddress,
        addToMarketComm: true,
        profileName: this.user.profileName,
        jobTitle: this.user.jobTitle,
        jobDescription: '',
        address: {
          country: {
            isocode: this.user.country.value,
            name: this.user.country.label,
          },
          firstName: this.user.firstName,
          line1: this.user.address1,
          line2: this.user.address2,
          postalCode: this.user.zipCode,
          town: this.user.city,
          region: {
            countryIso: this.user.country.value,
            isocode: `${this.user.country.value}-${this.user.state.value}`,
            isocodeShort: this.user.state.value,
            name: this.user.state.label,
          },
          lastName: this.user.lastName,
          titleCode: 'mr',
          phone: this.user.phoneNumber,
          companyName: this.user.companyName,
        },
      };
      return reqbody;
    },
    /**
     * Once the registration is complete, user will be redirected to login page and a flyout message will be shown.
     */
    handleRegisterResponse() {
      this.$refs.spinner.hideSpinner();
      this.showFlyoutNextPage(
        'success',
        this.i18n.registerSuccessMsg,
        false,
        true,
      );
      window.location.href = this.globals.getNavigationUrl('login');
    },
    /**
     * Hides the spinner when service call for registration fails
     */
    handleRegisterError(error) {
      const { errors } = error.response.data;
      const errorMsg = errors && errors[0].code === '197'
        ? this.i18n.registrationExpirationProfileErrorMsg
        : this.i18n.registrationErrorMsg;

      this.$refs.spinner.hideSpinner();
      this.showFlyout(flyoutStatus.error, errorMsg, true);
    },

    ValidateBriteVerify(callback) {
      if (typeof this.config.bvapi === 'undefined') {
        callback.call(this, true);
        return;
      } if (this.config.bvapi.length === 0) {
        callback.call(this, true);
        return;
      } if (this.emailField.value[0] === ':') {
        callback.call(this, true);
        return;
      }
      const capitalize = (tempSiteID) => {
        if (typeof tempSiteID !== 'string') return '';
        return tempSiteID.charAt(0).toUpperCase() + tempSiteID.slice(1);
      };
      const newSiteID = capitalize(this.globals.siteId);
      const request = {
        remoteName: `${newSiteID}-BV`,
        remoteUrl: `/emails.json?address=${this.emailField.value}&apikey={$remote_access_token}`,
        remoteHeaders: ['Content-Type:application/json'],
        postData: '',
      };
      this.GetAuthToken((success) => {
        if (success) {
          const options = {
            headers: {
              Authorization: `bearer ${this.config.access_token}`,
              'Content-Type': 'application/json',
            },
          };
          axios.post(
            this.API_URL,
            request,
            options,
          ).then(({ data: response }) => {
            if (
              response
              && response.status !== 'invalid'
              && response.status !== 'unknown'
              && response.status !== 'accept_all'
              && response.disposable !== 'true'
            ) {
              callback.call(this, true);
            } else {
              callback.call(this, false);
            }
          }).catch(() => {
            callback.call(this, true);
          });
        }
      });
    },
  },

  DisableSubmit() {
    if (this.submitButton) {
      this.submitButton.setAttribute('disabled', true);
      this.submitButton.setAttribute('data-enabled-value', this.submitButton.getAttribute('value'));
      this.submitButton.setAttribute('value', 'Validating Email...');
      if (this.$refs.spinner) {
        this.$refs.spinner.showSpinner();
      }
    }
  },

  EnableSubmit() {
    if (this.submitButton) {
      this.submitButton.removeAttribute('disabled');
      this.submitButton.setAttribute('value', this.submitButton.getAttribute('data-enabled-value'));
      this.submitButton.removeAttribute('data-enabled-value');
      if (this.$refs.spinner) {
        this.$refs.spinner.hideSpinner();
      }
    }
  },

};
