var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer-section" }, [
    !_vm.isCheckoutFooter
      ? _c("div", { staticClass: "footer px-sm-5 mx-xs-0 row" }, [
          _c(
            "div",
            { staticClass: "d-flex flex-wrap px-sm-0 px-xs-0" },
            _vm._l(_vm.filteredFooterData, function(item, index) {
              return _c(
                "div",
                { staticClass: "non-printable-section footer__column" },
                [
                  _vm.isMobile()
                    ? _c(
                        "h2",
                        {
                          staticClass:
                            "my-xs-4 mx-xs-3 ml-sm-0 mt-sm-0 mb-sm-3 footer__column__heading",
                          attrs: { "aria-label": item.title },
                          on: {
                            click: function($event) {
                              _vm.toggle($event, index)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.title) +
                              "\n                    "
                          ),
                          _c("button", {
                            staticClass: "icon-chevron-down visible-xs",
                            attrs: {
                              role: "button",
                              "aria-hidden": "true",
                              "aria-label": "Expand " + item.title,
                              "aria-expanded": "false"
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isMobile()
                    ? _c(
                        "h2",
                        {
                          staticClass:
                            "my-xs-4 mx-xs-3 ml-sm-0 mt-sm-0 mb-sm-3 footer__column__heading"
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.title) +
                              "\n                "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { attrs: { role: "list" } },
                    _vm._l(item.links, function(subItem, subIndex) {
                      return _c("li", { attrs: { role: "listitem" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "px-xs-3 pl-sm-0 pb-xs-3 py-sm-1 footer__column__item"
                          },
                          [
                            subItem.linkText
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: [
                                        subItem.external
                                          ? subItem.linkTo
                                          : _vm.globals.contextPath +
                                            subItem.linkTo
                                      ],
                                      target: [
                                        subItem.newWindow ? "_blank" : "_self"
                                      ]
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                          " +
                                        _vm._s(subItem.linkText) +
                                        "\n                      "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    })
                  ),
                  _vm._v(" "),
                  index === 5
                    ? [
                        _c("div", { staticClass: "social-icons-container" }, [
                          _c(
                            "ul",
                            { attrs: { role: "list" } },
                            [
                              _vm._l(_vm.footerData.columns[7].links, function(
                                socialIcons
                              ) {
                                return [
                                  _c(
                                    "li",
                                    { attrs: { role: "listitem" } },
                                    [
                                      _vm.isHomePage
                                        ? [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "social-icons pr-xs-3",
                                                attrs: {
                                                  target: "_blank",
                                                  rel: "me",
                                                  href: socialIcons.linkTo
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "my-xs-2",
                                                  attrs: {
                                                    src:
                                                      _vm.globals.assetsPath +
                                                      "images/" +
                                                      socialIcons.linkText.toLowerCase() +
                                                      ".png",
                                                    alt: socialIcons.linkText
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        : [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "social-icons pr-xs-3",
                                                attrs: {
                                                  href: socialIcons.linkTo,
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "my-xs-2",
                                                  attrs: {
                                                    src:
                                                      _vm.globals.assetsPath +
                                                      "images/" +
                                                      socialIcons.linkText.toLowerCase() +
                                                      ".png",
                                                    alt: socialIcons.linkText
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                    ],
                                    2
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "recaptchaFooterTextMargin" },
                          [
                            _vm.globals.siteConfig.isReCaptchaEnabled
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mt-xs-3 mx-xs-3 ml-xs-4 recaptchaFooterText"
                                  },
                                  [
                                    _vm._v(
                                      "\n                          This site is protected by reCAPTCHA and the Google\n                          "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "https://policies.google.com/privacy"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                              Privacy Policy\n                          "
                                        )
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                          and\n                          "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "https://policies.google.com/terms"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                              Terms of Service\n                          "
                                        )
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                          apply.\n                      "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            })
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "bottom-bar px-sm-5",
        class: { checkout: _vm.isCheckoutFooter }
      },
      [
        _c("div", { staticClass: "copy-right" }, [
          _vm._v("\n            " + _vm._s(_vm.copyrightText) + "\n        ")
        ]),
        _vm._v(" "),
        !_vm.isCheckoutFooter
          ? _c(
              "div",
              { staticClass: "misc-links" },
              [
                _vm._l(
                  _vm.footerData.columns[_vm.footerDataLength - 2].links,
                  function(socialIcons) {
                    return _c(
                      "a",
                      {
                        staticClass: "misc-link",
                        attrs: {
                          target: "_blank",
                          itemprop: "sameAs",
                          rel: "me",
                          itemscope: "",
                          href: socialIcons.linkTo
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(socialIcons.linkText) +
                            "\n            "
                        )
                      ]
                    )
                  }
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ot-sdk-show-settings",
                    attrs: {
                      role: "link",
                      "aria-label":
                        "Do Not Sell or Share My Personal Information",
                      id: "ot-sdk-btn"
                    }
                  },
                  [_vm._v("Cookies Settings")]
                )
              ],
              2
            )
          : _c(
              "div",
              { staticClass: "misc-links" },
              [
                _vm._l(_vm.footerData.columns[0].links, function(socialIcons) {
                  return _c(
                    "a",
                    {
                      staticClass: "misc-link",
                      attrs: {
                        target: "_blank",
                        itemprop: "sameAs",
                        rel: "me",
                        itemscope: "",
                        href: socialIcons.linkTo
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(socialIcons.linkText) +
                          "\n            "
                      )
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ot-sdk-show-settings",
                    attrs: {
                      role: "link",
                      "aria-label":
                        "Do Not Sell or Share My Personal Information",
                      id: "ot-sdk-btn"
                    }
                  },
                  [_vm._v("Cookies Settings")]
                )
              ],
              2
            )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f55e8d24", { render: render, staticRenderFns: staticRenderFns })
  }
}