/**
 * Infographic pop-up
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';

export default {
  name: 'vx-uiux-infographic',
  components: {
    vxModal,
  },
  props: {
    uiuxInfographicData: {
      type: Object,
    },
    componentTheme: String,
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    /**
         * Opens modal and displays our infographic  in modal
         */
    expandInfographic(event) {
      this.$refs.infographicModal.open(event);
    },
  },
};
