var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "image-title row",
      class: _vm.imgTitleData.componentTheme,
      style: _vm.backgroundStyle,
      attrs: { id: _vm.imgTitleData.componentId }
    },
    [
      _c("vx-video-player", { ref: "videoPlayer" }),
      _vm._v(" "),
      _vm.imgTitleData.componentHeader
        ? _c("div", {
            staticClass: "header my-xs-0 pb-xs-4 pt-xs-3 pt-sm-5 pb-md-5",
            style: { color: _vm.imgTitleData.componentHeaderColor },
            domProps: { innerHTML: _vm._s(_vm.imgTitleData.componentHeader) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.imgTitleData.subHeaderText
        ? _c("h4", {
            staticClass: "subheader m-xs-0",
            domProps: { innerHTML: _vm._s(_vm.imgTitleData.subHeaderText) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.imgTitleData.componentHeader || _vm.imgTitleData.subHeaderText
        ? _c("div", {
            staticClass: "title-underline",
            class: _vm.imgTitleData.subHeaderText ? "" : "mt-xs-0",
            style: _vm.imgTitleData.componentHeaderColor
              ? { "border-bottom-color": _vm.imgTitleData.componentHeaderColor }
              : ""
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-wrap justify-content-start mob image-title-container justify-content-center"
        },
        _vm._l(_vm.imgTitleData.tiles, function(tile, index) {
          return _c(
            "div",
            {
              staticClass: "px-sm-2 d-flex image-tile",
              class: {
                "first-element":
                  (index + 1) % _vm.imgTitleData.noOfColumns === 1,
                "last-element":
                  (index + 1) % _vm.imgTitleData.noOfColumns === 0,
                "feature-product-single-tile": _vm.imgTitleData.noOfColumns == 1
              },
              style: _vm.imageTileWidth
            },
            [
              _c(
                "div",
                {
                  staticClass: " mb-xs-3 w-xs-100",
                  style: { background: tile.backgroundColor }
                },
                [
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-title__wrapper feature-product",
                        class: {
                          "no-image": !(
                            tile.imgSrcD ||
                            tile.imgSrcT ||
                            tile.imgSrcM
                          )
                        }
                      },
                      [
                        tile.imageLink
                          ? _c(
                              "a",
                              {
                                staticClass: "feature-product-tile",
                                attrs: {
                                  href: tile.isExternalImage
                                    ? tile.imageLink
                                    : _vm.globals.getUrlWithContextPath(
                                        tile.imageLink
                                      ),
                                  target: tile.isExternalImage ? "_blank" : "",
                                  "aria-label": _vm.getResponsiveAlt(
                                    tile.imgSrcAltTextD,
                                    tile.imgSrcAltTextT,
                                    tile.imgSrcAltTextM
                                  )
                                }
                              },
                              [
                                _c("lazy-component", [
                                  tile.imgSrcD || tile.imgSrcT || tile.imgSrcM
                                    ? _c("picture", [
                                        _c("source", {
                                          attrs: {
                                            media: "(min-width: 1200px)",
                                            srcset: tile.imgSrcD
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("source", {
                                          attrs: {
                                            media: "(min-width: 768px)",
                                            srcset: tile.imgSrcT
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("source", {
                                          attrs: {
                                            media: "(min-width: 360px)",
                                            srcset: tile.imgSrcM
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("img", {
                                          attrs: {
                                            src: tile.imgSrcD,
                                            alt: _vm.getResponsiveAlt(
                                              tile.imgSrcAltTextD,
                                              tile.imgSrcAltTextT,
                                              tile.imgSrcAltTextM
                                            )
                                          }
                                        })
                                      ])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "bottom-block" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tile-relative custom-word-wrap-all"
                                    },
                                    [
                                      tile.secondaryImgD
                                        ? _c("img", {
                                            attrs: {
                                              src: tile.secondaryImgD,
                                              alt: tile.secondaryimgAltTextD
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      tile.header
                                        ? _c("h3", {
                                            staticClass: "tile-header mb-xs-0",
                                            class: [
                                              tile.secondaryImgD
                                                ? "mt-xs-3"
                                                : "mt-xs-0"
                                            ],
                                            style: { color: tile.headerColor },
                                            domProps: {
                                              innerHTML: _vm._s(tile.header)
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      tile.tileText
                                        ? _c("div", {
                                            staticClass: "tile-text",
                                            class: [
                                              tile.header || tile.secondaryImgD
                                                ? "mt-xs-2"
                                                : "mt-xs-0"
                                            ],
                                            style: { color: tile.textColor },
                                            domProps: {
                                              innerHTML: _vm._s(tile.tileText)
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      tile.ctaText && tile.ctaLink
                                        ? _c(
                                            "div",
                                            {
                                              class: [
                                                tile.secondaryImgD ||
                                                tile.tileText ||
                                                tile.header
                                                  ? "mt-xs-3"
                                                  : "mt-xs-0",
                                                _vm.imgTitleData.ctaStyle
                                              ]
                                            },
                                            [
                                              !tile.jumpToHtml
                                                ? _c("a", {
                                                    staticClass:
                                                      "tile-btn view-all",
                                                    style: {
                                                      color: tile.ctaColor
                                                    },
                                                    attrs: {
                                                      target: tile.isExternalLink
                                                        ? "_blank"
                                                        : "",
                                                      href: tile.isExternalLink
                                                        ? tile.ctaLink
                                                        : _vm.globals.getUrlWithContextPath(
                                                            tile.ctaLink
                                                          ),
                                                      "aria-label": tile.header
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        tile.ctaText
                                                      )
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              tile.jumpToHtml
                                                ? _c("a", {
                                                    staticClass:
                                                      "tile-btn view-all",
                                                    style: {
                                                      color: tile.ctaColor
                                                    },
                                                    attrs: {
                                                      target: tile.isExternalLink
                                                        ? "_blank"
                                                        : "",
                                                      href: tile.isExternalLink
                                                        ? tile.ctaLink
                                                        : _vm.globals.getUrlWithContextPath(
                                                            tile.ctaLink
                                                          ),
                                                      "aria-label": tile.header
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        tile.ctaText
                                                      )
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.emitScrollSignal(
                                                          tile.ctaLink
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "feature-product-tile" },
                              [
                                _c("lazy-component", [
                                  tile.imgSrcD || tile.imgSrcT || tile.imgSrcM
                                    ? _c("picture", [
                                        _c("source", {
                                          attrs: {
                                            media: "(min-width: 1200px)",
                                            srcset: tile.imgSrcD
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("source", {
                                          attrs: {
                                            media: "(min-width: 768px)",
                                            srcset: tile.imgSrcT
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("source", {
                                          attrs: {
                                            media: "(min-width: 360px)",
                                            srcset: tile.imgSrcM
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("img", {
                                          attrs: {
                                            src: tile.imgSrcD,
                                            alt: _vm.getResponsiveAlt(
                                              tile.imgSrcAltTextD,
                                              tile.imgSrcAltTextT,
                                              tile.imgSrcAltTextM
                                            )
                                          }
                                        })
                                      ])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "bottom-block" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tile-relative custom-word-wrap-all"
                                    },
                                    [
                                      _c("lazy-component", [
                                        tile.secondaryImgD
                                          ? _c("img", {
                                              attrs: {
                                                src: tile.secondaryImgD,
                                                alt: tile.secondaryimgAltTextD
                                              }
                                            })
                                          : _vm._e()
                                      ]),
                                      _vm._v(" "),
                                      tile.header
                                        ? _c("div", {
                                            staticClass: "tile-header mb-xs-0",
                                            class: [
                                              tile.secondaryImgD
                                                ? "mt-xs-3"
                                                : "mt-xs-0"
                                            ],
                                            style: { color: tile.headerColor },
                                            domProps: {
                                              innerHTML: _vm._s(tile.header)
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      tile.tileText
                                        ? _c("div", {
                                            staticClass: "tile-text",
                                            class: [
                                              tile.header || tile.secondaryImgD
                                                ? "mt-xs-2"
                                                : "mt-xs-0"
                                            ],
                                            style: { color: tile.textColor },
                                            domProps: {
                                              innerHTML: _vm._s(tile.tileText)
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      tile.ctaText && tile.ctaLink
                                        ? _c(
                                            "div",
                                            {
                                              class: [
                                                tile.secondaryImgD ||
                                                tile.tileText ||
                                                tile.header
                                                  ? "mt-xs-3"
                                                  : "mt-xs-0",
                                                _vm.imgTitleData.ctaStyle
                                              ]
                                            },
                                            [
                                              !tile.jumpToHtml
                                                ? _c("a", {
                                                    staticClass:
                                                      "tile-btn view-all",
                                                    style: {
                                                      color: tile.ctaColor
                                                    },
                                                    attrs: {
                                                      target: tile.isExternalLink
                                                        ? "_blank"
                                                        : "",
                                                      href: tile.isExternalLink
                                                        ? tile.ctaLink
                                                        : _vm.globals.getUrlWithContextPath(
                                                            tile.ctaLink
                                                          ),
                                                      "aria-label": tile.header
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        tile.ctaText
                                                      )
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              tile.jumpToHtml
                                                ? _c("a", {
                                                    staticClass:
                                                      "tile-btn view-all",
                                                    style: {
                                                      color: tile.ctaColor
                                                    },
                                                    attrs: {
                                                      target: tile.isExternalLink
                                                        ? "_blank"
                                                        : "",
                                                      href: tile.isExternalLink
                                                        ? tile.ctaLink
                                                        : _vm.globals.getUrlWithContextPath(
                                                            tile.ctaLink
                                                          ),
                                                      "aria-label": tile.header
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        tile.ctaText
                                                      )
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.emitScrollSignal(
                                                          tile.ctaLink
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            ),
                        _vm._v(" "),
                        tile.isVideo === "true"
                          ? _c("a", {
                              staticClass:
                                "image-title__video-play-icon image-title__position__center icon-play",
                              style: { color: tile.playIconColor },
                              attrs: {
                                target: tile.isExternalImage ? "_blank" : "",
                                href: "javascript:void(0)",
                                title: _vm.i18n.iconPlayTitle
                              },
                              on: {
                                click: function($event) {
                                  _vm.openModal(tile.videoSrc)
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ]
                ],
                2
              )
            ]
          )
        })
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f8f8860a", { render: render, staticRenderFns: staticRenderFns })
  }
}