var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-share-item-modal" },
    [
      _c("vx-spinner", {
        ref: "shareItemSpinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.globals.isB2B()
        ? _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "share-item-modal-content pb-lg-4 pb-md-4 pb-sm-4 pb-xs-4"
              },
              [_vm._v(_vm._s(_vm.i18n.content))]
            ),
            _vm._v(" "),
            _c("form", { ref: "shareItemForm" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("p", { staticClass: "pb-lg-1 pb-md-1 pb-sm-1 pb-xs-1" }, [
                  _vm._v(_vm._s(_vm.i18n.recipientEmail))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate:recipients",
                      value: "required|email",
                      expression: "'required|email'",
                      arg: "recipients"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.recipientValue,
                      expression: "recipientValue"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("recipients") },
                  attrs: {
                    type: "text",
                    name: "recipients",
                    "aria-label": _vm.i18n.recipientEmail
                  },
                  domProps: { value: _vm.recipientValue },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.recipientValue = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("recipients")
                  ? _c("div", [
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(_vm._s(_vm.i18n.recipientEmailValidation))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "pb-lg-5 pt-lg-2 pb-md-4 pt-md-2 pb-sm-4 pt-sm-2 pb-xs-4 pt-xs-2 share-item-helper-text"
                  },
                  [
                    _vm._v(
                      "\n                    " + _vm._s(_vm.i18n.helperText)
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex pb-lg-5 pb-md-4 pb-sm-4 pb-xs-4" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "sender-name pr-lg-3 pr-md-3 pr-sm-3 pr-xs-3"
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "pb-lg-1 pb-md-1 pb-sm-1 pb-xs-1" },
                          [_vm._v(_vm._s(_vm.i18n.senderName))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.senderName,
                              expression: "form.senderName"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "input-error": _vm.errors.has("senderName")
                          },
                          attrs: {
                            type: "text",
                            name: "senderName",
                            maxlength: "40",
                            "aria-label": _vm.i18n.senderName
                          },
                          domProps: { value: _vm.form.senderName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "senderName",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("senderName")))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "sender-email pl-lg-3 pl-md-3 pl-sm-3 pl-xs-2"
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "pb-lg-1 pb-md-1 pb-sm-1 pb-xs-1" },
                          [_vm._v(_vm._s(_vm.i18n.senderEmail))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.senderEmail,
                              expression: "form.senderEmail"
                            }
                          ],
                          staticClass: "form-control",
                          class: { "input-error": _vm.errors.has("sender") },
                          attrs: {
                            type: "text",
                            name: "sender",
                            "aria-label": _vm.i18n.senderEmail
                          },
                          domProps: { value: _vm.form.senderEmail },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "senderEmail",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("sender")))
                        ])
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "pb-lg-5 pb-md-4 pb-sm-4 pb-xs-4" }, [
                  _c("p", { staticClass: "pb-lg-1 pb-md-1 pb-sm-1 pb-xs-1" }, [
                    _vm._v(_vm._s(_vm.i18n.subject))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.subject,
                        expression: "form.subject"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control subject",
                    class: { "input-error": _vm.errors.has("subject") },
                    attrs: {
                      type: "text",
                      name: "subject",
                      "aria-label": _vm.i18n.subject,
                      maxlength: "95"
                    },
                    domProps: { value: _vm.form.subject },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "subject", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-msg" }, [
                    _vm._v(_vm._s(_vm.errors.first("subject")))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pb-lg-3 pb-md-3 pb-sm-3 pb-xs-3" }, [
                  _c("p", { staticClass: "pb-lg-1 pb-md-1 pb-sm-1 pb-xs-1" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.i18n.message) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.senderMessage,
                        expression: "form.senderMessage"
                      }
                    ],
                    staticClass: "form-control message",
                    attrs: {
                      type: "text",
                      "aria-label": _vm.i18n.a11ySenderOptionalMsgLabel,
                      maxlength: "462"
                    },
                    domProps: { value: _vm.form.senderMessage },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "senderMessage", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _vm.productInfo || _vm.isList
                  ? _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("div", { staticClass: "checkbox" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addLink,
                                expression: "form.addLink"
                              }
                            ],
                            attrs: { id: "addLink", type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.form.addLink)
                                ? _vm._i(_vm.form.addLink, null) > -1
                                : _vm.form.addLink
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.form.addLink,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "addLink",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "addLink",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "addLink", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "addLink" } }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.i18n.addLink) +
                                "\n                            "
                            )
                          ])
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-primary mt-lg-2 px-lg-5 mt-md-2 px-md-5 mt-sm-2 px-sm-5 mt-xs-2 px-xs-5 send-email-button modal-btn-primary-large",
                      attrs: { type: "button" },
                      on: { click: _vm.onSubmit }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.i18n.sendEmail) +
                          "\n                    "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                !_vm.globals.isGppro()
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "mt-xs-4 disclaimer modal-btn-IEspacing modal-btn-EDGEspacing"
                      },
                      [
                        _c("p", { staticClass: "disclaimer-text" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.i18n.disclaimerLine1) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "disclaimer-text" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.i18n.disclaimerLine2) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "disclaimer-line disclaimer-text" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.i18n.disclaimerLine3) +
                                "\n                        "
                            ),
                            _c(
                              "a",
                              { attrs: { href: _vm.i18n.privacyPolicyLink } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.i18n.disclaimerLine4) +
                                    "\n                        "
                                )
                              ]
                            ),
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.i18n.disclaimerLine5) +
                                "\n                    "
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.globals.isB2B()
        ? _c(
            "div",
            {
              staticClass: "social-share",
              class: { "show-social-share": _vm.enableSocialShare }
            },
            [
              _c(
                "div",
                {
                  staticClass: "a2a_kit a2a_default_style p-xs-0 mt-xs-2",
                  class: _vm.socialShareSizeClass,
                  attrs: {
                    "data-a2a-url": _vm.url,
                    "data-a2a-title": _vm.title
                  }
                },
                [
                  _vm.isSocialShare.isSocialShareforFBEnabled
                    ? _c("a", { staticClass: "a2a_button_facebook mt-xs-1" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSocialShare.isSocialShareForTwitterEnabled
                    ? _c("a", { staticClass: "a2a_button_twitter mt-xs-1" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSocialShare.isSocialShareForPinterestEnabled
                    ? _c("a", { staticClass: "a2a_button_pinterest mt-xs-1" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSocialShare.isSocialShareForLinkedInEnabled
                    ? _c("a", { staticClass: "a2a_button_linkedin mt-xs-1" })
                    : _vm._e()
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loadPdfGenerator
        ? [
            _vm.globals.isB2B() && _vm.pdfData
              ? _c(
                  "vx-pdf-generator",
                  {
                    ref: "pdfModule",
                    attrs: {
                      name: "pdfversion",
                      "has-sustainability-page": false,
                      "pdf-data": _vm.pdfData
                    }
                  },
                  [
                    !_vm.isList
                      ? [
                          _c(
                            "div",
                            { attrs: { slot: "details" }, slot: "details" },
                            [
                              _c("div", { staticClass: "pdf-left-section" }, [
                                _vm.pdfData.name
                                  ? _c("h5", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.replaceText(_vm.pdfData.name)
                                        )
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.pdfData.description
                                  ? _c("h5", [
                                      _vm._v(
                                        "\n                            Description:\n                        "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.pdfData.description
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "description-text",
                                        staticStyle: { "font-size": "12px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.pdfData.description) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _vm.pdfData.featureList &&
                              _vm.pdfData.featureList.length
                                ? _c(
                                    "h5",
                                    { staticClass: "pdf-feature-heading" },
                                    [
                                      _vm._v(
                                        "\n                        Features and Benefits:\n                    "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.pdfData.featureList
                                ? _c("div", { staticClass: "pdf-features" }, [
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "features",
                                        staticStyle: { "font-size": "12px" }
                                      },
                                      _vm._l(_vm.pdfData.featureList, function(
                                        feature
                                      ) {
                                        return _vm.pdfData.featureList
                                          ? _c("li", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.replaceText(feature)
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      })
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("img", { staticClass: "pdf-itf" }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "pdf-itf1",
                                attrs: {
                                  src:
                                    _vm.pdfData.images &&
                                    _vm.pdfData.images[0] &&
                                    _vm.pdfData.images[0].zoomUrl
                                      ? _vm.pdfData.images[0].zoomUrl
                                      : _vm.globals.assetsPath +
                                        "images/no_image.png"
                                }
                              })
                            ]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-75093ba1", { render: render, staticRenderFns: staticRenderFns })
  }
}