var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-uiux-infographic", class: _vm.componentTheme },
    [
      _c("img", {
        staticClass: "expand-icon",
        attrs: {
          title: "Expand Image",
          role: "button",
          tabindex: "0",
          src: _vm.globals.assetsPath + "images/expand-icon.png"
        },
        on: {
          click: function($event) {
            _vm.expandInfographic($event)
          },
          keyup: function($event) {
            if (
              !("button" in $event) &&
              _vm._k(
                $event.keyCode,
                "enter-space",
                undefined,
                $event.key,
                undefined
              )
            ) {
              return null
            }
            _vm.expandInfographic($event)
          }
        }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "infographic",
        attrs: {
          src:
            _vm.uiuxInfographicData.image ||
            _vm.globals.assetsPath + "images/no_image.svg",
          alt: "No Image Found1"
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "infographicModal",
          attrs: { size: "large", heading: "Infographic" }
        },
        [
          _c(
            "div",
            {
              staticClass: "text-center",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c("img", {
                attrs: {
                  src:
                    _vm.uiuxInfographicData.image ||
                    _vm.globals.assetsPath + "images/no_image.svg",
                  alt: "No Image Found"
                }
              })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f4b732e0", { render: render, staticRenderFns: staticRenderFns })
  }
}