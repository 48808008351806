<template>

    <vx-compare-slider-render :config="config" :i18n="i18n">
      <slot></slot>
    </vx-compare-slider-render>
</template>
<script>
export default {
  name: 'vx-compare-slider',
  props: {
    i18n: {
      type: Object,
      required: false,
    },
    config: {
      type: Object,
      required: false,
    },
  },
  components: {
    vxCompareSliderRender: () => import(/* webpackChunkName: "vx-compare-slider" */ './vx-compare-slider.vue'),
  },
};
</script>
