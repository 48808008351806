import filter from 'lodash.filter';
import includes from 'lodash.includes';
import { Validator } from 'vee-validate';
// import createAccount from './create-account-i18n';
import vxDropdownPrimary from '../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import globals from '../../common/globals';
import vxCompanyDetails from '../vx-company-details/vx-company-details.vue';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import loginMixin from '../../common/mixins/login-mixin';
import kauthMixin from '../../common/mixins/kauth-mixin';
import detectDeviceMixin from '../../common/mixins/detect-device-mixin';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import vxTermsCondition from '../../common/vx-terms-condition/vx-terms-condition.vue';
import RegistrationLoginService from '../../common/services/registration-login-service';
import { globalEventBus } from '../../../modules/event-bus';
import {
  monthLiterals,
  monthCollection1,
  monthCollection2,
  genderList,
} from '../../common/mixins/vx-enums';
import optInMixin from '../../common/mixins/optin-mixin';
import vxPasswordCheckList from '../vx-password-checkList/vx-password-checkList.vue';

/**
 * Register component
 */

export default {
  name: 'vx-create-account',
  mixins: [loginMixin, kauthMixin, detectDeviceMixin, optInMixin],
  props: {
    actionUrl: String,
    i18n: Object,
    countryMarketingValues: Object,
    isGenderEnabled: {
      type: String,
      default: 'false',
    },
    isDobEnabled: {
      type: String,
      default: 'false',
    },
  },
  data() {
    return {
      globals,
      registrationLoginService: new RegistrationLoginService(),
      kochTokens: {},
      // i18n: createAccount,
      createAccountServerError: '',
      marketingCheckbox: {},
      monthLiterals,
      date: [],
      dateLimit: 31,
      populateMonths: [],
      dateOfBirth: '',
      countryList: [
        {
          label: 'United States of America',
          value: 'US',
        },
        {
          label: 'Canada',
          value: 'CA',
        },
        {
          label: 'Other',
          value: 'other',
        },
      ],
      genderList,
      user: {
        country: {
          label: 'United States of America',
          value: 'US',
        },
        gender: {
          label: 'Select Gender',
          value: '',
        },
        month: {
          label: 'Month',
          value: '',
        },
        date: {
          label: 'Date',
          value: '',
        },
        firstName: '',
        lastName: '',
        uid: '',
        password: '',
        b2bMailerCheck: false,
        addToMarketComm: true,
        confirmEmail: '',
      },
      showPassword: false,
      showDateError: false,
      showMonthError: false,
      isEmailMismatch: true,
    };
  },
  components: {
    vxDropdownPrimary,
    vxCompanyDetails,
    vxTermsCondition,
    vxModal,
    vxSpinner,
    vxPasswordCheckList,
  },
  methods: {
    /**
     * openTermsModal is called on clicking of terms and conditions, which opens the termsConditionModal.
     * @param {Object} event contains the event of the particular element.
     */
    openTermsModal(event) {
      this.$refs.termsConditionModal.open(event);
    },
    /**
     * This function toggles the password on hiding and showing the text.
     */
    togglePassword() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.$refs.password.type = 'text';
      } else {
        this.$refs.password.type = 'password';
      }
    },
    /**
     * submitForm is called on cicking submit button of the create account form.
     * @param {Object} e contains the event of the form.
     * Validates the data entered on successfull validation user a post call is made to registerUser.
     * On failure focus is set back to first field in the form.
     */
    submitForm(e) {
      const self = this;
      e.preventDefault();
      if (this.user.month.value === '' && this.user.date.value !== '') {
        this.showMonthError = true;
      } else if (this.user.month.value !== '' && this.user.date.value === '') {
        this.showDateError = true;
      } else if (
        (this.user.month.value === '' && this.user.date.value === '')
        || (this.user.month.value !== '' && this.user.date.value !== '')
      ) {
        this.showMonthError = false;
        this.showDateError = false;
      }
      this.$validator.validateAll().then((result) => {
        if (result && !this.showMonthError && !this.showDateError) {
          this.setDob();
          if (self.globals.isB2C()) {
            self.user.b2bMailerCheck = false;
          } else {
            self.user.addToMarketComm = true;
          }

          const requestConfig = {};
          requestConfig.data = self.createRequestbody();

          const continueRegister = function() {
            self.registrationLoginService.registerUser(
              requestConfig,
              self.handleRegisterResponse,
              self.handleRegisterError,
            );
          };
          self.$refs.spinner.showSpinner();
          if ((this.globals.isB2C() && this.user.addToMarketComm)) {
            const cmmConfig = {
              email: requestConfig.data.uid,
              'opt-in': 1,
              spprograms: this.globals.getspprograms('create-account'),
              brands: this.globals.siteId.toLowerCase(),
              int_source: 'create_act',
            };
            const callSendOpp = function() {
              self.SendOpt(cmmConfig, continueRegister);
            };

            this.optinAnalyticsHandler('submit', true, requestConfig.data.uid, callSendOpp);
          } else {
          //  if (this.globals.isB2B() && this.user.b2bMailerCheck) {
          //   this.CheckOpt(requestConfig.data, continueCheckout);
          // }
          // else {
            this.optinAnalyticsHandler('submit', false, undefined, continueRegister);
          }
        } else {
          this.globals.setFocusByName(
            this.$el,
            this.globals.getElementName(this.errors),
          );
        }
      });
    },
    /**
     * Creates a request based on the data entered by the user.
     */
    createRequestbody() {
      let marketingCommunications = false;
      if (this.globals.isB2B()) {
        marketingCommunications = this.user.b2bMailerCheck;
      } else {
        marketingCommunications = this.user.addToMarketComm;
      }
      const reqbody = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        password: this.user.password,
        country: this.user.country.value,
        gender: this.user.gender.value,
        dateOfBirth: this.dateOfBirth,
        uid: this.user.uid,
        addToMarketComm: marketingCommunications,
        titleCode: 'mr',
      };
      return reqbody;
    },
    /**
     * handleRegisterResponse is called on successfull posting of the user.
     * A success flyout messae is displayed.
     * If it is B2C and Employee store the kauthData is delete.
     * If it is a B2B Site company details modal is opened.
     */
    handleRegisterResponse() {
      this.$refs.spinner.hideSpinner();
      this.showFlyoutNextPage('success', this.i18n.registerSuccessMsg, true);
      if (this.globals.isB2C()) {
        // If employee store, delete Koch Auth data
        this.submitLogin();
      } else if (this.globals.isB2B()) {
        // If it is B2B then survey details call we will generate
        // if it is success company details modal will be opened
        if (this.globals.siteConfig.createAcountMoreDetailsEnabled) {
          this.$refs.companyDetailsModal.open();
        } else {
          this.submitLogin();
        }
      }
    },
    /**
     * On submit or skip or close  we will close the modal we will send
     * data of company details / survey
     * details to company details ajax request on its response we will call login
     * company details success response we will allow person to login.
     */
    loginRequest() {
      this.$refs.companyDetailsModal.close();
      this.submitLogin();
    },
    /**
     * handleRegisterError is called on failure of  posting  the registerUser to server.
     * @param {Object} error is the data recieved from server on failure which contains error code, status.
     */
    handleRegisterError(error) {
      this.$refs.spinner.hideSpinner();
      if (error) {
        this.createAccountServerError = this.i18n[
          error.response.data.errors[0].message
        ];
        globalEventBus.$emit('announce', this.createAccountServerError);
      }
    },
    /**
     * This function navigated the user to login page.
     */
    login() {
      this.globals.navigateToUrl('login');
    },
    /**
     * Based on change in month the date dropdown is set with the respective dates.
     */
    setDateDropDown() {
      this.date = [];
      for (let i = 1; i <= this.dateLimit; i += 1) {
        const tempObj = {};
        if (i < 10) {
          tempObj.label = `0${i}`;
          tempObj.value = `0${i}`;
        } else {
          tempObj.label = i.toString();
          tempObj.value = i.toString();
        }
        this.date.push(tempObj);
      }
    },
    /**
     * When ever there is change in Month handleChangeMonth is called.
     * @param {Object} evt contains the event of the particular field.
     */
    handleChangeMonth(evt) {
      switch (evt.value) {
        case '02':
          this.dateLimit = 29;
          break;
        case '04':
        case '06':
        case '09':
        case '11':
          this.dateLimit = 30;
          break;
        default:
          this.dateLimit = 31;
      }
      this.user.month.value = evt.value;
      if (this.showMonthError) {
        this.showMonthError = false;
      }
      this.setDateDropDown();
    },
    /**
     * Changing the Months in month drop down when ever user changes the date.
     * handleChangeDate is called on chaining the date.
     * @param {Object} evt contains the event of the particular dropdown.
     */
    handleChangeDate(evt) {
      let temp = {};
      const valueLiteral = 'value';
      switch (evt.value) {
        case '31':
          temp = this.filterMonths(
            this.monthLiterals.options,
            valueLiteral,
            monthCollection1,
          );
          break;
        case '30':
          temp = this.filterMonths(
            this.monthLiterals.options,
            valueLiteral,
            monthCollection2,
          );
          break;
        default:
          temp = this.monthLiterals.options;
          break;
      }
      if (this.showDateError) {
        this.showDateError = false;
      }
      this.user.date.value = evt.value;
      this.populateMonths = temp;
    },
    filterMonths(collection, property, values) {
      return filter(collection, (item) => includes(values, item[property]));
    },
    /**
     * Sets tht DOB i.e date of the birth.
     */
    setDob() {
      if (
        this.$refs.dateDropdown
        && this.$refs.monthDropdown
        && this.user.date.value !== ''
        && this.user.month.value !== ''
      ) {
        this.dateOfBirth = `${this.user.date.value}-${this.user.month.value}`;
      }
    },
    handleLoginCallback() {
      this.showFlyoutNextPage('success', 'User logged in', true);
    },
    /**
     * Marketing country check box changes/
     * @param {Object} country contians the current country chose.
     */
    handleCountryForMarketing(country) {
      this.marketingCheckbox = this.countryMarketingValues[country];
    },
    /**
     * handleKochTokenResponse is called on successfull response from getKochAuthToken.
     * @param {Object} response contains the kochTokens.
     */
    handleKochTokenResponse(response) {
      this.kochTokens = response.data;
    },
    handleKochTokenError() {},
    checkPasswordCharacters() {
      return this.passwordLengthRegex.test(this.user.password);
    },
    checkPasswordCase() {
      return this.passwordAlphabetRegex.test(this.user.password);
    },
    checkPasswordNumbers() {
      return this.passwordNumberRegex.test(this.user.password);
    },
    checkPasswordSpecialChars() {
      return this.passwordSpecialCharacterRegex.test(this.user.password);
    },
    updateEmailMismatch() {
      if (this.user.uid === this.user.confirmEmail) {
        this.isEmailMismatch = false;
      } else {
        this.isEmailMismatch = true;
      }
    },
    handlePasteAction(event) {
      event.preventDefault();
    },
  },
  mounted() {
    globalEventBus.$emit('announce', 'Create Account Page');
    const veeCustomErrorMessage = {
      en: {
        custom: {
          firstName: {
            required: this.i18n.firstNameRequiredError,
            regex: this.i18n.firstNameRegexError,
            max: this.i18n.firstNameMaxError,
          },
          lastName: {
            required: this.i18n.lastNameRequiredError,
            regex: this.i18n.lastNameRegexError,
            max: this.i18n.lastNameMaxError,
          },
          email: {
            required: this.i18n.emailRequiredError,
            email: this.i18n.emailInvalidError,
          },
          password: {
            required: this.i18n.passwordRequiredError,
            regex: this.i18n.passwordInvalidError,
            min: this.i18n.passwordMinError,
          },
          createAccountMonth: {
            required: this.i18n.monthRequiredError,
          },
          createAccountDay: {
            required: this.i18n.DayRequiredError,
          },
          confirmEmail: {
            required: this.i18n.confirmEmailRequiredError,
          },
        },
      },
    };
    this.populateMonths = this.monthLiterals.options;
    this.setDateDropDown();
    Validator.localize(veeCustomErrorMessage);
    this.handleCountryForMarketing(this.user.country.value.toLowerCase());
    this.$refs.countryDropdown.setDropdownLabel(this.user.country.label);
    if (this.$refs.genderDropdown) {
      this.$refs.genderDropdown.setDropdownLabel(this.user.gender.label);
    }
    if (this.$refs.monthDropdown) {
      this.$refs.monthDropdown.setDropdownLabel(this.user.month.label);
    }
    if (this.$refs.dateDropdown) {
      this.$refs.dateDropdown.setDropdownLabel(this.user.date.label);
    }
    if (this.globals.isB2B()) {
      this.user.b2bMailerCheck = false;
    } else {
      this.user.addToMarketComm = true;
    }
    this.isCheckBoxOnPage();
  },
  watch: {
    'user.country': function() {
      this.handleCountryForMarketing(this.user.country.value.toLowerCase());
      if (this.globals.isB2B()) {
        this.user.b2bMailerCheck = this.marketingCheckbox.B2BMailerCheck;
      } else {
        this.user.addToMarketComm = this.marketingCheckbox.B2CAddToMarket;
      }
    },
    'user.uid': function() {
      this.updateEmailMismatch();
    },
    'user.confirmEmail': function() {
      if (this.user.confirmEmail.length) {
        this.updateEmailMismatch();
      }
    },
  },
};
