var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "vx-promotion" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "promotionDetailsModal",
          attrs: { size: "small", heading: _vm.i18n.promoDetailsHeading },
          on: {
            close: function($event) {
              _vm.closeVoucherModal()
            }
          }
        },
        [
          _c("vx-voucher-details", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n,
              "voucher-data": _vm.voucherObject
            },
            on: {
              voucherDetailsClose: function($event) {
                _vm.closeVoucherModal()
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("h3", { staticClass: "promotions-label" }, [
        _vm._v("\n        Promotions\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "promotions-body" }, [
        _c("div", { staticClass: "apply-promo" }, [
          _c("div", { staticClass: "form-group apply-voucher d-flex" }, [
            _c("div", { staticClass: "promo-input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.voucherCode,
                    expression: "voucherCode"
                  }
                ],
                staticClass: "form-control",
                class: { "promo-error input-error": _vm.showPromoError },
                attrs: {
                  type: "text",
                  name: "",
                  "aria-label": _vm.i18n.enterPromo,
                  placeholder: _vm.i18n.enterPromo,
                  "aria-required": "true",
                  "aria-invalid": "false",
                  "aria-describedby": "promoCode_err_msg",
                  tabindex: "0"
                },
                domProps: { value: _vm.voucherCode },
                on: {
                  keyup: function($event) {
                    _vm.showPromoError = false
                  },
                  keydown: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.applyVoucher($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.voucherCode = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-tertiary",
                  attrs: {
                    type: "button",
                    "aria-label": _vm.i18n.applyProduct,
                    disabled: _vm.showPromoError
                  },
                  on: { click: _vm.applyVoucher }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.i18n.applyProduct) +
                      "\n                    "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPromoError,
                  expression: "showPromoError"
                }
              ],
              staticClass: "mt-xs-2 form-group",
              attrs: { "aria-hidden": !_vm.showPromoError }
            },
            [
              _c(
                "span",
                {
                  staticClass: "error-style",
                  attrs: { id: "promoCode_err_msg" }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.promoErrorMsg) +
                      "\n                "
                  )
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _vm.eligibleCodesData.length
          ? _c(
              "div",
              { staticClass: "applied-promo" },
              [
                _c("h4", { staticClass: "mb-20" }, [
                  _vm._v(_vm._s(_vm.i18n.appliedPromotions))
                ]),
                _vm._v(" "),
                _vm._l(_vm.eligibleCodesData, function(item) {
                  return [
                    _c(
                      "div",
                      { staticClass: "pb-xs-3 voucher-container pl-10" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-start justify-content-between"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "voucher-coupon",
                                attrs: { tabindex: "0", role: "button" },
                                on: {
                                  click: function($event) {
                                    _vm.voucherDetails(item, $event)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.voucherDetails(item, $event)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.code))]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm.calculateVoucherAmount(item.description)
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "promo-amount pr-xs-2 pull-left"
                                    },
                                    [
                                      _vm._v(
                                        "-" +
                                          _vm._s(
                                            _vm.calculateVoucherAmount(
                                              item.description
                                            )
                                          )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "icon-x pull-right promotion-x",
                                attrs: {
                                  title: _vm.i18n.iconCloseTitle,
                                  role: "button",
                                  tabindex: "0",
                                  "aria-label": _vm.i18n.iconCloseTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.deleteVoucher(item.code)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.deleteVoucher(item.code)
                                  }
                                }
                              })
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                })
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.checkDividervisibility()
          ? _c("div", { staticClass: "promo-divider" })
          : _vm._e(),
        _vm._v(" "),
        _vm.nonApplicableCodes.length
          ? _c(
              "div",
              [
                _c("h4", { staticClass: "mb-20 top-spacing" }, [
                  _vm._v(_vm._s(_vm.i18n.promotionsNotApplicable))
                ]),
                _vm._v(" "),
                _vm._l(_vm.nonApplicableCodes, function(item) {
                  return [
                    _c(
                      "div",
                      { staticClass: "pb-xs-3 voucher-container pl-10" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-start justify-content-between"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "voucher-coupon",
                                attrs: { tabindex: "0", role: "button" },
                                on: {
                                  click: function($event) {
                                    _vm.voucherDetails(item, $event)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.voucherDetails(item, $event)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item) +
                                    "\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "icon-x pull-right promotion-x",
                              attrs: {
                                title: _vm.i18n.iconCloseTitle,
                                role: "button",
                                tabindex: "0",
                                "aria-label": _vm.i18n.iconCloseTitle
                              },
                              on: {
                                click: function($event) {
                                  _vm.deleteVoucher(item)
                                },
                                keyup: function($event) {
                                  if (
                                    !("button" in $event) &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter-space",
                                      undefined,
                                      $event.key,
                                      undefined
                                    )
                                  ) {
                                    return null
                                  }
                                  _vm.deleteVoucher(item)
                                }
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                })
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-30fd507c", { render: render, staticRenderFns: staticRenderFns })
  }
}