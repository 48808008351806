import vxUpsellCarousel from '../components/pdp/vx-upsell-carousel/vx-upsell-carousel.vue';
import vxThumbnailViewer from '../components/pdp/vx-thumbnail-viewer/vx-thumbnail-viewer.vue';
import vxPdpProductInfo from '../components/pdp/vx-pdp-product-info/vx-pdp-product-info.vue';
import vxTabContainer from '../components/pdp/vx-tab-container/vx-tab-container.vue';
import vxShareItem from '../components/pdp/vx-share-item/vx-share-item.vue';
import vxFrequentlyBoughtTogether from '../components/pdp/vx-frequently-bought-together/vx-frequently-bought-together.vue';
import vxPdpCompareProducts from '../components/pdp/vx-pdp-compare-products/vx-pdp-compare-products.vue';

const pdpModule = {
  components: {
    vxUpsellCarousel,
    vxThumbnailViewer,
    vxPdpProductInfo,
    vxTabContainer,
    vxShareItem,
    vxFrequentlyBoughtTogether,
    vxPdpCompareProducts,
  },
};
export default pdpModule;
