var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-uiux-nested-tiles",
      class: _vm.imgTileData.componentTheme
    },
    [
      _vm.imgTileData.componentHeader
        ? _c("div", {
            ref: "header",
            staticClass: "header",
            domProps: { innerHTML: _vm._s(_vm.imgTileData.componentHeader) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.imgTileData.imageTexts.length > 0
        ? _c(
            "div",
            { staticClass: "tiles", attrs: { role: "list" } },
            _vm._l(_vm.imgTileData.imageTexts, function(tile) {
              return _c("vx-uiux-gp-image-text", {
                attrs: {
                  "uiux-image-tile-data": tile.imageData,
                  "uiux-text-tile-data": tile
                }
              })
            })
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-60bdaba0", { render: render, staticRenderFns: staticRenderFns })
  }
}