var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-single-shipping-address" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addNewAddressModal",
          attrs: {
            size: "large",
            heading:
              _vm.editAddressModalHeading ||
              _vm.i18n.shippingAddress.addAddress,
            "is-modal-scrollable": _vm.modalScrollable
          }
        },
        [
          _c(
            "vx-add-edit-address",
            {
              ref: "addressForm",
              attrs: {
                slot: "component",
                buttonText: _vm.i18n.shippingAddress.useShippingAddress,
                submitButtonText: _vm.i18n.shippingAddress.useShippingAddress,
                "pallet-shipment": _vm.palletShipment,
                i18n: _vm.i18n.address,
                editAddress: _vm.addressToEdit,
                "is-bussiness-unit": _vm.globals.isB2B()
              },
              on: { onAddressSaved: _vm.saveThisAddress },
              slot: "component"
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("div", { staticClass: "section-header mb-xs-3" }, [
                  _vm._v(_vm._s(_vm.i18n.address.shippingAddressModal))
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isSaved
        ? _c(
            "div",
            { staticClass: "mx-xs-0 row" },
            [
              !_vm.addressAvailable
                ? [
                    _vm.userLevel || !_vm.globals.getIsLoggedIn()
                      ? [
                          _c(
                            "vx-add-edit-address",
                            {
                              ref: "addressForm",
                              attrs: {
                                buttonText:
                                  _vm.i18n.shippingAddress.useShippingAddress,
                                submitButtonText:
                                  _vm.i18n.shippingAddress.useShippingAddress,
                                "pallet-shipment": _vm.palletShipment,
                                isSingleShipping: "true",
                                editAddress: {},
                                i18n: _vm.i18n.address,
                                "is-bussiness-unit": _vm.globals.isB2B(),
                                "is-first-address": ""
                              },
                              on: { onAddressSaved: _vm.saveThisAddress }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "header" }, slot: "header" },
                                [_c("div")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "companyNameLabel" },
                                  slot: "companyNameLabel"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "companyName",
                                        "aria-hidden": _vm.isDeviceAndroid()
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.i18n.address.newUsercompanyName
                                        )
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "phoneNumberLabel" },
                                  slot: "phoneNumberLabel"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "phoneNumber",
                                        "aria-hidden": _vm.isDeviceAndroid()
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.i18n.address.newUserPhoneNumber
                                        )
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "addressLine1Label" },
                                  slot: "addressLine1Label"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "addressLine1",
                                        "aria-hidden": _vm.isDeviceAndroid()
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.i18n.address.newUserAddressLine1
                                        )
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "addressLine2Label" },
                                  slot: "addressLine2Label"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "addressLine2",
                                        "aria-hidden": _vm.isDeviceAndroid()
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.i18n.address.newUserAddressLine2
                                        )
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "stateLabel" },
                                  slot: "stateLabel"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        "aria-hidden": _vm.isDeviceAndroid()
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.i18n.address.newUserstate)
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      : [
                          _c("div", { staticClass: "sub-heading mt-xs-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.i18n.shippingAddress
                                  .shippingAddressSubHeading
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "px-xs-0 col-md-6 col-xs-12 dropdown mt-xs-4"
                            },
                            [_c("vx-dropdown-primary")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "px-xs-0 col-xs-12 pt-xs-4" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { disabled: "" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.i18n.shippingAddress
                                        .useShippingAddress
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "error-msg pt-xs-4 px-xs-0 col-xs-12",
                              attrs: { role: "contentinfo" }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.i18n.shippingAddress.b2bNoAddress
                                  ) +
                                  "\n          "
                              ),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.globals.navigateToUrl("addAddress")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.i18n.shippingAddress.b2bNoAddressLink
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.addressAvailable
                ? _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "px-xs-0 col-xs-12  pt-xs-4 dropdown" },
                      [
                        _c("vx-dropdown-primary", {
                          ref: "addressDropdown",
                          attrs: {
                            dropdownValues: _vm.allUserSavedAddresses,
                            "aria-label":
                              _vm.i18n.shippingAddress.shippingAddressHeading
                          },
                          on: {
                            "selected-item": function($event) {
                              _vm.selectedAddress($event)
                            },
                            primaryDropdownMounted: _vm.populateStoredAddress,
                            "edit-selected-address": function($event) {
                              _vm.editAddress($event)
                            }
                          }
                        }),
                        _vm._v(" "),
                        !_vm.isSaved &&
                        _vm.globals.siteConfig.showPoBoxShippingMsg
                          ? _c("div", { staticClass: "sub-heading mt-xs-3" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.i18n.shippingAddress
                                      .shippingAddressSubHeading
                                  )
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.dropdownError
                          ? _c("div", { staticClass: "mt-xs-2" }, [
                              _c("span", { staticClass: "error-msg" }, [
                                _vm._v(
                                  _vm._s(_vm.i18n.shippingAddress.enterAddress)
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.dropdownError && _vm.noPhoneNumber
                          ? _c("div", { staticClass: "mt-xs-2" }, [
                              _c("span", { staticClass: "error-msg" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.i18n.shippingAddress.checkPhone)
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.dropdownError && _vm.noCompanyName
                          ? _c("div", { staticClass: "mt-xs-2" }, [
                              _c("span", { staticClass: "error-msg" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.i18n.shippingAddress.checkCompanyName
                                    )
                                )
                              ])
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.restrictionError && !_vm.restrictedItems.length === 0
                ? _c(
                    "div",
                    { staticClass: "mt-xs-3 restriction-sub-heading" },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.i18n.shippingAddress.restrictionSubHeading)
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.addressAvailable
                ? _c("div", { staticClass: "col-xs-12 mt-xs-4 px-xs-0" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn button-color text-trans modal-btn-primary-medium",
                        on: {
                          click: function($event) {
                            _vm.toggleViewMode(_vm.savedAddress, $event)
                          },
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter-space",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            _vm.toggleViewMode(_vm.savedAddress, $event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.i18n.shippingAddress.useShippingAddress)
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isSaved
        ? _c("div", [
            _vm.existingAddresses.length === 1
              ? _c("div", { staticClass: "pt-xs-4" }, [
                  _c("p", { staticClass: "address-view mb-xs-2" }, [
                    _vm._v(
                      _vm._s(_vm.savedAddress.value.firstName) +
                        " " +
                        _vm._s(_vm.savedAddress.value.lastName)
                    )
                  ]),
                  _vm._v(" "),
                  _vm.savedAddress &&
                  _vm.savedAddress.value &&
                  _vm.savedAddress.value.companyName
                    ? _c("p", { staticClass: "address-view mb-xs-2" }, [
                        _vm._v(_vm._s(_vm.savedAddress.value.companyName))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "address-view mb-xs-2" }, [
                    _vm._v(_vm._s(_vm.savedAddress.value.line1))
                  ]),
                  _vm._v(" "),
                  _vm.savedAddress.value.line2
                    ? _c("p", { staticClass: "address-view mb-xs-2" }, [
                        _vm._v(_vm._s(_vm.savedAddress.value.line2))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "address-view mb-xs-2" }, [
                    _vm._v(
                      _vm._s(_vm.savedAddress.value.town) +
                        " " +
                        _vm._s(_vm.savedAddress.value.region.isocodeShort) +
                        "\n        " +
                        _vm._s(_vm.savedAddress.value.postalCode)
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "address-view mb-xs-2" }, [
                    _vm._v(_vm._s(_vm.savedAddress.value.country.isocode))
                  ]),
                  _vm._v(" "),
                  _vm.savedAddress.value.userId && _vm.globals.getIsLoggedIn()
                    ? _c("p", { staticClass: "address-view mb-xs-2" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.savedAddress.value.userId.split("|")[0])
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.savedAddress.value.userId && !_vm.globals.getIsLoggedIn()
                    ? _c(
                        "p",
                        { staticClass: "address-view guest-email mb-xs-2" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.savedAddress.value.userId.split("|")[1]
                              )
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.savedAddress.value.phone
                    ? _c("p", { staticClass: "address-view mb-xs-2" }, [
                        _vm._v(_vm._s(_vm.savedAddress.value.phone))
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.existingAddresses.length > 1
              ? _c(
                  "div",
                  {
                    staticClass: "col-xs-12 px-xs-0 d-flex flex-row flex-wrap"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "sub-heading mt-xs-2 col-xs-12 px-xs-0" },
                      [_vm._v(_vm._s(_vm.i18n.shippingAddress.shippedMultiple))]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.existingAddresses, function(data, index) {
                      return _c(
                        "div",
                        { staticClass: "mt-xs-4 col-sm-6 col-xs-12 px-xs-0" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "product-address-heading",
                              attrs: { role: "heading" }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(index + 1) + ".")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.i18n.shippingAddress
                                      .shippingAddressHeading
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "pt-xs-2 address-container",
                              attrs: { role: "text", tabindex: "0" }
                            },
                            [
                              data && data.value
                                ? _c(
                                    "p",
                                    { staticClass: "address-view mb-xs-2" },
                                    [
                                      _vm._v(
                                        _vm._s(data.value.firstName) +
                                          " " +
                                          _vm._s(data.value.lastName)
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              data && data.value && data.value.companyName
                                ? _c(
                                    "p",
                                    { staticClass: "address-view mb-xs-2" },
                                    [_vm._v(_vm._s(data.value.companyName))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              data && data.value
                                ? _c(
                                    "p",
                                    { staticClass: "address-view mb-xs-2" },
                                    [
                                      _vm._v(
                                        _vm._s(data.value.line1) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              data && data.value && data.value.line2
                                ? _c(
                                    "p",
                                    { staticClass: "address-view mb-xs-2" },
                                    [_vm._v(_vm._s(data.value.line2))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("p", { staticClass: "address-view mb-xs-2" }, [
                                _vm._v(
                                  _vm._s(data.value.town) +
                                    " " +
                                    _vm._s(data.value.region.isocodeShort) +
                                    "\n              " +
                                    _vm._s(data.value.postalCode)
                                )
                              ]),
                              _vm._v(" "),
                              data && data.value && data.value.country
                                ? _c(
                                    "p",
                                    { staticClass: "address-view mb-xs-2" },
                                    [_vm._v(_vm._s(data.value.country.isocode))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              data.value.userId
                                ? _c(
                                    "p",
                                    { staticClass: "address-view mb-xs-2" },
                                    [
                                      _vm._v(
                                        _vm._s(data.value.userId.split("|")[0])
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              data && data.value
                                ? _c(
                                    "p",
                                    { staticClass: "address-view mb-xs-2" },
                                    [_vm._v(_vm._s(data.value.phone))]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.restrictedItems.length !== 0
        ? [
            _vm.restrictionError
              ? _c(
                  "div",
                  { staticClass: "mt-xs-5 shipping-restriction-section" },
                  [
                    _c("h4", { staticClass: "pb-xs-3 restriction-header" }, [
                      _vm._v(
                        _vm._s(_vm.i18n.shippingAddress.restrictionHeading)
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "pb-xs-3 restriction-error" }, [
                      _vm._v(
                        _vm._s(_vm.i18n.shippingAddress.shippingRestrictionText)
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.restrictedItems, function(item) {
                      return _c(
                        "vx-product-tile",
                        { attrs: { slot: "component" }, slot: "component" },
                        [
                          _c("template", { slot: "top-right" }, [
                            _c("span", { staticClass: "col-xs-2 px-xs-0" }, [
                              _c("span", {
                                staticClass: "cart-icons icon-trash",
                                attrs: {
                                  title:
                                    _vm.i18n.shippingAddress.iconTrashTitle,
                                  role: "button",
                                  tabindex: "0",
                                  "aria-label":
                                    _vm.i18n.shippingAddress.iconTrashTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.deleteCartItem(
                                      _vm.formattedEntries[item.productCode]
                                        .entryNumber,
                                      _vm.formattedEntries[item.productCode]
                                        .product.code,
                                      $event
                                    )
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.deleteCartItem(
                                      _vm.formattedEntries[item.productCode]
                                        .entryNumber,
                                      _vm.formattedEntries[item.productCode]
                                        .product.code,
                                      $event
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.formattedEntries &&
                          _vm.formattedEntries[item.productCode] &&
                          _vm.formattedEntries[item.productCode].product
                            .images &&
                          _vm.formattedEntries[item.productCode].product.images
                            .length !== 0 &&
                          _vm.formattedEntries[item.productCode].product
                            .images[2] &&
                          _vm.formattedEntries[item.productCode].product
                            .images[2].url
                            ? _c("template", { slot: "image-slot" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        _vm.globals.getNavigationUrl("empty") +
                                        _vm.formattedEntries[item.productCode]
                                          .product.url
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        alt:
                                          _vm.formattedEntries[item.productCode]
                                            .product.name,
                                        src: _vm.globals.getThumbnailImageUrl(
                                          _vm.formattedEntries[item.productCode]
                                            .product.images
                                        )
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.formattedEntries[item.productCode].product.images
                            ? _c("template", { slot: "image-slot" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        _vm.globals.getNavigationUrl("empty") +
                                        _vm.formattedEntries[item.productCode]
                                          .product.url
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        alt: "no image",
                                        src:
                                          _vm.globals.assetsPath +
                                          "images/no_image.svg"
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("template", { slot: "details" }, [
                            _c("p", { staticClass: "product-title mb-xs-2" }, [
                              _c("a", {
                                attrs: {
                                  href:
                                    _vm.globals.getNavigationUrl("empty") +
                                    _vm.formattedEntries[item.productCode]
                                      .product.url
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.formattedEntries[item.productCode]
                                      .product.name
                                  )
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.i18n.shippingAddress.itemID) +
                                  " : " +
                                  _vm._s(
                                    _vm.formattedEntries[item.productCode]
                                      .product.code
                                  ) +
                                  "\n          "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "product-code mb-xs-2",
                                attrs: { "aria-hidden": "true" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.i18n.shippingAddress.itemID) +
                                    "\n            " +
                                    _vm._s(
                                      _vm.formattedEntries[item.productCode]
                                        .product.code
                                    ) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "restriction-error" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.i18n.shippingAddress
                                      .restrictionErrorPart1
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.country.isocode) +
                                    "/" +
                                    _vm._s(item.region.isocode)
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.i18n.shippingAddress
                                      .restrictionErrorPart3
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "customer-service" },
                                [
                                  _vm.globals.isB2B()
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "https://gpxpr.es/",
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.shippingAddress
                                                    .restrictionErrorPart4
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    : [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.contactUsUrl,
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.shippingAddress
                                                    .restrictionErrorPart4
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.i18n.shippingAddress
                                      .restrictionErrorPart5
                                  )
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "bottom-full-slot" })
                        ],
                        2
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b0792dd4", { render: render, staticRenderFns: staticRenderFns })
  }
}