import globals from '../../common/globals';
import cookiesMixin from '../../common/mixins/cookies-mixin';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import {
  cartEventBus,

} from '../../../modules/event-bus';
import {
  flyoutStatus,
} from '../../common/mixins/vx-enums';
import ManageShoppingCartService from '../../common/services/manage-shopping-cart-service';
import flyoutBannerMixin from '../../common/vx-flyout-banner/vx-flyout-banner-mixin';

export default {
  name: 'vx-cart-guid',
  mixins: [cookiesMixin, flyoutBannerMixin],
  components: {
    vxSpinner,
  },
  props: [],
  data() {
    return {
      globals,
      guid: 'guid',
      guid_user: 'guid_user',
      events: ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'],
      userTimeInterval: '',
      idleTime: 0,
      cartInactiveTime: 30,
      manageShoppingCartService: new ManageShoppingCartService(),
      restrictBasicCartPages: ['cartPage', 'checkoutPage'],
    };
  },
  computed: {

  },
  mounted() {
    cartEventBus.$on('call-basic-cart', () => {
      this.getBasicCart();
    });
    this.conditionCheck();
    this.idleTimeHandler();
  },
  methods: {
    /**
     * This function checks whether user is logged in or not
     */
    conditionCheck() {
      // When user is loggedin
      if (this.globals.getIsLoggedIn()) {
        this.loggedInHandler();
      } else {
        this.loggedOutHandler();
      }
    },
    /**
     * This function creates cart for logged in user
     */
    loggedInHandler() {
      if (this.readCookie(this.guid_user) === 'anonymous' && !this.readCookie('asmCartId')) {
        const anonymousGuid = this.readCookie(this.guid);
        this.eraseCookie(this.guid);
        this.eraseCookie(this.guid_user);
        // Pass the Old guid for merge cart call
        this.callCreateCart(anonymousGuid);
      } else {
        this.getBasicCart();
      }
    },
    /**
     *This function creates cart for logged out user
     */
    loggedOutHandler() {
      if (!this.readCookie(this.guid)) {
        this.callCreateCart();
      } else if (!(this.globals.currentPage === 'checkoutPage' && this.globals.asmLoggedin && this.globals.uid === 'asm_anonymous')) {
        // placing the below condition to disable the cart call in checkout page in asm mode ---ycom-12986
        this.getBasicCart();
      }
    },
    /**
     *This function gets the cart details
     */
    getBasicCart() {
      if (this.restrictBasicCartPages.indexOf(this.globals.currentPage) >= 0 && this.globals.getIsLoggedIn()) {
        this.$root.$data.cartExists = true;
        return;
      }
      const requestConfig = {};
      this.$refs.spinner.showSpinner();
      this.manageShoppingCartService.getBasicCartService(requestConfig, this.handleBasicCartResponse, this.handleBasicCartError);
    },
    /**
     *This function handles the repsonse of cart details call
     */
    handleBasicCartResponse(response) {
      this.$refs.spinner.hideSpinner();
      this.$root.$data.cartExists = true;
      cartEventBus.$emit('total-items-updated', response.data.totalItems, response.data.code);
    },
    /**
     *This function handles the error of cart details call
     */
    handleBasicCartError() {
      this.$refs.spinner.hideSpinner();
      if (!this.globals.getIsLoggedIn()) {
        this.callCreateCart();
      }
    },
    /**
     *This function creates cart
     * @param  {String} anonymousGuid cart id for anonymous user
     */
    callCreateCart(anonymousGuid) {
      const requestConfig = {
        params: {
          fields: 'CART_CREATE',
        },
      };
      if (anonymousGuid) {
        requestConfig.params.oldCartId = anonymousGuid;
      }
      this.$refs.spinner.showSpinner();
      this.manageShoppingCartService.createCartService(requestConfig, this.handleCreateCartResponse, this.handleCreateCartError);
    },
    /**
     *This function handles the response for create cart call
     */
    handleCreateCartResponse(response) {
      this.$refs.spinner.hideSpinner();
      this.$root.$data.cartExists = true;
      cartEventBus.$emit('total-items-updated', response.data.totalItems, response.data.code);
      cartEventBus.$emit('cart-update');
      this.createCookie(this.guid, response.data.guid);
      if (this.globals.getIsLoggedIn()) {
        this.createCookie(this.guid_user, 'current');
      } else {
        this.createCookie(this.guid_user, 'anonymous');
      }
    },
    /**
     *This function handles the error for create cart call
     */
    handleCreateCartError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     *This function deletes cart after 30 min of time for guest user
     */
    idleTimeHandler() {
      if (!globals.getIsLoggedIn()) {
        this.startTimer();
        for (let i = 0; i < this.events.length; i += 1) {
          document.addEventListener(this.events[i], this.handleEvents.bind(this));
        }
      }
    },
    handleEvents() {
      this.idleTime = 0;
    },
    /**
     *This function stops the timer and calls the delete cart method
     */
    stopTimer() {
      clearInterval(this.userTimeInterval);
      this.userTimeInterval = '';
      this.idleTime = 0;
      this.$refs.spinner.showSpinner();
      // this.manageShoppingCartService.deleteCartService(requestConfig, this.handleDeleteCartResponse, this.handleDeleteCartError);
      this.manageShoppingCartService.getDefaultCart({}, this.handleKeepAliveCartResponse, this.handleKeepAliveCartError);
    },
    /**
  * This function handles the response of keep alive cart details call
  */
    handleKeepAliveCartResponse() {
      if (this.$refs.spinner) {
        this.$refs.spinner.hideSpinner();
      }
    },
    /**
     * This function handles the error of keep alive cart details call
     */
    handleKeepAliveCartError() {
      if (this.$refs.spinner) {
        this.$refs.spinner.hideSpinner();
      }
      if (globals.currentPage === 'checkoutPage' || globals.currentPage === 'cartPage') {
        this.showFlyoutNextPage(
          flyoutStatus.error,
          'We apologize for the inconvience, your session has expired and your cart was cleared. Please try again.',
          false,
        );
        this.globals.navigateToUrl('home');
      } else {
        this.globals.navigateToUrl('home');
      }
    },
    /**
     *This function handles the response of stopping the timer and calling the delete cart method
     */

    handleDeleteCartResponse() {
      this.eraseCookie(this.guid);
      this.eraseCookie(this.guid_user);
      this.$refs.spinner.hideSpinner();
    },
    /**
     *This function handles the response of stopping the timer and calling the delete cart method
     */
    handleDeleteCartError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     *This function starts the timer
     */
    startTimer() {
      const self = this;
      this.userTimeInterval = setInterval(() => {
        self.idleTime += 1;
        if (self.idleTime > self.cartInactiveTime) {
          self.stopTimer();
        }
      }, 60000);
    },

  },
};
