var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main__inner-wrapper" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-3" },
          [
            _c("vx-left-nav", {
              attrs: {
                leftNavData: _vm.leftNavData,
                i18n: _vm.messages.manageProfileShoppingLists.leftNav
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b3779f3e", { render: render, staticRenderFns: staticRenderFns })
  }
}