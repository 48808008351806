import find from 'lodash.find';
import debounce from 'lodash.debounce';
import vxStepperControl from '../../../../components/common/vx-stepper-control/vx-stepper-control.vue';
import globals from '../../../../components/common/globals';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import { cartEventBus, globalEventBus } from '../../../../modules/event-bus';
import vxAddInstallation from '../../../../components/manage-shopping-cart/vx-add-installation/vx-add-installation.vue';
import vxRemoveInstallation from '../../../../components/manage-shopping-cart/vx-remove-installation/vx-remove-installation.vue';
import vxInstallationDetails from '../../../../components/manage-shopping-cart/vx-installation-details/vx-installation-details.vue';
import vxGiftingDetails from '../../../../components/manage-shopping-cart/vx-gifting-details/vx-gifting-details.vue';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import AnalyticsService from '../../../../components/common/services/analytics-service';
import ManageShoppingCartService from '../../../../components/common/services/manage-shopping-cart-service';
import vxProductIcons from '../../common/vx-product-icons/vx-product-icons.vue';
import ProductMixin from '../../../../components/common/mixins/product-mixin';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import {
  ProductAvailability,
  discount,
  flyoutStatus,
} from '../../../../components/common/mixins/vx-enums';

export default {
  name: 'vx-cart-product-tile',
  mixins: [flyoutBannerMixin, ProductMixin, mobileMixin],
  components: {
    vxStepperControl,
    vxSpinner,
    vxAddInstallation,
    vxRemoveInstallation,
    vxInstallationDetails,
    vxModal,
    vxGiftingDetails,
    vxProductIcons,
  },
  props: {
    // Data of individual product in the cart
    productData: Object,
    // Text coming from property file
    i18n: Object,
    // Indicates if this module is to be used for mini cart
    isMiniCart: Boolean,
    // Promotion Data
    promotionData: Object,
    // Indicates if there is Lease error
    isB2bLease: Boolean,
    // Installation price data
    installationPrice: Object,
    // Indicates if the cart is Sample cart
    isSampleCart: {
      type: Boolean,
      default: false,
    },
    // Indicates if user can remove the product from cart
    isProductRemovable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      globals,
      analyticsService: new AnalyticsService(),
      giftable: {
        isGiftOpted: false,
        isGiftable: false,
        code: '',
      },
      installable: {
        isInstallable: false,
        isInstalled: false,
        code: '',
        parentProductEntry: this.productData.entryNumber,
        parentProductQuantity: this.productData.quantity,
        parentProductCode: this.productData.product.code,
        parentProductPrice: this.productData.totalPrice.value,
        parentProductName: this.productData.product.name,
      },
      isLeasable: false,
      manageShoppingCartService: new ManageShoppingCartService(),
      ProductAvailability,
      delayInterval: 300,
      discount,
      lowStockEntryData: [],
      flyoutStatus,
      productIconsData: [],
    };
  },
  computed: {
    /**
         * This function shows lease error
         */
    leaseableError() {
      let leaseError = false;
      if (this.globals.isB2B() && this.globals.getIsLoggedIn()) {
        if (this.productData.leasable && !this.isB2bLease) {
          leaseError = true;
        } else {
          leaseError = false;
        }
      }
      return leaseError;
    },
  },
  watch: {
    'productData.quantity': function(newVal) {
      this.installable.parentProductQuantity = newVal;
    },
  },
  created() {
    const self = this;
    /**
         * This function handles on adding quantity manually, debounce will send request once user finished typing.
         */
    self.handleQuantity = debounce((itemNumber, itemQuantity) => {
      // sending the data to Google Analytics on quantity update
      if (this.productData.quantity !== itemQuantity) {
        const analyticsObject = {
          code: this.productData.product.code,
          name: this.productData.product.name,
          quantity: itemQuantity,
          price: this.productData.basePrice.value,
          currencyCode: this.productData.basePrice.currencyIso,
        };
        this.analyticsService.trackRemoveFromCart(analyticsObject);
        if (this.globals.siteId === 'gppro') {
          this.analyticsService.trackRemoveFromCartGA4(analyticsObject);
        }
        this.analyticsService.trackAddToCart(analyticsObject);
        if (this.globals.siteId === 'gppro') {
          this.analyticsService.trackAddToCartGA4(analyticsObject);
        }
        // this.trackAddToCart(analyticsObject);
      }
      if (itemQuantity === 0) {
        this.deleteCartItem(itemNumber);
      } else {
        const requestConfig = {};
        requestConfig.data = {
          quantity: itemQuantity,
          installed: this.installable.isInstalled,
        };
        this.manageShoppingCartService.editCartItem(
          requestConfig,
          this.handleEditCartResponse,
          this.handleEditCartError,
          itemNumber,
        );
        this.$refs.spinner.showSpinner();
      }
    }, self.delayInterval);
  },
  mounted() {
    if (!this.isMiniCart) {
      this.checkAdditionalAttributes();
      // if (this.globals.isB2B()) {
      //   this.checkLeasable(this.productData);
      // }
    }
    this.productIconsData = this.productData.product.productIcons;
  },
  methods: {
    /**
         * This function deletes an item from cart
         * @param  {Number} itemNumber product entryNumber
         */
    deleteCartItem(itemNumber) {
      // sending the data to Google Analytics on delete icon click
      const analyticsObject = {
        code: this.productData.product.code,
        name: this.productData.product.name,
        quantity: this.productData.quantity,
        price: this.productData.basePrice.value,
        currencyCode: this.productData.basePrice.currencyIso,
      };
      this.analyticsService.trackRemoveFromCart(analyticsObject);
      if (this.globals.siteId === 'gppro') {
        this.analyticsService.trackRemoveFromCartGA4(analyticsObject);
      }
      this.manageShoppingCartService.deleteCartItem(
        {},
        this.handleDeleteCartItemResponse,
        this.handleDeleteCartItemError,
        itemNumber,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
         * This function handles the response of delete an item from cart
         */
    handleDeleteCartItemResponse() {
      cartEventBus.$emit('cart-update');
      globalEventBus.$emit('announce', 'product deleted');
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function handles the error of delete an item from cart
         */
    handleDeleteCartItemError() {
      // this.$emit('errorDelete'); can show flyout
      cartEventBus.$emit('cart-update-error');
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function edits an item from cart
         */
    handleQuantity() {
      // dummy function which will invoke inside created().
    },
    /**
         * This function handles the error of editing an item from cart
         */
    handleEditCartError() {
      cartEventBus.$emit('cart-update-error');
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function handles the response of editing an item from cart
         */
    handleEditCartResponse() {
      cartEventBus.$emit('cart-update');
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function opens add installation modal
         */
    openAddInstallationModal(event) {
      this.$refs.addInstallationModal.open(event);
    },
    /**
         * This function handles the success of addition of installation from the modal
         */
    handleAddInstallatioSuccess() {
      this.$refs.addInstallationModal.close();
      this.installable.isInstalled = true;
      globalEventBus.$emit('announce', 'Installation Service Added');
      this.showFlyout(
        this.flyoutStatus.success,
        `${this.installable.parentProductQuantity} ${
          this.i18n.addToCartSuccessMessage
        }`,
        true,
        Number(this.globals.siteConfig.bannerTimer),
      );
    },
    /**
         * This function handles the error of addition of installation from the modal
         */
    handleAddInstallatioError() {
    },
    /**
         * This function opens installation details modal
         */
    openInstallationDetailsModal(event) {
      this.$refs.installationDetailsModal.open(event);
    },
    /**
         * This function opens remove installation modal
         */
    openRemoveInstallationModal() {
      this.$refs.removeInstallationModal.open();
    },
    /**
         * This function removes installation if added
         */
    removeInstallation() {
      const requestConfig = {};
      requestConfig.data = {
        quantity: this.productData.quantity,
        additionalAttributes: {
          entry: [
            {
              key: 'installed',
              value: 'false',
            },
          ],
        },
      };
      this.manageShoppingCartService.removeInstallation(
        requestConfig,
        this.handleRemoveInstallationResponse,
        this.handleRemoveInstallationError,
        this.productData.entryNumber,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
         * This function handles the response of removal of installation if added
         */
    handleRemoveInstallationResponse() {
      cartEventBus.$emit('cart-update');
      this.$refs.removeInstallationModal.close();
      this.$refs.spinner.hideSpinner();
      this.installable.isInstalled = false;
    },
    /**
         * This function handles the error of removal of installation if added
         */
    handleRemoveInstallationError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function opens gifting details modal
         */
    openGiftModal(event) {
      this.$refs.addGiftModal.open(event);
    },
    /**
         * This function handles addition and removal of gifting option
         */
    editGift() {
      const requestConfig = {};
      requestConfig.data = {
        quantity: this.productData.quantity,
        additionalAttributes: {
          entry: [
            {
              key: 'giftOpted',
              value: this.giftable.isGiftOpted.toString(),
            },
          ],
        },
      };
      this.manageShoppingCartService.editGift(
        requestConfig,
        this.handleEditGiftResponse,
        this.handleEditGiftError,
        this.productData.entryNumber,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
         * This function handles the response of addition and removal of gifting option
         */
    handleEditGiftResponse() {
      cartEventBus.$emit('cart-update');
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function handles the error of addition and removal of gifting option
         */
    handleEditGiftError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function checks installation and gifting options
         */
    checkAdditionalAttributes() {
      if (this.productData.additionalAttributes) {
        // check if the product is already opted for installation
        if (this.globals.isB2B()) {
          this.checkInstallation(this.productData.additionalAttributes);
        }
        if (this.globals.isB2C()) {
          this.checkGiftable(this.productData.additionalAttributes);
        }
      }
    },
    /**
         * This function checks gifting options
         * @param  {Object} additionalAttributes contains data of the product
         */
    checkGiftable(additionalAttributes) {
      const { entry } = additionalAttributes;
      // check if we need to show gifting property
      if (this.getKey(entry, 'giftableProduct')) {
        this.giftable.code = this.getKey(entry, 'giftableProduct').value;
        this.giftable.isGiftable = true;
        if (this.getKey(entry, 'giftOpted')) {
          this.giftable.isGiftOpted = JSON.parse(this.getKey(entry, 'giftOpted').value) || false;
        }
      }
    },
    /**
         * This function checks installational options
         * @param  {Object} additionalAttributes contains data of the product
         */
    checkInstallation(additionalAttributes) {
      const { entry } = additionalAttributes;
      // check if we need to show the user installation property
      if (this.getKey(entry, 'installableProductCode')) {
        this.installable.code = this.getKey(
          entry,
          'installableProductCode',
        ).value;
        this.installable.isInstallable = true;
        if (this.getKey(entry, 'installed')) {
          this.installable.isInstalled = JSON.parse(
            this.getKey(entry, 'installed').value,
          );
        }
      }
    },
    // check if we need to show the user leasable property
    // checkLeasable(product) {
    //   if (product.leasable) {
    //     this.isLeasable = true;
    //   }
    // },
    /**
         * This function finds a prticular key from an array
         * @param  {Array} entry product entry
         * @param  {String} keyCode key to be searched in the array
         */
    getKey(entry, keyCode) {
      return find(entry, {
        key: keyCode,
      });
    },
    /**
         * This function shows error message when user enters more than max quantity in input box
         */
    maxValueUpdated() {
      this.showFlyout(
        this.flyoutStatus.error,
        this.i18n.maxValueUpdatedStatus,
        true,
      );
    },
    /**
         * This function checks give away product for max order quantity
         * @param  {Number} maxValue max orderable value of the product
         * @param  {Number} qty quantity of the product
         * @param  {Boolean} isgiveAway indicates if product is giveaway
         */
    maxOrderQuantity(maxValue, qty, isgiveAway) {
      if (isgiveAway) {
        return qty;
      }
      return maxValue;
    },
    /**
         * This function shows error message when user enters less than min quantity in input box
         */
    minValueUpdated() {
      this.showFlyout(
        this.flyoutStatus.error,
        this.i18n.minValueUpdatedStatus,
        true,
      );
    },
    /**
         * This function checks give away product for min order quantity
         * @param  {Number} minValue min orderable value of the product
         * @param  {Number} qty quantity of the product
         * @param  {Boolean} isgiveAway indicates if product is giveaway
         */
    minOrderQuantity(minValue, qty, isgiveAway) {
      if (isgiveAway) {
        return qty;
      }
      return minValue;
    },
    /**
         * This function returns stock of the product
         * @param  {Number} stockLevel stock of the product
         */
    setStockLevel(stockLevel) {
      return stockLevel;
    },
    /**
         * This function shows stock level update message
         */
    stockLevelUpdated() {
      this.showFlyout(
        this.flyoutStatus.error,
        this.i18n.stockLevelUpdatedStatus,
        true,
      );
    },
  },
};
