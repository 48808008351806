/**
 * vx-store-search is used to
 * perform search on find a store.
 */
import vxDropdownPrimary from '../../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import globals from '../../../../../components/common/globals';
import detectDeviceMixin from '../../../../../components/common/mixins/detect-device-mixin';
import googleMapsMixin from '../../../../../components/common/mixins/google-maps-mixin';
import vxModal from '../../../../../components/common/vx-modal/vx-modal.vue';
import flyoutBannerMixin from '../../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import mobileMixin from '../../../../../components/common/mixins/mobile-mixin';
import { flyoutStatus } from '../../../../../components/common/mixins/vx-enums';
import ProductMixin from '../../../../../components/common/mixins/product-mixin';
import vxAutoSuggestProducts from '../../../../../components/search-browse/vx-auto-suggest-products/vx-auto-suggest-products';

export default {
  name: 'vx-store-search',
  components: {
    vxDropdownPrimary,
    vxModal,
    vxAutoSuggestProducts,
  },
  props: {
    i18n: {
      type: Object,
      required: true,
    },
    prdInfo: {
      type: Object,
    },
    isFromPdp: {
      type: Boolean,
      default: false,
    },
    permissionDenied: {
      type: Boolean,
      default: false,
    },
    resetFilter: {
      type: Boolean,
      default: false,
      required: true,
    },
    productList: {
      type: Array,
      default: [],
    },
    listPageUrl: {
      type: String,
      default: '',
    },
    showSearchError: {
      type: Boolean,
      default: false,
    },
    isLinkShown: {
      type: Boolean,
      default: false,
    },
    industriesList: {
      type: Array,
      default: [],
    },
  },
  mixins: [
    ProductMixin,
    detectDeviceMixin,
    googleMapsMixin,
    flyoutBannerMixin,
    mobileMixin,
  ],
  data() {
    return {
      globals,
      storesError: '',
      pincode: '',
      showProductDropdown: false,
      distanceList: [
        {
          label: '50 miles',
          value: '50',
        },
        {
          label: '100 miles',
          value: '100',
        },
        {
          label: '200 miles',
          value: '200',
        },
        {
          label: '500 miles',
          value: '500',
        },
      ],
      isDisabled: false,
      isDisabledStock: false,
      showStockProducts: true,
      selectedWishlist: {},
      getProductDistributors: true,
      flyoutStatus,
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit() {
      /* Commenting this since we are hardcoding categories for now
            this.$refs.category.requestConfig.params = { fields: this.i18n.custom };
            this.$refs.category.generateRequest(); */
      if (this.$refs.distanceDropdown) {
        this.$refs.distanceDropdown.setDropdownLabel(
          this.i18n.distanceDropdownLabel,
        );
      }
      if (this.$refs.productListdrp) {
        this.$refs.productListdrp.setDropdownLabel(
          this.i18n.productListDropDownLabel,
        );
      }
      if (!globals.getIsLoggedIn()) {
        this.isDisabled = true;
      }
      if (!this.isFromPdp) {
        this.isDisabledStock = true;
        this.showStockProducts = false;
      }
      // this.$refs.industryDropdown.setDropdownLabel(this.industriesList[0].label);
    },
    /**
         * created the dropdown list with the item param passed
         * @param {object} item
         * @return {object} object
         */
    createDropdownList(item) {
      return {
        label: item.group_name,
        value: item.group_id,
      };
    },
    /**
         * update radius emits the event with
         *  the evt parameter as payload to the event emitted
         * @param {string} evt
         */
    updateRadius(evt) {
      this.$emit('radiusUpdate', evt);
    },
    /**
         * get distributors emits the event 'getDistributors with pincode as payload
         */
    getDistributors() {
      if (this.pincode) {
        // this.locationTextErr = false;
        this.$emit('getDistributors', this.pincode);
      } else {
        // this.locationTextErr = true;
        this.showFlyout(
          this.flyoutStatus.error,
          `${this.i18n.locationTextError}`,
          false,
        );
      }
    },
    /**
         * get location emits event with boolean true as payload
         */
    getLocation() {
      this.$emit('getLocation', true);
    },
    /**
         * checks if the stock is available
         * @param {object} event
         * @return {object} null
         */
    checkStock(event) {
      if (!event.target.checked && this.$refs.productListdrp) {
        if (Object.keys(this.selectedWishlist).length > 0) {
          this.$emit('noListSelected', {});
        }
      }
      this.getProductDistributors = !this.getProductDistributors;
      if (
        !this.isFromPdp
                && Object.keys(this.selectedWishlist).length > 0
                && this.selectedWishlist.value !== ''
                && this.getProductDistributors
      ) {
        this.$emit('listSelected', this.selectedWishlist);
      } else if (this.isFromPdp) {
        this.$emit('prodcheckedPDP', this.getProductDistributors);
      }
    },
    /**
         * triggers when the list is selected
         * @param {object} list
         * @return {object} null
         */
    selectedList(list) {
      if (!list.value) {
        this.$refs.productListdrp.setDropdownLabel(
          this.i18n.productListDropDownLabel,
        );
      } else {
        if (!this.selectedWishlist.label) {
          this.showStockProducts = true;
          this.isDisabledStock = false;
        }
        this.selectedWishlist = list;
        if (this.getProductDistributors) {
          this.$emit('listSelected', this.selectedWishlist);
        }
      }
    },
    updateIndustries(evt) {
      this.$emit('industryUpdate', evt);
    },
    handleFoundProductsToList(productCodes) {
      this.emitAddProductsToList(productCodes);
    },

    emitAddProductsToList(productCodes) {
      this.$emit('addProductsToList', productCodes);
      this.closeAddProductToList();
    },
    /**
     * This function emits an event to close the popup. It gets handled in vx-list-details.
     */
    closeAddProductToList() {
      this.$emit('closeAddProductToList');
    },
    handleductSelection(productSku) {
      this.$emit('selectedSku', productSku);
      this.isDisabledStock = false;
      this.showStockProducts = true;
    },
  },
  watch: {
    resetFilter() {
      this.pincode = '';
      this.$refs.distanceDropdown.setDropdownLabel(this.i18n.distanceDropdownLabel);
    },
  },
};
