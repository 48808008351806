import AnalyticsService from '../services/analytics-service';

const checkoutTrackingMixin = {
  data() {
    return {
      queryString: {},
      analyticsService: new AnalyticsService(),
    };
  },

  methods: {
    sendEventToDataLayer(event, step, option, entries) {
      const ecommerce = {
        checkout: {
          actionField: {
            step,
            option,
          },
        },
      };
      if (entries && entries.length) {
        ecommerce.checkout.products = this.createProductsObj(entries);
      }
      this.analyticsService.trackMarketingFormPost({
        event,
        ecommerce,
      });
    },
    createProductsObj(entries) {
      const productsArr = [];
      const productList = entries;
      for (let i = 0; i < productList.length; i += 1) {
        const product = {};
        product.name = productList[i].product.name;
        product.id = productList[i].product.code;
        product.price = productList[i].basePrice.value;
        product.brand = productList[i].product.subbrand;
        product.quantity = productList[i].quantity;
        product.coupon = productList[i].slashPriceValue;
        productsArr.push(product);
      }
      return productsArr;
    },
  },
};

export default checkoutTrackingMixin;
