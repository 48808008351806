/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/**
 * vx-hero-banner is used to author CMS hero component where an image,
 *  text and video SRC can be authored.
 */
import { globalEventBus } from '../../../modules/event-bus';
import vxVideoPlayer from '../../common/vx-video-player/vx-video-player.vue';
import mobileMixin from '../../common/mixins/mobile-mixin';
import { backgroundColor, raysImages } from '../../common/mixins/vx-enums';
import globals from '../../common/globals';
import viewSiteContent from '../../../locale/view-site-content-i18n';
import vxPriceSpider from '../vx-price-spider/vx-price-spider-shell';

export default {
  name: 'vx-hero-banner-responsive',
  components: {
    vxVideoPlayer, vxPriceSpider,
  },
  mixins: [mobileMixin],
  props: {
    heroBannerData: {
      type: Object,
      required: true,
    },
    isCategoriesBanner: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      backgroundColor,
      raysImages,
      globals,
      i18n: viewSiteContent.heroBanner,
      isPriceSpider: false,
      psSKU: '',
    };
  },
  computed: {
    /**
     * used to set the background color for the banner
     * @return {object} object with background color as key
     */
    backgroundStyle() {
      return !this.heroBannerData.subHeadBgColor
        || this.heroBannerData.subHeadBgColor === ''
        ? {
          'background-color': this.backgroundColor.transparent,
        }
        : {
          'background-color': this.heroBannerData.subHeadBgColor,
        };
    },
    /**
     * returns the alt text of the background
     */
    backgroundAlt() {
      return this.getResponsiveAlt(
        this.heroBannerData.backgroundImageAltTextD,
        this.heroBannerData.backgroundImageAltTextT,
        this.heroBannerData.backgroundImageAltTextM,
      );
    },
  },
  created() {},
  async mounted() {
    if (this.isCategoriesBanner) {
      this.heroBannerData = this.globals.catergoryBannerData;
    }
    if (this.heroBannerData.imageLink && this.heroBannerData.imageLink.indexOf('price-spider') > -1) {
      this.isPriceSpider = true;
      this.heroBannerData.imageLink.split(' ').forEach((element) => { if (element.indexOf('price-spider') > -1) { this.psSKU = element.split('-')[2]; } });
    }
  },
  methods: {
    /**
     * retuns position of matched string
     */
    hasRaysImage() {
      if (this.heroBannerData.componentTheme) {
        return (this.heroBannerData.componentTheme.indexOf(this.raysImages.calssname) !== -1);
      }
      return false;
    },
    /**
     * opens the video modal
     */
    openModal() {
      const videoSRC = this.heroBannerData.videoSrc;
      this.$refs.videoPlayer.openPlayer(videoSRC);
    },
    /**
     * emits the scroll-to event with link as payload
     * @param {string} link
     * @return {object} null
     */
    emitScrollSignal(link) {
      globalEventBus.$emit('scroll-to', link);
    },
    clickPriceSpiderBtn() {
      this.$el.querySelector('.ps-widget').click();
    },
    // Method to strip HTML tags while dynamically setting the aria-label on links
    stripHtmlTags(ariaLabel) {
      if (ariaLabel) {
        return ariaLabel.replace(/(<([^>]+)>)/gi, '');
      }
      return undefined;
    },
  },
};
