var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row vx-profile-card",
      class: _vm.cardType === "add" ? "d-flex" : ""
    },
    [
      _vm.cardType === "social"
        ? _c(
            "div",
            {
              staticClass: "col-xs-12 social-card",
              class: [
                { "card-default-height": _vm.isDefaultHeight },
                _vm.cardClasses ? _vm.cardClasses : ""
              ]
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between card-heading"
                },
                [
                  _vm._t("heading", [
                    _c("h4", [_vm._v("Social Accounts")]),
                    _vm._v(" "),
                    _vm._m(0)
                  ])
                ],
                2
              ),
              _vm._v(" "),
              _vm._t("details", [_vm._m(1), _vm._v(" "), _vm._m(2)])
            ],
            2
          )
        : _vm.cardType === "add"
        ? _c(
            "div",
            {
              staticClass: "col-xs-12 add-card",
              class: [
                { "card-default-height": _vm.isDefaultHeight },
                _vm.cardClasses ? _vm.cardClasses : ""
              ]
            },
            [
              _c(
                "p",
                { staticClass: "add-card-container" },
                [_vm._t("details", [_vm._m(3)])],
                2
              )
            ]
          )
        : _vm.cardType === "column"
        ? _c(
            "div",
            {
              staticClass: "col-xs-12 profile-card",
              class: [
                {
                  disabled: _vm.isDisabled,
                  "card-default-height": _vm.isDefaultHeight
                },
                _vm.cardClasses ? _vm.cardClasses : ""
              ]
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "card-details-container",
                    class: _vm.hasIcons ? "col-xs-10" : "col-xs-12"
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "ul",
                        { staticClass: "col-xs-12" },
                        [
                          _vm._t("heading", [_vm._m(4), _vm._v(" "), _vm._m(5)])
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [_vm._t("details", [_vm._m(6), _vm._v(" "), _vm._m(7)])],
                      2
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-2" }, [
                  _c("div", { staticClass: "row" }, [
                    _vm.hasIcons
                      ? _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isDisabled,
                                expression: "!isDisabled"
                              }
                            ],
                            staticClass: "col-xs-12 vx-profile-card-icons"
                          },
                          [
                            _vm._t("icons", [_vm._m(8), _vm._v(" "), _vm._m(9)])
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm.hasFooter
                ? _c(
                    "div",
                    { staticClass: "row card-footer" },
                    [
                      _vm._t("footer", [
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isDisabled,
                                expression: "isDisabled"
                              }
                            ],
                            staticClass: "card-item-link col-xs-12"
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "link-regular link-bold pull-right",
                                attrs: { href: "#" }
                              },
                              [_vm._v("Enable")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isDisabled && _vm.isDefault,
                                expression: "isDisabled && isDefault"
                              }
                            ],
                            staticClass:
                              "d-flex col-xs-12 align-items-center justify-content-between"
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "card-item card-item-bold" },
                              [_vm._v("Default Address")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "link-regular link-bold",
                                attrs: { href: "#" }
                              },
                              [_vm._v("Enable")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isDisabled,
                                expression: "!isDisabled"
                              }
                            ],
                            staticClass:
                              "d-flex col-xs-12 align-items-center justify-content-between"
                          },
                          [
                            _c("button", { staticClass: "btn btn-secondary" }, [
                              _vm._v("Reject")
                            ]),
                            _vm._v(" "),
                            _c("button", { staticClass: "btn btn-primary" }, [
                              _vm._v("Approve")
                            ])
                          ]
                        )
                      ])
                    ],
                    2
                  )
                : _vm._e()
            ]
          )
        : _c(
            "div",
            {
              staticClass: "col-xs-12 profile-card",
              class: [
                {
                  disabled: _vm.isDisabled,
                  "card-default-height": _vm.isDefaultHeight
                },
                _vm.cardClasses ? _vm.cardClasses : ""
              ]
            },
            [
              _c("div", { staticClass: "row d-flex" }, [
                _c(
                  "ul",
                  {
                    staticClass: "card-details-container",
                    class: _vm.hasIcons ? "col-xs-10" : "col-xs-12"
                  },
                  [
                    _vm._t("heading", [_vm._m(10), _vm._v(" "), _vm._m(11)]),
                    _vm._v(" "),
                    _vm._t("details", [
                      _c("li", { staticClass: "card-item" }, [
                        _vm._v("Peter Williams")
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "card-item" }, [
                        _vm._v("peter.williams@email.com")
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "card-item" }, [
                        _vm._v("123-456-7890")
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "card-item" }, [
                        _vm._v(
                          "You're currently enrolled in email marketing, SMS marketing & SMS order tracking"
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "card-item card-item-bold" }, [
                        _vm._v("Default Address")
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "card-item card-item-bold" }, [
                        _vm._v("Default Address")
                      ]),
                      _vm._v(" "),
                      _vm._m(12),
                      _vm._v(" "),
                      _vm._m(13)
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.hasIcons
                  ? _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isDisabled || _vm.showTrash,
                            expression: "!isDisabled || showTrash"
                          }
                        ],
                        staticClass: "col-xs-2 vx-profile-card-icons",
                        attrs: { role: "presentation" }
                      },
                      [_vm._t("icons", [_vm._m(14), _vm._v(" "), _vm._m(15)])],
                      2
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.hasFooter
                ? _c(
                    "div",
                    { staticClass: "row card-footer" },
                    [
                      _vm._t("footer", [
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isDisabled,
                                expression: "isDisabled"
                              }
                            ],
                            staticClass: "card-item-link col-xs-12"
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "link-regular link-bold pull-right",
                                attrs: { href: "javascript:void(0)" },
                                on: { click: _vm.enableAddress }
                              },
                              [_vm._v("Enable")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isDisabled && _vm.isDefault,
                                expression: "isDisabled && isDefault"
                              }
                            ],
                            staticClass:
                              "d-flex col-xs-12 align-items-center justify-content-between"
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "card-item card-item-bold" },
                              [_vm._v("Default Address")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "link-regular link-bold",
                                attrs: { href: "javascript:void(0)" }
                              },
                              [_vm._v("Enable")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isEnabled,
                                expression: "isEnabled"
                              }
                            ],
                            staticClass: "col-xs-12"
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "link-regular link-bold pull-right",
                                attrs: { href: "javascript:void(0)" },
                                on: { click: _vm.disableAddress }
                              },
                              [_vm._v("Disable")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isDisabled && !_vm.isEnabled,
                                expression: "!isDisabled && !isEnabled"
                              }
                            ],
                            staticClass:
                              "d-flex col-xs-12 align-items-center justify-content-between"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary col-xs-6 mr-sm-5 mr-md-5 mr-xs-5",
                                on: { click: _vm.rejectAddress }
                              },
                              [_vm._v("Reject")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary col-xs-6",
                                on: { click: _vm.approveAddress }
                              },
                              [_vm._v("Approve")]
                            )
                          ]
                        )
                      ])
                    ],
                    2
                  )
                : _vm._e()
            ]
          )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [
      _c("span", {
        staticClass: "icon-default icon-big icon-edit",
        attrs: { "aria-label": "edit icon", tabindex: "0", role: "button" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row d-flex align-items-center justify-content-between" },
      [
        _c("div", { staticClass: "card-items align-items-center d-flex" }, [
          _c("span", { staticClass: "icon-default icon-big icon-facebook" }),
          _vm._v(" "),
          _c("span", { staticClass: "pl-xs-2" }, [_vm._v("Facebook")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-item-link" }, [
          _c(
            "a",
            {
              staticClass: "link-regular link-bold",
              attrs: { href: "#", tabindex: "0" }
            },
            [_vm._v("Connect")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row d-flex align-items-center justify-content-between" },
      [
        _c("div", { staticClass: "card-items align-items-center d-flex" }, [
          _c("span", { staticClass: "icon-default icon-big icon-google" }),
          _vm._v(" "),
          _c("span", { staticClass: "pl-xs-2" }, [_vm._v("Google")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-item-link" }, [
          _c(
            "a",
            {
              staticClass: "link-regular link-bold",
              attrs: { href: "#", tabindex: "0" }
            },
            [_vm._v("Connect")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", {
        staticClass: "icon-big icon-plus",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "ml-xs-1 link-large link-bold" }, [
        _vm._v("Add")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "card-heading" }, [
      _c("h4", [_vm._v("Personal Details")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "card-heading-link" }, [
      _c("a", { staticClass: "#" }, [_c("h4", [_vm._v("Personal Details")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "col-xs-6" }, [
      _c("li", { staticClass: "card-item" }, [_vm._v("Peter Williams")]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item" }, [
        _vm._v("peter.williams@email.com")
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item" }, [_vm._v("123-456-7890")]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item" }, [
        _vm._v(
          "You're currently enrolled in email marketing, SMS marketing & SMS order tracking"
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item card-item-bold" }, [
        _vm._v("Default Address")
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item card-item-bold" }, [
        _vm._v("Default Address")
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item-password" }, [
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" })
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item-link link-small link-bold" }, [
        _c("a", { staticClass: "link-bold", attrs: { href: "#" } }, [
          _vm._v("Forgot Password")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "col-xs-6" }, [
      _c("li", { staticClass: "card-item" }, [_vm._v("Peter Williams")]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item" }, [
        _vm._v("peter.williams@email.com")
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item" }, [_vm._v("123-456-7890")]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item" }, [
        _vm._v(
          "You're currently enrolled in email marketing, SMS marketing & SMS order tracking"
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item card-item-bold" }, [
        _vm._v("Default Address")
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item card-item-bold" }, [
        _vm._v("Default Address")
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item-password" }, [
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" }),
        _vm._v(" "),
        _c("span", { staticClass: "dot" })
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "card-item-link link-small link-bold" }, [
        _c("a", { staticClass: "link-bold", attrs: { href: "#" } }, [
          _vm._v("Forgot Password")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "card-icon" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("span", { staticClass: "icon-default icon-small icon-trash" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "card-icon" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("span", {
          staticClass: "icon-default icon-default icon-small icon-edit"
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "card-heading" }, [
      _c("h4", [_vm._v("Personal Details")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "card-heading-link" }, [
      _c("a", { staticClass: "#" }, [_c("h4", [_vm._v("Personal Details")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "card-item-password" }, [
      _c("span", { staticClass: "dot" }),
      _vm._v(" "),
      _c("span", { staticClass: "dot" }),
      _vm._v(" "),
      _c("span", { staticClass: "dot" }),
      _vm._v(" "),
      _c("span", { staticClass: "dot" }),
      _vm._v(" "),
      _c("span", { staticClass: "dot" }),
      _vm._v(" "),
      _c("span", { staticClass: "dot" }),
      _vm._v(" "),
      _c("span", { staticClass: "dot" }),
      _vm._v(" "),
      _c("span", { staticClass: "dot" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "card-item-link link-small link-bold" }, [
      _c("a", { staticClass: "link-bold", attrs: { href: "#" } }, [
        _vm._v("Forgot Password")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "card-icon" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("span", { staticClass: "icon-default icon-small icon-trash" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "card-icon" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("span", {
          staticClass: "icon-default icon-default icon-small icon-edit"
        })
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-907323ca", { render: render, staticRenderFns: staticRenderFns })
  }
}