/* eslint-disable consistent-return */
/**
 * vx-hero-banner is used to author CMS hero component where an image,
 *  text and video SRC can be authored.
 */
import { globalEventBus } from '../../../modules/event-bus';
import vxVideoPlayer from '../../common/vx-video-player/vx-video-player.vue';
import mobileMixin from '../../common/mixins/mobile-mixin';
import { backgroundColor } from '../../common/mixins/vx-enums';
import globals from '../../common/globals';
import viewSiteContent from '../../../locale/view-site-content-i18n';

export default {
  name: 'vx-hero-banner',
  components: {
    vxVideoPlayer,
  },
  mixins: [mobileMixin],
  props: {
    heroBannerData: {
      type: Object,
      required: true,
    },
    isCategoriesBanner: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      backgroundColor,
      globals,
      i18n: viewSiteContent.heroBanner,
      backgroundImg: '',
    };
  },
  computed: {
    /**
     * used to set the background color for the banner
     * @return {object} object with background color as key
     */
    backgroundStyle() {
      return !this.heroBannerData.subHeadBgColor
        || this.heroBannerData.subHeadBgColor === ''
        ? {
          'background-color': this.backgroundColor.transparent,
        }
        : {
          'background-color': this.heroBannerData.subHeadBgColor,
        };
    },
    /**
     * sets the background image
     * @return {object} background-image
     */
    backgroundImage() {
      return { 'background-image': `url(${this.backgroundImg})` };
    },
    /**
     * returns the alt text of the background
     */
    backgroundAlt() {
      return this.getResponsiveAlt(
        this.heroBannerData.backgroundImageAltTextD,
        this.heroBannerData.backgroundImageAltTextT,
        this.heroBannerData.backgroundImageAltTextM,
      );
    },
  },
  created() {},
  async mounted() {
    this.backgroundImg = this.getResponsiveImage(
      this.heroBannerData.backgroundImageD,
      this.heroBannerData.backgroundImageT,
      this.heroBannerData.backgroundImageM,
    );
    if (this.isCategoriesBanner) {
      this.heroBannerData = this.globals.catergoryBannerData;
      this.backgroundImg = this.getResponsiveImage(
        this.heroBannerData.backgroundImageD,
      );
    }
  },
  methods: {
    /**
     * opens the video modal
     */
    openModal() {
      const videoSRC = this.heroBannerData.videoSrc;
      this.$refs.videoPlayer.openPlayer(videoSRC);
    },
    /**
     * emits the scroll-to event with link as payload
     * @param {string} link
     * @return {object} null
     */
    emitScrollSignal(link) {
      globalEventBus.$emit('scroll-to', link);
    },
    // Method to strip HTML tags while dynamically setting the aria-label on links
    stripHtmlTags(ariaLabel) {
      if (ariaLabel) {
        return ariaLabel.replace(/(<([^>]+)>)/gi, '');
      }
    },
  },
};
