var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.globals.isB2B()
    ? _c(
        "div",
        {
          staticClass: "embeddedServiceInvitation",
          attrs: {
            id: "snapins_invite",
            inert: "true",
            "aria-live": "assertive",
            role: "dialog",
            "aria-atomic": "true"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "embeddedServiceInvitationHeader",
              attrs: {
                "aria-labelledby": "snapins_titletext",
                "aria-describedby": "snapins_bodytext"
              }
            },
            [
              _c("img", { attrs: { id: "embeddedServiceAvatar" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "embeddedServiceTitleText",
                  attrs: { id: "snapins_titletext" }
                },
                [_vm._v("Need help?")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "embeddedServiceCloseIcon",
                  attrs: {
                    type: "button",
                    id: "closeInvite",
                    "aria-label": "Exit invitation"
                  },
                  on: { click: _vm.exitInvite }
                },
                [_vm._v("×")]
              )
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "embeddedServiceInvitationFooter",
              attrs: { "aria-describedby": "snapins_bodytext" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "embeddedServiceActionButton",
                  attrs: { type: "button", id: "rejectInvite" },
                  on: { click: _vm.rejectInvite }
                },
                [_vm._v("Close")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "embeddedServiceActionButton",
                  attrs: { type: "button", id: "acceptInvite" },
                  on: { click: _vm.acceptInvite }
                },
                [_vm._v("Start Chat")]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "embeddedServiceInvitationBody" }, [
      _c("p", { attrs: { id: "snapins_bodytext" } }, [
        _vm._v("How can we help you?")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-96b4c0ca", { render: render, staticRenderFns: staticRenderFns })
  }
}