var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-pdf-download" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          ref: "pdfDownloadForm",
          attrs: { id: "pdf-download" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c("div", { staticClass: "basic-information" }, [
            _c("div", { staticClass: "section row" }, [
              _c("div", { staticClass: "name-on-pdf col-xs-12 form-group" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "nameOnPdf",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.i18n.nameOnPdf) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pdfInfo.nameOnPdf,
                      expression: "pdfInfo.nameOnPdf"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    name: "nameOnPdf",
                    maxlength: "90",
                    type: "text",
                    "aria-label": _vm.i18n.nameOnPdf
                  },
                  domProps: { value: _vm.pdfInfo.nameOnPdf },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.pdfInfo, "nameOnPdf", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section row" }, [
              _c(
                "div",
                { staticClass: "phone-number col-xs-12 col-md-6 form-group" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "phoneNumber",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.i18n.phoneNumber) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("the-mask", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "min:10",
                        expression: "'min:10'"
                      }
                    ],
                    staticClass: "phone-number form-control",
                    class: { "input-error": _vm.errors.has("phoneNumber") },
                    attrs: {
                      masked: _vm.masked,
                      mask: "+1 ###-###-####",
                      type: "tel",
                      name: "phoneNumber",
                      "aria-label": _vm.i18n.phoneNumber
                    },
                    model: {
                      value: _vm.pdfInfo.phoneNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.pdfInfo, "phoneNumber", $$v)
                      },
                      expression: "pdfInfo.phoneNumber"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("phoneNumber")
                    ? _c("div", { staticClass: "mt-xs-2" }, [
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.errors.first("phoneNumber")) +
                              "\n                        "
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "email-address col-xs-12 col-md-6 form-group" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "emailAddress",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.emailAddress))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "email|max:75",
                        expression: "'email|max:75'"
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pdfInfo.emailAddress,
                        expression: "pdfInfo.emailAddress"
                      }
                    ],
                    staticClass: "form-control input-elements",
                    class: { "input-error": _vm.errors.has("emailAddress") },
                    attrs: {
                      type: "email",
                      maxlength: "75",
                      name: "emailAddress",
                      "aria-label": _vm.i18n.emailAddress
                    },
                    domProps: { value: _vm.pdfInfo.emailAddress },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.pdfInfo,
                          "emailAddress",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("emailAddress")
                    ? _c("div", { staticClass: "mt-xs-2" }, [
                        _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            attrs: { id: "emailAddress" }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.errors.first("emailAddress")) +
                                "\n                        "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section row" }, [
              _c("div", { staticClass: "message col-xs-12 form-group" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "message",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.i18n.message) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pdfInfo.message,
                      expression: "pdfInfo.message"
                    }
                  ],
                  staticClass: "form-control message-input",
                  attrs: {
                    type: "text",
                    maxlength: "90",
                    name: "message",
                    "aria-label": _vm.i18n.message
                  },
                  domProps: { value: _vm.pdfInfo.message },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.pdfInfo, "message", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section row" }, [
              _c(
                "div",
                { staticClass: "bar-color col-xs-12 form-group" },
                [
                  _c(
                    "label",
                    {
                      attrs: { for: "barColor", "aria-hidden": !_vm.isTablet() }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.i18n.barColor) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    ref: "barColorDropdown",
                    attrs: {
                      dropdownValues: _vm.colorList,
                      "aria-label": _vm.i18n.barColor,
                      name: "barColor"
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.handleBarColorValue($event)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          !_vm.isPdp
            ? _c("div", { staticClass: "list-format" }, [
                _c("p", { staticClass: "mb-xs-4 info-text" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.i18n.formatList) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "container-content col-xs-12 px-xs-0" },
                  [
                    _c(
                      "div",
                      { staticClass: "left-container col-xs-12 col-sm-6" },
                      [
                        _c(
                          "div",
                          { staticClass: "form-check" },
                          _vm._l(_vm.displayFormat, function(item, index) {
                            return _c(
                              "div",
                              {
                                staticClass: "form-group",
                                on: {
                                  click: function($event) {
                                    _vm.radioButtonOptionChange(item, $event)
                                  }
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.pdfInfo.formatColumns ||
                                        _vm.formatColumns,
                                      expression:
                                        "pdfInfo.formatColumns || formatColumns"
                                    }
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: {
                                    tabindex: "0",
                                    name: "formatColumns",
                                    type: "radio",
                                    label: item.label,
                                    "aria-label": item.label
                                  },
                                  domProps: {
                                    value: item.value,
                                    checked: _vm._q(
                                      _vm.pdfInfo.formatColumns ||
                                        _vm.formatColumns,
                                      item.value
                                    )
                                  },
                                  on: {
                                    "selected-option": function($event) {
                                      _vm.handleFormatColumnsValue($event)
                                    },
                                    change: function($event) {
                                      _vm.$set(
                                        _vm.pdfInfo,
                                        "formatColumns || formatColumns",
                                        item.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label",
                                    attrs: {
                                      "aria-hidden": _vm.isDeviceAndroid()
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.label) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              ]
                            )
                          })
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "right-container col-xs-12 col-sm-6" },
                      [
                        _c("div", { staticClass: "col-xs-12 form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pdfInfo.categoryDescription,
                                expression: "pdfInfo.categoryDescription"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "checkbox",
                              name: "category",
                              id: "category",
                              tabindex: "0",
                              checked: "checked",
                              "aria-label": _vm.i18n.category
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.pdfInfo.categoryDescription
                              )
                                ? _vm._i(
                                    _vm.pdfInfo.categoryDescription,
                                    null
                                  ) > -1
                                : _vm.pdfInfo.categoryDescription
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.pdfInfo.categoryDescription,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.pdfInfo,
                                        "categoryDescription",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.pdfInfo,
                                        "categoryDescription",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.pdfInfo,
                                    "categoryDescription",
                                    $$c
                                  )
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: {
                                for: "category",
                                "aria-hidden": _vm.isDeviceAndroid()
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.i18n.category) +
                                  "\n                        "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-12 form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pdfInfo.productSellingStatement,
                                expression: "pdfInfo.productSellingStatement"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "checkbox",
                              name: "productSellingStatement",
                              id: "productSellingStatement",
                              tabindex: "0",
                              "aria-label": _vm.i18n.productSellingStatement
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.pdfInfo.productSellingStatement
                              )
                                ? _vm._i(
                                    _vm.pdfInfo.productSellingStatement,
                                    null
                                  ) > -1
                                : _vm.pdfInfo.productSellingStatement
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.pdfInfo.productSellingStatement,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.pdfInfo,
                                        "productSellingStatement",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.pdfInfo,
                                        "productSellingStatement",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.pdfInfo,
                                    "productSellingStatement",
                                    $$c
                                  )
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: {
                                for: "productSellingStatement",
                                "aria-hidden": _vm.isDeviceAndroid()
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.i18n.productSellingStatement) +
                                  "\n                        "
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isPdp
            ? _c(
                "div",
                { staticClass: "form-group mb-lg-4 mb-sm-4 mb-xs-4" },
                [
                  _c("div", { staticClass: "mb-xs-3" }, [
                    _c("p", { staticClass: "mb-xs-3 info-text col-xs-12" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.i18n.featureCheckedItem) +
                          "\n                "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.featureChecked, function(item, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "form-group",
                        on: {
                          click: function($event) {
                            _vm.radioButtonOptionFeature(item, $event)
                          }
                        }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.pdfInfo.featureCheckedItems ||
                                _vm.featureCheckedItems,
                              expression:
                                "pdfInfo.featureCheckedItems || featureCheckedItems"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            tabindex: "0",
                            name: "featureCheckedItems",
                            type: "radio",
                            label: item.label,
                            "aria-label": item.label
                          },
                          domProps: {
                            value: item.value,
                            checked: _vm._q(
                              _vm.pdfInfo.featureCheckedItems ||
                                _vm.featureCheckedItems,
                              item.value
                            )
                          },
                          on: {
                            "selected-option": function($event) {
                              _vm.handleFormatColumnsValue($event)
                            },
                            change: function($event) {
                              _vm.$set(
                                _vm.pdfInfo,
                                "featureCheckedItems || featureCheckedItems",
                                item.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { "aria-hidden": _vm.isDeviceAndroid() }
                          },
                          [_vm._v(_vm._s(item.label))]
                        ),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.description) +
                              "\n                "
                          )
                        ])
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "mt-xs-3" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.i18n.featureDropDown) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    ref: "selectFeatureDropdown",
                    attrs: {
                      dropdownValues: _vm.selectFeatureItems,
                      "aria-label": _vm.i18n.featureDropDown,
                      multiSelectDropdown: true,
                      name: "selectFeature"
                    },
                    on: {
                      primaryDropdownMounted: function($event) {
                        _vm.handleSelectFeatureValue()
                      }
                    }
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "optional-headlines" }, [
            _c("div", { staticClass: "section row" }, [
              _c("div", { staticClass: "col-xs-12 form-group" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "headlineLine1",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.i18n.headlineLine1) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pdfInfo.headlineLine1,
                      expression: "pdfInfo.headlineLine1"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    name: "headlineLine1",
                    maxlength: "60",
                    "aria-label": _vm.i18n.headlineLine1,
                    type: "text"
                  },
                  domProps: { value: _vm.pdfInfo.headlineLine1 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.pdfInfo,
                        "headlineLine1",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section row" }, [
              _c("div", { staticClass: "col-xs-12 form-group" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "headlineLine2",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.i18n.headlineLine2) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pdfInfo.headlineLine2,
                      expression: "pdfInfo.headlineLine2"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    name: "headlineLine2",
                    maxlength: "60",
                    "aria-label": _vm.i18n.headlineLine2,
                    type: "text"
                  },
                  domProps: { value: _vm.pdfInfo.headlineLine2 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.pdfInfo,
                        "headlineLine2",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section row" }, [
              _c(
                "div",
                { staticClass: "col-xs-12 form-group" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "headlineColor",
                        "aria-hidden": !_vm.isTablet()
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.headlineColor))]
                  ),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    ref: "headlineColorDropdown",
                    attrs: {
                      dropdownValues: _vm.colorList,
                      "aria-label": _vm.i18n.headlineColor,
                      name: "headlineColor"
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.handleHeadlineColorValue($event)
                      }
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            !_vm.isPdp
              ? _c("div", { staticClass: "section row" }, [
                  _c("div", { staticClass: "col-xs-12 form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.pdfInfo.onlyOnFirstPage,
                          expression: "pdfInfo.onlyOnFirstPage"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        "aria-label": _vm.i18n.onlyOnFirstPageDescription,
                        name: "onlyOnFirstPage",
                        id: "onlyOnFirstPage",
                        tabindex: "0"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.pdfInfo.onlyOnFirstPage)
                          ? _vm._i(_vm.pdfInfo.onlyOnFirstPage, null) > -1
                          : _vm.pdfInfo.onlyOnFirstPage
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.pdfInfo.onlyOnFirstPage,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.pdfInfo,
                                  "onlyOnFirstPage",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.pdfInfo,
                                  "onlyOnFirstPage",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.pdfInfo, "onlyOnFirstPage", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: {
                          for: "onlyOnFirstPage",
                          "aria-hidden": _vm.isDeviceAndroid()
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.i18n.onlyOnFirstPageDescription) +
                            "\n                    "
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isPdp
              ? _c("div", { staticClass: "section row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 form-group" },
                    [
                      _c(
                        "label",
                        {
                          attrs: {
                            for: "coverPage",
                            "aria-hidden": !_vm.isTablet()
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.coverPage))]
                      ),
                      _vm._v(" "),
                      _c("vx-dropdown-primary", {
                        ref: "coverPageDropdown",
                        attrs: {
                          dropdownValues: _vm.coverpage.options,
                          "aria-label": _vm.i18n.coverPage,
                          name: "coverPage"
                        },
                        on: {
                          "selected-option": function($event) {
                            _vm.handleCoverPageValue($event)
                          },
                          primaryDropdownMounted: function($event) {
                            _vm.handleCoverPageDefaultValue()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.coverpage.options && _vm.imgSrc
                    ? _c("div", { staticClass: "col-xs-12" }, [
                        _c("img", {
                          staticClass: "coverImage",
                          attrs: { src: _vm.imgSrc },
                          on: { error: _vm.imgSrcAlt }
                        })
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "reset-link-container d-flex flex-column" },
            [
              _c(
                "a",
                {
                  staticClass: "reset-link",
                  attrs: { "aria-label": _vm.i18n.resetLink },
                  on: {
                    click: function($event) {
                      _vm.handleResetLink()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.i18n.resetLink) +
                      "\n            "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "mt-md-3 mt-sm-3 mt-xs-4 submit btn btn-primary modal-btn-primary-small modal-bottom-spacing",
              attrs: { type: "submit", disabled: _vm.errors.any() }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.i18n.createAndDownloadPdf) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          !_vm.globals.isGppro()
            ? _c(
                "div",
                {
                  staticClass:
                    "mt-xs-4 disclaimer modal-btn-IEspacing modal-btn-EDGEspacing"
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.i18n.disclaimerLine1) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.i18n.disclaimerLine2) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "disclaimer-line" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.i18n.disclaimerLine3) +
                        "\n                "
                    ),
                    _c("a", { attrs: { href: _vm.i18n.privacyPolicyLink } }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.i18n.disclaimerLine4) +
                          "\n                "
                      )
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.i18n.disclaimerLine5) +
                        "\n            "
                    )
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2e7c6110", { render: render, staticRenderFns: staticRenderFns })
  }
}