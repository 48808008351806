var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-checkout" },
    [
      _c(
        "vx-spinner",
        {
          ref: "spinner",
          attrs: {
            image: _vm.globals.assetsPath + "images/spinner.gif",
            "full-screen": ""
          }
        },
        [_vm._v(">")]
      ),
      _vm._v(" "),
      _vm.dataLoaded
        ? [
            _c("div", { staticClass: "col-md-8 px-xs-0 pr-md-3" }, [
              _c("h1", { staticClass: "main-heading" }, [
                _vm._v(_vm._s(_vm.i18n.checkout.checkoutHeading))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "checkout-sections" },
                [
                  _vm._l(_vm.visibleSections, function(section, index) {
                    return [
                      _c(
                        "div",
                        { staticClass: "checkout-section" },
                        [
                          !section.enabled
                            ? _c(
                                "span",
                                {
                                  staticClass: "heading",
                                  attrs: { role: "heading", "aria-level": "2" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(index + 1) +
                                      ". " +
                                      _vm._s(section.heading)
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          section.id === "shippingAddress"
                            ? [
                                section.enabled
                                  ? _c("vx-shipping-address", {
                                      attrs: {
                                        "visible-entries":
                                          _vm.visibleCheckoutEntries,
                                        i18n: _vm.i18n,
                                        "user-level": _vm.userLevel,
                                        "section-index": index + 1,
                                        "all-entries": _vm.checkoutData.entries
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          section.id === "leaseAgreement"
                            ? [
                                section.enabled
                                  ? _c("vx-lease-agreement", {
                                      attrs: {
                                        "checkout-data": _vm.checkoutData,
                                        "leasable-products":
                                          _vm.leasableProducts,
                                        country: _vm.leaseCountry,
                                        "country-quantity":
                                          _vm.leasableQtyItems,
                                        i18n: _vm.i18n,
                                        "section-index": index + 1
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          section.id === "scheduleinstallation"
                            ? [
                                section.enabled
                                  ? _c("vx-schedule-installation", {
                                      attrs: {
                                        "installable-products":
                                          _vm.installableProducts,
                                        i18n: _vm.i18n,
                                        "section-index": index + 1
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          section.id == "shippingMethod"
                            ? [
                                section.enabled
                                  ? _c("vx-shipping-method", {
                                      attrs: {
                                        "address-changed": _vm.addressChanged,
                                        i18n: _vm.i18n.shippingMethod,
                                        "split-applicable": _vm.splitApplicable,
                                        "checkout-data": _vm.checkoutData,
                                        "is-shipping-multiple":
                                          _vm.isShippingMultiple,
                                        "section-index": index + 1
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          section.id === "giftOptions"
                            ? [
                                section.enabled
                                  ? _c("vx-gift-options", {
                                      attrs: {
                                        "giftable-products":
                                          _vm.giftableProducts,
                                        i18n: _vm.i18n.giftingOptions,
                                        "section-index": index + 1
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          section.id === "paymentBilling"
                            ? [
                                section.enabled
                                  ? _c("vx-payment-billing", {
                                      key: _vm.isPaymentButtonDisabled,
                                      attrs: {
                                        "selected-card":
                                          _vm.checkoutData.paymentInfo,
                                        i18n: _vm.i18n,
                                        "shipping-address": _vm.shippingAddress,
                                        "section-index": index + 1,
                                        "disable-button":
                                          _vm.isPaymentButtonDisabled,
                                        "guest-email": _vm.guestEmail,
                                        "checkout-data": _vm.checkoutData
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          section.id === "overview"
                            ? [
                                _c("vx-overview", {
                                  attrs: {
                                    "visible-entries":
                                      _vm.visibleCheckoutEntries,
                                    i18n: _vm.i18n.overview,
                                    "split-applicable": _vm.splitApplicable,
                                    "total-items": _vm.checkoutData.totalItems,
                                    "promotion-data": _vm.promotionData,
                                    "total-price":
                                      _vm.checkoutData.totalSubscriptionPrice
                                  }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "checkout-order-summary-details col-sm-6 col-sm-offset-6 col-md-4 col-md-offset-0 px-xs-0 pl-md-3"
              },
              [
                _c(
                  "vx-order-summary",
                  {
                    attrs: {
                      i18n: _vm.i18n.orderSummary,
                      page: "checkout",
                      "order-details": _vm.checkoutData,
                      "is-giftable": _vm.isGiftable,
                      "is-installable": _vm.isInstallable,
                      "is-shipping-multiple": _vm.isShippingMultiple
                    }
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "bottomButton" }, slot: "bottomButton" },
                      [
                        _c("vx-place-order", {
                          attrs: {
                            i18n: _vm.i18n.checkout,
                            "order-details": _vm.checkoutData
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-795c75ef", { render: render, staticRenderFns: staticRenderFns })
  }
}