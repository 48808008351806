var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pdpCompareIds.length > 1 && _vm.hideComponent === false
    ? _c("div", { staticClass: "vx-pdp-compare-products" }, [
        _c("div", { staticClass: "header" }, [
          _vm._v("\n        Compare to Similar Products\n    ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "compare-result-page d-flex flex-column" },
          [
            _c(
              "div",
              {
                staticClass:
                  "compare-tiles d-flex mx-xs-0 pt-xs-4 px-xs-3 pt-sm-3 pt-md-5 px-md-3"
              },
              [
                _c("div", { staticClass: "empty-space" }),
                _vm._v(" "),
                _vm._l(_vm.productsCompareData.products, function(item, index) {
                  return _c("vx-product-tile-vertical", {
                    key: index,
                    attrs: {
                      product: item,
                      showRemoveOption: false,
                      "is-favorite-visible": false,
                      i18n: _vm.messages["en-US"].searchBrowse.productTile,
                      showCertifications: true
                    }
                  })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("vx-compare-table", {
              attrs: { productData: _vm.productsCompareData }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-319ff359", { render: render, staticRenderFns: staticRenderFns })
  }
}