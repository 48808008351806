var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "access-customer-service-live-chat contact-type-container",
      class: _vm.liveChatTheme
    },
    [
      _c(
        "div",
        { staticClass: "d-flex livechat-left" },
        [
          _vm.showContactUs
            ? [
                _c("div", { staticClass: "left-section contact-type" }, [
                  _c("div", { staticClass: "chat-section row mx-sm-0" }, [
                    _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                      _c("h2", { staticClass: "contact-type-heading" }, [
                        _vm._v("Send an Email")
                      ]),
                      _vm._v(" "),
                      _vm.i18n.sendEmailText
                        ? _c("p", { staticClass: "mb-xs-2" }, [
                            _vm._v(_vm._s(_vm.i18n.sendEmailText))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", { staticClass: "my-xs-0 telephone" }, [
                        _vm.i18n.emailAddress
                          ? _c(
                              "a",
                              {
                                staticClass: "mb-xs-0",
                                attrs: {
                                  href: "mailto:" + _vm.i18n.emailAddress
                                }
                              },
                              [_vm._v(_vm._s(_vm.i18n.emailAddress))]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showContactUs && _vm.globals.siteConfig.isCallUsEnabled
            ? _c("div", { staticClass: "right-section contact-type" }, [
                _c("div", { staticClass: "row call-text-section mx-sm-0" }, [
                  _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                    _c("h2", { staticClass: "contact-type-heading" }, [
                      _vm._v("\n            Call Us\n          ")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "call-text-timings pb-xs-4" }, [
                      _vm.i18n.callOrTextUsTimings1
                        ? _c(
                            "p",
                            {
                              staticClass: "mb-xs-0 bold",
                              attrs: { "aria-hidden": "true" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.i18n.callOrTextUsTimings1) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.i18n.callOrTextUsTimings1Accessiblity
                        ? _c("span", { staticClass: "sr-only" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.i18n.callOrTextUsTimings1Accessiblity
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.i18n.callOrTextUsTimings2
                        ? _c(
                            "p",
                            {
                              staticClass: "mb-xs-0 bold",
                              attrs: { "aria-hidden": "true" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.i18n.callOrTextUsTimings2) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.i18n.callOrTextUsTimings2Accessibility
                        ? _c("span", { staticClass: "sr-only" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.i18n.callOrTextUsTimings2Accessibility
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.i18n.callOrTextUsBody
                      ? _c(
                          "p",
                          { staticClass: "call-text-body pb-xs-4 mb-xs-0" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.i18n.callOrTextUsBody) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "call-text-number d-flex" }, [
                      _c("p", { staticClass: "mb-xs-4 mb-md-0 telephone" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.i18n.callText) +
                            "\n              "
                        ),
                        _vm.i18n.callNumber
                          ? _c(
                              "a",
                              {
                                attrs: { href: "tel:+" + _vm.i18n.callNumber }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.i18n.callNumber) +
                                    "\n              "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.i18n.callExt
                          ? _c("a", { attrs: { href: "javascript:void(0)" } }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.i18n.callExt) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.i18n.textNumber && _vm.i18n.text
                        ? _c("p", { staticClass: "mb-xs-0 pl-xs-0 pl-md-3" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.i18n.text) +
                                "\n              "
                            ),
                            _vm.i18n.textNumber
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "sms://+" + _vm.textNumber }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.i18n.textNumber) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "email-us pb-xs-4" }, [
                      _vm.i18n.emailText
                        ? _c("span", [_vm._v(_vm._s(_vm.i18n.emailText))])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.i18n.emailUs
                        ? _c(
                            "a",
                            {
                              staticClass: "mb-xs-0",
                              attrs: { href: "mailto:" + _vm.i18n.emailUs }
                            },
                            [_vm._v(_vm._s(_vm.i18n.emailUs))]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "write-us" }, [
                      _vm.i18n.writeText
                        ? _c("p", { staticClass: "mb-xs-2" }, [
                            _vm._v(_vm._s(_vm.i18n.writeText))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.i18n.addressLine1
                        ? _c("p", { staticClass: "mb-xs-0" }, [
                            _vm._v(_vm._s(_vm.i18n.addressLine1))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.i18n.addressLine2
                        ? _c("p", { staticClass: "mb-xs-0" }, [
                            _vm._v(_vm._s(_vm.i18n.addressLine2))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.i18n.addressLine3
                        ? _c("p", { staticClass: "mb-xs-0" }, [
                            _vm._v(_vm._s(_vm.i18n.addressLine3))
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.globals.siteConfig.isEmailUsEnabled
            ? _c("div", { staticClass: "left-section contact-type" }, [
                _c("div", { staticClass: "send-email-section row mx-sm-0" }, [
                  _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                    _vm.imageTextData.headingText
                      ? _c("h3", {
                          domProps: {
                            innerHTML: _vm._s(_vm.imageTextData.headingText)
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h2", { staticClass: "contact-type-heading" }, [
                      _vm._v("Chat Us")
                    ]),
                    _vm._v(" "),
                    _vm.imageTextData.informationText
                      ? _c("p", {
                          staticClass: "my-xs-0",
                          domProps: {
                            innerHTML: _vm._s(_vm.imageTextData.informationText)
                          }
                        })
                      : _vm._e()
                  ])
                ])
              ])
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-68e606da", { render: render, staticRenderFns: staticRenderFns })
  }
}