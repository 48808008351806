<template>
  <div class="vx-auto-suggest-products">
    <div class="search-input form-group">
      <input class="form-control auto-suggest-input"
             type="text"
             placeholder="Search"
             @input="onInput($event.target.value)"
             id='autoSuggest-productSearch'
             @blur="onBlur"
             >
    <div v-if="products.length" class="autopopulate-dropdown">
      <ul>
        <li class="search-item"
            v-for="product in products" :key="product.code"
            @click="onItemClick(product)">
          [{{ product.code }}] {{ product.name }}
        </li>
      </ul>
    </div>
    </div>

  </div>

</template>

<script>
import debounce from 'lodash.debounce';
import SearchBrowseService from '../../common/services/search-browse-service';

export default {
  name: 'vx-auto-suggest-to-list',
  data() {
    return {
      products: [],
      selectedProducts: [],
      searchBrowseService: new SearchBrowseService(),
      delay: 500,
      debouncedSendSearchRequest: null,
      selectedProduct: '',
    };
  },
  created() {
    this.debouncedSendSearchRequest = debounce(this.sendSearchRequest, this.delay);
  },
  props: {
    handleProductSelection: {
      type: Function,
      required: false,
    },
  },
  methods: {
    onInput(text) {
      if (text.length >= 3) {
        this.debouncedSendSearchRequest(text);
      } else {
        this.products = [];
      }
    },
    sendSearchRequest(text) {
      const params = {
        term: encodeURIComponent(text.trim()),
        maxProducts: 10,
        fields: 'BASIC',
      };
      this.searchBrowseService.getAutosuggest(
        { params },
        this.handleSuggestResponse,
        () => {},
      );
    },
    handleSuggestResponse(response) {
      this.products = response.data.products || [];
    },
    onItemClick(product) {
      const element = document.getElementById('autoSuggest-productSearch');
      element.value = `${product.code}-${product.name}`;
      this.products = [];
      this.selectedProduct = `${product.code}-${product.name}`;
      this.handleProductSelection(product.code);
    },
    onItemClickRemove(product) {
      const { code } = product;
      this.selectedProducts = this.selectedProducts.filter((item) => item.code !== code);
    },
    onClickEmitSelectedProducts() {
      const productCodes = this.selectedProducts.map((item) => item.code).join(',');
      this.$emit('addSelectedProducts', productCodes);
    },
    onBlur() {
      setTimeout(() => {
        this.products = [];
        const element = document.getElementById('autoSuggest-productSearch');
        if (this.selectedProduct.length > 0 && element.value.length !== 0) {
          // handling case when some parts of text has been changed but nothing was selected
          element.value = this.selectedProduct;
        } else if (element.value.length === 0) {
          // case where the input has been cleared by the user completely
          this.selectedProduct = '';
          this.handleProductSelection('');
        } else {
          // nothing has been selected so far but there is some text typed by user
          element.value = '';
        }
      }, 300);
    },
  },
};
</script>
