// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../node_modules/mini-css-extract-plugin/dist/loader.js!../../node_modules/css-loader/index.js?{\"sourceMap\":true}!../../node_modules/vue-loader/lib/style-compiler/index.js?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-20e3896f\",\"scoped\":true,\"sourceMap\":true}!../../node_modules/vue-loader/lib/selector.js?type=styles&index=0!./order-details.vue");
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add the styles to the DOM
var update = require("!../../node_modules/vue-style-loader/lib/addStylesClient.js")("cc3c6298", content, false, {});
// Hot Module Replacement
if(module.hot) {
 // When the styles change, update the <style> tags
 if(!content.locals) {
   module.hot.accept("!!../../node_modules/mini-css-extract-plugin/dist/loader.js!../../node_modules/css-loader/index.js?{\"sourceMap\":true}!../../node_modules/vue-loader/lib/style-compiler/index.js?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-20e3896f\",\"scoped\":true,\"sourceMap\":true}!../../node_modules/vue-loader/lib/selector.js?type=styles&index=0!./order-details.vue", function() {
     var newContent = require("!!../../node_modules/mini-css-extract-plugin/dist/loader.js!../../node_modules/css-loader/index.js?{\"sourceMap\":true}!../../node_modules/vue-loader/lib/style-compiler/index.js?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-20e3896f\",\"scoped\":true,\"sourceMap\":true}!../../node_modules/vue-loader/lib/selector.js?type=styles&index=0!./order-details.vue");
     if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];
     update(newContent);
   });
 }
 // When the module is disposed, remove the <style> tags
 module.hot.dispose(function() { update(); });
}