var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-edit-list-name" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.editListName($event)
          }
        }
      },
      [
        _c("div", { staticClass: "mb-md-5 mb-xs-4 form-group" }, [
          _c("p", [_vm._v(_vm._s(_vm.i18n.editListName.editListLabel))]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.listId,
                expression: "listId"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|max:40",
                expression: "'required|max:40'"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("listName") },
            attrs: {
              type: "text",
              name: "listName",
              maxlength: "40",
              "aria-label": _vm.i18n.editListName.editListLabel
            },
            domProps: { value: _vm.listId },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.listId = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("listName")
            ? _c("div", { staticClass: "mt-xs-2" }, [
                _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.errors.first("listName")))
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c(
            "button",
            {
              staticClass:
                "col-md-12 col-xs-12 col-lg-12 col-sm-12 btn button-color text-trans",
              attrs: { type: "submit", disabled: _vm.errors.any() }
            },
            [_vm._v(_vm._s(_vm.i18n.editListName.save))]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-af2f177c", { render: render, staticRenderFns: staticRenderFns })
  }
}