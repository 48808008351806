import vxApplicationAuth from '../../components/common/vx-application-auth/vx-application-auth.vue';
import vxHeaderSection from '../components/common/vx-header-section/vx-header-section.vue';
import vxFlyoutBanner from '../../components/common/vx-flyout-banner/vx-flyout-banner.vue';
import vxFooterSection from '../components/common/vx-footer-section/vx-footer-section.vue';
import vxVideoPlayer from '../../components/common/vx-video-player/vx-video-player.vue';
import VxSectionTitle from '../../components/common/vx-title/vx-title.vue';
import vxRichText from '../../components/common/vx-rich-text/vx-rich-text.vue';
import vxJuicerFeed from '../../components/common/vx-juicer-feed/vx-juicer-feed.vue';
import vxBreadcrumb from '../components/common/vx-breadcrumb/vx-breadcrumb.vue';
import vxCustomerDetails from '../../components/common/vx-customer-details/vx-customer-details.vue';
import vxAssistiveText from '../../components/common/vx-assistive-text/vx-assistive-text.vue';
import vxBundles from '../../components/common/vx-bundles/vx-bundles.vue';
import emailExtends from '../../components/common/vx-extends';
import vxReviewLease from '../../components/common/vx-review-lease/vx-review-lease.vue';
import vxMinimizedHeader from '../../components/common/vx-minimized-header/vx-minimized-header.vue';
import vxAddToCart from '../../components/common/vx-add-to-cart/vx-add-to-cart.vue';
import vxFavoriteBtn from '../../components/common/vx-favorite-btn/vx-favorite-btn.vue';
import vxNotifyButton from '../../components/common/vx-notify-button/vx-notify-button.vue';
import vxEmbeddedChat from '../../components/common/vx-embedded-chat/vx-embedded-chat.vue';
import vxSampleShoppingCart from '../../components/common/vx-sample-shopping-cart/vx-sample-shopping-cart.vue';
import vxSampleCartCheckout from '../../components/common/vx-sample-cart-checkout/vx-sample-cart-checkout.vue';
import vxPdfGenerator from '../../components/common/vx-pdf-generator/vx-pdf-generator.vue';
import vxFlyoutBannerSecondary from '../../components/common/vx-flyout-banner-secondary/vx-flyout-banner-secondary.vue';
import vxMarketingModal from '../../components/common/vx-marketing-modal/vx-marketing-modal.vue';
import vxUiuxInfographic from '../components/common/vx-uiux-infographic/vx-uiux-infographic.vue';
import vxDoubleCtaBanner from '../components/common/vx-double-cta-banner/vx-double-cta-banner.vue';
import vxUiuxTilesCarousel from '../components/common/vx-uiux-tiles-carousel/vx-uiux-tiles-carousel.vue';
import vxUiuxTextSideSubcats from '../components/common/vx-uiux-text-side-subcats/vx-uiux-text-side-subcats.vue';
import vxUiuxCtaCenteredText from '../components/common/vx-uiux-cta-centered-text/vx-uiux-cta-centered-text.vue';
import vxCtaButton from '../components/common/vx-cta-button/vx-cta-button.vue';
import vxUiuxBrandBar from '../components/common/vx-uiux-brand-bar/vx-uiux-brand-bar.vue';
import vxUiuxBannerText from '../components/common/vx-uiux-banner-text/vx-uiux-banner-text.vue';
import vxUiuxProductCarousel from '../components/common/vx-uiux-product-carousel/vx-uiux-product-carousel.vue';
import vxUiuxNewsKnowledge from '../components/common/vx-uiux-news-knowledge/vx-uiux-news-knowledge.vue';
import vxTabbedBanner from '../components/common/vx-tabbed-banner/vx-tabbed-banner.vue';
import vxUiuxGpImageText from '../components/common/vx-uiux-gp-image-text/vx-uiux-gp-image-text.vue';
import vxUiuxWidebannerRightCta from '../components/common/vx-uiux-widebanner-right-cta/vx-uiux-widebanner-right-cta.vue';
import vxUiuxHalfBanner from '../components/common/vx-uiux-half-banner/vx-uiux-half-banner.vue';
import vxUiuxWideBannerCarousel from '../components/common/vx-uiux-wide-banner-carousel/vx-uiux-wide-banner-carousel.vue';
import vxUiuxProductCategoriesCarousel from '../components/common/vx-uiux-product-categories-carousel/vx-uiux-product-categories-carousel.vue';
import vxUiuxNestedTiles from '../components/common/vx-uiux-nested-tiles/vx-uiux-nested-tiles.vue';
import vxUiuxPerformanceText from '../components/common/vx-uiux-performance-text/vx-uiux-performance-text.vue';
import vxFileUpload from '../../components/common/vx-file-upload/vx-file-upload.vue';
import vxUiuxSubCategories from '../components/common/vx-uiux-sub-categories/vx-uiux-sub-categories.vue';
import initMixin from '../../components/common/mixins/init-mixin';
import trackjsMixin from '../../components/common/mixins/trackjs-mixin';
import recaptchaMixin from '../../components/common/mixins/recaptcha-mixin';
import checkoutTrackingMixin from '../../components/common/mixins/checkout-tracking-mixin';
import pardotMixin from '../../components/common/mixins/pardot-mixin';
import vxPersistentNavigation from '../components/common/vx-persitent-navigation/vx-persistent-navigation.vue';
import vxVideoLibrary from '../components/common/vx-video-library/vx-video-library.vue';
import vxMultiIcon from '../components/common/vx-multi-icon/vx-multi-icon.vue';
import vxProductResourceData from '../components/pdp/vx-product-resource-data/vx-product-resource-data.vue';
import vxGpxpressLogin from '../components/common/vx-gpxpress-login/vx-gpxpress-login.vue';

const commonModule = {
  components: {
    vxApplicationAuth,
    vxHeaderSection,
    vxFlyoutBanner,
    vxFooterSection,
    vxVideoPlayer,
    VxSectionTitle,
    vxRichText,
    vxJuicerFeed,
    vxBreadcrumb,
    vxCustomerDetails,
    vxAssistiveText,
    vxBundles,
    vxReviewLease,
    vxMinimizedHeader,
    vxAddToCart,
    vxFavoriteBtn,
    vxNotifyButton,
    vxSampleShoppingCart,
    vxSampleCartCheckout,
    vxPdfGenerator,
    vxFlyoutBannerSecondary,
    emailExtends,
    vxMarketingModal,
    vxUiuxInfographic,
    vxDoubleCtaBanner,
    vxUiuxTilesCarousel,
    vxUiuxTextSideSubcats,
    vxUiuxCtaCenteredText,
    vxCtaButton,
    vxUiuxBrandBar,
    vxUiuxBannerText,
    vxUiuxProductCarousel,
    vxUiuxNewsKnowledge,
    vxTabbedBanner,
    vxUiuxGpImageText,
    vxUiuxWidebannerRightCta,
    vxUiuxHalfBanner,
    vxUiuxWideBannerCarousel,
    vxUiuxProductCategoriesCarousel,
    vxUiuxNestedTiles,
    vxUiuxPerformanceText,
    vxFileUpload,
    vxUiuxSubCategories,
    vxPersistentNavigation,
    vxEmbeddedChat,
    vxVideoLibrary,
    vxMultiIcon,
    vxProductResourceData,
    vxGpxpressLogin,
  },

  mixins: [
    initMixin,
    trackjsMixin,
    recaptchaMixin,
    checkoutTrackingMixin,
    pardotMixin,
  ],
};
export default commonModule;
