var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-container" }, [
    _c("h1", [_vm._v(_vm._s(_vm.msg))]),
    _vm._v(" "),
    _c("div", { staticClass: "main__inner-wrapper" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("vx-forgot-password", {
                attrs: {
                  i18n: _vm.messages.registrationLogin.passwordExpiry,
                  "is-expiry": ""
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("vx-update-password", {
                attrs: {
                  heading: "Update Password",
                  "action-url": "/someurl/somepoint",
                  i18n: _vm.messages.registrationLogin.updatePassword
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("vx-create-account", {
                attrs: {
                  "action-url": "/someurl/somepoint",
                  i18n: _vm.messages.registrationLogin.createAccount
                }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1ab767cf", { render: render, staticRenderFns: staticRenderFns })
  }
}