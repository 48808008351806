var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-support-ticket" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c("h2", { staticClass: "supportTicketHeading" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.i18n.supportTicketHeading) +
              "\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _vm.globals.siteConfig.showLiveChat
        ? _c("div", { staticClass: "contact-info-section mb-xs-4" }, [
            _c("div", { staticClass: "contact-information m-xs-4" }, [
              _c("a", { attrs: { href: "javascript:void(0)" } }, [
                _vm._v(_vm._s(_vm.i18n.contactNumber))
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "mt-xs-2", attrs: { "aria-hidden": "true" } },
                [_vm._v(_vm._s(_vm.i18n.contactTime))]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "sr-only" }, [
                _vm._v(_vm._s(_vm.i18n.contactTimeA11y))
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "mt-xs-2", attrs: { "aria-hidden": "true" } },
                [_vm._v(_vm._s(_vm.i18n.contactTime2))]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "sr-only" }, [
                _vm._v(_vm._s(_vm.i18n.contactTime2A11y))
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "live-chat m-xs-4" },
              [
                _vm.i18n.liveChat
                  ? [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showButtons,
                              expression: "showButtons"
                            }
                          ],
                          ref: "chatOnline",
                          staticClass:
                            "btn btn-tertiary btn-small-text py-xs-0 d-flex justify-content-center align-items-center",
                          attrs: { target: "_blank" },
                          on: {
                            click: function($event) {
                              _vm.triggerChat($event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.liveChat))]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showButtons,
                              expression: "showButtons"
                            }
                          ],
                          ref: "chatOffline",
                          staticClass:
                            "btn btn-tertiary btn-small-text py-xs-0 d-flex justify-content-center align-items-center",
                          attrs: { target: "_blank", disabled: "" }
                        },
                        [_vm._v(_vm._s(_vm.i18n.liveChat))]
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "mt-xs-2", attrs: { "aria-hidden": "true" } },
                  [_vm._v(_vm._s(_vm.i18n.liveChatTime))]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "sr-only" }, [
                  _vm._v(_vm._s(_vm.i18n.liveChatTimeA11y))
                ])
              ],
              2
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isMobile() && _vm.tableData.length
        ? [
            _c("vx-dropdown-primary", {
              ref: "sortDropdown",
              staticClass: "mb-xs-3",
              attrs: { dropdownValues: _vm.mobileSortOptions },
              on: {
                primaryDropdownMounted: _vm.populateDefaultMessage,
                "selected-option": function($event) {
                  _vm.triggerSort($event)
                }
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row d-flex px-xs-3" }, [
        _c("div", { staticClass: "col-sm-6 col-xs-12 py-xs-2 py-sm-3" }, [
          _c("div", { staticClass: "row vx-profile-card d-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 add-card card-default-height mb-xs-3 px-xs-3 py-xs-3 pl-sm-4 pr-sm-3 create-ticket-wrapper"
              },
              [
                _c("div", { staticClass: "create-ticket" }, [
                  _c("span", {
                    staticClass: "icon-big icon-plus",
                    attrs: { title: "Add", "aria-hidden": "true" }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "createTicketHeading",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            _vm.handleCreateTicket()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.createTicket))]
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c("vx-table", {
            attrs: { tableConfig: _vm.tableConfig, tableData: _vm.tableData }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5ea13433", { render: render, staticRenderFns: staticRenderFns })
  }
}