var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-store-list" }, [
    _vm.stores
      ? _c("div", { staticClass: "store__found mb-xs-4 col-xs-12" }, [
          _vm._v(_vm._s(_vm.i18n.distributorsFound) + " "),
          _c("span", { staticClass: "sotre__found_no" }, [
            _vm._v("-\n      " + _vm._s(_vm.noOfDistributor))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.stores
      ? _c(
          "div",
          { staticClass: "col-xs-12 col-sm-6 store__container" },
          _vm._l(_vm.storesList, function(store, index) {
            return _c(
              "div",
              {
                ref: "store" + index,
                refInFor: true,
                staticClass: "store__details px-xs-3 py-xs-3 px-sm-4 py-sm-4",
                class: {
                  active: store.address.id == _vm.selectedStore.STORE_ID
                },
                on: {
                  click: function($event) {
                    _vm.storeSelected(store, "", true, index)
                  }
                }
              },
              [
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "a",
                    {
                      staticClass: "store-name",
                      attrs: { href: "javascript:void(0)" }
                    },
                    [_vm._v(_vm._s(store.name))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "store-distance" }, [
                    _vm._v(_vm._s(store.formattedDistance.toLowerCase()))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "py-xs-3 store-info" }, [
                  store.address.line1
                    ? _c("span", [_vm._v(_vm._s(store.address.line1) + ",")])
                    : _vm._e(),
                  _vm._v(" "),
                  store.address.town
                    ? _c(
                        "span",
                        [
                          _vm._v(_vm._s(store.address.town) + " "),
                          store.address.region
                            ? [
                                _vm._v(
                                  _vm._s(store.address.region.isocodeShort)
                                )
                              ]
                            : _vm._e(),
                          _vm._v(",")
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  store.address.postalCode
                    ? _c("span", [_vm._v(_vm._s(store.address.postalCode))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                store.address.phone
                  ? _c(
                      "a",
                      {
                        staticClass: "store-info",
                        attrs: { href: "tel:" + store.address.phone },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [_vm._v(_vm._s(store.address.phone))]
                    )
                  : _vm._e()
              ]
            )
          })
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-16235923", { render: render, staticRenderFns: staticRenderFns })
  }
}