var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-refills-modal" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "refill-container" },
        [
          _c(
            "p",
            { staticClass: "refill-text mb-xs-5 mb-sm-4 mb-md-5 mt-sm-2" },
            [_vm._v(_vm._s(_vm.i18n.refillAvailable))]
          ),
          _vm._v(" "),
          _vm.refillProducts.length > 0
            ? [
                _c("vx-slider", {
                  attrs: {
                    "product-data": _vm.refillProducts,
                    showSelectCheckbox: true,
                    "is-favorites": _vm.isFavorites,
                    "slider-slides": _vm.sliderSlides,
                    "is-refill": "",
                    "is-bazaar-voice": _vm.isBazaarVoice,
                    "search-browse-i18n": _vm.searchBrowseI18n
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "mb-sm-4 mb-xs-3 section-divider" })
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-center refill-buttons" },
        [
          !_vm.enableSampleCart
            ? _c("div", {
                staticClass: "mb-sm-4 mb-xs-3 mobile-section-divider"
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "btn btn-default mr-xs-0 mr-sm-3 modal-dual-btn modal-btn-IEspacing mb-xs-3 mb-sm-0",
              attrs: { type: "button" },
              on: { click: _vm.handleNoThanks }
            },
            [_vm._v(_vm._s(_vm.i18n.noThanks))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "btn btn-primary ml-xs-0 ml-sm-3 modal-dual-btn add-to-cart-btn modal-btn-IEspacing",
              attrs: { disabled: _vm.disableCartButton, type: "button" },
              on: { click: _vm.handleAddToCart }
            },
            [_vm._v(_vm._s(_vm.i18n.addToCart))]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1c401f7c", { render: render, staticRenderFns: staticRenderFns })
  }
}