/* eslint-disable no-param-reassign */
/* eslint-disable radix */
import debounce from 'lodash.debounce';
import detectDeviceMixin from '../mixins/detect-device-mixin';

export default {
  name: 'vx-stepper-control',
  props: {
    // initial value
    value: {
      type: Number,
      default: 1,
    },
    // minimum value
    minValue: {
      type: Number,
      default: 1,
    },
    // maximum value
    maxValue: {
      type: Number,
      default: 999,
    },
    // stock level value
    stockLevel: {
      type: Number,
      default: 100,
    },
    // indicates to disable stepper control
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [detectDeviceMixin],
  updated() {
    this.showSpinner();
  },
  data() {
    return {
      counter: JSON.parse(this.value),
    };
  },
  watch: {
    counter: debounce(function(newVal, oldVal) {
      const regex = RegExp('[^0-9]', 'g');
      newVal = regex.test(newVal) ? undefined : parseInt(newVal);
      if (regex.test(oldVal)) {
        return;
      }
      if (this.stockLevel > 0) {
        if (this.minValue > this.stockLevel) {
          this.counter = parseInt(this.minValue);
          this.$emit('minValueUpdated', parseInt(this.minValue));
          this.$emit('disableAddToCart', parseInt(this.stockLevel));
          this.hideSpinner();
        } else if (!regex.test(newVal) && newVal !== parseInt(oldVal) && newVal >= parseInt(this.minValue) && newVal <= parseInt(this.maxValue) && newVal <= parseInt(this.stockLevel)) {
          this.$emit('currentCount', newVal);
          this.hideSpinner();
        } else if (!regex.test(newVal) && newVal !== oldVal && newVal >= parseInt(this.minValue) && newVal > parseInt(this.maxValue)) {
          if (parseInt(this.maxValue) > parseInt(this.stockLevel) && newVal > parseInt(this.stockLevel)) {
            this.counter = parseInt(this.stockLevel);
            this.$emit('stockLevelUpdated', parseInt(this.stockLevel));
            this.hideSpinner();
          } else {
            this.counter = parseInt(this.maxValue);
            this.$emit('maxValueUpdated', parseInt(this.maxValue));
            this.hideSpinner();
          }
        } else if (!regex.test(newVal) && newVal !== oldVal && newVal <= parseInt(this.maxValue) && newVal < parseInt(this.minValue) && newVal < parseInt(this.stockLevel) && parseInt(this.minValue) < parseInt(this.stockLevel)) {
          if (parseInt(this.minValue) > parseInt(this.stockLevel)) {
            this.counter = parseInt(this.stockLevel);
            this.$emit('stockLevelUpdated', parseInt(this.stockLevel));
            this.hideSpinner();
          } else {
            this.counter = parseInt(this.minValue);
            this.$emit('minValueUpdated', parseInt(this.minValue));
            this.hideSpinner();
          }
        } else if (!regex.test(newVal) && newVal !== oldVal && newVal >= parseInt(this.minValue) && newVal <= parseInt(this.maxValue) && newVal > parseInt(this.stockLevel)) {
          this.counter = parseInt(this.stockLevel);
          this.$emit('stockLevelUpdated', parseInt(this.stockLevel));
          this.hideSpinner();
        } else {
          this.counter = parseInt(oldVal);
          this.hideSpinner();
        }
      } else if (!regex.test(newVal) && newVal !== parseInt(oldVal) && newVal >= parseInt(this.minValue) && newVal <= parseInt(this.maxValue)) {
        this.$emit('currentCount', newVal);
        this.hideSpinner();
      } else if (!regex.test(newVal) && newVal !== oldVal && newVal >= parseInt(this.minValue) && newVal > parseInt(this.maxValue)) {
        this.counter = parseInt(this.maxValue);
        this.$emit('maxValueUpdated', parseInt(this.maxValue));
        this.hideSpinner();
      } else if (!regex.test(newVal) && newVal !== oldVal && newVal <= parseInt(this.maxValue) && newVal < parseInt(this.minValue)) {
        this.counter = parseInt(this.minValue);
        this.$emit('minValueUpdated', parseInt(this.minValue));
        this.hideSpinner();
      } else {
        this.hideSpinner();
      }
    }, 1000),
    value(newVal) {
      this.counter = parseInt(newVal);
    },
  },
  methods: {
    /**
     * Function to increment count handler
     */
    incrementCount() {
      this.counter = parseInt(this.counter) + 1;
    },
    /**
     * Function to decrement count handler
     */
    decrementCount() {
      if (parseInt(this.counter) > 1) {
        this.counter = parseInt(this.counter) - 1;
      }
    },
    showSpinner() {
      this.$emit('spinnerShow');
    },
    /**
         * Function to hide spinner on update of stepper control
         */
    hideSpinner() {
      this.$emit('spinnerHide');
    },
  },
};
