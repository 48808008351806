var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-player" },
    [
      _c("vx-modal", { ref: "banner__video", attrs: { size: "large" } }, [
        _c(
          "div",
          {
            staticClass:
              "player-container embed-responsive embed-responsive-16by9",
            attrs: { slot: "component" },
            slot: "component"
          },
          [
            _c("vx-youtube-player", {
              attrs: {
                src: _vm.videoSRCWithOptions,
                width: "100%",
                height: "350"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0e54b899", { render: render, staticRenderFns: staticRenderFns })
  }
}