var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-product-tile" }, [
    _c(
      "div",
      { staticClass: "product-tile" },
      [
        _c(
          "div",
          { staticClass: "upper-section" },
          [_vm._t("upper-full-slot")],
          2
        ),
        _vm._v(" "),
        _vm._t("promo-section"),
        _vm._v(" "),
        _c("div", { staticClass: "main-section" }, [
          _c("div", { staticClass: "left-section" }, [
            _c(
              "div",
              { staticClass: "product-image p-md-4 p-sm-1 p-xs-1" },
              [_vm._t("image-slot")],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "product-icons" },
              [_vm._t("product-icons-slot")],
              2
            ),
            _vm._v(" "),
            _c("div", {}, [_vm._t("left-full-slot")], 2)
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-content-section" }, [
            _c("div", { staticClass: "card-content" }, [
              _c(
                "div",
                { staticClass: "card-content-left-section" },
                [_vm._t("details")],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-content-right-section" },
                [_vm._t("right-section-slot")],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-content-bottom-section" },
              [_vm._t("card-content-bottom")],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "top-right" }, [_vm._t("top-right")], 2)
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bottom-section" },
          [_vm._t("bottom-full-slot")],
          2
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0f79b060", { render: render, staticRenderFns: staticRenderFns })
  }
}