/**
 * kauth-mixin is used to set the koch-keys
 *  and delete the koch keys.
 */
import storageMixin from './storage-mixin';

const kauthMixin = {
  data() {
    return {
      kochKeys: {
        KOCHCODE: 'kochCode',
        KOCHREDIRECT: 'kochRedirectUrl',
      },
    };
  },
  mixins: [storageMixin],
  methods: {
    /**
     * used to delete the koch redirect url an koch code.
     */
    deleteKauthData() {
      this.deleteStorage(this.kochKeys.KOCHREDIRECT);
      this.deleteStorage(this.kochKeys.KOCHCODE);
    },
  },
};

export default kauthMixin;
