var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "vx-mini-cart" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: { image: _vm.globals.assetsPath + "images/spinner.gif" }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "selectListModal",
          attrs: {
            size: "small",
            heading:
              _vm.messages["en-US"].searchBrowse.searchResult.selectAList
                .selectListHeading
          }
        },
        [
          _c("vx-save-cart", {
            attrs: {
              slot: "component",
              i18n: _vm.messages["en-US"].searchBrowse.searchResult.selectAList,
              "is-bulk-enabled": _vm.isBulkEnabled,
              "selected-bulk-products": _vm.shoppingCartProducts,
              "is-product": "true"
            },
            on: { "list-saved": _vm.onSelectListSuccess },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.guestListName
        ? [
            _c("vx-save-cart", {
              attrs: {
                i18n:
                  _vm.messages["en-US"].searchBrowse.searchResult.selectAList,
                "is-bulk-enabled": _vm.isBulkEnabled,
                "is-product": "true",
                "guest-list-name": _vm.guestListName,
                "selected-bulk-products": _vm.shoppingCartProducts
              },
              on: { "list-saved": _vm.onSelectListSuccess }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.shoppingCartData.totalItems
        ? _c(
            "div",
            [
              _c("vx-empty-cart", {
                attrs: {
                  "is-mini-cart": "",
                  i18n: _vm.i18n,
                  "msg-visible": _vm.dataLoaded
                }
              })
            ],
            1
          )
        : _c("div", [
            _c(
              "div",
              { staticClass: "mini-cart-container ml-xs-3 mr-xs-4 mt-xs-3" },
              [
                _c("p", { staticClass: "total-items" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.i18n.itemNumber) +
                      " -\n                "
                  ),
                  _c("span", { staticClass: "total-items-count" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.shoppingCartData.totalItems) +
                        "\n                "
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._l(
                  _vm.lastNelements(_vm.shoppingCartData.entries).reverse(),
                  function(product, index) {
                    return _c("vx-cart-product-tile", {
                      key: product.id,
                      attrs: {
                        "is-sample-cart": _vm.isSampleCart,
                        i18n: _vm.i18n,
                        "product-data": product,
                        "is-mini-cart": ""
                      }
                    })
                  }
                )
              ],
              2
            ),
            _vm._v(" "),
            !_vm.isSampleCart
              ? _c(
                  "div",
                  {
                    staticClass:
                      "sub-total-container col-xs-12 py-xs-3 pl-xs-3 pr-xs-4"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "sub-total-text col-xs-7 px-xs-0" },
                      [
                        _c("p", { staticClass: "heading mt-xs-0" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.i18n.subTotal) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.i18n.shippingTaxMsg) +
                              "\n                "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "sub-total-price col-xs-5 px-xs-0" },
                      [
                        _c("p", { staticClass: "total-price" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.shoppingCartData.totalPrice.formattedValue
                              ) +
                              "\n                "
                          )
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-tertiary btn-block form-blue-button",
                on: {
                  click: function($event) {
                    _vm.globals.navigateToUrl("cart")
                  }
                }
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.i18n.cartButton) + "\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "btn btn-tertiary btn-block form-blue-button save-to-list",
                on: {
                  click: function($event) {
                    _vm.handleBulkAddToList()
                  }
                }
              },
              [
                _c("p", [
                  _vm._v("\n                Save to List\n            ")
                ])
              ]
            )
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-220a52a2", { render: render, staticRenderFns: staticRenderFns })
  }
}