var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-assistive-text" }, [
    _c(
      "div",
      {
        staticClass: "assistive-text",
        attrs: { "aria-live": _vm.ariaLive, "aria-relevant": "additions" }
      },
      [
        _vm._t("announce"),
        _vm._v(" "),
        _vm._l(_vm.textToRead, function(text, index) {
          return _c("div", { key: index }, [_vm._v(_vm._s(text))])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-081ce16e", { render: render, staticRenderFns: staticRenderFns })
  }
}