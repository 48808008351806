import vxProductTile from '../../common/vx-product-tile/vx-product-tile.vue';
import vxAccordion from '../../common/vx-accordion/vx-accordion.vue';
import vxCartProductTile from '../../manage-shopping-cart/vx-cart-product-tile/vx-cart-product-tile.vue';
import globals from '../../../../components/common/globals';
import {
  checkoutEventBus,
} from '../../../../modules/event-bus';
import vxProductIcons from '../../common/vx-product-icons/vx-product-icons.vue';
import {
  ProductAvailability,
  discount,
} from '../../../../components/common/mixins/vx-enums';

export default {
  name: 'vx-overview',
  components: {
    vxProductTile,
    vxAccordion,
    vxCartProductTile,
    vxProductIcons,
  },
  props: {
    // Products to show in the overview section
    visibleEntries: Array,
    // Text coming from property file
    i18n: Object,
    // Total items in cart
    totalItems: Number,
    // Promotion data
    promotionData: Object,
    // Indicates if split address is opted in shipping address section
    splitApplicable: Boolean,
    // Total price for subscription product
    totalPrice: Object,
  },
  data() {
    return {
      globals,
      isMultiple: false,
      restrictedEntries: [],
      ProductAvailability,
      restrictionError: false,
      giftWrapDetails: [],
      discount,
    };
  },
  computed: {},
  mounted() {
    // Determines if shipping selected is multiple
    checkoutEventBus.$on('shipping-multiple', () => {
      this.isMultiple = true;
    });
    // Determines if shipping selected is single
    checkoutEventBus.$on('shipping-single', () => {
      this.isMultiple = false;
    });
    // Listens when shipping restriction is found in shipping address section
    checkoutEventBus.$on('restriction-error', (restrictedEntries) => {
      this.restrictedEntries = restrictedEntries;
      if (this.restrictedItems.length === 0) {
        this.restrictionError = false;
      } else {
        this.restrictionError = true;
      }
    });
    // Sets data for subscription popover
    $('.vx-overview')
      .popover({
        trigger: 'focus',
        content: `<p>${this.i18n.subscribePopoverText}</p><a href=${globals.getUrlWithContextPath(globals.navigations.learnMore)}>${
          this.i18n.subscribeLearn
        }</a>`,
        html: true,
        placement: 'bottom',
        container: '.vx-overview',
        selector: '.popover-subscribe',
      });
  },
  methods: {
    /**
         * This function sets title attribute for subscription tooltip
         */
    openTooltip() {
      this.$refs.subscribeInfo[0].children[0].setAttribute(
        'title',
        this.i18n.subscribeIconTitle,
      );
    },
    /**
         * This function gets the quantity of products in cart
         */
    getCartQuantity() {
      let quantity = 0;
      if (this.visibleEntries.length > 0) {
        this.visibleEntries.forEach((item) => {
          quantity += item.quantity;
        });
      }
      return quantity;
    },
    /**
         * This function finds if the product is restricted and shows error
         * @param  {String} productCode product code
         */
    restrictedItems(productCode) {
      let isRestricted = false;
      for (let i = 0; i < this.restrictedEntries.length; i += 1) {
        if (this.restrictedEntries[i].productCode === productCode) {
          isRestricted = true;
          break;
        }
      }
      return isRestricted;
    },
    /**
         * This function gets address in particular format
         * @param  {Object} data deliveryAddress
         */
    getAddress(data) {
      let address = '';
      if (data.line2) {
        address = `${data.line1}, ${data.line2}, ${data.town}, ${data.region.isocodeShort}, ${
          data.postalCode
        }`;
      } else {
        address = `${data.line1}, ${data.town}, ${data.region.isocodeShort}, ${data.postalCode}`;
      }
      return address;
    },
    /**
         * This function gets shipping method in particular format
         * @param  {String} data deliveryMode name
         */
    getShippingMethod(data) {
      const method = data.split('-')
        .join(' ');
      return method;
    },
  },
};
