/**
 * Half banner component
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-uiux-half-banner',
  components: {},
  props: {
    imgTitleData: Object,
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
    };
  },
  computed: {},
  mounted() {
    if (this.imgTitleData.tiles[0] && this.imgTitleData.tiles[0].imgSrcD) {
      const backgroundImageURL = this.getResponsiveImage(this.imgTitleData.tiles[0].imgSrcD, this.imgTitleData.tiles[0].imgSrcT, this.imgTitleData.tiles[0].imgSrcM);
      this.$refs.tileOneImage.style.background = `url("${backgroundImageURL}") no-repeat center`;
      this.$refs.tileOneImage.style.backgroundSize = 'cover';
    }
    if (this.imgTitleData.tiles[0] && this.imgTitleData.tiles[0].headerColor) {
      this.$refs.tileOneHeader.style.color = this.imgTitleData.tiles[0].headerColor;
      if (this.$refs.tileOneHeader.childNodes[0] && this.$refs.tileOneHeader.childNodes[0].style) {
        this.$refs.tileOneHeader.childNodes[0].style.color = this.imgTitleData.tiles[0].headerColor;
      }
    }
    if (this.imgTitleData.tiles[0] && this.imgTitleData.tiles[0].textColor) {
      this.$refs.tileOneDescription.style.color = this.imgTitleData.tiles[0].textColor;
    }
    if (this.imgTitleData.tiles[0] && this.imgTitleData.tiles[0].ctaColor) {
      this.$refs.tileOneButton.style.color = this.imgTitleData.tiles[0].ctaColor;
      this.$refs.tileOneButton.style.backgroundColor = 'transparent';
      this.$refs.tileOneButton.style.border = `3px solid ${this.imgTitleData.tiles[0].ctaColor}`;
    }
    if (this.imgTitleData.tiles[1] && this.imgTitleData.tiles[1].imgSrcD) {
      const backgroundImageURL = this.getResponsiveImage(this.imgTitleData.tiles[1].imgSrcD, this.imgTitleData.tiles[1].imgSrcT, this.imgTitleData.tiles[1].imgSrcM);
      this.$refs.tileTwoImage.style.background = `url("${backgroundImageURL}") no-repeat center`;
      this.$refs.tileTwoImage.style.backgroundSize = 'cover';
    }
    if (this.imgTitleData.tiles[1] && this.imgTitleData.tiles[1].headerColor) {
      this.$refs.tileTwoHeader.style.color = this.imgTitleData.tiles[1].headerColor;
      if (this.$refs.tileTwoHeader.childNodes[0] && this.$refs.tileTwoHeader.childNodes[0].style) {
        this.$refs.tileTwoHeader.childNodes[0].style.color = this.imgTitleData.tiles[1].headerColor;
      }
    }
    if (this.imgTitleData.tiles[1] && this.imgTitleData.tiles[1].textColor) {
      this.$refs.tileTwoDescription.style.color = this.imgTitleData.tiles[1].textColor;
    }
    if (this.imgTitleData.tiles[1] && this.imgTitleData.tiles[1].ctaColor) {
      this.$refs.tileTwoButton.style.color = this.imgTitleData.tiles[1].ctaColor;
      this.$refs.tileTwoButton.style.backgroundColor = 'transparent';
      this.$refs.tileTwoButton.style.border = `3px solid ${this.imgTitleData.tiles[1].ctaColor}`;
    }
  },
  methods: {
    goToLink(link) {
      if (link) {
        window.location.href = link;
      }
    },
  },
};
