var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-create-list-modal" }, [
    _c(
      "form",
      {
        ref: "createListModal",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.createAList($event)
          }
        }
      },
      [
        _c("div", { staticClass: "mb-md-5 mb-xs-4 form-group" }, [
          _c("p", { staticClass: "custom-label" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.i18n.createList.createListLabel) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.listName,
                expression: "listName"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|max:50",
                expression: "'required|max:50'"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("listName") },
            attrs: {
              type: "text",
              name: "listName",
              maxlength: _vm.maxNameLength,
              "aria-label": _vm.i18n.createList.createListLabel
            },
            domProps: { value: _vm.listName },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.listName = $event.target.value
                },
                function($event) {
                  $event.target.value = $event.target.value.substr(0, 50)
                }
              ],
              change: function($event) {
                _vm.listName = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "mt-xs-2" }, [
            _vm.errors.first("listName")
              ? _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.errors.first("listName")))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.inlineError
              ? _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.i18n.createList.existingListError))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "button",
            {
              staticClass: "modal-btn-primary-small form-blue-button",
              attrs: { type: "submit", disabled: _vm.errors.any() }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.i18n.createList.save) +
                  "\n            "
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4c49e8c7", { render: render, staticRenderFns: staticRenderFns })
  }
}