import VeeValidate from 'vee-validate';

const email = {
  getMessage(field) {
    return `The ${field} must be a valid email`;
  },
  validate(value) {
    try {
      const EMAILREG = /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/;
      return VeeValidate.Rules.email(value) || EMAILREG.test(value);
    } catch (e) {
      console.log(e);
    }
    return true;
  },
};

VeeValidate.Validator.extend('email', email);

export default email;
