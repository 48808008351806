var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-auto-suggest-products" }, [
    _c("div", { staticClass: "search-input form-group" }, [
      _c("input", {
        staticClass: "form-control auto-suggest-input",
        attrs: {
          type: "text",
          placeholder: "Search",
          id: "autoSuggest-productSearch"
        },
        on: {
          input: function($event) {
            _vm.onInput($event.target.value)
          },
          blur: _vm.onBlur
        }
      }),
      _vm._v(" "),
      _vm.products.length
        ? _c("div", { staticClass: "autopopulate-dropdown" }, [
            _c(
              "ul",
              _vm._l(_vm.products, function(product) {
                return _c(
                  "li",
                  {
                    key: product.code,
                    staticClass: "search-item",
                    on: {
                      click: function($event) {
                        _vm.onItemClick(product)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        [" +
                        _vm._s(product.code) +
                        "] " +
                        _vm._s(product.name) +
                        "\n      "
                    )
                  ]
                )
              })
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-31e5d60f", { render: render, staticRenderFns: staticRenderFns })
  }
}