var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "vx-mini-cart" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: { image: _vm.globals.assetsPath + "images/spinner.gif" }
      }),
      _vm._v(" "),
      !_vm.shoppingCartData.totalItems
        ? _c(
            "div",
            [
              _c("vx-empty-cart", {
                attrs: {
                  "is-mini-cart": "",
                  i18n: _vm.i18n,
                  "msg-visible": _vm.dataLoaded
                }
              })
            ],
            1
          )
        : _c("div", [
            _c(
              "div",
              { staticClass: "mini-cart-container ml-xs-3 mr-xs-4 mt-xs-3" },
              [
                _c("p", { staticClass: "total-items" }, [
                  _vm._v(_vm._s(_vm.i18n.itemNumber) + " -\n        "),
                  _c("span", { staticClass: "total-items-count" }, [
                    _vm._v(_vm._s(_vm.shoppingCartData.totalItems))
                  ])
                ]),
                _vm._v(" "),
                _vm._l(
                  _vm.lastNelements(_vm.shoppingCartData.entries).reverse(),
                  function(product, index) {
                    return _c("vx-cart-product-tile", {
                      key: product.id,
                      attrs: {
                        "is-sample-cart": _vm.isSampleCart,
                        i18n: _vm.i18n,
                        "product-data": product,
                        "is-mini-cart": ""
                      }
                    })
                  }
                )
              ],
              2
            ),
            _vm._v(" "),
            !_vm.isSampleCart
              ? _c(
                  "div",
                  {
                    staticClass:
                      "sub-total-container col-xs-12 py-xs-3 pl-xs-3 pr-xs-4"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "sub-total-text col-xs-7 px-xs-0" },
                      [
                        _c("p", { staticClass: "heading mt-xs-0" }, [
                          _vm._v(_vm._s(_vm.i18n.subTotal))
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.i18n.shippingTaxMsg))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "sub-total-price col-xs-5 px-xs-0" },
                      [
                        _c("p", { staticClass: "total-price" }, [
                          _vm._v(
                            _vm._s(
                              _vm.shoppingCartData.totalPrice.formattedValue
                            )
                          )
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-tertiary btn-block",
                on: {
                  click: function($event) {
                    _vm.globals.navigateToUrl("cart")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.i18n.cartButton))]
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-25b88d8d", { render: render, staticRenderFns: staticRenderFns })
  }
}