/**
 * A mixin to format price with double and commas
 */
const priceFormatMixin = {
  methods: {
    /**
     * Function gets formatted price
     * @param  {String}   unformattedPrice string of unformatted price to be formatted
     */
    getFormattedPrice(unformattedPrice) {
      const price = unformattedPrice.toFixed(2);
      return `$${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    },
  },
};
export default priceFormatMixin;
