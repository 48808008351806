export default {
  name: 'vx-remove-installation',
  components: {},
  props: {
    // Text coming from property file
    i18n: Object,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    /**
     * This function trigger the call to remove installation service in vx-cart-product-tile
     */
    removeInstallation() {
      this.$emit('remove-installation-success');
    },
  },
};
