var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-add-installation" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("div", { attrs: { tabindex: "0" } }, [
        _c("span", {
          domProps: { innerHTML: _vm._s(_vm.installationDetails.description) }
        }),
        _vm._v(" "),
        _vm.installationDetails.price
          ? _c("p", [
              _vm._v(
                "Price - " +
                  _vm._s(_vm.installationDetails.price.formattedValue)
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xs-12 pt-xs-5 d-flex justify-content-center" },
        [
          _c(
            "button",
            {
              staticClass:
                "add-to-cart btn btn-primary add-to-cart-btn modal-btn-primary-small modal-btn-xs-sticky",
              attrs: { type: "button" },
              on: { click: _vm.addToCart }
            },
            [_vm._v(_vm._s(_vm.i18n.addInstallationButton))]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-18279591", { render: render, staticRenderFns: staticRenderFns })
  }
}