import globals from '../../../../components/common/globals';

export default {
  name: 'vx-contact-method',
  props: {
    // Text and label from properties file
    i18n: {
      type: Object,
      required: true,
    },
    // Dynamic css class for live chat
    liveChatTheme: {
      type: String,
      required: false,
    },
    // Shows call us section
    showContactUs: {
      type: Boolean,
      required: true,
      default: true,
    },
    // Data about chat section
    imageTextData: {
      type: Object,
      required: false,
    },
    // Contact email to be passed from jsp
    contactUsEmail: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      globals,
    };
  },
  methods: {
    navigateToContact() {
      this.globals.navigateToUrl('contactUs');
    },
    chatCustomerSupport() {
      const chatButton = document.querySelector('.embeddedServiceHelpButton button');
      // this.$refs.emailUsBtn.click();
      chatButton.click();
    },
  },
};
