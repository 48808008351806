import messages from '../../../locale/messages';
import vxProductResourcesTab from '../vx-product-resources-tab/vx-product-resources-tab.vue';
import vxProductDetailsTab from '../vx-product-details-tab/vx-product-details-tab.vue';
import vxRelatedProductsTab from '../vx-related-products-tab/vx-related-products-tab.vue';
import vxCompareResultPage from '../../search-browse/vx-compare-result-page/vx-compare-result-page.vue';
import cookiesMixin from '../../common/mixins/cookies-mixin';
import {
  eventBus,
} from '../../../modules/event-bus';
import globals from '../../common/globals';

export default {
  name: 'vx-tab-container',
  components: {
    vxProductResourcesTab,
    vxProductDetailsTab,
    'vx-related-products-tab': vxRelatedProductsTab,
    vxCompareResultPage,
  },
  props: {
    // Copy text coming from properties files
    i18n: {
      type: Object,
    },
    // indicates whether the site is configured for favorites
    isFavorites: {
      type: Boolean,
      default: false,
    },
    // indicates whether the site is configured for Bazaar Voice
    isBazaarVoice: {
      type: String,
      default: '',
    },
    // indicates whether the related products are enabled for the product
    isRelatedEnabled: {
      type: Boolean,
      default: false,
    },
    // Copy text coming from properties files for search browse components
    searchBrowseI18n: {
      type: Object,
    },
    productId: {
      type: String,
      required: true,
    },
    productID: {
      type: String,
      required: true,
    },
  },
  mixins: [cookiesMixin],
  data() {
    return {
      bvProductInlineRating: 'BVRRInlineRating-',
      pdpProductsData: '',
      referenceTypes: {
        compare: 'SELECT',
        related: 'SIMILAR',
        viewed: 'UPSELLING',
      },
      messages: messages['en-US'],
      compareCookieName: 'CompareCookie',
      compareProductIds: [],
      relatedProductIds: [],
      upsellProductIds: [],
      isRelatedProducts: true,
      globals,
    };
  },
  computed: {},
  async mounted() {
    /* eslint no-undef: "error" */

    const self = this;
    if (self.productId.includes('/')) {
      this.productID = self.productId.replace('/', '_');
    } else {
      this.productID = self.productId;
    }
    eventBus.$on('noRelatedProducts', (data) => {
      if (data) {
        this.isRelatedProducts = false;
      }
    });
    eventBus.$on('pdpProductsData', (data) => {
      self.pdpProductsData = data;
      self.pdpProductsData.tabInfo.sort((a, b) => a.sequence - b.sequence);
      self.bvProductInlineRating += data.code;
      self.onPdpData();
      setTimeout(() => {
        if (self.pdpProductsData.defaultTabName) {
          document.querySelector(`#tabs li a[name="${self.pdpProductsData.defaultTabName}"]`).tab('show');
        }
      }, 500);
    });
  },
  methods: {
    /**
     * Function is called on mounted to get the complete product data
     */
    onPdpData() {
      const self = this;
      const pdpData = self.pdpProductsData;

      // check for product resources
      if (pdpData.productReferences) {
        /** Compare Product Data  * */
        self.compareProductIds = self.getProductIds(
          pdpData.productReferences,
          self.referenceTypes.compare,
        );

        /** Related Product Data  * */
        self.relatedProductIds = self.getProductIds(
          pdpData.productReferences,
          self.referenceTypes.related,
        );

        /** Upsell Carousel * */
        self.upsellProductIds = self.getProductIds(
          pdpData.productReferences,
          self.referenceTypes.viewed,
        );
        if (self.upsellProductIds.length > 0) {
          eventBus.$emit('upsellData', pdpData.code);
        }
      }
    },
    /**
     * Function get Product Ids
     * @param  {Array} referencesArr reference array
     * @param  {String} referenceType   type of reference value
     * @return {Array} retruns array of Product Ids
     */
    getProductIds(referencesArr, referenceType) {
      let filteredRefencesData = [];
      filteredRefencesData = referencesArr.filter(
        (item) => item.referenceType === referenceType,
      );
      const productIdArr = filteredRefencesData.map((item) => item.target.code);
      return productIdArr;
    },
  },
};
