import vxRadioButtonGroup from '../../../../components/common/vx-radio-button-group/vx-radio-button-group.vue';
// import shippingMethodDetails from './vx-single-shipping-method-mock';
import CheckoutService from '../../../../components/common/services/checkout-service';
import ManageShoppingCartService from '../../../../components/common/services/manage-shopping-cart-service';
import globals from '../../../../components/common/globals';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import AnalyticsService from '../../../../components/common/services/analytics-service';
import {
  checkoutEventBus,
} from '../../../../modules/event-bus';
import detectDeviceMixin from '../../../../components/common/mixins/detect-device-mixin';

export default {
  name: 'vx-single-shipping-method',
  mixins: [detectDeviceMixin],
  components: {
    vxRadioButtonGroup,
    vxSpinner,
    vxModal,
  },
  props: {
    // Indicates if user us trying to change shipping method
    isEditable: Boolean,
    // Text coming from property file
    i18n: Object,
    // Checkout related data
    checkoutData: Object,
    // Indicates if user changed address
    addressChanged: Boolean,
    // Id of the product
    productId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedShippingMethod: {
        value: '',
        instructions: '',
        deliveryDetails: '',
        label: '',
        name: '',
        cost: '',
      },
      productEntries: {},
      shippingMethodDetails: {},
      cartDetails: {},
      deliverymodes: {},
      deliveryMethods: [],
      checkoutService: new CheckoutService(),
      manageShoppingCartService: new ManageShoppingCartService(),
      analyticsService: new AnalyticsService(),
      globals,
      dataLoaded: false,
      prodName: '',
      prodPrice: 0,
      prodSubBrand: '',
      prodCategory: '',
      currencyCode: '',
    };
  },
  computed: {},
  watch: {
    'checkoutData.deliveryMode': function() {
      this.$nextTick(() => {
        this.showSavedData();
      });
    },
  },
  mounted() {
    // this.selectedShippingMethodData();
    this.getShippingMethod();
    this.callShoppingCart();
    const context = this;
    checkoutEventBus.$on('refresh-delivery-modes', () => {
      context.getShippingMethod();
    });
  },
  methods: {
    /**
         * This function triggers the call that gets shipping method
         */
    getShippingMethod() {
      this.checkoutService.getSingleMethod({}, this.handleGetMethodResponse, this.handleGetMethodError);
      this.$refs.spinner.showSpinner();
    },
    /**
    * This function handles the response of single shipping method call
    */
    handleGetMethodResponse(response) {
      this.deliverymodes = response.data;
      this.$refs.spinner.hideSpinner();
      if (response && response.data) {
        this.shippingMethodDetails = response.data;
        this.deliveryMethods = this.createDeliveryMethodArray();
        this.showSavedData();
      }
    },
    /**
         * This function handles the error of single shipping method call
         */
    handleGetMethodError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function creates array for shipping method radio buttons
         */
    createDeliveryMethodArray() {
      const shippingMethods = this.shippingMethodDetails.deliveryModes.map((item) => {
        let label = '';
        if (this.globals.siteConfig.showDeliveryCost) {
          label = `${item.name} : ${item.deliveryCost.formattedValue}`;
        } else {
          label = item.name;
        }
        return {
          label,
          value: item.code,
          // deliveryDetails: item.description,
          name: item.name,
          cost: item.deliveryCost.formattedValue,
        };
      });
      this.dataLoaded = true;
      return shippingMethods;
    },
    /**
         * This function sets data for shippingMethodDetails Object
         * @param  {Object} data shipping method array
         */
    setData(data) {
      this.selectedShippingMethod.label = data.label;
      this.selectedShippingMethod.value = data.value;
      // this.selectedShippingMethod.deliveryDetails = data.deliveryDetails;
      this.selectedShippingMethod.name = data.name;
      this.selectedShippingMethod.cost = data.cost;
    },
    /**
         * This function sets delivery mode
         */
    setValue() {
      let value = '';
      if (this.shippingMethodDetails.deliveryMode) {
        value = this.shippingMethodDetails.deliveryMode.code;
      }
      return value;
    },
    /**
         * This function sets the lowest shipping method as default in the shipping method radio button
         * or set already saved data if present in the getShippingMethod call in the shipping method radio button
         */
    handleDefault() {
      if (this.selectedShippingMethod.value) {
        this.deliveryMethods.forEach((item) => {
          if (this.selectedShippingMethod.value === item.value) {
            this.$refs.shippingMethod.setSelectedByValue(item.value);
            this.setData(item);
          }
        });
      } else {
        this.$refs.shippingMethod.setSelectedByValue(this.deliveryMethods[0].value);
        this.setData(this.deliveryMethods[0]);
      }
    },
    /**
         * This function stores the shipping method from radio button values when user selectes one
         */
    handleSelected(event) {
      this.setData(event);
    },
    /**
         * This function shows already saved data if present in the getShippingMethod or checkout call
         * (if user refreshes the page after saving multiple shipping method section)
         */
    showSavedData() {
      if (this.checkoutData.deliveryMode && !this.addressChanged) {
        // checkoutEventBus.$emit('show-saved-mode');
        // this.selectedShippingMethod.deliveryDetails = data.deliveryDetails;
        this.selectedShippingMethod.instructions = this.checkoutData.deliveryMode.description;
        const savedData = this.deliveryMethods.filter((method) => method.value === this.checkoutData.deliveryMode.code);
        if (savedData.length) {
          this.setData(savedData[0]);
        } else {
          this.selectedShippingMethod.name = this.checkoutData.deliveryMode.name;
          this.selectedShippingMethod.cost = this.checkoutData.deliveryMode.deliveryCost.formattedValue;
        }
      } else {
        checkoutEventBus.$emit('show-edit-mode');
      }
    },
    /**
         * This function gets cart details
         */
    callShoppingCart() {
      const requestConfig = {};
      this.manageShoppingCartService.getFullCartService(requestConfig, this.handleShoppingCartResponse, this.handleShoppingCartError);
    },
    /**
         * This function handles the response of cart details
         */
    handleShoppingCartResponse(response) {
      this.cartDetails = response.data;
    },
    /**
         * This function handles the error of cart details
         */
    handleShoppingCartError() {
    },
    /**
         * This function saves single Shipping method
         */
    saveSingleMethod() {
      const requestConfig = {};
      const analyticsObject = {
        cartDetails: this.cartDetails,
        deliverymodes: this.deliverymodes,
      };
      if (this.globals.siteId === 'gppro') {
        this.analyticsService.trackShippingGA4(analyticsObject);
      }
      requestConfig.params = {
        deliveryModeId: this.selectedShippingMethod.value,
        deliveryInst: this.selectedShippingMethod.instructions,
      };
      this.checkoutService.saveSingleMethod(requestConfig, this.handleSaveMethodResponse, this.handleSaveMethodError);
      this.$refs.spinner.showSpinner();
    },
    /**
         * This function handles response of save single Shipping method call
         */
    handleSaveMethodResponse() {
      this.checkRecaptchaScore();
    },
    /**
         * This function handles error of save single Shipping method call
         */
    handleSaveMethodError() {
      this.$refs.spinner.hideSpinner();
    },
    /*
    Checking recaptcha score
    */
    checkRecaptchaScore() {
      const self = this;
      if (self.globals.siteConfig.isReCaptchaEnabled && window.grecaptcha) {
        const checkoutService = new CheckoutService();
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(self.globals.reCaptchaSiteKey, {
            action: 'shippingmethod',
          }).then((token) => {
            checkoutService.proceedService({}, self.handleProceedMethodResponse, self.handleProceedMethodError, token);
          });
        });
      }
    },
    /**
         * This function handles response of proceedService method call
         */
    handleProceedMethodResponse(response) {
      const status = response.data.ReCaptchaStatus;
      if (status === 'true') {
        checkoutEventBus.$emit('show-saved-mode');
        checkoutEventBus.$emit('update-cart', 'refresh');
      } else if (status === 'false' && this.checkoutData.totalPrice.value === 0 && this.checkoutData.totalDiscounts.value >= 75) {
        checkoutEventBus.$emit('show-saved-mode');
        checkoutEventBus.$emit('update-cart', 'refresh');
      } else {
        this.$refs.paymentIssueModal.open();
      }
      this.$refs.spinner.hideSpinner();
    },
    /**
         * This function handles error of save single Shipping method call
         */
    handleProceedMethodError() {
      this.$refs.spinner.hideSpinner();
    },
  },
};
