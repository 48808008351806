var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-business-unit-landing pt-xs-4 pt-sm-5" },
    [
      _c(
        "div",
        {
          staticClass: "heading-section d-flex justify-content-between pb-xs-3"
        },
        [
          _c("h3", { staticClass: "heading my-xs-0" }, [
            _vm._v("\n            " + _vm._s(_vm.i18n.heading) + "\n        ")
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row d-flex px-xs-3" }, [
        _c("div", { staticClass: "col-sm-6 col-xs-12 py-xs-2 py-sm-3" }, [
          _c("div", { staticClass: "row vx-profile-card d-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 add-card card-default-height mb-xs-3 px-xs-3 py-xs-3 pl-sm-4 pr-sm-3 add-unit-wrapper"
              },
              [
                _c("div", { staticClass: "add-unit" }, [
                  _c("span", {
                    staticClass: "icon-big icon-plus",
                    attrs: { title: "Add", "aria-hidden": "true" }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            _vm.handleAddBusinessUnit($event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.addBusinessUnit))]
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.dataAvailable
        ? _c(
            "div",
            {
              staticClass: "parent-unit-section mt-xs-3 mt-sm-4 p-xs-3 p-sm-4"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "title-section d-flex justify-content-between pb-xs-4"
                },
                [
                  _c("h4", { staticClass: "title my-xs-0" }, [
                    _vm._v(_vm._s(_vm.i18n.parentSectionHeading))
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "" +
                          _vm.globals.getNavBaseUrl() +
                          _vm.globals.navigations.businessUnitsLanding +
                          "/?" +
                          _vm.UnitDetails.UNIT_ID +
                          "=" +
                          encodeURIComponent(_vm.landingPageData.id)
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.i18n.viewDetails) +
                          "\n            "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "detail-section d-flex" }, [
                _c("div", {
                  staticClass: "parent-details pb-xs-3",
                  domProps: { innerHTML: _vm._s(_vm.parentUnitDetails) }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "parent-address pb-xs-4",
                  domProps: { innerHTML: _vm._s(_vm.parentUnitAddress) }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "user-section d-flex" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-between flex-column"
                    },
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.landingPageData.noOfAdministrators))
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.i18n.administrators))])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-between flex-column"
                    },
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.landingPageData.noOfCustomers))
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.i18n.buyersText))])
                    ]
                  )
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "child-units-section pt-xs-4" },
        [
          _c("h3", { staticClass: "my-xs-0 pb-xs-2 pb-sm-3" }, [
            _vm._v(_vm._s(_vm.i18n.childSectionHeading))
          ]),
          _vm._v(" "),
          _c("vx-table", {
            attrs: {
              tableConfig: _vm.tableConfig,
              tableData: _vm.landingPageData.children
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addBusinessUnitModal",
          attrs: { size: "small", heading: _vm.i18n.addUnitModalHeading },
          on: { close: _vm.resetValues }
        },
        [
          _c(
            "div",
            {
              staticClass: "row",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c(
                "form",
                {
                  staticClass: "col-xs-12",
                  attrs: { novalidate: "true" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.sendAddBusinessUnitRequest($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-xs-4 form-group col-xs-12 col-md-6 px-xs-0 pr-md-3"
                    },
                    [
                      _c(
                        "label",
                        {
                          attrs: {
                            for: "companyName",
                            "aria-hidden": _vm.isDeviceAndroid()
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.unitName))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.businessUnitDetails.companyName,
                            expression: "businessUnitDetails.companyName"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:35",
                            expression: "'required|max:35'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "companyName",
                          maxlength: "35",
                          "aria-label": _vm.i18n.unitName
                        },
                        domProps: {
                          value: _vm.businessUnitDetails.companyName
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.businessUnitDetails,
                              "companyName",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("companyName")
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c("span", {
                              staticClass: "error-icon icon-alert-triangle",
                              attrs: { title: _vm.i18n.iconAlertTitle }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "error-msg" }, [
                              _vm._v(_vm._s(_vm.errors.first("companyName")))
                            ])
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-xs-3 mb-sm-5 form-group col-xs-12 col-md-6 px-xs-0 pl-md-3"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "dropdown-label",
                          attrs: { for: "parentUnitDropdown" }
                        },
                        [_vm._v(_vm._s(_vm.i18n.parentUnit))]
                      ),
                      _vm._v(" "),
                      _c("vx-dropdown-primary", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        ref: "parentUnitDropdown",
                        attrs: {
                          "describedby-aria": _vm.errors.has("parentUnit")
                            ? "parent_unit_landing_error_msg"
                            : null,
                          "required-aria": "true",
                          dropdownValues: _vm.parentUnitsList,
                          "aria-label": _vm.i18n.parentUnit,
                          name: "parentUnit",
                          dropdownError: _vm.errors.has("parentUnit")
                        },
                        on: {
                          "selected-option": function($event) {
                            _vm.businessUnitDetails.parentUnit = $event
                          }
                        },
                        model: {
                          value: _vm.businessUnitDetails.parentUnit.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.businessUnitDetails.parentUnit,
                              "value",
                              $$v
                            )
                          },
                          expression: "businessUnitDetails.parentUnit.value"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("parentUnit")
                        ? _c("div", { staticClass: "mt-xs-2" }, [
                            _c("span", {
                              staticClass: "error-icon icon-alert-triangle",
                              attrs: { title: _vm.i18n.iconAlertTitle }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "error-msg",
                                attrs: {
                                  "aria-live": "assertive",
                                  id: "parent_unit_landing_error_msg"
                                }
                              },
                              [_vm._v(_vm._s(_vm.errors.first("parentUnit")))]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "save-btn modal-btn-primary-small modal-btn-xs-sticky form-blue-button",
                        attrs: { type: "submit" }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.i18n.save) +
                            "\n                    "
                        )
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1d7cd086", { render: render, staticRenderFns: staticRenderFns })
  }
}