var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "vx-share-cart" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "share-cart-body" },
            [
              _c("p", { staticClass: "description", attrs: { role: "text" } }, [
                _vm._v(_vm._s(_vm.i18n.shareListDescription))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-xs-4 mb-xs-5 form-group" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "recipients",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.shareListEmail))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate:recipients",
                      value: "required|email",
                      expression: "'required|email'",
                      arg: "recipients"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.recipientValue,
                      expression: "recipientValue"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("recipients") },
                  attrs: {
                    type: "text",
                    maxlength: "80",
                    name: "recipients",
                    "aria-label": _vm.i18n.shareListEmail,
                    "aria-describedby": "shareList_err_msg"
                  },
                  domProps: { value: _vm.recipientValue },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.recipientValue = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("recipients"),
                        expression: "errors.has('recipients')"
                      }
                    ],
                    staticClass: "mt-xs-2",
                    attrs: { "aria-hidden": !_vm.errors.has("recipients") }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "error-msg",
                        attrs: { id: "shareList_err_msg" }
                      },
                      [_vm._v(_vm._s(_vm.i18n.shareListError))]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.globals.siteConfig.isShareCartAdditionalInfoEnabled
                ? [
                    _c("div", { staticClass: "mb-xs-5 form-group" }, [
                      _c(
                        "label",
                        {
                          attrs: {
                            for: "sendername",
                            "aria-hidden": _vm.isDeviceAndroid()
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.sendername))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.senderNameValue,
                            expression: "senderNameValue"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "input-error": _vm.errors.has("sendername") },
                        attrs: {
                          type: "text",
                          maxlength:
                            _vm.globals.siteVariables.shareCartSenderNameLength,
                          name: "sendername",
                          "aria-label": _vm.i18n.sendername,
                          "aria-describedby": _vm.sender_name_err_msg
                        },
                        domProps: { value: _vm.senderNameValue },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.senderNameValue = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("sendername"),
                              expression: "errors.has('sendername')"
                            }
                          ],
                          staticClass: "mt-xs-2",
                          attrs: {
                            "aria-hidden": !_vm.errors.has("sendername")
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: { id: "sender_name_err_msg" }
                            },
                            [_vm._v(_vm._s(_vm.i18n.sender_name_err_msg))]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-xs-5 form-group" }, [
                      _c(
                        "label",
                        {
                          attrs: {
                            for: "senderemail",
                            "aria-hidden": _vm.isDeviceAndroid()
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.senderemail))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|email",
                            expression: "'required|email'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.senderEmailValue,
                            expression: "senderEmailValue"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "input-error": _vm.errors.has("senderemail") },
                        attrs: {
                          type: "text",
                          name: "senderemail",
                          "aria-label": _vm.i18n.senderemail,
                          "aria-describedby": _vm.sender_email_error_msg
                        },
                        domProps: { value: _vm.senderEmailValue },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.senderEmailValue = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("senderemail"),
                              expression: "errors.has('senderemail')"
                            }
                          ],
                          staticClass: "mt-xs-2",
                          attrs: {
                            "aria-hidden": !_vm.errors.has("senderemail")
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: { id: "sender_email_error_msg" }
                            },
                            [_vm._v(_vm._s(_vm.i18n.sender_email_error_msg))]
                          )
                        ]
                      )
                    ])
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "submit btn button-color text-trans center-block modal-btn-primary-small modal-btn-xs-sticky",
              attrs: { type: "submit", disabled: _vm.errors.any() }
            },
            [_vm._v(_vm._s(_vm.i18n.shareListButton))]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9121b93e", { render: render, staticRenderFns: staticRenderFns })
  }
}