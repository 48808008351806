// import vxPdfDownload from '../../common/vx-pdf-download/vx-pdf-download.vue';
import vxPdfDownload from '../vx-pdf-download/vx-pdf-download.vue';
import vxImageDownload from '../common/vx-image-download/vx-image-download.vue';

export default {
  name: 'vx-download-info',
  components: {
    vxPdfDownload,
    vxImageDownload,
  },
  props: {
    i18n: Object,
    /**
         * Indicates whether it is PDP page
         */
    isPdp: {
      type: Boolean,
      default: false,
    },
    colorCodes: {
      type: Object,
      default: {},
    },
    productEntries: {
      type: Array,
    },
    isImageDownloadEnabled: {
      type: Boolean,
      default: false,
    },
    isPdfDownloadEnabled: {
      type: Boolean,
      default: false,
    },
    certificationsName: {
      type: String,
    },
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    /**
         * Emit Image info
         * @param {object} imageInfo
         */
    onImageDownloadInit(imageInfo) {
      this.$emit('onImageDownloadInit', imageInfo);
    },
    /**
         * Emit PDF Info
         * @param {object} pdfInfo
         */
    onPdfDownloadInit(pdfInfo) {
      this.$emit('onPdfDownloadInit', pdfInfo);
    },
  },
};
