var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-download-info" }, [
    _c("div", { staticClass: "tab-container" }, [
      _c(
        "ul",
        {
          staticClass: "nav nav-tabs",
          attrs: {
            id: "tabs",
            role: "tablist",
            "aria-orientation": "horizontal"
          }
        },
        [
          _vm.isPdfDownloadEnabled
            ? _c(
                "li",
                {
                  staticClass: "tab-item active",
                  attrs: { role: "presentation" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        id: "tab-pdf",
                        "aria-controls": "pane-pdf",
                        href: "#pane-pdf",
                        "data-toggle": "tab",
                        role: "tab",
                        tabindex: "0"
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.downloadInfo.tabPdf))]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isImageDownloadEnabled
            ? _c(
                "li",
                { staticClass: "tab-item", attrs: { role: "presentation" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        id: "tab-image",
                        "aria-controls": "pane-image",
                        href: "#pane-image",
                        "data-toggle": "tab",
                        role: "tab",
                        tabindex: "0"
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.downloadInfo.tabImage))]
                  )
                ]
              )
            : _vm._e()
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab-content", attrs: { id: "content", role: "tablist" } },
      [
        _vm.isPdfDownloadEnabled
          ? _c(
              "div",
              {
                staticClass: "card tab-pane fade in active",
                attrs: {
                  id: "pane-pdf",
                  role: "tabpanel",
                  "aria-labelledby": "tab-pdf"
                }
              },
              [
                _c("vx-pdf-download", {
                  attrs: {
                    "is-pdp": _vm.isPdp,
                    i18n: _vm.i18n.pdfDownload,
                    colorCodes: _vm.colorCodes,
                    productEntries: _vm.productEntries,
                    certificationsName: _vm.certificationsName,
                    productCode: _vm.productCode
                  },
                  on: {
                    onPdfDownloadInit: function($event) {
                      _vm.onPdfDownloadInit($event)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isImageDownloadEnabled
          ? _c(
              "div",
              {
                staticClass: "card tab-pane fade",
                attrs: {
                  id: "pane-image",
                  role: "tabpanel",
                  "aria-labelledby": "tab-image"
                }
              },
              [
                _c("vx-image-download", {
                  attrs: { i18n: _vm.i18n.imageDownload },
                  on: {
                    onImageDownloadInit: function($event) {
                      _vm.onImageDownloadInit($event)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-23cb929b", { render: render, staticRenderFns: staticRenderFns })
  }
}