/**
 * CTA centered text component
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-uiux-cta-centered-text',
  components: {},
  props: {
    uiuxTextData: {
      type: Object,
    },
    uiuxImageData: {
      type: Object,
    },
    componentTheme: {
      type: String,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      isWhite: false,
    };
  },
  computed: {},
  mounted() {
    if (this.uiuxTextData.backgroundColor) {
      this.$el.style.backgroundColor = this.uiuxTextData.backgroundColor;
    }
    if (this.uiuxTextData.textAlignment) {
      this.$el.style.textAlign = this.uiuxTextData.textAlignment;
    }
    if (this.uiuxTextData.headerColor) {
      this.$refs.header.style.color = this.uiuxTextData.headerColor;
      if (this.$refs.header.childNodes[0] && this.$refs.header.childNodes[0].style) {
        this.$refs.header.childNodes[0].style.color = this.uiuxTextData.headerColor;
      }
    }
    if (this.uiuxTextData.textColor) {
      this.$refs.description.style.color = this.uiuxTextData.textColor;
    }
    if (this.uiuxTextData.ctaColor) {
      this.$refs.button.style.color = this.uiuxTextData.ctaColor;
      this.$refs.button.style.border = `1px solid ${this.uiuxTextData.ctaColor}`;
      this.$refs.button.style.backgroundColor = 'transparent';
    }

    if (this.$refs.container.style.backgroundColor === 'white') {
      this.isWhite = true;
    } else {
      this.isWhite = false;
    }
  },
  methods: {},
};
