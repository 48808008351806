var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-place-order" },
    [
      _c(
        "vx-modal",
        {
          ref: "termsConditionModal",
          attrs: { size: "medium", heading: _vm.i18n.termsConditionHeading },
          on: {
            close: function($event) {
              _vm.closetermsConditionModal()
            }
          }
        },
        [
          _c("vx-terms-condition", {
            attrs: { slot: "component" },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.globals.siteConfig.editCommPrefEnabled && !this.existingOpt
        ? _c("div", { staticClass: "my-xs-2" }, [
            _vm.globals.isB2C()
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "market-communication mb-xs-2 form-group" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.addToMarketComm,
                            expression: "user.addToMarketComm"
                          }
                        ],
                        attrs: { id: "addToMarketComm", type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.user.addToMarketComm)
                            ? _vm._i(_vm.user.addToMarketComm, null) > -1
                            : _vm.user.addToMarketComm
                        },
                        on: {
                          click: function($event) {
                            _vm.optinAnalyticsHandler("checkbox")
                          },
                          focus: function($event) {
                            _vm.createAccountServerError = ""
                          },
                          change: function($event) {
                            var $$a = _vm.user.addToMarketComm,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.user,
                                    "addToMarketComm",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.user,
                                    "addToMarketComm",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.user, "addToMarketComm", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "addToMarketComm" } }, [
                        _vm._v(_vm._s(_vm.i18n.addToMarketComm))
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "checkout-terms-cond mt-xs-3 ml-xs-4" }, [
        _vm.globals.siteConfig.isReCaptchaEnabled
          ? _c("span", {}, [
              _vm._v(_vm._s(_vm.i18n.captchaHelpText) + "\n      "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://policies.google.com/privacy",
                    target: "new"
                  }
                },
                [_vm._v("Privacy Policy")]
              ),
              _vm._v(" and\n      "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://policies.google.com/terms",
                    target: "new"
                  }
                },
                [_vm._v("Terms of Service")]
              ),
              _vm._v(" apply.\n    ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.isSubscribed
        ? [
            !(_vm.isPayPal || _vm.isApplePay || _vm.isGooglePay)
              ? _c(
                  "button",
                  {
                    staticClass:
                      "col-xs-12 mt-sm-0 checkout-button btn button-color text-trans",
                    attrs: { disabled: _vm.isButtonDisabled },
                    on: {
                      click: function($event) {
                        _vm.placeOrderRequest($event)
                      },
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.placeOrderRequest($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.placeOrderLabel))]
                )
              : _vm._e()
          ]
        : [
            !(_vm.isPayPal || _vm.isApplePay || _vm.isGooglePay)
              ? _c(
                  "button",
                  {
                    staticClass:
                      "col-xs-12 mt-sm-0 checkout-button btn button-color text-trans",
                    attrs: { disabled: _vm.isButtonDisabled },
                    on: {
                      click: function($event) {
                        _vm.placeOrderRequest($event)
                      },
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.placeOrderRequest($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.subscribeOrderLabel))]
                )
              : _vm._e()
          ],
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "fill-information-label my-xs-3",
          attrs: { role: "contentinfo" }
        },
        [_vm._v(_vm._s(_vm.i18n.fillInformationLabel))]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-dc84ff54", { render: render, staticRenderFns: staticRenderFns })
  }
}