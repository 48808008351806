/* Handles the order confirmation page */
import find from 'lodash.find';
import fecha from 'fecha';
import vxOrderSummary from '../../manage-shopping-cart/vx-order-summary/vx-order-summary.vue';
import vxProductTile from '../../common/vx-product-tile/vx-product-tile.vue';
import globals from '../../common/globals';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import flyoutBannerMixin from '../../common/vx-flyout-banner/vx-flyout-banner-mixin';
import vxCancelOrder from '../../manage-transaction-history/vx-cancel-order/vx-cancel-order.vue';
import {
  ProductAvailability,
  brandNames,
  discount,
  order,
  paymentTypes,
} from '../../common/mixins/vx-enums';
import { globalEventBus } from '../../../modules/event-bus';
import CheckoutService from '../../common/services/checkout-service';
import vxReorderProduct from '../../common/vx-reorder-product/vx-reorder-product.vue';

export default {
  name: 'vx-order-confirmation',
  components: {
    vxOrderSummary,
    vxProductTile,
    vxSpinner,
    vxModal,
    vxCancelOrder,
    vxReorderProduct,
  },
  mixins: [flyoutBannerMixin],
  props: {
    /**
     * Labels, button and caption texts
     */
    i18n: Object,
    contactNumber: String,
  },
  data() {
    return {
      country: 'United States',
      orderDetails: {},
      globals,
      order,
      orderCode: '',
      formattedEntries: {},
      isMultiple: false,
      isGiftable: false,
      isInstallable: false,
      dataLoaded: false,
      installableProducts: [],
      promotionData: {},
      leaseAgrementData: {},
      ProductAvailability,
      brandNames,
      showCancel: false,
      discount,
      checkoutService: new CheckoutService(),
      giveAwayCouponCode: '',
      giveAwayCouponName: '',
      showReferPopup: true,
      paymentTypes,
    };
  },
  computed: {},
  mounted() {
    this.getOrderCode();
    this.getOrderDetails();
    globalEventBus.$emit('announce', this.i18n.orderConfirmation.orderEmail);
    $('.vx-order-confirmation').popover({
      trigger: 'click',
      content: `<p>${
        this.i18n.orderConfirmation.subscribePopoverText
      }</p><a href=${globals.getUrlWithContextPath(globals.navigations.learnMore)}>${
        this.i18n.orderConfirmation.subscribeLearn
      }</a>`,
      html: true,
      placement: 'bottom',
      container: '.vx-order-confirmation',
      selector: '.popover-subscribe',
    });
  },
  methods: {
    /**
     * Shows title Info
     */
    openTooltip() {
      this.$refs.subscribeInfo[0].children[0].setAttribute(
        'title',
        this.i18n.orderConfirmation.subscribeIconTitle,
      );
    },
    /**
     * Used to print the page
     * @param {object} event
     */
    printPage() {
      window.print();
    },
    /**
     * Get the Order code from URL
     */
    getOrderCode() {
      const urlData = window.location.pathname.split('/');
      this.orderCode = urlData[urlData.length - 1];
    },
    /**
     * Forms user address string from an object
     * @param {object} address
     */
    formAddressString(address) {
      let userAddress = '';
      let companyName = '';
      const name = `${address.firstName} ${address.lastName}`;
      if (address && address.companyName) {
        companyName = address.companyName;
      }
      if (address) {
        userAddress = `${name},`
          + `${companyName},`
          + `${address.line1},`
          + `${address.line2},`
          + `${address.town},`
          + `${address.region.isocodeShort},`
          + `${address.postalCode},`
          + `${address.country.isocode}`;
      }
      return userAddress;
    },
    /**
     * Calls Order Details service
     */
    getOrderDetails() {
      this.checkoutService.getOrderDetails(
        {},
        this.handleGetOrderDetailsResponse,
        this.handleGetOrderDetailsError,
        this.orderCode,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
     * Success Handler for Order Details service
     * @param {object} response
     */
    handleGetOrderDetailsResponse(response) {
      if (response && response.data) {
        this.orderDetails = response.data;
        if (this.orderDetails.appliedOrderPromotions) {
          this.orderDetails.appliedOrderPromotions.forEach((promotion) => {
            if (promotion.giveAwayCouponCodes.length) {
              promotion.giveAwayCouponCodes.forEach((coupon) => {
                this.giveAwayCouponCode = coupon.couponCode;
                this.giveAwayCouponName = coupon.name;
              });
            }
          });
        }
        this.showCancel = this.orderDetails.cancellable;
        this.orderDetails.finalAddress = this.formAddressString(
          this.orderDetails.deliveryAddress,
        );
        this.formattedEntries = this.arrayToObject(
          this.orderDetails.entries,
          'entryNumber',
        );
        this.shippingType(this.orderDetails.deliveryGroup);
        this.dataLoaded = true;
        this.promotionData = this.getProductPromotions();
        if (!this.orderDetails.cancellable) {
          this.showReferPopup = false;
        }
        this.$refs.spinner.hideSpinner();
      }
    },
    /**
     * Hides the spinner when Order Details service call fails
     */
    handleGetOrderDetailsError() {
      this.$refs.spinner.hideSpinner();
      document.location.pathname = 'my-account/orders';
    },
    /**
     * Check if shipping is single or multiple
     * @param {Array} deliveryGroup
     */
    shippingType(deliveryGroup) {
      if (deliveryGroup.length > 1) {
        this.isMultiple = true;
      } else {
        this.isMultiple = false;
      }
    },
    /**
     * Checks whether to show Lease Option or not
     * @param {Array} array
     */
    checkLeasable(array) {
      let isLeasable = false;
      array.forEach((group) => {
        group.value.splitEntries.forEach((item) => {
          if (this.formattedEntries[item.entryNumber].leasable) {
            isLeasable = true;
          }
        });
      });
      return isLeasable;
    },
    /**
     * Checks if the product is selected for gift
     * @param {object} product
     */
    checkGiftable(product) {
      let isGift = false;
      if (product.additionalAttributes) {
        if (
          find(product.additionalAttributes.entry, {
            key: 'giftOpted',
            value: 'true',
          })
        ) {
          isGift = true;
          this.isGiftable = isGift;
        }
      }
      return isGift;
    },
    /**
     * Checks if the product is selected for installation
     * @param {object} product
     */
    checkInstallable(product) {
      let installable = false;
      if (product.additionalAttributes) {
        if (
          find(product.additionalAttributes.entry, {
            key: 'installed',
            value: 'true',
          })
        ) {
          installable = true;
          this.installableProducts[product.entryNumber] = product.product.code;
          this.isInstallable = installable;
        }
      }
      return installable;
    },
    /**
     * Used to get install date
     * @param {string} date
     */
    getInstallDate(date) {
      fecha.masks.myMask = 'MM/DD/YYYY';
      const dateObj = fecha.parse(date, 'dddd MMMM Do, YYYY');
      return fecha.format(dateObj, 'myMask');
    },
    /**
     * Checks if single or multiple shipping is used
     * @param {string} data
     */
    getShippingMethod(data) {
      const method = data.split('-').join(' ');
      return method;
    },
    /**
     * Returns Date by Current Timezone
     * @param {string} date
     */
    getDateByTimeZone(date) {
      if (date) {
        const modifiedTime = date.substring(0, 19);
        const utcDate = new Date(Date.parse(modifiedTime));
        const utcTime = utcDate.getTime();
        // If you want to display date according to current timezone uncomment below line
        // const currentDate = new Date();
        // const estOffset = currentDate.getTimezoneOffset() * 60000;
        // const timeZoneName = currentDate.toTimeString().substring(18);
        const estOffset = 300 * 60000;
        const currentTime = utcTime - estOffset;
        return fecha.format(new Date(currentTime), 'mediumDate');
      }
      return undefined;
    },
    /**
     * Returns Product related promotion data
     */
    getProductPromotions() {
      const promotionProductData = {};
      for (
        let i = 0;
        i < this.orderDetails.appliedProductPromotions.length;
        i += 1
      ) {
        for (
          let j = 0;
          j
          < this.orderDetails.appliedProductPromotions[i].consumedEntries.length;
          j += 1
        ) {
          const { orderEntryNumber } = this.orderDetails.appliedProductPromotions[i]
            .consumedEntries[j];
          const { description } = this.orderDetails.appliedProductPromotions[i];
          if (!this.orderDetails.entries[orderEntryNumber].promotionsRevoked) {
            promotionProductData[orderEntryNumber] = description;
          }
        }
      }
      return promotionProductData;
    },
    /**
     * Converts an array to Object
     * @param {Array} array
     * @param {string} keyField
     */
    arrayToObject(array, keyField) {
      const modifiedObj = array.reduce((obj, item) => {
        // eslint-disable-next-line no-param-reassign
        obj[item[keyField]] = item;
        return obj;
      }, {});
      return modifiedObj;
    },
    /**
     * Calls show lease agreement service
     * @param {object} event
     */
    showLeaseAgreement() {
      this.checkoutService.showLeaseAgreement(
        {},
        this.handleLeaseAgreementResponse,
        this.handleLeaseAgreementError,
        this.orderDetails.code,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
     * Open Terms and condition modal based on the response from show lease agreement
     * @param {object} response
     */
    handleLeaseAgreementResponse(response) {
      if (response && response.data) {
        this.leaseAgrementData = response.data;
        this.$refs.spinner.hideSpinner();
        this.$refs.viewTermsModal.open();
      }
    },
    /**
     * Hides the spinner on lease agreement service call error
     * @param {object} error
     */
    handleLeaseAgreementError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * Open Cancel Order Modal
     * @param {object} event
     */
    handleCancelOrder(event) {
      this.$refs.cancelOrder.open(event);
    },
    /**
     * On Sucess handler of cancel order, flyout message will be shown and show updated order details
     */
    handleCancelOrderSuccess() {
      this.$refs.cancelOrder.close();
      this.showReferPopup = false;
      this.showFlyout(
        'success',
        this.i18n.orderConfirmation.cancelOrderSuccessMsg,
        false,
      );
      this.showCancel = false;
      this.getOrderDetails();
    },
    /**
     * On Error handler of cancel order, close the cancel order modal and show a flyout message
     */
    handleCancelOrderError() {
      this.$refs.cancelOrder.close();
      this.showFlyout(
        'error',
        this.i18n.orderConfirmation.cancelOrderErrorMsg,
        true,
      );
    },
    /**
     * Close the Cancel order modal
     */
    handleKeepOrder() {
      this.$refs.cancelOrder.close();
    },
  },
};
