import get from 'lodash.get';
import arraySort from 'array-sort';
import vxPagination from '../vx-pagination/vx-pagination.vue';
import { dataTablesEventBus } from '../../../modules/event-bus';
import { sortPriceAsNumber } from '../functions';

// Documentation can be found at https://gpdigitalcore.atlassian.net/wiki/spaces/GDC/pages/2131361830/Vx+Data+tables+vx-table
/*
POINTS TO REMEMBER
1. Fields of configuration(tableConfig.columnConfiguration)
  {
    field: string,
    customHeaderName: string (mandatory),
    customHeaderTemplate: function/ES6 function if using 'this',
    customHeaderClass: single or space seperated class strings,
    customTemplate: function/ES6 function if using 'this',
    visibilityCondition: function/ES6 function if using 'this',
    emptyTable: string (message to display when there is no data in table),
    childRowTemplate: function returning template. If ES6 function using 'this'
  }
2. if using 'this' context inside the configuation always use es6 or arrow function
3. visibilityCondition is for conditionally deciding if a particular column must be visible
4. tableConfig also takes a field rowCount apart from columnConfiguration.
    The rowCount defines the number of rows of data visible per page.
5. if emptyTable field is not provided/configured, the default message is shown
6. field data can be simple or nested fields delimited by dots, the field data will not be considered if customemplate field is provided
7. 'enableSelectAll' is a boolean field to enable the select all feature of the vx-table, this is false by default,
8. selectAllCallback -  is a callback function that should be set if the enableSelectAll field is set to true,
        this function will return the array of selected data every time a checkbox is checked/unchecked.
        The parent component should handle the data and store it in some variable for later use,
9.  Fields of configuration(tableConfig.childRow)
    {
      template: function to return template, <tr> .... </tr>
      field: string with the field to get Data from, only in case if the field is array, else this is not required and the entire row data gets passed
    }
10. childRowTemplate - template for child row, this gets populated for each actual row data.
    can only be one table row <tr>...</tr>, hence multiple table rows or other elements are not possible.
*/

export default {
  name: 'vx-table',
  components: {
    vxPagination,
  },
  props: {
    msg: String,
    tableConfig: {
      Type: Object,
      default: null,
    },
    tableData: {
      Type: Array,
      default: [],
    },
  },
  data() {
    return {
      resultantColumnConfiguration: [],
      resultantHeaderTitles: [],
      constructedTableData: [],
      currentPage: 1,
      pageSize: 1,
      selectAllCheckbox: false,
      sortSetting: { column: 1, type: 1 }, // type 1 => ascending order and 0 => descending order
    };
  },
  methods: {
    constructTableData() {
      if (this.tableData !== null || this.tableData.length) {
        this.getDatainRange(0, this.tableConfig.rowCount);
      }
    },
    /*
      getDatainRange gets the data between start and end with start inclusive
    */
    getDatainRange(start, end) {
      // we try to filter-out the columns we need not display before gathering data
      this.eliminateUnwantedColumns();

      // start inclusive and end exclusive
      this.constructedTableData = this.tableData.slice(start, end);
      if (this.tableConfig.enableSelectAll === true) {
        this.constructedTableData = this.constructedTableData.map((row) => {
          row.selected = false;
          return row;
        });
      }
      this.constructedTableData = this.constructedTableData.map((rowData) => {
        const tableRowDat = rowData;
        this.resultantColumnConfiguration.forEach((coulmnConfig, index) => {
          if (Object.prototype.hasOwnProperty.call(coulmnConfig, 'customTemplate')) {
            const element = this.composeTableData(coulmnConfig, rowData);
            // create a dummy wrapper element to extract the inner text of the template provided
            const wrapper = document.createElement('div');
            wrapper.innerHTML = element;
            tableRowDat[`field${index}`] = wrapper.innerText.trim();
          } else {
            tableRowDat[`field${index}`] = this.composeTableData(coulmnConfig, rowData);
          }
        });
        return tableRowDat;
      });
    },
    handlePaginationClick(pageNumber) {
      // sync current page between pagination and table
      this.currentPage = pageNumber;
      this.getDatainRange((pageNumber - 1) * this.tableConfig.rowCount, ((pageNumber - 1) * this.tableConfig.rowCount) + this.tableConfig.rowCount);
    },
    /*
      this function basically filters that needs to be displayed based on visibilityCondition configuration provided.
     */
    eliminateUnwantedColumns() {
      if (get(this.tableConfig, 'columnConfiguration', null) !== null) {
        this.resultantColumnConfiguration = [];
        this.tableConfig.columnConfiguration.forEach((configObject) => {
          const configuration = configObject;
          const visibilityCondition = get(configuration, 'visibilityCondition', null);
          if (visibilityCondition === null || (typeof visibilityCondition === 'function' && visibilityCondition.call() === true)) {
            // checking if customHeaderTemplate is part of the config, if so we dont consider the customHeaderName field
            if (Object.prototype.hasOwnProperty.call(configuration, 'customHeaderTemplate')) {
              // if it is a custom header temaplate, then it could be a function or template string
              // if its a function, we execute that and assign the resultant tenplate to customHeaderTemplate else leave it as such
              if (typeof configuration.customHeaderTemplate === 'function') {
                const template = configuration.customHeaderTemplate();
                configuration.customHeaderTemplate = template;
              }
            }
            // we created a column configuration that has filtered out all the columns which are not required as per provided condition
            this.resultantColumnConfiguration.push(configuration);
          }
        });
      }
    },
    setSortedData(columnIndex, sortType) {
      const { sortAsNumber } = this.resultantColumnConfiguration[columnIndex];
      let sortBy = `field${columnIndex}`;
      if (sortAsNumber) {
        sortBy = sortPriceAsNumber(sortBy);
      }
      this.constructedTableData = arraySort(this.constructedTableData, sortBy, sortType);
    },
    sortColumn(columnIndex) {
      const sortType = { reverse: false };
      if (columnIndex === this.sortSetting.column) {
        if (this.sortSetting.type === 1) {
          sortType.reverse = true;
          this.sortSetting.type = 0;
        } else {
          this.sortSetting.type = 1;
        }
      } else {
        this.sortSetting.type = 1;
      }

      this.sortSetting.column = columnIndex;
      this.setSortedData(columnIndex, sortType);
    },
    sortMobileData(columnIndex, sortOrder) {
      this.setSortedData(columnIndex, { reverse: sortOrder === 'dsc' });
    },
    getChildRowTemplate(data) {
      return this.tableConfig.childRow.template(data);
    },
    composeTableData(fieldObject, rowData) {
      if (Object.prototype.hasOwnProperty.call(fieldObject, 'field')) {
        let field = null;
        if (Array.isArray(fieldObject.field)) {
          field = fieldObject.field;
        } else {
          // for dot de-limited nested fields
          field = fieldObject.field.split('.');
        }
        const tempData = get(rowData, field, '');
        return tempData;
      } if (Object.prototype.hasOwnProperty.call(fieldObject, 'customTemplate')) {
        const template = fieldObject.customTemplate(rowData);
        return template;
      }
      return undefined;
    },
    toggleSelectAll() {
      this.constructedTableData = this.constructedTableData.map((row) => {
        row.selected = this.selectAllCheckbox;
        return row;
      });
    },
    selectSingleItem(itemIndex) {
      this.constructedTableData[itemIndex].selected = !this.constructedTableData[itemIndex].selected;
      if (Object.prototype.hasOwnProperty.call(this.tableConfig, 'selectAllCallback')) {
        const selectedData = this.constructedTableData.filter((data) => data.selected);
        this.tableConfig.selectAllCallback(selectedData);
      }
    },
    classifySortClass(index) {
      if (index === this.sortSetting.column) {
        if (this.sortSetting.type === 1) {
          return 'sorting-asc';
        }
        return 'sorting-desc';
      }
      return 'no-sorting';
    },
    displayEmptyTableMessage() {
      return Object.prototype.hasOwnProperty.call(this.tableConfig, 'emptyTable') && this.tableConfig.length > 0 && this.tableConfig.emptyTable.length ? this.tableConfig.emptyTable : 'No Data Available';
    },
    verifyChildRowData(index) {
      const childRow = get(this.tableConfig, ['childRow', 'template']);
      if (childRow !== undefined) {
        if (get(this.tableConfig, ['childRow', 'accordionChild']) !== undefined) {
          if (this.constructedTableData[index].visible) {
            return true;
          }
          return false;
        }
        return true;
      }
      return false;
    },
    verifyDataFieldIsArray() {
      const { field } = this.tableConfig.childRow;
      const fieldValue = get(this.constructedTableData, [0, field]);
      return (fieldValue !== null && fieldValue !== undefined) && Array.isArray(fieldValue);
    },
    getChildRowClass() {
      const rowClass = get(this.tableConfig, ['childRow', 'rowClass']);
      if (rowClass !== undefined && rowClass !== null) {
        return rowClass;
      }
      return '';
    },
    toggleVisibility(index) {
      const dataCopy = [...this.constructedTableData];
      dataCopy[index].visible = !dataCopy[index].visible;
      this.constructedTableData = dataCopy;
    },
    getChevronClass(index) {
      return this.constructedTableData[index].visible ? 'table-icon-chevron-up rotate-icon' : 'table-icon-chevron-up';
    },
  },
  mounted() {
    this.constructTableData();
    dataTablesEventBus.$on('mobile-sort', (val) => {
      const { index, order } = val;
      this.sortMobileData(index, order);
    });
  },
  watch: {
    tableData() {
      /*
        if data loads asynchronously we use this watch to watch for the data to load and then load table
      */
      this.constructTableData();
    },
    selectAllCheckbox(val) {
      this.constructedTableData = this.constructedTableData.map((row) => {
        row.selected = val;
        return row;
      });
      if (Object.prototype.hasOwnProperty.call(this.tableConfig, 'selectAllCallback')) {
        if (val === true) {
          this.tableConfig.selectAllCallback(this.constructedTableData);
        } else {
          this.tableConfig.selectAllCallback([]);
        }
      }
    },
  },
};
