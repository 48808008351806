var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-cancel-order row mx-xs-0" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.i18n.cancelOrderText))]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "cancel-order mt-xs-5 col-xs-12 px-xs-3 px-sm-0 mb-xs-3"
        },
        [
          _c(
            "button",
            {
              staticClass:
                "btn btn-default text-trans mr-sm-5 col-xs-12 col-sm-6",
              on: {
                click: function($event) {
                  _vm.keepOrder($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.i18n.keepOrder))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "btn button-color text-trans col-xs-12 col-sm-6 mt-xs-3 mt-sm-0",
              on: {
                click: function($event) {
                  _vm.cancelOrder($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.i18n.cancelOrder))]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3e1d997b", { render: render, staticRenderFns: staticRenderFns })
  }
}