import { dataTablesEventBus } from '../../../modules/event-bus';

const vxTableUtilsMixin = {
  methods: {
    /**
     * Function to trigger sort in mobile devices.
     */
    triggerSort(e) {
      const sortValue = e.value.split('-');
      dataTablesEventBus.$emit('mobile-sort', { index: sortValue[0], order: sortValue[1] });
    },
  },
};

export default vxTableUtilsMixin;
