var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-container" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.msg))]),
      _vm._v(" "),
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          text: "Loading...",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-primary", on: { click: _vm.loadSpinner } },
        [_vm._v("Launch Full Spinner")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "main__inner-wrapper" }, [
        _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xs-12" },
              [
                _c(
                  "vx-product-tile",
                  [
                    _c("template", { slot: "top-right" }, [
                      _c("span", { staticClass: "col-xs-2 px-xs-0" }, [
                        _c("span", {
                          staticClass: "cart-icons icon-trash",
                          on: {
                            click: function($event) {
                              _vm.deleteCartItem("sdf")
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "image-slot" }, [
                      _c("img", {
                        attrs: { src: "globals.assetsPath+'images/'" }
                      })
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "product-icons-slot" }, [
                      _c("div", { staticClass: "pt-xs-3 pb-xs-3" }, [
                        _c("span", {
                          staticClass: "cart-icons icon-certification pr-xs-2"
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "cart-icons icon-shipping pr-xs-2"
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "cart-icons icon-online pr-xs-2"
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "cart-icons icon-subscription pr-xs-2"
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "cart-icons icon-seasonal" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "details" }, [
                      _c("p", { staticClass: "product-title mb-xs-2" }, [
                        _c("a", { attrs: { href: "" } }, [
                          _vm._v("productEntry.product.name")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "product-code mb-xs-2" }, [
                        _vm._v("productEntry.product.code")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "product-stock mb-xs-3" }, [
                        _vm._v("productEntry.product.stock.stockLevelStatus")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "product-price mb-xs-3" }, [
                        _c("span", { staticClass: "product-current-price" }, [
                          _vm._v("productEntry.basePrice.formattedValue")
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "product-old-price" },
                          [
                            _c("strike", [
                              _vm._v("productEntry.weblistPrice.formattedValue")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [
                          _c("p", { staticClass: "product-promo mb-xs-3" }, [
                            _vm._v("promotion Code")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "product-quantity" }, [
                            _vm._v("i18n.quantity")
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c("vx-stepper-control"),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "product-promo mb-xs-3" },
                                [_vm._v("promotion Code")]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "right-section-slot" }, [
                      _c("p", { staticClass: "product-discount" }, [
                        _vm._v("-productEntry.totalPrice.formattedValue")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "product-total-price" }, [
                        _vm._v("productEntry.totalPrice.formattedValue")
                      ])
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xs-12" },
              [
                _c(
                  "vx-accordion",
                  {
                    attrs: { heading: "Accordian Heading", "is-slot-based": "" }
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "component" }, slot: "component" },
                      [_vm._v("This will be your component")]
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-8" },
              [
                _c("vx-add-edit-address", {
                  attrs: { i18n: _vm.messages.common.address }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-12" },
            [
              _c("vx-rich-text", {
                attrs: { richTextMarkup: _vm.richTextMarkup }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("vx-product-solution", { attrs: { tabsData: _vm.tabsData } }),
      _vm._v(" "),
      _c("section-title", { attrs: { sectionTitle: _vm.sectionTitle } }),
      _vm._v(" "),
      _c("faq-comp", { attrs: { accordionGroupData: _vm.accordionGroupData } }),
      _vm._v(" "),
      _c("vx-sbs", { attrs: { "sbs-data": _vm.mktgSBSData } }),
      _vm._v(" "),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-12" },
            [
              _c(
                "vx-profile-card",
                {
                  attrs: {
                    "card-classes": _vm.preferenceCardClasses,
                    "is-default-height": _vm.preferenceCardDefaultHeight,
                    "has-footer": false
                  }
                },
                [
                  _c("template", { slot: "heading" }, [
                    _c("li", { staticClass: "card-heading" }, [
                      _c("h4", [_vm._v(_vm._s(_vm.personalDetailsTitle))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "details" },
                    _vm._l(_vm.personalDetails, function(item) {
                      return _c("li", { key: item, staticClass: "card-item" }, [
                        _vm._v(_vm._s(item))
                      ])
                    })
                  ),
                  _vm._v(" "),
                  _c("template", { slot: "icons" }, [
                    _c("li", { staticClass: "card-icon" }, [
                      _c("a", { on: { click: _vm.logMessage } }, [
                        _c("span", {
                          staticClass: "icon-default icon-small icon-trash"
                        })
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "footer" }, [_vm._v("​")])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-12" },
            [
              _c(
                "vx-profile-card",
                {
                  attrs: { "has-icons": _vm.unitCardIcons, "has-footer": false }
                },
                [
                  _c("template", { slot: "heading" }, [
                    _c("li", { staticClass: "card-heading-link" }, [
                      _c("a", { staticClass: "#" }, [
                        _c("h4", [_vm._v("Unit ID comes here")])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "details" }, [
                    _c("li", { staticClass: "card-item" }, [
                      _vm._v(
                        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "footer" }, [_vm._v("​")])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-12" },
            [
              _c(
                "vx-profile-card",
                {
                  attrs: {
                    "is-disabled": _vm.isAddressDisable,
                    "has-icons": true,
                    "has-footer": true
                  }
                },
                [
                  _c("template", { slot: "heading" }, [_vm._v(_vm._s(""))]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "details" },
                    _vm._l(_vm.addressDetails, function(item) {
                      return _c("li", { key: item, staticClass: "card-item" }, [
                        _vm._v(_vm._s(item))
                      ])
                    })
                  ),
                  _vm._v(" "),
                  _c("template", { slot: "footer" }, [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isAddressDisable,
                            expression: "isAddressDisable"
                          }
                        ],
                        staticClass:
                          "d-flex col-xs-12 align-items-center justify-content-between"
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "card-item card-item-bold" },
                          [_vm._v("Default Address")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "link-regular link-bold",
                            attrs: { href: "#" }
                          },
                          [_vm._v("Enable")]
                        )
                      ]
                    )
                  ])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-6" },
            [
              _c(
                "vx-profile-card",
                { attrs: { "card-type": "social", "is-default-height": true } },
                [
                  _c("template", { slot: "heading" }, [
                    _c("h4", [_vm._v("Social Accounts")]),
                    _vm._v(" "),
                    _c("a", { attrs: { href: "" } }, [
                      _c("span", {
                        staticClass: "icon-default icon-big icon-edit"
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "details" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "row d-flex align-items-center justify-content-between"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "card-items align-items-center d-flex"
                          },
                          [
                            _c("span", {
                              staticClass: "icon-default icon-big icon-facebook"
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "pl-xs-2" }, [
                              _vm._v("Facebook")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-item-link" }, [
                          _c(
                            "a",
                            {
                              staticClass: "link-regular link-bold",
                              attrs: { href: "#" }
                            },
                            [_vm._v("Connect")]
                          )
                        ])
                      ]
                    )
                  ])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-6" },
            [
              _c(
                "vx-profile-card",
                { attrs: { "card-type": "add", "is-default-height": true } },
                [
                  _c("template", { slot: "details" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _c("span", { staticClass: "icon-big icon-plus" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "ml-xs-1 link-large link-bold" },
                        [_vm._v("Add Address")]
                      )
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-6" },
            [
              _c(
                "vx-profile-card",
                {
                  attrs: {
                    "card-type": "column",
                    "has-icons": false,
                    "is-default-height": false,
                    "has-footer": false
                  }
                },
                [
                  _c("template", { slot: "heading" }, [
                    _c("li", { staticClass: "card-heading" }, [
                      _c("h4", [_vm._v("Parent Business Unit")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "details" }, [
                    _c(
                      "ul",
                      { staticClass: "col-xs-6" },
                      _vm._l(_vm.personalDetails, function(item) {
                        return _c(
                          "li",
                          { key: item, staticClass: "card-item" },
                          [_vm._v(_vm._s(item))]
                        )
                      })
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "col-xs-6" },
                      _vm._l(_vm.personalDetails, function(item) {
                        return _c(
                          "li",
                          { key: item, staticClass: "card-item" },
                          [_vm._v(_vm._s(item))]
                        )
                      })
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12" }, [
      _c("h1", [_vm._v("Card Component")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-03b66ef0", { render: render, staticRenderFns: staticRenderFns })
  }
}