/**
 * vx-product-carousel is a cms carousel component
 * where vx-slider is embedded in it to achieve the slider functionality.
 */
import globals from '../../common/globals';
import vxSlider from '../../pdp/vx-slider/vx-slider.vue';

export default {
  name: 'vx-product-carousel-render',
  components: {
    vxSlider,
  },
  props: {
    productCarouselData: {
      type: Object,
      default: () => ({
        componentHeader: 'Dixie Ultra® Interfold Napkin Dispensers',
        componentTheme: 'product-solution ruler',
        ctaText: 'Learn More',
        ctaStyle: 'btn btn-primary',
        ctaLink: 'https://www.google.com',
        noOfColumns: '5',
        isCarousel: false,
        products: [
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/l11pl.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/sxp9path.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/toc.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/ch56nc7.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/sxp9path.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/toc.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/sxp9path.png?la=en',
          },
          {
            name: 'sample',
            url: '#',
            code: 'Cost Effective',
            price: '890.78',
            imageUrl:
              'https://www.gppro.com/-/media/cpg/gpprofessional/img/serve-greatness/cafeteria/saladcoldbar/toc.png?la=en',
          },
        ],
      }),
    },
  },
  computed: {},
  data() {
    return {
      globals,
      sliderSlides: {
        desktop: {
          slides: 4,
          spaceAllowed: 0,
        },
        tablet: {
          slides: 4,
          spaceAllowed: 16,
        },
        mobile: {
          slides: 2,
          spaceAllowed: 16,
        },
      },
    };
  },
  methods: {
    /**
     * sets the tile width based on the columns count
     */
    imageTileWidth() {
      if (this.productCarouselData.noOfColumns) {
        return `width: ${100 / this.productCarouselData.noOfColumns}%`;
      }
      return undefined;
    },
  },
};
