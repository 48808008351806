var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-slider" }, [
    _vm.productData
      ? _c(
          "div",
          [
            _vm.title
              ? _c("h3", { staticClass: "mb-xs-4" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showNavigationArrows
              ? _c("button", {
                  staticClass: "swiper-button-prev icon-chevron-left",
                  class: { "swiper-button-disabled": _vm.firstSlide },
                  attrs: {
                    "aria-label": _vm.i18n.iconChevronLeftTitle,
                    tabindex: "0",
                    disabled: _vm.firstSlide ? true : false
                  },
                  on: {
                    click: function($event) {
                      _vm.slidePrev()
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "swiper",
              { attrs: { options: _vm.swiperOptions } },
              _vm._l(_vm.productData, function(product, index) {
                return _c(
                  "swiper-slide",
                  { key: index },
                  [
                    !_vm.isSlotBased
                      ? [
                          _c("vx-product-tile-vertical", {
                            attrs: {
                              product: product,
                              showSelectOption: _vm.showSelect,
                              "is-favorite-visible": _vm.isFavorites,
                              "is-refill": _vm.isRefill,
                              "is-bazaar-voice": _vm.isBazaarVoice,
                              i18n: _vm.searchBrowseI18n
                            }
                          })
                        ]
                      : [
                          _vm._t("slide", null, {
                            product: product,
                            i18n: _vm.searchBrowseI18n
                          })
                        ]
                  ],
                  2
                )
              })
            ),
            _vm._v(" "),
            _vm.showNavigationArrows
              ? _c("button", {
                  staticClass: "swiper-button-next icon-chevron-right",
                  class: { "swiper-button-disabled": _vm.lastSlide },
                  attrs: {
                    tabindex: "0",
                    "aria-label": _vm.i18n.iconChevronRightTitle,
                    disabled: _vm.lastSlide ? true : false
                  },
                  on: {
                    click: function($event) {
                      _vm.slideNext()
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-18353054", { render: render, staticRenderFns: staticRenderFns })
  }
}