import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import viewSiteContent from '../../../../locale/view-site-content-i18n';
import { globalEventBus, eventBus } from '../../../../modules/event-bus';

export default {
  name: 'vx-product-solution',
  components: {
    vxModal,
  },
  mixins: [mobileMixin],
  props: {
    productSolutionData: {
      type: Object,
      required: true,
      default: {},
    },
  },
  data() {
    return {
      globals,
      i18n: viewSiteContent.productSolution,
      backgroundImg: '',
    };
  },
  computed: {
    backgroundStyle() {
      return {
        'background-color': this.productSolutionData.backgroundColor ? this.productSolutionData.backgroundColor : '',
      };
    },
    backgroundAlt() {
      return this.getResponsiveAlt(this.productSolutionData.backgroundImageAltTextD, this.productSolutionData.backgroundImageAltTextT, this.productSolutionData.backgroundImageAltTextM);
    },
    imageTileWidth() {
      if (this.productSolutionData.noOfColumns) {
        return `width: ${100 / this.productSolutionData.noOfColumns}%`;
      }
      return 'width: 100%';
    },
  },
  mounted() {
    this.backgroundImg = this.getResponsiveImage(this.productSolutionData.backgroundImageD, this.productSolutionData.backgroundImageT, this.productSolutionData.backgroundImageM);
  },
  methods: {
    /**
         * Function to open video in the modal by sending the video source url.
         * @param {String} videoSRC
         */
    openModal(videoSRC) {
      eventBus.$emit('open-player', videoSRC);
    },
    /**
         * Function to emit scroll hash id to scroll the page to particular position.
         * @param {String} link
         */
    emitScrollSignal(link) {
      globalEventBus.$emit('scroll-to', link);
    },
  },
};
