var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-user-group-details" },
    [
      _c("vx-details-section", {
        attrs: {
          "i18n-user-status": _vm.i18n.userStatus,
          i18n: _vm.i18n.unitDetail,
          "details-data": _vm.detailPageData.detailsData,
          "edit-button": true,
          "disable-button": _vm.showDisablebutton,
          "link-button": true
        },
        on: {
          disable: _vm.handleDisableClick,
          enable: _vm.handleEnableClick,
          edit: _vm.handleEditClick,
          link: _vm.handleDeleteClick
        }
      }),
      _vm._v(" "),
      _c("vx-info-section", {
        attrs: {
          i18n: _vm.i18n.permissions,
          "info-data": _vm.detailPageData.permissionsGroupData,
          "new-button": false,
          "existing-button": true,
          "icon-class": "icon-trash",
          "display-active-only": false
        },
        on: {
          delete: _vm.handleRemoveClick,
          addExistingTo: _vm.handleAddExistingClick
        }
      }),
      _vm._v(" "),
      _c("vx-info-section", {
        attrs: {
          i18n: _vm.i18n.userGroups,
          "info-data": _vm.detailPageData.membersGroupData,
          "new-button": false,
          "existing-button": true,
          "icon-class": "icon-trash",
          "display-active-only": false
        },
        on: {
          delete: _vm.handleRemoveClick,
          addExistingTo: _vm.handleAddExistingClick
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "disableUserGroupModal",
          attrs: {
            heading: _vm.i18n.disableUserGroupModal.heading,
            size: "extra-small"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c(
                "p",
                { staticClass: "disable-user-group-text mb-xs-0 pb-xs-5" },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.i18n.disableUserGroupModal.message1) +
                      _vm._s(_vm.detailPageData.unitDetailsData.name) +
                      " " +
                      _vm._s(_vm.i18n.disableUserGroupModal.message2) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "disable-user-group-button modal-btn-primary-small modal-btn-xs-sticky form-blue-button",
                    on: { click: _vm.sendDisableRequest }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.i18n.disableUserGroupModal.disableText) +
                        "\n                "
                    )
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "removeModal",
          attrs: {
            heading: _vm.i18n.removeModal.heading + " " + _vm.removedInfo.from,
            size: "extra-small"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c("p", { staticClass: "mb-xs-0 pb-xs-5" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.i18n.removeModal.message) +
                    " " +
                    _vm._s(_vm.removedInfo.delete) +
                    " ?\n            "
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary align-self-center modal-btn-primary-small modal-btn-xs-sticky",
                  on: { click: _vm.sendRemoveRequest }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.i18n.removeModal.removeText) +
                      "\n            "
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "deleteUserGroupModal",
          attrs: {
            heading: "" + _vm.i18n.deleteModal.heading,
            size: "extra-small"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c(
                "p",
                { staticClass: "delete-user-group-text mb-xs-0 pb-xs-5" },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.i18n.deleteModal.messageWithOutUsers) +
                      " ?\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary modal-btn-primary-small modal-btn-xs-sticky",
                  on: { click: _vm.sendDeleteRequest }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.i18n.deleteModal.deleteWithOutUsers) +
                      "\n            "
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addExistingModal",
          attrs: {
            heading:
              _vm.i18n.addExistingModal.heading + " " + _vm.existingAddedTo,
            size: "extra-small"
          },
          on: { close: _vm.resetAddedValues }
        },
        [
          _c(
            "div",
            {
              staticClass: "add-existing row",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _vm.existingAddedTo === _vm.i18n.userGroups.groupLabel
                ? _c(
                    "div",
                    { staticClass: "col-xs-12" },
                    _vm._l(_vm.existingUsers, function(item, index) {
                      return _c("div", { staticClass: "mb-xs-5" }, [
                        _c("div", { staticClass: "form-group mb-xs-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.existingItems,
                                expression: "existingItems"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "checkbox" + index + item.info
                            },
                            domProps: {
                              value: item.info,
                              checked: Array.isArray(_vm.existingItems)
                                ? _vm._i(_vm.existingItems, item.info) > -1
                                : _vm.existingItems
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.existingItems,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = item.info,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.existingItems = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.existingItems = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.existingItems = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "pl-xs-5",
                              attrs: { for: "checkbox" + index + item.info }
                            },
                            [
                              item.isPending
                                ? _c("p", { staticClass: "mb-xs-0" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.label) +
                                        "\n                            "
                                    )
                                  ])
                                : _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.userDetailsUrl + item.link
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item.label) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "pl-xs-5 mb-xs-0" }, [
                          _vm._v(_vm._s(item.email))
                        ])
                      ])
                    })
                  )
                : _vm.existingAddedTo === _vm.i18n.permissions.groupLabel
                ? _c(
                    "div",
                    { staticClass: "col-xs-12" },
                    _vm._l(_vm.existingPermissions, function(item, index) {
                      return _c(
                        "div",
                        { staticClass: "mb-xs-5 existing-permissions-info" },
                        [
                          _c("div", { staticClass: "form-group mb-xs-0" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.existingItems,
                                  expression: "existingItems"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "checkbox" + index
                              },
                              domProps: {
                                value: item.link,
                                checked: Array.isArray(_vm.existingItems)
                                  ? _vm._i(_vm.existingItems, item.link) > -1
                                  : _vm.existingItems
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.existingItems,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item.link,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.existingItems = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.existingItems = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.existingItems = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "pl-xs-5",
                                attrs: { for: "checkbox" + index }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.permissionDetailsUrl + item.link
                                    }
                                  },
                                  [_vm._v(_vm._s(item.link))]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "pl-xs-5 mb-xs-0",
                            domProps: { innerHTML: _vm._s(item.info) }
                          })
                        ]
                      )
                    })
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col-xs-12" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-primary justify-content-center modal-btn-primary-small modal-btn-xs-sticky",
                    on: { click: _vm.sendAddExistingRequest }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.i18n.addExistingModal.saveText) +
                        "\n                "
                    )
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "editUserGroupModal",
          attrs: {
            size: "large",
            heading: _vm.i18n.editUserGroupModal.heading
          },
          on: { close: _vm.resetEditValues }
        },
        [
          _c(
            "div",
            {
              staticClass: "row",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c(
                "form",
                {
                  staticClass: "col-xs-12",
                  attrs: { novalidate: "true" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.editUserGroup($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" },
                      [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "id",
                              "aria-hidden": _vm.isDeviceAndroid()
                            }
                          },
                          [_vm._v(_vm._s(_vm.i18n.editUserGroupModal.id))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editUserGroupData.id,
                              expression: "editUserGroupData.id"
                            }
                          ],
                          staticClass: "form-control",
                          class: { "input-error": _vm.errors.has("id") },
                          attrs: {
                            type: "text",
                            maxlength: "35",
                            name: "id",
                            "aria-label": _vm.i18n.editUserGroupModal.id
                          },
                          domProps: { value: _vm.editUserGroupData.id },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.editUserGroupData,
                                "id",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.has("id")
                          ? _c("div", { staticClass: "mt-xs-2" }, [
                              _c("span", { staticClass: "error-msg" }, [
                                _vm._v(_vm._s(_vm.errors.first("id")))
                              ])
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb-xs-4 form-group col-xs-12 col-md-6" },
                      [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "userGroupName",
                              "aria-hidden": _vm.isDeviceAndroid()
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.i18n.editUserGroupModal.userGroupName)
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editUserGroupData.userGroupName,
                              expression: "editUserGroupData.userGroupName"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "input-error": _vm.errors.has("userGroupName")
                          },
                          attrs: {
                            type: "text",
                            maxlength: "35",
                            name: "userGroupName",
                            "aria-label":
                              _vm.i18n.editUserGroupModal.userGroupName
                          },
                          domProps: {
                            value: _vm.editUserGroupData.userGroupName
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.editUserGroupData,
                                "userGroupName",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.has("userGroupName")
                          ? _c("div", { staticClass: "mt-xs-2" }, [
                              _c("span", { staticClass: "error-msg" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first("userGroupName"))
                                )
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "mb-xs-3 mb-sm-5 form-group col-xs-12 col-md-6"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "dropdown-label",
                            attrs: { for: "businessUnitDropdown" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.i18n.editUserGroupModal.businessUnit)
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("vx-dropdown-primary", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          ref: "businessUnitDropdown",
                          attrs: {
                            "required-aria": "true",
                            dropdownValues: _vm.businessUnitsList,
                            name: "businessUnit",
                            dropdownError: _vm.errors.has("businessUnit"),
                            "aria-label":
                              _vm.i18n.editUserGroupModal.businessUnit
                          },
                          on: {
                            "selected-option": function($event) {
                              _vm.editUserGroupData.businessUnit = $event
                            },
                            primaryDropdownMounted: _vm.businessUnitDropdownInit
                          },
                          model: {
                            value: _vm.editUserGroupData.businessUnit,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.editUserGroupData,
                                "businessUnit",
                                $$v
                              )
                            },
                            expression: "editUserGroupData.businessUnit"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.isUserGroupExists
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 alert-msg p-xs-3 mb-xs-5 mt-xs-3"
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.isUserGroupExistsError) +
                                "\n                    "
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary save-btn modal-btn-primary-small",
                        attrs: { type: "submit" }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.i18n.editUserGroupModal.save) +
                            "\n                    "
                        )
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-717e6a7c", { render: render, staticRenderFns: staticRenderFns })
  }
}