var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-shopping-lists" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "createListModal",
          attrs: { size: "extra-small", heading: "CREATE LIST" }
        },
        [
          _c("vx-create-list", {
            attrs: {
              slot: "component",
              allListNames: _vm.allListNames,
              i18n: _vm.i18n
            },
            on: { "close-create-success": _vm.createModalSuccess },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "deleteListModal",
          attrs: { size: "extra-small", heading: "Delete List" }
        },
        [
          _c("vx-delete-list", {
            attrs: {
              slot: "component",
              selectedListsName: _vm.selectedListNames,
              selectedLists: _vm.selectedLists,
              i18n: _vm.i18n
            },
            on: {
              "close-delete-success": _vm.deleteModalSuccess,
              "close-delete-error": _vm.deleteModalError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.filterHidden
        ? _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("h2", { staticClass: "shopping-list-label pt-lg-3 h3" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.i18n.shoppingLists.shoppingListsLabel) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _vm.hideRemoveLists
              ? _c("span", {
                  staticClass:
                    "icon-filter hidden-lg hidden-md hidden-sm visible-xs mt-sm-3 mt-xs-3",
                  attrs: {
                    title: _vm.i18n.shoppingLists.iconFilterTitle,
                    role: "button",
                    tabindex: "0",
                    "aria-label": _vm.i18n.shoppingLists.iconFilterTitle
                  },
                  on: {
                    click: _vm.toggleFilter,
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k(
                          $event.keyCode,
                          "enter-space",
                          undefined,
                          $event.key,
                          undefined
                        )
                      ) {
                        return null
                      }
                      return _vm.toggleFilter($event)
                    }
                  }
                })
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.filterHidden
        ? _c(
            "h3",
            { staticClass: "shopping-list-text pb-lg-3 pb-sm-4 pb-xs-4" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.i18n.shoppingLists.shoppingListsContent) +
                  "\n    "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.filterHidden
        ? _c(
            "div",
            {
              staticClass: "d-flex pb-xs-1 pb-sm-3 button-container",
              class: {
                "justify-content-between":
                  (_vm.hideRemoveLists && _vm.isMobile()) || !_vm.isMobile(),
                "justify-content-end": !_vm.hideRemoveLists && _vm.isMobile()
              }
            },
            [
              !_vm.isMobile()
                ? _c("span", [
                    _c(
                      "button",
                      {
                        staticClass: "remove-list",
                        class: !_vm.enableRemoveLink
                          ? "is-disabled"
                          : "is-enabled",
                        attrs: {
                          disabled: !_vm.enableRemoveLink ? true : false
                        },
                        on: { click: _vm.handleDeleteList }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.i18n.shoppingLists.deleteListLink) +
                            "\n            "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hideRemoveLists && _vm.isMobile()
                ? _c("span", [
                    _c(
                      "button",
                      {
                        staticClass: "remove-list",
                        class: !_vm.enableRemoveLink
                          ? "is-disabled"
                          : "is-enabled",
                        attrs: {
                          disabled: !_vm.enableRemoveLink ? true : false
                        },
                        on: { click: _vm.handleDeleteList }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.i18n.shoppingLists.deleteListLink) +
                            "\n            "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "create-list-container" }, [
                _c(
                  "button",
                  {
                    class:
                      _vm.enableRemoveLink || _vm.isFavorites
                        ? "is-disabled"
                        : "is-enabled",
                    attrs: {
                      disabled:
                        _vm.enableRemoveLink || _vm.isFavorites ? true : false
                    },
                    on: { click: _vm.handleCreateList }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.i18n.shoppingLists.createListLink) +
                        "\n            "
                    )
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isMobile()
        ? _c(
            "div",
            { staticClass: "mb-xs-3" },
            [
              _c(
                "vx-modal",
                {
                  ref: "refineListModal",
                  attrs: {
                    size: "extra-small",
                    heading: _vm.i18n.shoppingLists.refineResults
                  },
                  on: { close: _vm.closeRefineListModal }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "component" }, slot: "component" },
                    [
                      _c("vx-dropdown-primary", {
                        ref: "sortDropdown",
                        attrs: { dropdownValues: _vm.MobileSortOptions },
                        on: {
                          primaryDropdownMounted: _vm.populateDefaultMessage,
                          "selected-option": function($event) {
                            _vm.triggerSort($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dataLoaded
        ? [
            _c("vx-table", {
              attrs: { tableConfig: _vm.tableConfig, tableData: _vm.tableData }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-579b2e02", { render: render, staticRenderFns: staticRenderFns })
  }
}