var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-details-tab-specs" }, [
    _c(
      "div",
      {
        staticClass: "product-details-tab-specs__holder px-sm-3 px-md-2 py-md-2"
      },
      [
        _c("h3", {
          staticClass:
            "product-details-tab-specs__heading pl-xs-3 pl-sm-3 pb-xs-3 pb-sm-3",
          domProps: {
            innerHTML: _vm._s(_vm.productDetailsSpecificationData.categoryLabel)
          }
        }),
        _vm._v(" "),
        _vm._l(_vm.productDetailsSpecificationData.subCategories, function(
          productSpecsCategoryData,
          index
        ) {
          return _c("div", { staticClass: "row mb-xs-2" }, [
            _c("div", { staticClass: "col-md-12 col-sm-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-md-6 col-sm-6 col-xs-6 product-details-tab-specs__key"
                },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(productSpecsCategoryData.attributeLabel)
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-md-6 col-sm-6 col-xs-6 product-details-tab-specs__value"
                },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        productSpecsCategoryData.columnValues[0]
                      )
                    }
                  })
                ]
              )
            ])
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-043e3dfc", { render: render, staticRenderFns: staticRenderFns })
  }
}