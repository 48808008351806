var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-persistent-navigation" },
    _vm._l(_vm.persistentData.persistentLinkCompontents, function(linkData) {
      return _c(
        "div",
        { staticClass: "navigation-section", attrs: { tabindex: "0" } },
        [
          _c("a", { attrs: { href: linkData.ctaLink, tabindex: "-1" } }, [
            _c("div", { staticClass: "icon-container" }, [
              _c("img", {
                attrs: {
                  src: linkData.imageData.imageSrcD,
                  alt: "",
                  srcset: ""
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "navigation-title" }, [
              _vm._v(_vm._s(linkData.ctaText))
            ])
          ])
        ]
      )
    })
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-70c7368a", { render: render, staticRenderFns: staticRenderFns })
  }
}