var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "vx-sample-shopping-cart row" },
    [
      _c(
        "vx-modal",
        {
          ref: "shareCartModal",
          attrs: { size: "extra-small", heading: _vm.i18n.shareListHeading }
        },
        [
          _c("vx-share-cart", {
            attrs: {
              slot: "component",
              type: "modal",
              "cart-id": _vm.shoppingCartData.guid,
              i18n: _vm.i18n
            },
            on: {
              "share-success": _vm.handleShareCartSuccess,
              "share-error": _vm.handleShareCartError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "saveCartModal",
          attrs: { size: "extra-small", heading: _vm.i18n.saveListHeading }
        },
        [
          _c("vx-save-cart", {
            attrs: {
              slot: "component",
              "cart-guid": _vm.shoppingCartData.guid,
              i18n: _vm.i18n
            },
            on: { "list-saved": _vm.handleListSaved },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mt-xs-4 px-xs-0 mt-sm-5 mt-md-3 cart-heading-container"
        },
        [
          _vm.giveAwayCouponDescription
            ? _c("h5", { staticClass: "pt-md-2 pt-sm-0 pb-xs-3 my-xs-0" }, [
                _vm._v(_vm._s(_vm.giveAwayCouponDescription))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("h1", { staticClass: "cart-heading" }, [
            _vm._v(_vm._s(_vm.i18n.shoppingCartHeading))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "item pl-xs-3 no-of-cart-items" }, [
            _vm._v("(" + _vm._s(_vm.shoppingCartData.totalItems) + "\n      "),
            _vm.shoppingCartData.totalItems === 1
              ? _c("span", [_vm._v(_vm._s(_vm.i18n.item))])
              : _c("span", [_vm._v(_vm._s(_vm.i18n.items))]),
            _vm._v(")")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-xs-12 pt-xs-4 pb-xs-3 pb-md-2 px-xs-0 mb-xs-0 cart-id-container"
        },
        [
          _c("span", { staticClass: "cart-id" }, [
            _vm._v(
              _vm._s(_vm.i18n.cartId) + ": " + _vm._s(_vm.shoppingCartData.code)
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "shift-right" }, [
            _c("span", { staticClass: "save-cart pr-xs-4" }, [
              _c("span", {
                staticClass: "cart-icons icon-add-to-list",
                attrs: {
                  title: _vm.i18n.iconAddToListTitle,
                  "aria-label": _vm.i18n.iconAddToListTitle,
                  tabindex: "0",
                  role: "link"
                },
                on: {
                  click: function($event) {
                    _vm.handleSaveACart($event)
                  },
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k(
                        $event.keyCode,
                        "enter-space",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    _vm.handleSaveACart($event)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "share-cart" }, [
              _c("span", {
                staticClass: "cart-icons icon-share",
                attrs: {
                  title: _vm.i18n.iconShareTitle,
                  "aria-label": _vm.i18n.iconShareTitle,
                  tabindex: "0",
                  role: "link"
                },
                on: {
                  click: function($event) {
                    _vm.openShareCartModal($event)
                  },
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k(
                        $event.keyCode,
                        "enter-space",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    _vm.openShareCartModal($event)
                  }
                }
              })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xs-12 pr-sm-0 px-xs-0 product-tile" },
        _vm._l(_vm.shoppingCartData.entries, function(productEntry) {
          return _c(
            "vx-product-tile",
            {
              staticClass: "pb-xs-4",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c("template", { slot: "top-right" }, [
                _c("span", { staticClass: "col-sm-2 px-xs-0" }, [
                  _c("span", {
                    staticClass: "cart-icons icon-trash shift-right",
                    attrs: {
                      title: _vm.i18n.iconDeleteTitle,
                      tabindex: "0",
                      role: "button",
                      "aria-label": _vm.i18n.iconDeleteTitle
                    },
                    on: {
                      click: function($event) {
                        _vm.deleteCartItem(
                          productEntry,
                          productEntry.entryNumber,
                          $event
                        )
                      },
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.deleteCartItem(_vm.productData.entryNumber, $event)
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              productEntry &&
              productEntry.product &&
              productEntry.product.images &&
              productEntry.product.images.length !== 0 &&
              productEntry.product.images[2] &&
              productEntry.product.images[2].url
                ? _c(
                    "template",
                    {
                      staticClass:
                        "product-img px-md-2 py-md-4 px-sm-1 py-sm-3 px-xs-1 py-xs-2",
                      slot: "image-slot"
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.globals.getNavigationUrl("empty") +
                              productEntry.product.url
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.globals.getThumbnailImageUrl(
                                productEntry.product.images
                              ),
                              alt: productEntry.product.images[2].altText
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              productEntry &&
              productEntry.product &&
              !productEntry.product.images
                ? _c("template", { slot: "image-slot" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            _vm.globals.getNavigationUrl("empty") +
                            productEntry.product.url
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.globals.assetsPath + "images/no_image.svg",
                            alt: "No Image"
                          }
                        })
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "template",
                { slot: "details" },
                [
                  _c("p", { staticClass: "product-title mb-xs-2 pr-sm-5" }, [
                    _c("a", {
                      attrs: {
                        href:
                          _vm.globals.getNavigationUrl("empty") +
                          productEntry.product.url
                      },
                      domProps: { innerHTML: _vm._s(productEntry.product.name) }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "product-codes",
                      attrs: { "aria-hidden": "true" }
                    },
                    [
                      productEntry.product && productEntry.product.code
                        ? _c("span", { staticClass: "manufacturer-id" }, [
                            _vm._v(
                              _vm._s(_vm.i18n.manufactureID) +
                                _vm._s(productEntry.product.code)
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isMobile() &&
                      productEntry.product &&
                      productEntry.product.cmirCode
                        ? _c("span", [_vm._v("|")])
                        : _vm._e(),
                      _vm._v(" "),
                      productEntry.product && productEntry.product.cmirCode
                        ? _c(
                            "span",
                            { staticClass: "customer-id pt-xs-2 pt-sm-0" },
                            [
                              _vm._v(
                                _vm._s(_vm.i18n.customerID) +
                                  _vm._s(productEntry.product.cmirCode)
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.globals.siteConfig.enableDisplayAttributes &&
                  productEntry.product.displayAttributes
                    ? _c("div", {
                        staticClass: "display-attributes",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.displayAttributes(
                              productEntry.product.displayAttributes
                            )
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  productEntry.product.isAvailableForLocation
                    ? _c(
                        "p",
                        {
                          staticClass: "location-availability mt-xs-3 mt-sm-4"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.i18n.locationAvailability) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "product-quantity mt-xs-3 mb-xs-2" }, [
                    _vm._v(_vm._s(_vm.i18n.quantity))
                  ]),
                  _vm._v(" "),
                  _c("vx-stepper-control", {
                    attrs: {
                      "max-value": _vm.maxOrderQuantity(
                        productEntry.product.maxOrderQuantity
                      ),
                      value: productEntry.quantity,
                      "min-value": _vm.minOrderQuantity(
                        productEntry.product.minOrderQuantity
                      )
                    },
                    on: {
                      currentCount: function($event) {
                        _vm.handleQuantity(
                          productEntry,
                          productEntry.entryNumber,
                          $event
                        )
                      },
                      maxValueUpdated: _vm.maxValueUpdated,
                      minValueUpdated: _vm.minValueUpdated
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        })
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-76a121b7", { render: render, staticRenderFns: staticRenderFns })
  }
}