var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "htb-container", class: _vm.liveChatTheme },
    [
      _c(
        "div",
        { staticClass: "d-flex livechat-left" },
        [
          [
            _c("div", { staticClass: "left-section htb-type" }, [
              _c("div", { staticClass: "chat-section row mx-sm-0" }, [
                _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                  _c(
                    "h2",
                    {
                      staticClass: "htb-heading",
                      on: {
                        click: function($event) {
                          _vm.goToLink("htb-buy-online")
                        }
                      }
                    },
                    [_vm._v("Buy Online")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-xs-2" }, [
                    _vm._v("Purchase directly from GPPRO.com.")
                  ])
                ])
              ])
            ])
          ],
          _vm._v(" "),
          _c("div", { staticClass: "right-section htb-type" }, [
            _c("div", { staticClass: "row call-text-section mx-sm-0" }, [
              _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                _c(
                  "h2",
                  {
                    staticClass: "htb-heading",
                    on: {
                      click: function($event) {
                        _vm.goToLink("htb-distributor")
                      }
                    }
                  },
                  [_vm._v("\n            Find a Distributor\n          ")]
                ),
                _vm._v(" "),
                _vm._m(0)
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "left-section htb-type" }, [
            _c("div", { staticClass: "send-email-section row mx-sm-0" }, [
              _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                _c(
                  "h2",
                  {
                    staticClass: "htb-heading",
                    on: {
                      click: function($event) {
                        _vm.goToLink("htb-warranty")
                      }
                    }
                  },
                  [_vm._v("Warranty and Replacements")]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "my-xs-0" }, [
                  _vm._v(
                    "Get assistance with dispenser replacements, within or outside of your warranty. "
                  )
                ])
              ])
            ])
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "call-text-timings pb-xs-4" }, [
      _c(
        "p",
        { staticClass: "mb-xs-0 bold", attrs: { "aria-hidden": "true" } },
        [
          _vm._v(
            "\n              We can help you find a distributor that best fits your needs.\n            "
          )
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-25713358", { render: render, staticRenderFns: staticRenderFns })
  }
}