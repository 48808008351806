/**
 * This component is the common add to cart button component
 */

import globals from '../globals';
import vxSpinner from '../vx-spinner/vx-spinner.vue';
import flyoutBannerMixin from '../vx-flyout-banner/vx-flyout-banner-mixin';
import SearchBrowseService from '../services/search-browse-service';
import AnalyticsService from '../services/analytics-service';

import { cartEventBus } from '../../../modules/event-bus';

export default {
  name: 'vx-add-to-cart',
  components: {
    vxSpinner,
  },
  mixins: [flyoutBannerMixin],
  props: {
    // Copy text coming from properties file
    i18n: {
      type: Object,
    },
  },
  data() {
    return {
      globals,
      quantity: '1',
      searchBrowseService: new SearchBrowseService(),
      analyticsService: new AnalyticsService(),
      productPrice: '',
      productCode: '',
      productName: '',
      prodPrice: '',
    };
  },
  computed: {},
  mounted() {},
  methods: {
    /**
     * Function is triggered on click of the add to cart button
     */
    addToCart() {
      this.$refs.spinner.showSpinner();
      const requestObjParams = {
        quantity: this.quantity,
        product: {
          code: `${this.productCode}`,
        },
      };
      const requestConfig = {};
      requestConfig.data = requestObjParams;
      this.searchBrowseService.addToCart(
        requestConfig,
        this.handleAddProductResponse,
        this.handleAddProductErrorResponse,
      );
      // / move it to handleAddProductResponse() and create handleAddProductResponse2()
      const analyticsObject = {
        code: this.productCode,
        name: this.productName,
        quantity: this.quantity, // the default quantity set to 1 as there is no stepper control to edit quantity
        price: this.prodPrice,
      };
      // sending the data to Google Analytics on Add to Cart button click
      this.analyticsService.trackAddToCart(analyticsObject);
      if (this.globals.siteId === 'gppro') {
        this.analyticsService.trackAddToCartGA4(analyticsObject);
      }
    },
    /**
     * Function handles the response of add to cart service call
     */
    handleAddProductResponse(response) {
      this.$refs.spinner.hideSpinner();
      if (response && response.data) {
        // if (response.data.entry.product.assetCode === 'refill') {
        //   this.$refs.refillsModal.open();
        // }
        this.showFlyout(
          'success',
          `1 ${this.i18n.addToCartSuccessMessage}`,
          true,
          Number(this.globals.siteConfig.bannerTimer),
        );
        cartEventBus.$emit('cart-update');
        cartEventBus.$emit('call-basic-cart');
      }
    },
    /**
     * Function handles the error of add to cart service call
     */
    handleAddProductErrorResponse(error) {
      this.$refs.spinner.hideSpinner();
      if (
        error
        && error.response
        && error.response.data
        && error.response.data.errors.length !== 0
        && error.response.data.errors[0]
        && error.response.data.errors[0].code
      ) {
        if (
          error.response.data.errors[0].code
          === this.i18n.maxPurchaseableQuantityErrorCode
        ) {
          this.showFlyout(
            'error',
            this.i18n.maxPurchaseableQuantityErrorMessage,
            true,
          );
        } else if (
          error.response.data.errors[0].code === this.i18n.lowStockErrorCode
        ) {
          this.showFlyout('error', this.i18n.lowStockErrorMessage, true);
        }
      }
    },
  },
};
