var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-image-download checkbox-spacing" }, [
    _c("h3", { staticClass: "pt-xs-4" }, [
      _vm._v(
        "\n        " + _vm._s(_vm.i18n.imageDownloadDescription) + "\n    "
      )
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v("\n        " + _vm._s(_vm.i18n.imageDownloadcontent) + "\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group box-container checkbox-spacing" }, [
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.imageInfo.allImages,
              expression: "imageInfo.allImages"
            }
          ],
          attrs: {
            id: "downloadAllImage",
            type: "checkbox",
            "aria-label": _vm.i18n.allImageDownloadCheckbox
          },
          domProps: {
            checked: Array.isArray(_vm.imageInfo.allImages)
              ? _vm._i(_vm.imageInfo.allImages, null) > -1
              : _vm.imageInfo.allImages
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.imageInfo.allImages,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.imageInfo, "allImages", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.imageInfo,
                        "allImages",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.imageInfo, "allImages", $$c)
                }
              },
              _vm.downloadAllImageChange
            ]
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            attrs: {
              for: "downloadAllImage",
              "aria-hidden": _vm.isDeviceAndroid()
            }
          },
          [
            _vm._v(
              "\n              " +
                _vm._s(_vm.i18n.allImageDownloadCheckbox) +
                "\n          "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pt-5" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.imageInfo.enhancedImages,
              expression: "imageInfo.enhancedImages"
            }
          ],
          attrs: {
            id: "downloadEnhancedImage",
            type: "checkbox",
            "aria-label": _vm.i18n.enhancedImageDownloadCheckbox
          },
          domProps: {
            checked: Array.isArray(_vm.imageInfo.enhancedImages)
              ? _vm._i(_vm.imageInfo.enhancedImages, null) > -1
              : _vm.imageInfo.enhancedImages
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.imageInfo.enhancedImages,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.imageInfo,
                        "enhancedImages",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.imageInfo,
                        "enhancedImages",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.imageInfo, "enhancedImages", $$c)
                }
              },
              _vm.downloadEnhancedImageChange
            ]
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            attrs: {
              for: "downloadEnhancedImage",
              "aria-hidden": _vm.isDeviceAndroid()
            }
          },
          [
            _vm._v(
              "\n              " +
                _vm._s(_vm.i18n.enhancedImageDownloadCheckbox) +
                "\n          "
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pt-xs-4 px-xs-0 col-12 checkbox-spacing" }, [
      _c("h3", [
        _vm._v(
          "\n            " +
            _vm._s(_vm.i18n.imageDownloadFileFormat) +
            "\n        "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "checkbox-spacing" },
        [
          _c("vx-radio-button-group", {
            ref: "format",
            attrs: {
              "radiobutton-values": _vm.formatOptions,
              name: "formatOptions"
            },
            model: {
              value: _vm.imageInfo.format,
              callback: function($$v) {
                _vm.$set(_vm.imageInfo, "format", $$v)
              },
              expression: "imageInfo.format"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pt-xs-4 px-xs-0 col-12" }, [
      _c("h3", [
        _vm._v(
          "\n            " +
            _vm._s(_vm.i18n.imageDownloadFileSize) +
            "\n        "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: " checkbox-spacing" },
        [
          _c("vx-radio-button-group", {
            ref: "size",
            attrs: {
              "radiobutton-values": _vm.sizeOptions,
              name: "sizeOptions"
            },
            model: {
              value: _vm.imageInfo.size,
              callback: function($$v) {
                _vm.$set(_vm.imageInfo, "size", $$v)
              },
              expression: "imageInfo.size"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 px-xs-0 d-flex vx-image-download__buttons" },
      [
        _c(
          "button",
          {
            staticClass: "submit btn btn-primary modal-btn-primary-small",
            attrs: { type: "submit", disabled: _vm.errors.any() },
            on: {
              click: function($event) {
                _vm.downloadImagesZip()
              }
            }
          },
          [_vm._v(_vm._s(_vm.i18n.imageDownloadButton) + "\n        ")]
        )
      ]
    ),
    _vm._v(" "),
    !_vm.globals.isGppro()
      ? _c(
          "div",
          {
            staticClass:
              "text-left col-12 disclaimer modal-btn-IEspacing modal-btn-EDGEspacing"
          },
          [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.i18n.disclaimerLine1) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.i18n.disclaimerLine2) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "disclaimer-line" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.i18n.disclaimerLine3) +
                  "\n            "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.i18n.privacyPolicyLink,
                    "aria-label": _vm.i18n.disclaimerLine4
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.i18n.disclaimerLine4) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.i18n.disclaimerLine5) +
                  "\n        "
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-39d6e6bc", { render: render, staticRenderFns: staticRenderFns })
  }
}