var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-500-page" }, [
    _c("div", { staticClass: "error-container" }, [
      _c("div", { staticClass: "error-heading" }, [
        _vm._v("\n      " + _vm._s(_vm.i18n.heading) + "\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "error-msg-container" }, [
        _vm._v("\n      " + _vm._s(_vm.i18n.errorMsg) + "\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "immediate-assistance" }, [
        _vm._v("\n      " + _vm._s(_vm.i18n.technicalProblem) + "\n    ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4d8e3d6b", { render: render, staticRenderFns: staticRenderFns })
  }
}