/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import globals from '../../common/globals';
import { globalEventBus } from '../../../modules/event-bus';
import vxVideoPlayer from '../../common/vx-video-player/vx-video-player.vue';
import mobileMixin from '../../common/mixins/mobile-mixin';
import viewSiteContent from '../../../locale/view-site-content-i18n';
import vxPriceSpider from '../vx-price-spider/vx-price-spider-shell';

export default {
  name: 'vx-image-title-render',
  components: {
    vxVideoPlayer, vxPriceSpider,
  },
  mixins: [mobileMixin],
  props: {
    imgTitleData: {
      type: Object,
      required: true,
      default: {},
    },
    jumpToHtml: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      globals,
      i18n: viewSiteContent.imageTitle,
      backgroundImg: '',
      isPriceSpider: false,
      psSKU: '',
      priceSpiderTiles: [],
    };
  },
  computed: {
    backgroundStyle() {
      return !this.backgroundImg
        ? {
          'background-color': this.imgTitleData.backgroundColor
            ? this.imgTitleData.backgroundColor
            : '',
        }
        : {
          'background-image': `url(${this.backgroundImg})`,
        };
    },

    imageTileWidth() {
      if (this.imgTitleData.noOfColumns) {
        return `width: ${100 / this.imgTitleData.noOfColumns}%`;
      }
      return 'width: 100%';
    },

    backgroundAlt() {
      return this.getResponsiveAlt(
        this.imgTitleData.backgroundImageAltTextD,
        this.imgTitleData.backgroundImageAltTextT,
        this.imgTitleData.backgroundImageAltTextM,
      );
    },
  },
  async mounted() {
    this.backgroundImg = this.getResponsiveImage(
      this.imgTitleData.backgroundImageD,
      this.imgTitleData.backgroundImageT,
      this.imgTitleData.backgroundImageM,
    );
    if (this.imgTitleData.ctaLink && this.imgTitleData.ctaLink.indexOf('price-spider') > -1) {
      this.isPriceSpider = true;
      this.imgTitleData.ctaLink.split(' ').forEach((element) => { if (element.indexOf('price-spider') > -1) { this.psSKU = element.split('-')[2]; } });
    }
    this.imgTitleData.tiles.forEach((tile, index) => {
      if (tile.ctaLink && tile.ctaLink.indexOf('price-spider') > -1) {
        tile.ctaLink.split(' ').forEach((element) => { if (element.indexOf('price-spider') > -1) { this.priceSpiderTiles[index] = element.split('-')[2]; } });
      } else {
        this.priceSpiderTiles[index] = null;
      }
    });
    this.$forceUpdate();
  },
  methods: {
    /**
     * Emitting the event to open video player by sending video source url.
     * @param {String} videoSRC
     */
    openModal(videoSRC) {
      this.$refs.videoPlayer.openPlayer(videoSRC);
    },
    /**
     * Emitting the event to scroll the page to a particular position by giving the hash id.
     * @param {String} link
     */
    emitScrollSignal(link) {
      globalEventBus.$emit('scroll-to', link);
    },
    // Method to strip HTML tags while dynamically setting the aria-label on links
    stripHtmlTags(ariaLabel) {
      if (ariaLabel) {
        return ariaLabel.replace(/(<([^>]+)>)/gi, '');
      }
      return undefined;
    },
  },
};
