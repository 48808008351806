/**
 * checkout-service provides multiple methods for checkout modules.
 */

import RootService from './root-service';
import globals from '../globals';

class CheckOutService extends RootService {
  /**
   * Gets full cart details
   */
  fullCart(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('empty', 'cart');
    config.params = {
      fields: 'FULL',
      calculationType: 'checkout',
    };
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Full Subscription Cart Call
   * @param  {String}  subscriptionCartId Subscription Id
   * @param  {String}  calculationType Calculation type for cart and checkout
   */
  getSubscriptionCart(
    requestConfig,
    successCallback,
    errorCallback,
    subscriptionCartId,
    calculationType,
  ) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl(
      'subscriptionCart',
      'user',
    )}/${subscriptionCartId}`;
    if (calculationType !== 'cart') {
      config.params = {
        fields: 'FULL',
        calculationType: 'checkout',
      };
    } else {
      config.params = {
        fields: 'FULL',
        calculationType: 'cart',
      };
    }

    this.get(config, successCallback, errorCallback);
  }

  /**
   * Gets all Shipping Address for logged in user
   */
  getCheckoutAddress(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.headers = {
      pragma: 'no-cache',
    };
    config.url = globals.getRestUrl('checkoutGetAddress', 'user');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Navigates guest user to checkout page from checkout sign in page
   * @param  {String}  guestEmail guest email id
   */
  getGuestCheckout(requestConfig, successCallback, errorCallback, guestEmail) {
    const config = requestConfig;
    config.url = globals.getRestUrl('guestemail', 'cart');
    config.params = {
      email: guestEmail,
    };
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Gets all Shipping Address for guest user
   */
  getGuestAddress(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('getAddress', 'cart');
    } else {
      config.url = globals.getRestUrl('getAddress', 'subscribe');
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Deletes product from cart in Shipping address section
   * @param  {Number}  itemNumber product entryNumber
   */
  deleteCartItem(requestConfig, successCallback, errorCallback, itemNumber) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = `${globals.getRestUrl(
        'deleteCartEntry',
        'cart',
      )}/${itemNumber}`;
    } else {
      config.url = `${globals.getRestUrl(
        'deleteCartEntry',
        'subscribe',
      )}/${itemNumber}`;
    }
    this.delete(config, successCallback, errorCallback);
  }

  /**
   * Returns Shipping Retriction Items in Shipping address section
   * @param  {Object}  data requestconfig data
   */
  shippingRestriction(requestConfig, successCallback, errorCallback, data) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('shippingRestriction', 'cart');
    } else {
      config.url = globals.getRestUrl('shippingRestriction', 'subscribe');
    }
    if (config.params) {
      config.params.refreshCart = 'false';
    } else {
      config.params = {
        refreshCart: 'false',
      };
    }
    config.data = data;
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Saves new address added from shipping address section
   * @param  {Object}  data requestconfig data
   */
  addAddress(requestConfig, successCallback, errorCallback, data) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('addAddress', 'cart');
    } else {
      config.url = globals.getRestUrl('addAddress', 'subscribe');
    }
    config.data = data;
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Gets Shipping methods for Multiple Shipping
   */
  getMultipleMethod(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('getMultipleShippingMethod', 'cart');
    } else {
      config.url = globals.getRestUrl('getMultipleShippingMethod', 'subscribe');
    }
    if (config.params) {
      config.params.refreshCart = 'false';
    } else {
      config.params = {
        refreshCart: 'false',
      };
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Saves Shipping methods for Multiple Shipping
   */
  saveMultipleMethod(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('setMultipleShippingMethod', 'cart');
    } else {
      config.url = globals.getRestUrl('setMultipleShippingMethod', 'subscribe');
    }
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Gets Shipping methods for Single Shipping
   */
  getSingleMethod(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('getSingleShippingMethod', 'cart');
    } else {
      config.url = globals.getRestUrl('getSingleShippingMethod', 'subscribe');
    }
    if (config.params) {
      config.params.refreshCart = 'false';
    } else {
      config.params = {
        refreshCart: 'false',
      };
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Saves Shipping methods for Multiple Shipping
   */
  saveSingleMethod(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (config.params) {
      config.params.refreshCart = 'false';
    } else {
      config.params = {
        refreshCart: 'false',
      };
    }
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('setSingleShippingMethod', 'cart');
    } else {
      config.url = globals.getRestUrl('setSingleShippingMethod', 'subscribe');
    }
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Gets Installations Details
   */
  getInstallationDetails(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('scheduleInstallation', 'cart');
    } else {
      config.url = globals.getRestUrl('scheduleInstallation', 'subscribe');
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Saves Installations Details
   */
  setInstallationDetails(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('scheduleInstallation', 'cart');
    } else {
      config.url = globals.getRestUrl('scheduleInstallation', 'subscribe');
    }
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Saves Gift Details
   */
  saveGift(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('setGiftWrap', 'cart');
    } else {
      config.url = globals.getRestUrl('setGiftWrap', 'subscribe');
    }
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Saves Lease Agreement
   * @param  {Object}  data requestconfig data
   */
  saveLeaseAgrement(requestConfig, successCallback, errorCallback, data) {
    const config = requestConfig;
    config.data = data;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('saveLeaseAgrement', 'cart');
    } else {
      config.url = globals.getRestUrl('saveLeaseAgrement', 'subscribe');
    }
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Gets Lease Agreement details in vx-review-lease-agreement
   * @param  {String}  setLanguage language in which the user wants details
   */
  leaseAgreement(requestConfig, successCallback, errorCallback, setLanguage) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = `${globals.getRestUrl(
        'leaseAgreement',
        'cart',
      )}/${setLanguage}`;
    } else {
      config.url = `${globals.getRestUrl(
        'leaseAgreement',
        'subscribe',
      )}/${setLanguage}`;
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Gets payment (card) details of the user
   */
  getPaymentDetails(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('paymentDetails', 'user');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Saves payment (card) selected by the user
   * @param  {String}  paymentId payment id of the card
   */
  savePayment(requestConfig, successCallback, errorCallback, paymentId) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = globals.getRestUrl('paymentDetails', 'cart');
    } else {
      config.url = globals.getRestUrl('paymentDetails', 'subscribe');
    }
    config.params = {
      paymentDetailsId: paymentId,
    };
    this.put(config, successCallback, errorCallback);
  }

  /**
   * Places order
   */
  placeOrder(requestConfig, successCallback, errorCallback, captchaToken) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.params = {
        cartId: globals.getCartGuid(),
        gpRecaptchaToken: captchaToken,
      };
    } else {
      config.params = {
        cartId: globals.getSubscribtionCartId(),
        gpRecaptchaToken: captchaToken,
      };
    }
    config.url = globals.getRestUrl('placeOrder', 'user');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Gets details of order confirmation page
   * @param  {Number}  orderCode Order Id
   */
  getOrderDetails(requestConfig, successCallback, errorCallback, orderCode) {
    const config = requestConfig;
    if (globals.getIsLoggedIn()) {
      config.url = `${globals.getRestUrl('getOrder', 'user')}/${orderCode}`;
    } else {
      config.url = `${globals.getRestUrl('getOrder')}/${orderCode}`;
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Gets Lease Agreement details of order confirmation page
   * @param  {Number}  orderCode Order Id
   */
  showLeaseAgreement(requestConfig, successCallback, errorCallback, orderCode) {
    const config = requestConfig;
    if (globals.getIsLoggedIn()) {
      config.url = `${globals.getRestUrl('getLease', 'user')}/${orderCode}`;
    } else {
      config.url = `${globals.getRestUrl('getLease')}/${orderCode}`;
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * Gets PayPal id for authorization
   * @param  {String}  type type of paypal selected (PayPal/paypalCredit)
   */
  paypalRequest(requestConfig, successCallback, errorCallback, type) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = `${globals.getRestUrl(
        'paypalPaymentDetails',
        'cart',
      )}?paypalType=${type}`;
    } else {
      config.url = `${globals.getRestUrl(
        'paypalPaymentDetails',
        'subscribe',
      )}?paypalType=${type}`;
    }
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Saves PayPal response which we get from PayPal and depending on the response user can place the order
   */
  savePaypalResponse(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    if (!globals.getIsSubscription()) {
      config.url = `${globals.getRestUrl('paypalSavePayment', 'cart')}`;
    } else {
      config.url = `${globals.getRestUrl('paypalSavePayment', 'subscribe')}`;
    }
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Checks recaptcha score
   */
  proceedService(requestConfig, successCallback, errorCallback, captchaToken) {
    const config = requestConfig;
    config.params = {
      gpRecaptchaToken: captchaToken,
    };
    config.url = `${globals.getRestUrl('proceed')}`;
    this.post(config, successCallback, errorCallback);
  }
}

export default CheckOutService;
