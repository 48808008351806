/**
 * Brand bar component
 */
import {
  swiper,
  swiperSlide,
} from 'vue-awesome-swiper';
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';

export default {
  name: 'vx-uiux-brand-bar',
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    brandBarData: Object,
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      swiper: '',
      showNavigationArrows: false,
      firstSlide: true,
      lastSlide: false,
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 20,
        freeMode: false,
        watchOverflow: true,
        loop: false,
        breakpoints: {
          767: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          1199: {
            slidesPerView: 3,
            spaceBetween: 18,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
        simulateTouch: this.enableSimulateTouch,
      },
      showSelect: false,
      viewPort: {
        mobile: 767,
        desktop: 1440,
        tablet: 1199,
      },
      i18n: this.$root.messages.common.slider,
      swiperWrapperClass: '.swiper-container',
    };
  },
  computed: {},
  created() {
    if (this.showSelectCheckbox) {
      this.showSelect = true;
    }
    if (this.swiperModifierClass) {
      this.swiperOptions.containerModifierClass = this.swiperModifierClass;
    }
  },
  mounted() {
    if (this.brandBarData.backgroundColor) {
      this.$el.style.backgroundColor = this.brandBarData.backgroundColor;
    }
    if (this.brandBarData.headerColor) {
      this.$refs.header.style.color = this.brandBarData.headerColor;
      if (this.brandBarData.headerColor.childNodes[0] && this.brandBarData.headerColor.childNodes[0].style) {
        this.brandBarData.headerColor.childNodes[0].style.color = this.brandBarData.headerColor;
      }
    }
    if (this.brandBarData.subHeaderColor) {
      this.$refs.header.style.description = this.brandBarData.subHeaderColor;
    }
    if (this.brandBarData.brands && this.brandBarData.brands.length > 0) {
      if (this.swiperModifierClass) {
        if (document.querySelector(`.${this.swiperModifierClass.concat('initialized')}`)) {
          this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'initialized');
        } else if (document.querySelector(`.${this.swiperModifierClass.concat('horizontal')}`)) {
          this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'horizontal');
        } else if (document.querySelector(`.${this.swiperModifierClass.concat('android')}`)) {
          this.swiperWrapperClass = this.swiperWrapperClass.concat('.', this.swiperModifierClass, 'android');
        }
      }
      const self = this;
      self.onInit();
      self.swiper = document.querySelector(`.vx-uiux-brand-bar ${this.swiperWrapperClass}`).swiper;
      self.swiper.on('reachEnd', () => {
        self.firstSlide = false;
        self.lastSlide = true;
      });
      self.swiper.on('reachBeginning', () => {
        self.firstSlide = true;
        self.lastSlide = false;
      });
      window.addEventListener('resize', () => {
        self.onInit();
      });
    }
  },
  watch: {
    brandBarData() {
      this.onInit();
    },
  },
  methods: {
    /**
         * Function sets the slides as per Viewport
         */
    onInit() {
      const self = this;
      const wrapperElement = document.querySelector('.vx-uiux-brand-bar .swiper-wrapper');
      if (self.isMobile()) {
        self.showNavigationArrows = self.brandBarData.brands.length > 1;
        wrapperElement.style.justifyContent = 'flex-start';
        wrapperElement.setAttribute('role', 'list');
      } else if (self.isTablet()) {
        self.showNavigationArrows = self.brandBarData.brands.length > 3;
        wrapperElement.style.justifyContent = self.brandBarData.brands.length < 3 ? 'center' : 'flex-start';
        wrapperElement.setAttribute('role', 'list');
      } else {
        self.showNavigationArrows = self.brandBarData.brands.length > 4;
        wrapperElement.style.justifyContent = self.brandBarData.brands.length < 4 ? 'center' : 'flex-start';
        wrapperElement.setAttribute('role', 'list');
      }
    },
    /*
         * Function sets the slides to previous product
         */
    slidePrev() {
      this.swiper.slidePrev();
      this.lastSlide = false;
    },
    /**
         * Function sets the slides to next product
         */
    slideNext() {
      this.swiper.slideNext();
      this.firstSlide = false;
    },
  },
};
