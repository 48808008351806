<template>
  <div class="vx-auto-suggest-to-list">

    <div class="selected-items-container">
        <div class="selected-item"
             v-for="product in selectedProducts" :key="product.code">
          {{ product.code }}
          <span class="icon-x"
                title="i18n.iconXTitle"
                @click="onItemClickRemove(product)"></span>
        </div>
    </div>

    <div class="search-input form-group">
      <span class="icon-search"
            title="i18n.iconSearchTitle"
            aria-hidden="true">
      </span>
      <input class="form-control"
             type="text"
             placeholder="Search"
             @input="onInput($event.target.value)">
      <button class="btn btn-tertiary d-flex align-items-center selected-items-btn"
              :disabled="selectedProducts.length === 0"
              @click="onClickEmitSelectedProducts">
        <span class="icon-plus"></span>
      </button>
    </div>

    <div class="search-item"
         v-for="product in products" :key="product.code"
         @click="onItemClick(product)">
      [{{ product.code }}] {{ product.name }}
    </div>

  </div>

</template>

<script>
import debounce from 'lodash.debounce';
import SearchBrowseService from '../../common/services/search-browse-service';

export default {
  name: 'vx-auto-suggest-to-list',
  data() {
    return {
      products: [],
      selectedProducts: [],
      searchBrowseService: new SearchBrowseService(),
      delay: 500,
      debouncedSendSearchRequest: null,
    };
  },
  created() {
    this.debouncedSendSearchRequest = debounce(this.sendSearchRequest, this.delay);
  },
  methods: {
    onInput(text) {
      if (text.length >= 3) {
        this.debouncedSendSearchRequest(text);
      } else {
        this.products = [];
      }
    },
    sendSearchRequest(text) {
      const params = {
        term: encodeURIComponent(text.trim()),
        maxProducts: 10,
        fields: 'BASIC',
      };
      this.searchBrowseService.getAutosuggest(
        { params },
        this.handleSuggestResponse,
        () => {},
      );
    },
    handleSuggestResponse(response) {
      this.products = response.data.products || [];
    },
    onItemClick(product) {
      const { code } = product;
      if (this.selectedProducts.every((item) => item.code !== code)) {
        this.selectedProducts.push(product);
      }
    },
    onItemClickRemove(product) {
      const { code } = product;
      this.selectedProducts = this.selectedProducts.filter((item) => item.code !== code);
    },
    onClickEmitSelectedProducts() {
      const productCodes = this.selectedProducts.map((item) => item.code).join(',');
      this.$emit('addSelectedProducts', productCodes);
    },
  },
};
</script>
