var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-update-password" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitPassword($event)
          }
        }
      },
      [
        _c("vx-spinner", {
          ref: "updateProfileSpinner",
          attrs: {
            image: _vm.globals.assetsPath + "images/spinner.gif",
            "full-screen": ""
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mb-md-5 mb-sm-5 mb-xs-4 form-group has-feedback mr-xs-1 ml-xs-1"
          },
          [
            _c(
              "label",
              {
                attrs: { for: "password", "aria-hidden": _vm.isDeviceAndroid() }
              },
              [_vm._v(_vm._s(_vm.i18n.currentPasswordLabel))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: { required: true, regex: _vm.globals.PASSWORD_REGEX },
                  expression: "{required: true, regex: globals.PASSWORD_REGEX }"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentPasswordUpdateText,
                  expression: "currentPasswordUpdateText"
                }
              ],
              ref: "oldPassword",
              staticClass: "mb-xs-2 form-control password",
              class: {
                "input-error":
                  _vm.errors.has("oldPassword") || _vm.isPasswordMismatch
              },
              attrs: {
                maxlength: "255",
                type: "password",
                name: "oldPassword",
                "aria-label": _vm.i18n.currentPasswordLabel
              },
              domProps: { value: _vm.currentPasswordUpdateText },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.currentPasswordUpdateText = $event.target.value
                  },
                  _vm.updatePasswordMismatch
                ],
                focus: _vm.focusInButStateChange
              }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "icon-eye toggle-password",
              class: { "icn-eye-collapsed": _vm.showOldPassword },
              attrs: {
                title: _vm.showOldPassword
                  ? _vm.i18n.iconEyeHideTitle
                  : _vm.i18n.iconEyeShowTitle,
                tabindex: "0",
                role: "button",
                "aria-label": _vm.showOldPassword
                  ? _vm.i18n.iconEyeHideTitle
                  : _vm.i18n.iconEyeShowTitle
              },
              on: {
                keyup: function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k(
                      $event.keyCode,
                      "enter-space",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  _vm.togglePassword("oldPassword")
                },
                click: function($event) {
                  _vm.togglePassword("oldPassword")
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("oldPassword") ||
            _vm.isPasswordMismatch ||
            _vm.isNewPassword
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: { "aria-live": "polite", role: "alert" }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("oldPassword")))]
                  ),
                  _vm._v(" "),
                  _vm.isNewPassword
                    ? _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: { "aria-live": "polite", role: "alert" }
                        },
                        [_vm._v(_vm._s(_vm.i18n.newPasswordResetError))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isPasswordMismatch
                    ? _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: { "aria-live": "polite", role: "alert" }
                        },
                        [_vm._v(_vm._s(_vm.i18n.currentPasswordInvalidError))]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mb-md-3 mb-sm-3 mb-xs-3 form-group has-feedback mr-xs-1 ml-xs-1"
          },
          [
            _c(
              "label",
              {
                attrs: { for: "password", "aria-hidden": _vm.isDeviceAndroid() }
              },
              [_vm._v(_vm._s(_vm.i18n.newPasswordLabel))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: { required: true, regex: _vm.globals.PASSWORD_REGEX },
                  expression:
                    "{ required: true, regex: globals.PASSWORD_REGEX }"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newPasswordUpdateText,
                  expression: "newPasswordUpdateText"
                }
              ],
              ref: "newPassword",
              staticClass: "mb-xs-2 form-control password",
              class: { "input-error": _vm.errors.has("newPassword") },
              attrs: {
                maxlength: "255",
                type: "password",
                name: "newPassword",
                "aria-label": _vm.i18n.newPasswordLabel
              },
              domProps: { value: _vm.newPasswordUpdateText },
              on: {
                focus: _vm.focusInButStateChange,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newPasswordUpdateText = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "icon-eye toggle-password",
              class: { "icn-eye-collapsed": _vm.showNewPassword },
              attrs: {
                title: _vm.showNewPassword
                  ? _vm.i18n.iconEyeHideTitle
                  : _vm.i18n.iconEyeShowTitle,
                tabindex: "0",
                role: "button",
                "aria-label": _vm.showNewPassword
                  ? _vm.i18n.iconEyeHideTitle
                  : _vm.i18n.iconEyeShowTitle
              },
              on: {
                click: function($event) {
                  _vm.togglePassword("newPassword")
                },
                keyup: function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k(
                      $event.keyCode,
                      "enter-space",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  _vm.togglePassword("newPassword")
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("newPassword")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: { "aria-live": "polite", role: "alert" }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("newPassword")))]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("vx-password-check-list", {
              attrs: {
                password: _vm.currentPasswordUpdateText,
                i18n: _vm.i18n.passwordCheck
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.updatePasswordServerError
          ? _c("div", { staticClass: "mt-xs-2" }, [
              _c(
                "span",
                {
                  staticClass: "error-msg",
                  attrs: { "aria-live": "polite", role: "alert" }
                },
                [_vm._v(_vm._s(_vm.updatePasswordServerError))]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn modal-btn-primary-small modal-btn-xs-sticky mb-xs-2 form-blue-button",
            attrs: {
              type: "submit",
              disabled: _vm.errors.any(),
              "aria-label": _vm.i18n.savePasswordButton
            }
          },
          [_vm._v(_vm._s(_vm.i18n.savePasswordButton) + "\n        ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-90ce86b2", { render: render, staticRenderFns: staticRenderFns })
  }
}