var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.productCarouselData.isCarousel
    ? _c(
        "div",
        {
          staticClass: "vx-product-carousel row",
          class: _vm.productCarouselData.componentTheme,
          attrs: { id: _vm.productCarouselData.componentId }
        },
        [
          _c("h2", {
            domProps: {
              innerHTML: _vm._s(_vm.productCarouselData.componentHeader)
            }
          }),
          _vm._v(" "),
          _vm.productCarouselData.componentHeader
            ? _c("div", { staticClass: "title-underline mt-xs-0" })
            : _vm._e(),
          _vm._v(" "),
          _c("vx-slider", {
            attrs: {
              "product-data": _vm.productCarouselData.products,
              isSlotBased: "",
              "slider-slides": _vm.sliderSlides
            },
            scopedSlots: _vm._u([
              {
                key: "slide",
                fn: function(ref) {
                  var product = ref.product
                  return [
                    _c("div", { staticClass: "banner-content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "px-sm-2 d-flex justify-content-center"
                        },
                        [
                          _c("div", { staticClass: "tile mb-xs-3" }, [
                            _c(
                              "a",
                              {
                                staticClass: "image-title__wrapper",
                                attrs: {
                                  target: product.isExternal ? "_blank" : "",
                                  href: product.isExternal
                                    ? product.url
                                    : _vm.globals.getUrlWithContextPath(
                                        product.url
                                      )
                                }
                              },
                              [
                                _c("lazy-component", [
                                  product.imageUrl
                                    ? _c("img", {
                                        attrs: {
                                          src: product.imageUrl,
                                          alt: product.name
                                        }
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src:
                                            _vm.globals.assetsPath +
                                            "images/no_image.svg",
                                          alt: product.name
                                        }
                                      })
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "bottom-block mt-xs-3" }, [
                              _c("div", { staticClass: "tile-btn-container" }, [
                                product.name && product.url
                                  ? _c("a", {
                                      staticClass:
                                        "mb-xs-4 mt-xs-3 mb-sm-3 my-md-4 tile-btn",
                                      attrs: {
                                        target: product.isExternal
                                          ? "_blank"
                                          : "",
                                        href: product.isExternal
                                          ? product.url
                                          : _vm.globals.getUrlWithContextPath(
                                              product.url
                                            )
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(product.name)
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.globals.siteConfig.isProductPriceEnabled &&
                                product.price &&
                                !product.notPurchasable
                                  ? _c("div", {
                                      staticClass: "price",
                                      domProps: {
                                        innerHTML: _vm._s(product.price)
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ])
                          ])
                        ]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "vx-product-list d-flex flex-wrap",
          attrs: { id: _vm.productCarouselData.componentId }
        },
        [
          _vm.productCarouselData.componentHeader
            ? _c("h2", {
                domProps: {
                  innerHTML: _vm._s(_vm.productCarouselData.componentHeader)
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.productCarouselData.products, function(item) {
            return _c(
              "div",
              { staticClass: "slide-wrapper", style: _vm.imageTileWidth() },
              [
                _c("div", { staticClass: "banner-content" }, [
                  _c(
                    "div",
                    { staticClass: "px-sm-2 d-flex justify-content-center" },
                    [
                      _c("div", { staticClass: "tile mb-xs-3" }, [
                        _c(
                          "a",
                          {
                            staticClass: "image-title__wrapper",
                            attrs: {
                              target: item.isExternal ? "_blank" : "",
                              href: item.isExternal
                                ? item.url
                                : _vm.globals.getUrlWithContextPath(item.url)
                            }
                          },
                          _vm._l(_vm.list, function(img) {
                            return _c(
                              "li",
                              [
                                _c("lazy-component", [
                                  _c("img", {
                                    attrs: {
                                      src: item.imageUrl,
                                      alt: item.name
                                    }
                                  })
                                ])
                              ],
                              1
                            )
                          })
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "bottom-block mt-xs-3" }, [
                          _c("div", { staticClass: "tile-btn-container" }, [
                            item.name && item.url
                              ? _c("a", {
                                  staticClass:
                                    "mb-xs-4 mt-xs-3 mb-sm-3 my-md-4 tile-btn",
                                  attrs: {
                                    target: item.isExternal ? "_blank" : "",
                                    href: item.isExternal
                                      ? item.url
                                      : _vm.globals.getUrlWithContextPath(
                                          item.url
                                        )
                                  },
                                  domProps: { innerHTML: _vm._s(item.name) }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.globals.siteConfig.isProductPriceEnabled &&
                            item.price &&
                            !item.notPurchasable
                              ? _c("div", {
                                  staticClass: "price",
                                  domProps: { innerHTML: _vm._s(item.price) }
                                })
                              : _vm._e()
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ]
            )
          })
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-44cf9422", { render: render, staticRenderFns: staticRenderFns })
  }
}