var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-tabbed-banner" }, [
    _c("div", { staticClass: "nav" }, [
      _c(
        "div",
        { staticClass: "nav-items" },
        _vm._l(_vm.carouselData.gpTabbedData, function(item) {
          return _c(
            "div",
            {
              staticClass: "nav-item",
              class: { selected: _vm.selectedItemTitle === item.tabTitle },
              on: {
                click: function($event) {
                  _vm.selectItem(item)
                }
              }
            },
            [
              _vm._v(
                "\n                " + _vm._s(item.tabTitle) + "\n            "
              )
            ]
          )
        })
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "link-container", attrs: { id: "link-container" } },
        [
          _vm.carouselData.ctaLink && _vm.carouselData.ctaText
            ? _c(
                "a",
                { ref: "navButton", attrs: { href: _vm.carouselData.ctaLink } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.carouselData.ctaText) +
                      "\n            "
                  )
                ]
              )
            : _vm._e()
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "item",
        staticClass: "item",
        on: {
          click: function($event) {
            _vm.goToImageLink()
          }
        }
      },
      [
        _c("div", { staticClass: "text-section" }, [
          _c("div", {
            ref: "header",
            staticClass: "header",
            domProps: { innerHTML: _vm._s(_vm.shownHeader) }
          }),
          _vm._v(" "),
          _c("div", {
            ref: "description",
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.shownDescription) }
          }),
          _vm._v(" "),
          _vm.shownButtonLink && _vm.shownButtonText
            ? _c(
                "a",
                { ref: "shownButton", attrs: { href: _vm.shownButtonLink } },
                [
                  _c("button", { staticClass: "form-blue-button" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.shownButtonText) +
                        "\n                "
                    )
                  ])
                ]
              )
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5f122eb0", { render: render, staticRenderFns: staticRenderFns })
  }
}