/**
 * Widebanner with right CTA component
 */
import globals from '../../../../components/common/globals';
import mobileMixin from '../../../../components/common/mixins/mobile-mixin';
import { eventBus } from '../../../../modules/event-bus';

export default {
  name: 'vx-uiux-widebanner-right-cta',
  components: {},
  props: {
    uiuxImageData: Object,
    uiuxTextData: Object,
    componentTheme: String,
  },
  mixins: [mobileMixin],
  data() {
    return {
      globals,
      playIconSrc: `${globals.assetsPath}images/gp-pro/play-video-icon.png`,
    };
  },
  computed: {},
  created() {},
  mounted() {
    if (this.uiuxTextData.backgroundColor) {
      this.$el.style.backgroundColor = this.uiuxTextData.backgroundColor;
    }
    if (this.uiuxTextData.headerColor) {
      this.$refs.header.style.color = this.uiuxTextData.headerColor;
      if (this.$refs.header.childNodes[0] && this.$refs.header.childNodes[0].style) {
        this.$refs.header.childNodes[0].style.color = this.uiuxTextData.headerColor;
      }
    }
    if (this.uiuxTextData.superHeaderColor) {
      this.$refs.superHeader.style.color = this.uiuxTextData.superHeaderColor;
    }
    if (this.uiuxTextData.textColor) {
      this.$refs.description.style.color = this.uiuxTextData.textColor;
    }
    if (this.uiuxTextData.isExternalLink === true && this.$refs.link) {
      this.$refs.link.target = '_blank';
    }
    if (this.uiuxTextData.ctaColor) {
      this.$refs.linkButton.style.color = this.uiuxTextData.ctaColor;
      this.$refs.linkButton.style.backgroundColor = 'transparent';
      this.$refs.linkButton.style.border = `3px solid ${this.uiuxTextData.ctaColor}`;
    }
  },
  methods: {
    openModal() {
      if (this.uiuxImageData.videoSrc) {
        const videoSRC = this.uiuxImageData.videoSrc;
        eventBus.$emit('open-player', videoSRC);
      }
    },
    onLazyLoad() {
      if (this.uiuxImageData.imageSrcD) {
        const backgroundImageURL = this.getResponsiveImage(this.uiuxImageData.imageSrcD, this.uiuxImageData.imageSrcT, this.uiuxImageData.imageSrcM);
        this.$refs.leftSide.style.background = `url("${backgroundImageURL}") no-repeat center`;
        this.$refs.leftSide.style.backgroundSize = 'contain';
      }
    },
    onMouseOver() {
      this.playIconSrc = `${globals.assetsPath}images/gp-pro/play-video-icon-dark.png`;
    },
    onMouseLeave() {
      this.playIconSrc = `${globals.assetsPath}images/gp-pro/play-video-icon.png`;
    },
  },
};
