// john youngman - see vxBannerCarousel is our version and not the one in the top-level components folder

// john youngman - had to add an extra '../' to reference the top level components
import vxAccordionGroup from '@/components/view-site-content/vx-faq/vx-faq.vue';
import vxHeroBannerResponsive from '@/components/view-site-content/vx-hero-banner-responsive/vx-hero-banner-responsive.vue';
import vxMarketingPromo from '@/components/view-site-content/vx-marketing-promo/vx-marketing-promo.vue';
import vxProductSupport from '@/components/view-site-content/vx-product-support/vx-product-support.vue';
import vxProductSolution from '@/components/view-site-content/vx-product-category-solution/vx-product-category-solution.vue';
import vxBrandBar from '@/components/view-site-content/vx-brand-bar/vx-brand-bar.vue';
import vxSbs from '@/components/view-site-content/vx-side-by-side/vx-side-by-side.vue';
import vxFeatureProduct from '@/components/view-site-content/vx-feature-product/vx-feature-product.vue';
import vxProductCarousel from '@/components/view-site-content/vx-product-carousel/vx-product-carousel.vue';
import vxImageTitle from '@/components/view-site-content/vx-image-title/vx-image-title.vue';
import vxCompareSlider from '../../components/view-site-content/vx-compare-slider/vx-compare-slider-shell';
import vxFindDistributor from '../components/view-site-content/find-distributor/vx-find-distributor/vx-find-distributor.vue';
import vxHeroBanner from '../components/view-site-content/vx-hero-banner/vx-hero-banner.vue';
import vxIndustrialSolution from '../components/view-site-content/vx-product-solution/vx-product-solution.vue';
import vxTextTile from '../components/view-site-content/vx-text-tile/vx-text-tile.vue';
import vxImageTile from '../components/view-site-content/vx-image-tile/vx-image-tile.vue';
import vxImageText from '../components/view-site-content/vx-image-text/vx-image-text.vue';
import vxBannerCarousel from '../components/view-site-content/vx-banner-carousel/vx-banner-carousel.vue';
import vxHowToBuy from '../components/vx-how-to-buy/vx-how-to-buy.vue';

const viewSiteContentModule = {
  components: {
    vxHeroBanner,
    vxHeroBannerResponsive,
    vxBannerCarousel,
    vxAccordionGroup,
    vxMarketingPromo,
    vxProductSolution,
    vxProductSupport,
    vxImageTile,
    vxTextTile,
    vxImageTitle,
    vxBrandBar,
    vxSbs,
    vxImageText,
    vxFeatureProduct,
    vxIndustrialSolution,
    vxProductCarousel,
    vxFindDistributor,
    vxCompareSlider,
    vxHowToBuy,
  },
};

export default viewSiteContentModule;
