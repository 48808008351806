/* eslint-disable no-undef */
/* eslint-disable camelcase */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'promise-polyfill/src/polyfill';
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueTheMask from 'vue-the-mask';
import VueLazyload from 'vue-lazyload';
import router from '../router';
import globals from '../components/common/globals';
import store from '../components/common/store/store';
import messages from '../locale/gp-pro/messages';
import commonModule from './modules/common';
import viewSiteContentModule from './modules/view-site-content'; // john youngman - using our "gppro" version
import accessCustomerServiceModule from './modules/access-customer-service';
import nfrModule from './modules/nfr'; // e Kathuria - using our "gppro" version
import vxDownloadInfoModule from './modules/vx-download-info'; // jeffrey - using 'gppro' version
import vxImageDownloadModule from './modules/vx-image-download'; // jeffrey - using 'gppro' version
import vxPdfDownloadModule from './modules/vx-pdf-download'; // jeffrey - using 'gppro' version
import searchBrowseModule from './modules/search-browse'; // jeffrey - using 'gppro' version
import registerLoginModule from './modules/registration-login';
import manageProfileShoppingListsModule from './modules/manage-profile-shopping-lists';
import manageShoppingCartModule from './modules/manage-shopping-cart';
import pdpModule from './modules/pdp';
import checkoutModule from './modules/checkout';
import manageB2bOrgModule from './modules/manage-b2b-org';
import quickOrderModule from '../modules/quick-order';
import manageTransactionHistoryModule from './modules/manage-transaction-history';
import manageSubscriptionModule from '../modules/manage-subscription';
import '../components/common/directives/directives';
import siteConfig from '../config/gppro-config';
import { globalEventBus } from '../modules/event-bus';
import headerHeightAdjustment from '../components/common/mixins/header-height-adjustment-mixin';
import variables from './variables';

const scriptURL = document.currentScript.src;
const parts = scriptURL.split('/');
__webpack_public_path__ = `${parts.slice(0, parts.length - 3).join('/')}/`;

// youngman testing
// Vue.config.devtools = true;
// end looklisten testing
const config = {
  delay: 100,
  aria: false,
};
Vue.use(VeeValidate, config);
Vue.use(VueTheMask);
Vue.config.productionTip = false;
Vue.config.keyCodes = {
  // Enter or space Capturing
  'enter-space': [13, 32],
};
Vue.use(VueLazyload, {
  lazyComponent: true,
  attempt: 1,
});
/* eslint-disable no-new */
const vm = new Vue({
  el: '#gppro',
  data: {
    messages: messages['en-US'],
    globals,
    cartExists: false,
    siteConfig,
    variables,
  },
  mixins: [
    commonModule,
    viewSiteContentModule,
    accessCustomerServiceModule,
    nfrModule,
    searchBrowseModule,
    headerHeightAdjustment,
    registerLoginModule,
    manageShoppingCartModule,
    pdpModule,
    manageProfileShoppingListsModule,
    checkoutModule,
    manageB2bOrgModule,
    quickOrderModule,
    manageTransactionHistoryModule,
    manageSubscriptionModule,
    vxDownloadInfoModule,
    vxImageDownloadModule,
    vxPdfDownloadModule,
  ],
  router,
  store,
  components: {},
  created() {
    window.cartExists = this.cartExists;
  },
  mounted() {
    /**
         * setting site specific variables
         */
    this.globals.siteVariables = this.variables;
    globalEventBus.$on('scroll-to', (val) => {
      const targetElement = document.querySelector(val);
      if (targetElement) targetElement.scrollIntoView({ behavior: 'smooth' });
    });
    if (this.globals.asmLaunchMode === 'true') {
      window.history.scrollRestoration = 'manual';
    }
  },
  methods: {},
  updated() {
    this.setInnerWrapperPadding();
  },
  // template: '<GPPro/>',
});

Vue.config.errorHandler = (err, vm1, info) => {
  vm.sendToTrackJS(err, vm1, info);
};
