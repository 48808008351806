/**
 * This component Handles the order confirmation page
 */
import find from 'lodash.find';
import fecha from 'fecha';
import vxOrderSummary from '../../../../components/manage-shopping-cart/vx-order-summary/vx-order-summary.vue';
import vxProductTile from '../../../../components/common/vx-product-tile/vx-product-tile.vue';
import globals from '../../../../components/common/globals';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import ManageTransactionService from '../../../../components/common/services/manage-transaction-service';
import {
  discount,
  approvalDefaultStatus,
  approvalDetailStatus,
  paymentTypes,
} from '../../../../components/common/mixins/vx-enums';

export default {
  name: 'vx-order-approval-details',
  components: {
    vxOrderSummary,
    vxProductTile,
    vxSpinner,
    vxModal,
  },
  props: {
    /**
     * Labels, button and caption texts
     */
    i18n: Object,
  },
  data() {
    return {
      country: 'United States',
      orderDetails: {},
      globals,
      orderCode: '',
      formattedEntries: {},
      isMultiple: false,
      isGiftable: false,
      isInstallable: false,
      dataLoaded: false,
      istallableProducts: [],
      promotionData: {},
      leaseAgrementData: {},
      manageTransactionService: new ManageTransactionService(),
      statusUpdated: true,
      approvalComments: '',
      approvalDefaultStatus,
      approvalDetailStatus,
      discount,
      paymentTypes,
    };
  },
  computed: {},
  mounted() {
    this.getOrderCode();
    this.getOrderDetails();
  },
  methods: {
    /**
     * function to print page.
     */
    printPage() {
      window.print();
    },
    /**
     * function to get order code from URL.
     */
    getOrderCode() {
      const urlData = window.location.pathname.split('/');
      this.orderCode = urlData[urlData.length - 1];
    },
    /**
     * function to create address string.
     */
    formAddressString(address) {
      let userAddress = '';
      let companyName = '';
      if (address && address.companyName) {
        companyName = address.companyName;
      }
      if (address) {
        userAddress = `${address.firstName} `
          + `${address.lastName},`
          + `${companyName},`
          + `${address.line1},`
          + `${address.line2},`
          + `${address.town},`
          + `${address.region.isocodeShort},`
          + `${address.postalCode},`
          + `${address.country.isocode}`;
      }
      return userAddress;
    },
    /**
     * function to call get order detail service.
     */
    getOrderDetails() {
      this.manageTransactionService.getOrderDetailsB2BAdmin(
        {},
        this.handleOrderDetailsSuccess,
        this.handleOrderDetailsError,
        this.orderCode,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
     * function to handle success response for order detail service.
     */
    handleOrderDetailsSuccess(response) {
      if (response && response.data) {
        this.orderDetails = response.data;
        if (
          this.orderDetails.status
          === this.approvalDefaultStatus.pendingApproval
        ) {
          this.statusUpdated = false;
        } else {
          this.statusUpdated = true;
        }
        this.orderDetails.finalAddress = this.formAddressString(
          this.orderDetails.deliveryAddress,
        );
        this.formattedEntries = this.arrayToObject(
          this.orderDetails.entries,
          'entryNumber',
        );
        this.shippingType(this.orderDetails.deliveryGroup);
        this.dataLoaded = true;
        this.promotionData = this.getProductPromotions();
        this.$refs.spinner.hideSpinner();
      }
    },
    /**
     * function to handle error response for order detail service.
     */
    handleGetOrderDetailsError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * function to check if shipping is single or multiple.
     */
    shippingType(deliveryGroup) {
      if (deliveryGroup.length > 1) {
        this.isMultiple = true;
      } else {
        this.isMultiple = false;
      }
    },
    /**
     * function to check if Lease Option should be shown.
     */
    checkLeasable(array) {
      let isLeasable = false;
      array.forEach((group) => {
        group.value.splitEntries.forEach((item) => {
          if (this.formattedEntries[item.entryNumber].leasable) {
            isLeasable = true;
          }
        });
      });
      return isLeasable;
    },
    /**
     * function to check if product is selected for gift.
     */
    checkGiftable(product) {
      let isGift = false;
      if (product.additionalAttributes) {
        if (
          find(product.additionalAttributes.entry, {
            key: 'giftOpted',
            value: 'true',
          })
        ) {
          isGift = true;
        }
      }
      this.isGiftable = isGift;
      return isGift;
    },
    /**
     * function to check if product is selected for installation.
     */
    checkInstallable(product) {
      let installable = false;
      if (product.additionalAttributes) {
        if (
          find(product.additionalAttributes.entry, {
            key: 'installed',
            value: 'true',
          })
        ) {
          installable = true;
          this.istallableProducts[product.entryNumber] = product.product.code;
        }
      }
      this.isInstallable = installable;
      return installable;
    },
    /**
     * function to check if single or multiple shipping is used.
     */
    getShippingMethod(data) {
      const method = data.split('-').join(' ');
      return method;
    },
    /**
     * function to get modified date.
     */
    getDate(date) {
      const modidfiedDate = date.substring(0, 10);
      return fecha.format(new Date(modidfiedDate), 'mediumDate');
    },
    /**
     * function to get product promotion data.
     */
    getProductPromotions() {
      const promotionProductData = {};
      for (
        let i = 0;
        i < this.orderDetails.appliedProductPromotions.length;
        i += 1
      ) {
        for (
          let j = 0;
          j
          < this.orderDetails.appliedProductPromotions[i].consumedEntries.length;
          j += 1
        ) {
          const { orderEntryNumber } = this.orderDetails.appliedProductPromotions[i]
            .consumedEntries[j];
          const { description } = this.orderDetails.appliedProductPromotions[i];
          promotionProductData[orderEntryNumber] = description;
        }
      }
      return promotionProductData;
    },
    /**
     * function to convert array to object.
     */
    arrayToObject(array, keyField) {
      const modifiedObj = array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
      }, {});
      return modifiedObj;
    },
    /**
     * function to call showLeaseAgreement service.
     */
    showLeaseAgreement() {
      this.manageTransactionService.showLeaseAgreement(
        {},
        this.handleLeaseAgreementResponse,
        this.handleLeaseAgreementError,
        this.orderDetails.code,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
     * function to handle success response for showLeaseAgreement service.
     */
    handleLeaseAgreementResponse(response) {
      if (response && response.data) {
        this.leaseAgrementData = response.data;
        this.$refs.spinner.hideSpinner();
        this.$refs.viewTermsModal.open();
      }
    },
    /**
     * function to handle error response for showLeaseAgreement service.
     */
    handleLeaseAgreementError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * function to update order status.
     */
    onStatusUpdate(status) {
      this.manageTransactionService.orderStatusUpdated(
        {},
        this.handleOrderStatusUpdateSuccess,
        this.handleOrderStatusUpdateError,
        this.orderCode,
        status,
        this.approvalComments,
      );
      this.$refs.spinner.showSpinner();
    },
    /**
     * function to handle success response for orderStatusUpdate service.
     */
    handleOrderStatusUpdateSuccess() {
      this.$refs.spinner.hideSpinner();
      this.getOrderDetails();
    },
    /**
     * function to handle success response for orderStatusUpdate service.
     */
    handleOrderStatusUpdateError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * function to get install date
     */
    getInstallDate(date) {
      fecha.masks.myMask = 'MM/DD/YYYY';
      const dateObj = fecha.parse(date, 'dddd MMMM Do, YYYY');
      return fecha.format(dateObj, 'myMask');
    },
  },
};
