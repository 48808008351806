/**
 * Component for displaying the data of products in tabular form on compare results page
 */
import {
  eventBus,
} from '../../../modules/event-bus';
import mobileMixin from '../../common/mixins/mobile-mixin';

export default {
  name: 'vx-compare-table',
  components: {},
  props: {
    /**
     * data of the products selected for comparison
     */
    productData: {
      type: Object,
    },
  },
  mixins: [mobileMixin],
  data() {
    return {
      removeData: '',
    };
  },
  computed: {},
  methods: {
    /**
     * This function finds index of removed data
     */
    getProductTileIndex() {
      const self = this;
      let productIndex = -1;
      this.productData.products.some((el, i) => {
        if (el.code === self.removeData) {
          productIndex = i;
          return true;
        }
        return undefined;
      });
      this.removeProductSpecification(productIndex);
    },
    /**
     * This function deletes data of removed product from compare table
     * @param  {Number} productIndex index of the product to be deleted
     */
    removeProductSpecification(productIndex) {
      this.productData.products.splice(productIndex, 1);
      this.productData.specifications.forEach((specification) => {
        specification.subCategories.forEach((subCategory) => {
          subCategory.columnValues.splice(productIndex, 1);
        });
      });
    },
    /**
     * This function splits the column values into 2 parts for mobile view
     * @param  {Array} columnValues array of column values for each product
     */
    specCols(columnValues) {
      if (this.isMobile()) {
        return columnValues.slice(0, 2);
      }
      return columnValues;
    },
  },
  async mounted() {
    eventBus.$on('comparePageRemove', (data) => {
      this.removeData = data;
      this.getProductTileIndex();
    });
  },
};
