var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.msg))]),
      _vm._v(" "),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("vx-login", {
                attrs: {
                  "action-url": "/someurl/somepoint",
                  i18n: _vm.messages.registrationLogin.login
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("vx-guest-checkout", {
                attrs: { i18n: _vm.messages.checkout.guestCheckout }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "mb-xs-4" }, [
          _vm._v("Login Test for Form Submit")
        ])
      ]),
      _vm._v(" "),
      _c("vx-slider", {
        attrs: { "product-data": _vm.products, title: "CUSTOMER ALSO VIEWED" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cc825312", { render: render, staticRenderFns: staticRenderFns })
  }
}