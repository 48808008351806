var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-confirm-password" },
    [
      _c("vx-spinner", {
        ref: "verifyPasswordSpinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.verifyPassword($event)
            }
          }
        },
        [
          _c("div", { staticClass: "form-group has-feedback" }, [
            _c("label", { attrs: { for: "password" } }, [
              _vm._v(_vm._s(_vm.i18n.currentPasswordLabel))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "password-container" }, [
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: true,
                      regex: _vm.globals.PASSWORD_REGEX
                    },
                    expression:
                      "{ required: true, regex: globals.PASSWORD_REGEX }"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password"
                  }
                ],
                ref: "password",
                staticClass: "mb-xs-2 form-control password",
                class: {
                  "input-error":
                    _vm.errors.has("password") || _vm.isPasswordMismatch
                },
                attrs: {
                  type: "password",
                  name: "Password",
                  "aria-label": _vm.i18n.currentPasswordLabel
                },
                domProps: { value: _vm.password },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    },
                    _vm.updatePasswordMismatch
                  ]
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "icon-eye toggle-password",
                class: { "icn-eye-collapsed": _vm.showPassword },
                attrs: {
                  title: _vm.showPassword
                    ? _vm.i18n.iconEyeHideTitle
                    : _vm.i18n.iconEyeShowTitle,
                  tabindex: "0",
                  role: "button",
                  "aria-label": _vm.showPassword
                    ? _vm.i18n.iconEyeHideTitle
                    : _vm.i18n.iconEyeShowTitle
                },
                on: {
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k(
                        $event.keyCode,
                        "enter-space",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    _vm.handleDeleteList($event)
                  },
                  click: _vm.togglePassword
                }
              })
            ]),
            _vm._v(" "),
            _vm.errors.has("Password") || _vm.isPasswordMismatch
              ? _c("div", { staticClass: "mt-xs-2 " }, [
                  _c("span", { staticClass: "error-msg" }, [
                    _vm._v(_vm._s(_vm.errors.first("Password")))
                  ]),
                  _vm._v(" "),
                  _vm.isPasswordMismatch
                    ? _c("span", { staticClass: "error-msg" }, [
                        _vm._v(_vm._s(_vm.i18n.currentPasswordInvalidError))
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "mb-md-5 mb-sm-5 mb-xs-4 passwordHelp " }, [
              _vm._v(_vm._s(_vm.i18n.passwordInstruction))
            ])
          ]),
          _vm._v(" "),
          _vm.updatePasswordServerError
            ? _c("div", { staticClass: "mt-xs-2 " }, [
                _c("span", { staticClass: "error-msg " }, [
                  _vm._v(_vm._s(_vm.updatePasswordServerError))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "update-password btn btn-primary center-block modal-btn-primary-small modal-btn-xs-sticky",
              attrs: { type: "submit", disabled: _vm.errors.any() }
            },
            [_vm._v(_vm._s(_vm.i18n.submit))]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-21212964", { render: render, staticRenderFns: staticRenderFns })
  }
}