var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "image-title",
      class: _vm.imgTitleData.componentTheme,
      style: _vm.backgroundStyle,
      attrs: {
        "aria-label": [_vm.backgroundAlt],
        id: _vm.imgTitleData.componentId
      }
    },
    [
      _c("vx-video-player", { ref: "videoPlayer" }),
      _vm._v(" "),
      _vm.imgTitleData.componentHeader
        ? _c("div", {
            staticClass: "header my-xs-0 pb-xs-4 pt-xs-3 pt-sm-5 pb-md-5",
            style: { color: _vm.imgTitleData.componentHeaderColor },
            domProps: { innerHTML: _vm._s(_vm.imgTitleData.componentHeader) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.imgTitleData.componentHeader
        ? _c("div", {
            staticClass: "title-underline mt-xs-0",
            style: _vm.imgTitleData.componentHeaderColor
              ? { "border-bottom-color": _vm.imgTitleData.componentHeaderColor }
              : ""
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.imgTitleData.subHeaderText
        ? _c("p", {
            staticClass: "subheader my-xs-0 pb-xs-4 pb-md-5 mx-xs-6",
            style: { color: _vm.imgTitleData.componentHeaderColor },
            domProps: { innerHTML: _vm._s(_vm.imgTitleData.subHeaderText) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-wrap justify-content-center mob image-title-container"
        },
        _vm._l(_vm.imgTitleData.tiles, function(tile, index) {
          return _c(
            "div",
            {
              staticClass: "px-sm-2 d-flex image-tile",
              style: _vm.imageTileWidth
            },
            [
              _c(
                "div",
                {
                  staticClass: "tile mb-xs-3",
                  style: { background: tile.backgroundColor }
                },
                [
                  tile.imgSrcD || tile.imgSrcT || tile.imgSrcM
                    ? [
                        _c("div", { staticClass: "image-title__wrapper" }, [
                          !tile.jumpToImage
                            ? _c(
                                "a",
                                {
                                  style: [
                                    tile.imageLink
                                      ? { cursor: "pointer" }
                                      : { cursor: "default" }
                                  ],
                                  attrs: {
                                    href: tile.isExternalImage
                                      ? tile.imageLink
                                      : _vm.globals.getUrlWithContextPath(
                                          tile.imageLink
                                        ),
                                    target: tile.isExternalImage
                                      ? "_blank"
                                      : "",
                                    "aria-label": _vm.getResponsiveAlt(
                                      tile.imgSrcAltTextD,
                                      tile.imgSrcAltTextT,
                                      tile.imgSrcAltTextM
                                    )
                                  }
                                },
                                [
                                  _c("lazy-component", [
                                    _c("picture", [
                                      _c("source", {
                                        attrs: {
                                          media: "(min-width: 1200px)",
                                          srcset: tile.imgSrcD
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("source", {
                                        attrs: {
                                          media: "(min-width: 768px)",
                                          srcset: tile.imgSrcT
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("source", {
                                        attrs: {
                                          media: "(min-width: 360px)",
                                          srcset: tile.imgSrcM
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("img", {
                                        attrs: {
                                          src: tile.imgSrcD,
                                          alt: _vm.getResponsiveAlt(
                                            tile.imgSrcAltTextD,
                                            tile.imgSrcAltTextT,
                                            tile.imgSrcAltTextM
                                          )
                                        }
                                      })
                                    ])
                                  ])
                                ],
                                1
                              )
                            : _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      _vm.emitScrollSignal(tile.imageLink)
                                    }
                                  }
                                },
                                [
                                  _c("lazy-component", [
                                    _c("picture", [
                                      _c("source", {
                                        attrs: {
                                          media: "(min-width: 1200px)",
                                          srcset: tile.imgSrcD
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("source", {
                                        attrs: {
                                          media: "(min-width: 768px)",
                                          srcset: tile.imgSrcT
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("source", {
                                        attrs: {
                                          media: "(min-width: 360px)",
                                          srcset: tile.imgSrcM
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("img", {
                                        attrs: {
                                          src: tile.imgSrcD,
                                          alt: _vm.getResponsiveAlt(
                                            tile.imgSrcAltTextD,
                                            tile.imgSrcAltTextT,
                                            tile.imgSrcAltTextM
                                          )
                                        }
                                      })
                                    ])
                                  ])
                                ],
                                1
                              ),
                          _vm._v(" "),
                          tile.isVideo === "true"
                            ? _c("a", {
                                staticClass:
                                  "image-title__video-play-icon image-title__position__center icon-play",
                                style: { color: tile.playIconColor },
                                attrs: {
                                  role: "button",
                                  target: tile.isExternalImage ? "_blank" : "",
                                  href: "javascript:void(0)",
                                  tabindex: "0",
                                  title:
                                    _vm.getResponsiveAlt(
                                      tile.imgSrcAltTextD,
                                      tile.imgSrcAltTextT,
                                      tile.imgSrcAltTextM
                                    ) +
                                    " " +
                                    _vm.i18n.iconPlayTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.openModal(tile.videoSrc)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.openModal(tile.videoSrc)
                                  }
                                }
                              })
                            : _vm._e()
                        ])
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "bottom-block" },
                    [
                      tile.header
                        ? _c("div", {
                            staticClass:
                              "mt-xs-4 mb-xs-3 mt-sm-3 my-md-4 tile-header text-center",
                            style: { color: tile.headerColor },
                            domProps: { innerHTML: _vm._s(tile.header) }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      tile.tileText
                        ? _c("div", {
                            staticClass: "tile-text",
                            style: { color: tile.textColor },
                            domProps: { innerHTML: _vm._s(tile.tileText) }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.priceSpiderTiles[index]
                        ? _c("vx-price-spider", {
                            staticClass: "tile-btn-container tile-btn",
                            class: tile.ctaStyle,
                            style: { color: tile.ctaColor },
                            attrs: {
                              config: {
                                pskey: _vm.globals.getPriceSpiderID(),
                                pscountry: "US",
                                pslanguage: "en",
                                pssku: _vm.priceSpiderTiles[index],
                                src: "//cdn.pricespider.com/1/lib/ps-widget.js",
                                pslabel: _vm.imgTitleData.ctaText,
                                pslabel: _vm.imgTitleData.ctaText
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !tile.jumpToHtml &&
                      !tile.isVideoCta &&
                      tile.ctaText &&
                      !_vm.priceSpiderTiles[index]
                        ? _c("a", {
                            staticClass: "tile-btn-container tile-btn",
                            class: tile.ctaStyle,
                            style: { color: tile.ctaColor },
                            attrs: {
                              target: tile.isExternalLink ? "_blank" : "",
                              href: tile.isExternalLink
                                ? tile.ctaLink
                                : _vm.globals.getUrlWithContextPath(
                                    tile.ctaLink
                                  ),
                              "aria-label":
                                _vm.stripHtmlTags(tile.header) &&
                                _vm.stripHtmlTags(tile.header) !== undefined
                                  ? _vm.stripHtmlTags(tile.header) +
                                    " " +
                                    _vm.stripHtmlTags(tile.ctaText)
                                  : _vm.stripHtmlTags(tile.ctaText)
                            },
                            domProps: { innerHTML: _vm._s(tile.ctaText) }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !tile.jumpToHtml &&
                      tile.isVideoCta &&
                      tile.ctaText &&
                      !_vm.priceSpiderTiles[index]
                        ? _c("a", {
                            staticClass: "tile-btn-container tile-btn",
                            class: tile.ctaStyle,
                            style: { color: tile.ctaColor },
                            attrs: {
                              href: "javascript:void(0)",
                              "aria-label":
                                _vm.stripHtmlTags(tile.header) &&
                                _vm.stripHtmlTags(tile.header) !== undefined
                                  ? _vm.stripHtmlTags(tile.header) +
                                    " " +
                                    _vm.stripHtmlTags(tile.ctaText)
                                  : _vm.stripHtmlTags(tile.ctaText)
                            },
                            domProps: { innerHTML: _vm._s(tile.ctaText) },
                            on: {
                              click: function($event) {
                                _vm.openModal(tile.ctaLink)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      tile.jumpToHtml &&
                      !tile.isVideoCta &&
                      tile.ctaText &&
                      !_vm.priceSpiderTiles[index]
                        ? _c("a", {
                            staticClass: "tile-btn-container tile-btn",
                            class: tile.ctaStyle,
                            style: { color: tile.ctaColor },
                            attrs: {
                              href: "javascript:void(0)",
                              "aria-label":
                                _vm.stripHtmlTags(tile.header) &&
                                _vm.stripHtmlTags(tile.header) !== undefined
                                  ? _vm.stripHtmlTags(tile.header) +
                                    " " +
                                    _vm.stripHtmlTags(tile.ctaText)
                                  : _vm.stripHtmlTags(tile.ctaText)
                            },
                            domProps: { innerHTML: _vm._s(tile.ctaText) },
                            on: {
                              click: function($event) {
                                _vm.emitScrollSignal(tile.ctaLink)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            ]
          )
        })
      ),
      _vm._v(" "),
      _vm.isPriceSpider
        ? _c("vx-price-spider", {
            staticClass: "component-button",
            class: _vm.imgTitleData.ctaStyle,
            attrs: {
              config: {
                pskey: _vm.globals.getPriceSpiderID(),
                pscountry: "US",
                pslanguage: "en",
                pssku: _vm.psSKU,
                src: "//cdn.pricespider.com/1/lib/ps-widget.js",
                pslabel: _vm.imgTitleData.ctaText
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.imgTitleData.ctaText && _vm.imgTitleData.ctaLink && !_vm.isPriceSpider
        ? _c("a", {
            staticClass: "component-button",
            class: _vm.imgTitleData.ctaStyle,
            attrs: {
              target: _vm.imgTitleData.isExternal ? "_blank" : "",
              href: _vm.imgTitleData.isExternal
                ? _vm.imgTitleData.ctaLink
                : _vm.globals.getUrlWithContextPath(_vm.imgTitleData.ctaLink),
              "aria-label": _vm.stripHtmlTags(_vm.imgTitleData.componentHeader)
            },
            domProps: { innerHTML: _vm._s(_vm.imgTitleData.ctaText) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d41c278a", { render: render, staticRenderFns: staticRenderFns })
  }
}