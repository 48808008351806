export default {
  name: 'vx-voucher-details',
  components: {},
  props: {
    // Voucher details
    voucherData: Object,
    // Text coming from property file
    i18n: Object,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
