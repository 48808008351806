/* Services for Manage Profile Shopping List Service */
import get from 'lodash.get';
import axios from 'axios';
import RootService from './root-service';

const url = '/gpcommercewebservices/v2/gppro/apiproxy';
const pardotConfig = {
  access_token: '',
  authUrl: '/authorizationserver/oauth/token?client_id=mobile_android&client_secret=secret&grant_type=client_credentials',
  urlPrefix: '',
};

const generateRemoteHeader = (apiKey) => (['Content-Type:application/x-www-form-urlencoded',
  `Authorization:Bearer ${apiKey}`, 'Pardot-Business-Unit-Id:0Uv3q000000TN5bCAG']);

class PardotService extends RootService {
  getApiKey(callback) {
    const config = {};
    config.url = url;
    config.data = {
      remoteName: 'Pardot-OAuth',
      remoteUrl: '/token',
      remoteHeaders: ['Content-Type:application/x-www-form-urlencoded'],
      postData: '{{$formData}}',
    };
    const self = this;
    // get auth token before firing API
    this.getAuthToken((success) => {
      if (success) {
        config.headers = {
          Authorization: `bearer ${pardotConfig.access_token}`,
          'Content-Type': 'application/json',
        };
        config.method = 'POST';
        axios(config)
          .then(({ data: pardotResponse }) => {
            if (!Object.prototype.hasOwnProperty.call(pardotResponse, 'error')) {
              pardotConfig.api_key = get(pardotResponse, ['access_token']);
              callback.call(self, true);
            }
          });
      }
    });
  }

  getAuthToken(callback) {
    const self = this;
    if (pardotConfig.access_token) {
      if (callback) {
        callback.call(self, true);
      }
    } else {
      axios.post(pardotConfig.urlPrefix + pardotConfig.authUrl)
        .then((response) => {
          if (response && response.data && response.data.access_token) {
            pardotConfig.access_token = response.data.access_token;
            if (callback) {
              callback.call(self, true);
            }
          } else {
            this.handleSendFormDataError.call('The server is unable to accept this form post at this time.');
          }
        })
        .catch(callback);
    }
  }

  readProspectWithEmail(email, successCallback, errorCallback) {
    const config = {};
    config.url = url;

    this.getApiKey((success) => {
      if (success) {
        config.headers = {
          Authorization: `bearer ${pardotConfig.access_token}`,
          'Content-Type': 'application/json',
        };

        config.data = {
          remoteName: 'Pardot-Read-Prospect',
          remoteUrl: `/${email}`,
          remoteHeaders: generateRemoteHeader(pardotConfig.api_key),
          postData: '{{$formdata}}',
        };
        config.method = 'POST';
        axios(config)
          .then((response) => {
            successCallback(response.data);
          })
          .catch((err) => {
            if (err.response) {
              errorCallback(err.response.data);
            }
          });
      }
    });
  }

  createProspect(urlData, successCallback, errorCallback) {
    const config = {};
    config.url = url;

    this.getApiKey((success) => {
      if (success) {
        config.headers = {
          Authorization: `bearer ${pardotConfig.access_token}`,
          'Content-Type': 'application/json',
        };

        config.data = {
          remoteName: 'Pardot-Create-Prospect',
          remoteUrl: `/${urlData}`,
          remoteHeaders: generateRemoteHeader(pardotConfig.api_key),
          postData: '{{$formdata}}',
        };
        config.method = 'POST';

        axios(config)
          .then((response) => {
            successCallback(response.data);
          })
          .catch(errorCallback);
      }
    });
  }

  updateProspect(urlData, successCallback, errorCallback) {
    const config = {};
    config.url = url;

    this.getApiKey((success) => {
      if (success) {
        config.headers = {
          Authorization: `bearer ${pardotConfig.access_token}`,
          'Content-Type': 'application/json',
        };

        config.data = {
          remoteName: 'Pardot-Update-Prospect',
          remoteUrl: `/${urlData}`,
          remoteHeaders: generateRemoteHeader(pardotConfig.api_key),
          postData: '{{$formdata}}',
        };
        config.method = 'POST';
        axios(config)
          .then((response) => {
            successCallback(response);
          })
          .catch(errorCallback);
      }
    });
  }
}

export default PardotService;
