var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-refine-approval-list" },
    [
      _c("div", { staticClass: "pb-xs-2 filterHeading" }, [
        _vm._v(_vm._s(_vm.i18n.filter))
      ]),
      _vm._v(" "),
      _vm._t("sortDropdown"),
      _vm._v(" "),
      _c("div", { staticClass: "pt-xs-4 pb-xs-2 status" }, [
        _vm._v(_vm._s(_vm.i18n.statusOptions.title))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-xs-3" },
        [
          _c("vx-checkbox-group", {
            ref: "statusCheckbox",
            attrs: { "check-box-values": _vm.filterOptions },
            on: { "checked-options": _vm.checkedOptions }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.filterError
        ? _c("div", { staticClass: "pb-xs-2 status-error" }, [
            _vm._v(_vm._s(_vm.statusError))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 filter-sort-buttons" }, [
        _c(
          "button",
          {
            staticClass: "col-xs-6 clear-filter-button btn btn-default mr-xs-4",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                _vm.filterAction("clear")
              }
            }
          },
          [_vm._v(_vm._s(_vm.i18n.clearAll))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "col-xs-5 save-filter-button btn btn-primary ",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                _vm.filterAction("apply")
              }
            }
          },
          [_vm._v(_vm._s(_vm.i18n.apply))]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-895b5eac", { render: render, staticRenderFns: staticRenderFns })
  }
}